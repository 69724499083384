import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import AgriAction from 'agriAdministration/actions/AgriAction'
import ActionComponent from 'components/ActionComponent'
import { DOCUMENT_TYPES, PICTURE_TYPES } from 'components/file/constant/FileConstants'
import DtoFile from 'components/file/dto/DtoFile'
import Checkbox from 'components/forms/Checkbox'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Icon from 'components/icon/Icon'
import TabList from 'components/list/TabList'
import ProgressCard from 'components/card/ProgressCard'
import { push } from '@lagunovsky/redux-react-router'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import HomeAction from 'home/actions/HomeAction'
import { isEqual, keys, orderBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import StationAction from 'station/actions/StationAction'
import PictureStationPanel from 'station/components/picture/PictureStationPanel'
import DtoPicture from 'station/dto/DtoPicture'
import DtoSurvey from 'survey/dto/DtoSurvey'
import { getFileExtension, getFileName, getFileNatureLabel, getFormatedFileName, getTypeSandreCode } from 'utils/FileUtils'
import { hasValue } from 'utils/NumberUtil'
import { getSandreList } from 'utils/StoreUtils'
import Card from '../../components/card/Card'
import Input from '../../components/forms/Input'
import { searchAllCharacters } from '../../utils/StringUtil'
import DeclarationStatsCard from './cards/DeclarationStatsCard'
import DtoDeclarationWithStats from 'survey/dto/DtoDeclarationWithStats'
import { DefaultDialogTitle } from 'components/styled/Dialog'

class ExploitationDocumentsApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            idExploitation: parseInt(props.params.id),
            dataLoaded: false,
            tmpFilter: {},
            filter: {},
            tab: 'declarations',
            modalData: { open: false },
            openPopup: false,
            isEditMode: false,
        }
    }

    componentDidMount() {
        const { idExploitation } = this.state
        const { exploitation } = this.props
        this.props.fetchDeclarationsByExploitationId(idExploitation)
        if (exploitation && exploitation.idExploitation !== idExploitation) {
            this.props.fetchExploitation(idExploitation).then(() => {
                this.setExploitation(this.props.exploitation)
            })
        } else {
            this.setExploitation(exploitation)
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.pictures, this.props.pictures)) {
            const { pictures } = this.props
            this.setState({ pictures })
        }
        if (!isEqual(prevProps.files, this.props.files)) {
            const { files } = this.props
            this.setState({ files })
        }
        this.addActions(this.state.isEditMode)
    }

    toggleEditMode = () => {
        this.setState(({ isEditMode }) => ({ isEditMode: !isEditMode }))
    }

    addActions = (editMode) => {
        if (editMode) {
            this.setActions({
                new: () => this.setState({ openPopup: true }),
                cancel: this.toggleEditMode,
            })
        } else {
            this.setActions({
                edit: this.toggleEditMode,
                new: () => this.setState({ openPopup: true }),
            })
        }
    }

    setExploitation = (exploitation) => {
        const { isEditMode } = this.state
        this.props.fetchPictures(exploitation.codification.replaceAll(' ', ''), 10).then(() => this.setState({ picturesFetched: true }))
        this.props.fetchFiles(exploitation.codification.replaceAll(' ', ''), 10).then(() => this.setState({ filesFetched: true }))
        this.addActions(isEditMode)
        this.props.fetchContributor(exploitation.operatorCode).then(() => {
            const { contributor } = this.props
            this.props.setTitle([
                {
                    title: i18n.folders,
                    href: 'dossiers',
                },
                {
                    title: `${contributor.name} ${exploitation.codification ? `[${exploitation.codification}]` : ''}`,
                    href: `dossiers/${exploitation.idExploitation}/dashboard`,
                },
                {
                    title: i18n.documents,
                    href: `dossiers/${exploitation.idExploitation}/documents`,
                },
            ])
            this.setState({ dataLoaded: true })
        })
    }

    getFilteredData = (data) => {
        const { filter } = this.state
        const includesValue = searchAllCharacters(filter.searchValue || '')
        return data.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (document) => {
        return searchAllCharacters(keys(document).map(key => ({ ...document, name: '', stationCode: '', url: '' })[key]))
    }

    showDeclarations = () => {
        const { declarationsExploitation, surveys } = this.props
        const filteredData = this.getFilteredData(declarationsExploitation)
        if (filteredData.length) {
            return orderBy(filteredData, 'year', 'desc').map((d) => {
                const surveyDeclaration = surveys.find((s) => s.idSurvey === d.idSurvey) || {}
                return (
                    <Grid2 size={3} key={d.idDeclaration}>
                        <DeclarationStatsCard
                            className='no-padding no-margin'
                            title={`${i18n.declaration} ${surveyDeclaration.year}`}
                            declaration={d}
                        />
                    </Grid2>
                )
            })
        }
        return <h5 className='margin-left-1'>{i18n.noDataToDisplay}</h5>
    }

    getActive = panel => {
        return panel === this.state.tab ? 'active' : ''
    }

    onChangeTab = tab => {
        this.setState({ tab })
    }

    onUploadFile = (event) => {
        // Read File
        const { exploitation } = this.props
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader()
            const file = event.target.files[0]
            const { sandreCodes } = this.props
            reader.onload = (e) => {
                const { sandreCode } = this.getFileType(e.target.result)
                const fileTypes = orderBy(getSandreList(sandreCodes, sandreCode), 'code')
                this.setState({
                    file:
                    {
                        stationType: 10,
                        stationId: exploitation.idExploitation,
                        path: '',
                        name: file.name,
                        content: e.target.result,
                        fileType: fileTypes[0]?.code,
                        active: 1,
                    },
                })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    getFileType = (content) => {
        if (!content) {
            return {}
        }
        if (content.includes(PICTURE_TYPES.IMAGE)) {
            return { title: i18n.pictureType, sandreCode: SANDRE.TYPE_PHOTO }
        } else if (content.includes(DOCUMENT_TYPES.APPLICATION) || content.includes(DOCUMENT_TYPES.TEXT)) {
            return { title: i18n.documentType, sandreCode: SANDRE.TYPE_DOCUMENT }
        } return {}
    }

    onClosePopupAddFile = () => {
        this.setState({ openPopup: false, file: null })
    }

    onFileDelete = (file) => {
        const { exploitation } = this.props
        this.setState({ documentsLoaded: false })
        this.setState({ picturesLoaded: false })
        this.props.deleteFile(file).then(() => {
            this.loadPictures(exploitation.codification.replaceAll(' ', ''))
            this.loadDocuments(exploitation.codification.replaceAll(' ', ''))
        })
    }

    onFileUpdate = (file) => {
        const { exploitation } = this.props
        this.setState({ documentsLoaded: false })
        this.setState({ picturesLoaded: false })
        this.onClosePopupAddFile()
        this.props.updateFile(file).then(() => {
            this.loadPictures(exploitation.codification.replaceAll(' ', ''))
            this.loadDocuments(exploitation.codification.replaceAll(' ', ''))
        })
    }

    getPanel = () => {
        const { tab, isEditMode } = this.state
        switch (tab) {
            default: case 'declarations':
                return (
                    <div className='padding-1'>
                        <Grid2 container spacing={1}>
                            {this.showDeclarations()}
                        </Grid2>
                    </div>
                )
            case 'documents':
                const { files, pictures, sandreCodes } = this.props
                const data = this.getFilteredData([...pictures, ...files].map(fp => {
                    return { ...fp, type: getFileNatureLabel(fp.name, getSandreList(sandreCodes, getTypeSandreCode(fp.fileType))) }
                }))
                return (
                    <>
                        { data.length ?
                            <PictureStationPanel
                                files={data}
                                editable={isEditMode}
                                deletePicture={ this.onFileDelete }
                                updateFile={ this.onFileUpdate }
                                stationTypes={ ['agriculture'] }
                            />
                            :
                            <div style={ { padding: '5%' } }>
                                <div className='col s12 text-align-center'>
                                    <Icon size='Medium' style={{ color: 'black' }} icon='insert_drive_file'/>
                                </div>
                                <div className='center font-size-20'>{ i18n.noResults }</div>
                            </div>
                        }
                    </>
                )
        }
    }

    getAddFileModal = () => {
        const { file, openPopup } = this.state
        const { sandreCodes } = this.props
        const { sandreCode, title } = this.getFileType(file?.content)
        const sandreCodeNature = getSandreList(sandreCodes, sandreCode)
        return (
            <Dialog
                onClose={this.onClosePopupAddFile}
                fullWidth
                maxWidth='lg'
                open={openPopup}
            >
                <DefaultDialogTitle
                    title={i18n.importFile}
                    onClose={this.onClosePopupAddFile}
                />
                <DialogContent className='padding-1 padding-top-2'>
                    <Grid2 container>
                        <Grid2 size={12}>
                            <div className='file-field input-field padding-bottom-2'>
                                <div className='btn'>
                                    <span>{ i18n.importLabel }</span>
                                    <input
                                        accept='*'
                                        id='uploadFile'
                                        type='file'
                                        multiple
                                        onChange={ this.onUploadFile }
                                    />
                                </div>
                                <div className='file-path-wrapper'>
                                    <input className='file-path validate' type='text' />
                                </div>
                            </div>
                        </Grid2>
                        {file &&
                            <>
                                <Grid2 size={12}>
                                    <Select
                                        noNullValue
                                        className='padding-bottom-2'
                                        noSort={true}
                                        options={ orderBy(sandreCodeNature, 'code') }
                                        label={ title }
                                        value={ file.fileType }
                                        nullLabel='&nbsp;'
                                        onChange={ v => this.setState({ file: { ...file, fileType: v } }) }
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <SimpleDatePicker
                                        label={i18n.date}
                                        value={file.date}
                                        onChange={ v => this.setState({ file: { ...file, date: v } }) }
                                        calendarPosition='right-end'
                                    />
                                </Grid2>
                                <Grid2 size={2}>
                                    <Checkbox
                                        componentClassName='padding-1'
                                        onChange={ v => this.setState({ file: { ...file, active: v ? 1 : 0 } }) }
                                        checked={file.active} label={ file.active ? i18n.active : i18n.inactive}
                                    />
                                </Grid2>
                            </>
                        }
                    </Grid2>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.saveFile} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    loadPictures = (code) => {
        this.onClosePopupAddFile()
        if (code?.replaceAll(' ', '') !== '') {
            this.props.fetchPictures(code, 10).then(() => {
                this.setState({ picturesLoaded: true })
            })
        } else {
            this.props.warning(i18n.noFileOrPictureWithoutCode)
            this.setState({ picturesLoaded: true })
        }
    }

    loadDocuments = (code) => {
        if (code?.replaceAll(' ', '') !== '') {
            this.props.fetchFiles(code, 10).then(() => {
                this.setState({ documentsLoaded: true })
            })
        } else {
            this.setState({ documentsLoaded: true })
        }
    }

    saveFile = () => {
        const { file } = this.state
        const { stationTypes } = this.props
        if (file && this.getFileType(file.content) && hasValue(file.fileType)) {
            const { sandreCodes, exploitation } = this.props
            const { sandreCode } = this.getFileType(file.content)
            const fileType = getSandreList(sandreCodes, sandreCode).find(type => type.code === file.fileType)
            const date = moment().valueOf()
            const codification = exploitation.codification.replaceAll(' ', '')
            const fileFormated = {
                ...file,
                date: file.date || date,
                name: getFormatedFileName(codification, file.active, getFileName(file.name), getFileExtension(file.name), fileType.name, file.date || date, stationTypes[0], false),
            }
            if (file.content.includes('data:image/')) {
                this.setState({ picturesLoaded: false })
                this.props.addStationFile(fileFormated, 'picture').then(() => {
                    this.loadPictures(codification)
                    this.setState({ file: undefined })
                })
            } else {
                this.setState({ documentsLoaded: false })
                this.props.addStationFile(fileFormated, 'doc').then(() => {
                    this.loadDocuments(codification)
                    this.setState({ file: undefined })
                })
            }
        }
        this.onClosePopupAddFile()
    }

    render() {
        const { dataLoaded, tmpFilter, openPopup } = this.state
        return (
            <div className='padding-1'>
                <Card round>
                    <Grid2 container className='padding-1' spacing={1} justifyContent='space-between'>
                        <Grid2 size={4}>
                            <Input
                                title={ i18n.search }
                                value={ tmpFilter.searchValue }
                                onChange={(value) => this.setState({ tmpFilter: { searchValue: value } })}
                                onEnterKeyPress={() => this.setState({ filter: tmpFilter })}
                            />
                        </Grid2>
                        <Grid2 container className='padding-1' justifyContent='flex-end' spacing={1}>
                            <Button variant='outlined' onClick={() => this.setState({ filter: {}, tmpFilter: {} })}>
                                {i18n.reinit}
                            </Button>
                            <Button variant='contained' onClick={() => this.setState({ filter: tmpFilter })}>
                                {i18n.search}
                            </Button>
                        </Grid2>
                    </Grid2>
                </Card>
                <TabList
                    onChangeTab={(v) => this.onChangeTab(v)}
                    tabs={[{
                        value: 'declarations',
                        label: i18n.declarations,
                        icon: 'description',
                    },
                    {
                        value: 'documents',
                        label: i18n.picturesAndDocuments,
                        icon: 'folder',
                    }]}
                >
                    {dataLoaded ? this.getPanel() : <ProgressCard indeterminate withMessage round />}
                </TabList>
                {openPopup && this.getAddFileModal()}
            </div>
        )
    }
}

ExploitationDocumentsApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    fetchContributor: PropTypes.func,
    setTitle: PropTypes.func,
    push: PropTypes.func,
    fetchDeclarationsByExploitationId: PropTypes.func,
    fetchExploitation: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    contributor: PropTypes.instanceOf(ContributorItem),
    declarationsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationWithStats)),
    surveys: PropTypes.arrayOf(PropTypes.instanceOf(DtoSurvey)),
    files: PropTypes.arrayOf(PropTypes.instanceOf(DtoFile)),
    pictures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPicture)),
}

const mapStateToProps = (store) => {
    return {
        exploitation: store.AgriReducer.exploitation,
        declarationsExploitation: store.AgriReducer.declarationsExploitation,
        surveys: store.AgriReducer.surveys,
        contributor: store.ContributorReducer.contributor,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        stationTypes: store.ReferencialReducer.stationTypes,
        pictures: store.StationReducer.pictures,
        files: store.StationReducer.files,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    fetchDeclarationsByExploitationId: AgriAction.fetchDeclarationsByExploitationId,
    fetchContributor: ContributorAction.fetchContributor,
    setTitle: HomeAction.setTitle,
    addStationFile: StationAction.addStationFile,
    deleteFile: StationAction.deleteFile,
    updateFile: StationAction.updateFile,
    fetchPictures: StationAction.fetchPictures,
    fetchFiles: StationAction.fetchFiles,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ExploitationDocumentsApp)
