import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import { SmallStyledFieldSet } from '../../../../components/StyledElements'
import { Grid2, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import StepperDialog from 'components/modal/StepperDialog'
import { CardTable } from 'components/datatable/NewTable'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import { useDispatch } from 'react-redux'
import PerimeterStatus from 'components/PerimeterStatus'
import { camelCase, isNil, keys, lowerFirst, maxBy, orderBy, partition } from 'lodash'
import { ButtonMUI } from 'components/styled/Buttons'
import { darkBlue } from 'utils/constants/ColorTheme'
import StationAction from 'station/actions/StationAction'
import download from 'downloadjs'
import DtoFileFTP from 'station/dto/DtoFileFTP'
import IntegrationAction from 'import/actions/IntegrationAction'
import FileUploadInput from 'import/dto/FileUploadInput'
import RefJobAction from 'domain/job/RefJobAction'
import JobAction from 'import/actions/JobAction'
import { ERROR, SUCCESS } from 'import/constants/JobConstants'
import DtoJobLogLight from 'import/dto/DtoJobLogLight'
import { getFullDate } from 'utils/DateUtil'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import SimpleCircularProgressWithLabel from 'components/progress/SimpleCircularProgressWithLabel'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'

const STEP = {
    SETTINGS: 0,
    STATUS: 1,
    SUCCESS: 2,
}
const IMPORT_STATUS = {
    0: 'orange',
    1: 'red',
}
const JOB_SUCCESS_STATUS = [1, 2, 4]
const IMPORT_PERIMETERS_JOB_ID = -21

const CHECK_UP_IMPORT_ERRORS = ['nullValue', 'line', 'perimeter', 'city', 'parcel', 'remark']

const DownloadModelImport = ({
    model,
    onClose = () => {},
}) => (
    <Grid2 container alignItems='center' rowSpacing={1}>
        <Grid2 size={12}>
            <Typography fontSize='14px' color='mainWhite'>{i18n.downloadModelFileMessage}</Typography>
        </Grid2>
        <Grid2 container size={12} alignItems='center' justifyContent='space-between'>
            <Grid2>
                <ButtonMUI
                    onClick={onClose}
                    variant='outlined'
                    color='mainWhite'
                >
                    {i18n.no}
                </ButtonMUI>
            </Grid2>
            <Grid2>
                <ButtonMUI
                    onClick={() => {
                        download(model)
                        onClose()
                    }}
                    variant='contained'
                    color='mainWhite'
                >
                    {i18n.yes}
                </ButtonMUI>
            </Grid2>
        </Grid2>
    </Grid2>
)

DownloadModelImport.propTypes = {
    onClose: PropTypes.func,
    model: PropTypes.instanceOf(DtoFileFTP),
}

const ImportStep = ({
    onUploadFile = () => {},
}) => {
    const [openModelImportTooltip, setOpenModelImportTooltip] = useState(false)
    const [model, setModel] = useState()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(StationAction.fetchFilesFtp('MODELES/PERITEC', 'PERIMETER_IMPORT_MODEL.xlsx')).then(files => {
            if (files[0]?.url) {
                setModel(files[0]?.url)
            }
        })
    }, [])

    return (
        <SmallStyledFieldSet>
            <Grid2 container direction='column' sx={{ padding: '1rem' }}>
                <Grid2 container columnSpacing={1} alignItems='center'>
                    <Grid2>{i18n.selectExcelFileNotaryFormat}</Grid2>
                    {!!model && (
                        <Grid2>
                            <Tooltip
                                open={openModelImportTooltip}
                                placement='top-start'
                                title={(
                                    <DownloadModelImport
                                        onClose={() => setOpenModelImportTooltip(false)}
                                        model={model}
                                    />
                                )}
                                slotProps={{
                                    tooltip: {
                                        sx: {
                                            padding: '1rem',
                                            color: darkBlue,
                                            maxWidth: '335px',
                                        },
                                    },
                                }}
                            >
                                <InfoRoundedIcon sx={{ cursor: 'pointer' }} onClick={() => setOpenModelImportTooltip(true)} />
                            </Tooltip>
                        </Grid2>
                    )}
                </Grid2>
                <Grid2>
                    <div className='file-field input-field'>
                        <div className='btn'>
                            <span>{i18n.importLabel}</span>
                            <input
                                accept='.xls, .xlsx'
                                type='file'
                                onChange={onUploadFile}
                            />
                        </div>
                        <div className='file-path-wrapper'>
                            <input className='file-path validate' type='text' style={{ margin: 0 }} />
                        </div>
                    </div>
                </Grid2>
            </Grid2>
        </SmallStyledFieldSet>
    )
}

ImportStep.propTypes = {
    onUploadFile: PropTypes.func,
}

const CheckUpStep = ({
    importLogs = [],
    job = {},
}) => {
    const formattedImportErrors = orderBy(importLogs, 'date', 'asc').map(ie => ({
        ...ie,
        nullValue: (
            <PerimeterStatus
                color={IMPORT_STATUS[ie.level]}
            />
        ),
        color: IMPORT_STATUS[ie.level],
        date: getFullDate(ie.date),
    })) || []

    return (
        <Grid2 container justifyContent='center' sx={{ padding: '1rem' }} rowSpacing={4}>
            {isNil(job.statusCode) && (
                <Grid2 container size={12} rowSpacing={2}>
                    <Grid2 container size={12} justifyContent='center'>
                        <Grid2>
                            <SimpleCircularProgressWithLabel />
                        </Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='center'>
                        <Grid2>
                            <Typography fontSize='16px'>{i18n.checkingImportInProgress}</Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
            )}
            {(!isNil(job.statusCode) && !JOB_SUCCESS_STATUS.includes(job.statusCode)) && (
                <Grid2 container size={12} rowSpacing={1}>
                    <Grid2 container size={12} justifyContent='center'>
                        <Grid2>
                            <WarningRoundedIcon sx={{ color: 'orange', fontSize: '5rem' }} />
                        </Grid2>
                    </Grid2>
                    <Grid2 container size={12} justifyContent='center'>
                        <Grid2>
                            <Typography fontSize='16px'>{i18n.fileContainsErrors}</Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
            )}
            <Grid2 size={12}>
                <CardTable
                    title={i18n.importControls}
                    rows={formattedImportErrors}
                    headers={CHECK_UP_IMPORT_ERRORS}
                    rowsPerPageOptions={nbPerPageLabelShort}
                />
            </Grid2>
        </Grid2>
    )
}

CheckUpStep.propTypes = {
    importLogs: PropTypes.arrayOf(PropTypes.shape({})),
    job: PropTypes.shape({}),
}

const ValidateStep = ({
    validateImport,
}) => {
    const [newKeys, updatedKeys] = partition(keys(validateImport), i => i.startsWith('new'))
    return (
        <Grid2 container justifyContent='center' sx={{ padding: '1rem' }} rowSpacing={8}>
            <Grid2 container size={12} rowSpacing={1}>
                <Grid2 container size={12} justifyContent='center'>
                    <Grid2>
                        <CheckCircleRoundedIcon sx={{ color: 'green', fontSize: '5rem' }} />
                    </Grid2>
                </Grid2>
                <Grid2 container size={12} justifyContent='center'>
                    <Grid2>
                        <Typography fontSize='16px'>{i18n.importPerimetersValidateMessage}</Typography>
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2 container size={12} justifyContent='space-between' rowSpacing={2}>
                {newKeys.map(key => {
                    const nb = validateImport[key]
                    const formattedKey = nb > 1 ? key : key.slice(0, -1)
                    return (
                        <Grid2 container key={`Import_de_perimetres_${camelCase(key)}`} size={4} alignItems='center' justifyContent='center' columnSpacing={1}>
                            <Grid2>
                                <AddCircleRoundedIcon sx={{ color: 'green', fontSize: '16px' }} />
                            </Grid2>
                            <Grid2>
                                <Typography fontSize='16px'>{nb} {lowerFirst(i18n[formattedKey])}</Typography>
                            </Grid2>
                        </Grid2>
                    )
                })}
                {updatedKeys.map(key => {
                    const nb = validateImport[key]
                    const formattedKey = nb > 1 ? key : key.slice(0, -1)
                    return (
                        <Grid2 container key={`Import_de_perimetres_${camelCase(key)}`} size={4} alignItems='center' justifyContent='center' columnSpacing={1}>
                            <Grid2>
                                <EditRoundedIcon sx={{ color: 'green', fontSize: '16px' }} />
                            </Grid2>
                            <Grid2>
                                <Typography fontSize='16px'>{nb} {lowerFirst(i18n[formattedKey])}</Typography>
                            </Grid2>
                        </Grid2>
                    )
                })}
            </Grid2>
        </Grid2>
    )
}

ValidateStep.propTypes = {
    validateImport: PropTypes.shape({
        newParcels: PropTypes.number,
        updatedParcels: PropTypes.number,
        newOwnersGroups: PropTypes.number,
        updatedOwnersGroups: PropTypes.number,
        newOwners: PropTypes.number,
        updatedOwners: PropTypes.number,
    }),
}

const PerimetersImportStepper = ({
    folderId,
    open = false,
    setOpen = () => { },
}) => {
    const [fileName, setFileName] = useState()
    const [prevFileContent, setPrevFileContent] = useState()
    const [fileContent, setFileContent] = useState()
    const [importLogs, setImportLogs] = useState([])
    const [validateImport, setValidateImport] = useState()
    const [job, setJob] = useState()

    const dispatch = useDispatch()

    useEffect(() => {
        if (!open) {
            setFileName()
            setPrevFileContent()
            setFileContent()
            setImportLogs([])
            setValidateImport()
            setJob()
        }
    }, [open])

    const onImportFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        reader.onload = upload => {
            setFileName(file.name)
            setFileContent(upload.target.result)
            setImportLogs([])
        }
        reader.readAsDataURL(file)
    }

    const onClose = () => setOpen(false)

    const automaticCallLogs = (nextStep = () => {}) => {
        RefJobAction.fetchJobExecutions(IMPORT_PERIMETERS_JOB_ID).then(res => {
            const jobExecutionId = maxBy(res, 'date').id
            if (jobExecutionId) {
                JobAction.requestJobLogs(-500, jobExecutionId).then(res2 => {
                    const jobLogs = res2.data.map(el => new DtoJobLogLight(el, jobExecutionId))
                    const errorJobLogs = jobLogs.filter(d => d.status === ERROR && d.value?.startsWith('{'))
                    const jobExecution = res.find(e => e.id === jobExecutionId)
                    setJob(jobExecution)
                    setImportLogs(errorJobLogs.map(l => JSON.parse(l.value)))
                    if (jobExecution?.statusCode === undefined) {
                        setTimeout(() => automaticCallLogs(nextStep), 2000)
                    } else if (JOB_SUCCESS_STATUS.includes(jobExecution?.statusCode)) {
                        const findedSuccessLog = jobLogs.find(jl => jl.status === SUCCESS && jl.value?.startsWith('{'))?.value
                        if (findedSuccessLog) {
                            setValidateImport(JSON.parse(findedSuccessLog))
                        }
                        nextStep()
                    }
                })
            }
        })
    }

    return (
        <StepperDialog
            steps={[{
                label: i18n.settings,
                constant: STEP.SETTINGS,
                nextAvailable: !!fileContent,
                onNext: nextStep => {
                    if (fileContent === prevFileContent) {
                        nextStep()
                    } else {
                        const param = { folderId }
                        const splittedFileName = fileName.split('.')
                        const parameters = new FileUploadInput({
                            stationType: 0,
                            stationId: 0,
                            path: 'DATA/PERITEC/',
                            name: splittedFileName?.[1] ? `ImportPerimetresTemp.${splittedFileName[1]}` : fileName,
                            content: fileContent,
                        })

                        dispatch(IntegrationAction.integration(IMPORT_PERIMETERS_JOB_ID, param, parameters, false, undefined, false)).then(() => {
                            setPrevFileContent(fileContent)
                            automaticCallLogs(nextStep)
                        })
                        nextStep()
                    }
                },
            }, {
                label: i18n.status,
                constant: STEP.STATUS,
                nextAvailable: !importLogs?.length,
            }, {
                label: i18n.success,
                constant: STEP.SUCCESS,
            }].filter(s => !!s)}
            open={open}
            title={i18n.importLabel}
            closeDialog={onClose}
            renderSaveButton={step => (step === STEP.SUCCESS) && (
                <ButtonMUI
                    variant='contained'
                    onClick={onClose}
                >
                    {i18n.finish}
                </ButtonMUI>
            )}
        >
            {step => (
                <>
                    {step === STEP.SETTINGS && (
                        <ImportStep
                            onUploadFile={onImportFile}
                        />
                    )}
                    {step === STEP.STATUS && (
                        <CheckUpStep
                            importLogs={importLogs}
                            job={job}
                        />
                    )}
                    {step === STEP.SUCCESS && (
                        <ValidateStep
                            validateImport={validateImport}
                        />
                    )}
                </>
            )}
        </StepperDialog>
    )
}

PerimetersImportStepper.propTypes = {
    folderId: PropTypes.number,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default PerimetersImportStepper