/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { getLabel } from '../../../utils/StoreUtils'
import StationDescriptionTable from './StationDescriptionTable'
import NumberField from 'components/forms/NumberField'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'

const headers = ['order', 'aquifer', 'startDepth', 'endDepth', 'aquiferRoof', 'aquiferFloor']

const CasingDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const {
        aquifers,
    } = useSelector(store => ({
        aquifers: store.AquiferReducer.aquifers,
    }), shallowEqual)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Select
                            value={stateElement.aquiferCode}
                            label={i18n.aquifer}
                            onChange={v => onChangeElement({ aquiferCode: v })}
                            keyValue='code'
                            options={aquifers}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.order}
                            title={i18n.order}
                            onChange={v => onChangeElement({ order: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.startDepth}
                            title={i18n.startDepth}
                            onChange={v => onChangeElement({ startDepth: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.endDepth}
                            title={i18n.endDepth}
                            onChange={v => onChangeElement({ endDepth: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.aquiferRoof}
                            title={i18n.aquiferRoof}
                            onChange={v => onChangeElement({ aquiferRoof: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.aquiferFloor}
                            title={i18n.aquiferFloor}
                            onChange={v => onChangeElement({ aquiferFloor: v })}
                            floatValue
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

CasingDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}


const StationCasingPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        aquifers,
    } = useSelector(store => ({
        aquifers: store.AquiferReducer.aquifers,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_casings'
            formatFunction={c => ({
                ...c,
                aquifer: getLabel(aquifers, c.aquiferCode),
            })}
            titles={{
                title: i18n.casings,
                edit: i18n.editCasing,
                new: i18n.newCasing,
            }}
            headers={headers}
            dialogContent={CasingDialogContent}
        />
    )
}

StationCasingPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default StationCasingPanel