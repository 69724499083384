/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { getFullDate, getHour } from '../../../utils/DateUtil'
import { getLabel } from '../../../utils/StoreUtils'
import NetworkAction from 'referencial/components/network/actions/NetworkAction'
import Select from 'components/forms/Select'
import { Card, CardContent, Grid2 } from '@mui/material'
import Input from 'components/forms/Input'
import { authorizeFilter } from 'utils/HabilitationUtil'
import { onChangeHour } from 'utils/FormUtils'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import StationDescriptionTable from './StationDescriptionTable'

const headers = ['startDate', 'endDate', 'network', 'sandreCode']

const NetworkDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const {
        networks,
        accountUser,
    } = useSelector(store => ({
        networks: store.NetworkReducer.networks,
        accountUser: store.AccountReducer.accountUser,
    }))

    return (
        <Card sx={{ marginBottom: '50px' }}>
            <CardContent>
                <Grid2 container spacing={2}>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            label={i18n.startDate}
                            onChange={v => onChangeElement({ startDate: v })}
                            max={stateElement.endDate}
                            calendarPosition='bottom'
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(stateElement.startDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateElement.endDate }, stateElement.startDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            label={i18n.endDate}
                            onChange={v => onChangeElement({ endDate: v })}
                            min={stateElement.startDate}
                            calendarPosition='right'
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(stateElement.endDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateElement.startDate }, stateElement.endDate)}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Select
                            value={stateElement.idNetwork}
                            label={i18n.network}
                            options={authorizeFilter(networks, accountUser, stateElement.idNetwork)}
                            keyValue='id'
                            onChange={v => onChangeElement({ idNetwork: v })}
                            obligatory
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>

    )
}

NetworkDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const StationNetworkPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        networks,
    } = useSelector(store => ({
        networks: store.NetworkReducer.networks,
    }), shallowEqual)

    useEffect(() => {
        if (!networks.length) {
            dispatch(NetworkAction.fetchNetworks())
        }
    }, [])

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_networks'
            formatFunction={n => {
                return {
                    ...n,
                    sandreCode: (networks.find(ns => ns.code === n.idNetwork) || {}).sandreCode,
                    startDate: getFullDate(n.startDate),
                    endDate: getFullDate(n.endDate),
                    network: getLabel(networks, n.idNetwork),
                }
            }}
            titles={{
                title: i18n.networks,
                edit: i18n.editNetwork,
                new: i18n.newNetwork,
            }}
            headers={headers}
            dialogContent={NetworkDialogContent}
        />
    )
}

StationNetworkPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

export default StationNetworkPanel