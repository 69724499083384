import { round } from '../../../utils/NumberUtil'
import { getOptions } from '../EChartUtils'

class _Band {
    constructor(obj) {
        this.obj = { ...obj, isBand: true }
    }

    getDefaultOptions = () => {
        return {
            name: 'band chart',
            type: 'line',
            connectNulls: true,
            data: [],
            lineStyle: {
                normal: {
                    opacity: 0,
                },
            },
            itemStyle: {
                normal: {
                    color: 'grey',
                },
            },
            z: -1,
        }
    }

    updateObj = (changes) => {
        this.obj = { ...this.obj, ...changes }
    }

    getValues = () => {
        return [this.obj.min.values.map(v => v[1]), this.obj.max.values.map(v => v[1])].flat()
    }

    getJson = (bandCorrection = false) => {
        const { min, max } = this.obj
        const minValues = min.values || min.data
        // const maxValues = this.obj.max
        // this.obj.minValues = null
        // this.obj.maxValues = null


        return [
            (() => { // partie min
                const options = {
                    ...getOptions({ ...this.obj, ...min }, this.getDefaultOptions(), {
                        color: () => (
                            {
                                itemStyle: {
                                    normal: { color: min.color || this.obj.color },
                                },
                            }),
                    }),
                    stack: this.obj.serieId || 'stackedBand',
                }
                if (min.lineStyle) {
                    options.lineStyle = min.lineStyle
                }
                options.data = minValues
                if (bandCorrection) { // #tpa: utilisé sur suivi piézo
                    options.data = options.data.map(d => {
                        if (d.value) {
                            return { ...d, value: [d.value[0], d.value[1] !== null ? round(d.value[1] + 1000) : null, d.value[2], d.value[3]] }
                        }
                        return [d[0], d[1] !== null ? round(d[1] + 1000) : null, d[2], d[3]]
                    })
                }
                return options
            })(),
            (() => { // partie max
                const options = {
                    ...getOptions({ ...this.obj, ...max }, this.getDefaultOptions(), {
                        color: () => (
                            {
                                itemStyle: {
                                    normal: { color: max.color || this.obj.color },
                                },
                                areaStyle: {
                                    normal: { color: this.obj.color, opacity: 0.5 },
                                },
                            }),
                    }),
                    stack: this.obj.serieId || 'stackedBand',
                }
                if (max.lineStyle) {
                    options.lineStyle = max.lineStyle
                }
                options.data = max.values || max.data
                if (bandCorrection) { // #tpa: utilisé sur suivi piézo
                    options.data = options.data.map((d, idx) => {
                        if (d.value) {
                            return { ...d, value: [d.value[0], d.value[1] !== null ? round(d.value[1] - minValues[idx].value[1]) : null, d.value[2], d.value[3]], realValue: d.value[1] }
                        }
                        return [d[0], d[1] !== null ? round(d[1] - minValues[idx][1]) : null, d[2], d[3]]
                    })
                }
                return options
            })(),
        ]
        // const arr = [minValues, minValues]
        // return arr.map((values, index) => {
        //     const options = getOptions(this.obj, this.getDefaultOptions(), {
        //         color: () => (
        //             {
        //                 areaStyle: {
        //                     normal: { color: this.obj.color },
        //                 },
        //                 itemStyle: {
        //                     normal: { color: arr[index].color || this.obj.color },
        //                 },
        //             }),
        //     })
        //     if (values.lineStyle) {
        //         options.lineStyle = values.lineStyle
        //     }
        //     options.data = values.values
        //     if (bandCorrection) { // #tpa: utilisé sur suivi piézo
        //         options.data = options.data.map(d => {
        //             if (d.value) {
        //                 return { ...d, value: [d.value[0], d.value[1] !== null ? round(d.value[1] + 1000) : null, d.value[2], d.value[3]] }
        //             }
        //             return [d[0], d[1] !== null ? round(d[1] + 1000) : null, d[2], d[3]]
        //         })
        //     }
        //     options.name = values.name
        //     options.serieId = values.serieId
        //     if (index === 1 && !arr[index].color) {
        //         options.areaStyle = {
        //             normal: {
        //                 color: '#fff',
        //                 opacity: 1,
        //             },
        //         }
        //     }
        //     return options
        // })
    }
}

export default function Band(args) {
    return new _Band(args)
}
