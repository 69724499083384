import PropTypes from 'prop-types'
import React from 'react'
import UserDto from '../../../administration/components/user/dto/UserDto'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import ProfileCard from '../../card/ProfileCard'
import { NewTable, WrapperAccordionDetails } from '../../datatable/NewTable'
import { Accordion, AccordionDetails, AccordionSummary, Grid2 } from '@mui/material'

const ProfileCardList = ({
    title,
    users,
    onClickTable,
    open,
}) => {
    return (
        <Grid2 paddingBottom={1}>
            <Accordion round defaultExpanded={open}>
                <AccordionSummary round>{title}</AccordionSummary>
                <AccordionDetails style={{ padding: 0 }}>
                    {
                        users.length <= 25 ?
                            users.map((card, i) => (
                                <ProfileCard
                                    key={i}
                                    picture={card.picture}
                                    onClick={card.onClick}
                                >
                                    {card.content}
                                </ProfileCard>)) :
                            <NewTable
                                headers={['login', 'name', 'mail', 'mobile', 'tel', 'admin', 'metadata', 'organism', 'lastConnexion']}
                                rows={users}
                                rowsPerPageOptions={nbPerPageLabel}
                                onClickRow={(user) => onClickTable(user.login)}
                                WrapperComponent={WrapperAccordionDetails}
                            />
                    }
                </AccordionDetails>
            </Accordion>
        </Grid2>
    )
}

ProfileCardList.propTypes = {
    title: PropTypes.string.isRequired,
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    onClickTable: PropTypes.func,
    open: PropTypes.bool,
}
export default ProfileCardList
