import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import Button from '../../../../../components/forms/Button'
import Row from '../../../../../components/react/Row'
import { hasValue } from '../../../../../utils/NumberUtil'
import { MEASURE_COTE } from '../../../../constants/PiezometryConstants'
import { groupBy, orderBy, range, isEqual } from 'lodash'
import moment from 'moment'
import IAEauAction from '../../../../../iaeau/IAEauAction'
import { getColorFromPalette, getColorFromPalette2 } from '../../../../../utils/ColorUtil'
import Line from '../../../../../components/echart/series/Line'
import AppStore from '../../../../../store/AppStore'
import WaitAction from '../../../../../wait/WaitAction'
import { getDate, getMonthList } from '../../../../../utils/DateUtil'
import { MODEL_TYPES } from '../../../../../iaeau/constants/IAEauConstants'
import DtoPredStat from '../../../../../iaeau/dto/DtoPredStat'
import Input from '../../../../../components/forms/Input'
import { getMapSituationCalendar } from '../../../../../station/components/mapSituation/MapSituationUtils'
import Icon from '../../../../../components/icon/Icon'
import Select from '../../../../../components/forms/Select'
import { Card, CardContent, Grid2, Icon as IconMui, Popover } from '@mui/material'
import { ButtonMUI } from '../../../../../components/styled/Buttons'
import { arrayOf, createIndex, instanceOf } from '../../../../../utils/StoreUtils'
import DtoPredMeasure from '../../../../../iaeau/dto/DtoPredMeasure'
import { getI18nOrLabel } from '../../../../../utils/StringUtil'
import MultiBand from '../../../../../components/echart/series/MultiBand'
import ToastrAction from '../../../../../toastr/actions/ToastrAction'
import { MODELS } from '../../constants/PiezometerSuiviConstants'
import { WhiteCard } from '../../../../../components/styled/Card'
import DtoPiezometer from '../../../../dto/DtoPiezometer'
import DtoMeasureStats from '../../../../../station/dto/piezometer/DtoMeasureStats'
import { getSiteUrl } from '../../../../../utils/mapUtils/SiteTypes'
import { STATION_NAME_ASSOCIATION } from '../../../../../station/constants/StationConstants'
import Bar from '../../../../../components/echart/series/Bar'

const toEchartMeasure = (m, displayCote, lastLandmark, measure) => ({ value: [moment(m[0]).hour(12).valueOf(), displayCote === MEASURE_COTE.NGF ? m[1] : lastLandmark - m[1], { NGF: m[1], depth: lastLandmark - m[1], measure }, m[2], m[3]], isPiezo: true })

const getDefaultSeries = (pred, results, displayCote, landmarkValue, idxPred) => {
    const allSeries = groupBy(results, 'serieName')
    const nbLineSeries = Object.keys(allSeries).filter(key => hasValue(allSeries[key][0].value)).length
    const isPluvio = pred.station?.stationType === 'pluviometry' && pred.typeId === 1
    return Object.keys(allSeries).flatMap((serieName, idx) => {
        const orderedMeasures = orderBy(allSeries[serieName], 'date')
        const labelSerie = `${pred.source}${serieName && serieName !== 'undefined' ? ` - ${getI18nOrLabel(serieName)}` : ''}`
        if (hasValue(orderedMeasures[0].doubtMin)) {
            const lower = {
                showSymbol: false,
                color: pred?.model?.color || 'grey',
                data: orderedMeasures.map(m => toEchartMeasure([m.date, m.doubtMin], displayCote, landmarkValue, m)),
                name: labelSerie,
                isArea: true,
            }
            const upper = {
                showSymbol: false,
                color: pred?.model?.color || 'grey',
                data: orderedMeasures.map(m => toEchartMeasure([m.date, m.doubtMax], displayCote, landmarkValue, m)),
                name: labelSerie,
                isArea: true,
            }
            return [MultiBand({ station: pred.station, bands: displayCote === MEASURE_COTE.NGF ? [lower, upper] : [upper, lower], noSort: true, noGap: false, stack: `${pred.source}${idx}` })]
        }
        return (isPluvio ? Bar : Line)({
            data: orderedMeasures.map(m => toEchartMeasure([m.date, m.value], displayCote, landmarkValue, m)),
            name: labelSerie,
            axisName: pred.station.label,
            showSymbol: false,
            isPiezo: true,
            station: pred.station,
            ...(isPluvio ? {
                isPiezo: false,
                isPluvio: true,
                xAxisIndex: 1,
                data: orderedMeasures.map(m => ({ value: [getDate(m.date, 'DD/MM/YYYY'), m.value, 'mm', m], unit: 'mm' })),
                color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || getColorFromPalette2(idxPred)),
            } : {
                color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                connectNulls: true,
                lineStyle: {
                    normal: {
                        color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                        width: pred?.model?.lineWidth || 2,
                        type: pred?.model?.lineType || 'dashed',
                        opacity: pred?.model?.lineOpacity || 1,
                    },
                },
                itemStyle: {
                    normal: {
                        color: nbLineSeries > 1 ? getColorFromPalette(idx) : (pred?.model?.color || 'black'),
                    },
                },
            }),
        })
    })
}

const getSeries = (pred, results, displayCote, landmarkValue, idx) => {
    switch (pred.model?.typeModel) {
        case MODEL_TYPES.HYPE:
            return []
        default:
            return getDefaultSeries(pred, results, displayCote, landmarkValue, idx)
    }
}

const loadModelResults = ({ predStats, modelCheck, modelDate, piezometer, displayCote, landmarkValue, cb }) => {
    const predsToLoad = Object.keys(modelCheck).filter(key => modelCheck[key]).map(key => {
        const [code, stationTypeId, source] = key.split(':')
        return predStats.find(p => p.station.code === code && p.typeId === parseInt(stationTypeId) && p.source === source)
    })
    const promises = predsToLoad.map(pred => IAEauAction.promiseModelMeasures(
        pred.station.stationType,
        pred.station.stationId,
        pred.idModel,
        pred.source,
        modelDate[`${pred.station.code}:${pred.typeId}:${pred.source}`],
        pred.station.stationType === 'pluviometry' && pred.typeId === 1 ? 'SUM' : undefined,
    ))
    AppStore.dispatch(WaitAction.waitStart())
    Promise.all(promises).then(jsonTab => {
        AppStore.dispatch(WaitAction.waitStop())
        const series = jsonTab.flatMap((json, idx) => {
            return getSeries(predsToLoad[idx], json.map(j => new DtoPredMeasure(j)), displayCote, landmarkValue, idx)
        })
        cb(series)
    }).catch(() => AppStore.dispatch(WaitAction.waitStop()))
}

const years = range(moment().year(), 1949, -1).map(y => ({ value: y, label: y }))

const onValidate = ({ modelCheck, predStats, modelDate, stations, changeParent, setModelsSeries, piezometer, displayCote, landmarkValue }) => {
    if (!Object.keys(modelCheck).filter(key => modelCheck[key]).length) {
        changeParent({ modelsSeries: [] })
        setModelsSeries([])
    } else {
        loadModelResults({
            predStats, modelCheck, modelDate, piezometer, displayCote, landmarkValue, stations,
            cb: series => {
                setModelsSeries(series)
                changeParent({ modelsSeries: series })
            },
        })
    }
}

const PiezoSuiviModelTab2 = ({
    tab,
    id,
    piezometerStatistics,
    displayCote,
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent, // met à jour les state du parent (dont les séries liées à cette tab)
    piezometer,
    typeId,
    isPiezo,
    selectedAssociations,
}) => {
    const dispatch = useDispatch()

    const { associatedSites } = useSelector(store => ({
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const [predStats, setPredStats] = useState([])
    const [modelCheck, setModelCheck] = useState({})
    const [modelDate, setModelDate] = useState({})
    const [prevSucc, setPrevSucc] = useState({})
    const [modelCalendarDates, setModelCalendarDates] = useState({})
    const [selectedMonth, setSelectedMonth] = useState({})
    const [selectedYear, setSelectedYear] = useState({})
    const [calendarRefs, setCalendarRefs] = useState({})
    const [calendarOpen, setCalendarOpen] = useState({})
    const [stations, setStations] = useState([])
    const [modelsSeries, setModelsSeries] = useState([])


    useEffect(() => {
        setModelCheck({})
        changeParent({ modelsSeries: [] })
    }, [typeId])

    useEffect(() => {
        // retirer les données de prévision des données associées qui ne sont plus affichées
        const filteredModels = modelsSeries.filter(s => stations.some(st => st.stationId === s.obj.station.stationId && st.stationTypeId === s.obj.station.stationTypeId && st.stationType === s.obj.station.stationType))
        if (filteredModels.length !== modelsSeries.length) {
            setModelsSeries(filteredModels)
            changeParent({ modelsSeries: filteredModels })
        }
    }, [stations])

    useEffect(() => {
        const selectedStat = piezometerStatistics.find(s => s.typeId === typeId)
        // const key = `${station.typeName}:${station.stationLinkedId}:${sStat.typeId}:${sStat.codepoint}:${sStat.label}:${sStat.isPiezo || sStat.typeId === -1 || false}` <-- clé utilisée dans PiezoSuiviAssociationsTab2
        const stationsKeys = [`piezometry:${piezometer.id}:${typeId}:${selectedStat.codepoint}:${selectedStat.label}:${selectedStat.isPiezo || typeId === -1 || false}`, ...selectedAssociations]
        const stationsSelected = stationsKeys.map((key, idx) => {
            const [stationType, stationId, stationTypeId, codepoint, label, stationIsPiezo] = key.split(':')
            return {
                stationType, stationId: parseInt(stationId), stationTypeId: parseInt(stationTypeId), codepoint: parseInt(codepoint), label, stationIsPiezo: stationIsPiezo?.includes('true'),
                code: idx === 0 ? piezometer.code : associatedSites.find(ass => ass.typeName === stationType && ass.stationLinkedId === parseInt(stationId))?.stationLinkedCode,
                name: idx === 0 ? piezometer.name : associatedSites.find(ass => ass.typeName === stationType && ass.stationLinkedId === parseInt(stationId))?.stationLinkedName,
            }
        })

        Promise.all(stationsSelected.map(({ stationId, stationType }) => IAEauAction.promisePredStats(stationType, stationId))).then(jsonTab => {
            const globalObj = stationsSelected.reduce((accStation, station, idx) => {
                const { code, stationTypeId } = station
                const {
                    modelDate: initialModelDate,
                    modelCalendarDates: initialmodelCalendarDates,
                    selectedMonth: initialSelectedMonth,
                    selectedYear: initialSelectedYear,
                    prevSucc: initalPrevSucc,
                    calendarRefs: initialCalendarRefs,
                    newPreds: initialNewPreds,
                } = accStation

                const key = pred => `${code}:${stationTypeId}:${pred.source}`
                const newPreds = jsonTab[idx].map(p => new DtoPredStat(p, station))
                return {
                    ...accStation,
                    newPreds: [...initialNewPreds, ...newPreds],
                    modelDate: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initialModelDate[key(p)] ?? p.maxSimulationDate }), initialModelDate),
                    modelCalendarDates: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initialmodelCalendarDates[key(p)] ?? p.dates }), initialmodelCalendarDates),
                    selectedMonth: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initialSelectedMonth[key(p)] ?? p.maxSimulationDate }), initialSelectedMonth),
                    selectedYear: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initialSelectedYear[key(p)] ?? p.maxSimulationDate }), initialSelectedYear),
                    prevSucc: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initalPrevSucc[key(p)] ?? [p.previousDate, undefined] }), initalPrevSucc),
                    calendarRefs: newPreds.reduce((acc, p) => ({ ...acc, [key(p)]: initialCalendarRefs[key(p)] ?? React.createRef() }), initialCalendarRefs),
                }
            }, { modelDate, modelCalendarDates, selectedMonth, selectedYear, prevSucc, calendarRefs, newPreds: [] })

            setPredStats(globalObj.newPreds)
            setModelDate(globalObj.modelDate)
            setModelCalendarDates(globalObj.modelCalendarDates)
            setSelectedMonth(globalObj.selectedMonth)
            setSelectedYear(globalObj.selectedYear)
            setPrevSucc(globalObj.prevSucc)
            setCalendarRefs(globalObj.calendarRefs)
            setStations(stationsSelected)
        })
    }, [selectedAssociations])

    const reloadModelResultsDates = (newSelectedMonth, newSelectedYear, pred) => {
        IAEauAction.promiseModelResultDates('piezometry', piezometer.id, pred.idModel, pred.source, moment(newSelectedYear).month(moment(newSelectedMonth).month()).valueOf())
            .then(predResultDates => {
                setSelectedMonth({ ...selectedMonth, [pred.source]: newSelectedMonth })
                setSelectedYear({ ...selectedYear, [pred.source]: newSelectedYear })
                setModelCalendarDates({ ...modelCalendarDates, [pred.source]: predResultDates })
            })
    }

    const changePrevSucc = (pred, newDate) => {
        if (newDate) {
            setModelDate({ ...modelDate, [pred.source]: newDate })
            IAEauAction.promiseModelResultPrevSuccDates('piezometry', piezometer.id, pred.idModel, pred.source, newDate).then(res => {
                setPrevSucc({ ...prevSucc, [pred.source]: res }) // [previous, next]
            })
        }
    }

    useEffect(() => {
        // charger une des données de prévisions au chargelement de l'écran, où dès qu'on ajoute une nouvelle donnée complémentaire
        const checkKeys = Object.keys(modelCheck)
        const toLoadByDefault = stations.reduce((acc, st) => {
            const keyStart = `${st.code}:${st.stationTypeId}:`
            if (!checkKeys.some(key => key.startsWith(keyStart))) { // pas encore de donnée de prévision affichée (ou qui a déjà été cochée) pour cette station
                const foundPred = predStats.find(pred => pred.station.stationId === st.stationId && pred.typeId === st.stationTypeId && pred.station.stationType === st.stationType)
                if (foundPred) {
                    return { ...acc, [`${st.code}:${st.stationTypeId}:${foundPred.source}`]: true }
                }
            }
            return acc
        }, modelCheck)
        if (!isEqual(checkKeys, toLoadByDefault)) {
            setModelCheck(toLoadByDefault)
            onValidate({ modelCheck: toLoadByDefault, predStats, modelDate, stations, changeParent, setModelsSeries, piezometer, displayCote, landmarkValue })
        }
    }, [predStats, stations])

    if (tab !== MODELS) {
        return null
    }

    return (
        <WhiteCard title={i18n.prevData} sx={{ width: '100%' }}>
            <div className='padding-left-2 padding-right-1'>
                {
                    stations.map(station => (
                        <Grid2 container justifyContent='center' alignItems='center' spacing={3} className='margin-top-1' key={`${station.code} - ${station.name}`}>
                            <Card sx={{ width: '100%' }} elevation={10}>
                                <CardContent elevation={10}>
                                    <Grid2 container justifyContent='center' alignItems='center' spacing={3}>
                                        <Grid2 size={1}>
                                            <img src={getSiteUrl(STATION_NAME_ASSOCIATION[station.stationType])} style={{ maxHeight: '30px' } } />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <h6>{i18n[station.stationType]}</h6>
                                            <h6>{station.label}</h6>
                                        </Grid2>
                                        <Grid2 size={7}>
                                            <h6>{`${station.code} - ${station.name}`}</h6>
                                        </Grid2>
                                    </Grid2>
                                    <div>
                                        {
                                            predStats.filter(pred => pred.station.code === station.code && pred.typeId === station.stationTypeId).map(pred => {
                                                const key = `${pred.station.code}:${pred.typeId}:${pred.source}`
                                                return (
                                                    <div className='row no-margin padding-bottom-1'>
                                                        <StyledFieldSet>
                                                            <StyledLegend>{`${pred.source} (${pred.horizon} ${i18n[pred.horizonMode || 'days']})`}</StyledLegend>
                                                            <Row className='valign-wrapper'>
                                                                <Checkbox col={1} checked={modelCheck[key]}
                                                                    onChange={v => setModelCheck({ ...modelCheck, [key]: v })}
                                                                />
                                                                <div className='col s1'>
                                                                    <ButtonMUI
                                                                        variant={'outlined'}
                                                                        onClick={() => changePrevSucc(pred, prevSucc[key]?.[0])}
                                                                        style={{
                                                                            border: prevSucc[key]?.[0] ? 'solid rgba(53, 96, 159, 1)' : 'solid grey',
                                                                            borderWidth: 2,
                                                                            fontWeight: 600,
                                                                            paddingLeft: 0,
                                                                            paddingRight: 0,
                                                                            minWidth: 40,
                                                                        }}
                                                                    >
                                                                        <IconMui style={{
                                                                            fontSize: 22,
                                                                            color: prevSucc[key]?.[0] ? 'rgba(53, 96, 159, 1)' : 'grey',
                                                                        }}
                                                                        >keyboard_double_arrow_left</IconMui></ButtonMUI>
                                                                </div>
                                                                <div className='col s3' style={{ paddingLeft: 24 }} ref={calendarRefs[key]}
                                                                    onClick={() => setCalendarOpen({ ...calendarOpen, [key]: true })}
                                                                >
                                                                    <Input title={i18n.date} value={getDate(modelDate[key])}
                                                                        onChange={() => setModelDate({ ...modelDate, [key]: modelDate[key] })}
                                                                    />
                                                                </div>
                                                                <div className='col s1'>
                                                                    <ButtonMUI
                                                                        variant={'outlined'}
                                                                        onClick={() => changePrevSucc(pred, prevSucc[key]?.[1])}
                                                                        style={{
                                                                            border: prevSucc[key]?.[1] ? 'solid rgba(53, 96, 159, 1)' : 'solid grey',
                                                                            borderWidth: 2,
                                                                            fontWeight: 600,
                                                                            paddingLeft: 0,
                                                                            paddingRight: 0,
                                                                            minWidth: 40,
                                                                        }}
                                                                    >
                                                                        <IconMui style={{
                                                                            fontSize: 22,
                                                                            color: prevSucc[key]?.[1] ? 'rgba(53, 96, 159, 1)' : 'grey',
                                                                        }}
                                                                        >keyboard_double_arrow_right</IconMui></ButtonMUI>
                                                                </div>
                                                                <div className='col s6'/>
                                                                <Popover
                                                                    open={calendarOpen[key]}
                                                                    anchorEl={calendarRefs[key]?.current}
                                                                    // anchorOrigin={{
                                                                    //     vertical: 'bottom',
                                                                    //     horizontal: 'left',
                                                                    // }}
                                                                    // transformOrigin={{
                                                                    //     vertical: 'top',
                                                                    //     horizontal: 'left',
                                                                    // }}
                                                                    onClose={() => setCalendarOpen({ ...calendarOpen, [key]: false })}
                                                                >
                                                                    <div style={{ width: 250 }}>
                                                                        <Row className='padding-top-1 padding-left-1 padding-right-1'>
                                                                            <div className='col s1 no-padding'>
                                                                                <Icon style={{ fontSize: 27 }} clickable icon='chevron_left'
                                                                                    onClick={() => {
                                                                                        if (moment(selectedMonth[key]).month() + 1 === 1) {
                                                                                            reloadModelResultsDates(moment().month(11).valueOf(), moment(selectedYear[key]).subtract(1, 'year').valueOf(), pred)
                                                                                        } else {
                                                                                            reloadModelResultsDates(moment(selectedMonth[key]).subtract(1, 'month').valueOf(), selectedYear[key], pred)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className='col s5'>
                                                                                <Select value={moment(selectedMonth[key]).month() + 1}
                                                                                    options={getMonthList()}
                                                                                    onChange={v => reloadModelResultsDates(moment().month(v - 1).valueOf(), selectedYear[key], pred)}
                                                                                    noInputFieldClass noSort
                                                                                />
                                                                            </div>
                                                                            <div className='col s1 no-padding'>
                                                                                <Icon style={{ fontSize: 27 }} clickable icon='chevron_right'
                                                                                    onClick={() => {
                                                                                        if (moment(selectedMonth[key]).month() + 1 === 12) {
                                                                                            reloadModelResultsDates(moment().month(0).valueOf(), moment(selectedYear[key]).add(1, 'year').valueOf(), pred)
                                                                                        } else {
                                                                                            reloadModelResultsDates(moment(selectedMonth[key]).add(1, 'month').valueOf(), selectedYear[key], pred)
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <div className='col s5'>
                                                                                <Select value={moment(selectedYear[key]).year()} options={years}
                                                                                    onChange={v => reloadModelResultsDates(selectedMonth, moment().year(v).valueOf(), pred)}
                                                                                    noInputFieldClass noSort
                                                                                />
                                                                            </div>
                                                                        </Row>
                                                                        <Row>
                                                                            {getMapSituationCalendar(
                                                                                selectedYear[key],
                                                                                selectedMonth[key],
                                                                                modelCalendarDates[key] || [],
                                                                                modelDate[key],
                                                                                v => setModelDate({ ...modelDate, [key]: v }),
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                </Popover>
                                                            </Row>
                                                        </StyledFieldSet>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid2>
                    ))
                }
                {
                    !predStats.length ? (
                        <h6>{i18n.noModelAvailable}</h6>
                    ) : null
                }
            </div>
            <Row className='padding-bottom-1 padding-top-1 center-align'>
                <Button tooltip={i18n.apply}
                    onClick={() => onValidate({ modelCheck, predStats, modelDate, stations, changeParent, setModelsSeries, piezometer, displayCote, landmarkValue })}
                    icon='border_color' className={'btn-floating btn-large'}
                    disabled={!predStats.length}
                />
            </Row>
        </WhiteCard>
    )
}

PiezoSuiviModelTab2.propTypes = {
    piezometer: instanceOf(DtoPiezometer),
    tab: PropTypes.string,
    displayCote: PropTypes.number,
    id: PropTypes.number,
    typeId: PropTypes.number,
    isPiezo: PropTypes.bool,
    selectedAssociations: PropTypes.arrayOf(PropTypes.string), // liste de String qui représente la liste des donnes associées qui sont affichées
    piezometerStatistics: arrayOf(DtoMeasureStats),
}

export default PiezoSuiviModelTab2