import React, { forwardRef, useMemo, useRef, useState } from 'react'
import { Grid2 } from '@mui/material'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Icon from '../icon/Icon'
import { MEASURE_COTE } from '../../piezometry/constants/PiezometryConstants'
import { PIEZOMETRY_COLOR } from '../../utils/constants/ColorTheme'
import ChartOptionsPopover from './ChartOptionsPopover'
import { removeNullKeys } from '../../utils/StoreUtils'
import FilterAltIcon from '@mui/icons-material/FilterAlt'


const getCote = cote => {
    switch (cote) {
        case MEASURE_COTE.DEPTH: return { label: i18n.depthLastlandmark, color: PIEZOMETRY_COLOR.DEPTH }
        case MEASURE_COTE.NGF: return { label: i18n.ngf, color: PIEZOMETRY_COLOR.NGF }
        case MEASURE_COTE.GROUND: return { label: i18n.ground, color: PIEZOMETRY_COLOR.GROUND }
        case MEASURE_COTE.LANDMARK: return { label: i18n.landmarkHistory, color: PIEZOMETRY_COLOR.LANDMARK_HISTORY }
        default: return { label: i18n.depthLastlandmark, color: PIEZOMETRY_COLOR.DEPTH }
    }
}

const ChipLabel = forwardRef(({
    label = '',
    onClick = () => {},
    sx = {},
    isOpen= false,
    filterIcon = false,
}, ref) => (
    <Grid2
        ref={ref}
        onClick={onClick}
        sx={{
            cursor: 'pointer',
            backgroundColor: !isOpen ? 'rgba(53, 96, 159, 1)' : 'white',
            color: !isOpen ? 'white' : 'rgba(53, 96, 159, 1)',
            padding: '0px 9px',
            borderTop: '2px solid rgba(53, 96, 159, 1)',
            borderLeft: '2px solid rgba(53, 96, 159, 1)',
            borderRight: '2px solid rgba(53, 96, 159, 1)',
            borderRadius: isOpen ? '5px 5px 0 0' : '5px',
            ...sx,
        }}
        container
        justifyContent={'flex-start'}
        alignItems={'center'}
    >
        <Grid2 padding={'4px'}>
            <Icon icon={'settings'}/>
        </Grid2>
        { filterIcon && (
            <FilterAltIcon style={ { height: 27, width: 27 } }/>
        ) }
        <Grid2 sx={{ padding: '5px 5px 5px 2px' }}>
            {label}
        </Grid2>
    </Grid2>

))

ChipLabel.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    sx: PropTypes.shape({}),
    isOpen: PropTypes.bool,
    filterIcon: PropTypes.bool,
}


const ChartOptionsChip = (props) => {
    const {
        displayCote = {},
        displayModes = {},
        filterStatusQualif = { qualifications: [1, 2, 3, 4], status: [1, 2, 3, 4] },
        withReferenceLevel = false,
    } = props
    const anchorEl = useRef()
    const [openModal, setOpenModal] = useState(false)

    const displayedLabel = useMemo(() => {
        if (withReferenceLevel) {
            return getCote(displayCote).label
        }
        return Object.keys(removeNullKeys({ ...displayModes, personalizedGroupingValue: undefined })).filter(key => displayModes[key])
            .map(key => key === 'personalizedGrouping' ? i18n.personalized : i18n[key]).join(', ')
    }, [displayCote, withReferenceLevel, displayModes])

    return (
        <Grid2>
            <ChipLabel
                label={displayedLabel}
                onClick={() => setOpenModal(prev => !prev)}
                ref={anchorEl}
                isOpen={openModal}
                sx={{ height: '2.7rem' }}
                filterIcon={filterStatusQualif?.qualifications?.length !== 4 || filterStatusQualif?.status?.length !== 4}
            />
            <ChartOptionsPopover
                openModal={openModal}
                setOpenModal={setOpenModal}
                anchorEl={anchorEl}
                {...props}
            />
        </Grid2>
    )
}

ChartOptionsChip.propTypes = {
    displayCote: PropTypes.number, // type de courbe (piézo uniquement) --> Profondeur ou NGF généralement
    displayModes: PropTypes.shape({
        auto: PropTypes.bool,
        brute: PropTypes.bool,
        average: PropTypes.bool,
        min: PropTypes.bool,
        max: PropTypes.bool,
        suppr: PropTypes.bool,
    }),
    filterStatusQualif: PropTypes.shape({
        qualifications: PropTypes.arrayOf(PropTypes.number),
        status: PropTypes.arrayOf(PropTypes.number),
    }),
    changeDisplayModes: PropTypes.func,
    changeDisplayCote: PropTypes.func,
    changeParent: PropTypes.func, // utilisé pour les composant/Composant (pas fonctionnel)
    changeFilterStatusQualif: PropTypes.func,
    withInitialDisplay: PropTypes.bool, // mesures initiales (validation uniquement)
    withSupprDisplay: PropTypes.bool, // mesures initiales (validation uniquement)
    displayModesUniqChoise: PropTypes.bool, // TODO : à mettre par défaut partout
    hideCustom: PropTypes.bool,
    noSol: PropTypes.bool, // pas de mode sol car pas de repère au sol (piézo uniquement)
    noAuto: PropTypes.bool, // pas de mode auto (plus simple pour l'écran de suivi)
    withReferenceLevel: PropTypes.bool, // niveau de référence (piézo)
    ngfAndDepthOnly: PropTypes.bool,
    displayModesKey: PropTypes.string,
    filterStatusQualifKey: PropTypes.string,
}

export default ChartOptionsChip
export { getCote }