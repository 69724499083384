import React, { forwardRef, useRef, useState } from 'react'
import { Grid, Popover } from '@mui/material'
import PropTypes from 'prop-types'
import Checkbox from '../forms/Checkbox'
import i18n from 'simple-react-i18n'
import Icon from '../icon/Icon'
import { setLocalStorageJson } from '../../utils/FormUtils'
import { ButtonMUI } from '../styled/Buttons'
import { StyledFieldSet, StyledLegend } from '../StyledElements'
import RadioButtons from '../forms/RadioButtons'
import Select from '../forms/Select'
import NumberField from '../forms/NumberField'
import { keys } from 'lodash'
import {
    ALL,
    AUTO,
    HOUR,
    DAY,
    CUMUL_PERSO_MAX,
    CUMUL_PERSO_MIN,
    CUMUL_PERSO_SUM,
    CUMUL_PERSO_AVERAGE, SUM_HOUR, SUM_DAY, SUM_WEEK, SUM_DECADE, SUM_MONTH, SUM_YEAR,
    PERSONALIZED_GROUPING, PLUVIO_TAB_DISPLAY_MODES,
} from '../../pluviometry/constants/PluvioOptionConstant'

const getMode = label => {
    switch (label) {
        case 'sumHour': return i18n.hourTotal
        case 'sumDay': return i18n.dailyTotal
        case 'sumWeek': return i18n.weeklyTotal
        case 'sumDecade': return i18n.decadeTotal
        case 'sumMonth': return i18n.monthlyTotal
        case 'sumYear': return i18n.yearTotal
        case AUTO: return i18n.auto
        case PERSONALIZED_GROUPING: return i18n.personalizedGrouping
        case ALL: default: return i18n.allMeasures
    }
}

const ModeItem = ({ label, checked, onClick, iconInfos }) => (
    <Grid
        container
        item
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
    >
        <Checkbox checked={checked} />
        <span>
            { label } { !!iconInfos && (
                <Icon
                    icon={iconInfos.type}
                    tooltip={iconInfos.toolTip}
                    style={ { fontSize: 18, color: iconInfos.color } }
                />
            ) }
        </span>
    </Grid>
)
ModeItem.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    iconInfos: PropTypes.shape({
        type: PropTypes.string,
        color: PropTypes.string,
        toolTip: PropTypes.string,
    }),
}

const ReferenceLevelItem = ({ label, color, checked, onClick }) => (
    <Grid
        container
        item
        alignItems='center'
        sx={{ cursor: 'pointer' }}
        onClick={onClick}
    >
        <Checkbox checked={checked} />
        <Chip
            label={label}
            color={color}
            style={{ padding: '0px 6px' }}
        />
    </Grid>
)
ReferenceLevelItem.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    checked: PropTypes.bool,
    onClick: PropTypes.func,
}

const ChipLabel = forwardRef(({
    label,
    onClick = () => {},
    sx = {},
    isOpen= false,
}, ref) => (
    <Grid
        ref={ref}
        onClick={onClick}
        sx={{
            cursor: 'pointer',
            backgroundColor: !isOpen ? 'rgba(53, 96, 159, 1)' : 'white',
            color: !isOpen ? 'white' : 'rgba(53, 96, 159, 1)',
            padding: '0px 9px',
            borderTop: '2px solid rgba(53, 96, 159, 1)',
            borderLeft: '2px solid rgba(53, 96, 159, 1)',
            borderRight: '2px solid rgba(53, 96, 159, 1)',
            borderRadius: isOpen ? '5px 5px 0 0' : '5px',
            ...sx,
        }}
        container
        justifyContent={'flex-start'}
        alignItems={'center'}
    >
        <Grid container item alignItems={'center'} justifyContent={'space-between'}>
            <Icon tooltip={ i18n.hydrometricGrouping } icon={'settings'}/>
            <Grid paddingLeft={1}>{getMode(label)}</Grid>
        </Grid>
    </Grid>

))

ChipLabel.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    isOpen: PropTypes.bool,
}

const Chip = ({
    label = '',
    color = '',
    style = {},
}) => (
    <span
        style={{
            cursor: 'pointer',
            backgroundColor: color,
            color: 'white',
            padding: '0.3rem 0.6rem',
            borderRadius: '1rem',
            ...style,
        }}
    >
        {label}
    </span>
)

Chip.propTypes = {
    label: PropTypes.string,
    color: PropTypes.string,
    style: PropTypes.object,
}


const SimpleChartTabsHydro = ({
    displayModes,
    changeParent = {},
    displayModesUniqChoise = false,
}) => {
    const anchorEl = useRef()
    const [openModal, setOpenModal] = useState(false)

    const [optionDayHourSelected, setOptionDayHourSelected] = useState(HOUR)
    const [hoursCumul, setHoursCumul] = useState(1)
    const [optionCumulSelected, setOptionCumulSelected] = useState(CUMUL_PERSO_MAX)
    const [label, setLabel] = useState(keys(displayModes).find(mode => displayModes[mode]))

    const [group, setGroup] = useState(displayModes ?? {
        auto: false,
        all: false,
        sumHour: false,
        sumDay: false,
        sumWeek: false,
        sumDecade: false,
        sumMonth: false,
        sumYear: false,

        personalizedGrouping: false,
        personalizedGroupingValue: '',
    })


    const onSave = () => {
        const day = `${optionCumulSelected}_${hoursCumul * 24}`
        const hour = `${optionCumulSelected}_${hoursCumul}`
        const grp = { ...group, personalizedGroupingValue: optionDayHourSelected === DAY ? day : hour }
        setLabel(keys(group).find(mode => group[mode]))
        setOpenModal(false)
        setLocalStorageJson(PLUVIO_TAB_DISPLAY_MODES, grp)
        changeParent(grp)
    }

    const onChangesMode = (mode) => {
        const defaultGroup = {
            auto: false,
            all: false,
            sumHour: false,
            sumDay: false,
            sumWeek: false,
            sumDecade: false,
            sumMonth: false,
            sumYear: false,
            personalizedGrouping: false,
        }
        switch (mode) {
            case AUTO :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, auto: true })
                    break
                }
                setGroup(prev => !prev.auto ? ({
                    auto: !prev.all,
                    all: prev.all,
                    sumHour: prev.all,
                    sumDay: prev.all,
                    sumWeek: prev.all,
                    sumDecade: prev.all,
                    sumMonth: prev.all,
                    sumYear: prev.all,
                    personalizedGrouping: prev.all,
                }) : {
                    auto: true,
                    all: false,
                    sumHour: false,
                    sumDay: false,
                    sumWeek: false,
                    sumDecade: false,
                    sumMonth: false,
                    sumYear: false,
                    personalizedGrouping: false,
                })
                break
            case ALL :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, all: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    all: !(!prev.all || prev.sumHour || prev.sumDay || prev.sumWeek || prev.sumDecade || prev.sumMonth || prev.sumYear || prev.personalizedGrouping),
                    sumHour: !prev.sumHour,
                }))
                break
            case SUM_HOUR :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, sumHour: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    all: !(!prev.sumHour || prev.sumDay || prev.sumWeek || prev.sumDecade || prev.sumMonth || prev.sumYear || prev.personalizedGrouping),
                    sumHour: !prev.sumHour,
                }))
                break
            case SUM_DAY :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, sumDay: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    all: !(prev.sumHour || !prev.sumDay || prev.sumWeek || prev.sumDecade || prev.sumMonth || prev.sumYear || prev.personalizedGrouping),
                    sumDay: !prev.sumDay,
                }))
                break
            case SUM_WEEK :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, sumWeek: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    all: !(prev.sumHour || prev.sumDay || !prev.sumWeek || prev.sumDecade || prev.sumMonth || prev.sumYear || prev.personalizedGrouping),
                    sumWeek: !prev.sumWeek,
                }))
                break
            case SUM_DECADE :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, sumDecade: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    all: !(prev.sumHour || prev.sumDay || prev.sumWeek || !prev.sumDecade || prev.sumMonth || prev.sumYear || prev.personalizedGrouping),
                    sumDecade: !prev.sumDecade,
                }))
                break
            case SUM_MONTH :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, sumMonth: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    all: !(prev.sumHour || prev.sumDay || prev.sumWeek || prev.sumDecade || !prev.sumMonth || prev.sumYear || prev.personalizedGrouping),
                    sumMonth: !prev.sumMonth,
                }))
                break
            case SUM_YEAR :
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, sumYear: true })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    all: !(prev.sumHour || prev.sumDay || prev.sumWeek || prev.sumDecade || prev.sumMonth || !prev.sumYear || prev.personalizedGrouping),
                    sumYear: !prev.sumYear,
                }))
                break
            case PERSONALIZED_GROUPING :
                const day = `${optionCumulSelected}_${hoursCumul * 24}`
                const hour = `${optionCumulSelected}_${hoursCumul}`
                if (displayModesUniqChoise) {
                    setGroup({ ...defaultGroup, personalizedGrouping: true, personalizedGroupingValue: optionDayHourSelected === DAY ? day : hour })
                    break
                }
                setGroup(prev => ({
                    ...prev,
                    all: !(prev.sumHour || prev.sumDay || prev.sumWeek || prev.sumDecade || prev.sumMonth || prev.sumYear || !prev.personalizedGrouping),
                    personalizedGrouping: !prev.personalizedGrouping,
                    personalizedGroupingValue: optionDayHourSelected === DAY ? day : hour,
                }))
                break
            default:
                break
        }
    }

    const optionDayHour = [
        { value: HOUR, label: i18n.hour },
        { value: DAY, label: i18n.day },
    ]
    const optionCumul = [
        { value: CUMUL_PERSO_MAX, label: i18n.max },
        { value: CUMUL_PERSO_MIN, label: i18n.min },
        { value: CUMUL_PERSO_SUM, label: i18n.sum },
        { value: CUMUL_PERSO_AVERAGE, label: i18n.average },
    ]


    return (
        <Grid>
            <ChipLabel
                label={label}
                onClick={() => setOpenModal(prev => !prev)}
                ref={anchorEl}
                isOpen={openModal}
                sx={{ height: '2.6rem' }}
            />
            <Popover
                open={openModal}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '0 5px 5px 5px',
                        },
                    },
                }}
                onClose={() => setOpenModal(false)}
            >
                <Grid width={300} sx={{ border: '2px solid rgba(53, 96, 159, 1)', padding: '10px' }}>
                    <Grid
                        container
                        direction='column'
                        spacing={0.5}
                    >
                        <Grid fontWeight={'bold'} textAlign={'center'}>
                            {i18n.pluvioTotal}
                        </Grid>
                        <ModeItem
                            label={i18n.auto}
                            color='mainBlack'
                            checked={group.auto}
                            onClick={() => onChangesMode(AUTO)}
                            iconInfos={{ type: 'info', color: 'grey', toolTip: (
                                <p className='no-margin'>{i18n.descriptionCumulAutoPluvio}</p>
                            ) } }
                        />
                        <ModeItem
                            label={i18n.allMeasures}
                            checked={group.all}
                            onClick={() => onChangesMode(ALL)}
                        />
                        <ModeItem
                            label={i18n.hourTotal}
                            checked={group.sumHour}
                            onClick={() => onChangesMode(SUM_HOUR)}
                        />
                        <ModeItem
                            label={i18n.dailyTotal}
                            checked={group.sumDay}
                            onClick={() => onChangesMode(SUM_DAY)}
                        />
                        <ModeItem
                            label={i18n.weeklyTotal}
                            checked={group.sumWeek}
                            onClick={() => onChangesMode(SUM_WEEK)}
                        />
                        <ModeItem
                            label={i18n.decadeTotal}
                            checked={group.sumDecade}
                            onClick={() => onChangesMode(SUM_DECADE)}
                        />
                        <ModeItem
                            label={i18n.monthlyTotal}
                            checked={group.sumMonth}
                            onClick={() => onChangesMode(SUM_MONTH)}
                        />
                        <ModeItem
                            label={i18n.yearTotal}
                            checked={group.sumYear}
                            onClick={() => onChangesMode(SUM_YEAR)}
                        />
                        <ModeItem
                            label={i18n.personalizedGrouping}
                            checked={group.personalizedGrouping}
                            onClick={() => onChangesMode(PERSONALIZED_GROUPING)}
                        />
                        { !!group.personalizedGrouping && (
                            <StyledFieldSet>
                                <StyledLegend>{i18n.pluvioTotalPersonalized}</StyledLegend>
                                <Grid container justifyContent='space-evenly' sx={{ padding: '10px 10px' }}>
                                    <Grid item>
                                        <RadioButtons
                                            colOption={6}
                                            label={i18n.modeDaysHours}
                                            elements={optionDayHour}
                                            selected={optionDayHourSelected}
                                            onChange={setOptionDayHourSelected}
                                            disabled={!group.personalizedGrouping}
                                        />
                                    </Grid>
                                    <Grid container justifyContent='space-evenly' sx={{ padding: '10px 10px' }}>
                                        <Select
                                            label={i18n.modeGrouping}
                                            options={optionCumul}
                                            value={optionCumulSelected}
                                            onChange={setOptionCumulSelected}
                                            disabled={!group.personalizedGrouping}
                                        />
                                        <NumberField
                                            title={i18n.numberHoursDays}
                                            value={hoursCumul}
                                            onChange={setHoursCumul}
                                            min={1}
                                            disabled={!group.personalizedGrouping}
                                        />
                                    </Grid>
                                </Grid>
                            </StyledFieldSet>
                        )}
                        <Grid container item sx={{ cursor: 'pointer' }} >
                            <Grid item xs={12}>
                                <ButtonMUI
                                    fullWidth
                                    variant={'contained'}
                                    onClick={ onSave }
                                >
                                    { i18n.toLoad }
                                </ButtonMUI>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Popover>
        </Grid>
    )
}

SimpleChartTabsHydro.propTypes = {
    displayCote: PropTypes.number,
    displayModes: PropTypes.shape({
        all: PropTypes.bool,
        sumHour: PropTypes.bool,
        sumDay: PropTypes.bool,
        sumWeek: PropTypes.bool,
        sumDecade: PropTypes.bool,
        sumMonth: PropTypes.bool,
        sumYear: PropTypes.bool,
    }),
    changeParent: PropTypes.func,
    displayModesUniqChoise: PropTypes.bool,
}

export default SimpleChartTabsHydro
