import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import SieauAction from '../../../components/sieau/SieauAction'
import Input from '../../../components/forms/Input'
import Checkbox from '../../../components/forms/Checkbox'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import { getUser } from '../../../utils/SettingUtils'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import STEPCivilEngPanel from './components/STEPCivilEngPanel'
import STEPEquipmentsPanel from './components/STEPEquipmentsPanel'
import STEPAssociatedTownsPanel from './components/STEPAssociatedTownsPanel'
import STEPAssociatedIndustriesPanel from './components/STEPAssociatedIndustriesPanel'
import STEPCollectionBasinsPanel from './components/STEPCollectionBasinsPanel'
import NumberField from '../../../components/forms/NumberField'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSTEP from './dto/DtoSTEP'
import QualityAction from '../../../quality/actions/QualityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import InstallationEquipmentsPanel from '../installations/components/InstallationEquipmentsPanel'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import { downloadURI } from '../../../utils/ExportDataUtil'
import {
    getVisitCheckBoxChange,
    getVisitDatePickerChange,
    getVisitSandreSelectChange,
    getVisitSelectChange,
} from '../../../utils/VisitUtils'
import { getDate } from '../../../utils/DateUtil'
import { onChangeDate } from '../../../utils/FormUtils'
import Select from '../../../components/forms/Select'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import { greyBlue } from 'utils/constants/ColorTheme'
import WatermassAction from 'referencial/components/watermass/actions/WatermassAction'
import WatershedAction from 'referencial/components/watershed/actions/WatershedAction'
import STEPRejectPointsPanel from './components/STEPRejectPointsPanel'
import STEPFollowPointsPanel from './components/STEPFollowPointsPanel'
import GenericInstallationDescription from '../installationDefault/GenericInstallationDescription'
import { Card, CardContent, Grid2 } from '@mui/material'
import { isDisplayed } from 'utils/InstallationUtils'
import { CardTitle } from 'components/card/NewCard'

class STEPDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            usedWater: {},
            readMode: true,
            STEP: new DtoSTEP({}),
        }
    }

    componentDidMount = () => {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            this.props.fetchInstallationSTEP(this.props.installation.id).then(() => {
                this.setState({
                    STEP: { ...this.props.installationSTEP, idStation: this.props.installation.id },
                })
            })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationsLight.length) {
            this.props.fetchInstallationsLight()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }

        if (!this.props.watermasses.length) {
            this.props.fetchWatermasses()
        }

        if (!this.props.watersheds.length) {
            this.props.fetchWatersheds()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () =>
            this.props.push('/installation'),
        )
    }

    onSave = (visit) => {
        const { installation, STEP: step } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    step,
                },
            ).then(() => this.setReadOnlyMode())
        }
        return this.props.updateInstallationSTEP(
            installation,
            step,
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, STEP: this.props.installationSTEP })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav(
                'installation',
                this.props.installationTable,
                this.props.installation.id,
                (s) => this.props.push(`/station/installation/${s.id}/description`),
            ),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav(
                'installation',
                this.props.installationTable,
                this.props.installation.id,
                (s) => this.props.push(`/station/installation/${s.id}/description`),
            ),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) =>
        this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangeSTEP = (newObject) => this.setState({ STEP: { ...this.state.STEP, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    render() {
        const { installation, readMode, STEP } = this.state
        const { sandreCodes, watermasses, watersheds, applicationSettings } = this.props
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
            sandreCode: SANDRE.EQUIPEMENTS_TYPE_ASST,
        }

        const paramsSTEP = {
            STEP,
            onChange: this.onChangeSTEP,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <GenericInstallationDescription
                readMode={readMode}
                installation={installation}
                onChangeInstallation={this.onChangeInstallation}
                onChangeVisit={this.onChangeVisit}
                descElements={
                    <>
                        <Grid2 size={6}>
                            <Checkbox
                                checked={STEP.autorisationDecree}
                                label={i18n.autorisationDecree}
                                onChange={(v) => {
                                    this.onChangeSTEP({ autorisationDecree: v })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.autorisationDecree, STEP.autorisationDecree, v))
                                }}
                                disabled={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={STEP.numDecree}
                                title={i18n.numDecree}
                                onChange={(v) => {
                                    this.onChangeSTEP({ numDecree: v })
                                    this.onChangeVisit({ previousValue: STEP.numDecree, newValue: v, field: i18n.numDecree })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={getDate(STEP.autorisationDecreeDate)}
                                title={i18n.autorisationDecreeDate}
                                onChange={v => {
                                    onChangeDate(v, v2 => this.onChangeSTEP({ autorisationDecreeDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.autorisationDecreeDate, STEP.autorisationDecreeDate, v))
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={STEP.decreeDuration}
                                title={i18n.decreeDuration}
                                onChange={(v) => {
                                    this.onChangeSTEP({ decreeDuration: v })
                                    this.onChangeVisit({ previousValue: STEP.decreeDuration, newValue: v, field: i18n.decreeDuration })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={STEP.treatmentCapacity}
                                title={i18n.treatmentCapacity}
                                onChange={(v) => {
                                    this.onChangeSTEP({ treatmentCapacity: v })
                                    this.onChangeVisit({ previousValue: STEP.treatmentCapacity, newValue: v, field: i18n.treatmentCapacity })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={STEP.incomingFlow}
                                title={i18n.incomingFlow}
                                onChange={(v) => {
                                    this.onChangeSTEP({ incomingFlow: v })
                                    this.onChangeVisit({ previousValue: STEP.incomingFlow, newValue: v, field: i18n.incomingFlow })
                                }}
                                floatValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={STEP.nominalCapacityDB05}
                                title={i18n.nominalCapacityDB05}
                                onChange={(v) => {
                                    this.onChangeSTEP({ nominalCapacityDB05: v })
                                    this.onChangeVisit({ previousValue: STEP.nominalCapacityDB05, newValue: v, field: i18n.nominalCapacityDB05 })
                                }}
                                floatValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={STEP.maxCharge}
                                title={i18n.maxCharge}
                                onChange={(v) => {
                                    this.onChangeSTEP({ maxCharge: v })
                                    this.onChangeVisit({ previousValue: STEP.maxCharge, newValue: v, field: i18n.maxCharge })
                                }}
                                floatValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={STEP.receivingMedium}
                                title={i18n.receivingMedium}
                                onChange={(v) => {
                                    this.onChangeSTEP({ receivingMedium: v })
                                    this.onChangeVisit({ previousValue: STEP.receivingMedium, newValue: v, field: i18n.receivingMedium })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={STEP.populationEquivalent}
                                title={i18n.populationEquivalent}
                                onChange={(v) => {
                                    this.onChangeSTEP({ populationEquivalent: v })
                                    this.onChangeVisit({ previousValue: STEP.populationEquivalent, newValue: v, field: i18n.populationEquivalent })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={STEP.sludgeTreatmentSector}
                                label={i18n.sludgeTreatmentSector}
                                options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_FILIERETRAITEMENTEBOUES)}
                                keyvalue='code'
                                onChange={(v) => {
                                    this.onChangeSTEP({ sludgeTreatmentSector: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_FILIERETRAITEMENTEBOUES, i18n.sludgeTreatmentSector, STEP.sludgeTreatmentSector, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={STEP.sludgeDisposalSystem}
                                label={i18n.sludgeDisposalSystem}
                                options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_FILIEREELIMINATIONBOUES)}
                                keyvalue='code'
                                onChange={(v) => {
                                    this.onChangeSTEP({ sludgeDisposalSystem: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_FILIEREELIMINATIONBOUES, i18n.sludgeDisposalSystem, STEP.sludgeDisposalSystem, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={STEP.rejectType}
                                label={i18n.rejectType}
                                options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEREJET)}
                                onChange={(v) => {
                                    this.onChangeSTEP({ rejectType: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEREJET, i18n.rejectType, STEP.rejectType, v))
                                }}
                                integerValue
                                keyvalue='code'
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={STEP.wastewaterTreatmentSector}
                                label={i18n.wastewaterTreatmentSector}
                                keyvalue='code'
                                options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_FILIERETRAITEMENTEAUXUSEES)}
                                onChange={(v) => {
                                    this.onChangeSTEP({ wastewaterTreatmentSector: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_FILIERETRAITEMENTEAUXUSEES, i18n.wastewaterTreatmentSector, STEP.wastewaterTreatmentSector, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.watermass}
                                value={STEP.watermassCode}
                                onChange={v => {
                                    this.onChangeSTEP({ watermassCode: v })
                                    this.onChangeVisit(getVisitSelectChange(watermasses, 'id', i18n.watermass, STEP.watermassCode, v))
                                }}
                                options={watersheds}
                                keyValue='id'
                                stringValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.watershed}
                                value={STEP.watershed}
                                onChange={v => {
                                    this.onChangeSTEP({ watershed: v })
                                    this.onChangeVisit(getVisitSelectChange(watersheds, 'id', i18n.watershed, STEP.watershed, v))
                                }}
                                options={watersheds}
                                keyValue='id'
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={STEP.networkType}
                                label={i18n.networkType}
                                keyvalue='code'
                                options={getSandreList(sandreCodes, SANDRE.STEP_TYPE_RESEAU)}
                                onChange={(v) => {
                                    this.onChangeSTEP({ networkType: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.STEP_TYPE_RESEAU, i18n.networkType, STEP.networkType, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={STEP.agriSpreadingQuantity}
                                title={i18n.agriSpreadingQuantity}
                                onChange={(v) => {
                                    this.onChangeSTEP({ agriSpreadingQuantity: v })
                                    this.onChangeVisit({ previousValue: STEP.agriSpreadingQuantity, newValue: v, field: i18n.agriSpreadingQuantity })
                                }}
                                floatValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                            <STEPRejectPointsPanel {...paramsSTEP} />
                        </Grid2>
                        <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                            <STEPFollowPointsPanel {...paramsSTEP} />
                        </Grid2>
                        {isDisplayed(applicationSettings, 'BASINS', installation.installationType) && (
                            <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                                <STEPCollectionBasinsPanel {...paramsSTEP} />
                            </Grid2>
                        )}
                        <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                            <Card>
                                <CardTitle title={i18n.complEquipements} color={greyBlue} />
                                <CardContent>
                                    <Grid2 container columnSpacing={1}>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={STEP.finishingLagoon}
                                                label={i18n.finishingLagoon}
                                                onChange={(v) => {
                                                    this.onChangeSTEP({ finishingLagoon: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.finishingLagoon, STEP.finishingLagoon, v))
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={STEP.reuseTreatedWastedwater}
                                                label={i18n.reuseTreatedWastedwater}
                                                onChange={(v) => {
                                                    this.onChangeSTEP({ reuseTreatedWastedwater: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.reuseTreatedWastedwater, STEP.reuseTreatedWastedwater, v))
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={STEP.sandCleaningMaterialsIUnit}
                                                label={i18n.sandCleaningMaterialsIUnit}
                                                onChange={(v) => {
                                                    this.onChangeSTEP({ sandCleaningMaterialsIUnit: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.sandCleaningMaterialsIUnit, STEP.sandCleaningMaterialsIUnit, v))
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={STEP.fatsUnit}
                                                label={i18n.fatsUnit}
                                                onChange={(v) => {
                                                    this.onChangeSTEP({ fatsUnit: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.fatsUnit, STEP.fatsUnit, v))
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={STEP.drainMaterialUnit}
                                                label={i18n.drainMaterialUnit}
                                                onChange={(v) => {
                                                    this.onChangeSTEP({ drainMaterialUnit: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.drainMaterialUnit, STEP.drainMaterialUnit, v))
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={STEP.azoteTreatment}
                                                label={i18n.azoteTreatment}
                                                onChange={(v) => {
                                                    this.onChangeSTEP({ azoteTreatment: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.azoteTreatment, STEP.azoteTreatment, v))
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={STEP.phosphorusTreatment}
                                                label={i18n.phosphorusTreatment}
                                                onChange={(v) => {
                                                    this.onChangeSTEP({ phosphorusTreatment: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.phosphorusTreatment, STEP.phosphorusTreatment, v))
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={STEP.disinfection}
                                                label={i18n.disinfection}
                                                onChange={(v) => {
                                                    this.onChangeSTEP({ disinfection: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.disinfection, STEP.disinfection, v))
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox
                                                checked={STEP.spreadingPlan}
                                                label={i18n.spreadingPlan}
                                                onChange={(v) => {
                                                    this.onChangeSTEP({ spreadingPlan: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.spreadingPlan, STEP.spreadingPlan, v))
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                        {isDisplayed(applicationSettings, 'LIFTING_EQUIPMENTS', installation.installationType) && (
                            <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                                <Card>
                                    <CardTitle title={i18n.civilEngineering} color={greyBlue} />
                                    <CardContent>
                                        <Grid2 container columnSpacing={1}>
                                            <STEPCivilEngPanel {...paramsSTEP} />
                                            <Grid2 size={12}>
                                                <BuildingsPanel {...params} />
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        )}
                        {isDisplayed(applicationSettings, 'LIFTING_EQUIPMENTS', installation.installationType) && (
                            <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                                <Card>
                                    <CardTitle title={i18n.equipments} color={greyBlue} />
                                    <CardContent>
                                        <Grid2 container columnSpacing={1}>
                                            <STEPEquipmentsPanel {...paramsSTEP} />
                                            <Grid2 size={12}>
                                                <InstallationEquipmentsPanel {...params} />
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        )}
                        {isDisplayed(applicationSettings, 'STAFF_SAFETY', installation.installationType) && (
                            <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                                <STEPAssociatedTownsPanel {...paramsSTEP} />
                            </Grid2>
                        )}
                        {isDisplayed(applicationSettings, 'STAFF_SAFETY', installation.installationType) && (
                            <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                                <STEPAssociatedIndustriesPanel {...paramsSTEP} />
                            </Grid2>
                        )}
                    </>
                }
            />

        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM &&
            nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)
        ) {
            return false
        }
        return true
    }
}

STEPDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    installationSTEP: store.InstallationReducer.installationSTEP,
    installationVisits: store.InstallationReducer.installationVisits,
    installationsLight: store.InstallationReducer.installationsLight,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    watermasses: store.WatermassReducer.watermasses,
    watersheds: store.WatershedReducer.watersheds,
})

const mapDispatchToProps = {
    push,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationSTEP: InstallationAction.updateInstallationSTEP,
    fetchInstallationSTEP: InstallationAction.fetchInstallationSTEP,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    fetchInstallationsLight: InstallationAction.fetchInstallationsLight,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
    fetchWatermasses: WatermassAction.fetchWatermasses,
    fetchWatersheds: WatershedAction.fetchWatersheds,
}

export default connect(mapStateToProps, mapDispatchToProps)(STEPDescriptionPanel)
