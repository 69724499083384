import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import NumberField from '../../../../components/forms/NumberField'
import DtoCaptureStation from '../../../../installation/components/capture/dto/DtoCaptureStation'
import { getExport } from '../../../../utils/linkUtils'
import { orderBy, sum } from 'lodash'
import { push } from '@lagunovsky/redux-react-router'
import { CardTable } from 'components/datatable/NewTable'
import { Grid2 } from '@mui/material'
import { greyBlue } from 'utils/constants/ColorTheme'

const CaptureDownstreamDistribUnitPanel = ({
    station = {},
}) => {
    const {
        captureDownstreamDistribUnits,
        distributionUnits,
        associatedSites,
    } = useSelector(store => ({
        captureDownstreamDistribUnits: store.DistributionUnitReducer.captureDownstreamDistribUnits,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)
    const stationDownstreamUnits = captureDownstreamDistribUnits.filter(cddu => cddu.idStation === station.id)
    const tableDataDownstream = orderBy(stationDownstreamUnits.map(d => {
        const distribUnit = distributionUnits.find(du => du.id === d.downstreamIdStation) || {}
        return {
            ...distribUnit,
            downstreamLevel: d.downstreamLevel,
            decreePopulation: distribUnit.decreePopulation || 0,
            population: distribUnit.decreePopulation,
        }
    }), ['downstreamLevel', 'decreePopulation'], ['asc', 'desc'])

    const stationAssociated = associatedSites.filter(as => as.stationLinkedType === 6)
    const tableDataAssociated = orderBy(stationAssociated.map(d => {
        const distribUnit = distributionUnits.find(du => du.code === d.stationLinkedCode) || {}
        return {
            ...distribUnit,
            downstreamLevel: d.downstreamLevel,
            decreePopulation: distribUnit.decreePopulation || 0,
            population: distribUnit.decreePopulation,
        }
    }), ['downstreamLevel', 'decreePopulation'], ['asc', 'desc'])

    const headersAssociated = ['code', 'name', 'population']
    const headersDownstream = ['code', 'name', 'population', 'downstreamLevel']
    const exportActionAssociated = getExport(tableDataDownstream, i18n.downstreamUDI, headersAssociated)
    const exportActionDownstream = getExport(tableDataDownstream, i18n.downstreamUDI, headersDownstream)

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={6}>
                <NumberField
                    title={i18n.population}
                    value={`${sum([...tableDataDownstream.map(d => d.decreePopulation), ...tableDataAssociated.map(d => d.decreePopulation)]) || '0'} habs`}
                    readMode
                />
            </Grid2>
            <Grid2 size={12}>
                <CardTable
                    title={i18n.directUDI}
                    color={greyBlue}
                    rows={tableDataAssociated}
                    headers={headersAssociated}
                    actions={[exportActionAssociated]}
                    onClickRow={udi => push(`station/distributionUnit/${udi.id}`)}
                />
            </Grid2>
            <Grid2 size={12}>
                <CardTable
                    title={i18n.downstreamUDI}
                    condensed
                    color={greyBlue}
                    rows={tableDataDownstream}
                    headers={headersDownstream}
                    actions={[exportActionDownstream]}
                    onClickRow={udi => push(`station/distributionUnit/${udi.id}`)}
                />
            </Grid2>
        </Grid2>

    )
}

CaptureDownstreamDistribUnitPanel.propTypes = {
    station: PropTypes.instanceOf(DtoCaptureStation),
}

export default CaptureDownstreamDistribUnitPanel