/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import DtoInstallationBorehole from '../../../../dto/borehole/DtoInstallationBorehole'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import { Card, CardContent, Grid2 } from '@mui/material'
import NumberField from 'components/forms/NumberField'
import SimpleTextArea from 'components/forms/SimpleTextArea'

const headers = ['order', 'comment', 'depth']

const CommentDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.order}
                            title={i18n.order}
                            onChange={v => onChangeElement({ order: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.depth}
                            title={i18n.depth}
                            onChange={v => onChangeElement({ depth: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            value={stateElement.comment}
                            title={i18n.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

CommentDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const BoreholeCommentTable = ({
    borehole = {},
    readMode = false,
    onChange = () => { },
}) => {
    return (
        <StationDescriptionTable
            station={borehole}
            readMode={readMode}
            onChange={onChange}
            keyList='link_boreholeComments'
            titles={{
                title: i18n.comments,
                edit: i18n.comments,
                new: i18n.new,
            }}
            headers={headers}
            dialogContent={CommentDialogContent}
        />
    )
}

BoreholeCommentTable.propTypes = {
    borehole: PropTypes.instanceOf(DtoInstallationBorehole),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default BoreholeCommentTable