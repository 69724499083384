import { Grid } from '@mui/material'
import Icon from '../../../components/icon/Icon'
import i18n from 'simple-react-i18n'
import React, { useState } from 'react'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import DtoQuantifications from './dto/DtoQuantifications'
import { arrayOf, getObjectLabel } from '../../../utils/StoreUtils'
import Bar from '../../../components/echart/series/Bar'
import { round, orderBy } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { substringText } from '../../../utils/StringUtil'
import { SuperParameterGraphModal } from '../qualityComponents/ParameterGraph'
import useBoolean from '../../../utils/customHook/useBoolean'


const NewMoleculeDetectedChart = ({
    quantifications,
}) => {
    const {
        parametersIndex,
        qualitometer,
    } = useSelector(store => ({
        parametersIndex: store.ParameterReducer.parametersIndex,
        qualitometer: store.QualityReducer.qualitometer,
    }), shallowEqual)

    const [parameter, setParameter] = useState()
    const {
        value: isGraphOpen,
        setTrue: openGraph,
        setFalse: closeGraph,
    } = useBoolean(false)


    if (!quantifications.length) {
        return (
            <Grid className='text-align-center' style={{ fontSize: 14.3, color: 'black', verticalAlign: 'middle' }}>
                <Icon style={{ fontSize: 50 }}>cloud_off</Icon>
                <Grid>{i18n.noResults}</Grid>
            </Grid>
        )
    }
    const data = orderBy(quantifications.filter(q => q.quantifications).map(q => {
        return {
            value: round(q.quantifications * 100 / q.total),
            nbQuantifications: q.quantifications,
            nbResearch: q.total,
            code: q.parameter,
            parameter: parametersIndex[q.parameter],
        }
    }), 'value', 'asc')

    const options = {
        series: [Bar({
            data,
            name: 'Nb quantification',
        })],
        xAxis: [Axis({ type: 'value', showSplitLine: true, name: i18n.percentOfQuantifications, nameLocation: 'end', max: 100 })],
        yAxis: [Axis({ data: data.map(d => getObjectLabel(d.parameter, 'shortLabel')), axisLabel: { formatter: name => substringText(name, 30) } })],
        height: (30 * data.length) < 250 ? 250 : (30 * data.length),
        gridHeight: (20 * data.length) < 220 ? 220 : (20 * data.length),
        tooltip: {
            formatter: (params) => {
                return `${params.data.parameter.name} [ ${params.data.parameter.code} ]<br />${params.value} ${i18n.percentOfQuantifications} <br />
                                    ${params.data.nbQuantifications} ${i18n.quantifications} <br/> ${params.data.nbResearch} ${i18n.research}`
            },
        },
        grid: {
            top: '1%',
            left: '3%',
            right: '4%',
            containLabel: true,
        },
    }
    return (
        <div>
            <EChart
                options={options}
                onClick={v => {
                    setParameter(v.data.code.toString())
                    openGraph()
                }}
                lazyUpdate={false}
            />
            <SuperParameterGraphModal
                isOpen={isGraphOpen}
                closeGraph={closeGraph}
                parameter={parameter}
                qualitometer={qualitometer.id}
                thresholds={[]}
            />
        </div>
    )
}

NewMoleculeDetectedChart.propTypes = {
    quantifications: arrayOf(DtoQuantifications),
}

export default NewMoleculeDetectedChart