import React, { Component } from 'react'
import { every, round } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { hasValue } from '../../utils/NumberUtil'
import PropTypes from 'prop-types'
import { isEqualBy, sieauTooltip } from '../../utils/FormUtils'
import { searchAllCharacters } from '../../utils/StringUtil'
import i18n from 'simple-react-i18n'
import { Icon } from '@mui/material'

class RadioButtons extends Component {
    constructor(props) {
        super(props)
        this.id = props.id || searchAllCharacters(props.title + uuidv4()).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle')
    }

    getOption = (code, name, colOption, selected = false, index, helpTooltip, disabledOption) => {
        const { disabled: disabledProps } = this.props
        const isDisabled = disabledProps || disabledOption
        const disabled = { disabled: isDisabled }
        return (
            <div
                className={`col s${colOption}`}
                onClick={!isDisabled ? (e) => {
                    e.preventDefault()
                    this.callOnChange(code)
                } : () => {}}
                key={this.id + code}
                data-cy={`radio_button_${index}`}
                style={{ display: 'flex', alignItems: 'center' }}
            >
                {/* empty onChange to correct the warning */}
                <input className='sieau-input' type='radio' id={this.id + code} checked={selected} onChange={() => { }} {...disabled} />
                <label htmlFor={this.id + code} className={isDisabled ? '' : 'black-text'}>{name}</label>
                {helpTooltip && <Icon className='margin-left-1' {...sieauTooltip(helpTooltip, null)}>help_outlined</Icon>}
            </div>
        )
    }

    callOnChange = (val) => {
        const { disabled, keyObj } = this.props
        if (disabled) {
            return
        }
        if (keyObj && this.props.changeObj) {
            this.props.changeObj({ [keyObj]: val, LAST_FORM: 'RADIO' })
        }
        if (this.props.onChange) {
            this.props.onChange(val)
        }
    }

    getOptions = () => {
        const { selected, elements, colOption, activeOneBelowOther } = this.props
        const firstElement = !selected || selected == -1 ? { ...elements[0], selected: true } : elements[0]
        const elementsFormatted = [firstElement, ...elements.slice(1)]
        const col = colOption || (!activeOneBelowOther ? round(12 / elements.length) : 7)
        if (every(elementsFormatted, e => hasValue(e.code) && (e.name || e.label || e.label === ''))) {
            return elementsFormatted.map((e, i) => this.getOption(e.code, e.name || e.label, col, e.code == selected, i, e.helpTooltip, e.disabled))
        }
        if (every(elementsFormatted, e => hasValue(e.id) && (e.name || e.label || e.label === ''))) {
            return elementsFormatted.map((e, i) => this.getOption(e.id, e.name || e.label, col, e.id == selected, i, e.helpTooltip, e.disabled))
        }
        if (every(elementsFormatted, e => hasValue(e.value) && (e.label || e.label === ''))) {
            return elementsFormatted.map((e, i) => this.getOption(e.value, e.name || e.label, col, e.value == selected, i, e.helpTooltip, e.disabled))
        }
        return []
    }

    getSelect = () => {
        const { disabled, col, className, title, obligatory } = this.props
        return (
            <div className={`no-margin-top ${col ? '' : className}`} >
                {title !== '' && (
                    <div className='input-field' >
                        <label className='radioButtons-label sieau-input' disabled={disabled}>{title}{obligatory ? (<span className='primary-color-text'> { i18n.obligatoryField }</span>) : ''}</label>
                    </div>
                )}
                <div className='row no-margin' >
                    <form action='#' style={{ marginBottom: 0 }}>
                        {this.getOptions()}
                    </form>
                </div>
            </div>
        )
    }

    render() {
        const { col, className } = this.props
        return col ? (
            <div className={`relative col s${col} ${className}`} id='radioButtons' >
                {this.getSelect()}
            </div>
        ) : this.getSelect()
    }

    shouldComponentUpdate(nextProps) {
        const { elements, freezeOpti } = this.props
        if (elements.length !== nextProps.elements.length) {
            return true
        }
        if (freezeOpti && isEqualBy(this.props, nextProps, ['selected', 'col', 'disabled', 'readMode'])) {
            return false
        }
        return true
    }
}

RadioButtons.propTypes = {
    elements: PropTypes.arrayOf(PropTypes.object).isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    col: PropTypes.number,
    colOption: PropTypes.number,
    disabled: PropTypes.bool,
    keyObj: PropTypes.string,
    changeObj: PropTypes.func,
    freezeOpti: PropTypes.bool,
    activeOneBelowOther: PropTypes.bool,
    id: PropTypes.string,
    obligatory: PropTypes.bool,
}

RadioButtons.defaultProps = {
    title: '',
    className: '',
    activeOneBelowOther: false,
}

export default RadioButtons
