import { groupBy, isNil, isUndefined, maxBy, orderBy, round } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { findApproximateValue } from 'station/components/suivipc/qualitometer/utils/SuiviPCUltils'
import { getLabel, getLabelTruncate } from 'utils/StoreUtils'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import { getDate } from 'utils/DateUtil'
import useListIndexed from 'utils/customHook/useListIndexed'
import i18n from 'simple-react-i18n'
import { exportModelFile, formatData, getModelFileType } from 'utils/ExportDataUtil'
import ExportAction from 'export/actions/ExportAction'
import ExportFileModal from 'components/modal/ExportFileModal'
import moment from 'moment'
import { ALIGN, NewTable } from 'components/datatable/NewTable'
import { THRESHOLD_COLOR } from 'utils/AnalyseUtils'

const ExportModal = ({
    analysis = [],
    operations = [],

    isExportModalOpen = false,
    closeExportModal = () => {},

    filter = {},
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
        parameters,
        parameterGroupUsage,
        units,
        supports,
        contributors,
        remarks,
        qualifications,
        status,
        qualitometer,
        typeEnvironmentModels,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        parameters: store.ParameterReducer.parameters,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        units: store.UnitReducer.units,
        supports: store.SupportReducer.supports,
        contributors: store.ContributorReducer.contributors,
        remarks: store.OperationReducer.remarks,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        qualitometer: store.QualityReducer.qualitometer,
        typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    }), shallowEqual)

    const qualitometersIndex = useListIndexed(qualitometers, 'id')

    const groupFunction = useCallback(o => `${o.qualitometer}#${o.id}`, [])
    const operationsIndex = useListIndexed(operations, groupFunction)

    const formatExportData = (qualitometerAnalysis) => {
        const exportData = qualitometerAnalysis.map(a => {
            const { groupCode = '', groupName = '' } = parameterGroupUsage.find(p => p.parameter === a.parameter) || {}
            const operation = operationsIndex[`${a.qualitometer}#${a.operation}`]
            return {
                date: { value: a.sampleDate && getDate(a.sampleDate) || '', format: 'dd/MM/yyyy', cellType: 'date' },
                codeGroup: { value: groupCode, cellType: 'right' },
                group: { value: groupName },
                codeParameter: { value: a.parameter, format: '0', cellType: 'number' },
                parameter: getLabel(parameters, a.parameter),
                codeUnit: { value: a.unit, cellType: 'right' },
                unit: getLabel(units, a.unit, 'symbol'),
                result: { value: a.value.replace('.', ','), color: a.color, cellType: 'right' },
                levelThreshold: { value: a.thresholdIndice, format: '0', cellType: 'number' },
                valueThreshold: { value: a.thresholdValue, format: '0.000', cellType: 'number' },
                statusCode: { value: a.status, format: '0', cellType: 'number' },
                status: getLabel(status, a.status),
                qualificationCode: { value: a.qualification, format: '0', cellType: 'number' },
                qualification: getLabel(qualifications, a.qualification),
                remarkCode: { value: a.remarkCode, format: '0', cellType: 'number' },
                remark: getLabel(remarks, a.remarkCode),
                supportCode: { value: a.support, format: '0', cellType: 'number' },
                support: getLabel(supports, a.support),
                comment: a.comment,
                producer: { value: !isUndefined(operation?.producer) ? getLabel(contributors, operation?.producer, 'mnemonique') : '' },
                sampler: { value: !isUndefined(operation?.sampler) ? getLabel(contributors, operation?.sampler, 'mnemonique') : '' },
                laboratory: { value: getLabel(contributors, a.labo, 'mnemonique') },
                quantificationLimit: { value: !isUndefined(a.lq) ? a.lq : (a.value.includes('<') ? a.value.replace('<', '') : ''), cellType: 'number', format: '0.000' },
            }
        })
        return exportData.length ? [{ ...exportData[0], headers: Object.keys(exportData[0]) }, ...exportData.slice(1)] : []
    }

    const getExport = type => {
        const groupAnalysis = groupBy(analysis, 'qualitometer')
        Object.keys(groupAnalysis).map(key => {
            const qualitometerAnalysis = groupAnalysis[key]
            const exportData = formatExportData(qualitometerAnalysis)
            const code = qualitometersIndex[key]?.code
            dispatch(ExportAction.export(formatData(exportData), type, `${code || ''} - ${i18n.followUpPC}`))
        })
    }

    const tableExport = [{
        name: i18n.resultsTable,
        formats: [{
            type: i18n.excelFile,
            callback: () => getExport('xlsx'),
        }, {
            type: i18n.csvFile,
            callback: () => getExport('csv'),
        }],
    }]

    const exportModel = typeEnvironmentModels.map((model) => {
        const fileNameSplit = model.split('.')
        const name = fileNameSplit.slice(0, -1).join('')
        const ext = fileNameSplit[fileNameSplit.length - 1]
        return {
            name,
            formats: [{
                type: getModelFileType(ext),
                callback: () => exportModelFile({
                    stationId: qualitometer.id.toString(),
                    stationCode: qualitometer.code,
                    stationType: qualitometer.typeName,
                    environmentModels: typeEnvironmentModels,
                    filenameModelExport: model,
                    qualityFilter: {
                        stations: [qualitometer.id],
                        startDate: filter.startDate,
                        endDate: filter.endDate,
                        parameters: filter.parameters,
                        selectionCode: filter.selection,
                        groupCode: filter.group,
                        threshold: filter.threshold,

                        producers: filter.producers,
                        laboratories: filter.laboratories,
                        point: filter.point,
                        campaign: filter.campaign,
                        support: isNil(filter.support) ? undefined : `${filter.support}`,
                        fraction: filter.fraction,
                        status: filter.status,
                        qualification: filter.qualification,

                        quantificationControl: filter.quantificationControl,
                        detectionControl: filter.detectionControl,
                    },
                }),
            }],
        }
    })

    return (
        <ExportFileModal
            open={isExportModalOpen}
            onClose={closeExportModal}
            data={[...tableExport, ...exportModel]}
        />
    )
}

ExportModal.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* DtoAnalysisLight + calculateThresholdResult */ })),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),

    isExportModalOpen: PropTypes.bool,
    closeExportModal: PropTypes.func,

    filter: PropTypes.shape({}),
}

const PcMonitoringListTable = ({
    analysis = [],
    operations = [],

    hydroDatas = [],
    piezoDatas = [],
    pluvioDatas = [],

    displayAssociatedStations = false,

    isExportModalOpen = false,
    closeExportModal = () => {},

    filter = {},
}) => {
    const {
        hydrometricStations,
        qualitometers,
        pluviometers,
        piezometers,
        parameters,
        parameterGroupUsage,
        units,
        supports,
        contributors,
        remarks,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        qualitometers: store.QualityReducer.qualitometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        piezometers: store.PiezometryReducer.piezometersLight,
        parameters: store.ParameterReducer.parameters,
        parameterGroupUsage: store.ParameterReducer.parameterGroupUsage,
        units: store.UnitReducer.units,
        supports: store.SupportReducer.supports,
        contributors: store.ContributorReducer.contributors,
        remarks: store.OperationReducer.remarks,
    }), shallowEqual)

    const shouldCalculateFlow = hydroDatas.some(({ calculateFlux }) => calculateFlux)
    const hydroDatasFormatted = useMemo(() => {
        return hydroDatas.filter(({ id, type }) => !isUndefined(id) && !isUndefined(type)).map(({ id, calculateFlux, coeffFlux = 1, measures = [] }) => {
            const { code } = hydrometricStations.find(p => p.id == id)
            const measuresWithValue = measures.filter(v => !isUndefined(v.value))
            return {
                header: code,
                calculateFlux,
                coeffFlux,
                measures: groupBy(measuresWithValue, ({ date }) => getDate(date)),
            }
        })
    }, [hydrometricStations, hydroDatas])

    const pluvioDatasFormatted = useMemo(() => {
        return pluvioDatas.filter(({ id, type }) => !isUndefined(id) && !isUndefined(type)).map(({ id, offset = 0, measures = [] }) => {
            const { code } = pluviometers.find(p => p.id == id)
            return {
                header: code,
                measures: groupBy(measures, m => getDate(moment(m.date).add(offset, 'day').valueOf())),
            }
        })
    }, [pluvioDatas, pluviometers])

    const piezoDatasFormatted = useMemo(() => {
        return piezoDatas.filter(({ id, type }) => !isUndefined(id) && !isUndefined(type)).map(({ id, measures = [] }) => {
            const { code } = piezometers.find(p => p.id == id)
            return {
                header: code,
                measures: groupBy(measures, ({ date }) => getDate(date)),
            }
        })
    }, [piezoDatas, piezometers])

    const groupFunction = useCallback(o => `${o.qualitometer}#${o.id}`, [])
    const operationsIndex = useListIndexed(operations, groupFunction)

    const data = useMemo(() => {
        return orderBy(analysis, ['sampleDate', 'qualitometer'], ['desc', 'asc']).map(a => {
            const date = !isUndefined(a.sampleDate) ? getDate(a.sampleDate) : ''
            const piezo = piezoDatasFormatted.reduce((acc, { measures = [], header }) => {
                const [, color, measure] = findApproximateValue(date, measures)
                const value = measure ? maxBy(measure, 'value').value : ''
                acc[header] = { value, color: THRESHOLD_COLOR[color] ?? color, align: ALIGN.RIGHT }
                return acc
            }, {})
            const hydro = hydroDatasFormatted.reduce((acc, { measures = [], header }) => {
                const [, color, measure] = findApproximateValue(date, measures)
                const value = measure ? maxBy(measure, 'value').value : ''
                acc[header] = { value, color: THRESHOLD_COLOR[color] ?? color, align: ALIGN.RIGHT }
                return acc
            }, {})
            const pluvio = pluvioDatasFormatted.reduce((acc, { measures = [], header }) => {
                const [, color, measure] = findApproximateValue(date, measures)
                const value = measure ? maxBy(measure, 'value')?.value : ''
                acc[header] = { value, color: THRESHOLD_COLOR[color] ?? color, align: ALIGN.RIGHT }
                return acc
            }, {})
            const dataFlux = hydroDatasFormatted.reduce((sumFlow, { measures = [], calculateFlux, coeffFlux = 1 }) => {
                if (calculateFlux) {
                    const [, , measure] = findApproximateValue(date, measures)
                    const value = measure ? maxBy(measure, 'value').value : ''
                    return measure ? sumFlow + value * coeffFlux : sumFlow
                }
                return sumFlow
            }, 0)
            const flux = shouldCalculateFlow ? {
                value: round(a.result * dataFlux, 5), align: ALIGN.RIGHT,
            } : undefined
            const operation = operationsIndex[`${a.qualitometer}#${a.operation}`]
            const group = parameterGroupUsage.find(p => p.parameter === a.parameter)
            return {
                station: { value: qualitometers.find(({ id }) => id === a.qualitometer)?.code },
                date: { value: date },
                parameter: { value: getLabel(parameters, a.parameter, 'labelWithCode') },
                group: {
                    value: getLabelTruncate(group?.groupName, group?.groupCode, 15),
                    tooltip: group?.labelWithCode,
                },
                unit: { value: getLabel(units, a.unit, 'symbol') + (!isUndefined(a.unit) ? ` [${a.unit}]` : '') },
                result: { value: a.value, color: THRESHOLD_COLOR[a.color], align: ALIGN.RIGHT },
                flux,
                ...hydro,
                ...piezo,
                ...pluvio,
                remark: { value: getLabel(remarks, a.remark) },
                support: { value: getLabel(supports, a.support) },
                comment: { value: a.comment },
                producer: { value: !isUndefined(operation?.producer) ? getLabel(contributors, operation?.producer, 'mnemonique') : '' },
                sampler: { value: !isUndefined(operation?.sampler) ? getLabel(contributors, operation?.sampler, 'mnemonique') : '' },
                laboratory: { value: getLabel(contributors, a.labo, 'mnemonique') },
                quantificationLimit: { value: !isUndefined(a.lq) ? a.lq : (a.value.includes('<') ? a.value.replace('<', '') : '') },
            }
        })
    }, [analysis, piezoDatasFormatted, hydroDatasFormatted, pluvioDatasFormatted, shouldCalculateFlow, operationsIndex, parameterGroupUsage, qualitometers, parameters, units, remarks, supports, contributors])

    const stationsHeader = [...hydroDatasFormatted, ...piezoDatasFormatted, ...pluvioDatasFormatted].map(({ header }) => header)
    const headers = ['date', 'group', 'parameter', 'unit', 'result', ...(shouldCalculateFlow ? ['flux'] : []), ...stationsHeader, 'remark', 'support', 'comment', 'producer', 'sampler', 'laboratory', 'quantificationLimit']

    return (
        <>
            <NewTable
                rows={data}
                headers={displayAssociatedStations ? ['station', ...headers] : headers}
                rowsPerPageOptions={nbPerPageLabel}
            />
            <ExportModal
                analysis={analysis}
                operations={operations}

                isExportModalOpen={isExportModalOpen}
                closeExportModal={closeExportModal}

                filter={filter}
            />
        </>
    )
}

PcMonitoringListTable.propTypes = {
    analysis: PropTypes.arrayOf(PropTypes.shape({/* DtoAnalysisLight + calculateThresholdResult */ })),
    operations: PropTypes.arrayOf(PropTypes.instanceOf(DtoOperation)),

    hydroDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.string,
        calculateFlux: PropTypes.bool,
        coeffFlux: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    piezoDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),
    pluvioDatas: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        type: PropTypes.number,
        offset: PropTypes.number,
        cumul: PropTypes.number,
        measures: PropTypes.arrayOf(PropTypes.shape({})),
    })),

    displayAssociatedStations: PropTypes.bool,

    isExportModalOpen: PropTypes.bool,
    closeExportModal: PropTypes.func,

    filter: PropTypes.shape({}),
}

export default PcMonitoringListTable