import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { isUndefined, orderBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Card from '../../../components/card/Card'
import Table from '../../../components/datatable/Table'
import ApplicationConf from '../../../conf/ApplicationConf'
import EventsAction from '../../../events/actions/EventsAction'
import { EVENT_TYPES_CODES } from '../../../events/constants/EventsConstants'
import { PATH_STATION } from '../../../home/constants/RouteConstants'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import InstallationAction from '../../../installation/actions/InstallationAction'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoQualitometerLight from '../../../quality/dto/DtoQualitometerLight'
import ContributorItem from '../../../referencial/components/contributor/dto/ContributorItem'
import AppStore from '../../../store/AppStore'
import { getDate } from '../../../utils/DateUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { createIconMaterialState } from '../../../utils/MaterielUtils'
import { hasValue } from '../../../utils/NumberUtil'
import { getStationTypeFromName, getStationTypeNameFromTypeCode } from '../../../utils/StationUtils'
import { getLabel } from '../../../utils/StoreUtils'
import MaterielAction from '../../actions/MaterielAction'
import DtoMaterielSituation from '../../dto/DtoMaterielSituation'
import DtoMaterielState from '../../dto/DtoMaterielState'
import DtoMaterielStationEvent from '../../dto/DtoMaterielStationEvent'
import DtoVariousMaterielSituation from '../variousMateriel/dto/DtoVariousMaterielSituation'
import SituationForm from './form/SituationForm'


const headers = ['assignment', 'siteName', 'statusCode', 'comment', 'administrator', 'situationDate', 'situationEndDate', 'updateLogin', 'updateDate']

class SituationPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            situation: { situationDate: props.materielSituation.length ? moment().valueOf() : props.purchaseDate },
            situationMaterialReplace: {},
            shouldReplace: false,
            shouldUsePreviousParam: false,

            openPopup: false,
            step: 0,
        }
    }

    resetState = obj => this.setState({
        ...obj,
        situation: { situationDate: this.props.materielSituation.length ? moment().valueOf() : this.props.purchaseDate },
        situationMaterialReplace: {},
        shouldReplace: false,
        shouldUsePreviousParam: false,
    })

    componentDidMount() {
        if (!this.props.materielStates.length) {
            AppStore.dispatch(MaterielAction.fetchMaterielStates())
        }
    }

    componentDidUpdate = prevProps => {
        if (this.props.purchaseDate !== prevProps.purchaseDate && !this.props.materielSituation.length) {
            this.setState(({ situation }) => ({ situation: { ...situation, situationDate: this.props.purchaseDate } }))
        }
        if (this.props.materielSituation.length !== prevProps.materielSituation.length && this.props.materielSituation.length) {
            this.setState(({ situation }) => ({ situation: { ...situation, situationDate: moment().valueOf() } }))
        }
    }

    getDatas = () => {
        if (this.props.materielSituation.length) {
            return orderBy(this.props.materielSituation, 'situationEndDate', 'desc').map(situation => {
                const findState = this.props.materielStates.find(state => state.code === situation.statusCode) || {}
                const siteType = (() => {
                    if (situation.siteType) {
                        return getStationTypeNameFromTypeCode(situation.siteType)
                    }
                    return ''
                })()
                const link = (() => {
                    if (siteType) {
                        const linkTypeName = getStationTypeFromName(siteType)
                        if (linkTypeName) {
                            return `${PATH_STATION}/${linkTypeName}/${situation.siteCode}`
                        }
                    }
                    return ''
                })()
                const comment = (() => {
                    const result = situation.comment
                    if (result && result.length >= 50) {
                        return `${result.substring(0, 47)}...`
                    }
                    return result
                })()
                const administrator = getLabel(this.props.contributors, situation.administratorCode)
                return {
                    statusCode: createIconMaterialState(findState.label, findState.code),
                    stateLabel: findState.label,
                    stateCode: situation.statusCode,
                    assignment: siteType && situation.siteName ? i18n[siteType] : '',
                    siteName: situation.siteName,
                    link,
                    comment,
                    situationDate: getDate(situation.situationDate),
                    situationEndDate: getDate(situation.situationEndDate),
                    administrator,
                    id: situation.id,
                    updateLogin: situation.updateLogin,
                    updateDate: getDate(situation.updateDate),
                }
            })
        }
        return []
    }

    addEvent = (siteType, siteCode, event) => {
        if (siteType === 7) {
            return this.props.addInstallationEvent(siteCode, event)
        }
        return this.props.addEvent(siteType, siteCode, event)
    }

    addSituation = (situation, event, situationMaterialReplace, shouldReplace) => {
        this.addEvent(situation.siteType, situation.siteCode, event).then(eventCode => {
            if (eventCode) {
                this.props.saveFunction({ ...situation, eventCode })
                if (shouldReplace) {
                    this.props.saveFunction({ ...situationMaterialReplace, eventCode }, false)
                }
            }
        })
    }

    onSave = () => {
        const {
            step,
            situation,
            situationMaterialReplace,
            shouldUsePreviousParam,
            shouldReplace,
        } = this.state
        const {
            materielSituation,
            toastrWarning,
        } = this.props
        if (materielSituation.some(({ situationEndDate, situationDate }) => (situationEndDate || situationDate) > situation.situationDate)) {
            toastrWarning(i18n.theMaterielAlreadyHasASituationOnThisDateRange)
            return
        }
        const situationFormat = { ...situation, [this.props.idKey]: this.props.idMateriel }
        const situationMaterialReplaceFormat = { ...situationMaterialReplace, [this.props.idKey]: situationMaterialReplace.materielId, situationDate: situationFormat.situationDate }
        if (shouldUsePreviousParam) {
            this.props.savePreviousParam()
        }
        switch (step) {
            case 1:
                const eventTech = {
                    date: situation.situationDate,
                    eventType: 'T',
                    graph: '0',
                    bankExport: '0',
                    eventHour: situation.situationDate,
                    code: situation.siteCode,
                }
                this.addSituation(situationFormat, eventTech, situationMaterialReplaceFormat, shouldReplace)
                break
            case 2:
                if (shouldReplace) {
                    this.props.saveFunction(situationMaterialReplaceFormat, false)
                }
                this.props.saveFunction(situationFormat)
                break
            default:
                this.props.saveFunction(situationFormat)
        }
        this.resetState({ openPopup: false, step: 0 })
    }

    onExportSituation = data => {
        exportFile({
            data: data.map(d => ({ ...d, statusCode: { value: d.stateCode, format: '0', cellType: 'number' }, status: d.stateLabel, headers: ['assignment', 'siteName', 'statusCode', 'status', 'comment', 'administrator', 'situationDate', 'situationEndDate'] })),
            exportType: 'xlsx',
            titleFile: i18n.situation,
        })
    }

    getStations = () => {
        switch (this.state.situation.siteType) {
            case 1:
                return this.props.piezometers
            case 2:
                return this.props.pluviometers
            case 3:
                return this.props.qualitometers
            case 4:
                return this.props.hydrometricStations
            case 7:
                return this.props.installations
            default:
                return []
        }
    }

    onChange = obj => {
        if (obj.situation && obj.situation.siteCode && obj.situation.siteCode !== this.state.situation.siteCode) {
            const eventUrl = (() => {
                const siteId = obj.situation.siteCode
                switch (obj.situation.siteType) {
                    case 1:
                        return ApplicationConf.piezometer.events(siteId)
                    case 2:
                        return ApplicationConf.pluviometry.events(siteId)
                    case 3:
                        return ApplicationConf.qualitometer.events(siteId)
                    case 4:
                        return ApplicationConf.hydrometricStation.events(siteId)
                    case 7:
                        return ApplicationConf.installation.events(siteId)
                    default:
                        return ''
                }
            })()
            if (eventUrl) {
                this.props.fetchMaterielStationEvents(eventUrl, obj.situation.siteType)
            }
        }
        if (obj.shouldUsePreviousParam) {
            this.props.fetchPreviousParam(this.state.situationMaterialReplace.materielId)
        }
        this.setState(obj)
    }

    getStationEvent = () => this.props.stationsEvents.filter(
        e => e.code === this.state.situation.siteCode && e.siteType === this.state.situation.siteType
            && (e.eventType === EVENT_TYPES_CODES.PREVENTIVE || e.eventType === EVENT_TYPES_CODES.CURATIVE),
    )

    getPopupPanel = step => {
        const {
            situation,
            shouldReplace,
            shouldUsePreviousParam,
            situationMaterialReplace,
        } = this.state
        switch (step) {
            case 0:
                const stations = this.getStations(this.props, situation.siteType)
                const station = stations.find(s => s.id === situation.siteCode)
                const lastMaterialOnSite = station ? this.props.lastSituations.filter(s => s.siteCode === station.id && !s.situationEndDate) : []
                const materialOnSite = lastMaterialOnSite.map(s => this.props.materials.find(m => m.id === s.materielId)).filter(m => !!m)
                return (
                    <SituationForm
                        situation={situation}
                        onChange={this.onChange}
                        serialNumber={this.props.serialNumber}
                        showCheckbox={!!lastMaterialOnSite.length}
                        shouldReplace={shouldReplace}
                        isCentral={this.props.isCentral}
                        shouldUsePreviousParam={shouldUsePreviousParam}
                        materialOnSite={materialOnSite}
                        situationMaterialReplace={situationMaterialReplace}
                        stations={this.getStations()}
                    />
                )
            case 1:
                const eventNumber = this.getStationEvent().length
                return (
                    <div className='col s12 center'>
                        <div className={'col s12 row'}>
                            <a className={`btn ${!eventNumber ? 'disabled' : ''}`} style={{ width: '250px' }} onClick={() => this.setState({ step: 2 })}>{i18n.assignEvent}</a>
                        </div>
                        <div className='col s12 row'>
                            <a className='btn' style={{ width: '250px' }} onClick={() => this.onSave()}>{i18n.doNotLink}</a>
                        </div>
                    </div>
                )
            case 2:
                const events = orderBy(this.getStationEvent(), 'date', 'desc')
                return (
                    <div className='col s12'>
                        <div className='scrollable-card' style={{ maxHeight: '70%' }}>
                            {
                                events.map((e, i) => (
                                    <div className='row col s12 no-margin'>
                                        <input name='eventGroup' type='radio' id={i} onChange={() => this.setState({
                                            situation: {
                                                ...situation,
                                                eventCode: e.number,
                                            },
                                            situationMaterialReplace: {
                                                ...situationMaterialReplace,
                                                eventCode: e.number,
                                            },
                                        })}
                                        />
                                        <label htmlFor={i} className='truncate'>{getDate(e.date)} - {e.comment}</label>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            default:
                return null
        }
    }

    getValidateButton = step => {
        const {
            situation,
            shouldReplace,
            situationMaterialReplace,
        } = this.state
        const isBlock = !hasValue(situation.statusCode) || ((situation.statusCode === 1 || situation.statusCode === 4) && !situation.siteCode) || (shouldReplace && isUndefined(situationMaterialReplace.statusCode)) || (step === 2 && !situation.eventCode)
        return (
            !step && (situation.statusCode === 1 || situation.statusCode === 4) ? (
                <Button disabled={isBlock} onClick={() => !isBlock && this.setState({ step: step + 1 })} variant='outlined' >
                    {i18n.next}
                </Button>
            ) : (
                <Button disabled={isBlock} onClick={() => !isBlock && this.onSave()} >
                    {i18n.validate}
                </Button>
            )
        )
    }

    render = () => {
        if (!this.props.idMateriel) {
            return null
        }
        const data = this.getDatas()
        const actions = [
            {
                iconName: 'note_add',
                onClick: () => this.setState({ openPopup: true }),
            },
            {
                iconName: 'file_download',
                onClick: () => this.onExportSituation(data),
            },
        ]
        const {
            step,
            openPopup,
        } = this.state
        return (
            <div className='row margin-top-2'>
                <div className='col s12'>
                    <Card title={i18n.materielAssignments} active={this.props.active} actions={actions}>
                        <Table
                            showTitle={false}
                            data={data}
                            type={{ headers }}
                            condensed
                            sortable
                            deletable
                            onDelete={({ id }) => this.props.deleteFunction(id)}
                            active={this.props.active}
                        />
                    </Card>
                </div>
                <Dialog
                    onClose={() => this.setState({ openPopup: false, step: 0 })}
                    fullWidth
                    maxWidth='lg'
                    open={openPopup}
                >
                    <DialogContent>
                        <div style={{ height: '300px' }}>
                            {this.getPopupPanel(step)}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            if (step === 0) {
                                this.resetState()
                            }
                            this.setState({
                                openPopup: step !== 0,
                                step: step < 2 ? 0 : 1,
                            })
                        }} variant='outlined'
                        >
                            { step === 0 ? i18n.close : i18n.return}
                        </Button>
                        {(step !== 1 && this.getValidateButton(step))}
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

SituationPanel.propTypes = {
    materielSituation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielSituation)),
    active: PropTypes.bool,
    idKey: PropTypes.string,
    idMateriel: PropTypes.number,
    serialNumber: PropTypes.string,
    purchaseDate: PropTypes.number,
    lastSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielSituation)),
    materials: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        label: PropTypes.string,
    })),
    saveFunction: PropTypes.func,
    deleteFunction: PropTypes.func,
    isCentral: PropTypes.bool,
    savePreviousParam: PropTypes.func,
    fetchPreviousParam: PropTypes.func,

    materielStates: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielState)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    stationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoMaterielStationEvent)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),

    fetchMaterielStationEvents: PropTypes.func,
    addEvent: PropTypes.func,
    addInstallationEvent: PropTypes.func,
    toastrWarning: PropTypes.func,
}

SituationPanel.defaultProps = {
    active: false,
    isCentral: false,
    savePreviousParam: () => { },
    fetchPreviousParam: () => { },
    saveFunction: () => { },
    deleteFunction: () => { },
    materielSituation: [],
    lastSituations: [],
}

const mapStateToProps = store => ({
    materielStates: store.MaterielReducer.materielStates,
    piezometers: store.PiezometryReducer.piezometersLight,
    qualitometers: store.QualityReducer.qualitometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    installations: store.InstallationReducer.installations,
    stationsEvents: store.MaterielReducer.stationsEvents,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = ({
    fetchMaterielStationEvents: MaterielAction.fetchMaterielStationEvents,
    toastrWarning: ToastrAction.warning,
    addEvent: EventsAction.addEvent,
    addInstallationEvent: InstallationAction.addInstallationEvent,
})

export default connect(mapStateToProps, mapDispatchToProps)(SituationPanel)
