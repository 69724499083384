import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import InstallationProtectionsPanel from './InstallationProtectionsPanel'
import InstallationAccessTypePanel from './InstallationAccessTypePanel'
import Checkbox from '../../../../components/forms/Checkbox'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Input from '../../../../components/forms/Input'
import { getVisitCheckBoxChange, getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import Select from '../../../../components/forms/Select'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { Card, CardContent, Grid2 } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'
import useSandreList from 'utils/customHook/useSandreList'
import { greyBlue } from 'utils/constants/ColorTheme'

const InstallationAccessCasingPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    station = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const onChangeAccessCasing = (value) => {
        const accessCasing = station.link_accessCasings[0] || {}
        onChange({
            link_accessCasings: [{
                idStation: station.id,
                ...accessCasing,
                ...value,
            }],
        })
    }

    const onChangePrevention = (value) => {
        const preventions = station.link_preventions || {}

        const newPreventions = preventions && preventions.filter(p => p.code === value).length
            ? preventions.filter(p => p.code !== value)
            : [
                ...preventions,
                {
                    idStation: station.id,
                    code: value,
                },
            ]

        onChange({
            link_preventions: newPreventions,
        })
    }

    const otherProtectionList = useSandreList(SANDRE.INSTALLATIONS_AUTREPROTECTION)

    const accessCasing = station.link_accessCasings[0] || {}

    return (
        <Card>
            <CardTitle title={i18n.accessCasing} />
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                        <InstallationAccessTypePanel onChange={onChange} station={station} readMode={readMode} />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={accessCasing.lockedAccess}
                            label={i18n.lockedAccess}
                            onChange={v => {
                                onChangeAccessCasing({ lockedAccess: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.lockedAccess, accessCasing.lockedAccess, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={accessCasing.differentsKeysAccess}
                            label={i18n.differentsKeysAccess}
                            onChange={v => {
                                onChangeAccessCasing({ differentsKeysAccess: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.differentsKeysAccess, accessCasing.differentsKeysAccess, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={accessCasing.closeType}
                            label={i18n.closeType}
                            keyvalue='code'
                            integerValue
                            onChange={v => {
                                onChangeAccessCasing({ closeType: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEVERROUILLAGE, i18n.closeType, accessCasing.closeType, v))
                            }}
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEVERROUILLAGE)}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.closingBrand}
                            value={accessCasing.closingBrand}
                            onChange={v => {
                                onChangeAccessCasing({ closingBrand: v })
                                onChangeVisit({ previousValue: accessCasing.closingBrand, newValue: v, field: `${i18n.casingAccess} - ${i18n.closingBrand}` })
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.closingModel}
                            value={accessCasing.closingModel}
                            onChange={v => {
                                onChangeAccessCasing({ closingModel: v })
                                onChangeVisit({ previousValue: accessCasing.closingModel, newValue: v, field: `${i18n.casingAccess} - ${i18n.closingModel}` })
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.description}
                            value={accessCasing.description}
                            onChange={v => {
                                onChangeAccessCasing({ description: v })
                                onChangeVisit({ previousValue: accessCasing.description, newValue: v, field: i18n.description })
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={accessCasing.antiintrusion}
                            label={i18n.antiintrusion}
                            onChange={v => {
                                onChangeAccessCasing({ antiintrusion: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.antiintrusion, accessCasing.antiintrusion, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.deconnexion}
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_DECONNEXION)}
                            value={accessCasing.deconnexion}
                            onChange={v => {
                                onChangeAccessCasing({ deconnexion: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_DECONNEXION, i18n.deconnexion, accessCasing.deconnexion, v))
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                        <InstallationProtectionsPanel onChange={onChange} station={station} readMode={readMode} />
                    </Grid2>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.otherSystem} color={greyBlue} />
                            <CardContent>
                                {otherProtectionList.map(sandre => {
                                    const prevention = station.link_preventions.filter(d => d.code === sandre.code).length || false
                                    return (
                                        <Grid2 size={12} key={sandre.name}>
                                            <Checkbox
                                                checked={prevention}
                                                label={sandre.name}
                                                onChange={() => {
                                                    onChangePrevention(sandre.code)
                                                    onChangeVisit(getVisitCheckBoxChange(sandre.name, prevention, !prevention))
                                                }}
                                                disabled={readMode}
                                            />
                                        </Grid2>
                                    )
                                })}
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card >
    )
}

InstallationAccessCasingPanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
    changeBssCode: PropTypes.func,
}


export default InstallationAccessCasingPanel
