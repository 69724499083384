import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Checkbox from '../../../../components/forms/Checkbox'
import DtoPumpingStation from '../dto/DtoPumpingStation'
import { getVisitCheckBoxChange, getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import Select from '../../../../components/forms/Select'
import { Grid2 } from '@mui/material'
import useSandreList from 'utils/customHook/useSandreList'
import { useSelector } from 'react-redux'

const PumpingEquipmentsPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    pumping = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }))
    const onChangePumpingEquipments = (value) => {
        onChange({
            link_equipments: [
                {
                    idStation: pumping.idStation,
                    ...pumping.link_equipments[0],
                    ...value,
                },
            ],
        })
    }

    const equipments = pumping.link_equipments[0] || {}

    const flowList = useSandreList(SANDRE.INSTALLATIONS_MESUREDEBIT)

    return (
        <>
            <Grid2 size={6}>
                <Checkbox
                    checked={equipments.turbidimeter}
                    label={i18n.turbidimeter}
                    onChange={(v) => {
                        onChangePumpingEquipments({ turbidimeter: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.turbidimeter, equipments.turbidimeter, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={equipments.chlorineResidualAnalyzer}
                    label={i18n.chlorineResidualAnalyzer}
                    onChange={(v) => {
                        onChangePumpingEquipments({ chlorineResidualAnalyzer: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.chlorineResidualAnalyzer, equipments.chlorineResidualAnalyzer, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={equipments.flowMeasure}
                    label={i18n.flowMeasure}
                    keyValue='code'
                    options={flowList}
                    onChange={(v) => {
                        onChangePumpingEquipments({ flowMeasure: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT, i18n.flowMeasure, equipments.flowMeasure, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

PumpingEquipmentsPanel.propTypes = {
    pumping: PropTypes.instanceOf(DtoPumpingStation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default PumpingEquipmentsPanel
