import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import SieauAction from '../../../components/sieau/SieauAction'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getSetting, getUser } from '../../../utils/SettingUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import { downloadURI } from '../../../utils/ExportDataUtil'
import InstallationSampleCharacteristicsPanel from '../installations/components/InstallationSampleCharacteristicsPanel'
import InstallationOuvragePanel from '../installations/components/InstallationOuvragePanel'
import StationTanksPanel from '../../../station/components/link/StationTanksPanel'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import AquiferAction from '../../../referencial/components/aquifers/actions/AquiferAction'
import HydrogeologicalEntityAction from '../../../referencial/components/hydrogeologicalEntity/actions/HydrogeologicalEntityAction'
import StationAction from '../../../station/actions/StationAction'
import QualityAction from '../../../quality/actions/QualityAction'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import { arrayOf } from 'utils/StoreUtils'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import AgriAction from 'agriAdministration/actions/AgriAction'
import { Card, CardContent, Grid2 } from '@mui/material'
import GenericInstallationDescription from '../installationDefault/GenericInstallationDescription'
import { CardTitle } from 'components/card/NewCard'

class AgriPrelDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: { ...props.installation },
            readMode: true,
        }
    }

    componentDidMount() {
        const {
            installation,
            watersheds,
            aquifers,
            hydrogeologicalEntities,
            installationAgriTanks,
            natures,
            fieldModes,
            status,
            agriTanksTypes,
        } = this.props
        if (installation.id) {
            this.setState({ installation })
        }
        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
        if (!watersheds.length) {
            this.props.fetchWatersheds()
        }
        if (!aquifers.length) {
            this.props.fetchAquifers()
        }
        if (!hydrogeologicalEntities.length) {
            this.props.fetchHydrogeologicalEntities()
        }
        if (!installationAgriTanks.length) {
            this.props.fetchInstallationAgriTanks()
        }
        if (!natures.length) {
            this.props.fetchNatures()
        }
        if (!fieldModes.length) {
            this.props.fetchFieldModes()
        }
        if (!status.length) {
            this.props.fetchStatus()
        }
        if (!agriTanksTypes.length) {
            this.props.fetchTanksTypes()
        }

        // this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
        //     if (this.props.onChangeVisit) {
        //         this.setState({ readMode: false })
        //     } else {
        //         this.setReadOnlyMode()
        //     }
        // })
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => this.props.push('/installation')

    getInstallationObject = (installation) => {
        return {
            ...installation,
            usages: [],
            contributors: [],
            arrangements: [],
            cartographyData: [],
            localisation: {},
        }
    }

    getObjectIfHasChanged = (propsObject, stateObject) => {
        if (!isEqual(propsObject, stateObject)) {
            return stateObject
        }
        return null
    }

    onSave = () => {
        return this.props.updateInstallation(
            this.state.installation,
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation })
                this.setReadOnlyMode()
            },
            delete: () => this.props.delete('installation', this.props.installation.id, this.onDelete),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        this.setState({ readMode: true })

        // const { installationVisits } = this.props
        // const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null

        const actions = {
            // ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.props.delete('installation', this.props.installation.id, this.onDelete),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }
    onChangeInstallation = (newObject) => {
        this.setState({ installation: { ...this.state.installation, ...newObject } })
    }

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return getSetting(this.props.applicationSettings, `PANEL_${this.state.installation.installationType}_${panel}`) !== 'hidden'
    }

    render() {
        const { installation, readMode } = this.state
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <GenericInstallationDescription
                readMode={readMode}
                installation={installation}
                onChangeInstallation={this.onChangeInstallation}
                onChangeVisit={this.onChangeVisit}
                descElements={
                    <>
                        <Grid2 size={12}>
                            <BuildingsPanel {...params} />
                        </Grid2>
                    </>
                }
                otherElements={
                    <>
                        <Grid2 size={12}>
                            <Card>
                                <CardTitle title={i18n.characteristics} />
                                <CardContent>
                                    <Grid2 container columnSpacing={1}>
                                        <InstallationSampleCharacteristicsPanel {...params} />
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                        <Grid2 size={12}>
                            <Card>
                                <CardTitle title={i18n.work} />
                                <CardContent>
                                    <Grid2 container columnSpacing={1}>
                                        <InstallationOuvragePanel {...params} />
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                        <Grid2 size={12}>
                            <StationTanksPanel {...params} />
                        </Grid2>
                    </>
                }
            />
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM?.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

AgriPrelDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = store => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    watersheds: store.WatershedReducer.watersheds,
    aquifers: store.AquiferReducer.aquifers,
    hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
    installationAgriTanks: store.InstallationReducer.installationAgriTanks,
    natures: store.StationReducer.natures,
    fieldModes: store.StationReducer.fieldModes,
    status: store.QualityReducer.status,
    cities: store.CityReducer.cities,
    users: store.UserReducer.users,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    installation: store.InstallationReducer.installation,
    installationTable: store.InstallationReducer.installationTable,
    // installationVisits: store.InstallationReducer.installationVisits,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    agriTanksTypes: store.AgriReducer.agriTanksTypes,
})

const mapDispatchToProps = {
    push,
    updateInstallation: InstallationAction.updateInstallation,
    info: ToastrAction.info,
    fetch: SieauAction.fetch,
    delete: SieauAction.delete,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationAgriTanks: InstallationAction.fetchInstallationAgriTanks,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchHydrogeologicalEntities: HydrogeologicalEntityAction.fetchHydrogeologicalEntities,
    fetchNatures: StationAction.fetchNatures,
    fetchFieldModes: StationAction.fetchFieldModes,
    fetchStatus: QualityAction.fetchStatus,
    fetchTanksTypes: AgriAction.fetchTanksTypes,
    // fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
}

export default connect(mapStateToProps, mapDispatchToProps)(AgriPrelDescriptionPanel)
