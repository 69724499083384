import React, { Component } from 'react'
import { getAxisIntervalFormatter, getOptions } from './EChartUtils'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/graph'
import 'echarts/lib/chart/heatmap'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/chart/sunburst'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/visualMap'
import 'echarts/lib/component/dataZoom'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/markPoint'
import 'echarts/lib/component/toolbox'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import { flatten, isEqual } from 'lodash'
import ChartTabs from './ChartTabs'
import moment from 'moment'
import { removeNullKeys } from '../../utils/StoreUtils'
import { hasValue } from '../../utils/NumberUtil'
import PropTypes from 'prop-types'
import { DialogContentMUI, DialogTitleMUI } from '../styled/Dialog'
import { Dialog, Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import Icon from '../icon/Icon'
import NumberField from '../forms/NumberField'
import { ButtonMUI } from '../styled/Buttons'

class EChart extends Component {
    constructor(props) {
        super(props)
        this.state = { minDate: null }
        this.zoom = { start: null, end: null }
        this.tmpYZoom = { min: undefined, max: undefined }
    }

    getDefaultOptions = () => {
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    animation: false,
                    label: {
                        backgroundColor: '#505765',
                    },
                },
            },
            grid: {
                top: '8%',
                left: '3%',
                right: '4%',
                bottom: '80',
                containLabel: true,
                height: 300,
            },
            xAxis: [],
            yAxis: [],
            series: [],
        }
    }

    resetZoom = () => {
        this.zoom.start = 0
        this.zoom.end = 100
    }

    showTip = (serieId, date) => {
        const serieIndex = this.options.series.findIndex(s => s.serieId === serieId)
        if (serieIndex !== -1) {
            const data = this.options.series[serieIndex].data
            if (data && data.length) {
                const sameFound = data.findIndex(d => d.value[0] === date)
                if (sameFound !== -1) {
                    this.dispatchAction({ type: 'showTip', seriesIndex: serieIndex, dataIndex: sameFound })
                } else {
                    const sameDayFound = data.findIndex(d => moment(d.value[0]).isSame(date, 'day'))
                    if (sameDayFound !== -1) {
                        this.dispatchAction({ type: 'showTip', seriesIndex: serieIndex, dataIndex: sameDayFound })
                    }
                }
            }
        }
    }

    dispatchAction = (action) => {
        const echarts_instance = this.echarts_react.getEchartsInstance()
        echarts_instance.dispatchAction(action)
    }

    getScroll = () => {
        if (this.props.scrollable) {
            return {
                'overflow-y': 'auto',
                height: 'calc(100vh - 64px)',
            }
        }
        return {}
    }

    componentDidUpdate(prevProps) {
        if (this.props.withYZoomModal && !isEqual(prevProps.YZoom, this.props.YZoom)) {
            this.tmpYZoom = this.props.YZoom
        }
    }

    getInstance = () => this.echarts_react?.getEchartsInstance()

    render() {
        const { openLogModal, withLogModal, messageLogModal, onCloseLogModal, withYZoomModal, openYZoomModal, setYZoom } = this.props
        const options = getOptions(this.props.options, this.getDefaultOptions(), {
            title: () => ({ title: {
                text: this.props.options.title,
                left: 'center',
            } }),
            customTitle: () => ({ title: this.props.options.customTitle }),
            series: () => ({ series: flatten(this.props.options.series.map(serie => serie.getJson(this.props.bandCorrection))) }),
            xAxis: () => ({ xAxis: this.props.options.xAxis.map(axis => axis.getJson()) }),
            yAxis: () => ({ yAxis: this.props.options.yAxis.map(axis => axis.getJson(this.props.bandCorrection)) }),
            tooltip: () => ({ tooltip: this.props.options.tooltip }),
            showLegend: () => {
                if (this.props.options.showLegend) {
                    return {
                        legend: {
                            data: this.props.options.series.filter(s => s.obj.data.length).map(serie => serie.obj.name),
                        },
                    }
                }
                return {}
            },
            setDataZoom: () => {
                if (this.props.options.setDataZoom) {
                    const usedZoom = !hasValue(this.zoom.start) && !hasValue(this.zoom.end) && this.props.initialZoomValueStart && this.props.initialZoomValueEnd ? {
                        startValue: this.props.initialZoomValueStart,
                        endValue: this.props.initialZoomValueEnd,
                    } : { start: this.zoom.start || 0, end: this.zoom.end || 100 }
                    return {
                        dataZoom: [{
                            type: 'inside',
                            ...usedZoom,
                            // filterMode: 'none', // Ne pas toucher ! Cela sert à ne pas avoir de "blancs" au début et à la fin du graphique. Si il faut filtrer les données, il vaut mieux le faire en amont.
                            filterMode: this.props.hasBars ? 'filter' : 'none', // Ne pas toucher ! Cela sert à ne pas avoir de "blancs" au début et à la fin du graphique. Si il faut filtrer les données, il vaut mieux le faire en amont.
                            xAxisIndex: this.props.options.xAxis && this.props.options.xAxis.length ? this.props.options.xAxis.map((_, i) => i) : null,
                        }, {
                            ...usedZoom,
                            handleSize: '80%',
                            bottom: '0',
                            // filterMode: 'none', // Ne pas toucher ! Cela sert à ne pas avoir de "blancs" au début et à la fin du graphique. Si il faut filtrer les données, il vaut mieux le faire en amont.
                            filterMode: this.props.hasBars ? 'filter' : 'none', // Ne pas toucher ! Cela sert à ne pas avoir de "blancs" au début et à la fin du graphique. Si il faut filtrer les données, il vaut mieux le faire en amont.
                            xAxisIndex: this.props.options.xAxis && this.props.options.xAxis.length ? this.props.options.xAxis.map((_, i) => i) : null,
                            handleStyle: {
                                color: '#fff',
                                shadowBlur: 3,
                                shadowColor: 'rgba(0, 0, 0, 0.6)',
                                shadowOffsetX: 2,
                                shadowOffsetY: 2,
                            },
                        }],
                    }
                }
                return {}
            },
            color: () => (
                {
                    lineStyle: {
                        normal: {
                            color: this.props.options.color,
                        },
                    },
                    itemStyle: {
                        normal: {
                            color: this.props.options.color,
                        },
                    },
                }),
        })
        if (this.props.options.gridHeight) {
            options.grid.height = this.props.options.gridHeight
        }

        const onEvents = removeNullKeys({
            click: this.props.onClick,
            dataZoom: options.dataZoom ? params => {
                if (params.batch) {
                    this.zoom.start = params.batch[0].start
                    this.zoom.end = params.batch[0].end
                } else {
                    this.zoom.start = params.start
                    this.zoom.end = params.end
                }
            } : undefined,
        })

        if (this.props.options.withDateTabs) {
            const axisLabelObj = getAxisIntervalFormatter(moment(), moment(this.state.minDate || options.xAxis[0].min))
            options.xAxis = options.xAxis.map(axis => Object.assign({}, axis, {
                min: this.state.minDate,
                max: moment().valueOf(),
                interval: axisLabelObj.interval,
                axisLabel: { formatter: axisLabelObj.formatter },
            }))
        }
        this.options = options // Don't remove the sieauChart clasName !!!
        return (
            <>
                <div id={this.props.id} className='sieauChart' style={this.getScroll()}>
                    {this.props.options.withDateTabs && <ChartTabs onChangeDate={changes => this.setState(changes)}/>}
                    <ReactEchartsCore
                        echarts={echarts}
                        ref={(e) => {
                            this.echarts_react = e
                        }}
                        option={options}
                        notMerge={true}
                        lazyUpdate={this.props.lazyUpdate}
                        className={'row no-margin'}
                        onEvents={onEvents}
                        style={{ height: (this.props.options.height || 300) + (options.dataZoom ? 75 : 10) }}
                    />
                </div>
                {
                    withLogModal && (
                        <Dialog
                            open={openLogModal}
                        >
                            <DialogTitleMUI>
                                <Grid2 container justifyContent='space-between' alignItems='center'>
                                    <Grid2>
                                        {i18n.generalInfos}
                                    </Grid2>
                                    <Grid2>
                                        <Icon style={{ color: 'white' }} size='small' icon={'close'}
                                            onClick={onCloseLogModal}
                                        />
                                    </Grid2>
                                </Grid2>
                            </DialogTitleMUI>
                            <DialogContentMUI>
                                <div style={{ padding: '20px 10px 5px 0px' }}>
                                    {messageLogModal}
                                </div>
                            </DialogContentMUI>
                        </Dialog>
                    )
                }
                {
                    withYZoomModal && (
                        <Dialog
                            open={openYZoomModal}
                        >
                            <DialogTitleMUI>
                                <Grid2 container justifyContent='space-between' alignItems='center'>
                                    <Grid2>
                                        {i18n.zoomVertical}
                                    </Grid2>
                                    <Grid2>
                                        <Icon style={{ color: 'white' }} size='small' icon={'close'}
                                            onClick={() => setYZoom(this.props.YZoom)}
                                        />
                                    </Grid2>
                                </Grid2>
                            </DialogTitleMUI>
                            <DialogContentMUI>
                                <Grid2 container spacing={2}>
                                    <Grid2 size={6}>
                                        <NumberField
                                            title='Min'
                                            value={this.tmpYZoom.min}
                                            onChange={v => {
                                                this.tmpYZoom.min = v
                                            }}
                                            floatValue
                                            onEnterKeyPress={v => {
                                                this.tmpYZoom.min = v
                                                setYZoom(removeNullKeys(this.tmpYZoom))
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            title='Max'
                                            value={this.tmpYZoom.max}
                                            onChange={v => {
                                                this.tmpYZoom.max = v
                                            }}
                                            floatValue
                                            onEnterKeyPress={v => {
                                                this.tmpYZoom.max = v
                                                setYZoom(removeNullKeys(this.tmpYZoom))
                                            }}
                                        />
                                    </Grid2>
                                    <Grid2 size={3} offset={6}>
                                        <ButtonMUI
                                            onClick={() => {
                                                this.tmpYZoom = {}
                                                setYZoom({})
                                            }}
                                            variant='outlined'
                                            fullWidth
                                        >
                                            {i18n.clean}
                                        </ButtonMUI>
                                    </Grid2>
                                    <Grid2 size={3}>
                                        <ButtonMUI
                                            onClick={() => setYZoom(removeNullKeys(this.tmpYZoom))}
                                            variant='contained'
                                            fullWidth
                                        >
                                            {i18n.apply}
                                        </ButtonMUI>
                                    </Grid2>
                                </Grid2>
                            </DialogContentMUI>
                        </Dialog>
                    )
                }
            </>
        )
    }
}

EChart.propTypes = {
    id: PropTypes.string,
    options: PropTypes.object,
    onClick: PropTypes.func,
    scrollable: PropTypes.bool,
    initialZoomValueStart: PropTypes.number,
    initialZoomValueEnd: PropTypes.number,
    bandCorrection: PropTypes.bool,
    lazyUpdate: PropTypes.bool,
    hasBars: PropTypes.bool,
    withLogModal: PropTypes.bool,
    openLogModal: PropTypes.bool,
    messageLogModal: PropTypes.string,
    onCloseLogModal: PropTypes.func,

    withYZoomModal: PropTypes.bool,
    openYZoomModal: PropTypes.bool,
    YZoom: PropTypes.shape({ min: PropTypes.number, max: PropTypes.number }),
    setYZoom: PropTypes.func,
}

EChart.defaultProps = {
    id: 'sieauChart',
    lazyUpdate: true, // can produce (Cannot read properties of undefined (reading 'getRawIndex')) when set to true
}

export default EChart
