import React, { Component } from 'react'
import { links } from '../../../quality/constants/StationsConstants'
import i18n from 'simple-react-i18n'
import {
    H_HYDRO_CAMPAIGN,
    H_HYDRO_DASHBOARD,
    H_HYDRO_EXPORT,
    H_HYDRO_GRAPHIC,
    H_HYDRO_MAP_SITUATION,
    H_HYDRO_OPTIONS,
    H_HYDRO_SITUATION, H_STATION_HYDRO_IMPORT,
} from '../../../account/constants/AccessRulesConstants'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import FixedSlideNav from '../../../components/navbar/FixedSlideNav'
import BoundaryError from '../../../log/components/BoundaryError'
import { Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'
import User from '../../../account/dto/User'
import { connect } from 'react-redux'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import UserDto from 'administration/components/user/dto/UserDto'
import AdministrationAction from 'administration/actions/AdministrationAction'

class HydrometersApp extends Component {
    componentDidMount() {
        const { globalParameters } = this.props
        if (!globalParameters.length) {
            this.props.fetchGlobalParameters()
        }
    }

    getLink = (linkName) => {
        const link = links[linkName]
        return {
            ...link,
            href: link.href || `/hydrometry/${link.hrefName || linkName}`,
            name: i18n[link.i18n],
        }
    }

    getLinksHabilitations = () => {
        return [
            ...[
                { linkName: 'dashboard', habilitation: H_HYDRO_DASHBOARD },
                { linkName: 'import', habilitation: H_STATION_HYDRO_IMPORT },
                // exportOverview
                { linkName: 'export', habilitation: H_HYDRO_EXPORT },
                { linkName: 'integrationOverview', habilitation: H_HYDRO_SITUATION },
                { linkName: 'campaign', habilitation: H_HYDRO_CAMPAIGN },
                { linkName: 'graphic', habilitation: H_HYDRO_GRAPHIC },
                // diagnostics
                { linkName: 'hydrometryOptions', habilitation: H_HYDRO_OPTIONS },
            ].filter(l => componentHasHabilitations(l.habilitation)),
            { linkName: 'mapSituations', habilitation: H_HYDRO_MAP_SITUATION },
        ]
    }

    render() {
        const baseLinks = this.getLinksHabilitations().filter(l => l)
        const navBarLinks = baseLinks.map(a => this.getLink(a.linkName))
        return (
            <div className='row no-margin'>
                <FixedSlideNav links={navBarLinks}/>
                <main>
                    <div className='row no-margin padding-left-1'>
                        <div className='content-page no-padding fullscreen'>
                            <BoundaryError>
                                <Outlet />
                            </BoundaryError>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

HydrometersApp.propTypes = {
    children: PropTypes.element,
    getLink: PropTypes.func,
    accountUser: PropTypes.instanceOf(User),
    globalParameters: PropTypes.arrayOf(PropTypes.instanceOf(GlobalParametersDto)),
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    fetchGlobalParameters: PropTypes.func,
}

const mapStateToProps = store => ({
    accountUser: store.AccountReducer.accountUser,
    globalParameters: store.AdministrationReducer.globalParameters,
    users: store.UserReducer.users,
})

const mapDispatchToProps = {
    fetchGlobalParameters: AdministrationAction.fetchGlobalParameters,
}

export default connect(mapStateToProps, mapDispatchToProps)(HydrometersApp)
