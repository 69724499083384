import React from 'react'
import { connect } from 'react-redux'
import { arrayOf, removeNullKeys } from '../../../../utils/StoreUtils'
import { MEASURE_COTE, PIEZO_L_STORAGE } from '../../../constants/PiezometryConstants'
import i18n from 'simple-react-i18n'
import {
    exportExcelIcon,
    exportPictureIcon,
    getAxisIntervalFormatter,
    logIcon,
    setLogOptions,
    setYOptionsPiezo,
    swapVertical,
    yAutomaticScaleValues,
} from '../../../../components/echart/EChartUtils'
import EChart from '../../../../components/echart/EChart'
import Axis from '../../../../components/echart/Axis'
import moment from 'moment'
import { enumerateBetweenDates, getDate, getDateWithHour, getFullDate } from '../../../../utils/DateUtil'
import { groupBy, last, maxBy, orderBy, partition, sum } from 'lodash'
import { hasValue, nFormatter, round } from '../../../../utils/NumberUtil'
import Line from '../../../../components/echart/series/Line'
import PropTypes from 'prop-types'
import DtoPiezometer from '../../../dto/DtoPiezometer'
import { getLocalStorageJson } from '../../../../utils/FormUtils'
import {
    DEPTH,
    getDisplayCote,
    getEventGraph,
    getEventsBar,
    piezoMeasureIsValid,
} from '../../../../utils/PiezometryUtils'
import PiezoSuiviStatTab2 from './chartTabs2/PiezoSuiviStatTab2'
import PiezoSuiviPastYearsTab2 from './chartTabs2/PiezoSuiviPastYearsTab2'
import PiezoSuiviAssociationsTab2 from './chartTabs2/PiezoSuiviAssociationsTab2'
import PiezoSuiviThresholds2 from './chartTabs2/PiezoSuiviThresholds2'
import PiezoSuiviModelTab2 from './chartTabs2/PiezoSuiviModelTab2'
import PiezometerStationAction from '../../../../station/actions/PiezometerStationAction'
import OptionsToolPanel from '../../validation/tools/OptionsToolPanel'
import DtoPiezometerChartOptions from '../../../../station/dto/piezometer/DtoPiezometerChartOptions'
import AppStore from '../../../../store/AppStore'
import SieauAction from '../../../../components/sieau/SieauAction'
import { getLinks, getStationTitle } from '../../../../utils/StationUtils'
import { componentHasHabilitations } from '../../../../utils/HabilitationUtil'
import { H_STATION_PIEZO_FOLLOW_UP } from '../../../../account/constants/AccessRulesConstants'
import DtoPiezoMeasureLight from '../../../dto/chart/DtoPiezoMeasureLight'
import DtoMeasureStats from '../../../../station/dto/piezometer/DtoMeasureStats'
import { getMeasureJplus } from '../../../../iaeau/utils/IAEauUtils'
import { getStationArrowNav } from 'utils/ActionUtils'
import ActionComponent from 'components/ActionComponent'
import { push } from '@lagunovsky/redux-react-router'
import { repeatList } from '../../../../utils/ListUtil'
import {
    generateGradient,
    getColorCircleElement,
    getColorCircleEvent,
    getEventColor,
    getRGBColor,
} from '../../../../utils/ColorUtil'
import { chunkWithWords, getExportFormat } from 'utils/StringUtil'
import PiezometryAction from 'piezometry/actions/PiezometryAction'
import { exportFile } from 'utils/ExportDataUtil'
import SimpleTabSideList from '../../../../components/navbar/SimpleTabSideList'
import ChartOptionsChip from '../../../../components/echart/ChartOptionsChip'
import MUIChartTabs from '../../../../components/echart/MUIChartTabs'
import { CIVIL_YEAR } from '../../../../alerting/constants/ChartFollowContants'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import PiezoSuiviEventsTab from './chartTabs2/PiezoSuiviEventsTab'
import DtoEvent from '../../../../events/dto/DtoEvent'
import { EVENT_TYPES_CODES } from '../../../../events/constants/EventsConstants'

const STATISTICS = 'statistics'
const PREVIOUSYEARS = 'previousYears'
const LINKEDSTATIONS = 'linkedStations'
const CHARTOPTIONS = 'chartOptions'
const THRESHOLDS = 'thresholds'
const MODELS = 'models'
const EVENTS = 'events'

const defaultDisplayModes = {
    brute: false,
    min: false,
    max: true,
    average: false,
    personalizedGrouping: false,
    personalizedGroupingValue: undefined,
}

class PiezoSuiviChart2 extends ActionComponent {
    constructor(props) {
        super(props)

        // calculate landmarkValue
        const lastLandmark = maxBy(props.piezometer.link_landmarks, 'startDate')
        const lastRefAlti = lastLandmark ? props.piezometer.link_altimetrySystems.find(alt => alt.natureCode === lastLandmark.altimetrySystemNature && alt.startDate === lastLandmark.altimetrySystemDate) : null
        const landmarkValue = lastLandmark && lastRefAlti ? lastLandmark.height + lastRefAlti.altitude : null

        const defaultDisplayCote = getLocalStorageJson(PIEZO_L_STORAGE.DISPLAY_COTE)
        this.state = {
            displayCote: (!defaultDisplayCote || [MEASURE_COTE.DEPTH, MEASURE_COTE.NGF].includes(defaultDisplayCote)) ? MEASURE_COTE.DEPTH : defaultDisplayCote, // mode d'affichage piézo
            minDate: moment().startOf('year').valueOf(), // date min du graphique
            maxDate: null, // date max du graphique,
            typeId: -1, // Type de donnée qui est sélectionné, chronqiue piézo par défaut
            isPiezo: true, // si on est sur le mode d'affichage piézo (NGF ou profondeur)

            statsSeries: [],
            yearSeries: [],
            associationsSeries: [],
            associationsAxis: [],
            selectedAssociations: [], // liste de String qui représente la liste des donnes associées qui sont affichées (réutilisé sur l'onglet des données prévi)
            modelsSeries: [],
            thresholds: [],
            landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
            hasValidMeasures: null, // true si les données piézos contiennent au moins une mesure valide
            piezometerChartOptions: [],
            chartOptionsAreLoaded: false,
            displayEvents: true,
            modeDate: 'dailyDate',
            time: CIVIL_YEAR, // tab temporelle sélectionnée
            measures: [], // max measures
            bruteMeasures: [], // brute measures
            bruteSerie: [],
            maxSerie: [],
            maxMeasures: [],
            minSerie: [],
            minMeasures: [],
            averageSerie: [],
            averageMeasures: [],
            personalizedGroupingSerie: [],
            personalizedGroupingMeasures: [],
            withBrutes: false,
            displayModes: getLocalStorageJson(PIEZO_L_STORAGE.DISPLAY_MODES_SUIVI) ?? defaultDisplayModes,
            filterStatusQualif: getLocalStorageJson(PIEZO_L_STORAGE.STATUS_QUALIF_SUIVI) ?? { // filtre sur statut et qualification (fait côté back)
                qualifications: getQualificationSelectOptions().map(q => q.code),
                status: getStatusSelectOptions().map(s => s.code),
            },
            tabLocked: false,
            // log mode
            openLogModal: false,
            displayLog: false,
            messageLogModal: '',

            // yZoom
            yZoom: {
                min: undefined,
                max: undefined,
            },
        }
        this.series = []
    }

    componentDidMount() {
        const { piezometer, piezometryDataTypes } = this.props
        this.setTitle(piezometer)
        this.props.fetchPiezoMeasuresStats(parseInt(piezometer.id))
        if (!componentHasHabilitations(H_STATION_PIEZO_FOLLOW_UP)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.getMaxMeasures()
        this.loadOtherDisplayMeasuresIfChecked()
        this.props.fetchPiezometerChartOptions(piezometer.id, () => this.setState({ piezometerChartOptions: this.props.piezometerChartOptions, chartOptionsAreLoaded: true }))
        this.setActions({
            links: getLinks(piezometer, this.props),
            arrowNav: getStationArrowNav('piezometry', this.props.piezometers, piezometer.id, s => this.props.push(`/station/piezometry/${s.id}/piezometricFollowUp`)),
        })
        if (!piezometryDataTypes.length) {
            this.props.fetchPiezometryDataTypes()
        }
    }

    // chope tout l'historique des max au chargement de l'écran, car réutilisé dans plein d'endroits (ex: années précédentes)
    getMaxMeasures = () => {
        const { typeId } = this.state
        PiezometerStationAction.promisePiezoChartMeasures({
            stationId: this.props.piezometer.id,
            displayCote: this.state.isPiezo ? MEASURE_COTE.NGF : undefined,
            groupFunc: 'MAX',
            dataType: typeId,
        }).then(res => {
            const measures = res.map(m => new DtoPiezoMeasureLight(m))
            const series = this.calculateSerie(measures, this.props.piezometerStatistics.find(t => t.typeId === typeId)?.label ?? i18n.chronic, '#3d72d2')
            this.setState({
                measures,
                maxSerie: series,
                hasValidMeasures: measures.some(m => piezoMeasureIsValid(m)),
                height: window.innerHeight - window.$('#chart').offset().top - 80,
            })
        })
    }

    loadOtherDisplayMeasuresIfChecked = () => {
        const otherSeriesToFetch = [
            { key: 'personalizedGrouping', groupFunc: this.state.displayModes.personalizedGroupingValue, color: '#ff0' },
            { key: 'min', groupFunc: 'MIN', color: '#f44336' },
            { key: 'average', groupFunc: 'AVERAGE', color: '#01cc01' },
            { key: 'brute', groupFunc: 'brute', color: '#000000' },
        ].filter(({ key }) => this.state.displayModes[key])
        if (otherSeriesToFetch.length) {
            Promise.all(otherSeriesToFetch.map(({ groupFunc }) => PiezometerStationAction.promisePiezoChartMeasures({
                stationId: this.props.piezometer.id,
                displayCote: this.state.isPiezo ? MEASURE_COTE.NGF : undefined,
                groupFunc,
                dataType: this.state.typeId,
                startDate: this.state.minDate,
                endDate: this.state.maxDate,
                distinctByCodePoint: false,
            }))).then(jsonTab => {
                const obj = jsonTab.reduce((acc, json, idx) => {
                    const measures = json.map(m => ({ ...new DtoPiezoMeasureLight(m), NGF: m[1] }))
                    const { key, color } = otherSeriesToFetch[idx]
                    return {
                        ...acc,
                        [`${key}Serie`]: this.calculateSerie(measures, key === 'brute' ? i18n.bruteMeasures : i18n[key], color),
                        [`${key}Measures`]: measures,
                    }
                }, {})
                this.setState(obj)
            })
        }
    }

    setTitle = (station) => {
        AppStore.dispatch(SieauAction.forceFetch('title', [{
            title: i18n.piezometry,
            href: 'piezometry',
        }, {
            title: getStationTitle(station),
            href: `station/piezometry/${station.id}`,
        }, {
            title: i18n.piezometrySuivi,
            href: `station/piezometry/${station.id}/piezometricFollowUp`,
        }]))
    }

    calculateSerie = (measures, labelSerie, baseColor) => {
        const { displayCote, landmarkValue, isPiezo, typeId } = this.state
        const unit = this.props.piezometerStatistics.find(t => t.typeId === DEPTH)?.unit ?? ''
        const group = groupBy(measures, 'codepoint')

        const measuresEchart = Object.keys(group).map(k => {
            return orderBy(group[k].flatMap(obj => {
                const depth = round(landmarkValue - obj.value, 3)
                const newObj = { ...obj, depth, NGF: obj.value }
                if (obj.initialPoint === 1) {
                    return [
                        { value: [moment(newObj.date).subtract(1, 'second').valueOf(), null, newObj], isPiezo, unit },
                        { value: [newObj.date, isPiezo ? (displayCote === MEASURE_COTE.NGF ? newObj.NGF : newObj.depth) : newObj.value, newObj], symbolSize: 5, isPiezo, unit },
                    ]
                }
                return [{ value: [newObj.date, isPiezo ? (displayCote === MEASURE_COTE.NGF ? newObj.NGF : newObj.depth) : newObj.value, newObj], symbolSize: 0, isPiezo, unit }]
            }), m => m.value[0])
        })
        const colors = repeatList(generateGradient(baseColor, '#FFF', 5), 10)
        return measuresEchart.map((byPointMeasures, idx) => Line({
            data: byPointMeasures,
            name: labelSerie + (measuresEchart.length > 1 ? ` - ${this.props.piezometerStatistics.find(s => s.typeId === typeId && s.codepoint === byPointMeasures[0].value[2].codepoint)?.namePoint}` : ''),
            connectNulls: false,
            showSymbol: false,
            yAxisIndex: 0,
            xAxisIndex: 0,
            color: colors[idx],
            serieId: 'measures',
            isPiezo,
        }))
    }

    getTooltip() {
        const { isPiezo, modeDate, displayCote } = this.state
        return {
            trigger: 'axis',
            formatter: params => {
                const filtered = params.filter(p => !p.data.noTooltip && hasValue(p.value[1]) && p.seriesName !== i18n.events)
                if (!filtered.length) {
                    return ''
                }
                const isPeriod = params.some(p => p.data.isPeriod)
                const date = modeDate === 'allDate' ? getFullDate(moment(filtered[0].value[0])) : getDate(moment(filtered[0].value[0]))
                const categoryDate = params.find(p => p.axisType.includes('category'))?.axisValue

                const events = this.props.stationEvents.filter(o => moment(o.date).isSame(params[0].value[0], 'day') && o.graph == '1' && o.eventType !== EVENT_TYPES_CODES.TECHNIQUE)
                const labelEvents = events.length ? events.reduce((acc, v) => {
                    return `${acc}<br />${
                        getColorCircleElement(getRGBColor(getEventColor(v.eventType)))}${v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event}<br />${
                        hasValue(v.ns) ? `${i18n.staticLevelMeasure} : ${v.ns}m<br />` : ''
                    }${hasValue(v.nc) ? `${i18n.sensorInstantLevel} : ${v.nc}m<br />` : ''}`
                }, '') : ''

                const paramsOrder = orderBy(filtered.map((o, idx) => {
                    return {
                        ...o,
                        marker: o.marker,
                        seriesName: o.seriesName === i18n.enveloppe ? i18n.enveloppeMax : o.seriesName,
                        value: (() => {
                            if (isPeriod && modeDate === MEASURE_COTE.DEPTH && hasValue(o.data.depthHackValue)) {
                                return o.data.depthHackValue
                            }
                            if (isPeriod && displayCote === MEASURE_COTE.NGF && o.seriesName.startsWith('>')) {
                                return filtered[idx - 1].data.realValue
                            }
                            return (o.data.realValue || o.value[1]) + (o.data.isPiezo && !hasValue(o.data.realValue) ? -1000 : 0)
                        })(),
                    }
                }), ['value', 'seriesName'], !isPiezo || displayCote === MEASURE_COTE.NGF ? ['desc', 'desc'] : ['asc', 'desc'])
                const result = paramsOrder.map(o => {
                    return `${o.marker} ${o.seriesName} : ${o.value} ${o.data.unit ?? ''} ${getMeasureJplus(o.data?.value[2]?.measure)}`
                }).join('<br/>')
                const measureType = isPiezo ? `${i18n.display} : ${getDisplayCote(displayCote)}` : ''
                return `${categoryDate ?? date} ${labelEvents}<br />${result}<br />${measureType}`
            },
        }
    }

    changeCote = (obj) => {
        const { displayCote } = obj
        const key = displayCote === MEASURE_COTE.NGF ? 'NGF' : 'depth'
        const changeMeasure = m => ({ ...m, value: [m.value[0], m.value[1] !== null ? m.value[2][key] : null, m.value[2], m.value[3]] })
        this.series.forEach(s => {
            if (s.obj.isPiezo) {
                if (s.obj.isMultiBand) {
                    s.updateObj({ bands: orderBy(s.obj.initialBands, ['idx'], displayCote === MEASURE_COTE.NGF ? ['asc'] : ['desc']).map(b => ({
                        ...b,
                        data: b.data.map(d => changeMeasure(d)),
                        color: b.isPeriod ? displayCote === MEASURE_COTE.NGF ? b.NGFColor : b.depthColor : b.color,
                    })) })
                } else {
                    s.updateObj({ data: s.obj.data.map(d => changeMeasure(d)) })
                }
            }
        })
        this.state.maxSerie.forEach(s => s.updateObj({ data: s.obj.data.map(d => changeMeasure(d)) }))
        this.setState({ ...obj, YZoom: {} }, this.loadOtherDisplayMeasuresIfChecked)
    }

    getExportData = (chartMinDate, chartMaxDate) => {
        const roundValue = this.props.piezometryDataTypes.find(pdt => pdt.id === DEPTH)?.numberDecimal || 3
        const data = this.series.flatMap(chart => {
            if (chart.obj?.bands) {
                return chart.obj.bands.flatMap(() => [...this.exportSerie(chart.obj.bands[1], chartMinDate, chartMaxDate, roundValue), ...this.exportSerie(chart.obj.bands[0], chartMinDate, chartMaxDate, roundValue)])
            }
            return this.exportSerie(chart.obj, chartMinDate, chartMaxDate, roundValue)
        })

        if (data?.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type']
        }

        return data
    }

    exportSerie = (serie, chartMinDate, chartMaxDate, roundValue) => serie.data.filter(d => d.value[0] >= chartMinDate && d.value[0] <= chartMaxDate).map(d => {
        return {
            stationCode: { value: this.props.piezometer.code },
            stationName: { value: this.props.piezometer.name },
            date: { value: getFullDate(d.value[0]), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
            value: { value: round(d.value[1]), format: getExportFormat(roundValue), cellType: 'number' },
            type: { value: serie.name },
        }
    })

    getTabs = () => {
        const { piezometer } = this.props
        const { displayCote, landmarkValue, hasValidMeasures, time, typeId, isPiezo, tabLocked, selectedAssociations,
            displayEvents, minDate, maxDate, measures, chartOptionsAreLoaded } = this.state
        const chartMinDate = minDate || (measures[0]?.date) || moment().valueOf()
        const chartMaxDate = maxDate || moment().endOf('year').valueOf()
        const histoYears = measures.length ? moment(last(measures).date).year() - moment(measures[0].date).year() : 0
        const defaultOptions = {
            displayCote,
            landmarkValue,
            id: piezometer.id,
            changeParent: changes => this.setState(changes),
            minDate: chartMinDate,
            maxDate: chartMaxDate,
            time,
            typeId,
            isPiezo,
        }

        const tabs = [{
            icon: 'multiline_chart',
            constant: STATISTICS,
            label: i18n.statistics,
        }, {
            icon: 'fast_rewind',
            constant: PREVIOUSYEARS,
            label: i18n.previousYears,
        },
        {
            icon: 'list',
            constant: LINKEDSTATIONS,
            label: `${i18n.associatedStations} ${i18n.and} ${i18n.additionalData}`,
        }, {
            icon: 'aspect_ratio',
            constant: CHARTOPTIONS,
            label: i18n.chartOptions,
        },
        {
            icon: 'report_problem',
            constant: THRESHOLDS,
            label: i18n.thresholds,
        },
        {
            icon: 'event_note',
            constant: EVENTS,
            label: i18n.events,
        },
        {
            icon: 'extension',
            constant: MODELS,
            label: i18n.prevData,
        }]
        if (hasValidMeasures === null || !chartOptionsAreLoaded) {
            return null
        }
        return (
            <SimpleTabSideList
                position='right'
                defaultTab={STATISTICS}
                tabs={tabs}
                cantChangeTabMessage={ tabLocked ? i18n.validateOrCancelFirst : undefined }
            >
                {
                    tab => (
                        <>
                            <PiezoSuiviStatTab2
                                {...defaultOptions}
                                tab={tab}
                                hasValidMeasures={hasValidMeasures ?? false}
                                histoYears={histoYears}
                                piezometerChartOptions={this.state.piezometerChartOptions}
                                piezometerStatistics={this.props.piezometerStatistics}
                                changeTypeId={v => this.setState({ typeId: v, displayModes: defaultDisplayModes, isPiezo: v === -1 }, () => {
                                    this.getMaxMeasures()
                                })}
                            />
                            <PiezoSuiviPastYearsTab2
                                {...defaultOptions}
                                tab={tab}
                                measures={this.state.measures}
                            />
                            <OptionsToolPanel
                                {...defaultOptions}
                                tab={tab}
                                lastLandmark={this.state.landmarkValue}
                                groundRefAlti={ null }
                                piezometerChartOptions={this.state.piezometerChartOptions}
                                yScale={ this.yScale }
                                modeDate={ this.state.modeDate }
                            />

                            <PiezoSuiviThresholds2
                                tab={tab}
                                {...defaultOptions}
                                piezometer={piezometer}
                                unit={this.props.piezometerStatistics.find(t => t.typeId === typeId)?.unit}
                            />
                            <PiezoSuiviAssociationsTab2
                                tab={tab}
                                {...defaultOptions}
                                piezometer={piezometer}
                            />
                            {
                                tab === EVENTS && (
                                    <PiezoSuiviEventsTab
                                        {...defaultOptions}
                                        displayEvents={displayEvents}
                                    />
                                )
                            }
                            <PiezoSuiviModelTab2
                                tab={tab}
                                {...defaultOptions}
                                piezometer={piezometer}
                                measures={this.state.measures}
                                selectedAssociations={selectedAssociations}
                                piezometerStatistics={this.props.piezometerStatistics}
                            />
                        </>
                    )
                }
            </SimpleTabSideList>
        )
    }

    render() {
        const { piezometer, stationEvents } = this.props
        const { landmarkValue, displayCote, displayModes, time, displayLog, openLogModal, messageLogModal, YZoom,
            openYZoomModal, typeId, isPiezo, filterStatusQualif, displayEvents, minDate, maxDate, measures,
            associationsSeries, associationsAxis, modelsSeries, statsSeries, yearSeries, thresholds,
            piezometerChartOptions } = this.state
        const chartMinDate = minDate || (measures[0]?.date) || moment().valueOf()
        const chartMaxDate = maxDate || moment().endOf('year').valueOf()

        const [xAxis, yAxis] = [[], []]

        const axisLabelObj = getAxisIntervalFormatter(moment(chartMaxDate), moment(chartMinDate))

        const [associationsInFirstAxis, otherSeries] = partition(associationsSeries, s => s.obj.isFirstAxis)

        const otherAxisObj = {}
        const otherAxis = [
            ...associationsAxis,
        ].map((axis, idx) => {
            otherAxisObj[axis.name] = idx + 1
            const yScale = yAutomaticScaleValues([
                ...([...otherSeries, ...modelsSeries].filter(s => s.obj.axisName === axis.name).flatMap(s => s.obj.data).flat().map(v => v.value[1])),
                ...otherSeries.filter(s => s.obj.axisName === axis.name).flatMap(s => s.obj.markLine?.data?.map(d => d.yAxis) || []),
            ])
            return Axis({
                type: 'value',
                nameLocation: 'middle',
                nameGap: 35,
                position: 'right',
                axisLabel: { formatter: nFormatter },
                offset: idx*60,
                splitNumber: 5,
                ...yScale,
                ...(removeNullKeys(axis)),
                name: `${axis.name} ${axis?.unit ? `[${axis?.unit}]` : ''}`,
                inverse: axis.isPluvio || (axis.isPiezo && displayCote === MEASURE_COTE.DEPTH),
            })
        })

        otherSeries.forEach(serie => {
            serie.updateObj({ yAxisIndex: otherAxisObj[serie.obj.axisName] })
        })
        modelsSeries.forEach(serie => {
            serie.updateObj({ yAxisIndex: otherAxisObj[serie.obj.axisName] ?? 0 })
        })

        const firstAxisSeries = [
            ...['brute', 'min', 'average', 'personalizedGrouping', 'max'].map(key => displayModes[key] ? this.state[`${key}Serie`] : []).flat(),
            ...statsSeries,
            ...yearSeries,
            ...associationsInFirstAxis,
            ...thresholds,
            ...modelsSeries,
        ]

        this.series = [
            ...firstAxisSeries,
            ...otherSeries,
        ]

        const option = piezometerChartOptions.find(opt => parseInt(opt.dataType ?? -1) === typeId)

        xAxis.push(Axis({
            type: 'time',
            position: 'bottom',
            min: chartMinDate,
            max: chartMaxDate,
            interval: axisLabelObj.interval,
            axisLabel: { show: true, formatter: axisLabelObj.formatter },
            gridIndex: 0,
            showSplitLine: option && hasValue(option.displayXIntervalYear) ? option.displayXIntervalYear === '1' : true,
        }))
        if (otherSeries.some(s => s.obj.isPluvio)) {
            xAxis.push(Axis({
                type: 'category',
                position: 'up',
                data: enumerateBetweenDates(chartMinDate, chartMaxDate, 'days').map(d => d.format('DD/MM/YYYY')),
                axisLabel: { show: false },
                axisTick: { show: false },
                gridIndex: 0,
            }))
        }
        const allValues = firstAxisSeries.filter(s => s.obj.isPiezo && !s.obj.noYScale)
            .flatMap(s => s.obj.isMultiBand ? s.obj.bands.filter(b => !b.noYScale).flatMap(b => b.data) : s.obj.data)
            .flat().map(v => v.value[1])
        const yScale = yAutomaticScaleValues(allValues)
        this.yScale = yScale

        const stat = this.props.piezometerStatistics.find(t => t.typeId === typeId)
        const unit = stat?.unit
        yAxis.push(Axis({
            type: displayLog ? 'log' : 'value',
            nameLocation: 'middle',
            name: `${stat?.label ?? ''} ${unit ? `[${unit}]` : ''}`,
            gridIndex: 0,
            nameGap: 40,
            showSplitLine: option && hasValue(option.displayYIntervalYear) ? option.displayYIntervalYear === '1' : true,
            inverse: displayCote && isPiezo ? displayCote !== MEASURE_COTE.NGF : false,
            isPiezo: isPiezo && !displayLog,
            yScale,
            ...setYOptionsPiezo(option, typeId, yScale, displayCote, landmarkValue),
            ...setLogOptions(displayLog, allValues),
            ...YZoom,
        }))
        otherAxis.forEach(axis => yAxis.push(axis))

        const gridTop = displayEvents ? 130 : 90
        const grids = [{
            top: gridTop,
            left: 100,
            right: `${4+yAxis.length}%`,
            height: this.state.height - gridTop - (parseInt(this.series.length / 3)*20),
        }]

        /// EVENTS
        if (displayEvents) {
            const events = stationEvents.filter(e => e.graph === '1' && e.eventType !== EVENT_TYPES_CODES.TECHNIQUE)
            const eventOptions = {
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        const eventsList = events.filter(e => {
                            const startDate = getDateWithHour(e.date, e.eventHour).valueOf()
                            const endDate = e.endDate && e.endDate - startDate > 20000000 ? e.endDate : startDate + 20000000
                            return startDate >= params.value[0] && endDate <= params.value[1] && e.graph == '1'
                        })
                        const labelEvents = eventsList.reduce((acc, v) => {
                            const comment = v.comment ? (chunkWithWords(v.comment, 40).replaceAll('\n', '<br />')) : i18n.event
                            return `${acc}<br />${getColorCircleEvent(v.eventType)}${comment}`
                        }, i18n.events)
                        return labelEvents
                    },
                },
                itemStyle: {
                    normal: {
                        opacity: 0.5,
                    },
                },
                yAxisIndex: yAxis.length,
                xAxisIndex: xAxis.length,
            }
            this.series.push(getEventGraph(events, eventOptions))
            this.series.push(getEventsBar(events, { yAxisIndex: yAxis.length, xAxisIndex: xAxis.length }))
            grids.push({
                top: 90,
                right: `${4+yAxis.length}%`,
                height: 40,
                left: 100,
            })
            xAxis.push(Axis({
                type: 'time',
                position: 'bottom',
                min: chartMinDate,
                max: chartMaxDate,
                interval: axisLabelObj.interval,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                gridIndex: 1,
            }))
            yAxis.push(Axis({
                type: 'value',
                data: [i18n.events],
                nameLocation: 'middle',
                minInterval: 1,
                nameGap: 40,
                position: 'right',
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                gridIndex: 1,
            }))
            yAxis.push(Axis({
                type: 'category',
                data: [i18n.events],
                nameLocation: 'middle',
                minInterval: 1,
                nameGap: 40,
                position: 'left',
                gridIndex: 1,
            }))
        }

        const options = {
            customTitle: {
                text: `[${piezometer.code}]${piezometer.name ? ` - [${piezometer.name}]` : ''}`,
                top: 55,
                left: 'center',
            },
            series: this.series.filter(s => ((s.obj.yAxisIndex ?? 0) <= (yAxis.length-1)) && ((s.obj.xAxisIndex ?? 0) <= (xAxis.length - 1))),
            xAxis,
            yAxis,
            legend: {
                bottom: 30,
                show: true,
                // type: 'scroll',
                data: this.series.flatMap(s => s.obj.bands ? s.obj.bands : [s.obj]).filter(s => !s.noLegend && s.name !== i18n.events)
                    .map(s => ({
                        name: s.name,
                        icon: ['fre', 'ENVELOPPE'].some(l => (s.code || '').includes(l)) ? 'roundRect' : 'circle',
                    })).filter(e => e.name !== i18n.enveloppeMin),
            },
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            tooltip: this.getTooltip(),
            setDataZoom: true,
            height: this.state.height,
            grid: grids,
            toolbox: {
                show: true,
                feature: {
                    restore: { title: i18n.restore },
                    saveAsImage: { title: i18n.export, icon: exportPictureIcon },
                    myToolExport: {
                        show: true,
                        title: i18n.excelExport,
                        icon: exportExcelIcon,
                        onclick: () => {
                            exportFile({
                                data: this.getExportData(chartMinDate, chartMaxDate),
                                exportType: 'xlsx',
                                titleFile: i18n.overview,
                            })
                        },
                    },
                    myLog: {
                        show: true,
                        title: i18n.logarithm,
                        icon: logIcon,
                        onclick: () => {
                            this.setState({ displayLog: !displayLog, openLogModal: true, messageLogModal: displayLog ? i18n.logarithmicScaleOff : i18n.logarithmicScaleActivated })
                        },
                    },
                    myZoom: {
                        show: true,
                        title: i18n.zoomVertical,
                        icon: swapVertical,
                        onclick: () => this.setState({ openYZoomModal: true }),
                    },
                },
                right: 200,
            },
        }
        return (
            <div style={ { marginLeft: 5, backgroundColor: 'white', overflow: 'hidden', position: 'relative' } } id='chart'>
                {this.getTabs()}
                <div>
                    <div style={{ position: 'absolute', left: 637, top: 11, zIndex: 200 }}>
                        <ChartOptionsChip
                            displayModes={displayModes}
                            displayCote={displayCote}
                            filterStatusQualif={filterStatusQualif}
                            displayModesKey={PIEZO_L_STORAGE.DISPLAY_MODES_SUIVI}
                            filterStatusQualifKey={PIEZO_L_STORAGE.STATUS_QUALIF_SUIVI}
                            changeParent={obj => {
                                if (obj.displayCote) {
                                    this.changeCote(obj)
                                } else {
                                    this.setState(obj, this.loadOtherDisplayMeasuresIfChecked)
                                }
                            }}
                            withInitialDisplay={false}
                            noAuto
                            withReferenceLevel={isPiezo}
                            ngfAndDepthOnly
                        />
                    </div>
                    <div style={{ position: 'absolute', left: 20, top: 10, zIndex: 200 }}>
                        <MUIChartTabs
                            time={time}
                            onChangeTime={(newTime) => {
                                this.setState({ ...newTime }, this.loadOtherDisplayMeasuresIfChecked)
                            }}
                            withSuiviTabs
                        />
                    </div>
                    <EChart
                        options={options}
                        id='piezometrySuiviChart'
                        bandCorrection={isPiezo && !displayLog}
                        withLogModal
                        openLogModal={openLogModal}
                        messageLogModal={messageLogModal}
                        onCloseLogModal={() => this.setState({ openLogModal: false })}

                        withYZoomModal
                        openYZoomModal={openYZoomModal}
                        YZoom={YZoom}
                        setYZoom={v => this.setState({ YZoom: v, openYZoomModal: false })}
                    />
                </div>
            </div>
        )
    }
}

PiezoSuiviChart2.propTypes = {
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    fetchPiezometerChartOptions: PropTypes.func,
    piezometerChartOptions: arrayOf(DtoPiezometerChartOptions),
    piezometerStatistics: arrayOf(DtoMeasureStats),
    stationEvents: PropTypes.arrayOf(DtoEvent),
}

const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    piezometerChartOptions: store.PiezometerStationReducer.piezometerChartOptions,
    piezometerStatistics: store.PiezometerStationReducer.piezometerStatistics,
    piezometers: store.PiezometryReducer.piezometersLight,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    stationEvents: store.EventsReducer.stationEvents,
})

const mapDispatchToProps = {
    fetchPiezometerChartOptions: PiezometerStationAction.fetchPiezometerChartOptions,
    fetchPiezoMeasuresStats: PiezometerStationAction.fetchPiezoMeasuresStats,
    push,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezoSuiviChart2)