import { arrayOf, getObjectLabel } from '../../../../../utils/StoreUtils'
import DtoPrettyAnalysisLight from '../../../dashboard/dto/DtoPrettyAnalysisLight'
import { groupBy, sumBy } from 'lodash'
import { shallowEqual, useSelector } from 'react-redux'
import { getSandreLabel } from '../../../../../utils/StringUtil'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import echarts from 'echarts/lib/echarts'
import ReactEchartsCore from 'echarts-for-react/lib/core'
import React from 'react'
import 'echarts/lib/chart/treemap'
import 'echarts/lib/chart/tree'

const getRemarkCodeWeight = (remark) => {
    switch (remark) {
        case '1':
            return 20
        case '2':
            return 1
        case '10':
            return 3
        case '7':
            return 5
        default:
            return 30
    }
}

const OperationTreeMapChart = ({
    analysis,
}) => {
    const {
        sandreCodes,
        parametersIndex,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        parametersIndex: store.ParameterReducer.parametersIndex,
    }), shallowEqual)

    const group = groupBy(analysis, 'remark')
    const chartData = Object.keys(group).map(remark => {
        const remarkLabel = getSandreLabel(sandreCodes, SANDRE.REMARK_CODE, remark)
        const remarkWeight = getRemarkCodeWeight(remark)
        const childs = group[remark].map(ana => {
            const name = getObjectLabel(parametersIndex[ana.parameter], 'shortLabelWithCode')
            return {
                value: remarkWeight,
                name,
                // path: `${remarkLabel}/${name}`,
            }
        })
        return {
            children: childs,
            value: sumBy(childs, 'value'),
            name: remarkLabel,
            // path: remarkLabel
        }
    })

    const options = {
        series: [
            {
                name: 'CodesRemarques',
                type: 'treemap',
                visibleMin: 0,
                // visibleMin: 300,
                label: {
                    show: true,
                    formatter: '{b}',
                },
                itemStyle: {
                    borderColor: '#fff',
                },
                levels: [
                    {
                        // itemStyle: {
                        //     borderColor: '#777',
                        //     borderWidth: 0,
                        //     gapWidth: 1
                        // },

                    },
                    {
                        itemStyle: {
                            borderColor: '#fff',
                            borderWidth: 5,
                            gapWidth: 1,
                        },
                        upperLabel: {
                            show: true,
                            color: '#555',
                        },
                        // emphasis: {
                        //     itemStyle: {
                        //         borderColor: '#ddd'
                        //     }
                        // }
                    },
                ],
                data: chartData,
            },
        ],
    }
    return (
        <ReactEchartsCore
            echarts={ echarts }
            option={ options }
            notMerge={ true }
            lazyUpdate={ true }
            className={ 'row no-margin' }
            style={ { height: 450 } }
        />
    )
}

OperationTreeMapChart.propTypes = {
    analysis: arrayOf(DtoPrettyAnalysisLight),
}

export default OperationTreeMapChart