import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import { Grid2 } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import { getVisitCheckBoxChange, getVisitSelectChange } from 'utils/VisitUtils'
import Input from 'components/forms/Input'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import Select from 'components/forms/Select'
import NumberField from 'components/forms/NumberField'
import DtoInstallationBorehole from 'installation/dto/borehole/DtoInstallationBorehole'
import { useSelector } from 'react-redux'

const BoreholeCharacteristicsPanel = ({
    onChangeInstallation = () => { },
    onChangeBorehole = () => { },
    onChangeVisit = () => { },
    borehole = {},
    installation = {},
    readMode = false,
    aquifer,
    onChangeAquifer = () => { },
}) => {
    const {
        aquifers,
        fieldModes,
        natures,
        watersheds,
    } = useSelector(store => ({
        aquifers: store.AquiferReducer.aquifers,
        fieldModes: store.StationReducer.fieldModes,
        natures: store.StationReducer.natures,
        watersheds: store.WatershedReducer.watersheds,
    }))
    return (
        <>
            <Grid2 size={6}>
                <Checkbox
                    checked={installation.exploitationCode == 1}
                    label={i18n.exploitation}
                    onChange={v => {
                        onChangeInstallation({ exploitationCode: v ? 1 : 0 })
                        onChangeVisit(getVisitCheckBoxChange(i18n.exploitation, installation.exploitationCode, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    label={i18n.waterTableAccompaniment}
                    checked={borehole.waterTableAccompaniment}
                    onChange={(v) => {
                        onChangeBorehole({ waterTableAccompaniment: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.waterTableAccompaniment, installation.waterTableAccompaniment, v))
                    }} disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={borehole.nature}
                    label={i18n.nature}
                    options={natures}
                    keyvalue='code'
                    onChange={v => {
                        onChangeBorehole({ nature: v })
                        onChangeVisit(getVisitSelectChange(natures, 'code', i18n.nature, borehole.nature, v))
                    }}
                    readMode={readMode}
                    integerValue
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={borehole.watershed} label={i18n.watershed} options={watersheds} keyValue='id' displayWithCode
                    onChange={v => {
                        onChangeBorehole({ watershed: v })
                        onChangeVisit(getVisitSelectChange(watersheds, 'id', i18n.watershed, borehole.watershed, v))
                    }} readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    title={i18n.depth}
                    value={borehole.depth}
                    floatValue
                    onChange={v => {
                        onChangeBorehole({ depth: v })
                        onChangeVisit({ previousValue: borehole.depth, newValue: v, field: i18n.depth })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={aquifer.aquiferCode}
                    label={i18n.aquifer}
                    options={aquifers}
                    keyValue='code'
                    onChange={v => {
                        onChangeAquifer(v)
                        onChangeVisit(getVisitSelectChange(aquifers, 'code', i18n.aquifer, aquifer.aquiferCode, v))
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    value={borehole.pumpType}
                    title={i18n.pumpType}
                    onChange={v => {
                        onChangeBorehole({ pumpType: v })
                        onChangeVisit({ previousValue: borehole.pumpType, newValue: v, field: i18n.pumpType })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={borehole.fieldMode}
                    label={i18n.fieldMode}
                    keyvalue='code'
                    options={fieldModes}
                    onChange={v => {
                        onChangeBorehole({ fieldMode: v })
                        onChangeVisit(getVisitSelectChange(fieldModes, 'code', i18n.fieldMode, borehole.fieldMode, v))
                    }} readMode={readMode} integerValue
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={borehole.hourWaterFlow}
                    title={i18n.hourWaterFlow}
                    onChange={v => {
                        onChangeBorehole({ hourWaterFlow: v })
                        onChangeVisit({ previousValue: borehole.hourWaterFlow, newValue: v, field: i18n.hourWaterFlow })
                    }}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={borehole.allowedHourWaterFlow}
                    title={i18n.allowedHourWaterFlow}
                    onChange={v => {
                        onChangeBorehole({ allowedHourWaterFlow: v })
                        onChangeVisit({ previousValue: borehole.allowedHourWaterFlow, newValue: v, field: i18n.allowedHourWaterFlow })
                    }}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={borehole.dayVolumeAllowed}
                    title={i18n.dayVolumeAllowed}
                    onChange={v => {
                        onChangeBorehole({ dayVolumeAllowed: v })
                        onChangeVisit({ previousValue: borehole.dayVolumeAllowed, newValue: v, field: i18n.dayVolumeAllowed })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={borehole.yearWaterFlow}
                    title={i18n.yearWaterFlow}
                    onChange={v => {
                        onChangeBorehole({ yearWaterFlow: v })
                        onChangeVisit({ previousValue: borehole.yearWaterFlow, newValue: v, field: i18n.yearWaterFlow })
                    }}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={borehole.allowedYearWaterVolume}
                    title={i18n.allowedYearWaterVolume}
                    onChange={v => {
                        onChangeBorehole({ allowedYearWaterVolume: v })
                        onChangeVisit({ previousValue: borehole.allowedYearWaterVolume, newValue: v, field: i18n.allowedYearWaterVolume })
                    }}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={6}>
                <SimpleTextArea
                    value={installation.comments}
                    title={i18n.comment}
                    onChange={v => {
                        onChangeInstallation({ comments: v })
                        onChangeVisit({ previousValue: installation.comments, newValue: v, field: i18n.comment })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    value={borehole.surfaceGeology}
                    title={i18n.surfaceGeology}
                    onChange={v => {
                        onChangeBorehole({ surfaceGeology: v })
                        onChangeVisit({ previousValue: borehole.surfaceGeology, newValue: v, field: i18n.surfaceGeology })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    value={borehole.cementationTubing}
                    title={i18n.cementationTubing}
                    onChange={v => {
                        onChangeBorehole({ cementationTubing: v })
                        onChangeVisit({ previousValue: borehole.cementationTubing, newValue: v, field: i18n.cementationTubing })
                    }} readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    title={`${i18n.pumpDepth}(${i18n.metersSymbol})`}
                    value={borehole.pumpDepth}
                    onChange={v => {
                        onChangeBorehole({ pumpDepth: v })
                        onChangeVisit({ previousValue: borehole.pumpDepth, newValue: v, field: `${i18n.pumpDepth}(${i18n.metersSymbol})` })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={borehole.overflowPresence}
                    label={i18n.overflowPresence}
                    onChange={v => {
                        onChangeBorehole({ overflowPresence: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.overflowPresence, borehole.overflowPresence, v))
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    title={`${i18n.drillingBlowRate} (m3/h)`}
                    value={borehole.drillingBlowRate}
                    onChange={v => {
                        onChangeBorehole({ drillingBlowRate: v })
                        onChangeVisit({ previousValue: borehole.drillingBlowRate, newValue: v, field: `${i18n.drillingBlowRate} (m3/h)` })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={borehole.gravitationalStation}
                    label={i18n.gravitationalStation}
                    onChange={v => {
                        onChangeBorehole({ gravitationalStation: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.gravitationalStation, borehole.gravitationalStation, v))
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    title={i18n.overflowRating}
                    value={borehole.overflowRating}
                    onChange={v => {
                        onChangeBorehole({ overflowRating: v })
                        onChangeVisit({ previousValue: installation.overflowRating, newValue: v, field: i18n.overflowRating })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

BoreholeCharacteristicsPanel.propTypes = {
    installation: PropTypes.object,
    borehole: PropTypes.instanceOf(DtoInstallationBorehole),
    aquifer: PropTypes.object,
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeInstallation: PropTypes.func,
    onChangeBorehole: PropTypes.func,
    onChangeAquifer: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default BoreholeCharacteristicsPanel
