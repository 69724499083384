/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import useSandreList from 'utils/customHook/useSandreList'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import SimpleTextArea from 'components/forms/SimpleTextArea'

const headers = ['localisation', 'protectionType', 'comment']

const ProtectionDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const protectionList = useSandreList(SANDRE.INSTALLATIONS_EMPLACEMENT)
    const placementList = useSandreList(SANDRE.INSTALLATIONS_PROTECTION)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.localisation}
                            options={placementList}
                            value={stateElement.placing}
                            onChange={v => onChangeElement({ placing: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.type}
                            options={protectionList}
                            value={stateElement.protectionType}
                            onChange={v => onChangeElement({ protectionType: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={stateElement.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

ProtectionDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}


const InstallationProtectionsPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_protections'
            formatFunction={c => ({
                ...c,
                protectionType: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_PROTECTION, c.protectionType, true),
                localisation: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_EMPLACEMENT, c.placing, true),
            })}
            titles={{
                title: i18n.noIntrudersProtections,
                edit: i18n.editProtection,
                new: i18n.newProtection,
            }}
            headers={headers}
            dialogContent={ProtectionDialogContent}
        />
    )
}

InstallationProtectionsPanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}


export default InstallationProtectionsPanel