import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import { getUser } from '../../../utils/SettingUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import CaptureCivilEngPanel from './components/CaptureCivilEngPanel'
import CaptureEquipmentsPanel from './components/CaptureEquipmentsPanel'
import DtoCaptureStation from './dto/DtoCaptureStation'
import QualityAction from '../../../quality/actions/QualityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import NumberField from '../../../components/forms/NumberField'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import CaptureDownstreamDistribUnitPanel from './components/CaptureDownstreamDistribUnitPanel'
import DistributionUnitAction from '../../../distributionUnit/actions/DistributionUnitAction'
import HydrogeologicalEntityAction from '../../../referencial/components/hydrogeologicalEntity/actions/HydrogeologicalEntityAction'
import InstallationEquipmentsPanel from '../installations/components/InstallationEquipmentsPanel'
import { downloadURI } from '../../../utils/ExportDataUtil'
import {
    getVisitSandreSelectChange,
    getVisitSelectChange,
} from '../../../utils/VisitUtils'
import { H_DISTRIBUTION_MODULE, H_PRODUCTION_MODULE } from '../../../account/constants/AccessRulesConstants'
import HabilitationRequired from '../../../utils/components/HabilitationRequired'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import ProductionUnitAction from 'productionUnit/actions/ProductionUnitAction'
import GenericInstallationDescription from '../installationDefault/GenericInstallationDescription'
import { Card, CardContent, Grid2 } from '@mui/material'
import { isDisplayed } from 'utils/InstallationUtils'
import { CardTitle } from 'components/card/NewCard'
import { greyBlue } from 'utils/constants/ColorTheme'

class CaptureStationDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            readMode: true,
            capture: new DtoCaptureStation({}),
        }
    }

    componentDidMount = () => {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            this.props.fetchInstallationCapture(this.props.installation.id).then(() => {
                this.setState({ capture: { ...this.props.installationCapture, idStation: this.props.installation.id } })
            })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        if (!this.props.captureAlimAreas.length) {
            this.props.fetchAllCaptureAlimAreas()
        }

        if (!this.props.captureDownstreamDistribUnits.length) {
            this.props.fetchCaptureDownstreamDistribUnits()
        }

        if (!this.props.hydrogeologicalEntities.length) {
            this.props.fetchHydrogeologicalEntities()
        }

        if (!this.props.productionUnits.length) {
            this.props.fetchProductionUnits()
        }

        if (!this.props.distributionUnits.length) {
            this.props.fetchDistributionUnits()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () => this.props.push('/installation'))
    }

    onSave = (visit) => {
        const { installation, capture } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    capture,
                },
            ).then(() => this.setReadOnlyMode())
        }

        return this.props.updateInstallationCapture(
            installation,
            capture,
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, capture: this.props.installationCapture })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) => this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangeCapture = (newObject) => this.setState({ capture: { ...this.state.capture, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    render() {
        const { installation, readMode, capture } = this.state
        const { sandreCodes, applicationSettings } = this.props
        const mode = { readMode, editMode: !readMode }
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
            sandreCode: SANDRE.EQUIPEMENTS_CAPTAGES,
        }

        const paramsCapture = {
            onChange: this.onChangeCapture,
            onChangeVisit: this.onChangeVisit,
            capture,
            readMode,
        }

        return (
            <GenericInstallationDescription
                readMode={readMode}
                installation={installation}
                onChangeInstallation={this.onChangeInstallation}
                onChangeVisit={this.onChangeVisit}
                descElements={
                    <>
                        <Grid2 size={6}>
                            <Select
                                value={capture.prodSite}
                                label={i18n.productionUnit}
                                options={this.props.productionUnits}
                                onChange={v => {
                                    this.onChangeCapture({ prodSite: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.productionUnits, 'id', i18n.productionUnit, capture.prodSite, v))
                                }}
                                keyValue='id'
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={capture.aacCode}
                                label={i18n.captureAlimArea}
                                options={this.props.captureAlimAreas}
                                keyvalue='code'
                                onChange={v => {
                                    this.onChangeCapture({ aacCode: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.captureAlimAreas, 'code', i18n.captureAlimArea, capture.aacCode, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={capture.sampleOrigin}
                                title={i18n.sampleOrigin}
                                onChange={v => {
                                    this.onChangeCapture({ sampleOrigin: v })
                                    this.onChangeVisit({ previousValue: capture.sampleOrigin, newValue: v, field: i18n.sampleOrigin })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={capture.qMoyJ}
                                title={'QMoyJ'}
                                onChange={v => {
                                    this.onChangeCapture({ qMoyJ: v })
                                    this.onChangeVisit({ previousValue: capture.qMoyJ, newValue: v, field: 'QMoyJ' })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={capture.waterNature}
                                label={i18n.waterNature}
                                keyvalue='code'
                                options={getSandreList(sandreCodes, SANDRE.NATURE_EAU)}
                                onChange={v => {
                                    this.onChangeCapture({ waterNature: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.NATURE_EAU, i18n.waterNature, capture.waterNature, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={capture.hydroEntityCode}
                                label={i18n.hydrogeologicalEntities}
                                keyvalue='code'
                                options={this.props.hydrogeologicalEntities}
                                onChange={v => {
                                    this.onChangeCapture({ hydroEntityCode: v })
                                    this.onChangeVisit(getVisitSelectChange(this.props.hydrogeologicalEntities, 'code', i18n.hydrogeologicalEntities, capture.hydroEntityCode, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        {isDisplayed(applicationSettings, 'CAPTURE_CIVIL_ENG', installation.installationType) && (
                            <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                                <Card>
                                    <CardTitle title={i18n.civilEngineering} color={greyBlue} />
                                    <CardContent>
                                        <Grid2 container columnSpacing={1}>
                                            <CaptureCivilEngPanel {...paramsCapture} />
                                            <Grid2 size={12}>
                                                <BuildingsPanel {...params} />
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        )}
                        {isDisplayed(applicationSettings, 'CAPTURE_EQUIPMENTS', installation.installationType) && (
                            <Grid2 size={12}>
                                <Card>
                                    <CardTitle title={i18n.equipments} color={greyBlue} />
                                    <CardContent>
                                        <CaptureEquipmentsPanel {...paramsCapture} />
                                        <Grid2 size={12}>
                                            <InstallationEquipmentsPanel className='padding-left-1 padding-right-1' {...params} />
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        )}
                    </>
                }
                otherElements={
                    <>
                        <HabilitationRequired habilitation={H_PRODUCTION_MODULE}>
                            <Grid2 size={12}>
                                <LinkedStationsPanel
                                    title={i18n.productionUnit}
                                    filteredStations={['productionUnit']}
                                    station={installation}
                                    {...mode}
                                />
                            </Grid2>
                        </HabilitationRequired>
                        <HabilitationRequired habilitation={H_DISTRIBUTION_MODULE}>
                            <Grid2 size={12}>
                                <Card>
                                    <CardTitle title={i18n.distributionUnit} color={greyBlue} />
                                    <CardContent>
                                        <CaptureDownstreamDistribUnitPanel station={installation} />
                                    </CardContent>
                                </Card>
                            </Grid2>
                        </HabilitationRequired>
                    </>
                }
                linkedStationTypes={['quality', 'piezometry', 'hydrometry', 'pluviometry', 'installation']}
            />
        )

        // <LinkedStationsPanel
        //     noMargin={false}
        //     className='margin-top-1'
        //     filteredStations={['quality', 'piezometry', 'hydrometry', 'pluviometry', 'installation']}
        //     onReMount={this.props.onReMount}
        //     station={installation}
        //     {...mode}
        // />
    }

    shouldComponentUpdate(_, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

CaptureStationDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    installationCapture: store.InstallationReducer.installationCapture,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    productionUnits: store.ProductionUnitReducer.productionUnits,
    captureAlimAreas: store.InstallationReducer.captureAlimAreas,
    hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
    captureDownstreamDistribUnits: store.DistributionUnitReducer.captureDownstreamDistribUnits,
    installationVisits: store.InstallationReducer.installationVisits,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    productionsUnits: store.ProductionUnitReducer.productionsUnits,
    distributionUnits: store.DistributionUnitReducer.distributionUnits,
})

const mapDispatchToProps = {
    push,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationCapture: InstallationAction.updateInstallationCapture,
    fetchInstallationCapture: InstallationAction.fetchInstallationCapture,
    fetchAllCaptureAlimAreas: InstallationAction.fetchAllCaptureAlimAreas,
    fetchCaptureDownstreamDistribUnits: DistributionUnitAction.fetchCaptureDownstreamDistribUnits,
    fetchHydrogeologicalEntities: HydrogeologicalEntityAction.fetchHydrogeologicalEntities,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
    fetchProductionUnits: ProductionUnitAction.fetchProductionUnits,
    fetchDistributionUnits: DistributionUnitAction.fetchDistributionUnits,
}

export default connect(mapStateToProps, mapDispatchToProps)(CaptureStationDescriptionPanel)
