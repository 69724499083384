import { shallowEqual, useSelector } from 'react-redux'
import { groupBy, minBy, maxBy, range } from 'lodash'
import MessageCard from '../../../../../components/card/MessageCard'
import i18n from 'simple-react-i18n'
import React from 'react'
import QualityOverviewTableDto from './QualityOverviewTableDto'
import { getLabel } from '../../../../../utils/StoreUtils'
import QualityOverviewTableStatsDto from './QualityOverviewTableStatsDto'
import { getDate } from '../../../../../utils/DateUtil'
import { getStatuses } from '../../../../../utils/QualityUtils'
import { nbPerPageLabel30 } from '../../../../../referencial/constants/ReferencialConstants'
import useActions from '../../../../../utils/customHook/useActions'
import PropTypes from 'prop-types'
import useListIndexed from 'utils/customHook/useListIndexed'
import { CardTable } from 'components/datatable/NewTable'

const getStatusColor = status => {
    switch (status) {
        case 3:
            return 'green'
        case 2:
            return 'lightgreen'
        case 4:
            return 'blue'
        default:
            return 'grey'
    }
}

const QualityIntegrationOverviewTable = ({
    operationResults = [],
    operationStats = [],
    stations = [],
}) => {
    const {
        citiesIndex,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const minYear = minBy(operationResults, 'year')?.year
    const maxYear = maxBy(operationResults, 'year')?.year
    const listOfYear = range(minYear, maxYear + 1, 1).reverse()

    const groups = groupBy(operationResults, 'id')
    const groupsStats = useListIndexed(operationStats)

    const data = stations.map(qualito => {
        const stats = groupsStats[qualito.id]

        const baseObj = {
            code: { value: qualito?.code, cellType: 'string' },
            name: { value: qualito?.name },
            city: { value: qualito?.townCode ? citiesIndex[qualito?.townCode]?.labelWithCode : '' },
            startDate: { value: getDate(stats?.start), format: 'dd/MM/yyyy', cellType: 'date' },
            endDate: { value: getDate(stats?.end), format: 'dd/MM/yyyy', cellType: 'date' },
            nbSample: { value: stats?.operations || 0 },
            analysis: { value: stats?.analysis || 0 },
        }
        const years = groups[qualito.id] || []
        years.forEach(yearObj => {
            baseObj[yearObj.year] = { value: yearObj.count, color: getStatusColor(yearObj.status), setTooltip: () => getLabel(getStatuses(), yearObj.status, undefined, undefined, i18n.brute) }
        })

        return baseObj
    })

    const headers = ['code', 'name', 'city', 'startDate', 'endDate', 'nbSample', 'analysis', ...listOfYear.map(y => `${y}`)]

    useActions(() => {
        return {
            export: () => {
                return {
                    data: data.length ? [
                        { ...data[0], headers },
                        ...data.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.integrationOverview,
                }
            },
        }
    }, [data])

    if (!operationResults.length) {
        return <MessageCard>{i18n.noOperationForTheseCriteria}</MessageCard>
    }

    return (
        <div className='padding-top-1'>
            <CardTable
                title={i18n.watchpoints}
                rows={data}
                headers={headers}
                rowsPerPageOptions={nbPerPageLabel30}
            />
        </div>
    )
}

QualityIntegrationOverviewTable.propTypes = {
    operationResults: PropTypes.arrayOf(PropTypes.instanceOf(QualityOverviewTableDto)),
    operationStats: PropTypes.arrayOf(PropTypes.instanceOf(QualityOverviewTableStatsDto)),
    stations: PropTypes.shape({/* qualitometer */}),
}

export default QualityIntegrationOverviewTable
