/* eslint-disable camelcase */
import { Button, Grid2 } from '@mui/material'
import { groupBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { getYear } from 'utils/DateUtil'
import AgriAction from '../../agriAdministration/actions/AgriAction'
import ServicesAccordion from './services/ServicesAccordion'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { useParams } from 'react-router-dom'
import ExploitationServicesPanel from './panels/ExploitationServicesPanel'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ProgressCard from 'components/card/ProgressCard'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import ToastrAction from 'toastr/actions/ToastrAction'
import ServicesFilters from './services/ServicesFilters'
import { searchAllCharacters } from 'utils/StringUtil'
import { servicesHeaders } from 'exploitations/utils/AgriUtils'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'

const ExploitationServiceApp = () => {
    const dispatch = useDispatch()

    const {
        servicesSituations,
        services,
        exploitation,
        contributor,
    } = useSelector(store => ({
        servicesSituations: store.AgriReducer.servicesSituations,
        services: store.AgriReducer.services,
        exploitation: store.AgriReducer.exploitation,
        contributor: store.ContributorReducer.contributor,
    }), shallowEqual)

    const [expanded, setExpanded] = useState(`panel_${getYear(moment.now())}`)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [calculationDate, setCalculationDate] = useState(moment.now())
    const [readMode, setReadMode] = useState(true)
    const [stateExploitation, setStateExploitation] = useState(exploitation)

    const codificationOCTAGRI = useApplicationSetting('codificationOCTAGRI')

    // filters
    const [search, setSearch] = useState()
    const [city, setCity] = useState()

    const { id } = useParams()

    useTitle(() => [
        {
            title: i18n.folders,
            href: 'dossiers',
        }, {
            title: `${contributor.name} ${exploitation.codification ? `[${exploitation.codification}]` : ''}`,
            href: `dossiers/${exploitation.idExploitation}/dashboard`,
        }, {
            title: i18n.services,
            href: `dossiers/${exploitation.idExploitation}/services`,
        },
    ], [exploitation.idExploitation, contributor])

    useEffect(() => {
        dispatch(AgriAction.fetchServicesSituations({ idExploitations: [parseInt(id)], codificationOCTAGRI })).then(() => setDataLoaded(true))
        dispatch(AgriAction.fetchExploitation(parseInt(id)))

        if (!services.length) {
            dispatch(AgriAction.fetchServices())
        }
    }, [])

    useEffect(() => {
        if (exploitation.link_services) {
            setStateExploitation(exploitation)
        }
    }, [exploitation.link_services])

    useEffect(() => {
        if (exploitation.operatorCode) {
            dispatch(ContributorAction.fetchContributor(exploitation.operatorCode))
        }
    }, [exploitation.operatorCode])

    useActions(() => {
        return readMode ? {
            edit: () => setReadMode(false),
        } : {
            cancel: () => {
                setReadMode(true)
                setStateExploitation(exploitation)
            },
            save: () => {
                dispatch(AgriAction.updateExploitation(stateExploitation)).then(() => {
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                    setReadMode(true)
                })
            },
        }
    }, [readMode, stateExploitation])

    const onChangeServices = (v) => {
        setStateExploitation({
            ...exploitation,
            ...v,
        })
    }

    const getHash = (service) => {
        return searchAllCharacters(servicesHeaders.map(key => service[key] || ''))
    }

    const filteredData = useMemo(() => {
        const searchValue = searchAllCharacters(search)
        const cityFilters = city ? servicesSituations.filter(ss => ss.cityCode === city) : servicesSituations
        return search ? cityFilters.filter(i => getHash(i).includes(searchValue)) : cityFilters
    }, [search, city, servicesSituations])

    const yearGrouped = groupBy(filteredData, d => getYear(d.calculationDate))

    return (
        <Grid2 container className='padding-left-1 padding-top-1 padding-right-1' sx={{ paddingBottom: '100px' }} spacing={1}>
            <Grid2 size={12}>
                <ExploitationServicesPanel
                    exploitation={stateExploitation}
                    readMode={readMode}
                    onChange={onChangeServices}
                />
            </Grid2>
            <Grid2 size={6}>
                <ServicesFilters setCity={setCity} setSearch={setSearch} />
            </Grid2>
            <Grid2 size={6}>
                <StyledFieldSet style={{ marginTop: 0 }}>
                    <StyledLegend>{i18n.actions}</StyledLegend>
                    <Grid2 container justifyContent='space-between' alignItems='center'>
                        <SimpleDatePicker
                            label={i18n.calculationDate}
                            value={calculationDate}
                            onChange={setCalculationDate}
                        />
                        <Button
                            variant='contained'
                            onClick={() => {
                                dispatch(AgriAction.calculateServicesSituation(calculationDate, [parseInt(id)], () => { }, () => {
                                    dispatch(AgriAction.fetchServicesSituations({ idExploitations: [parseInt(id)], codificationOCTAGRI }))
                                }))
                            }}
                        >
                            {i18n.calculate}
                        </Button>
                    </Grid2>
                </StyledFieldSet>
            </Grid2>
            {dataLoaded &&
                Object.keys(yearGrouped).reverse().map(yearKey => (
                    <ServicesAccordion
                        key={yearKey}
                        data={yearGrouped[yearKey]}
                        yearKey={yearKey}
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />
                )) || <Grid2 size={12}><ProgressCard indeterminate round /></Grid2>
            }
        </Grid2>
    )
}

export default ExploitationServiceApp
