import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getExport } from '../../../../utils/linkUtils'
import { hasBooleanValue } from '../../../../utils/NumberUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { greyBlue } from 'utils/constants/ColorTheme'
import { CardTable } from 'components/datatable/NewTable'
import useSandreList from 'utils/customHook/useSandreList'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import Checkbox from 'components/forms/Checkbox'
import SimpleTextArea from 'components/forms/SimpleTextArea'

const headers = ['name', 'integrated', 'comment']

const StaffSafetyDialog = ({
    idStation,
    selectedStaff = {},
    onChange = () => { },
    isOpen = false,
    setIsOpen = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [staffSafety, setStaffSafety] = useState({ ...selectedStaff, idStation })

    const onChangeElement = (changes) => setStaffSafety(prev => ({ ...prev, ...changes }))

    const onSave = () => {
        onChange(staffSafety)
        setIsOpen(false)
    }

    return (
        <Dialog
            maxWidth='lg'
            open={isOpen}
        >
            <DefaultDialogTitle
                title={`${i18n.staffSafety} : ${getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_PROTECTIONPERSONNEL, selectedStaff.code)}`}
                onClose={() => setIsOpen(false)}
            />
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid2 container spacing={1}>
                            <Grid2 size={6}>
                                <Checkbox
                                    checked={staffSafety.isIntegrated}
                                    label={i18n.integrated}
                                    onChange={v => onChangeElement({ isIntegrated: v })}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <SimpleTextArea
                                    title={i18n.comment}
                                    value={staffSafety.comment}
                                    onChange={v => onChangeElement({ comment: v })}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSave} variant='contained'>
                    {i18n.register}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

StaffSafetyDialog.propTypes = {
    idStation: PropTypes.number,
    selectedStaff: PropTypes.object,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

const InstallationStaffSafetyPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const [selectedStaff, setSelectedStaff] = useState({})
    const [isOpen, setIsOpen] = useState(false)

    const sandreList = useSandreList(SANDRE.INSTALLATIONS_PROTECTIONPERSONNEL)
    const stationStaffSafety = sandreList.map(element => {
        const data = station.link_staffSafeties.find(ss => element.code === ss.code) || {}
        return {
            ...element,
            ...data,
            comment: data.comment,
            integrated: hasBooleanValue(data.isIntegrated) ? data.isIntegrated ? i18n.yes : i18n.no : i18n.unknown,
        }
    })
    const exportAction = getExport(stationStaffSafety, i18n.staffSafety, headers)
    const actions = [exportAction]

    const lineActions = [{
        icon: 'edit',
        onClick: (element) => {
            setSelectedStaff(stationStaffSafety.find(c => element.code === c.code))
            setIsOpen(true)
        },
        displayed: !readMode,
    }]

    const updateElements = (newElement) => onChange({
        link_staffSafeties: [
            ...station.link_staffSafeties.filter(ss => ss.code !== newElement.code),
            newElement,
        ],
    })

    return (
        <>
            <CardTable
                title={i18n.staffSafety}
                rows={stationStaffSafety}
                headers={headers}
                actions={actions}
                color={greyBlue}
                lineActions={lineActions}
                rowsPerPageOptions={[{ value: 20, label: '' }]}
            />
            {isOpen && (
                <StaffSafetyDialog
                    idStation={station.id}
                    selectedStaff={selectedStaff}
                    onChange={updateElements}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
        </>
    )
}

InstallationStaffSafetyPanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default InstallationStaffSafetyPanel