import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Select from '../../components/forms/Select'
import DtoStation from '../dto/DtoStation'
import SimpleDatePicker from '../../components/forms/SimpleDatePicker'
import { Grid2 } from '@mui/material'
import { ButtonMUI } from '../../components/styled/Buttons'
import MultiContributorsAutocomplete
    from '../../referencial/components/contributor/components/MultiContributorsAutocomplete'
import { SELECTION, THRESHOLD } from '../../quality/constants/QualityConstants'
import ThresholdSelect from '../../components/forms/specific/ThresholdSelect'
import SelectionSelect from '../../components/forms/specific/SelectionSelect'
import SuperMultiAutocomplete from '../../components/forms/SuperMultiAutocomplete'
import { useDispatch, useSelector } from 'react-redux'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import CampaignAction from '../../campaign/actions/CampaignAction'
import { STATION_TYPE_NAME } from '../constants/StationConstants'
import Input from '../../components/forms/Input'

const StationsModelExportPanel = ({
    onApplyExportModel = () => {},
    onChange = () => {},
    selectedStations = [],
    model = null,
    modelTypesOptions = null,
    startDate = null,
    endDate = null,
    stationType = 'piezometry',
    producers = [],
    threshold = undefined,
    selectionCode = undefined,
    campaign = undefined,
    onChangeFilter = () => {},
    tmpFilter = {},
}) => {
    const dispatch = useDispatch()

    const { producersList, qualityCampaigns } = useSelector(store => ({
        producersList: store.ContributorReducer.producers,
        qualityCampaigns: store.QualityReducer.qualityCampaigns,
    }))

    useEffect(() => {
        dispatch(ContributorAction.fetchProducers())
        dispatch(CampaignAction.fetchQualityCampaigns())
    }, [])
    return (
        <div className='row no-margin padding-1'>
            <fieldset>
                <legend className='italic-label padding-right-1 padding-left-1'>{i18n.exportCriterias}</legend>
                <Grid2 container spacing={1} alignItems='center'>
                    <Grid2 size={3}>
                        <Select
                            label={i18n.model}
                            options={modelTypesOptions}
                            noNullValue
                            onChange={v => onChange({ model: v })}
                            value={model}
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <Input
                            title={ i18n.defineDirectory }
                            value={ tmpFilter.exportDir }
                            onChange={ v => onChangeFilter({ exportDir: v }) }
                        />
                    </Grid2>
                    <Grid2 size={2}>
                        <SimpleDatePicker label={i18n.startDate} value={startDate} onChange={v => onChange({ modelStartDate: v }) } />
                    </Grid2>
                    <Grid2 size={2}>
                        <SimpleDatePicker label={i18n.endDate} value={endDate} onChange={v => onChange({ modelEndDate: v }) } />
                    </Grid2>
                    <Grid2 size={3}>
                        <ButtonMUI style={{ width: '100%' }} onClick={() => onChange({ selectStationIsOpen: true })}>{`${i18n.selectStations} (${selectedStations.length})`}</ButtonMUI>
                    </Grid2>
                    { stationType !== STATION_TYPE_NAME.quality ? undefined : (
                        <>
                            <Grid2 size={3}>
                                <MultiContributorsAutocomplete
                                    label={i18n.producer}
                                    options={producersList}
                                    onChange={v => onChange({ producers: v })}
                                    values={producers}
                                    multiple
                                />
                            </Grid2>
                            <Grid2 size={2}>
                                <ThresholdSelect
                                    selected={threshold}
                                    onChange={v => onChange({ threshold: v })}
                                    thresholdType={THRESHOLD.PC}
                                />
                            </Grid2>
                            <Grid2 size={2}>
                                <SelectionSelect
                                    value={selectionCode}
                                    onChange={(res, s) => {
                                        onChange({ selectionCode: s, parameters: res })
                                    }}
                                    type={SELECTION.PC}
                                />
                            </Grid2>
                            <Grid2 size={2}>
                                <SuperMultiAutocomplete
                                    label={i18n.campaign}
                                    options={qualityCampaigns}
                                    values={campaign}
                                    onChange={v => {
                                        onChange({ campaign: v })
                                    }
                                    }
                                />
                            </Grid2>
                        </>
                    ) }
                    <Grid2 size={3} offset={stationType === STATION_TYPE_NAME.quality ? undefined : 10}>
                        <ButtonMUI fullWidth onClick={() => onApplyExportModel(modelTypesOptions.find(m => m.value === model).name)}>{i18n.apply}</ButtonMUI>
                    </Grid2>
                </Grid2>
            </fieldset>
        </div>
    )
}


StationsModelExportPanel.propTypes = {
    onApplyExportModel: PropTypes.func,
    onChange: PropTypes.func,
    selectedStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStation)),
    model: PropTypes.number,
    modelTypesOptions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    displayCote: PropTypes.number,
    stationType: PropTypes.string,
    producers: PropTypes.arrayOf(PropTypes.number),
    threshold: PropTypes.number,
    selectionCode: PropTypes.string,
    campaign: PropTypes.number,
    onChangeFilter: PropTypes.func,
    tmpFilter: PropTypes.shape({}),
}

export default StationsModelExportPanel
