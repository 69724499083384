import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import DtoRemark from '../../../quality/components/operation/dto/DtoRemark'
import OperationAction from '../../../quality/components/operation/actions/OperationAction'
import Axis from '../../../components/echart/Axis'
import { getDate, getFullDate } from '../../../utils/DateUtil'
import i18n from 'simple-react-i18n'
import {
    exportExcelIcon,
    exportPictureIcon,
    getTimeAxisInterval,
    logIcon,
    setLogOptions,
    toEchartsData,
} from '../../../components/echart/EChartUtils'
import { exportFile } from '../../../utils/ExportDataUtil'
import EChart from '../../../components/echart/EChart'
import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { fixedNumber, hasValue, round } from '../../../utils/NumberUtil'
import { arrayOf, getLabel, objectOf } from '../../../utils/StoreUtils'
import { max, min, take } from 'lodash'
import Line from '../../../components/echart/series/Line'
import { getColorFromPalettePluvio } from '../../../utils/ColorUtil'
import DtoHydroMeasures from '../../dto/chronicMeasures/DtoHydroMeasures'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import HydrometryAction from '../../actions/HydrometryAction'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'
import CardToMuchData from 'components/card/CardToMuchData'
import { statusIcon } from 'utils/StatusUtil'
import { renderToString } from 'react-dom/server'

const FORMAT_DATA = 'DD/MM/YYYY HH:mm:ss'
const MAX_MEASURES = 15000

const HydrometryGraphicChart = ({
    filter = {},
    graphicOptions = {},
}) => {
    const {
        hydrometryDataTypes,
        hydroMeasures,
        hydrosIndex,
    } = useSelector(store => ({
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        hydroMeasures: store.HydrometryReducer.hydroMeasures,
        hydrosIndex: store.HydrometryReducer.hydrosIndex,
    }), shallowEqual)

    const [showAllData, setShowAllData] = useState(false)
    const [logActive, setLogActive] = useState(false)

    const nbMaxMeasures = hydroMeasures.reduce((acc, hydroMeasure) => acc + hydroMeasure.measures.length, 0)
    const maxMeasuresAuthorized = MAX_MEASURES / hydroMeasures.filter(h => h.measures.length).length

    const limitData = !showAllData && (MAX_MEASURES < nbMaxMeasures)

    const data = useMemo(() => {
        if (!limitData) {
            return hydroMeasures
        }
        return hydroMeasures.map(h => ({
            ...h,
            measures: take(h.measures, maxMeasuresAuthorized),
        }))
    }, [limitData, hydroMeasures])

    const nbMeasuresShowed = limitData ? MAX_MEASURES : nbMaxMeasures

    const dataTypeLabel = getLabel(hydrometryDataTypes, filter.dataType, null, 'id')
    const dataType = hydrometryDataTypes.find(h => h.id === filter.dataType)
    const unit = dataType?.unit ? `[${dataType?.unit}]` : ''


    const exportData = useMemo(() => {
        const type = `${dataTypeLabel}${unit}`
        return data.flatMap(obj => {
            const station = hydrosIndex[obj.stationId]
            return obj.measures.map(m => ({
                stationCode: station.code,
                stationName: station.name,
                date: moment(m.date).format(FORMAT_DATA),
                value: fixedNumber(m.value),
                type,
                headers: ['stationCode', 'stationName', 'date', 'value', 'type'],
            }))
        })
    }, [data, dataTypeLabel, hydrosIndex, unit])

    const getMeasuresWithDisplayMode = (stationMeasures) => {
        switch (filter.displayMode) {
            case 'relative':
                const diff = stationMeasures[0]?.value[1]
                return hasValue(diff) ? stationMeasures.map(v => v.value[1] === null ? v : ({ ...v, value: [v.value[0], round(v.value[1] - diff), v.value[2], v.value[3]] })) : stationMeasures
            case 'percent':
                if (!stationMeasures.length) {
                    return stationMeasures
                }
                const values = stationMeasures.map(v => v.value[1]).filter(v => hasValue(v))
                const maxValue = max(values)
                const minValue = min(values)
                return stationMeasures.map(v => ({ ...v, value: v.value[1] === null ? v : [v.value[0], round((v.value[1] - minValue)*100/(maxValue-minValue)), v.value[2], v.value[3]] }))
            default:
                return stationMeasures
        }
    }

    const lineSeries = useMemo(() => data.map((p, idx) => {
        const hydro = hydrosIndex[p.stationId]
        const echartMeasures = toEchartsData(p.measures, p)
        const measuresDisplay = getMeasuresWithDisplayMode(echartMeasures)
        return Line({
            name: `${hydro?.code} - ${hydro?.name}`,
            data: measuresDisplay,
            color: getColorFromPalettePluvio(idx),
            showSymbol: false,
        })
    }), [data, hydrosIndex])

    const tooltip = useMemo(() => ({
        trigger: 'axis',
        formatter: params => {
            const date = getFullDate(params[0].value[0])
            const paramsOrder = params.reverse().filter(o => hasValue(o.value[1])).map(o => ({
                marker: o.marker,
                seriesName: o.seriesName,
                value: o.value[1],
                unit,
                status: renderToString(statusIcon(o.value[2], 20)),
            }))
            const result = paramsOrder.map(o => (`<span style="display:flex;align-items:center;">${o.marker} ${o.seriesName} : ${fixedNumber(o.value)} ${unit} <span style="padding-left:5px">${o.status}</span> </span>`)).join('')
            return `${date}<br />${result}`
        },
    }), [])

    const title = graphicOptions.graphicTitle || i18n.overlayGraphic

    const options = useMemo(() => ({
        series: lineSeries,
        tooltip,
        title,
        grid: {
            top: '5%',
            right: '10%',
            left: '10%',
            height: 320,
            bottom: 250,
        },
        xAxis: [Axis({
            type: 'time',
            boundaryGap: true,
            splitLine: {
                show: hasValue(graphicOptions.showXSplitLines) ? graphicOptions.showXSplitLines : true,
            },
            min: filter.startDate,
            max: filter.endDate,
            axisLabel: {
                show: true,
                formatter: value => getDate(value),
            },
            maxInterval: getTimeAxisInterval(graphicOptions.intervalChoice),
        })],
        yAxis: [Axis({
            type: logActive ? 'log' : 'value',
            splitLine: {
                interval: 10,
                show: hasValue(graphicOptions.showYSplitLines) ? graphicOptions.showYSplitLines : true,
            },
            nameLocation: 'middle',
            nameGap: 40,
            scale: true,
            name: `${dataTypeLabel} ${unit}`,
            min: graphicOptions.minY,
            max: graphicOptions.maxY,
            ...setLogOptions(logActive),
        })],
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        dataZoom: [
            {
                bottom: 0,
                type: 'slider',
                start: 0,
                end: 100,
            },
            {
                type: 'inside',
                start: 0,
                end: 100,
            },
        ],
        height: 660,
        gridHeight: 590,
        toolbox: {
            show: true,
            feature: {
                restore: { title: i18n.restore },
                saveAsImage: {
                    title: i18n.pictureExport,
                    icon: exportPictureIcon,
                },
                myToolExport: {
                    show: true,
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: () => {
                        exportFile({
                            data: exportData,
                            exportType: 'xlsx',
                            titleFile: title,
                        })
                    },
                },
                myLog: {
                    show: filter.displayMode === 'normal',
                    title: i18n.logarithm,
                    icon: logIcon,
                    onclick: () => setLogActive(!logActive),
                },
            },
            right: 50,
            top: 20,
        },
        legend: {
            bottom: 50,
            show: true,
        },
    }), [dataTypeLabel, exportData, filter.endDate, filter.startDate, graphicOptions.intervalChoice, graphicOptions.maxY, graphicOptions.minY, graphicOptions.showXSplitLines, graphicOptions.showYSplitLines, lineSeries, title, tooltip, unit, logActive])

    return (
        <>
            {limitData && (
                <CardToMuchData
                    nbElements={nbMaxMeasures}
                    maxNbElements={nbMeasuresShowed}
                    forceDisplay={() => setShowAllData(true)}
                />
            )}
            <div id='chart' className='row no-margin' style={{ paddingTop: '20px' }}>
                <EChart options={ options }/>
            </div>
        </>
    )
}

HydrometryGraphicChart.propTypes = {
    filter: PropTypes.shape({
        graphicType: PropTypes.string,
        stacked: PropTypes.bool,
        dataType: PropTypes.number,
        startDate: PropTypes.number,
        displayMode: PropTypes.string,
        endDate: PropTypes.number,
    }),
    hydroMeasures: arrayOf(DtoHydroMeasures),
    hydrometricStations: PropTypes.instanceOf(DtoHydrometricStation),
    hydrosIndex: objectOf(DtoHydrometricStation),
    hydrometryDataTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoParametrageDataType)),
    fetchHydrometryDataTypes: PropTypes.func,
    remarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoRemark)),
    fetchRemarks: PropTypes.func,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    graphicOptions: PropTypes.shape({
        graphicTitle: PropTypes.string,
        minY: PropTypes.number,
        maxY: PropTypes.number,
        intervalChoice: PropTypes.string,
        showXSplitLines: PropTypes.bool,
        showYSplitLines: PropTypes.bool,
    }),
}

const mapDispatchToProps = {
    fetchHydrometryDataTypes: HydrometryAction.fetchHydrometryDataTypes,
    fetchRemarks: OperationAction.fetchRemarks,
}

export default connect(null, mapDispatchToProps)(HydrometryGraphicChart)