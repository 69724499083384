import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import moment from 'moment'
import i18n from 'simple-react-i18n'
import { find, orderBy } from 'lodash'
import ProfileCardList from '../../../../components/list/cardList/ProfileCardList'
import UserDto from '../dto/UserDto'
import ActionComponent from 'components/ActionComponent'
import UserAction from '../actions/UserAction'
import KeyFigurePanel from 'components/keyFigures/KeyFigurePanel'
import DtoKeyFigure from '../../../../station/dto/DtoKeyFigure'
import DtoCreateUser from '../dto/DtoCreateUser'
import { getProfileCard, getSetting, getUser } from '../../../../utils/SettingUtils'
import ContactItem from '../../../../referencial/components/contact/dto/ContactItem'
import ContributorItem from '../../../../referencial/components/contributor/dto/ContributorItem'
import { getFullDateMini } from 'utils/DateUtil'
import SieauParameterDto from '../../../dto/SieauParameterDto'
import Checkbox from '../../../../components/forms/Checkbox'
import { getI18nTitleData, searchAllCharacters } from '../../../../utils/StringUtil'
import HomeAction from 'home/actions/HomeAction'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import AddUserModal from './AddUserModal'
import ProgressCard from 'components/card/ProgressCard'
import { contentsPath } from '../../../../conf/basepath'
import WaitAction from 'wait/WaitAction'
import FormFilterUsers from './FormFilterUsers'

const $ = window.$

const emptyFilter = {
    searchValue: '',
    contributors: [],
    city: [],
    isAdmin: false,
    metadata: false,
    labo: false,
    consultant: false,
    applicativeUsers: false,
}

class UsersApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            dataApplicativeLoaded: false,
            user: new DtoCreateUser({}),
            tmpFilter: this.getFilter(),
            filter: this.getFilter(),
            isOpen: false,
            allUsersFiltered: [],
            formattedActivateUsers: [],
            formattedDisableUsers: [],
            formattedInActivationsUsers: [],
            formattedApplicativeUsers: [],
            dontSendMail: false,
        }
    }

    getFilter = () => {
        const filter = emptyFilter
        const { globalResearch } = this.props
        return {
            ...filter,
            searchValue: globalResearch,
        }
    }

    componentDidMount() {
        const { tmpFilter } = this.state
        const { cities, applicativeUsers } = this.props
        this.props.setTitle([
            {
                title: i18n.administration,
                href: 'administration',
            },
            {
                title: i18n.users,
                href: 'administration/user',
            },
        ])
        this.props.setHelpLink('administration', '92')
        if (!cities.length) {
            this.props.fetchCities()
        }
        if (!this.props.users.length) {
            this.props.fetchUsers().then(() => {
                this.setDatasUsers(tmpFilter)
            })
        } else {
            this.setDatasUsers(tmpFilter)
        }
        if (!applicativeUsers.length) {
            this.props.fetchApplicativeUsers().then(() => {
                this.setDatasApplicativeUsers(tmpFilter)
            })
        } else {
            this.setDatasApplicativeUsers(tmpFilter)
        }
        if (getUser()) {
            this.props.fetchUser(localStorage.getItem('SIEAU_LOGIN')).then(() => {
                this.setActionsUsers()
            })
        } else {
            this.setActionsUsers()
        }
        this.props.updateGlobalResearch('')
    }


    setDatasUsers = (filters) => {
        const { users, contacts } = this.props
        const { contributors, cities, isAdmin, metadata, labo, consultant } = filters

        const usersFilteredByOrganization = contributors?.length > 0 ? users.filter(user => contributors.includes(user.contributorCode)) : users
        const usersFilteredByCity = cities?.length > 0 ? usersFilteredByOrganization.filter(user => {
            const contact = find(contacts, (o) => {
                return o.code == user.contactCode
            })
            return (contact && cities.includes(contact.cityCode)) && user
        }) : usersFilteredByOrganization

        const usersFilteredByAdmin = isAdmin ? usersFilteredByCity.filter(user => user.admin === '1') : usersFilteredByCity
        const usersFilteredByAdministrator = metadata ? usersFilteredByAdmin.filter(user => user.metadata === '1') : usersFilteredByAdmin
        const usersFilteredByTechnicien = labo ? usersFilteredByAdministrator.filter(user => user.labo === '1') : usersFilteredByAdministrator
        const usersFilteredByConsultant = consultant ? usersFilteredByTechnicien.filter(user => user.consultant === '1') : usersFilteredByTechnicien

        const usersWithContactData = this.formatUsersForTables(usersFilteredByConsultant)

        const dataActivateUsers = this.getFilteredData(usersWithContactData.filter((u) => this.checkBlockUser(u) === 0), filters)
        const dataDisableUsers = this.getFilteredData(usersWithContactData.filter((u) => this.checkBlockUser(u) === 1), filters)
        const dataInActivationsUsers = this.getFilteredData(usersWithContactData.filter((u) => this.checkBlockUser(u) === 2), filters)

        const formattedActivateUsers = dataActivateUsers.length > 25 ? dataActivateUsers : this.getCardListFormatted(dataActivateUsers)
        const formattedDisableUsers = dataDisableUsers.length > 25 ? dataDisableUsers : this.getCardListFormatted(dataDisableUsers)
        const formattedInActivationsUsers = dataInActivationsUsers.length > 25 ? dataInActivationsUsers : this.getCardListFormatted(dataInActivationsUsers)
        this.setState({
            dataLoaded: true,
            allUsersFiltered: usersFilteredByCity,
            formattedActivateUsers,
            formattedDisableUsers,
            formattedInActivationsUsers,
        })
    }

    setDatasApplicativeUsers = (filters) => {
        const { applicativeUsers, contacts } = this.props
        const { contributors, cities, isAdmin, metadata, labo, consultant } = filters


        const usersFilteredByOrganization = contributors?.length > 0 ? applicativeUsers.filter(user => contributors.includes(user.contributorCode)) : applicativeUsers
        const usersFilteredByCity = cities?.length > 0 ? usersFilteredByOrganization.filter(user => {
            const contact = find(contacts, (o) => {
                return o.code == user.contactCode
            })
            return (contact && cities.includes(contact.cityCode)) && user
        }) : usersFilteredByOrganization

        const usersFilteredByAdmin = isAdmin ? usersFilteredByCity.filter(user => user.admin === '1') : usersFilteredByCity
        const usersFilteredByAdministrator = metadata ? usersFilteredByAdmin.filter(user => user.metadata === '1') : usersFilteredByAdmin
        const usersFilteredByTechnicien = labo ? usersFilteredByAdministrator.filter(user => user.labo === '1') : usersFilteredByAdministrator
        const usersFilteredByConsultant = consultant ? usersFilteredByTechnicien.filter(user => user.consultant === '1') : usersFilteredByTechnicien

        const filteredApplicativeUsers = this.getFilteredData(usersFilteredByConsultant, filters)

        const formattedApplicativeUsers = filteredApplicativeUsers.length > 25 ? filteredApplicativeUsers : this.getCardListFormatted(filteredApplicativeUsers)

        this.setState({
            dataApplicativeLoaded: true,
            formattedApplicativeUsers,
        })
    }

    setActionsUsers = () => {
        const actions = {
            export: () => {
                return {
                    data: this.exportUsers(),
                    exportType: 'xlsx',
                    titleFile: i18n.users,
                }
            },
        }
        if (!(getUser().consultant === '1')) {
            actions.new = () => this.onAddUserPopup()
        }
        this.setActions(actions)
    }

    exportUsers = () => {
        const data = orderBy(this.props.users, ['login'], ['asc']).reduce((acc, user) => {
            const index = this.checkBlockUser(user)
            const contact = find(this.props.contacts, (o) => {
                return o.code == user.contactCode
            })
            const contributor = find(this.props.contributors, (o) => {
                return o.code == user.contributorCode
            })

            const showedAdmin = user.admin === '1' ? i18n.admin : null
            const showedBlocked = user.consultant ? i18n.consultant : null
            const showedMetadata = user.metadata === '1' ? i18n.referencialManager : null
            const nbMaxConnection = getSetting(this.props.applicationSettings, 'maximumNumberTry')
            const blockedUser =
                nbMaxConnection && user.connexionAttempts >= nbMaxConnection
                    ? i18n.blockedUser
                    : null
            const showedLabo = user.labo === '1' ? i18n.technicien : null
            const showedConsulting =
                (!user.admin || user.admin == '0') &&
                    (!user.labo || user.labo == '0') &&
                    (!user.metadata || user.metadata == '0')
                    ? i18n.consultant
                    : null
            const habilitation = [
                showedAdmin,
                showedBlocked,
                showedMetadata,
                showedLabo,
                blockedUser,
                showedConsulting,
            ].filter((i) => !!i)

            const result = {
                name: contact ? contact.name : '',
                login: user.login,
                email: contact ? contact.email : '',
                beginDate: user.beginDate ? getFullDateMini(user.beginDate) : '',
                endDate: user.endDate ? getFullDateMini(user.endDate) : '',
                contributor: contributor ? contributor.name : '',
                lastConnection: user.lastConnexion ? getFullDateMini(user.lastConnexion) : '',
                access: habilitation.join(', '),
                state:
                    index === 0
                        ? i18n.users
                        : index === 1
                            ? i18n.disableUsers
                            : i18n.activationProgress,
            }
            acc.push(result)
            return acc
        }, [])
        if (data.length) {
            data[0].headers = [
                'login',
                'name',
                'email',
                'beginDate',
                'endDate',
                'lastConnection',
                'contributor',
                'access',
                'state',
            ]
        }
        return data
    }

    changeUser = (login) => {
        this.props.push(`/administration/user/${login}`)
        $('.tooltipped').tooltip('remove')
        $('.tooltipped').tooltip()
    }

    onAddUserPopup = () => {
        this.setState({ isOpen: true })
    }

    onCloseAddUserModal = () => {
        this.setState({ isOpen: false })
    }

    addUser = (user) => {
        const { dontSendMail } = this.state
        this.props.addUser(user, dontSendMail).then(() => {
            this.props.waitStart()
            this.props.fetchUsers().then(() => {
                this.props.waitStop()
                this.props.push(`/administration/user/${user.login}`)
            })
        })
    }

    checkBlockUser = (user) => {
        if (user.endDate) {
            const format = 'DD/MM/YYYY'
            const formatEnDate = moment(moment(user.endDate).format(format), format)
            const formatNowDate = moment(moment().format(format), format)
            return formatEnDate.isSameOrAfter(formatNowDate) ? 0 : 1
        } else if (user.resetPassword) {
            return 2
        }
        return 0
    }

    formatUsersForTables = (users) => {
        const { contacts, contributors } = this.props
        const data = orderBy(users, ['login'], ['asc']).map((user) => {
            const contact = find(contacts, (o) => {
                return o.code == user.contactCode
            })
            const contributor = find(contributors, (o) => {
                return o.code == user.contributorCode
            })
            const name = contact ? contact.name : ''
            const mail = contact ? contact.email : ''
            const mobile = contact ? contact.mobile : ''
            const tel = contact ? contact.phoneTel : ''
            const contributorName = contributor ? contributor.name : ''
            return {
                ...user,
                name,
                lastConnexion: getFullDateMini(user.lastConnexion),
                mail,
                mobile,
                tel,
                organism: contributorName,
                admin: <Checkbox checked={user.admin === '1'} disabled={true} />,
                metadata: <Checkbox checked={user.metadata === '1'} disabled={true} />,
            }
        })
        return data
    }

    getCardListFormatted = (users) => {
        const { contacts, contributors, applicationSettings } = this.props
        return orderBy(users, ['login'], ['asc']).map(
            (user) => {
                const contact = find(contacts, (o) => {
                    return o.code == user.contactCode
                })
                const contributor = find(contributors, (o) => {
                    return o.code == user.contributorCode
                })
                const name = contact ? contact.name : ''
                const tooltipeContributor =
                    contributor && contributor.name
                        ? { className: 'tooltipped truncate', 'data-tooltip': contributor.name }
                        : { className: 'truncate' }
                const mnemonique =
                    contributor && (contributor.mnemonique || contributor.name) ? (
                        <span {...tooltipeContributor}>
                            {contributor.mnemonique ? contributor.mnemonique : contributor.name}
                        </span>
                    ) : null
                const picture = user.pictureName ? `${contentsPath}PHOTOS/${user.pictureName}` : ''
                const result = {
                    ...user,
                    name,
                    mnemonique,
                    picture,
                }
                return {
                    content: getProfileCard(
                        result,
                        getSetting(applicationSettings, 'maximumNumberTry') || 5,
                    ),
                    onClick: () => this.changeUser(result.login),
                }
            },
        )
    }

    getFilteredData = (data, filters) => {
        const search = searchAllCharacters(filters.searchValue || '')
        return data.filter((i) => this.getHash(i).includes(search))
    }
    getHash = (user) => {
        return searchAllCharacters(user.headers.map((key) => user[key]))
    }

    resetFilters = () => {
        this.setState({
            tmpFilter: emptyFilter,
            filter: emptyFilter,
        })
        this.setDatasUsers(emptyFilter)
        this.setDatasApplicativeUsers(emptyFilter)
    }

    onValidate = (tmpFilter) => {
        this.setState({ filter: tmpFilter })
        this.setDatasUsers(tmpFilter)
        this.setDatasApplicativeUsers(tmpFilter)
    }

    render() {
        const { dataLoaded, dataApplicativeLoaded, filter, allUsersFiltered, formattedActivateUsers, formattedInActivationsUsers, formattedDisableUsers,
            formattedApplicativeUsers, dontSendMail } = this.state

        const keyFigureData = [
            new DtoKeyFigure({
                title: 'users',
                value: allUsersFiltered.length,
            }),
            new DtoKeyFigure({
                title: 'admins',
                value: allUsersFiltered.filter((user) => user.admin).length,
            }),
        ]

        return (
            <div className='row no-margin'>
                <div className='col s10 padding-top-1'>
                    <FormFilterUsers onValidate={this.onValidate}/>
                    {dataLoaded && dataApplicativeLoaded ? (
                        <ul
                            className='sieau-collapsible no-border no-box-shadow'
                            data-collapsible='accordion'
                        >
                            {!filter.applicativeUsers ? (
                                <>
                                    <ProfileCardList
                                        title={`${i18n.users} : ${formattedActivateUsers.length} ${getI18nTitleData(i18n.element, i18n.elements, formattedActivateUsers)}`}
                                        users={formattedActivateUsers}
                                        onClickTable={this.changeUser}
                                        collapsible
                                        open
                                    />
                                    <ProfileCardList
                                        title={`${i18n.activationProgress} : ${formattedInActivationsUsers.length} ${getI18nTitleData(i18n.element, i18n.elements, formattedInActivationsUsers)}`}
                                        users={formattedInActivationsUsers}
                                        onClickTable={this.changeUser}
                                        collapsible
                                    />
                                    <ProfileCardList
                                        title={`${i18n.disableUsers} : ${formattedDisableUsers.length} ${getI18nTitleData(i18n.element, i18n.elements, formattedDisableUsers)}`}
                                        users={formattedDisableUsers}
                                        onClickTable={this.changeUser}
                                        collapsible
                                    />
                                </>
                            ) : (
                                <ProfileCardList
                                    title={`${i18n.usersApplication} : ${formattedApplicativeUsers.length} ${getI18nTitleData(i18n.element, i18n.elements, formattedApplicativeUsers)}`}
                                    users={formattedApplicativeUsers}
                                    onClickTable={this.changeUser}
                                    collapsible
                                />
                            )}
                        </ul>
                    ) : <ProgressCard className='padding-top-1' indeterminate round /> }
                </div>
                <div className='col s2'>
                    <KeyFigurePanel data={keyFigureData} round />
                </div>
                <AddUserModal
                    addUser={this.addUser}
                    dontSendMail={dontSendMail}
                    onChangeSendMail={(v) => this.setState({ dontSendMail: v })}
                    isOpen={this.state.isOpen}
                    onClose={this.onCloseAddUserModal}
                />
            </div>
        )
    }
}

UsersApp.propTypes = {
    users: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    applicativeUsers: PropTypes.arrayOf(PropTypes.instanceOf(UserDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactItem)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    getLink: PropTypes.func,
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    fetchUsers: PropTypes.func,
    fetchApplicativeUsers: PropTypes.func,
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    fetchCities: PropTypes.func,
    setHelpLink: PropTypes.func,
    setTitle: PropTypes.func,
    fetchUser: PropTypes.func,
    addUser: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    push: PropTypes.func,
    globalResearch: PropTypes.string,
    updateGlobalResearch: PropTypes.func,
}

const mapStateToProps = (store) => {
    return {
        users: store.UserReducer.users,
        applicativeUsers: store.UserReducer.applicativeUsers,
        contacts: store.ContactReducer.contacts,
        contributors: store.ContributorReducer.contributors,
        applicationSettings: store.AdministrationReducer.applicationSettings,
        cities: store.CityReducer.cities,
        globalResearch: store.HomeReducer.globalResearch,
    }
}

const mapDispatchToProps = {
    addUser: UserAction.addUser,
    fetchUsers: UserAction.fetchUsers,
    fetchApplicativeUsers: UserAction.fetchApplicativeUsers,
    fetchCities: CityAction.fetchCities,
    setHelpLink: HomeAction.setHelpLink,
    setTitle: HomeAction.setTitle,
    fetchUser: UserAction.fetchUser,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    updateGlobalResearch: HomeAction.updateGlobalResearch,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersApp)
