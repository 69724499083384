import hydrometryMarker from 'assets/pictures/markers/map_hydro.png'
import piezometryMarker from 'assets/pictures/markers/map_piezo.png'
import pluviometryMarker from 'assets/pictures/markers/map_pluvio.png'
import { push } from '@lagunovsky/redux-react-router'
import { reduce } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import ProgressBar from '../../../components/progress/ProgressBar'
import ApplicationConf from '../../../conf/ApplicationConf'
import HomeAction from '../../../home/actions/HomeAction'
import { PATH_ALERT } from '../../../home/constants/RouteConstants'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import DtoHydroMeasures from '../../../hydrometry/dto/chronicMeasures/DtoHydroMeasures'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoHydrometryThreshold from '../../../hydrometry/dto/DtoHydrometryThreshold'
import LogAction from '../../../log/actions/LogAction'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import { MEASURE_COTE } from '../../../piezometry/constants/PiezometryConstants'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoPiezometerChartLandmarks from '../../../piezometry/dto/situation/DtoPiezometerChartLandmarks'
import DtoPiezometrySituations from '../../../piezometry/dto/situation/DtoPiezometrySituations'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import PluviometerThresholdDto from '../../../pluviometry/dto/PluviometerThresholdDto'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import StationAction from '../../../station/actions/StationAction'
import DtoAssociatedStation from '../../../station/dto/DtoAssociatedStation'
import DtoPiezoThreshold from '../../../station/dto/piezometer/DtoPiezoThreshold'
import AppStore from '../../../store/AppStore'
import { getLocalStorageJson } from '../../../utils/FormUtils'
import { getUser } from '../../../utils/SettingUtils'
import { execByType, findStationType, getStationTypeCodeFromType } from '../../../utils/StationUtils'
import { arrayOf } from '../../../utils/StoreUtils'
import { geti18n } from '../../../utils/StringUtil'
import { getTrendLevel, TrendLevelObject } from '../../../utils/TrendLevelUtil'
import AlertAction from '../../actions/AlertAction'
import { DESCRIPTION } from '../../constants/AlertConstants'
import { H24, H48, ONE_MONTH, ONE_WEEK, ONE_YEAR, SIX_MONTHS } from '../../constants/ChartFollowContants'
import DtoMeasure from '../../dto/alert/DtoMeasure'
import { getLastMeasuresFormat, getLastMeasuresPiezoConverted, getMeasureArrayValues, getThresholdValue } from '../../utils/MeasureUtil'
import AlertMapPopin from '../dashboard/AlertMapPopin'
import AssociatedPanel from './associated/AssociatedPanel'
import FollowChartPanel from './chart/FollowChartPanel'


class FollowApp extends ActionComponent {
    state = {
        selectedSites: [],
        selectedSite: {},
        progress: 0,
        beginDate: moment().subtract('1', 'week'),
        endDate: moment(),
        selectedTime: ONE_WEEK,
        dataLoaded: false,
        associatedSites: [],
        panel: DESCRIPTION,
        sortBy: 'date',
        selectedSiteEvents: [],
        selectedSiteCampaigns: [],
        selectedSiteThreshold: [],
        piezometerCote: getLocalStorageJson('DISPLAY_COTE') || MEASURE_COTE.DEPTH,
        piezometryAllDataTypes: [],
        hydrometryAllDataTypes: [],
        pluviometryAllDataTypes: [],
        pluvioGrouped: 'SUM_AUTO',
    }

    componentDidMount() {
        const alreadyFetchedData = {
            hydrometers: this.props.hydrometers.length,
            hydroLastMeasures: this.props.hydroLastMeasures.length,
            hydrometryThresholds: this.props.hydrometryThresholds.length,
            pluviometerLastMeasures: this.props.pluviometerLastMeasures.length,
            pluviometerAllThresholds: this.props.pluviometerAllThresholds.length,
            piezometersWithCampaignsAndEvents: this.props.piezometersWithCampaignsAndEvents.length,
            piezometerThresholds: this.props.piezometerThresholds.length,
            piezometryMaxSituation: this.props.piezometryMaxSituation.length,
        }
        const notFetchedData = reduce(alreadyFetchedData, (result, value, key) => {
            if (!value) {
                result.push(key)
            }
            return result
        }, [])
        if (notFetchedData.length) {
            this.props.loadSituationSuperposition(notFetchedData, p => this.setState({ progress: p }))
                .then(() => {
                    const site = this.getSite()
                    const siteType = getStationTypeCodeFromType(site.typeName)
                    this.fetchAssociatedSites(site, siteType)
                }).then(() => {
                    this.props.fetchPiezometerChartLandmarks(this.props.piezometersWithCampaignsAndEvents.map(s => s.id))
                })
        } else {
            const site = this.getSite()
            const siteType = getStationTypeCodeFromType(site.typeName)
            this.fetchAssociatedSites(site, siteType)
        }
        this.props.fetchDataTypes('SIES').then(piezometryAllDataTypes => this.setState({ piezometryAllDataTypes: piezometryAllDataTypes ? piezometryAllDataTypes : [] }))
        this.props.fetchDataTypes('SIH').then(hydrometryAllDataTypes => this.setState({ hydrometryAllDataTypes: hydrometryAllDataTypes ? hydrometryAllDataTypes : [] }))
        this.props.fetchDataTypes('SIP').then(pluviometryAllDataTypes => this.setState({ pluviometryAllDataTypes: pluviometryAllDataTypes ? pluviometryAllDataTypes : [] }))
        const user = getUser()
        if (user.admin === '1' || user.metadata === '1') {
            this.setActions({ new: () => this.props.navigateTo('/alert/newCms') })
        }
    }

    componentDidUpdate(prevProps) {
        $('ul.tabs').tabs()
        const site = this.getSite()
        if (site && prevProps.params.id !== site.code) {
            this.setTitle(site)
        }
    }

    fetchAssociatedSites = (site, siteType) => {
        this.props.fetchAssociatedSites(site.code, siteType).then(() => {
            this.setTitle(site)
            const associatedList = [
                site,
                ...this.props.associatedSites.map(m => this.props.sites.find(s => s.code === m.code)),
            ]
            this.setState({ selectedSites: associatedList, dataLoaded: true })
            this.fetchSiteMeasures(associatedList)
        })
    }

    setTitle = (site = {}) => {
        const siteName = (({ name, code }) => {
            if (name) {
                if (code) {
                    return name ? `[${code}] - ${name} ${geti18n(site.typeName)}` : `[${code}]`
                }
                return name
            }
            return ''
        })(site)
        this.props.setTitle([
            {
                title: i18n.situation,
                href: PATH_ALERT,
            },
            {
                title: `${i18n.followUp} ${siteName}`,
            },
        ])
    }

    componentWillUnmount() {
        this.props.resetFollowSite()
    }

    changeSelectedDateValue = value => {
        const beginDate = (() => {
            switch (value) {
                case H24:
                    return moment(this.state.endDate).subtract('1', 'days')
                case H48:
                    return moment(this.state.endDate).subtract('2', 'days')
                case ONE_WEEK:
                    return moment(this.state.endDate).subtract('1', 'week')
                case ONE_MONTH:
                    return moment(this.state.endDate).subtract('1', 'month')
                case SIX_MONTHS:
                    return moment(this.state.endDate).subtract('6', 'month')
                case ONE_YEAR:
                    return moment(this.state.endDate).subtract('1', 'year')
                default:
                    return null
            }
        })()
        const newState = beginDate ? {
            selectedTime: value,
            beginDate,
            endDate: moment(),
        } : {
            selectedTime: value,
            beginDate: undefined,
            endDate: undefined,
        }
        this.setState(newState, () => {
            this.fetchSiteMeasures(this.state.selectedSites)
        })
    }


    getSite = () => {
        const { followSite, addFollowSite } = this.props
        const { params } = this.props
        if (followSite && params.id === followSite.code) {
            return followSite
        }
        const site = this.props.sites.find(o => o.code === params.id && o.typeName === params.type)
        if (site) {
            const {
                beginDate,
                endDate,
            } = this.state
            addFollowSite(site, beginDate.valueOf(), endDate.valueOf())
        }
        return site
    }

    fetchSiteMeasures = stations => {
        const { beginDate, endDate, pluvioGrouped } = this.state
        this.props.waitStart()
        const promises = stations.map(station => { // add piezometerCote action in displauCote
            return AlertAction.loadStationMeasures(station, beginDate ? beginDate.valueOf() : null, endDate ? endDate.valueOf() : null, pluvioGrouped, this.state.piezometerCote).then(measures => {
                const monthlyMeasures = measures.map(measure => {
                    if (station.typeName === 'piezometry') {
                        // const { lastLandmark, groundRefAlti } = this.props.piezometerChartLandmarks.find(s => s.id === station.id) || {}
                        return {
                            date: measure[0],
                            value: measure[1],
                        }
                    } else if (station.typeName === 'hydrometry') {
                        return {
                            date: measure[0],
                            value: measure[1],
                            typeId: measure[2],
                        }
                    } else if (station.typeName === 'pluviometry') {
                        return {
                            date: measure[0],
                            value: measure[1],
                        }
                    }
                    return null
                })
                return { ...station, measures: monthlyMeasures }
            })
        })
        Promise.all(promises).then(newSites => {
            const selectedSites = this.state.selectedSites.map(s => {
                const found = newSites.find(site => s.code === site.code && s.typeName === site.typeName)
                return found ? found : s
            })
            this.setState({ selectedSites }, () => this.props.waitStop())
        }).catch((err) => {
            AppStore.dispatch(WaitAction.waitStop())
            AppStore.dispatch(LogAction.logError(`${i18n.fetchError + i18n.measures} : ${err}`))
            AppStore.dispatch(ToastrAction.error(i18n.fetchError + i18n.measures))
        })
    }

    changeSelectedSite = site => {
        if (this.state.selectedSites.some(s => s.code === site.code && s.typeName === site.typeName)) {
            this.setState({
                selectedSites: this.state.selectedSites.filter(s => !(s.code === site.code && s.typeName === site.typeName)),
            })
        } else {
            this.setState({
                selectedSites: [
                    ...this.state.selectedSites,
                    site],
            })
            this.fetchSiteMeasures([site])
        }
    }

    formatSite = site => {
        const { piezometryAllDataTypes, hydrometryAllDataTypes, pluviometryAllDataTypes } = this.state
        const foundPiezoDataType = piezometryAllDataTypes.find(d => d.id === -1)
        const foundHydroDataType = hydrometryAllDataTypes.find(d => d.id === -1)
        const foundPluvioDataType = pluviometryAllDataTypes.find(d => d.id === -1)
        const dateNow = moment()
        const nbHoursBefore = execByType(site.typeName, {
            piezometry: () => foundPiezoDataType?.alertLimit || 120,
            hydrometry: () => foundHydroDataType?.alertLimit || 120,
            pluviometry: () => foundPluvioDataType?.alertLimit || 120,
            default: () => 120,
        })
        const dateBeforeNbHours = moment(dateNow.subtract(nbHoursBefore, 'hour'))
        const { lastMeasure, thresholds, lastLandmark, groundRefAlti } = execByType(site.typeName, {
            hydrometry: () => ({
                lastMeasure: getLastMeasuresFormat(site.id, this.props.hydroLastMeasures, 'hydrometry'),
                thresholds: this.props.hydrometryThresholds.filter(t => t.stationId === site.id),
            }),
            pluviometry: () => ({
                lastMeasure: getLastMeasuresFormat(site.id, this.props.pluviometerLastMeasures, 'pluviometry'),
                thresholds: this.props.pluviometerAllThresholds.filter(t => t.code === site.code),
            }),
            piezometry: () => {
                const chartLandmarks = this.props.piezometerChartLandmarks.find(s => s.id === site.id) || {}
                return {
                    lastLandmark: chartLandmarks.lastLandmark,
                    groundRefAlti: chartLandmarks.groundRefAlti,
                    lastMeasure: getLastMeasuresPiezoConverted(site.id, this.props.piezometryMaxSituation, chartLandmarks.lastLandmark, chartLandmarks.groundRefAlti),
                    thresholds: this.props.piezometerThresholds.filter(t => t.code === site.code),
                }
            },
            default: () => ({
                lastMeasure: [],
                thresholds: [],
            }),
        })
        const trendObject = TrendLevelObject(
            getMeasureArrayValues(lastMeasure),
            getThresholdValue(thresholds),
        )
        const markerIcon = execByType(site.typeName, {
            hydrometry: () => hydrometryMarker,
            pluviometry: () => pluviometryMarker,
            piezometry: () => piezometryMarker,
            default: () => '',
        })
        const measureDate = lastMeasure[0] && moment(lastMeasure[0].measureDate)
        const trend = getTrendLevel(trendObject)
        const headband = (() => {
            if ((measureDate && measureDate.isBefore(dateBeforeNbHours)) || !lastMeasure.length) {
                return 'GREY'
            }
            switch (trend) {
                case 3:
                    return 'RED'
                case 2:
                    return 'ORANGE'
                default:
                    return 'GREEN'
            }
        })()
        return {
            ...site,
            lastLandmark,
            groundRefAlti,
            lastMeasure,
            measureDate,
            trend,
            thresholds,
            siteType: findStationType(site.typeName).code,
            markerIcon,
            headband,
        }
    }

    getSites = () => this.props.sites.map(this.formatSite)

    getAssociatedSites = sites => {
        return this.props.associatedSites.map(associatedSite => sites.find(s => s.code === associatedSite.stationLinkedCode && s.typeName === associatedSite.typeName)).filter(a => !!a).filter(a => a.typeName !== 'quality' && a.typeName !== 'installation' && a.typeName !== 'productionUnit')
    }

    getProgressBar = () => (
        <Card>
            <div className='padding-top-2 padding-left-2 padding-right-2 padding-bottom-2'>
                <ProgressBar progress={this.state.progress} withMessage />
            </div>
        </Card>
    )

    getUrls = site => {
        if (site.typeName) {
            const siteId = site.id
            switch (site.typeName) {
                case 'piezometry':
                    return [
                        ApplicationConf.piezometer.events(siteId),
                        ApplicationConf.piezometer.campaigns(siteId),
                        ApplicationConf.piezometer.thresholds(siteId),
                    ]
                case 'pluviometry':
                    return [
                        ApplicationConf.pluviometry.events(siteId),
                        ApplicationConf.pluviometry.campaigns(siteId),
                        ApplicationConf.pluviometry.threshold(siteId),
                    ]
                case 'hydrometry':
                    return [
                        ApplicationConf.hydrometricStation.events(siteId),
                        ApplicationConf.hydrometricStation.campaigns(siteId),
                        ApplicationConf.hydrometricStation.hydrometricThreshold(siteId),
                    ]
                default:
                    return undefined
            }
        }
        return undefined
    }

    onGetPopinInfo = (site, panelToOpen, eventsSortBy) => {
        const urls = this.getUrls(site)
        if (urls) {
            this.props.waitStart()
            this.props.loadStationData(urls)
                .then(jsonTab => {
                    this.setState({
                        selectedSiteEvents: jsonTab[0],
                        selectedSiteCampaigns: jsonTab[1],
                        selectedSiteThreshold: jsonTab[2],
                        selectedSite: site,
                        panel: panelToOpen,
                        sortBy: eventsSortBy ? eventsSortBy : 'date',
                    })
                    this.props.waitStop()
                })
        } else {
            this.setState({
                selectedSite: site,
                panel: panelToOpen,
            })
        }
    }

    onClosePopin = () => {
        this.setState({ selectedSite: {}, panel: DESCRIPTION })
    }

    render() {
        if (this.state.dataLoaded) {
            const {
                selectedTime,
                endDate,
                beginDate,
                selectedSite,
                selectedSites,
                selectedSiteEvents,
                selectedSiteCampaigns,
                selectedSiteThreshold,
            } = this.state
            const [followSite] = selectedSites
            if (followSite && followSite.id) {
                const sites = this.getSites()
                const associatedSites = this.getAssociatedSites(sites)
                return (
                    <div className='row no-margin' key={'follow'}>
                        <div className='col s8 no-padding'>
                            <FollowChartPanel
                                listOfSites={ selectedSites }
                                changeSelectedDateValue={this.changeSelectedDateValue}
                                changeParentState={ changes => {
                                    if ((changes.beginDate && changes.endDate) || changes.pluvioGrouped) {
                                        this.setState(changes, () => this.fetchSiteMeasures(selectedSites))
                                    } else {
                                        this.setState(changes)
                                    }
                                }}
                                selectedTime={selectedTime}
                                endDate={endDate}
                                beginDate={beginDate}
                                onChangeCote={ v => this.setState({ piezometerCote: v }, () => this.fetchSiteMeasures(selectedSites)) }
                            />
                        </div>
                        <div className='col s4'>
                            <div className='row no-margin'>
                                <div className='col s12 no-padding'>
                                    <Card className='transparent no-box-shadow scrollable-card height-90p'>
                                        <AssociatedPanel
                                            site={this.formatSite(followSite)}
                                            changeSelectedSite={this.changeSelectedSite}
                                            onSelectSite={(site, panelToOpen, eventsSortBy) => this.onGetPopinInfo(site, panelToOpen, eventsSortBy)}
                                            selectedSites={selectedSites}
                                            sites={sites}
                                            associatedSites={associatedSites}
                                        />
                                    </Card>
                                </div>
                            </div>
                        </div>
                        <AlertMapPopin
                            siteData={{
                                selectedSite,
                                selectedSiteEvents,
                                selectedSiteCampaigns,
                                selectedSiteThreshold,
                            }}
                            onClose={ this.onClosePopin }
                            citiesIndex={ this.props.citiesIndex }
                            navigateTo={this.props.navigateTo}
                            onReload={(site, panel) => this.onGetPopinInfo(site, panel)}
                        />
                    </div>
                )
            }
        }
        return this.getProgressBar()
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.isWait) {
            return false
        }
        return true
    }
}

FollowApp.propTypes = {
    params: PropTypes.instanceOf({
        id: PropTypes.string,
        type: PropTypes.string,
    }),
    followSite: PropTypes.instanceOf(PropTypes.object),
    followMeasures: arrayOf(DtoMeasure),
    accountHabilitations: PropTypes.arrayOf(PropTypes.object),
    sites: PropTypes.arrayOf(PropTypes.object),
    hydrometers: arrayOf(DtoHydrometricStation),
    hydroLastMeasures: arrayOf(DtoHydroMeasures),
    hydrometryThresholds: arrayOf(DtoHydrometryThreshold),
    pluviometers: PropTypes.instanceOf(PluviometerDto),
    pluviometerAllThresholds: arrayOf(PluviometerThresholdDto),
    pluviometerLastMeasures: arrayOf(DtoMeasure),
    piezometersWithCampaignsAndEvents: arrayOf(DtoPiezometer),
    piezometerThresholds: arrayOf(DtoPiezoThreshold),
    piezometryMaxSituation: arrayOf(DtoPiezometrySituations),
    associatedSites: arrayOf(DtoAssociatedStation),
    fetchHydrometricThresholds: PropTypes.func,
    fetchHydrometrySituation: PropTypes.func,
    fetchHydrometers: PropTypes.func,
    fetchPluviometers: PropTypes.func,
    fetchPluviometerAllThresholds: PropTypes.func,
    fetchAllPiezometers: PropTypes.func,
    fetchAllPiezometerThreshold: PropTypes.func,
    fetchPiezometrySituation: PropTypes.func,
    fetchAssociatedSites: PropTypes.func,
    addFollowSite: PropTypes.func,
    resetFollowSite: PropTypes.func,
    navigateTo: PropTypes.func,
    setTitle: PropTypes.func,
    loadSituationSuperposition: PropTypes.func,
    loadStationData: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    citiesIndex: PropTypes.object,
    fetchPiezometerChartLandmarks: PropTypes.func,
    piezometerChartLandmarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerChartLandmarks)),
    isWait: PropTypes.func,
    fetchDataTypes: PropTypes.func,
}

const mapStateToProps = store => ({
    accountHabilitations: store.AccountReducer.accountHabilitations,
    followSite: store.AlertReducer.followSite,
    followMeasures: store.AlertReducer.followMeasures,
    hydrometers: store.HydrometryReducer.hydrometricStations,
    hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
    hydroLastMeasures: store.HydrometryReducer.hydroLastMeasures,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometerAllThresholds: store.PluviometryReducer.pluviometerAllThresholds,
    pluviometerLastMeasures: store.PluviometryReducer.pluviometerLastMeasures,
    piezometersWithCampaignsAndEvents: store.PiezometryReducer.piezometersWithCampaignsAndEvents,
    piezometerThresholds: store.PiezometerStationReducer.allPiezometerThresholds,
    piezometryMaxSituation: store.PiezometryReducer.piezometryMaxSituation,
    associatedSites: store.StationReducer.associatedSites,
    sites: [
        ...store.HydrometryReducer.hydrometricStations,
        ...store.PluviometryReducer.pluviometersWithCampaignsAndEvents,
        ...store.PiezometryReducer.piezometersWithCampaignsAndEvents,
    ],
    citiesIndex: store.CityReducer.citiesIndex,
    piezometerChartLandmarks: store.PiezometryReducer.piezometerChartLandmarks,
    isWait: store.WaitReducer.isWait,
})

const mapDispatchToProps = {
    addFollowSite: AlertAction.addFollowSite,
    resetFollowSite: AlertAction.resetFollowSite,
    fetchHydrometers: HydrometryAction.fetchHydrometricStations,
    fetchHydrometricThresholds: HydrometryAction.fetchHydrometricThresholds,
    fetchHydrometrySituation: HydrometryAction.fetchHydrometrySituation,
    fetchPluviometers: PluviometryAction.fetchPluviometers,
    fetchPluviometerAllThresholds: PluviometryAction.fetchPluviometerAllThresholds,
    fetchAllPiezometers: PiezometryAction.fetchAllPiezometers,
    fetchAllPiezometerThreshold: PiezometerStationAction.fetchAllPiezometerThreshold,
    fetchPiezometrySituation: PiezometryAction.fetchPiezometrySituation,
    fetchAssociatedSites: StationAction.fetchAssociatedSites,
    setTitle: HomeAction.setTitle,
    loadSituationSuperposition: AlertAction.loadSituationSuperposition,
    navigateTo: push,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    loadStationData: AlertAction.loadStationData,
    fetchPiezometerChartLandmarks: PiezometryAction.fetchPiezometerChartLandmarks,
    fetchDataTypes: StationAction.fetchDataTypes,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FollowApp)
