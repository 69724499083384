import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Input from 'components/forms/Input'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { template } from 'lodash'
import ToastrAction from 'toastr/actions/ToastrAction'
import StationMapDashboardPanel from 'station/components/dashboard/component/map/StationMapDashboardPanel'
import LinkedStationsPanel from 'station/components/associatedStation/LinkedStationsPanel'
import StationUpdatePanel from 'station/components/update/StationUpdatePanel'
import ResourceAction from 'resource/actions/ResourceAction'
import ResourceDto from 'resource/dto/ResourceDto'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import Select from 'components/forms/Select'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { CardContent, Grid2, Card } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'

const ResourceDescriptionPanel = ({
    id,
}) => {
    const {
        resourceProps,
    } = useSelector(store => ({
        resourceProps: store.ResourceReducer.resource,
    }), shallowEqual)

    const [readMode, setReadMode] = useState(true)
    const [resource, setResource] = useState({})
    const resourceTypes = useSandreList(SANDRE.MILIEU_EAU)

    const dispatch = useDispatch()

    const onChangeResource = value => {
        setResource({ ...resource, ...value })
    }

    const onSave = () => {
        if (!resource.code) {
            ToastrAction.error(template(i18n.fieldNotSet)({ field: i18n.code }))
        } else {
            dispatch(ResourceAction.updateResource(resource))
            setReadMode(true)
        }
    }

    useEffect(() => {
        if (resourceProps && resourceProps.id) {
            setResource(resourceProps)
        } else {
            dispatch(ResourceAction.fetchResource(id))
        }
    }, [resourceProps])

    useActions(() => {
        if (!readMode) {
            return {
                cancel: () => setReadMode(true),
                save: onSave,
            }
        }
        return {
            edit: () => setReadMode(false),
            delete: () => dispatch(ResourceAction.deleteResource(id)),
        }
    }, [resource, readMode])

    return (
        <Grid2 container spacing={1} sx={{ marginBottom: '100px', padding: '10 10 0 20' }} alignItems='flex-start'>
            <Grid2 container size={9} columnSpacing={1}>
                <Grid2 size={12}>
                    <Card>
                        <CardTitle title={i18n.description} />
                        <CardContent>
                            <Grid2 container columnSpacing={1}>
                                <Grid2 size={6}>
                                    <Input
                                        value={resource.code}
                                        title={i18n.code}
                                        disabled
                                        onChange={v => onChangeResource({ code: v })}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        value={resource.name}
                                        title={i18n.name}
                                        disabled={readMode}
                                        maxLength={25}
                                        onChange={v => onChangeResource({ name: v })}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        value={resource.nature}
                                        title={i18n.nature}
                                        disabled={readMode}
                                        onChange={v => onChangeResource({ nature: v })}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Select
                                        options={resourceTypes}
                                        label={i18n.resourceType}
                                        nullLabel='&nbsp;'
                                        onChange={v => onChangeResource({ resourceType: v.toString() })}
                                        value={resource.resourceType}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <SimpleTextArea
                                        style={{ margin: '4px 0 6px' }}
                                        noMargin={false}
                                        rows={5}
                                        value={resource.descriptif}
                                        title={i18n.descriptif}
                                        onChange={v => onChangeResource({ descriptif: v })}
                                        disabled={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <SimpleTextArea
                                        style={{ margin: '4px 0 6px' }}
                                        noMargin={false}
                                        rows={5}
                                        value={resource.exploitationComment}
                                        title={i18n.exploitationComment}
                                        onChange={v => onChangeResource({ exploitationComment: v })}
                                        disabled={readMode}
                                    />
                                </Grid2>
                            </Grid2>
                        </CardContent>
                    </Card>
                </Grid2>
            </Grid2>
            <Grid2 container size={3} columnSpacing={1}>
                <Grid2 size={12}>
                    <StationUpdatePanel station={resource} />
                </Grid2>
                <Grid2 size={12}>
                    <StationMapDashboardPanel
                        noMarkerTooltip
                        station={resource}
                        type={'resource'}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <LinkedStationsPanel
                        noMargin={false}
                        station={resourceProps}
                        {...{ readMode, editMode: !readMode }}
                    />
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

ResourceDescriptionPanel.propTypes = {
    id: PropTypes.number,
    fetchResource: PropTypes.func,
    updateResource: PropTypes.func,
    resource: PropTypes.instanceOf(ResourceDto),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

export default ResourceDescriptionPanel
