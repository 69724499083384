import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Job from '../../../import/dto/DtoJob'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import PiezometerStationAction from '../../../station/actions/PiezometerStationAction'
import JobAlert from './JobAlert'
import { MEASURE_COTE } from '../../../piezometry/constants/PiezometryConstants'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../store/AppStore'
import { getThresholdFormat } from '../../utils/MeasureUtil'

const DEFAULT_PIEZO_DATA_TYPE = '-1'

class AlertPiezo extends Component {
    componentDidMount() {
        this.props.fetchAllPiezometers()
        this.props.fetchAllPiezometerThreshold(MEASURE_COTE.DEPTH)
        if (!this.props.piezometryDataTypes.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
    }

    render() {
        const {
            piezometryDataTypes,
            piezometerThresholds,
            job,
            isEditMode,
            piezometers,
            onChangeEmails,
            onChangeContactsIds,
            onChangeStation,
            onChangeAlertTypes,
        } = this.props
        const formatThresholds = piezometerThresholds.map(t => {
            const dt = piezometryDataTypes?.find(d => d.id === parseInt(t.dataType ?? DEFAULT_PIEZO_DATA_TYPE))
            return dt ? getThresholdFormat(t, dt) : {}
        })
        return (
            <JobAlert
                stationType='piezometry'
                job={job}
                dataTypes={piezometryDataTypes}
                isEditMode={isEditMode}
                stations={piezometers}
                thresholds={formatThresholds}
                onChangeEmails={onChangeEmails}
                onChangeContactsIds={onChangeContactsIds}
                onChangeStation={onChangeStation}
                onChangeAlertTypes={onChangeAlertTypes}
                defaultDt={-1}
            />
        )
    }
}

AlertPiezo.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeEmails: PropTypes.func,
    onChangeContactsIds: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeAlertTypes: PropTypes.func,
    piezometers: PropTypes.arrayOf(PropTypes.object),
    piezometerThresholds: PropTypes.arrayOf(PropTypes.object),
    fetchAllPiezometers: PropTypes.func,
    fetchAllPiezometerThreshold: PropTypes.func,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
}

const mapStateToProps = store => ({
    piezometers: store.PiezometryReducer.piezometers.map(({ id, code, name }) => ({
        id,
        code,
        name: `[${code}] ${name}`,
    })),
    piezometerThresholds: store.PiezometerStationReducer.allPiezometerThresholds,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
})

const mapDispatchToProps = {
    fetchAllPiezometers: PiezometryAction.fetchAllPiezometers,
    fetchAllPiezometerThreshold: PiezometerStationAction.fetchAllPiezometerThreshold,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertPiezo)