/* eslint-disable camelcase */
import { Grid2 } from '@mui/material'
import { getApplyDays, setTableIndex } from 'exploitations/utils/AgriUtils'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { createIndex } from 'utils/StoreUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import AgriAction from '../../agriAdministration/actions/AgriAction'
import { useParams } from 'react-router-dom'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { groupBy, omit, partition, uniqBy } from 'lodash'
import UpdateWaterTurnsDialog from './waterTurns/UpdateWaterTurnsDialog'
import WaterTurnsFilters from './waterTurns/WaterTurnsFilters'
import WaterTurnsAccordion from './waterTurns/WaterTurnsAccordion'
import DtoWaterTurnsSlot from 'exploitations/dto/DtoWaterTurnsSlot'
import { getYear } from 'utils/DateUtil'
import moment from 'moment'
import { hasValue } from 'utils/NumberUtil'
import DtoWaterTurns from 'exploitations/dto/DtoWaterTurns'
import AddExploitationWaterTurnsDialog from './waterTurns/AddExploitationWaterTurnsDialog'
import ProgressCard from 'components/card/ProgressCard'

const hashHeaders = ['samplerName', 'pumpType', 'material', 'serialNumber', 'nature', 'identifier']

const exportHeaders = ['samplerName', 'pumpType', 'maxFlow', 'material', 'serialNumber', 'nature', 'category',
    'identifier', 'sampleType', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday', 'comment']

const ExploitationWaterTurnsApp = ({}) => {
    const dispatch = useDispatch()

    const {
        restrictions,
        variousMateriels,
        contributor,
        exploitation,
        waterTurns,
        waterTurnsSlots,
    } = useSelector(store => ({
        restrictions: store.AgriReducer.restrictions,
        variousMateriels: store.VariousMaterielReducer.variousMateriels,
        contributor: store.ContributorReducer.contributor,
        exploitation: store.AgriReducer.exploitation,
        waterTurns: store.AgriReducer.waterTurns,
        waterTurnsSlots: store.AgriReducer.waterTurnsSlots,
    }), shallowEqual)

    const equipments = useMemo(() => variousMateriels.filter(vm => vm.pump), [variousMateriels])

    const [readMode, setReadMode] = useState(true)
    const [dataLoaded, setDataLoaded] = useState(false)

    const [filters, setFilters] = useState({})

    const [selectedWaterTurn, setSelectedWaterTurn] = useState()
    const [title, setTitle] = useState(false)
    const [open, setOpen] = useState(false)
    const [openAdd, setOpenAdd] = useState(false)
    const [selectedSlot, setSelectedSlot] = useState(new DtoWaterTurnsSlot({}))
    const [expanded, setExpanded] = useState(`panel_${getYear(moment.now())}`)

    const [addedWaterTurns, setAddedWaterTurns] = useState([])
    const [updatedWaterTurns, setUpdatedWaterTurns] = useState([])
    const [deletedWaterTurns, setDeletedWaterTurns] = useState([])

    const id = parseInt(useParams().id)

    useEffect(() => {
        dispatch(AgriAction.fetchExploitation(id))
        dispatch(AgriAction.fetchWaterTurns({ idExploitations: [id] })).then(() => {
            setDataLoaded(true)
        })
        if (!waterTurnsSlots.length) {
            dispatch(AgriAction.fetchSlots())
        }
        if (!restrictions.length) {
            dispatch(AgriAction.fetchRestrictions()).then(json => {
                if (!json.length) {
                    dispatch(ToastrAction.warning(i18n.noRestrictionsInBase))
                }
            })
        }
    }, [id])

    useEffect(() => {
        if (exploitation.operatorCode) {
            dispatch(ContributorAction.fetchContributor(exploitation.operatorCode))
        }
    }, [exploitation.operatorCode])

    useTitle(() => [
        {
            title: i18n.folders,
            href: 'dossiers',
        }, {
            title: `${contributor.name} ${exploitation.codification ? `[${exploitation.codification}]` : ''}`,
            href: `dossiers/${exploitation.idExploitation}/dashboard`,
        }, {
            title: i18n.waterTurns,
            href: `dossiers/${exploitation.idExploitation}/waterTurns`,
        },
    ], [exploitation.idExploitation, contributor])

    const colors = useMemo(() => {
        return createIndex([
            ...restrictions,
            {
                id: 'X',
                color: '#52D100',
                level: 'X',
            },
        ], 'level')
    }, [restrictions])

    const getHash = (user) => {
        return searchAllCharacters(hashHeaders.map((key) => user[key]))
    }

    const getFilteredData = (data) => {
        const toSearch = searchAllCharacters(filters.search || '')
        return data.filter((i) => getHash(i).includes(toSearch))
    }

    const data = useMemo(() => {
        const { search, equipmentsFilter } = filters
        const deletedData = waterTurns.filter(d => !deletedWaterTurns.includes(d.id))
        const updatedData = deletedData.map(dd => {
            const updatedDatum = updatedWaterTurns.find(d => d.id === dd.id)
            return hasValue(updatedDatum?.id) ? updatedDatum : dd
        })
        const addedData = [...updatedData, ...addedWaterTurns]
        const equipmentsData = equipmentsFilter?.length ? addedData.filter(swt => equipmentsFilter.includes(swt.idMat)) : addedData

        return search ? getFilteredData(equipmentsData) : equipmentsData
    }, [waterTurns, filters, colors, addedWaterTurns, updatedWaterTurns, deletedWaterTurns])

    const onExport = () => {
        return {
            data: data.length ? [
                { ...data[0], headers: exportHeaders },
                ...data.slice(1),
            ] : [],
            titleFile: i18n.waterTurns,
        }
    }

    useActions(() => {
        const actions = readMode ? {
            export: onExport,
            edit: () => setReadMode(false),
        } : {
            save: () => {
                const p = Promise.resolve()
                p.then(() => {
                    if (deletedWaterTurns.length) {
                        dispatch(AgriAction.deleteWaterTurn(deletedWaterTurns))
                    }
                }).then(() => {
                    const updated = [...updatedWaterTurns, ...addedWaterTurns].map(d => new DtoWaterTurns(d))
                    if (updated.length) {
                        dispatch(AgriAction.updateWaterTurns(updated))
                    }
                }).then(() => {
                    setReadMode(true)
                    setAddedWaterTurns([])
                    setUpdatedWaterTurns([])
                    setDeletedWaterTurns([])
                    setDataLoaded(false)
                    dispatch(AgriAction.fetchWaterTurns({ idExploitations: [id] })).then(() => {
                        setDataLoaded(true)
                    })
                })
            },
            new: () => setOpenAdd(true),
            cancel: () => {
                setReadMode(true)
                setAddedWaterTurns([])
                setUpdatedWaterTurns([])
                setDeletedWaterTurns([])
                dispatch(AgriAction.fetchWaterTurns({ idExploitations: [id] }))
            },
        }
        return restrictions.length ? actions : omit(actions, 'edit')
    }, [readMode, waterTurns, restrictions, addedWaterTurns, updatedWaterTurns, deletedWaterTurns])

    const addWaterTurns = (exploitationsToAdd) => {
        setAddedWaterTurns(setTableIndex([
            ...addedWaterTurns,
            ...exploitationsToAdd,
        ]))
    }

    const updateWaterTurns = (waterTurn) => {
        if (hasValue(waterTurn.id)) {
            setUpdatedWaterTurns([
                ...updatedWaterTurns.filter(wt => wt.id !== waterTurn.id),
                waterTurn,
            ])
        } else {
            setAddedWaterTurns(setTableIndex([
                ...addedWaterTurns.filter(wt => wt.index !== waterTurn.index),
                waterTurn,
            ]))
        }
        setOpen(false)
    }

    const onApply = (slot, selectionTool, colorInput) => {
        const days = getApplyDays(selectionTool, colorInput)
        const filteredData = slot.id !== -1 ? data.filter(d => d.idSlot === slot.id && d.year === moment().year()) : data.filter(d => !d.idSlot && d.year === moment().year())
        const [updatedData, addedData] = partition(filteredData.map(swt => ({ ...swt, ...days })), d => hasValue(d.id))
        setAddedWaterTurns(setTableIndex(uniqBy([
            ...addedData,
            ...addedWaterTurns,
        ], d => d.index)))
        setUpdatedWaterTurns(uniqBy([
            ...updatedData,
            ...updatedWaterTurns,
        ], d => d.id))
    }

    const deleteWaterTurns = (waterTurn) => {
        if (hasValue(waterTurn.id)) {
            setDeletedWaterTurns([
                ...deletedWaterTurns,
                waterTurn.id,
            ])
            setUpdatedWaterTurns(updatedWaterTurns.filter(uwt => uwt.id !== waterTurn.id))
        } else {
            setAddedWaterTurns(setTableIndex(addedWaterTurns.filter(wt => wt.index !== waterTurn.index)))
        }
    }

    const onOpenUpdate = (waterTurn, slot, formattedTitle) => {
        setSelectedWaterTurn(waterTurn)
        setSelectedSlot(slot)
        setTitle(formattedTitle)
        setOpen(true)
    }

    const groupedYearData = groupBy(data, 'year')

    return (
        <Grid2 container justifyContent='center' className='padding-1' sx={{ paddingBottom: '100px' }}>
            <WaterTurnsFilters
                restrictions={restrictions}
                equipments={equipments}
                data={data}
                onFilter={setFilters}
                readMode={readMode}
            />
            <Grid2 size={12} className='padding-top-1'>
                {dataLoaded && !data.length && (
                    <div style={{ background: 'white', border: '1px solid #35609F', borderRadius: 5 }}>
                        <div style={{ padding: '50px' }}>
                            <div className='col s12 text-align-center'>
                                <i className='material-icons medium'>nature_people</i>
                            </div>
                            <div className='center font-size-20'>{i18n.NoneResultForThisResearch}</div>
                        </div>
                    </div>
                )}
                {dataLoaded && Object.keys(groupedYearData).sort().reverse().map(yearKey => (
                    <Grid2 key={`accordion_${yearKey}`}>
                        <WaterTurnsAccordion
                            data={groupedYearData[yearKey]}
                            readMode={readMode}
                            colors={colors}
                            deleteWaterTurns={deleteWaterTurns}
                            onOpenUpdate={onOpenUpdate}
                            setOpenAdd={null}
                            yearKey={yearKey}
                            expanded={expanded}
                            setExpanded={setExpanded}
                            onApply={onApply}
                        />
                    </Grid2>
                )) || <ProgressCard indeterminate withMessage round />}
            </Grid2>
            <UpdateWaterTurnsDialog
                selectedWaterTurn={selectedWaterTurn}
                updateWaterTurns={updateWaterTurns}
                slot={selectedSlot}
                colors={colors}
                title={title}
                open={open}
                setOpen={setOpen}
            />
            <AddExploitationWaterTurnsDialog
                openAdd={openAdd}
                setOpenAdd={setOpenAdd}
                addWaterTurns={addWaterTurns}
            />
        </Grid2>
    )
}

ExploitationWaterTurnsApp.propTypes = {
}

export default ExploitationWaterTurnsApp
