import { Grid } from '@mui/material'
import Card from 'components/card/Card'
import { push } from '@lagunovsky/redux-react-router'
import { groupBy, isEqual, orderBy, uniq } from 'lodash'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import 'moment/locale/fr'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getConvertRequestType } from 'utils/CampaignUtil'
import CampaignEventsModal from '../../../campaign/components/CampaignEventsModal'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import CampaingTrackingItemDto from '../../../campaign/dto/CampaingTrackingItemDto'
import DtoStationCampaign from '../../../campaign/dto/DtoStationCampaign'
import ActionComponent from '../../../components/ActionComponent'
import Table from '../../../components/datatable/Table'
import Checkbox from '../../../components/forms/Checkbox'
import Icon from '../../../components/icon/Icon'
import CartographyPanel from '../../../components/map/CartographyPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoEvent from '../../../events/dto/DtoEvent'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import { MAP, STATION_LIST } from '../../../station/constants/StationConstants'
import { getEventColor } from '../../../utils/ColorUtil'
import { DAY, MONTH, WEEK } from '../../../utils/constants/CalandarConstants'
import { enumerateBetweenDates, getDate, getMonthYear, getWeekYear } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getLabel } from '../../../utils/StoreUtils'
import { getMarkerIcon } from '../../utils/CampaignUtils'


const moment = extendMoment(Moment)
moment.locale('fr')

class PiezometryCampaignTrackingApp extends ActionComponent {
    state = {
        filterDate: MONTH,
        checkValue: false,
        panel: STATION_LIST,
    }

    componentDidMount = () => {
        this.setComponentActions()
        this.setTableFixedHeader()
        this.setTitle()
    }

    componentDidUpdate = prevProps => {
        this.setComponentActions()
        this.setTableFixedHeader()
        if (!isEqual(prevProps.campaign, this.props.campaign)) {
            this.setTitle()
        }
    }

    setTitle = () => {
        const {
            campaign,
            params,
            stationType,
        } = this.props
        this.props.forceFetch('title', [
            {
                title: i18n[stationType],
                href: `/${stationType}`,
            },
            {
                title: i18n.campaigns,
                href: `/${stationType}/campaign`,
            },
            {
                title: campaign.name || params.id,
                href: `/${stationType}/campaign/${params.id}/dashboard`,
            },
            {
                title: i18n.tracking,
                href: `/${stationType}/campaign/${params.id}/tracking`,
            },
        ])
    }

    calculateExecuted = () => {
        const startInit = moment(moment(this.props.campaign.beginningApplication).format('MM-YYYY'), 'MM-YYYY')
        const endInit = moment(moment(this.props.campaign.endingApplication).format('MM-YYYY'), 'MM-YYYY')
        const gapMonth = endInit.diff(startInit, 'months')
        const calculate = Math.round(gapMonth / 6)
        return calculate
    }

    getListData = () => {
        const headersDate = this.getHeaderDate(this.props.campaignEvents)
        return this.getStationsVisitData(this.props.campaignEvents, headersDate)
    }

    setComponentActions() {
        this.setActions({
            export: () => ({
                data: this.getListData(),
                exportType: 'xlsx',
                titleFile: i18n.tracking,
            }),
        })
    }

    setTableFixedHeader = () => {
        $('#trackingTable').tableHeadFixer(
            {
                head: true,
                left: 2,
                'z-index': 1,
            },
        )
    }

    changeShow = () => {
        this.setState(prevState => ({ checkValue: !prevState.checkValue }))
    }

    orderRangeDate = (rangeDate) => {
        return orderBy(rangeDate, o => moment(o), ['asc'])
    }

    getHeadersDateDay = (events) => {
        const rangeDate = (() => {
            if (this.state.checkValue) {
                return events.map(o => o.date)
            }
            const startDay = moment(this.props.campaign.beginningApplication, 'DD/MM/YYYY')
            const endDay = moment().valueOf()
            return enumerateBetweenDates(startDay, endDay, 'days')
        })()
        const dateDay = this.orderRangeDate(rangeDate).map(o => getDate(o))
        return dateDay
    }

    getHeadersDateWeek = (events) => {
        const rangeDate = (() => {
            if (this.state.checkValue) {
                return events.map(o => o.date)
            }
            const startWeek = moment(this.props.campaign.beginningApplication, 'DD/MM/YYYY')
            const endWeek = moment().valueOf()
            return enumerateBetweenDates(startWeek, endWeek, 'week')
        })()
        const dateWeek = this.orderRangeDate(rangeDate).map(o => `S${getWeekYear(o)}`)
        const dateWeekNow = `S${moment().format('WW/YYYY')}`
        const dateWeekWithNow = [
            ...dateWeek,
            dateWeekNow,
        ]
        return this.state.checkValue ? dateWeek : dateWeekWithNow
    }

    getHeadersDateMonth = (events) => {
        const rangeDate = (() => {
            if (this.state.checkValue) {
                return events.map(o => o.date)
            }
            const initStartMonth = moment(this.props.campaign.beginningApplication, 'DD/MM/YYYY').format('MM-YYYY')
            const startMonth = moment(initStartMonth, 'MM-YYYY')
            const endMonth = moment().valueOf()
            return uniq(enumerateBetweenDates(startMonth, endMonth, 'month'))
        })()
        const dateMonth = this.orderRangeDate(rangeDate).map(o => getMonthYear(o))
        return dateMonth
    }

    getFormatDate = (date) => {
        switch (this.state.filterDate) {
            case DAY:
                return getDate(date)
            case WEEK:
                return `S${getWeekYear(date)}`
            default:
                return getMonthYear(date)
        }
    }

    getFirstDate = (dateFromGroup) => {
        switch (this.state.filterDate) {
            case DAY:
                return moment(dateFromGroup, 'DD/MM/YYYY').valueOf()
            case WEEK:
                return moment(dateFromGroup.split('S')[1], 'ww/YYYY').valueOf()
            default:
                return moment(dateFromGroup, 'MM/YYYY').valueOf()
        }
    }

    getEventIcon = (events, label, icon) => {
        return !!events.length && (
            <Icon
                icon={icon}
                size='tiny'
                tooltip={label}
            />
        )
    }

    getStationEvents = (events, monthToCompare, stationId) => {
        const groupEvents = groupBy(events, e => this.getFormatDate(e.date))
        return monthToCompare.map(o => {
            const findEvents = groupEvents[o] || []
            const type = getConvertRequestType(this.props.campaign.campaignType)
            const campaignEvent = findEvents.filter(({ eventType }) => eventType === type)
            const toPlanEvents = findEvents.filter(({ eventType }) => eventType === 'P')
            const toMonitorEvent = findEvents.filter(({ eventType }) => eventType === 'S')
            if (findEvents.length) {
                return {
                    value: (
                        <>
                            {campaignEvent.length}
                            {this.getEventIcon(toMonitorEvent, i18n.toMonitor, 'notifications')}
                            {this.getEventIcon(toPlanEvents, i18n.toPlan, 'assignment')}
                        </>
                    ),
                    classNameColor: campaignEvent[0] ? getEventColor(campaignEvent[0]?.eventType) : 'white',
                    onClick: () => this.openEventsModal(campaignEvent, o, stationId),
                }
            }
            return {
                value: 0,
                onClick: () => this.openEventsModal([], o, stationId),
            }
        })
    }

    openEventsModal = (events, strDate, stationId) => {
        const actions = (
            <div>
                <a className='waves-effect waves-teal btn-flat modal-close'>{i18n.close}</a>
            </div>
        )
        const popup = {
            id: 'campaignEventsModal',
            header: `${i18n.events} ${strDate}`,
            actions,
            content: (
                <CampaignEventsModal
                    events={events}
                    stationType={this.props.stationType}
                    date={this.getFirstDate(strDate)}
                    campaignCode={this.props.campaign.id}
                    campaignType={this.props.campaign.campaignType}
                    stationId={stationId}
                />
            ),
        }
        this.props.setPopup(popup)
    }

    setHeaders = value => {
        this.setState({ filterDate: value.parameter })
    }

    getColorStationValidated = (eventLength, { previsionalVisitNumber = 1 }) => {
        if (previsionalVisitNumber === 0) {
            return 'white'
        } else if (eventLength >= (previsionalVisitNumber)) {
            return 'green'
        } else if (eventLength < (previsionalVisitNumber)) {
            return 'red'
        }
        return 'white'
    }

    getStationsVisitData = (events, headersDate) => {
        return this.props.campaignStations.map(campaignStation => {
            const station = this.props.stations.find(p => p.id == campaignStation.stationId)
            if (station) {
                const type = getConvertRequestType(this.props.campaign.campaignType)
                const stationsEvents = events.filter(evt => evt.code == station.id)
                const stationCampaignEvent = stationsEvents.filter(e => e.eventType === type)
                const stationEvent = this.getStationEvents(stationsEvents, headersDate, station.id)
                const code = {
                    id: station.id,
                    classNameColor: this.getColorStationValidated(stationCampaignEvent.length, campaignStation),
                    value: `[${station.code || ''}] ${getLabel(this.props.cities, station.townCode)} - ${station.name}`,
                    onClick: () => this.props.push(`/station/${this.props.stationType}/${station.id}`),
                }
                const previsionalVisitNumber = campaignStation.previsionalVisitNumber > 0 ? campaignStation.previsionalVisitNumber : 1
                const executed = { value: `${stationCampaignEvent.length}/${previsionalVisitNumber}` }
                const initDto = new CampaingTrackingItemDto(code, executed, headersDate)
                const dates = headersDate.reduce((acc, date, i) => {
                    acc[date] = stationEvent[i]
                    return acc
                }, {})
                return {
                    ...initDto,
                    headers: Object.keys(initDto),
                    ...dates,
                    name: station.name,
                    code: station.code,
                    order: campaignStation.visitOrder,
                }
            }
            return null
        }).filter(obj => hasValue(obj))
    }

    getHeaderDate = (events) => {
        switch (this.state.filterDate) {
            case DAY:
                return this.getHeadersDateDay(events)
            case WEEK:
                return this.getHeadersDateWeek(events)
            default:
                return this.getHeadersDateMonth(events)
        }
    }

    getMapPanel = (data) => {
        const stations = data.map(d => {
            const station = this.props.stations.find(p => p.id === d.station.id)
            return {
                ...station,
                markerIcon: getMarkerIcon(this.props.stationType, d.station.color),
            }
        })
        return (
            <CartographyPanel
                layers={['STATIONS_POINTS']}
                componentType={this.props.stationType}
                stationsPoints={stations}
                stationsPanelTitle={i18n[this.props.stationType]}
                heightToSubstract={450}
            />
        )
    }

    render = () => {
        const {
            panel,
            checkValue,
        } = this.state
        const filters = [
            {
                parameter: DAY,
                title: i18n.day,
            },
            {
                parameter: WEEK,
                title: i18n.week,
            },
            {
                parameter: MONTH,
                title: i18n.month,
            },
        ]

        const headersDate = this.getHeaderDate(this.props.campaignEvents)
        const defaultType = new CampaingTrackingItemDto('', '', headersDate)
        const headers = {
            headers: Object.keys(defaultType),
        }
        const datas = orderBy(this.getStationsVisitData(this.props.campaignEvents, headersDate), ['order', 'code'])
        return (
            <Grid className='padding-1 padding-left-2'>
                <Card>
                    <div className='row no-margin'>
                        {
                            panel === STATION_LIST && (
                                <div>
                                    <Checkbox
                                        col={3}
                                        checked={checkValue}
                                        onChange={this.changeShow}
                                        label={i18n.showOnlyEvents}
                                    />
                                    <div className='col s4 offset-s4 right-align'>
                                        {
                                            filters.map(o =>
                                                <a className='btn btn-small' onClick={() => this.setHeaders(o)}>{o.title}</a>,
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        <div className={`col s1 ${panel === MAP ? 'offset-s11' : ''}`}>
                            <Icon
                                icon={panel === MAP ? 'list' : 'map'}
                                className='map-right-icon blue-text right'
                                tooltip={panel === MAP ? i18n.table : i18n.map}
                                onClick={() => this.setState({ panel: panel === MAP ? STATION_LIST : MAP })}
                            />
                        </div>
                    </div >
                </Card>
                <Grid xs={12} className='padding-top-5-px'>
                    {
                        panel === STATION_LIST && (
                            <Table
                                id='trackingTable'
                                title={i18n.stations}
                                data={datas}
                                sortable
                                condensed
                                color
                                type={headers}
                                paging
                                nbPerPageLabel={nbPerPageLabel}
                                noSort
                            />
                        )
                    }
                    {
                        panel === MAP && this.getMapPanel(datas)
                    }
                </Grid>
            </Grid>
        )
    }
}

PiezometryCampaignTrackingApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    stationType: PropTypes.oneOf(['piezometry', 'pluviometry', 'hydrometry', 'installation']),
    campaign: PropTypes.instanceOf(CampaignDto),
    campaignStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCampaign)),
    campaignEvents: PropTypes.instanceOf(DtoEvent),
    stations: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    ]),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    forceFetch: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    campaign: store.CampaignReducer.campaign,
    campaignStations: store.CampaignReducer.campaignStations,
    campaignEvents: store.CampaignReducer.campaignEvents,
    cities: store.CityReducer.cities,
})

const mapDispatchToProps = {
    push,
    forceFetch: SieauAction.forceFetch,
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometryCampaignTrackingApp)
