import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Table from '../../../components/datatable/Table'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import Job from '../../../import/dto/DtoJob'
import { nbPerPageLabelShort } from '../../../referencial/constants/ReferencialConstants'


class PostFacebookJob extends Component {
    state = {
        files: [],
    }

    componentDidMount() {
        const { parameters = [] } = this.props.job.parameters
        this.setState({ files: parameters.map(f => JSON.parse(f)) })
    }

    onChangeDirectory = (val, index) => {
        const files = [...this.state.files]
        files[index] = { ...files[index], directory: val }
        this.setState({ files })
        this.props.onChangeFiles(files.map(f => JSON.stringify(f)))
    }

    onChangeStationType = (val, index) => {
        const files = [...this.state.files]
        files[index] = { ...files[index], stationType: val }
        this.setState({ files })
        this.props.onChangeFiles(files.map(f => JSON.stringify(f)))
    }

    getList = isEditMode => {
        const STATION_TYPE = [
            {
                value: 'piezometry',
                label: i18n.piezometry,
            },
            {
                value: 'pluviometry',
                label: i18n.pluviometry,
            },
            {
                value: 'quality',
                label: i18n.quality,
            },
            {
                value: 'hydrometry',
                label: i18n.hydrometry,
            },
        ]
        return this.state.files.map((file, i) => ({
            stationTypeLabel: (
                <Select
                    value={file.stationType}
                    options={STATION_TYPE}
                    col={12}
                    onChange={val => this.onChangeStationType(val, i)}
                    disabled={!isEditMode}
                    isEditMode={isEditMode}
                />
            ),
            defineDirectory: (
                <Input
                    col={12}
                    value={file.directory}
                    onChange={val => this.onChangeDirectory(val, i)}
                    disabled={!isEditMode}
                    readMode={!isEditMode}
                />
            ),
        }))
    }

    render() {
        return (
            <div>
                <div className='row no-margin padding-bottom-1'>
                    <div className='col s3 offset-s9 no-padding'>
                        <div className='col s12 no-padding'>
                            <a
                                className={`right waves-effect waves-light btn ${!this.props.isEditMode ? 'disabled' : ''}`}
                                onClick={() => this.props.isEditMode && this.setState({ files: [...this.state.files, {}] })}
                            >
                                <i className='material-icons left'>add_box</i>
                                {i18n.addDirectory}
                            </a>
                        </div>
                    </div>
                </div>
                {
                    !!this.state.files.length && (
                        <div className='row no-margin card no-box-shadow transparent'>
                            <div className='col s12 no-padding'>
                                <div className='job-parameter-table'>
                                    <div className='card-title active'>
                                        {i18n.selectDirectory}
                                    </div>
                                    <Table
                                        showTitle={false}
                                        data={this.getList(this.props.isEditMode)}
                                        type={{ headers: ['stationTypeLabel', 'defineDirectory'] }}
                                        nbPerPageLabel={nbPerPageLabelShort}
                                        deletable={this.props.isEditMode}
                                        onDelete={(_, index) => this.setState({ files: this.state.files.filter((__, i) => index !== i) })}
                                        sortable
                                        condensed
                                        paging
                                        overflow='visible'
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }
}

PostFacebookJob.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeFiles: PropTypes.func,
}

const mapDispatchToProps = {
    toastError: ToastrAction.error,
}

export default connect(
    null,
    mapDispatchToProps,
)(PostFacebookJob)
