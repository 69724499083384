import { Grid2 } from '@mui/material'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import ThresholdSelect from 'components/forms/specific/ThresholdSelect'
import QualityAction from 'quality/actions/QualityAction'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ReferencialAction from 'referencial/action/ReferencialAction'
import i18n from 'simple-react-i18n'
import useParseJson from 'utils/customHook/useParseJson'
import useSandreList from 'utils/customHook/useSandreList'
import PropTypes from 'prop-types'
import DtoJob from 'import/dto/DtoJob'

const QualityPostControl = ({
    job = {},
    onChangeJob = () => {},
    isEditMode = false,
}) => {
    const dispatch = useDispatch()

    const {
        qualifications,
        status,
        cmsCategories,
    } = useSelector(store => ({
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        cmsCategories: store.EventsReducer.cmsCategories,
    }), shallowEqual)

    const statusOptions = useSandreList('CMS.STATUS')
    const categories = cmsCategories.map(cms => ({ ...cms, icon: null, code: cms.id, name: cms.title }))

    useEffect(() => {
        dispatch(QualityAction.fetchQualifications())
        dispatch(QualityAction.fetchStatus())
        dispatch(ReferencialAction.fetchSandreCodes())
    }, [dispatch])


    const {
        json: params,
    } = useParseJson(job.parameters?.parameters?.[0] ?? '{}')

    const onChangeParams = (changes = {}) => {
        const parameters = {
            ...job.parameters,
            parameters: [JSON.stringify({
                ...params,
                ...changes,
            })],
        }
        onChangeJob({ parameters })
    }

    return (
        <div>
            <StyledFieldSet>
                <StyledLegend>
                    {i18n.controlToDo}
                </StyledLegend>
                <Grid2 container spacing={1} sx={{ padding: '5px' }}>
                    <Grid2 size={4}>
                        <Checkbox
                            label={i18n.controlSandre}
                            checked={params.conformitySandre}
                            onChange={v => onChangeParams({ conformitySandre: v })}
                            disabled={!isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Checkbox
                            label={i18n.controlOutlier}
                            checked={params.outlier}
                            onChange={v => onChangeParams({ outlier: v })}
                            disabled={!isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Checkbox
                            label={i18n.controlMultiParam}
                            checked={params.multiParam}
                            onChange={v => onChangeParams({ multiParam: v })}
                            disabled={!isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <ThresholdSelect
                            title={i18n.improbability}
                            selected={params.improbabilityThreshold}
                            onChange={v => onChangeParams({ improbabilityThreshold: v })}
                            fieldRef='CONTROL_IMPROBABILITY_JOB'
                            disabled={!isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <ThresholdSelect
                            title={i18n.impossibility}
                            selected={params.impossibilityThreshold}
                            onChange={v => onChangeParams({ impossibilityThreshold: v })}
                            fieldRef='CONTROL_IMPOSSIBILITY_JOB'
                            disabled={!isEditMode}
                        />
                    </Grid2>
                </Grid2>
            </StyledFieldSet>
            <StyledFieldSet>
                <StyledLegend>{ 'Données incorrectes' }</StyledLegend>
                <Grid2 container alignItems='center' spacing={3} >
                    <Grid2 size={3}>
                        <Checkbox
                            checked={params.noChangesKo}
                            label='Ne rien changer'
                            onChange={v => onChangeParams({ noChangesKo: v })}
                            disabled={!isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={9} />
                    <Grid2 size={4}>
                        <Select
                            options={status}
                            label={'Changement du statut'}
                            nullLabel='Ne rien changer'
                            onChange={v => onChangeParams({ statusKo: v })}
                            value={params.statusKo}
                            disabled={!isEditMode || params.noChangesKo}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={qualifications}
                            label={'Changement de qualification'}
                            nullLabel='Ne rien changer'
                            onChange={v => onChangeParams({ qualificationKo: v })}
                            value={params.qualificationKo}
                            disabled={!isEditMode || params.noChangesKo}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Checkbox
                            checked={params.autoDeleteKo}
                            label='Supprimer les données incorrectes'
                            onChange={v => onChangeParams({ autoDeleteKo: v })}
                            disabled={!isEditMode || params.noChangesKo}
                        />
                    </Grid2>
                </Grid2>
            </StyledFieldSet>
            <StyledFieldSet>
                <StyledLegend>{'Rapport de contrôle'}</StyledLegend>
                <Grid2 container alignItems='center' spacing={3}>
                    <Grid2 size={4}>
                        <Checkbox
                            checked={params.createThechnicalEvent}
                            label='Créer un événement technique sur les stations'
                            onChange={v => onChangeParams({ createThechnicalEvent: v })}
                            disabled={!isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <NumberField
                            value={params.alertDelayDays}
                            title='Ne pas faire de nouvelle alerte pendant (nb de jours)'
                            onChange={v => onChangeParams({ alertDelayDays: v })}
                            disabled={!isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={4}/>
                </Grid2>
                <Grid2 container alignItems='center' spacing={3}>
                    <Grid2 size={4}>
                        <Checkbox
                            checked={params.createCMS}
                            label='Créer un rapport CMS'
                            onChange={v => onChangeParams({ createCMS: v })}
                            disabled={!isEditMode}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={categories}
                            label={'Catégorie du CMS'}
                            noNullValue
                            onChange={v => onChangeParams({ cmsCategory: v })}
                            value={params.cmsCategory}
                            disabled={!isEditMode || !params.createCMS}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            options={statusOptions}
                            label={'Statut du CMS'}
                            noNullValue
                            onChange={v => onChangeParams({ cmsStatus: v })}
                            value={params.cmsStatus}
                            disabled={!isEditMode || !params.createCMS}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container justifyContent='center' alignItems='center' spacing={3} className='padding-bottom-1'>
                    <Grid2 size={4}>
                        <Checkbox
                            checked={params.sendEmail}
                            label='Transmettre le CMS par emails'
                            onChange={v => onChangeParams({ sendEmail: v })}
                            disabled={!isEditMode || !params.createCMS}
                        />
                    </Grid2>
                    <Grid2 size={8}>
                        <Input
                            title='Liste des emails (séparés par des ";")'
                            value={params.emails}
                            onChange={v => onChangeParams({ emails: v })}
                            disabled={!isEditMode || !params.createCMS ||!params.sendEmail}
                        />
                    </Grid2>
                </Grid2>
            </StyledFieldSet>
        </div>
    )
}

QualityPostControl.propTypes = {
    job: PropTypes.instanceOf(DtoJob),
    onChangeJob: PropTypes.func,
    isEditMode: PropTypes.bool,
}

export default QualityPostControl