/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getExport } from '../../../../utils/linkUtils'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getSandreLabel } from '../../../../utils/StringUtil'
import DtoTankStation from '../dto/DtoTankStation'
import useSandreList from 'utils/customHook/useSandreList'
import { CardTable } from 'components/datatable/NewTable'
import { greyBlue } from 'utils/constants/ColorTheme'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import Select from 'components/forms/Select'

const headers = ['name', 'state', 'comment']

const TankZoneDialog = ({
    idStation,
    selectedTankZone = {},
    onChange = () => { },
    isOpen = false,
    setIsOpen = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [tankZone, setTankZone] = useState({ ...selectedTankZone, idStation })

    const onChangeElement = (changes) => setTankZone(prev => ({ ...prev, ...changes }))

    const onSave = () => {
        onChange(tankZone)
        setIsOpen(false)
    }

    const sandreList = useSandreList(SANDRE.RESERVOIRS_ZONES_ETATS)

    return (
        <Dialog
            maxWidth='lg'
            open={isOpen}
        >
            <DefaultDialogTitle
                title={`${i18n.editZones} : ${getSandreLabel(sandreCodes, SANDRE.RESERVOIRS_ZONES, tankZone.code)}`}
                onClose={() => setIsOpen(false)}
            />
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid2 container spacing={1}>
                            <Grid2 size={6}>
                                <Select
                                    col={6}
                                    value={tankZone.stateCode}
                                    label={i18n.state}
                                    options={sandreList}
                                    onChange={v => onChangeElement({ stateCode: v })}
                                    integerValue
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <SimpleTextArea
                                    title={i18n.comment}
                                    value={tankZone.comment}
                                    onChange={v => onChangeElement({ comment: v })}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSave} variant='contained'>
                    {i18n.register}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

TankZoneDialog.propTypes = {
    idStation: PropTypes.number,
    selectedTankZone: PropTypes.object,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

const TankZonesPanel = ({
    tank = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const [selectedTankZone, setSelectedTankZone] = useState({})
    const [isOpen, setIsOpen] = useState(false)

    const sandreList = useSandreList(SANDRE.RESERVOIRS_ZONES)
    const stationTank = sandreList.map(element => {
        const data = tank.link_zones.find(ss => element.code === ss.code) || {}
        return {
            ...element,
            ...data,
            state: data.stateCode ? getSandreLabel(sandreCodes, SANDRE.RESERVOIRS_ZONES_ETATS, data.stateCode) : null,
        }
    })
    const exportAction = getExport(stationTank, i18n.states, headers)
    const actions = [exportAction]

    const lineActions = [{
        icon: 'edit',
        onClick: (element) => {
            setSelectedTankZone(stationTank.find(c => element.code === c.code))
            setIsOpen(true)
        },
        displayed: !readMode,
    }]

    const updateElements = (newElement) => onChange({
        link_zones: [
            ...tank.link_zones.filter(ss => ss.code !== newElement.code),
            newElement,
        ],
    })

    return (
        <>
            <CardTable
                title={i18n.states}
                rows={stationTank}
                headers={headers}
                actions={actions}
                color={greyBlue}
                lineActions={lineActions}
            />
            {isOpen && (
                <TankZoneDialog
                    idStation={tank.idStation}
                    selectedTankZone={selectedTankZone}
                    onChange={updateElements}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
        </>
    )
}

TankZonesPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default TankZonesPanel