/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getSandreLabel } from '../../../../utils/StringUtil'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import useSandreList from 'utils/customHook/useSandreList'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import NumberField from 'components/forms/NumberField'

const headers = ['type', 'area', 'comment']

const BuildingDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const buildingList = useSandreList(SANDRE.INSTALLATIONS_BATIMENT)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.type}
                            options={buildingList}
                            value={stateElement.buildingType}
                            onChange={v => onChangeElement({ buildingType: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.buildingArea}
                            title={i18n.area}
                            onChange={v => onChangeElement({ buildingArea: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={stateElement.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}


BuildingDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const BuildingsPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_buildings'
            formatFunction={c => ({
                ...c,
                area: c.buildingArea,
                type: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_BATIMENT, c.buildingType),
            })}
            titles={{
                title: i18n.buildings,
                edit: i18n.editBuilding,
                new: i18n.newBuilding,
            }}
            headers={headers}
            dialogContent={BuildingDialogContent}
        />
    )
}

BuildingsPanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default BuildingsPanel