import ApplicationConf from 'conf/ApplicationConf'
import { push } from '@lagunovsky/redux-react-router'
import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import WaitAction from 'wait/WaitAction'
import SieauAction from '../../../../components/sieau/SieauAction'
import { PATH_REFERENCIAL_CITY, PATH_REFERENCIAL_CITY_LINK } from '../../../../home/constants/RouteConstants'
import LogAction from '../../../../log/actions/LogAction'
import { checkAuth, checkError, genericPromise, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import { CITIES, CITY, RECEIVE_CITIES_HISTO, RECEIVE_CITY_CEDEX, RECEIVE_DEPARTMENTS, RESET_CITY } from '../constants/CityConstants'

const CityAction = {
    fetchIfNeeded(prop, fetchFunc, param1) {
        return (dispatch) => {
            const elem = AppStore.getState().CityReducer[prop]
            if (elem.length) {
                if (elem.length !== 0) {
                    return { type: '' }
                }
            } else if (Object.keys(elem).length !== 0) {
                return { type: '' }
            }
            return dispatch(fetchFunc(param1))
        }
    },

    receiveCity(city) {
        return { type: CITY, city }
    },

    receiveCities(cities) {
        return { type: CITIES, cities }
    },

    updateCity(city, code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.saveCity(code), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(city),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(CityAction.fetchCity(code))
                        dispatch(ToastrAction.success(i18n.citySuccessUpdated))
                    } else {
                        throw new Error()
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.city} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.city))
                })
        }
    },

    createCity(city) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.cities(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(city),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.insert) {
                        dispatch(push(PATH_REFERENCIAL_CITY_LINK + city.code))
                        dispatch(ToastrAction.success(i18n.successfulCreation))
                    }
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.createError + i18n.city} : ${err}`))
                    dispatch(ToastrAction.error(i18n.createError + i18n.city))
                })
        }
    },

    deleteCity(code) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.deleteCity(code), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.delete) {
                        dispatch(ToastrAction.success(i18n.elementDeleteSuccess))
                        dispatch(push(PATH_REFERENCIAL_CITY))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.city} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.city))
                })
        }
    },

    purgeCity() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.purgeCity(), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json) => {
                    if (json.purge || json.purge === 0) {
                        dispatch(ToastrAction.success(`${i18n.elementDeleteSuccess} ${i18n.total} : ${json.purge}`))
                        dispatch(CityAction.fetchCities())
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.deleteError + i18n.cities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.deleteError + i18n.cities))
                })
        }
    },

    replaceCity(city, cityReplace) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.replaceCity(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify({
                    id: city,
                    replaceId: cityReplace,
                }),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(json => {
                    if (json.update) {
                        dispatch(ToastrAction.success(i18n.citySuccessUpdated))
                    } else if (json.update == 0) {
                        dispatch(ToastrAction.success(`${json.update} ${i18n.replacement}`))
                    } else {
                        throw new Error()
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.replace + i18n.city} : ${err}`))
                })
        }
    },

    fetchCity(code) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.city(code), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = []) => {
                    checkError(json)
                    dispatch(CityAction.receiveCity(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.city} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.city))
                })
        }
    },

    promiseCities() {
        return fetch(ApplicationConf.referencial.cities(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchCities(forceLoad = false) {
        return (dispatch, getState) => {
            const { cities } = getState().CityReducer
            if (cities.length && !forceLoad) {
                return Promise.resolve()
            }
            return CityAction.promiseCities()
                .then((json = []) => {
                    dispatch(SieauAction.receiveProps('cities'))
                    dispatch(CityAction.receiveCities(json))
                })
                .catch((err) => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.cities} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.cities))
                })
        }
    },

    receiveCityCedex(cityCedex) {
        return { type: RECEIVE_CITY_CEDEX, cityCedex }
    },

    fetchCityCedex() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.cityCedex(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(CityAction.receiveCityCedex(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError} ${i18n.cedexCode} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.fetchError} ${i18n.cedexCode}`))
                })
        }
    },

    resetCity() {
        return { type: RESET_CITY }
    },

    fetchSynchroSandreCity() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.referencial.updateCity(), {
                method: 'POST',
                headers: getAuthorization(),

            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insert > 0 || json.update > 0) {
                        if (json.insert > 0) {
                            dispatch(ToastrAction.success(`${i18n.insertNumber} : ${json.insert}<br/>${i18n.updateNumber} : ${json.update}`))
                        } else {
                            dispatch(ToastrAction.success(`${i18n.updateNumber} : ${json.update}`))
                        }
                        dispatch(this.fetchCities())
                    } else {
                        dispatch(ToastrAction.success(i18n.aJobIsRunning))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.city + err))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.city))
                })
        }
    },

    fetchImportCityCSV(fichier, insertOrUpdate = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.uploadCsvCity(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ fileContent: fichier, insertOrUpdate }),
            })
                .then(checkAuth)
                .then(checkError)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insertCities > 0 || json.updateCities > 0) {
                        dispatch(ToastrAction.success(`${i18n.city}<br/> ${i18n.insertNumber} : ${json.insertCities}<br/>${i18n.updateNumber} : ${json.updateCities}`))
                        dispatch(CityAction.fetchCities())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.city + err))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    fetchImportCityXML(fichier, insertOrUpdate = true) {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.uploadXmlCity(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify({ fileContent: fichier, insertOrUpdate }),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json = {}) => {
                    if (json.insertCities > 0 || json.updateCities > 0) {
                        dispatch(ToastrAction.success(`${i18n.city}<br/> ${i18n.insertNumber} : ${json.insertCities}<br/>${i18n.updateNumber} : ${json.updateCities}`))
                        dispatch(CityAction.fetchCities())
                    } else {
                        dispatch(ToastrAction.success(i18n.noUpdate))
                    }
                    dispatch(WaitAction.waitStop())
                })
                .catch((err) => {
                    dispatch(LogAction.logError(i18n.updateError + i18n.city + err))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    receiveCitiesHisto(citiesHisto) {
        return { type: RECEIVE_CITIES_HISTO, citiesHisto }
    },

    fetchCitiesHisto() {
        return (dispatch) => {
            return fetch(ApplicationConf.referencial.citiesHisto(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then((json) => {
                    dispatch(CityAction.receiveCitiesHisto(json))
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
                    dispatch(ToastrAction.error(`${i18n.fetchError}`))
                })
        }
    },

    receiveDepartment: departments => ({ type: RECEIVE_DEPARTMENTS, payload: departments }),
    fetchDepartment: () => dispatch => genericPromise(ApplicationConf.referencial.departments())
        .then(json => dispatch(CityAction.receiveDepartment(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(`${i18n.fetchError}`))
        }),
}

export default CityAction
