/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate, getHour } from '../../../utils/DateUtil'
import { arrayOf } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import StationDescriptionTable from './StationDescriptionTable'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import Input from 'components/forms/Input'
import { onChangeHour } from 'utils/FormUtils'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import useSandreList from 'utils/customHook/useSandreList'

const headers = ['startDate', 'endDate', 'function', 'description']

const FunctionDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const functionList = useSandreList(SANDRE.FONCTIONS)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            label={i18n.startDate}
                            onChange={v => onChangeElement({ startDate: v })}
                            max={stateElement.endDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(stateElement.startDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateElement.endDate }, stateElement.startDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            label={i18n.endDate}
                            onChange={v => onChangeElement({ endDate: v })}
                            min={stateElement.startDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(stateElement.endDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateElement.startDate }, stateElement.endDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={functionList}
                            label={i18n.functions}
                            value={stateElement.functionCode}
                            onChange={v => onChangeElement({ functionCode: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.description}
                            value={stateElement.description}
                            onChange={v => onChangeElement({ description: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

FunctionDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const StationFunctionPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_functions'
            formatFunction={c => ({
                ...c,
                startDate: getFullDate(c.startDate),
                endDate: getFullDate(c.endDate),
                function: getSandreLabel(sandreCodes, SANDRE.FONCTIONS, c.functionCode),
            })}
            titles={{
                title: i18n.functions,
                edit: i18n.editFunction,
                new: i18n.newFunction,
            }}
            headers={headers}
            dialogContent={FunctionDialogContent}
        />
    )
}

StationFunctionPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationFunctionPanel)