import React from 'react'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { Card, CardContent, Grid2 } from '@mui/material'
import { getLogin } from 'utils/SettingUtils'
import moment from 'moment'
import { getVisitCheckBoxChange, getVisitDatePickerChange, getVisitSelectChange } from 'utils/VisitUtils'
import { getDate } from 'date-fns'
import { onChangeDate } from 'utils/FormUtils'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import Checkbox from 'components/forms/Checkbox'
import { useSelector } from 'react-redux'

const InstallationDataOriginPanel = ({
    readMode = false,
    installation = {},
    onChangeInstallation = () => { },
    onChangeVisit = () => { },
}) => {
    const {
        status,
        users,
    } = useSelector(store => ({
        status: store.QualityReducer.status,
        users: store.UserReducer.users,
    }))
    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={12}>
                        <Input
                            title={i18n.dataOrigin}
                            value={installation.dataOrigin}
                            onChange={v => {
                                onChangeInstallation({ dataOrigin: v })
                                onChangeVisit({ previousValue: installation.dataOrigin, newValue: v, field: i18n.dataOrigin })
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Select
                            label={i18n.status}
                            value={installation.status}
                            onChange={v => {
                                onChangeInstallation({
                                    status: v,
                                    statusLogin: getLogin(),
                                    statusDate: moment().valueOf(),
                                })
                                onChangeVisit(getVisitSelectChange(status, 'code', i18n.status, installation.status, v))
                                onChangeVisit({ previousValue: installation.statusLogin, newValue: getLogin(), field: i18n.controlDoneBy })
                                onChangeVisit({ previousValue: installation.statusDate, newValue: moment().valueOf(), field: i18n.controlDoneAt })
                            }}
                            options={status}
                            keyValue='code'
                            integerValue
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Input
                            value={getDate(installation.statusDate)}
                            title={i18n.controlDoneAt}
                            onChange={v => {
                                onChangeDate(v, v2 => onChangeInstallation({ statusDate: v2 }))
                                onChangeVisit(getVisitDatePickerChange(i18n.controlDoneAt, installation.statusDate, v))
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Select
                            label={i18n.controlDoneBy}
                            value={installation.statusLogin}
                            keyValue='name'
                            onChange={v => {
                                onChangeInstallation({ statusLogin: v })
                                onChangeVisit(getVisitSelectChange(users.map(u => ({ code: u.login, name: u.name || u.login })), 'name', i18n.controlDoneBy, installation.statusLogin, v))
                            }}
                            options={users.map(u => ({ code: u.login, name: u.name || u.login }))} readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Input
                            value={getDate(installation.endDate)}
                            title={i18n.deliberationDate}
                            onChange={v => {
                                onChangeDate(v, v2 => onChangeInstallation({ endDate: v2 }))
                                onChangeVisit(getVisitDatePickerChange(i18n.deliberationDate, installation.endDate, v))
                            }}
                            readMode={readMode}
                        />
                    </Grid2 >
                    <Grid2 size={12}>
                        <Input
                            value={installation.closeComment}
                            title={i18n.become}
                            onChange={v => {
                                onChangeInstallation({ closeComment: v })
                                onChangeVisit({ previousValue: installation.closeComment, newValue: v, field: i18n.become })
                            }} readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Checkbox
                            label={i18n.confidential}
                            checked={installation.confidential === '1'}
                            onChange={v => {
                                onChangeInstallation({ confidential: v ? '1' : '0' })
                                onChangeVisit(getVisitCheckBoxChange(i18n.confidential, installation.confidential === '1', v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2 >
                </Grid2 >
            </CardContent>
        </Card>
    )
}

InstallationDataOriginPanel.propTypes = {
    readMode: PropTypes.string,
    installation: PropTypes.instanceOf(DtoInstallation),
    onChangeInstallation: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default InstallationDataOriginPanel