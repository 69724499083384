import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import { sieauTooltip } from '../../../utils/FormUtils'
import Card from '../../../components/card/Card'
import Select from '../../../components/forms/Select'
import Icon from '../../../components/icon/Icon'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import validateImg from '../../../assets/pictures/pictos/validate.png'
import purgeImg from '../../../assets/pictures/pictos/purge.png'
import importImg from '../../../assets/pictures/pictos/import.png'
import compensationImg from '../../../assets/pictures/pictos/compensation.png'
import correctionImg from '../../../assets/pictures/pictos/correction.png'
import optionsImg from '../../../assets/pictures/pictos/options.png'
import thresholdImg from '../../../assets/pictures/pictos/threshold.png'
import eventImg from '../../../assets/pictures/pictos/event.png'
import ChartTabs from '../../../components/echart/ChartTabs'
import { orderBy, pick } from 'lodash'
import { getStationTitle } from '../../../utils/StationUtils'
import useTitle from '../../../utils/customHook/useTitle'
import {
    COMPENSATION,
    CORRECTION,
    GRAPH,
    OPTIONS,
    PURGE,
    TABLE,
    VALIDATION,
    WINDOW,
} from './constants/PluvioValidationConstants'
import { EVENT, IMPORT } from '../../../home/constants/RouteConstants'
import { THRESHOLDS } from '../../../alerting/constants/AlertConstants'
import PluviometryAction from '../../actions/PluviometryAction'
import ValidationPluvioToolPanel from './tools/ValidationPluvioToolPanel'
import TableViewValidationPluvioMode from './displayMode/TableViewValidationPluvioMode'
import GraphicValidationPluvioMode from './displayMode/GraphicValidationPluvioMode'
import IconTool from '../../../components/icon/IconTool'
import { Grid } from '@mui/material'
import useLocalStorage from '../../../utils/customHook/useLocalStorage'
import useApplicationSetting from '../../../utils/customHook/useApplicationSetting'
import ProgressCard from 'components/card/ProgressCard'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'

const PluviometryValidationApp = () => {
    const {
        pluviometer,
        pluvioMeasures,
        pluviometryDataTypes,
    } = useSelector(store => ({
        pluviometer: store.PluviometryReducer.pluviometer,
        pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,

    }), shallowEqual)

    const [dataType, setDataType] = useState(1)
    const [selectedCodePoint, setSelectedCodePoint] = useState(pluviometer.link_pointPrels.find(p => p.typeId === 1)?.point)
    const [tableView, setTableView] = useState(TABLE)
    const [tool, setTool] = useState(VALIDATION)
    const [selection, setSelection] = useState(WINDOW)
    const [selectionData, setSelectionData] = useState(null)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [validationFilter, setValidationFilter] = useLocalStorage('PLUVIO_VALIDATION_FILTERS', {})
    const {
        producer: defaultProducer,
        manager: defaultManager,
        validator: defaultValidator,
    } = validationFilter
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [minDate, setMinDate] = useState(null)
    const [maxDate, setMaxDate] = useState(null)
    const [value, setValue] = useState(null)
    const [initialPoint, setInitialPoint] = useState(null)
    const [status, setStatus] = useState(null)
    const [qualitifcation, setQualitifcation] = useState(null)
    const [nature, setNature] = useState(null)
    const [measureMode, setMeasureMode] = useState(null)


    const applicationSettingsDefaultProducer = useApplicationSetting('validationDefaultProducerPluvio', setting => setting ? parseInt(setting) : undefined)
    const applicationSettingsDefaultManager = useApplicationSetting('validationDefaultManagerPluvio', setting => setting ? parseInt(setting) : undefined)
    const applicationSettingsDefaultValidator = useApplicationSetting('validationDefaultValidatorPluvio', setting => setting ? parseInt(setting) : undefined)

    const [producer, setProducer] = useState(defaultProducer || applicationSettingsDefaultProducer || undefined)
    const [manager, setManager] = useState(defaultManager || applicationSettingsDefaultManager || undefined)
    const [validator, setValidator] = useState(defaultValidator || applicationSettingsDefaultValidator || undefined)

    const [isChange, setIsChange] = useState(false)

    const dispatch = useDispatch()


    useTitle(() => [{
        title: i18n.pluviometry,
        href: 'pluviometry',
    }, {
        title: getStationTitle(pluviometer),
        href: `station/pluviometry/${pluviometer.id}`,
    }, {
        title: i18n.validation,
        href: `station/pluviometry/${pluviometer.id}/pluviometryFollowUp`,
    }]
    , [pluviometer.id])

    useEffect(() => {
        dispatch(PluviometryAction.fetchPluviometers())
        dispatch(PluviometryAction.fetchPluviometryDataTypes())
    }, [])

    useEffect(() => {
        dispatch(PluviometryAction.loadPluvioChronicMeasuresValidation([{
            stationId: pluviometer.id,
            dataType,
            groupFunc: 'SUM_MAX',
            startDate: minDate,
            endDate: maxDate,
            codepoint: selectedCodePoint,
        }])).then(() => setDataLoaded(true))
    }, [pluviometer.id, dataType, selectedCodePoint, minDate, maxDate, isChange])

    const changeView = () => {
        if ([VALIDATION, PURGE].includes(tool)) {
            tableView === TABLE ? setTableView(GRAPH) : setTableView(TABLE)
        }
    }

    const getDates = () => {
        return pick({ startDate, endDate }, ['startDate', 'endDate'])
    }


    const measureChanges = (startDate, endDate) => {
        const obj = {
            newStatus: status,
            newQualitifcation: qualitifcation,
            newProducer: producer,
            newManager: manager,
            newNature: nature,
            newValidator: validator,
            newValue: value,
            newInitialPoint: initialPoint,
        }
        const measureToChanges = pluvioMeasures[0].measures.filter(p => p.date >= startDate && p.date <= endDate || p.date === startDate)
        const measureChangesUpdate = measureToChanges.map(m => ({
            ...m,
            status: obj.newStatus,
            qualification: obj.newQualitifcation,
            nature: obj.newNature,
            producer: obj.newProducer,
            manager: obj.newManager,
            validator: obj.newValidator,
            value: obj.newValue,
            initialPoint: obj.newInitialPoint,
            updated: true,
        }))
        return measureChangesUpdate
    }
    if (!dataLoaded) {
        return <ProgressCard className='margin-1' indeterminate withMessage round />
    }
    return (
        <div style={{ margin: '10 15' }}>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Card>
                        <div style={{ height: 50, padding: 3.5 }}>
                            {tableView === TABLE ? <ChartTabs
                                stationType={STATION_TYPE_NAME.pluviometry}
                                onChangeDate={(changes, forced) => {
                                    if (minDate !== changes.minDate || maxDate !== changes.maxDate || forced) {
                                        setMinDate(changes.minDate)
                                        setMaxDate(changes.maxDate)
                                    }
                                }}
                            /> : '' }
                        </div>
                        {tableView === TABLE ? <TableViewValidationPluvioMode
                            selection={selection}
                            selectionData={selectionData}
                            setSelectionData={setSelectionData}
                            measuresData={pluvioMeasures}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            setValue={setValue}
                            setInitialPoint={setInitialPoint}
                            setStatus={setStatus}
                            setQualification={ setQualitifcation }
                            setNature={ setNature }
                            setMeasureMode={ setMeasureMode }
                            setProducer={ setProducer }
                            setManager={ setManager }
                            setValidator={ setValidator }
                        />
                            : <GraphicValidationPluvioMode /> /* <GraphicValidationPluvioMode
                                    selection={selection}
                                    selectionData={selectionData}
                                    setSelectionData={setSelectionData}
                                    measuresData={pluvioMeasures}
                                    setMeasures={setMeasures}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    setValue={setValue}
                                    setInitialPoint={setInitialPoint}
                                    setStatus={setStatus}
                                    setQualification={setQualitifcation}/> */}
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card cardContentStyle={{ margin: '5px' }}>
                        <Grid container alignItems='center' spacing={1} >
                            <Grid item xs={10}>
                                <Select
                                    value={ dataType }
                                    options={ orderBy(pluviometryDataTypes, ['order', 'id'], 'asc') }
                                    label={ i18n.dataType }
                                    onChange={ v => {
                                        setDataType(v)
                                        setSelectedCodePoint(pluviometer.link_pointPrels.find(p => p.typeId === v)?.point)
                                    } }
                                    noSort
                                    noNullValue
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Icon icon={ tableView=== TABLE ? 'list' : 'multiline_chart' } onClick={ () => changeView() }
                                    style={{ fontSize: '2.5rem', color: ![VALIDATION, PURGE].includes(tool) ? 'grey' : '#2196F3' } } sieauTooltip={sieauTooltip(() => tableView ? i18n.graphicMode : i18n.tableMode)}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center' spacing={1} >
                            <Grid item xs={10}>
                                <Select
                                    value={ selectedCodePoint }
                                    options={ pluviometer.link_pointPrels.filter(s => s.typeId === dataType) }
                                    label={ i18n.pointPrelevement }
                                    onChange={ setSelectedCodePoint }
                                    noSort
                                    noNullValue
                                    keyValue='point'
                                />
                            </Grid>
                        </Grid>
                    </Card>
                    <Card>
                        <ul style={{ textAlign: 'center', marginTop: 5, marginBottom: 5, paddingTop: 5, paddingBottom: 5 }}>
                            <IconTool
                                icon={validateImg}
                                toolIcon={VALIDATION}
                                height={35} width={35}
                                tooltip={i18n.validation}
                                setTool={setTool}
                                iconSelected={tool}
                            />
                            <IconTool
                                icon={purgeImg}
                                toolIcon={PURGE}
                                height={35}
                                width={30}
                                tooltip={i18n.deleting}
                                setTool={setTool}
                                iconSelected={tool}
                            />
                            {tableView === TABLE && (
                                <>
                                    <IconTool
                                        icon={importImg}
                                        toolIcon={IMPORT}
                                        height={35}
                                        width={30}
                                        tooltip={i18n.manualImport}
                                        setTool={setTool}
                                        iconSelected={tool}
                                    />
                                    <IconTool
                                        icon={compensationImg}
                                        toolIcon={COMPENSATION}
                                        height={35} width={50}
                                        tooltip={i18n.compensation}
                                        setTool={setTool}
                                        iconSelected={tool}
                                    />
                                    <IconTool
                                        icon={correctionImg}
                                        toolIcon={CORRECTION}
                                        height={35}
                                        width={45}
                                        tooltip={i18n.driftingCorrection}
                                        setTool={setTool}
                                        iconSelected={tool}
                                    />
                                    <IconTool
                                        icon={optionsImg}
                                        toolIcon={OPTIONS}
                                        height={35}
                                        width={35}
                                        tooltip={i18n.chartOptions}
                                        setTool={setTool}
                                        iconSelected={tool}
                                    />
                                    <IconTool
                                        icon={thresholdImg}
                                        toolIcon={THRESHOLDS}
                                        setTool={setTool}
                                        height={35}
                                        width={35}
                                        tooltip={i18n.thresholds}
                                        iconSelected={tool}
                                    />
                                    <IconTool
                                        icon={eventImg}
                                        toolIcon={EVENT}
                                        height={35}
                                        width={35}
                                        tooltip={i18n.events}
                                        setTool={setTool}
                                        iconSelected={tool}
                                    />
                                </>
                            )}
                        </ul>
                    </Card>
                    <div>
                        {
                            tool === VALIDATION && (
                                <ValidationPluvioToolPanel
                                    selection={selection}
                                    setSelection={setSelection}
                                    setSelectionData={setSelectionData}
                                    objetPluvioStation={pluvioMeasures}
                                    dates={getDates()}
                                    value={value}
                                    setValue={setValue}
                                    initialPoint={initialPoint}
                                    setInitialPoint={setInitialPoint}
                                    status={status}
                                    setStatus={setStatus}
                                    qualification={qualitifcation}
                                    setQualification={setQualitifcation}
                                    nature={ nature }
                                    setNature={ setNature }
                                    measureMode={ measureMode }
                                    setMeasureMode={ setMeasureMode }
                                    producer={ producer }
                                    setProducer={ setProducer }
                                    manager={ manager }
                                    setManager={ setManager }
                                    validator={ validator }
                                    setValidator={ setValidator }
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    setIsChange={setIsChange}
                                    isChange={isChange}
                                    measureToUpdate={measureChanges(startDate, endDate)}
                                />
                            )
                        }
                        {
                            tool === PURGE && (
                                <Card><h6 className='padding-top-1 padding-bottom-1 padding-left-1'>{i18n.inDeveloppmentFunctionnality}</h6></Card>
                            )
                        }
                        {
                            tool === COMPENSATION && (
                                <Card><h6 className='padding-top-1 padding-bottom-1 padding-left-1'>{i18n.inDeveloppmentFunctionnality}</h6></Card>
                            )
                        }
                        {
                            tool === CORRECTION && (
                                <Card><h6 className='padding-top-1 padding-bottom-1 padding-left-1'>{i18n.inDeveloppmentFunctionnality}</h6></Card>
                            )
                        }
                        {
                            tool === EVENT && (
                                <Card><h6 className='padding-top-1 padding-bottom-1 padding-left-1'>{i18n.inDeveloppmentFunctionnality}</h6></Card>
                            )
                        }
                        {
                            tool === THRESHOLDS && (
                                <Card><h6 className='padding-top-1 padding-bottom-1 padding-left-1'>{i18n.inDeveloppmentFunctionnality}</h6></Card>
                            )
                        }
                        {
                            tool === IMPORT && (
                                <Card><h6 className='padding-top-1 padding-bottom-1 padding-left-1'>{i18n.inDeveloppmentFunctionnality}</h6></Card>
                            )
                        }
                        {
                            tool === OPTIONS && (
                                <Card><h6 className='padding-top-1 padding-bottom-1 padding-left-1'>{i18n.inDeveloppmentFunctionnality}</h6></Card>
                            )
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
export default PluviometryValidationApp