import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import { countBy, flatten, groupBy, isEqual, isUndefined, orderBy, pick, take, template, uniqBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import { H_PLUVIO_SITUATION } from '../../../account/constants/AccessRulesConstants'
import AdministrationAction from '../../../administration/actions/AdministrationAction'
import CampaignDto from '../../../campaign/dto/CampaignDto'
import DtoStationCampaign from '../../../campaign/dto/DtoStationCampaign'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import IconCard from '../../../components/card/IconCard'
import MessageCard from '../../../components/card/MessageCard'
import ProgressCard from '../../../components/card/ProgressCard'
import Table from '../../../components/datatable/Table'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import FilterSelect from '../../../components/forms/specific/FilterSelect'
import CartographyPanel from '../../../components/map/CartographyPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import { getThresholdColor } from '../../../utils/ColorUtil'
import { enumerateBetweenDates, getDate, getFullDate } from '../../../utils/DateUtil'
import { onChangeDate, sieauTooltip } from '../../../utils/FormUtils'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getMeasureStatusColor } from '../../../utils/PiezometryUtils'
import { statusIcon } from '../../../utils/StatusUtil'
import { arrayOf, getObjectLabel, objectOf } from '../../../utils/StoreUtils'
import { searchAllCharacters } from '../../../utils/StringUtil'
import PluviometryAction from '../../actions/PluviometryAction'
import DtoPluviometerContributorLink from '../../dto/DtoPluviometerContributorLink'
import DtoPluviometrySituations from '../../dto/DtoPluviometrySituations'
import DtoPluvioSituationDays from '../../dto/DtoPluvioSituationDays'
import PluviometerDto from '../../dto/PluviometerDto'
import UserDto from '../../../administration/components/user/dto/UserDto'

const tableHeaders = ['nullValue', 'code', 'city', 'name', 'startDate', 'date', 'value', 'measuresCount' ]
const tableHeadersRestriction = [ 'code', 'city', 'name', 'startDate', 'date', 'value', 'measuresCount' ]

class PluvioIntegrationApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = this.getInitialState()
    }

    getInitialState = () => {
        const cache = pick(AdministrationAction.getCache(STATION_TYPE_NAME.pluviometry), ['filter'])
        return {
            filter: {
                date: moment().valueOf(),
                campaign: null,
                nbDaysUndetected: 3,
                nbDaysAlert: 5,
                searchValue: '',
                dataType: 1,
                referentIds: [],
                network: null,
                nbDays: 30,
                color: 'all',
                lastMeasureBrute: false,
                ...cache,
            },
            tmpFilter: {
                date: moment().valueOf(),
                campaign: null,
                nbDaysUndetected: 3,
                nbDaysAlert: 5,
                searchValue: '',
                dataType: 1,
                referentIds: [],
                network: null,
                nbDays: 30,
                color: 'all',
                lastMeasureBrute: false,
                ...cache,
            },
            displayMode: 'list',
            event: {},
            selected: {},
            selectAll: false,
            isOpenNewCampaign: false,
            isOpenAddToCampaign: false,
            campaignStations: [],
            groupedEvents: {},
            groupedMeasureModes: {},
            networkGroup: {},
            networkList: [],
            filteredStations: [],
            forceDisplayAll: true,
            dataLoaded: false,
            minDisplayNumber: 100,
            hasCanceled: false,
        }
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_PLUVIO_SITUATION)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        $('ul.tabs').tabs()
        AppStore.dispatch(HomeAction.setHelpLink('pluviometrie', ''))
        this.props.forceFetch('title', [{
            title: i18n.pluviometry,
            href: 'pluviometry',
        }, {
            title: i18n.integrationOverview,
            href: 'pluviometry/situation',
        }])
        if (!this.props.cachePluviometrySituation) {
            this.props.loadPluviometrySituation(() => {})
                .then(() => {
                    const networkGroup = groupBy(this.props.networkPluvioLink, 'idNetwork')
                    const networkList = Object.keys(networkGroup).map(nId => this.props.networks.find(n => n.id == nId)).filter(n => !!n)
                    const filteredStations = this.getStationsFirstFilter(this.state.filter, networkGroup)
                    this.setState({
                        networkGroup,
                        networkList,
                        filteredStations,
                    })
                    this.loadData(filteredStations)
                })
        }
        const actions = {
            export: () => ({
                data: this.onExportData(),
                exportType: 'xlsx',
                titleFile: i18n.situation,
            }),
        }
        this.setActions(actions)
        if (!this.props.pluviometryDataTypes.length) {
            this.props.fetchPluviometryDataTypes()
        }
    }


    onExportData = () => {
        if (this.state.displayMode === 'list') {
            return this.getStations(this.state.filteredStations).map(s => ({ ...s, value: s.valueExport, headers: tableHeaders }))
        }
        const displayedStations = (this.state.forceDisplayAll ? this.state.filteredStations : take(this.state.filteredStations, this.state.minDisplayNumber))
        return this.getDaysData(displayedStations)
    }

    loadData = (filteredStations) => {
        const { dataType, date, nbDays } = this.state.filter
        const criterias = { date, nbDays, dataType }
        const pluviometerIds = (this.state.forceDisplayAll ? filteredStations : take(filteredStations, this.state.minDisplayNumber)).map(p => p.id)
        this.setState({ dataLoaded: false, dataLoading: true, progress: 0 })
        this.props.fetchPluviometryTypeSituationWithDays(criterias, pluviometerIds, p => this.setState({ progress: p }))
            .then(() => this.setState({ dataLoaded: true, dataLoading: false }))
    }

    getActive = (panel) => {
        return panel === this.state.displayMode ? 'active' : ''
    }

    onChangeParameter = (params, thenValidate) => {
        const tmpFilter = { tmpFilter: { ...this.state.tmpFilter, ...params } }
        this.setState(tmpFilter)
        if (thenValidate) {
            this.onValidate(params)
        }
    }

    getFirstFilterResult = (stations, filter = -1, filterResults) => {
        if (hasValue(filter) && filter !== -1 && filterResults.length) {
            return flatten(filterResults.map(stationId => stations.find(station => station.id === stationId) || []))
        }
        return stations
    }

    getStationsFirstFilter = (stateFilter = this.state.filter, networkGroup = this.state.networkGroup) => {
        const campaignGroups = groupBy(this.props.pluviometryCampaignStations, 'stationId')
        const pluvios = this.props.pluviometers.map(pluviometer => {
            const { townCode, id, code, name, x, y, projection, typeName } = pluviometer
            const city = townCode ? `${getObjectLabel(this.props.citiesIndex[townCode])} ${townCode ? ` [${townCode}]` : ''}` : ''
            return {
                id,
                code,
                name,
                city,
                campaigns: (campaignGroups[id] || []).map(c => c.campaignId),
                hash: searchAllCharacters([code, name, city].join('   ')),
                x,
                y,
                projection,
                typeName,
                scale: 0.5,
            }
        })

        const filterResults = stateFilter.filterStations ? stateFilter.filterStations : this.getFirstFilterResult(pluvios, stateFilter.filter, this.props.filterResults)

        const searchValue = hasValue(stateFilter.searchValue) ? searchAllCharacters(stateFilter.searchValue) : null
        const filterIds = hasValue(stateFilter.filter) && stateFilter.filter != -1 ? filterResults.map(f => f.id) : null

        const filterSearchValue = hasValue(searchValue) ? pluvios.filter(p => p.hash.includes(searchValue)) : pluvios
        const filterStationsFilter = filterIds ? filterSearchValue.filter(s => filterIds.includes(s.id)) : filterSearchValue
        const filterCampaign = hasValue(stateFilter.campaign) ? filterStationsFilter.filter(s => s.campaigns.includes(stateFilter.campaign)) : filterStationsFilter
        const filterNetwork = hasValue(stateFilter.network) ? filterCampaign.filter(s => (networkGroup[stateFilter.network] || []).map(n => n.idPluviometer).includes(s.id)) : filterCampaign
        const ids = this.props.pluviometersContributors?.filter(h => stateFilter.referentIds?.includes(h?.idContributor)).map(n => n.idStation)
        const filterReferent = stateFilter.referentIds?.length ? filterStationsFilter.filter(s => ids.includes(s.id)) : filterNetwork

        const stations = orderBy(filterReferent, o => o.name ? o.name.toUpperCase() : '}')
        this.props.setSelectedSearchValues('pluviometry', { stations })
        return stations
    }

    onValidate = (newState = this.state) => {
        const filteredStations = this.getStationsFirstFilter(newState.tmpFilter)
        const usedState = { filter: newState.tmpFilter, filteredStations, hasCanceled: false }
        this.setState(usedState, () => this.loadData(filteredStations))
    }

    getFilterPanel = (coloredStations) => {
        const colors = (!coloredStations.length) ? null : countBy(coloredStations, s => s.date.classNameColor ? s.date.classNameColor : 'white')
        const colorStats = {
            red: 0,
            orange: 0,
            green: 0,
            white: 0,
            ...colors,
        }
        const getStat = (color) => !colorStats ? '' : ` (${colorStats[color]})`
        const { date, nbDays, searchValue, campaign, network, dataType, nbDaysUndetected, nbDaysAlert } = this.state.tmpFilter
        return (
            <Card className='padding-top-1'>
                <div className='row no-margin'>
                    <Input
                        col={ 1 }
                        title={ i18n.endDate }
                        value={getDate(date)}
                        onChange={ v => onChangeDate(v, v2 => this.onChangeParameter({ date: v2 })) }
                    />
                    <NumberField
                        col={ 2 }
                        value={ nbDays }
                        onChange={ v => this.onChangeParameter({ nbDays: v }) } title={ i18n.nbDays }
                    />
                    <Input
                        col={2}
                        title={i18n.search}
                        value={searchValue}
                        onChange={v => this.onChangeParameter({ searchValue: v })}
                    />
                    <FilterSelect col={2} onChange={(v, filter, isFirstTime) => {
                        this.onChangeParameter({ filterStations: v, filter }, isFirstTime)
                    }} stationType='pluviometry'
                    />
                    <Select col={2} label={i18n.campaigns} options={this.props.pluviometryCampaigns}
                        value={campaign}
                        onChange={(v) => this.onChangeParameter({ campaign: v ? v : null })} nullLabel='&nbsp;'
                    />
                    <Select
                        options={this.props.networks}
                        keyLabel='mnemonic'
                        col={3}
                        label={i18n.network}
                        nullLabel='&nbsp;'
                        onChange={(value = {}) => {
                            this.onChangeParameter({ network: value })
                        }}
                        value={network}
                    />
                </div>
                <div className='row no-margin'>
                    <Select
                        col={3}
                        label={i18n.chronicType}
                        options={orderBy(this.props.pluviometryDataTypes, 'id')}
                        value={dataType}
                        onChange={v => this.onChangeParameter({ dataType: v })} noSort
                    />
                    <MultiContributorsAutocomplete
                        options={uniqBy(this.props.contributors, 'code')}
                        col={3}
                        multiple
                        label={i18n.referent}
                        onChange={(referentIds) => this.onChangeParameter({ referentIds }) }
                        keyValue='id'
                        limit={1}
                    />
                    <NumberField
                        col={3}
                        title={i18n.nbDaysUndetected}
                        onChange={v => this.onChangeParameter({ nbDaysUndetected: v })}
                        value={nbDaysUndetected}
                        max={nbDaysAlert}
                        min={0}
                    />
                    <NumberField
                        col={3}
                        title={i18n.nbDaysAlert}
                        onChange={v => this.onChangeParameter({ nbDaysAlert: v })}
                        value={nbDaysAlert}
                        min={nbDaysUndetected}
                    />
                </div>
                <Checkbox col={ 1 }
                    checked={ this.state.tmpFilter.color === 'all' }
                    label={ i18n.all }
                    onChange={ () =>
                        this.setState({ tmpFilter: { ...this.state.tmpFilter, color: 'all' } })
                    }
                />
                <Checkbox col={ 1 }
                    checked={ this.state.tmpFilter.color === 'green' }
                    label={ <span><span className={ 'green darken-1 arrests-level-panel green-text' }>__</span>{ getStat('green') }</span> }
                    onChange={ v =>
                        this.setState({ tmpFilter: { ...this.state.tmpFilter, color: v ? 'green' : 'all' } })
                    }
                />
                <Checkbox col={ 1 }
                    checked={ this.state.tmpFilter.color === 'orange' }
                    label={ <span><span className={ 'yellow darken-1 arrests-level-panel yellow-text' }>__</span>{ getStat('orange') }</span> }
                    onChange={ v =>
                        this.setState({ tmpFilter: { ...this.state.tmpFilter, color: v ? 'orange' : 'all' } })
                    }
                />
                <Checkbox col={ 1 }
                    checked={ this.state.tmpFilter.color === 'red' }
                    label={ <span><span className={ 'red darken-1 arrests-level-panel red-text' }>__</span>{ getStat('red') }</span> }
                    onChange={ v =>
                        this.setState({ tmpFilter: { ...this.state.tmpFilter, color: v ? 'red' : 'all' } })
                    }
                />
                <Checkbox col={ 1 }
                    checked={ this.state.tmpFilter.color === 'white' }
                    label={ i18n.empty + getStat('white') }
                    onChange={ v =>
                        this.setState({ tmpFilter: { ...this.state.tmpFilter, color: v ? 'white' : 'all' } })
                    }
                />
                <Checkbox
                    col={1}
                    checked={this.state.tmpFilter.lastMeasureBrute}
                    label={ i18n.toValidate }
                    onChange={() => this.setState({ tmpFilter: { ...this.state.tmpFilter, lastMeasureBrute: !this.state.tmpFilter.lastMeasureBrute } })}
                />
                <div className='row no-margin'>
                    <div className='col offset-s9 s3 padding-bottom-1'>
                        <a className='btn col s12' onClick={() => this.onValidate()}>
                            {i18n.search}
                        </a>
                    </div>
                </div>
                <div className='row no-margin'>
                    <div className='col s9'>
                        { this.state.dataLoaded && !this.state.forceDisplayAll && !this.state.hasCanceled && this.state.filteredStations.length > this.state.minDisplayNumber && (
                            <IconCard icon='warning' color='orange'>
                                <div className='row no-margin valign-wrapper'>
                                    <div className='col s12'>
                                        <h6 className='bold no-margin-bottom'>{i18n.tooMuchDataToShow}</h6>
                                        <div className='flex-row'>
                                            <h6>{template(i18n.performanceDisplayMessage)({
                                                maxNbElements: this.state.minDisplayNumber,
                                                nbElements: this.state.filteredStations.length,
                                            })}</h6>
                                            <div className='padding-left-1'/>
                                            <h6 className='textLink' onClick={ () => this.setState({ forceDisplayAll: true, dataLoaded: false }, () => this.loadData(this.getStationsFirstFilter(this.state.filter))) }>{i18n.displayEverything}</h6>
                                        </div>
                                    </div>
                                </div>
                            </IconCard>
                        )}
                    </div>
                    <div className='col s3 no-padding'>
                        <ul className='tabs'>
                            <li className='tab col s4' id='list'
                                onClick={() => this.setState({ displayMode: 'list' })} {...sieauTooltip(i18n.table, null, 'bottom')}
                            >
                                <a className={this.getActive('list')}><i
                                    className='material-icons inherited-line-height'
                                >list</i></a>
                            </li>
                            <li className='tab col s4' id='list'
                                onClick={() => this.setState({ displayMode: 'days' })} {...sieauTooltip(i18n.days, null, 'bottom')}
                            >
                                <a className={this.getActive('days')}><i
                                    className='material-icons inherited-line-height'
                                >event</i></a>
                            </li>
                            <li className='tab col s4' id='map'
                                onClick={() => this.setState({ displayMode: 'map' })} {...sieauTooltip(i18n.map, null, 'bottom')}
                            >
                                <a className={this.getActive('map')}><i
                                    className='material-icons inherited-line-height'
                                >map</i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </Card>
        )
    }

    getDateState = (date) => {
        if (moment(date).add(this.state.filter.nbDaysUndetected, 'days').isSameOrAfter(moment(this.state.filter.date))) {
            return { color: 'green', tooltip: template(i18n.infDaysData)({ days: this.state.filter.nbDaysUndetected }) }
        }
        if (moment(date).add(this.state.filter.nbDaysAlert, 'days').isSameOrAfter(moment(this.state.filter.date))) {
            return { color: 'orange', tooltip: template(i18n.infBetweenDaysData)({ days1: this.state.filter.nbDaysUndetected, days2: this.state.filter.nbDaysAlert }) }
        }
        return { color: 'red', tooltip: template(i18n.supDaysData)({ days: this.state.filter.nbDaysAlert }) }
    }

    getStations = filteredStations => {
        return filteredStations.map(pluviometer => {
            const baseObj = {
                ...pluviometer,
                nullValue: { leftIcon: 'border_color', leftIconColor: 'dodgerblue', leftIconClick: () => AppStore.dispatch(push(`/station/pluviometry/${pluviometer.id}/validationPluvio`)), leftIconTip: () => i18n.goToValidation },
                code: { value: pluviometer.code, onClick: () => this.props.push(`/station/pluviometry/${pluviometer.id}`) },
                name: { value: pluviometer.name, onClick: () => this.props.push(`/station/pluviometry/${pluviometer.id}`) },
                color: { value: 'white' },
                city: { value: pluviometer.city },
            }
            const pluvioSituation = this.props.pluviometrySituations.find(s => s.pluviometerId === pluviometer.id)
            if (pluvioSituation) {
                const { color, tooltip } = this.getDateState(pluvioSituation.endDate)
                return {
                    ...baseObj,
                    startDate: { value: getDate(pluvioSituation.startDate) },
                    date: {
                        value: getFullDate(pluvioSituation.endDate),
                        classNameColor: color,
                        color,
                        setTooltip: () => tooltip,
                    },
                    value: {
                        value: (
                            <span className='valign-wrapper'>
                                { statusIcon(pluvioSituation, undefined, true) }
                                <span className='padding-left-1'/>
                                { pluvioSituation.value }
                            </span>),

                        status: pluvioSituation.status,
                        qualification: pluvioSituation.qualification,
                        classNameColor: pluvioSituation.thresholdColor ? getThresholdColor(pluvioSituation.thresholdColor) : 'white',
                        setTooltip: pluvioSituation.threshold ? () => pluvioSituation.threshold : null,
                    },
                    valueExport: { value: pluvioSituation.value, color: pluvioSituation.thresholdColor ? getThresholdColor(pluvioSituation.thresholdColor) : 'white' },
                    min: { value: pluvioSituation.min },
                    max: { value: pluvioSituation.max },
                    measuresCount: { value: pluvioSituation.measuresCount },
                    color: { value: color },
                }
            }
            return {
                ...baseObj,
                startDate: '',
                endDate: '',
                date: '',
                value: '',
                min: '',
                max: '',
                measuresCount: '',
                color: 'white',
            }
        })
    }


    getDaysData = (displayedStations) => {
        const dates = enumerateBetweenDates(moment(this.state.filter.date).subtract(this.state.filter.nbDays - 1, 'days').valueOf(), this.state.filter.date, 'days').map(m => m.format('DD/MM/YY'))
        return displayedStations.map(s => {
            const daysSituation = this.props.pluviometryDaysSituation.find(situ => situ.pluvioId === s.id)
            const daysData = {}
            daysSituation.days.forEach(d => {
                const format = moment(d.date).format('DD/MM/YY')
                daysData[format.startsWith('01') || this.state.filter.nbDays >= 30 ? format: format.split('/')[0]] = {
                    value: d.count,
                    color: getMeasureStatusColor(d.status),
                }
            })
            return {
                headers: ['icon', 'code', 'name', 'city', ...dates],
                code: { value: s.code.value, onClick: () => this.props.push(`/station/pluviometry/${s.id}`) },
                name: { value: s.name.value, onClick: () => this.props.push(`/station/pluviometry/${s.id}`) },
                city: { value: s.city.value },
                ...daysData,
            }
        })
    }

    filteredStations = (stations) => {
        const { filter } = this.state
        const filtered1 = filter.color === 'all' ? stations :
            (filter.color === 'white' ? stations.filter(s => !s.date.classNameColor || s.date.classNameColor === 'white') : stations.filter(s => s.date.classNameColor === filter.color))
        return !filter.lastMeasureBrute ? filtered1 : filtered1.filter(s => (isUndefined(s.value.status) || s.value.status === 1) && !(s.value === ''))
    }

    getDaysPanel = (displayedStations) => {
        const data = this.getDaysData(this.filteredStations(displayedStations))
        if (data.length) {
            return (
                <div className='padding-top-1 padding-left-1 padding-right-1'>
                    <Table data={data}
                        sortable noHightlight
                        orderable
                        color
                        title={ i18n.pluviometers }
                        paging
                        nbPerPageLabel={nbPerPageLabel}
                        type={{ headers: data[0].headers }}
                        customHeaders={ { icon: ' ' } }
                        centerNumberHeaders
                        onSort={ (column, sort) => this.setState({ usedSort: { column, sort } }) }
                        initialSort={ this.state.usedSort }
                        condensed
                    />
                </div>
            )
        }
        return <MessageCard>{ i18n.noResults }</MessageCard>
    }

    getStationsCarto = (stations) => {
        return stations.map(station => {
            return {
                date: '',
                value: '',
                ...station,
                code: station.code.value,
                name: station.name.value,
                city: station.city.value,
                markerIcon: `pictures/markers/map_pluvio_${station.date.classNameColor || 'white'}.png`,
                color: null,
            }
        })
    }

    getPanel = (stations) => {
        if (this.state.dataLoaded && !this.state.dataLoading && !this.state.hasCanceled) {
            const filteredStations = this.filteredStations(stations)
            switch (this.state.displayMode) {
                case 'list':
                    return (
                        <div className='padding-top-1 padding-left-1 padding-right-1'>
                            <Table data={filteredStations}
                                sortable noHightlight
                                orderable
                                color
                                title={i18n.pluviometers}
                                paging
                                nbPerPageLabel={nbPerPageLabel}
                                type={{ headers: this.props.user.consultant === '1' ? tableHeadersRestriction : tableHeaders }}
                                customHeaders={ { nullValue2: <Checkbox checked={ this.state.selectAll } onChange={ this.checkAll } />, value: `${i18n.valueLabel} (mm)` }}
                                condensed
                            />
                        </div>
                    )
                case 'days':
                    return this.getDaysPanel(filteredStations)
                default:
                    return (
                        <CartographyPanel layers={['STATIONS_POINTS']}
                            componentType={'pluviometry'}
                            stationsPoints={this.getStationsCarto(filteredStations)}
                            stationsPanelTitle={i18n.stations}
                        />
                    )
            }
        }
        if (this.state.hasCanceled) {
            return <div className='padding-top-1 padding-left-1 padding-right-1'><MessageCard >{ i18n.noDataToDisplay }</MessageCard></div>
        }
        if (this.state.dataLoading) {
            return (
                <div className='padding-top-1 padding-left-1 padding-right-1'>
                    <ProgressCard progress={this.state.progress} withCancel whenCancel={ () => this.setState({ hasCanceled: true })}/>
                </div>
            )
        }
        return <div className='padding-top-1 padding-left-1 padding-right-1'><MessageCard >{ i18n.dataPreparing }</MessageCard></div>
    }

    render() {
        const displayedStations = this.state.dataLoaded ? (this.state.forceDisplayAll ? this.state.filteredStations : take(this.state.filteredStations, this.state.minDisplayNumber)) : []
        const coloredStations = this.getStations(displayedStations)
        return (
            <div>
                <div className='row no-margin'>
                    <div className='col s12 m12'>
                        { this.getFilterPanel(coloredStations) }
                    </div>
                </div>
                <div className='row no-margin'>
                    { this.getPanel(coloredStations) }
                </div>
            </div>
        )
    }

    shouldComponentUpdate(_, nextState) {
        if (this.state.selectAll !== nextState.selectAll) {
            return true
        }
        if (!isEqual(this.state.selected, nextState.selected) || !isEqual(this.state.event, nextState.event)) {
            return false
        }
        return true
    }
}

PluvioIntegrationApp.propTypes = {
    user: PropTypes.instanceOf(UserDto),
    params: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
    }),
    citiesIndex: objectOf(CityDto),
    pluviometrySituations: arrayOf(DtoPluviometrySituations),
    pluviometers: arrayOf(PluviometerDto),
    pluviometryDaysSituation: arrayOf(DtoPluvioSituationDays),
    pluviometryCampaigns: arrayOf(CampaignDto),
    pluviometryCampaignStations: arrayOf(DtoStationCampaign),
    fetchPluviometryCampaigns: PropTypes.func,
    fetchPluviometryCampaignStations: PropTypes.func,
    pluviometersContributors: arrayOf(DtoPluviometerContributorLink),
    push: PropTypes.func,
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    fetchPluviometryDataTypes: PropTypes.func,
    filterResults: PropTypes.arrayOf(PropTypes.number),
}

const mapStateToProps = store => ({
    user: store.AccountReducer.accountUser,
    citiesIndex: store.CityReducer.citiesIndex,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometrySituations: store.PluviometryReducer.pluviometrySituations,
    pluviometryDaysSituation: store.PluviometryReducer.pluviometryDaysSituation,
    pluviometryCampaigns: store.PluviometryReducer.pluviometryCampaigns,
    pluviometryCampaignStations: store.PluviometryReducer.pluviometryCampaignStations,
    networks: store.NetworkReducer.networks,
    networkPluvioLink: store.PluviometryReducer.networkPluvioLink,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    pluviometersContributors: store.PluviometryReducer.pluviometersContributors,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    filterResults: store.StationReducer.filterResults,
})

const mapDispatchToProps = {
    push,
    forceFetch: SieauAction.forceFetch,
    fetchPluviometryTypeSituationWithDays: PluviometryAction.fetchPluviometryTypeSituationWithDays,
    setSelectedSearchValues: AdministrationAction.setSelectedSearchValues,
    loadPluviometrySituation: PluviometryAction.loadPluviometrySituation,
    toastError: ToastrAction.error,
    fetchPluviometryDataTypes: PluviometryAction.fetchPluviometryDataTypes,
}

export default connect(mapStateToProps, mapDispatchToProps)(PluvioIntegrationApp)
