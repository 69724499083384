import { arrayOf } from '../../../utils/StoreUtils'
import PropTypes from 'prop-types'
import { MONTHS } from '../../../piezometry/components/suivi/constants/PiezometerSuiviConstants'
import { maxBy, meanBy, minBy, range, round, zip } from 'lodash'
import moment from 'moment/moment'
import DtoMonthValues from '../../../piezometry/components/suivi/dto/DtoMonthValues'
import i18n from 'simple-react-i18n'
import DtoYearValues from '../../../piezometry/components/suivi/dto/DtoYearValues'
import Card from '../../../components/card/Card'
import Table from '../../../components/datatable/Table'
import React from 'react'
import { setModal } from '../../../utils/FormUtils'
import Row from '../../../components/react/Row'
import DtoPluvioMeasureLight from '../../dto/measures/DtoPluvioMeasureLight'

const getAnnualData = (measures, year) => {
    const allDaysByMonth = MONTHS.map(() => {
        return range(1, 32).map(() => null)
    })

    measures.map(measure => {
        const date = moment(measure.date)
        allDaysByMonth[date.month()][date.date() - 1] = measure.value
    })

    const coloredData = allDaysByMonth.map(monthDays => {
        const coloredMonthDays = monthDays.map(value => {
            return { value, color: 'white' }
        })
        const dataWithValues = coloredMonthDays.filter(data => data.value && data.value !== null)
        if (maxBy(dataWithValues, 'value')) {
            const maxValue = maxBy(coloredMonthDays, 'value').value
            const minValue = minBy(coloredMonthDays, 'value').value
            coloredMonthDays.map(data => {
                if (data.value === maxValue) {
                    data.color = '#F44336'
                }
                if (data.value === minValue) {
                    data.color = '#3465ff'
                }
                return data
            })
            coloredMonthDays.push({ value: minBy(dataWithValues, 'value').value, color: 'orange' })
            coloredMonthDays.push({ value: meanBy(dataWithValues, 'value'), color: 'orange' })
            coloredMonthDays.push({ value: maxBy(dataWithValues, 'value').value, color: 'orange' })
        } else {
            range(3).map(() => coloredMonthDays.push({ value: null, color: 'orange' }))
        }
        return coloredMonthDays
    })

    const values = Reflect.apply(zip, {}, coloredData) // transpose matrice
        .map((months, index) => new DtoMonthValues({ value: index + 1, color: 'grey' }, months))

    values[31] = Object.assign({}, values[31], {
        day: { value: i18n.min, color: 'grey' },
        min: { value: values[31].min.value, color: '#F44336' },
        mean: null,
        max: null,
    })
    values[32] = Object.assign({}, values[32], {
        day: { value: i18n.mean, color: 'grey' },
        min: null,
        mean: { value: values[32].mean.value, color: 'orange' },
        max: null,
    })
    values[33] = Object.assign({}, values[33], {
        day: { value: i18n.max, color: 'grey' },
        min: null,
        mean: null,
        max: { value: values[33].max.value, color: '#3465ff' },
    })

    return {
        values,
        nbMeasures: measures.length,
    }
}

const getHistoricData = (measures, minYear, maxYear) => {
    const allDaysByYear = range(minYear, maxYear+1).map(year => {
        return {
            year,
            data: range(1, 367).map(() => null),
        }
    })

    measures.map(measure => {
        const correspondingYearData = allDaysByYear.find(year => parseInt(year.year) === parseInt(moment(measure.date).year())).data
        const bisextileDate = moment(measure.date).year(2016)
        correspondingYearData[bisextileDate.dayOfYear() - 1] = measure.value
    })

    const coloredData = allDaysByYear.map(yearData => {
        const coloredYearData = yearData.data.map(value => {
            return { value, color: 'white' }
        })
        const dataWithValues = coloredYearData.filter(data => data.value)
        if (maxBy(dataWithValues, 'value')) {
            const maxValue = maxBy(coloredYearData, 'value').value
            const minValue = minBy(coloredYearData, 'value').value
            coloredYearData.map(data => {
                if (data.value === maxValue) {
                    data.color = '#F44336'
                }
                if (data.value === minValue) {
                    data.color = '#3465ff'
                }
                return data
            })
            coloredYearData.push({ value: minBy(dataWithValues, 'value').value, color: 'orange' })
            coloredYearData.push({ value: meanBy(dataWithValues, 'value'), color: 'orange' })
            coloredYearData.push({ value: maxBy(dataWithValues, 'value').value, color: 'orange' })
        } else {
            range(3).map(() => coloredYearData.push({ value: null, color: 'orange' }))
        }
        return coloredYearData
    })

    const values = Reflect.apply(zip, {}, coloredData) // transpose matrice
        .map((days, index) => new DtoYearValues({ value: moment('2016-01-01').dayOfYear(index + 1).format('DD/MM'), color: 'grey' }, days))

    values[366] = Object.assign({}, values[366], {
        day: { value: i18n.min, color: 'grey' },
        min: { value: values[366].min.value, color: '#F44336' },
        mean: null,
        max: null,
    })
    values[367] = Object.assign({}, values[367], {
        day: { value: i18n.mean, color: 'grey' },
        min: null,
        mean: { value: values[367].mean.value, color: 'orange' },
        max: null,
    })
    values[368] = Object.assign({}, values[368], {
        day: { value: i18n.max, color: 'grey' },
        min: null,
        mean: null,
        max: { value: values[368].max.value, color: '#3465ff' },
    })

    return {
        values,
        nbMeasures: measures.length,
    }
}

const displayLegend = () => {
    setModal({
        title: i18n.legend,
        actions: (<div><a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a></div>),
        content: (
            <div>
                <Row><span className={ 'red arrests-level-panel ' + 'red-text' }>O</span> { i18n.monthlyMin }</Row>
                <Row><span className={ 'blue arrests-level-panel ' + 'blue-text' }>O</span> { i18n.monthlyMax }</Row>
                <Row><span className={ 'orange arrests-level-panel ' + 'orange-text' }>O</span> { i18n.statistics }</Row>
            </div>
        ),
    })
}

const PluvioSuiviTable = ({
    measures,
    horizon,
    dataLoaded,
    minYear,
    maxYear,
    selectedYear,
}) => {
    if (dataLoaded) {
        if (measures.length !== 0) {
            const data = horizon === 'historic' ? getHistoricData(measures, minYear, maxYear) : getAnnualData(measures, selectedYear)
            if (data.nbMeasures === 0) {
                return (
                    <div>
                        <Card className='padding-top-1 padding-bottom-1 padding-left-1'>
                            <h5>{i18n.noDataToDisplay}</h5>
                        </Card>
                        {/* {this.state.openExportModal && this.getExportModal(data)}*/}
                    </div>
                )
            }
            return (
                <div>
                    <Table color title={`${i18n.dataFollowUp} (${data.nbMeasures} ${i18n.measures})`}
                        data={data.values} sortable showNbElements={false}
                        type={data.values[0]} condensed id='suiviTable'
                        actions={[{ iconName: 'info', onClick: displayLegend, tooltip: i18n.legend }]}
                    />
                    {/* {this.state.openExportModal && this.getExportModal(data)}*/}
                </div>
            )
        }
        return (
            <div>
                <Card className='padding-top-1 padding-bottom-1 padding-left-1'>
                    <h5>{i18n.noDataToDisplay}</h5>
                </Card>
                {/* {this.state.openExportModal && this.getExportModal({})}*/}
            </div>
        )
    }
    return null
}

PluvioSuiviTable.propTypes = {
    measures: arrayOf(DtoPluvioMeasureLight),
    horizon: PropTypes.string,
    dataLoaded: PropTypes.bool,
    minYear: PropTypes.number,
    maxYear: PropTypes.number,
    selectedYear: PropTypes.number,
}

export default PluvioSuiviTable