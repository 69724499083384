import { Grid } from '@mui/material'
import Card from 'components/card/Card'
import { find, orderBy, template } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ResourceDto from 'resource/dto/ResourceDto'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import User from '../../../account/dto/User'
import UserAction from '../../../administration/components/user/actions/UserAction'
import DtoUserBookmark from '../../../administration/components/user/dto/DtoUserBookmark'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import SieauAction from '../../../components/sieau/SieauAction'
import QualityAction from '../../../quality/actions/QualityAction'
import DtoQualityProducer from '../../../quality/dto/DtoQualityProducer'
import DtoAquifer from '../../../referencial/components/aquifers/dto/DtoAquifer'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import CityInformationModal from '../../../referencial/components/city/components/information/CityInformationModal'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import DtoCountry from '../../../referencial/components/city/dto/DtoCountry'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import CountryAction from '../../../referencial/components/country/actions/CountryAction'
import ManagementUnitAction from '../../../referencial/components/managementUnit/actions/ManagementUnitAction'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { getDate, getYear } from '../../../utils/DateUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getExploitationAEP, getLogin } from '../../../utils/SettingUtils'
import {
    execByType,
    execByTypes,
    findStationType,
    getBookmarks,
    getStation,
    getStationType,
} from '../../../utils/StationUtils'
import { getLabel, getMapStateToProps, getPropTypes, getSandreList } from '../../../utils/StoreUtils'
import { getSandreLabel, getSandreMnemonique } from '../../../utils/StringUtil'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import StationAction from '../../actions/StationAction'
import { STATION_PRODUCER } from '../../constants/StationConstants'
import DtoFieldMode from '../../dto/DtoFieldMode'
import DtoHydrogeologicalEntity from '../../dto/DtoHydrogeologicalEntity'
import DtoNature from '../../dto/DtoNature'
import DtoQualitometerRepresentiveness from '../../dto/DtoQualitometerRepresentiveness'
import DtoQuality from '../../dto/DtoQuality'
import DtoState from '../../dto/DtoState'
import DtoStation from '../../dto/DtoStation'
import DtoType from '../../dto/DtoType'
import DtoWaterMass from '../../dto/DtoWaterMass'


const $ = window.$
const propsToFetch = {
    installation: false,
    hydrometricStation: false,
    distributionUnitSectors: false,
    operations: false,

    contributors: true,
    contributorTypes: true,
    watersheds: true,
    sandreCodes: true,
}

class StationDescription extends Component {
    componentDidMount() {
        // this.props.fetch(propsToFetch)
        if (this.props.userBookmarks.length) {
            this.props.fetchBookmarks()
        }
        execByTypes(this.props.stationTypes, {
            quality: this.fetchQuality,
            piezometry: () => null,
            pluviometry: () => null,
            hydrometry: () => null,
            installation: () => null,
            units: () => {
                this.props.fetchManagementUnits()
            },
        })
    }

    componentDidUpdate(prevProps) {
        execByTypes(this.props.stationTypes, {
            quality: () => {
                if (!this.props.qualitometer.id && prevProps.qualitometer.id) {
                    this.fetchQuality()
                }
            },
            piezometry: () => null,
            pluviometry: () => null,
            hydrometry: () => null,
            units: () => null,
        })
    }

    fetchQuality = () => {
        if (!this.props.qualitometerType.length) {
            this.props.fetchQualitometerType()
        }
        if (!this.props.types.length) {
            this.props.fetchTypes()
        }
        if (!this.props.qualities.length) {
            this.props.fetchQualities()
        }
        if (!this.props.fieldModes.length) {
            this.props.fetchFieldModes()
        }
        if (!this.props.states.length) {
            this.props.fetchStates()
        }
        if (!this.props.hydrogeologicalEntities.length) {
            this.props.fetchHydrogeologicalEntities()
        }
        if (!this.props.qualitometerRepresentiveness.length) {
            this.props.fetchQualitometerRepresentiveness()
        }
        if (!this.props.cities.length) {
            this.props.fetchCities()
        }
        if (!this.props.countries.length) {
            this.props.fetchCountries()
        }
        if (this.props.qualitometer.nature && !this.props.nature) {
            this.props.fetchNature(this.props.qualitometer.nature)
        }
        this.props.fetchQualityProducers(this.props.qualitometer.id, this.props.qualitometer.code)
    }

    setBookmark = (deleteBookmark = false) => {
        const code = getStation(this.props, this.props.stationTypes[0]).code
        const stationType = findStationType(this.props.stationTypes[0]).code
        if (deleteBookmark) {
            this.props.deleteBookmark(new DtoUserBookmark({ login: getLogin(), identifiant: code, stationType }))
        } else {
            this.props.createBookmark(new DtoUserBookmark({ login: getLogin(), identifiant: code, stationType }))
        }
    }

    getRow = (title, information) => {
        return (
            <div className='row no-margin'>
                <div className='col l3 right-align'>
                    <h6 className='bold'>{title}</h6>
                </div>
                <div className='col l9'>
                    <h6>{information} </h6>
                </div>
            </div>
        )
    }

    getLinkRow = (title, information, link) => {
        return (
            <div className='row no-margin valign-wrapper'>
                <div className='col l3 right-align'>
                    <h6 className='bold'>{title}</h6>
                </div>
                <div className='col l9'>
                    <h6><a href={link} className='blue-text'>{information} </a></h6>
                </div>
            </div>
        )
    }

    openModal = (id) => {
        const idModal = `#${id}`
        $(idModal).appendTo('body')
        $(idModal).modal('open')
    }

    getLinkCity = (title, station) => {
        const habilitation = this.props.accountUser.metadata
        const cityInformation = (() => {
            if (this.props.cities && this.props.cities.length && station && station.townCode) {
                const cityFound = find(this.props.cities, (o) => {
                    return o.code == station.townCode
                })
                if (cityFound) {
                    return cityFound
                }
                return null
            }
            return null
        })()
        if (cityInformation) {
            const information = `${cityInformation.name} [${cityInformation.code}]`
            if (habilitation && habilitation == '1') {
                return this.getLinkRow(title, information, `#/referencial/city/${cityInformation.code}`)
            }
            const idModal = uuidv4()
            return (
                <div className='row no-margin'>
                    <div className='col l3 right-align'>
                        <h6 className='bold'>{title}</h6>
                    </div>
                    <div className='col l9'>
                        <CityInformationModal id={idModal} city={cityInformation} />
                        <a onClick={() => this.openModal(idModal)}
                            className='blue-text clickable'
                        >{information} </a>
                    </div>
                </div>
            )
        }
        return null
    }

    getCoordinates = () => {
        const localisation = getStation(this.props, this.props.stationTypes[0]).localisation
        if (localisation.x && localisation.y) {
            const projection = localisation.projection ? (
                <div className='col l3'>
                    <span><b>{i18n.projection} :</b> {localisation.projection}</span>
                </div>
            ) : null
            return (
                <div className='row no-margin'>
                    <div className='col l3 right-align'>
                        <h6 className='bold'>{i18n.localisation}</h6>
                    </div>
                    <div className='col l3'>
                        <span><b>X: </b>{localisation.x}</span>
                    </div>
                    <div className='col l3'>
                        <span><b>Y: </b>{localisation.y}</span>
                    </div>

                    {projection}
                </div>
            )
        }
        return null
    }

    getAquifer = () => {
        if (this.props.aquifers.length > 0 && this.props.qualitometer.qualitometerAquiferLink.length > 0) {
            return this.props.qualitometer.qualitometerAquiferLink.map((aquiferLink) => {
                const result = this.props.aquifers.find(o => aquiferLink.idAquifer === o.code)
                if (result) {
                    return this.getRow(i18n.aquifer, `${result.name} [${result.code}]`)
                }
                return ''
            })
        }
        return ''
    }

    getHydrogeologicalEntity = () => {
        if (this.props.qualitometer.qualitometerHydrogeologicalEntityLink.length > 0
            && this.props.hydrogeologicalEntities.length !== 0) {
            const entities = this.props.qualitometer.qualitometerHydrogeologicalEntityLink.map((o) => {
                const result = find(this.props.hydrogeologicalEntities, (tmp) => {
                    return tmp.code === o.idHydrogeologicalEntity
                })
                return result ? `${result.name} [${result.code}],` : ''
            }).filter(e => e !== '')
            return entities.length !== 0 ? this.getRow(i18n.hydrogeologicalEntity, entities) : null
        }
        return null
    }

    getBookmark = () => {
        const bookmarks = getUserBookmarksByStationType(this.props.userBookmarks, this.props.stationTypes[0])
        return getBookmarks(getStation(this.props, this.props.stationTypes[0]).code, bookmarks, true, this.setBookmark)
    }

    getIcon = () => {
        const station = getStation(this.props, this.props.stationTypes[0])
        return hasValue(station.jobExecutionId) ? <i className='material-icons'>wifi</i> : null
    }

    getNameFromList = (list, code, key, usedKey = code, link) => {
        if (list && list.length !== 0) {
            const result = list.find(e => e[usedKey] == code)
            if (result) {
                if (link) {
                    return this.getLinkRow(key, `${result.name} [${result.code}]`, link)
                }
                return this.getRow(key, `${result.name} [${result.code}]`)
            }
        }
        return null
    }

    getContributors = (contributors) => {
        if (contributors.length) {
            if (getExploitationAEP(this.props.applicationSettings)) {
                return contributors.filter(c => !c.endDate).map(c => this.getRow(getLabel(this.props.contributorTypes, c.type), getLabel(this.props.contributors, c.id)))
            }
            const found = contributors.find(c => c.type === 0)
            if (found) {
                return this.getRow(i18n.administrator, getLabel(this.props.contributors, found.id))
            }
        }
        return null
    }

    getProducer = (title, station) => {
        if (!station.contributors) {
            return null
        }
        const producer = orderBy(station.contributors, 'startDate').reverse().filter(e => {
            const contributorType = getSandreLabel(this.props.sandreCodes, SANDRE.CONTRIBUTOR_TYPES, e.contributorType)
            return contributorType && !e.endDate ? contributorType === STATION_PRODUCER : false
        })
        if (producer.length) {
            producer.sort((a, b) => b.startDate - a.startDate)
            const foundProducer = this.props.contributors.find(e => e.id === producer[0].idContributor)
            if (foundProducer) {
                const operations = this.props.operations.filter(o => o.producer === foundProducer.id).sort((a, b) => b.date - a.date)
                const lastOperation = operations.length !== 0 ? `( ${template(i18n.lastOperation)({ date: getDate(operations[0].date) })} )` : ''
                return this.getRow(title, `${foundProducer.name} [ ${foundProducer.code} ] ${lastOperation}`)
            }
        }
        return null
    }

    getProducers = (title) => {
        if (this.props.qualityProducers.length > 0) {
            const producers = this.props.qualityProducers.map(m => {
                return `${m.name} [${m.contributorCode}], `
            })
            return this.getRow(title, producers)
        }
        return null
    }

    getWaterNature = (station) => {
        const sandreNature = getSandreList(this.props.sandreCodes, SANDRE.NATURE_EAU).find(sandre => sandre.code === station.waterNature) || {}
        return this.getRow(i18n.waterNature, sandreNature.name)
    }

    getManagementInfo = (title, station, attr) => {
        const managementUnit = this.props.managementUnits.find(mu => station[attr] === mu.managementCode) || {}
        const contributor = attr !== 'managementUnit' ? this.props.contributors.find(c => c.id === managementUnit[attr]) || {} : managementUnit
        if (contributor.name) {
            return this.getRow(title, contributor.name)
        }
        return null
    }

    getRowIfExists = (propKey, stationType) => {
        const isInternationalContext = this.props.applicationSettings.some(a => a.parameter === 'ContexteInternational' && a.value.toLowerCase() === 'oui')
        const station = getStation(this.props, stationType)
        if (station && !station[propKey] && !['sector', 'producer', 'producers', 'operatorCode', 'supervisorCode', 'managementUnit', 'resourceNature'].includes(propKey)) {
            return null
        }
        const city = this.props.cities.find(c => station && c.code === station.townCode)
        const country = this.props.countries.find(c => station && c.countryCode === station.countryCode || city && c.countryCode === city.countryCode)
        const countryText = country ? `, ${country.name || ''}` : ''
        const i18nKey = i18n[propKey]
        switch (propKey) {
            case 'code':
                return station.designation ? this.getRow(i18n.login, `${station.code}/${station.designation}`) : null
            case 'creationDate':
                return this.getRow(i18nKey, getDate(station.creationDate))
            case 'close':
                return this.getRow(i18n.closeDate, getDate(station.close))
            case 'townCode':
                return this.getLinkCity(i18n.city, station)
            case 'contactCode':
                return this.getNameFromList(this.props.contacts, station.contactCode, i18n.contact)
            case 'stationType':
                return this.getRow(i18n.stationType, `${getStationType(parseInt(station.stationType)).libelle} [${station.stationType}]`)
            case 'qualitometerType':
                return this.getNameFromList(this.props.qualitometerType, station.qualitometerType, i18n.waterPoint)
            case 'waterSourceType':
                return this.getNameFromList(this.props.types, station.waterSourceType, i18n.type)
            case 'resourceNature':
                return station.nature ? this.getRow(i18n.nature, station.nature) : null
            case 'nature':
                return this.props.nature.code ? this.getRow(i18n.nature, `${this.props.nature.libelle} [${this.props.nature.code}]`) : null
            case 'piezometerNature':
                return this.getRow(i18n.piezometerNature, getSandreLabel(this.props.sandreCodes, SANDRE.PIEZOMETER_NATURE, station.piezometerNature))
            case 'qualitometerAquiferLink':
                return this.getAquifer()
            case 'stateCode':
                return this.getNameFromList(this.props.states, station.stateCode, i18n.state)
            case 'qualityCode':
                return this.getNameFromList(this.props.qualities, station.qualityCode, i18n.quality)
            case 'fieldMode':
                return this.getNameFromList(this.props.fieldModes, station.fieldMode, i18n.fieldMode)
            case 'qualitometerHydrogeologicalEntityLink':
                return this.getHydrogeologicalEntity()
            case 'representativenessCode':
                return this.getNameFromList(this.props.qualitometerRepresentiveness, station.representativenessCode, i18n.representiveness)
            case 'localisation':
                return this.getCoordinates()
            case 'contributors':
                return this.getContributors(station.link_contributors)
            case 'sector':
                return this.getRow(i18n.sector, getLabel(this.props.distributionUnitSectors, station.id, 'name', 'distributionUnitId'))
            case 'watershed':
                return this.getNameFromList(this.props.watersheds, station.watershed, i18n.watershed, 'id', `#/referencial/watershed/${station.watershed}`)
            case 'comment':
                return this.getRow(i18nKey, station.comment.split('\n').map(txt => <h6>{txt}</h6>))
            case 'producer':
                // We need to display label operator instead of producer
                return this.getProducer(i18n.operator, station)
            case 'producers':
                return this.getProducers(i18n.producers)
            case 'waterNature':
                return this.getWaterNature(station)
            case 'exploitationModeCode':
                return this.getRow(i18nKey, getSandreMnemonique(this.props.sandreCodes, SANDRE.MODE_EXPLOITATION, station.exploitationModeCode))
            case 'operatorCode':
                return this.getManagementInfo(i18nKey, station, 'operatorCode')
            case 'supervisorCode':
                return this.getManagementInfo(i18nKey, station, 'supervisorCode')
            case 'managementUnit':
                return this.getManagementInfo(i18nKey, station, 'managementUnit')
            case 'decreePopulation':
                return this.getRow(i18nKey, station.decreePopulation)
            case 'permanentPopulation':
                return this.getRow(i18nKey, station.permanentPopulation)
            case 'address':
                return isInternationalContext ? this.getRow(i18nKey, `${station[propKey]}${countryText}`) : this.getRow(i18nKey, station.permanentPopulation)
            case 'state':
                return this.getRow(i18n.status, getSandreLabel(this.props.sandreCodes, SANDRE.CODE_ETAT, station.state))
            default:
                return this.getRow(i18nKey, station[propKey])
        }
    }

    getRows = (stationProps) => {
        return stationProps.map(propKey => {
            return this.props.stationTypes.reduce((acc, type) => {
                return acc ? acc : this.getRowIfExists(propKey, type)
            }, null)
        })
    }

    getContributorsDisplay = () => {
        const station = getStation(this.props, this.props.stationTypes[0])
        const stationContributors = station.link_contributors
        if (stationContributors?.length) {
            const data = stationContributors.map(c => {
                const contributor = this.props.contributors.find(cs => cs.id === c.idContributor)
                const type = getSandreLabel(this.props.sandreCodes, SANDRE.CONTRIBUTOR_TYPES, c.contributorType)
                return contributor && !c.endDate && (
                    <Grid container item xs={12} style={{ fontStyle: '14.3px' }}>
                        <Grid item xs={1}>
                            {getYear(c.startDate)}
                        </Grid>
                        <Grid item xs={5}>
                            {contributor.mnemonique || contributor.name}
                        </Grid>
                        <Grid item xs={3}>
                            {type}
                        </Grid>
                        <Grid item xs={3}>
                            {c.internalReference || null}
                        </Grid>

                    </Grid>
                )
            })
            return (
                <div className='row no-margin'>
                    <h6 className='bold'>{`${i18n.contributors}:`}</h6>
                    {data}
                </div>
            )
        }
        return null
    }

    render() {
        const rows = execByType(this.props.stationTypes[0], {
            stations: () => {
                const dynamicRows = this.props.extended ?
                    this.getRows(['townCode', 'code', 'name', 'creationDate', 'close', 'comment', 'address', 'location', 'contactCode', 'stationType', 'qualitometerType',
                        'waterSourceType', 'nature', 'qualitometerAquiferLink', 'contributors', 'stateCode', 'finality', 'qualityCode', 'fieldMode', 'qualitometerHydrogeologicalEntityLink',
                        'representativenessCode', 'localisation', 'watershed', 'piezometerNature', 'producer', 'producers'])
                    : this.getRows(['townCode', 'creationDate', 'close', 'comment', 'address', 'location', 'contactCode', 'stationType', 'qualitometerType',
                        'waterSourceType', 'nature', 'qualitometerAquiferLink', 'contributors', 'qualityCode', 'fieldMode', 'qualitometerHydrogeologicalEntityLink',
                        'representativenessCode', 'watershed', 'piezometerNature', 'producer', 'producers'])
                return (
                    <div>
                        {dynamicRows}
                        {this.props.stationWatermass.name ? this.getRow(i18n.watermass, `${this.props.stationWatermass.name} [${this.props.stationWatermass.code}]`) : null}
                    </div>
                )
            },
            productionUnit: () => this.getRows(['code', 'name', 'producer', 'producers', 'operator', 'administrator']),
            distributionUnit: () => this.getRows(['code', 'name', 'state', 'operator', 'buildingOwner', 'sector', 'waterNature', 'exploitationModeCode', 'operatorCode', 'supervisorCode', 'managementUnit', 'decreePopulation', 'permanentPopulation']),
            installation: () => this.getRows(['code', 'name', 'operator', 'administrator', 'townCode', 'descriptive']),
            resource: () => this.getRows(['code', 'name', 'resourceNature', 'type', 'descriptif', 'exploitationComment']),
        })
        // const rows = null
        const owner = execByType(this.props.stationTypes[0], {
            stations: () => (
                <div className='col l2 no-padding'>
                    <div className='row no-margin'>
                        <div className='col l12 no-padding center'>
                            <i className='material-icons large'>account_circle</i>
                        </div>
                        <div className='col l12 no-padding center'>
                            <div className='row no-margin'>Stéphane</div>
                            <div className='row no-margin'>BARTHON</div>
                        </div>
                    </div>
                </div>
            ),
            units: () => null,
        })
        const station = getStation(this.props, this.props.stationTypes[0])
        const isResource = station.typeName === 'resource'
        return (
            <div className='row margin-bottom-0'>
                <Card round className='padding-1'>
                    <div className='row no-margin'>
                        <div className='col l1 no-padding'>
                            <div className='col l12 padding-bottom-1'>
                                {this.getBookmark()}
                            </div>
                            <div className='col l12'>
                                {this.getIcon()}
                            </div>
                        </div>
                        <div id='station_infos' className={`col l${isResource ? 11 : 6} no-padding`}>
                            {rows}
                        </div>
                        {!isResource && (
                            <div className='col l5 no-padding'>
                                {this.getContributorsDisplay()}
                            </div>
                        )}
                        {this.props.noOwner ? null : owner}
                    </div>
                </Card>
            </div>
        )
    }
}

StationDescription.propTypes = getPropTypes(propsToFetch, {
    id: PropTypes.number,
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    qualitometer: PropTypes.instanceOf(DtoStation),
    nature: PropTypes.instanceOf(DtoNature),
    stationWatermass: PropTypes.instanceOf(DtoWaterMass),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    qualitometerRepresentiveness: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerRepresentiveness)),
    hydrogeologicalEntities: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrogeologicalEntity)),
    qualitometerType: PropTypes.arrayOf(PropTypes.instanceOf(DtoType)),
    aquifers: PropTypes.arrayOf(PropTypes.instanceOf(DtoAquifer)),
    states: PropTypes.arrayOf(PropTypes.instanceOf(DtoState)),
    qualities: PropTypes.arrayOf(PropTypes.instanceOf(DtoQuality)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    types: PropTypes.arrayOf(PropTypes.instanceOf(DtoType)),
    fieldModes: PropTypes.arrayOf(PropTypes.instanceOf(DtoFieldMode)),
    extended: PropTypes.boolean,
    noOwner: PropTypes.boolean,
    accountUser: PropTypes.instanceOf(User),
    qualityProducers: PropTypes.arrayOf(DtoQualityProducer),
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    countries: PropTypes.arrayOf(PropTypes.instanceOf(DtoCountry)),
    fetchCountries: PropTypes.func,
    resource: PropTypes.instanceOf(ResourceDto),
    sandreCodes: PropTypes.arrayOf(DtoSandreCode),
})

StationDescription.defaultProps = {
    extended: false,
    noOwner: false,
}

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    productionUnit: store.ProductionUnitReducer.productionUnit,
    distributionUnit: store.DistributionUnitReducer.distributionUnit,
    qualitometer: store.QualityReducer.qualitometer,
    piezometer: store.StationReducer.piezometer,
    pluviometer: store.PluviometryReducer.pluviometer,
    qualitometerRepresentiveness: store.StationReducer.qualitometerRepresentiveness,
    hydrogeologicalEntities: store.StationReducer.hydrogeologicalEntities,
    nature: store.StationReducer.nature,
    stationWatermass: store.StationReducer.stationWatermass,
    qualitometerType: store.StationReducer.qualitometerType,
    aquifers: store.StationReducer.aquifers,
    cities: store.CityReducer.cities,
    states: store.StationReducer.states,
    qualities: store.StationReducer.qualities,
    types: store.StationReducer.types,
    contacts: store.ContactReducer.contacts,
    fieldModes: store.StationReducer.fieldModes,
    accountUser: store.AccountReducer.accountUser,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    contributors: store.ContributorReducer.contributors,
    userBookmarks: store.UserReducer.userBookmarks,
    qualityProducers: store.QualityReducer.qualityProducers,
    applicationSettings: store.AdministrationReducer.applicationSettings,
    countries: store.CountryReducer.countries,
    resource: store.ResourceReducer.resource,
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

const mapDispatchToProps = {
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    fetch: SieauAction.fetch,
    add: SieauAction.add,
    fetchQualitometerType: StationAction.fetchQualitometerType,
    fetchTypes: StationAction.fetchTypes,
    fetchQualities: StationAction.fetchQualities,
    fetchFieldModes: StationAction.fetchFieldModes,
    fetchStates: StationAction.fetchStates,
    fetchHydrogeologicalEntities: StationAction.fetchHydrogeologicalEntities,
    fetchQualitometerRepresentiveness: StationAction.fetchQualitometerRepresentiveness,
    fetchCities: CityAction.fetchCities,
    fetchNature: StationAction.fetchNature,
    fetchBookmarks: UserAction.fetchBookmarks,
    deleteBookmark: UserAction.deleteBookmark,
    createBookmark: UserAction.createBookmark,
    fetchQualityProducers: QualityAction.fetchQualityProducers,
    fetchCountries: CountryAction.fetchCountries,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationDescription)
