import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import SieauAction from '../../../components/sieau/SieauAction'
import Input from '../../../components/forms/Input'
import Checkbox from '../../../components/forms/Checkbox'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import { getSetting, getUser } from '../../../utils/SettingUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import PumpingEquipmentsPanel from './components/PumpingEquipmentsPanel'
import NumberField from '../../../components/forms/NumberField'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoPumpingStation from './dto/DtoPumpingStation'
import QualityAction from '../../../quality/actions/QualityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import InstallationEquipmentsPanel from '../installations/components/InstallationEquipmentsPanel'
import { downloadURI } from '../../../utils/ExportDataUtil'
import { getVisitCheckBoxChange, getVisitSandreSelectChange } from '../../../utils/VisitUtils'
import Select from '../../../components/forms/Select'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { arrayOf, getSandreList } from 'utils/StoreUtils'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import ElecmecEquipmentPanel from '../installations/components/ElecmecEquipmentPanel'
import GenericInstallationDescription from '../installationDefault/GenericInstallationDescription'
import { CardContent, Grid2 } from '@mui/material'
import { isDisplayed } from 'utils/InstallationUtils'
import { CardTitle } from 'components/card/NewCard'

class PumpingStationDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            usedWater: {},
            readMode: true,
            pumping: new DtoPumpingStation({}),
        }
    }

    componentDidMount = () => {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            this.props.fetchInstallationPumping(this.props.installation.id).then(() => {
                this.setState({
                    pumping: {
                        ...this.props.installationPumping,
                        idStation: this.props.installation.id,
                    },
                })
            })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () =>
            this.props.push('/installation'),
        )
    }

    onSave = (visit) => {
        const { installation, pumping } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    pumping,
                },
            ).then(() => this.setReadOnlyMode())
        }
        return this.props.updateInstallationPumping(
            installation,
            pumping,
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, pumping: this.props.installationPumping })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav(
                'installation',
                this.props.installationTable,
                this.props.installation.id,
                (s) => this.props.push(`/station/installation/${s.id}/description`),
            ),
        }

        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav(
                'installation',
                this.props.installationTable,
                this.props.installation.id,
                (s) => this.props.push(`/station/installation/${s.id}/description`),
            ),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) =>
        this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangePumping = (newObject) =>
        this.setState({ pumping: { ...this.state.pumping, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return (
            getSetting(
                this.props.applicationSettings,
                `PANEL_${this.state.installation.installationType}_${panel}`,
            ) !== 'hidden'
        )
    }

    render() {
        const { installation, readMode, pumping } = this.state
        const { sandreCodes, applicationSettings } = this.props
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
            sandreCode: SANDRE.EQUIPEMENTS_ST_POMPAGES_AEP,
        }

        const paramsPumping = {
            station: pumping,
            pumping,
            onChange: this.onChangePumping,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <GenericInstallationDescription
                readMode={readMode}
                installation={installation}
                onChangeInstallation={this.onChangeInstallation}
                onChangeVisit={this.onChangeVisit}
                descElements={
                    <>
                        <Grid2 size={6}>
                            <NumberField
                                value={pumping.nominalCapacity}
                                title={i18n.nominalCapacity}
                                onChange={(v) => {
                                    this.onChangePumping({ nominalCapacity: v })
                                    this.onChangeVisit({ previousValue: pumping.nominalCapacity, newValue: v, field: i18n.nominalCapacity })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={pumping.nominalHourlyCapacity}
                                title={i18n.nominalHourlyCapacity}
                                onChange={(v) => {
                                    this.onChangePumping({ nominalHourlyCapacity: v })
                                    this.onChangeVisit({ previousValue: pumping.nominalHourlyCapacity, newValue: v, field: i18n.nominalHourlyCapacity })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox
                                checked={pumping.autorisationDecree}
                                label={i18n.autorisationDecree}
                                onChange={(v) => {
                                    this.onChangePumping({ autorisationDecree: v })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.autorisationDecree, pumping.autorisationDecree, v))
                                }}
                                disabled={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={pumping.numDecree}
                                title={i18n.numDecree}
                                onChange={(v) => {
                                    this.onChangePumping({ numDecree: v })
                                    this.onChangeVisit({ previousValue: pumping.numDecree, newValue: v, field: i18n.numDecree })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={pumping.TNLevel}
                                title={i18n.TNLevel}
                                onChange={(v) => {
                                    this.onChangePumping({ TNLevel: v })
                                    this.onChangeVisit({ previousValue: pumping.TNLevel, newValue: v, field: i18n.TNLevel })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={pumping.casingType}
                                label={i18n.casingType}
                                options={getSandreList(sandreCodes, SANDRE.STATION_POMPAGE_TYPE)}
                                onChange={(v) => {
                                    this.onChangePumping({ casingType: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.STATION_POMPAGE_TYPE, i18n.casingType, pumping.casingType, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={pumping.nominalFlow}
                                title={i18n.nominalFlow}
                                onChange={(v) => {
                                    this.onChangePumping({ nominalFlow: v })
                                    this.onChangeVisit({ previousValue: pumping.nominalFlow, newValue: v, field: i18n.nominalFlow })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={pumping.potentialVolume}
                                title={i18n.potentialVolume}
                                onChange={(v) => {
                                    this.onChangePumping({ potentialVolume: v })
                                    this.onChangeVisit({ previousValue: pumping.potentialVolume, newValue: v, field: i18n.potentialVolume })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={pumping.sampledVolume}
                                title={i18n.sampledVolume}
                                onChange={(v) => {
                                    this.onChangePumping({ sampledVolume: v })
                                    this.onChangeVisit({ previousValue: pumping.sampledVolume, newValue: v, field: i18n.sampledVolume })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={12} sx={{ paddingBottom: '5px' }}>
                            <BuildingsPanel {...params} />
                        </Grid2>
                        {isDisplayed(applicationSettings, 'ELECMEC', installation.installationType) && (
                            <Grid2 size={12} sx={{ paddingBottom: '5px' }}>
                                <ElecmecEquipmentPanel {...paramsPumping} />
                            </Grid2>
                        )}
                        {isDisplayed(applicationSettings, 'PUMPING_EQUIPMENTS', installation.installationType) && (
                            <Grid2 size={12}>
                                <Card>
                                    <CardTitle title={i18n.equipments} />
                                    <CardContent>
                                        <Grid2 container columnSpacing={1}>
                                            <PumpingEquipmentsPanel {...paramsPumping} />
                                            <Grid2 size={12}>
                                                <InstallationEquipmentsPanel className='padding-left-1 padding-right-1' {...params} />
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        )}
                    </>
                }
            />
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM &&
            nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)
        ) {
            return false
        }
        return true
    }
}

PumpingStationDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    installationPumping: store.InstallationReducer.installationPumping,
    installationVisits: store.InstallationReducer.installationVisits,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    push,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationPumping: InstallationAction.updateInstallationPumping,
    fetchInstallationPumping: InstallationAction.fetchInstallationPumping,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
}

export default connect(mapStateToProps, mapDispatchToProps)(PumpingStationDescriptionPanel)
