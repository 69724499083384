import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { isNil, isUndefined, max, maxBy, min, minBy, round, uniqBy } from 'lodash'
import { getDate, getFullDate } from 'utils/DateUtil'
import moment from 'moment'
import {
    exportExcelIcon, exportPictureIcon, getAxisIntervalFormatter, logIcon, toEchartsData, yAutomaticScaleValues, setLogOptions, setYOptions,
} from 'components/echart/EChartUtils'
import { exportFile } from 'utils/ExportDataUtil'
import { shallowEqual, useSelector } from 'react-redux'
import { getColorFromPalette2, getThresholdColor } from 'utils/ColorUtil'
import { MEASURE_COTE } from 'piezometry/constants/PiezometryConstants'
import { getCote } from 'components/echart/ChartOptionsChip'

const DEFAULT_GRAPH_HEIGHT = 150
const DEFAULT_GAP = 50
const DEFAULT_HEADER_HEIGHT = 40
const DEFAULT_FOOTER_HEIGHT = 75

const PiezoChart = ({
    dataMeasures = [],
    thresholds = [],
    startDate,
    endDate,

    headerHeight = DEFAULT_HEADER_HEIGHT,
    footerHeight = DEFAULT_FOOTER_HEIGHT,
}) => {
    const {
        piezometers,
        piezometryDataTypes,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    }), shallowEqual)

    // use on mouse event
    let echartRef = useRef(undefined)
    let displayToolbox = useRef(false)

    const [displayLog, setDisplayLog] = useState(false)

    const minDate = startDate ?? min(dataMeasures.map(d => minBy(d.measures, 'date')?.date).filter(d => !isUndefined(d)))
    const maxDate = endDate ?? max(dataMeasures.map(d => maxBy(d.measures, 'date')?.date).filter(d => !isUndefined(d)))

    const {
        formatter,
        interval,
    } = getAxisIntervalFormatter(moment(maxDate), moment(minDate))

    const listDataTypes = uniqBy(dataMeasures, 'dataType').map(m => m.dataType)

    const series = listDataTypes.flatMap((dt, i) => {
        const dataType = piezometryDataTypes.find(p => p.id === dt)
        const measures = dataMeasures.filter(m => m.dataType === dt)
        return measures.map((m, mi) => {
            const piezo = piezometers.find(p => p.id === m.stationId)
            const filteredThresholds = thresholds.filter(t => !isUndefined(t.value) && t.code == piezo?.code && t.dataType == dt).map(t => ({
                value: t.value,
                title: t.title,
                color: t.htmlColor || getThresholdColor(t.color),
            }))
            return {
                name: `${piezo?.name ?? ''} (${dataType?.label ?? ''})`,
                type: dt === -2 ? 'bar' : 'line',
                data: toEchartsData(displayLog ? m.measures.filter(d => d.value > 0) : m.measures),
                markLine: {
                    silent: false,
                    data: filteredThresholds.map(t => ({
                        yAxis: t.value,
                        symbol: 'none',
                        label: {
                            show: true,
                            position: 'middle',
                            formatter: () => t.title ? `${t.title} : ${round(t.value, 5)}` : '',
                        },
                        lineStyle: {
                            normal: {
                                color: t.color,
                                type: 'dashed',
                            },
                        },
                    })),
                },
                connectNulls: false,
                yAxisIndex: i,
                xAxisIndex: i,
                color: getColorFromPalette2(mi),
                barMaxWidth: '5px',
                lineStyle: {
                    type: dataType?.lineType ?? 'solid',
                    opacity: dataType?.lineOpacity ?? 1,
                    width: dataType?.lineWidth ?? 2,
                },
            }
        })
    })

    let tmpGap = headerHeight
    const grid = listDataTypes.map((_, i) => {
        const tmpTop = tmpGap + (i === 0 ? 0 : DEFAULT_GAP)
        const tmpGrid = {
            top: tmpTop,
            height: DEFAULT_GRAPH_HEIGHT,
            left: '70px',
            right: '40px',
        }
        tmpGap = tmpTop + DEFAULT_GRAPH_HEIGHT
        return tmpGrid
    })

    const xAxis = listDataTypes.map((_, i) => {
        return {
            type: 'time',
            boundaryGap: true,
            axisLabel: {
                rotate: 0,
                formatter,
                show: true,
            },
            axisLine: { show: true },
            axisTick: { show: true },
            splitLine: { show: true },
            interval,
            gridIndex: i,
            min: minDate,
            max: maxDate,
        }
    })

    const yAxis = listDataTypes.map((dt, i) => {
        const dataType = piezometryDataTypes.find(p => p.id === dt)
        const filteredData = series.filter(d => d.yAxisIndex === i)
        const allMeasures = filteredData.flatMap(d => d.data?.map(m => m.value[1]) ?? [])
        const allThresholds = filteredData.flatMap(d => d.markLine.data?.map(t => t.yAxis) ?? [])
        const yScale = yAutomaticScaleValues([...allMeasures, ...allThresholds])
        return {
            type: displayLog ? 'log' : 'value',
            nameLocation: 'middle',
            name: dataType ? `${dataType.id === -1 ? i18n.chronic : dataType.label ?? ''} ${dataType.unit ? `[${dataType.unit}]` : ''}` : i18n.unknown,
            gridIndex: i,
            nameGap: 40,
            showSplitLine: true,
            inverse: dt === -1 && dataMeasures.find(dm => dm.dataType === dt)?.displayCote === MEASURE_COTE.DEPTH,
            ...setYOptions(null, yScale),
            ...setLogOptions(displayLog),
        }
    })

    const getLegend = () => ({
        top: 10,
        left: 70,
        right: displayToolbox.current ? `${30 * 4 + 35}px` : 40,
        type: 'scroll',
        show: true,
    })

    const getToolbox = () => ({
        top: 5,
        right: 40,
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: displayToolbox.current ? {
            myLog: {
                title: i18n.logarithm,
                icon: logIcon,
                onclick: () => setDisplayLog(p => !p),
            },
            saveAsImage: {
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: i18n.dataPiezo,
            },
            myToolExport: {
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: () => {
                    const exportData = dataMeasures.flatMap(d => {
                        const piezo = piezometers.find(({ id }) => id === d.stationId)
                        const dataType = piezometryDataTypes.find(h => h.id === d.dataType)
                        return d.measures.map(m => ({
                            code: piezo?.code,
                            date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                            value: { value: m.value, format: '0.00000', cellType: 'number' },
                            name: piezo?.name || d.stationId,
                            dataType: dataType?.label ?? i18n.unknown,
                            cote: isNil(d.displayCote) ? '' : getCote(d.displayCote).label,
                        }))
                    })
                    exportFile({
                        data: exportData.length ? [
                            {
                                ...exportData[0],
                                headers: ['code', 'name', 'dataType', 'date', 'value', 'cote'],
                            },
                            ...exportData.slice(1),
                        ] : [],
                        exportType: 'xlsx',
                        titleFile: i18n.dataPiezo,
                    }, true)
                },
            },
            restore: { title: i18n.restore },
        } : {},
    })

    const options = {
        series,
        legend: getLegend(),
        xAxis,
        yAxis,
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const date = getFullDate(params[0].value[0])
                const result = params.map(o => `${o.marker} ${o.seriesName} : ${round(o.value[1], 2)} ${o.unit ?? ''}`).join('<br />')
                return `${date} <br /> ${result}`
            },
        },
        grid,
        dataZoom: [{
            type: 'slider',
            xAxisIndex: listDataTypes.map((_, index) => index),
            filterMode: 'none',
        }, {
            bottom: '10px',
            type: 'inside',
            filterMode: 'filter',
            xAxisIndex: listDataTypes.map((_, index) => index),
            handleStyle: {
                color: '#fff',
                shadowBlur: 3,
                shadowColor: 'rgba(0, 0, 0, 0.6)',
                shadowOffsetX: 2,
                shadowOffsetY: 2,
            },
        }],
        toolbox: getToolbox(),
        backgroundColor: '#fff',
    }

    const componentHeight = headerHeight + listDataTypes.length * DEFAULT_GRAPH_HEIGHT + (listDataTypes.length - 1) * DEFAULT_GAP + footerHeight

    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current?.getEchartsInstance().setOption({
                    ...options,
                    legend: getLegend(),
                    toolbox: getToolbox(),
                }, true, true)
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current?.getEchartsInstance().setOption({
                    ...options,
                    legend: getLegend(),
                    toolbox: getToolbox(),
                }, true, true)
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge
                lazyUpdate
                style={{ height: componentHeight }}
                ref={e => {
                    echartRef.current = e
                }}
            />
        </div>
    )
}

PiezoChart.propTypes = {
    dataMeasures: PropTypes.arrayOf(PropTypes.shape({})),
    thresholds: PropTypes.arrayOf(PropTypes.shape({})),
    startDate: PropTypes.number,
    endDate: PropTypes.number,

    headerHeight: PropTypes.number,
    footerHeight: PropTypes.number,
}

export default PiezoChart