import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getIndiceValue, getThresholdColors, getThresholdLabel } from '../../utils/AnalyseUtils'
import DtoThreshold from 'quality/dto/DtoThreshold'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import { isNil } from 'lodash'
import { Grid2, Icon, Tooltip } from '@mui/material'

const SliderPanel = ({
    title = '',
    subtitle = '',
    analysis = {},
    threshold,
    overrideColors,
    withThresholdLevels = false,
    onOpen = () => {},
}) => {
    const {
        thresholds,
    } = useSelector(store => ({
        thresholds: store.QualityReducer.thresholds,
    }), shallowEqual)

    const [nbThresholds, indice] = getIndiceValue(analysis.value, threshold)

    const list = Array(nbThresholds).fill(0)

    const thresholdLabel = thresholds.find(t => t.id === threshold?.thresholdType)?.name ?? ''

    const colors = overrideColors ?? getThresholdColors(threshold)

    return (
        <Grid2 container>
            <Grid2 container size={12} justifyContent='space-between' sx={{ backgroundColor: isNil(threshold) ? '#9e9e9e' : '#000', color: '#FFF', padding: '1px 8px', borderRadius: '5px' }}>
                <span>
                    {title}
                </span>
                <span>
                    {subtitle}
                </span>
            </Grid2>
            {!isNil(threshold) && list.map((_, i) => (
                <Grid2 key={i} container size={12/list.length} justifyContent='center' sx={{ marginTop: '-6px' }}>
                    {(indice - 1) === i && (<Icon sx={{ color: '#000' }} >network_wifi</Icon>)}
                </Grid2>
            ))}
            {!isNil(threshold) && list.map((_, i) => {
                const label = `${thresholdLabel} ${getThresholdLabel(i, threshold)}`
                return (
                    <Grid2 key={i} container size={12/list.length} justifyContent='center' sx={{ backgroundColor: colors[i], color: '#000', padding: '1px 2px', cursor: isNil(onOpen) ? 'normal' : 'pointer' }} onClick={onOpen}>
                        {withThresholdLevels && (
                            <span>
                                {label}
                            </span>
                        )}
                        {!withThresholdLevels && (
                            <Tooltip title={label}>
                                <div style={{ width: '100%', height: '1.3rem' }}/>
                            </Tooltip>
                        )}
                    </Grid2>
                )
            })}
        </Grid2>
    )
}

SliderPanel.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    analysis: PropTypes.shape({
        // DtoAnalysisLight
        // calculateThresholdResult
    }),
    threshold: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoQualityThreshold),
        PropTypes.shape({
            parameterCode: PropTypes.string,
            threshold1: PropTypes.number,
            threshold2: PropTypes.number,
        }),
    ]),
    withThresholdLevels: PropTypes.bool,
    onOpen: PropTypes.func,
    overrideColors: PropTypes.arrayOf(PropTypes.string),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoThreshold)),
}

export default SliderPanel
