import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import NumberField from '../../../components/forms/NumberField'
import { getSandreList } from '../../../utils/StoreUtils'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { getVisitSandreSelectChange, getVisitSelectChange } from '../../../utils/VisitUtils'
import { Grid2 } from '@mui/material'

const styleLabelSelect = {
    fontSize: '13px',
}

const StationLocationInfoPanel = ({
    station,
    onChange,
    onChangeVisit,
    readMode,
    disabled,
}) => {
    const {
        sandreCodes,
        cities,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        cities: store.CityReducer.cities,
    }))

    const onChangeGeoData = (value) => {
        const geoData = station.link_geo && station.link_geo[0] || {}

        onChange({
            link_geo: [{
                ...geoData,
                idStation: station.id,
                ...value,
            }],
        })
    }

    const geoData = station.link_geo && station.link_geo[0] || {}

    return (
        <Grid2 container columnSpacing={1}>
            <Grid2 size={12}>
                <Input
                    title={i18n.address}
                    value={station.address}
                    keyObj='address'
                    changeObj={onChange}
                    onChange={v => {
                        onChange({ address: v })
                        onChangeVisit({ previousValue: station.address, newValue: v, field: i18n.address })
                    }}
                    readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
            <Grid2 size={12}>
                <Select
                    value={station.townCode}
                    label={i18n.city}
                    options={cities}
                    keyValue='id'
                    displayWithCode
                    onChange={v => {
                        onChange({ townCode: v })
                        onChangeVisit(getVisitSelectChange(cities, 'code', i18n.city, station.townCode, v))
                    }} readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
            {(station.declarationTownCode !== station.townCode || !readMode) && (
                <Grid2 size={12}>
                    <Select
                        value={station.declarationTownCode}
                        label={i18n.declarationTownCode}
                        options={cities}
                        keyValue='id'
                        displayWithCode
                        onChange={v => {
                            onChange({ declarationTownCode: v })
                            onChangeVisit(getVisitSelectChange(this.props.cities, 'code', i18n.declarationTownCode, station.declarationTownCode, v))
                        }} readMode={readMode}
                        disabled={disabled}
                    />
                </Grid2>
            )}
            <Grid2 size={12}>
                <Input
                    value={station.countryCode}
                    title={i18n.countryCode}
                    keyObj='countryCode'
                    changeObj={onChange}
                    onChange={v => {
                        onChange({ countryCode: v })
                        onChangeVisit({ previousValue: station.countryCode, newValue: v, field: i18n.countryCode })
                    }}
                    readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
            <Grid2 size={12}>
                <Input
                    noMargin={false}
                    classname={!readMode && 'margin-bottom-1' || ''}
                    title={i18n.localisation}
                    value={station.location}
                    keyObj='location'
                    changeObj={onChange}
                    onChange={v => {
                        onChange({ location: v })
                        onChangeVisit({ previousValue: station.location, newValue: v, field: i18n.localisation })
                    }}
                    readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    title='X'
                    value={station.x}
                    floatValue
                    onChange={v => {
                        onChange({ x: v })
                        onChangeVisit({ previousValue: station.x, newValue: v, field: 'X' })
                    }}
                    readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    title='Y'
                    value={station.y}
                    floatValue
                    onChange={v => {
                        onChange({ y: v })
                        onChangeVisit({ previousValue: station.y, newValue: v, field: 'Y' })
                    }}
                    readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
            <Grid2 size={12}>
                <Select
                    styleLabel={{ ...styleLabelSelect }}
                    label={i18n.projection}
                    value={station.projection}
                    options={getSandreList(sandreCodes, SANDRE.PROJECTION)}
                    onChange={v => {
                        onChange({ projection: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.PROJECTION, i18n.projection, station.projection, v))
                    }} disabled={disabled} readMode={readMode} integerValue keyvalue='code'
                />
            </Grid2>
            <Grid2 size={12}>
                <NumberField
                    value={station.altitude}
                    title={i18n.altitude}
                    floatValue
                    onChange={v => {
                        onChange({ altitude: v })
                        onChangeVisit({ previousValue: station.altitude, newValue: v, field: i18n.altitude })
                    }}
                    readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    title={i18n.ignMap}
                    value={geoData.ignMap}
                    keyObj='ignMap'
                    changeObj={onChangeGeoData}
                    onChange={v => {
                        onChangeGeoData({ ignMap: v })
                        onChangeVisit({ previousValue: geoData.ignMap, newValue: v, field: i18n.ignMap })
                    }}
                    readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    title={i18n.geologicalMap}
                    value={geoData.geologicalMap}
                    keyObj='geologicalMap'
                    changeObj={onChangeGeoData}
                    onChange={v => {
                        onChangeGeoData({ geologicalMap: v })
                        onChangeVisit({ previousValue: geoData.geologicalMap, newValue: v, field: i18n.geologicalMap })
                    }}
                    readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    title={i18n.section}
                    value={geoData.parcel}
                    keyObj='parcel'
                    changeObj={onChangeGeoData}
                    onChange={v => {
                        onChangeGeoData({ parcel: v })
                        onChangeVisit({ previousValue: geoData.parcel, newValue: v, field: i18n.parcel })
                    }}
                    readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    title={i18n.parcel}
                    value={geoData.section}
                    keyObj='section'
                    changeObj={onChangeGeoData}
                    onChange={v => {
                        onChangeGeoData({ section: v })
                        onChangeVisit({ previousValue: geoData.section, newValue: v, field: i18n.section })
                    }}
                    readMode={readMode}
                    disabled={disabled}
                />
            </Grid2>
        </Grid2>
    )
}

StationLocationInfoPanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default StationLocationInfoPanel