import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import { arrayOf, getMapStateToProps, getPropTypes, getSandreList } from '../../../utils/StoreUtils'
import SieauAction from '../../../components/sieau/SieauAction'
import { isEqual, keys, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getSetting, getUser } from '../../../utils/SettingUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import { downloadURI } from '../../../utils/ExportDataUtil'
import Select from '../../../components/forms/Select'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import AquiferAction from '../../../referencial/components/aquifers/actions/AquiferAction'
import HydrogeologicalEntityAction from '../../../referencial/components/hydrogeologicalEntity/actions/HydrogeologicalEntityAction'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import DtoHydrogeologicalEntity from '../../../station/dto/DtoHydrogeologicalEntity'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import InstallationContextPanel from '../installations/components/InstallationContextPanel'
import DtoFlowObstruction from '../flowObstruction/dto/DtoFlowObstruction'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import NumberField from 'components/forms/NumberField'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import GenericInstallationDescription from './GenericInstallationDescription'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { Grid2 } from '@mui/material'

const storeProps = {
    installation: false,
    cities: false,
    contacts: false,
    watersheds: true,
    sandreCodes: false,
    natures: true,
    aquifers: true,
    fieldModes: true,
    installationEvents: false,
    users: false,
    status: true,
}

class FlowObstructionDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: { ...props.installation },
            flowObstruction: { ...props.flowObstruction },
            readMode: true,
        }
    }

    componentDidMount() {
        const { installation, watersheds, aquifers, hydrogeologicalEntities, flowObstruction, hydrometricStations } = this.props
        this.props.fetch(storeProps)
        if (installation.id) {
            this.setState({ installation })
        }
        if (!flowObstruction.id && installation.id) {
            this.props.fetchFlowObstruction(installation.id).then(() => {
                if (this.props.flowObstruction.id) {
                    this.setState({ flowObstruction: this.props.flowObstruction })
                } else {
                    this.setState({ flowObstruction: { id: installation.id } })
                }
            })
        }
        if (!watersheds.length) {
            this.props.fetchWatersheds()
        }
        if (!aquifers.length) {
            this.props.fetchAquifers()
        }
        if (!hydrogeologicalEntities.length) {
            this.props.fetchHydrogeologicalEntities()
        }

        if (!hydrometricStations.length) {
            this.props.fetchHydrometricStations()
        }

        if (installation.id) {
            this.props.fetchInstallationVisits(installation.id).then(() => {
                if (this.props.onChangeVisit) {
                    this.setState({ readMode: false })
                } else if (this.state.readMode) {
                    this.setReadOnlyMode()
                }
            })
        }

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.flowObstruction, this.props.flowObstruction)) {
            this.setState({ flowObstruction: this.props.flowObstruction })
        }
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => this.props.push('/installation')

    onSave = (visit) => {
        const { installation, flowObstruction } = this.state

        if (keys(flowObstruction).length > 1) {
            this.props.updateFlowObstruction(flowObstruction)
        }

        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                },
            ).then(() => this.setReadOnlyMode())
        }

        return this.props.updateInstallation(
            installation,
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation })
                this.setReadOnlyMode()
            },
            delete: () => this.props.delete('installation', this.props.installation.id, this.onDelete),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        this.setState({ readMode: true })
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.props.delete('installation', this.props.installation.id, this.onDelete),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }
    onChangeInstallation = (newObject) => {
        this.setState({ installation: { ...this.state.installation, ...newObject } })
    }

    onChangeFlowObstruction = (newObject) => {
        const { flowObstruction } = this.state
        this.setState({ flowObstruction: { ...flowObstruction, ...newObject } })
    }

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return getSetting(this.props.applicationSettings, `PANEL_${this.state.installation.installationType}_${panel}`) !== 'hidden'
    }

    render() {
        const { installation, readMode, flowObstruction } = this.state
        const { sandreCodes } = this.props
        const params = {
            station: installation,
            flowObstruction,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <GenericInstallationDescription
                readMode={readMode}
                installation={installation}
                onChangeInstallation={this.onChangeInstallation}
                onChangeVisit={this.onChangeVisit}
                descElements={
                    <>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.bief}
                                value={flowObstruction.biefCode}
                                options={getSandreList(sandreCodes, SANDRE.OBSTACLE_BIEF)}
                                keyValue='code'
                                onChange={v => this.onChangeFlowObstruction({ biefCode: v })}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.type}
                                value={flowObstruction.typeCode}
                                options={getSandreList(sandreCodes, SANDRE.OBSTACLE_TYPE)}
                                keyValue='code'
                                onChange={v => this.onChangeFlowObstruction({ typeCode: v })}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.subType}
                                value={flowObstruction.obstructionSubType}
                                options={getSandreList(sandreCodes, SANDRE.OBSTACLE_TYPE)}
                                keyValue='code'
                                onChange={v => this.onChangeFlowObstruction({ obstructionSubType: v })}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.navigationCrossingDevice}
                                value={flowObstruction.navigationCrossingCode}
                                options={getSandreList(sandreCodes, SANDRE.OBSTACLE_NAVIGUATION)}
                                keyValue='code'
                                onChange={v => this.onChangeFlowObstruction({ navigationCrossingCode: v })}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.fishCrossingDevice}
                                value={flowObstruction.fishCrossingCode}
                                options={getSandreList(sandreCodes, SANDRE.OBSTACLE_PISCICOLE)}
                                keyValue='code'
                                onChange={v => this.onChangeFlowObstruction({ fishCrossingCode: v })}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                label={i18n.mobileElements}
                                value={flowObstruction.codeMobileElements}
                                options={getSandreList(sandreCodes, SANDRE.OBSTACLE_MOBILE)}
                                keyValue='code'
                                onChange={v => this.onChangeFlowObstruction({ codeMobileElements: v })}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={flowObstruction.heightCode}
                                title={i18n.fallHeight}
                                floatValue
                                onChange={v => this.onChangeFlowObstruction({ heightCode: v })}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <SimpleTextArea
                                title={i18n.crossingNotes}
                                value={flowObstruction.crossingNotes}
                                onChange={v => this.onChangeFlowObstruction({ crossingNotes: v })}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <BuildingsPanel {...params} />
                        </Grid2>
                    </>
                }
                otherElements={
                    <Grid2 size={12}>
                        <InstallationContextPanel
                            defaultStation={flowObstruction}
                            onChange={this.onChangeFlowObstruction}
                            readMode={readMode}
                        />
                    </Grid2>
                }
            />
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

FlowObstructionDescriptionPanel.propTypes = getPropTypes(storeProps, {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    contacts: arrayOf(ContactDto),
    installation: PropTypes.instanceOf(DtoInstallation),
    hydrogeologicalEntities: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrogeologicalEntity)),
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
    flowObstruction: PropTypes.instanceOf(DtoFlowObstruction),
    updateFlowObstruction: PropTypes.func,
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    fetchHydrometricStations: PropTypes.func,
})

const mapStateToProps = (store) => getMapStateToProps(storeProps, {
    applicationSettings: store.AdministrationReducer.applicationSettings,
    watersheds: store.WatershedReducer.watersheds,
    aquifers: store.AquiferReducer.aquifers,
    contacts: store.ContactReducer.contacts,
    installation: store.InstallationReducer.installation,
    installationVisits: store.InstallationReducer.installationVisits,
    hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
    flowObstruction: store.InstallationReducer.flowObstruction,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
})

const mapDispatchToProps = {
    push,
    updateInstallation: InstallationAction.updateInstallation,
    info: ToastrAction.info,
    fetch: SieauAction.fetch,
    delete: SieauAction.delete,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchHydrogeologicalEntities: HydrogeologicalEntityAction.fetchHydrogeologicalEntities,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
    fetchFlowObstruction: InstallationAction.fetchFlowObstruction,
    updateFlowObstruction: InstallationAction.updateFlowObstruction,
    fetchHydrometricStations: HydrometryAction.fetchHydrometricStations,
}

export default connect(mapStateToProps, mapDispatchToProps)(FlowObstructionDescriptionPanel)
