/* eslint-disable valid-jsdoc */
import { every, some, without } from 'lodash'
import PropTypes from 'prop-types'
import { UserStoreInfos } from '../administration/components/user/reducers/UserReducer'
import { AdministrationStoreInfos } from '../administration/reducers/AdministrationReducer'
import { SieauStoreInfos } from '../components/sieau/SieauReducer'
import { DistributionUnitStoreInfos } from '../distributionUnit/reducers/DistributionUnitReducer'
import { EventsStoreInfos } from '../events/reducers/EventsReducer'
import { HomeStoreInfos } from '../home/reducers/HomeReducer'
import { HydrometryStoreInfos } from '../hydrometry/reducers/HydrometryReducer'
import { InstallationStoreInfos } from '../installation/reducers/InstallationReducer'
import LogAction from '../log/actions/LogAction'
import { PiezometryStoreInfos } from '../piezometry/reducers/PiezometryReducer'
import { ProductionUnitStoreInfos } from '../productionUnit/reducers/ProductionUnitReducer'
import { SituationStoreInfos } from '../quality/components/integrationOverview/reducers/QualityIntegrationOverviewReducer'
import { OperationStoreInfos } from '../quality/components/operation/reducers/OperationReducer'
import { QualityStoreInfos } from '../quality/reducers/QualityReducer'
import { ReferencialStoreInfos } from '../referencial/reducers/ReferencialReducer'
import { SuiviPcStoreInfos } from '../station/components/suivipc/qualitometer/reducers/SuivipcReducer'
import { PiezometerStationStoreInfos } from '../station/reducers/PiezometerStationReducer'
import { StationStoreInfos } from '../station/reducers/StationReducer'
import AppStore from '../store/AppStore'
import { hasValue } from './NumberUtil'
import { substringText } from './StringUtil'

const $ = window.$


/**
 * @deprecated pas utile
 */
const arrayOf = (dto) => {
    return PropTypes.arrayOf(PropTypes.instanceOf(dto))
}

/**
 * @deprecated pas utile
 */
const instanceOf = (dto) => {
    return PropTypes.instanceOf(dto)
}

/**
 * @deprecated pas utile
 */
const objectOf = (dto) => {
    return PropTypes.objectOf(dto)
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getStoreInfos = () => {
    return Object.assign({},
        ReferencialStoreInfos(),
        QualityStoreInfos(),
        PiezometryStoreInfos(),
        HydrometryStoreInfos(),
        ProductionUnitStoreInfos(),
        DistributionUnitStoreInfos(),
        InstallationStoreInfos(),
        SuiviPcStoreInfos(),
        StationStoreInfos(),
        HomeStoreInfos(),
        UserStoreInfos(),
        SituationStoreInfos(),
        EventsStoreInfos(),
        AdministrationStoreInfos(),
        SieauStoreInfos(),
        PiezometerStationStoreInfos(),
        OperationStoreInfos())
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getPropsInfos = (propsObject, info) => {
    const storeInfos = getStoreInfos()
    const propsList = propsObject.length ? propsObject : Object.keys(propsObject)
    return propsList.reduce((acc, prop) => {
        if (!storeInfos[prop]) {
            AppStore.dispatch(LogAction.logError(`Cannot find prop named "${prop}" on store`))
        } else {
            acc[prop] = storeInfos[prop][info]
        }
        return acc
    }, {})
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getFetchFunctions = (propsObject) => {
    return getPropsInfos(propsObject, 'fetch')
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getAddFunctions = (propsObject) => {
    return getPropsInfos(propsObject, 'add')
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getDeleteFunctions = (propsObject) => {
    return getPropsInfos(propsObject, 'delete')
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getUpdateFunctions = (propsObject) => {
    return getPropsInfos(propsObject, 'update')
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getResetFunctions = (propsObject) => {
    return getPropsInfos(propsObject, 'reset')
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getMapStateToProps = (propsObject, otherProps = {}) => {
    const obtainedObject = getPropsInfos(propsObject, 'store')
    return Object.assign({}, obtainedObject, otherProps)
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getPropTypes = (propsObject, otherProps = {}) => {
    const obtainedObject = getPropsInfos(propsObject, 'propType')
    return Object.assign({}, obtainedObject, otherProps)
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getPropType = (propName) => {
    const obtainedObject = getPropsInfos([propName], 'propType')
    return obtainedObject[propName]
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const whenReceiveProps = (props, nextProps, tab) => {
    if (some(tab, propName => !props[propName])) {
        return false
    }
    if (!tab.length) {
        return props[tab].length ? (nextProps[tab].length !== 0 && props[tab].length === 0) : (nextProps[tab].id && !props[tab].id)
    }
    const boolTable = tab.map(propName => {
        const otherProps = without(tab, propName)
        if (props[propName].length === 0 || props[propName].length) {
            return (nextProps[propName].length !== 0 && props[propName].length === 0) && every(otherProps.map(name => nextProps[name].length !== 0), bool => bool === true)
        }
        return (nextProps[propName].id && !props[propName].id) && every(otherProps.map(name => nextProps[name].id), bool => bool === true)
    })
    return some(boolTable, bool => bool === true)
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const whenPropsAreReceived = (props, nextProps, tab) => {
    if (!props.receivedProps) {
        AppStore.dispatch(LogAction.logError('cannot access receivedProps'))
        return false
    }
    return !every(tab, propName => props.receivedProps.includes(propName)) && every(tab, propName => nextProps.receivedProps.includes(propName))
}

const getObjectLabel = (obj, labelName, defaultReturn='') => {
    if (obj) {
        if (hasValue(labelName) && obj[labelName]) {
            return obj[labelName]
        }
        if (obj.name) {
            return obj.name
        }
        if (obj.label) {
            return obj.label
        }
        if (obj.title) {
            return obj.title
        }
    }
    return defaultReturn
}

/**
 * @deprecated use keyBy de lodash ou useListIndexed
 */
const createIndex = (tab, keyToUse) => {
    const res = {}
    if (keyToUse) {
        if (tab.length) {
            tab.forEach(o => {
                res[o[keyToUse]] = o
            })
            return res
        }
        return {}
    }
    if (tab.length) {
        tab.forEach(o => {
            res[o.id] = o
        })
        return res
    }
    return {}
}

const getLabel = (propList, code, labelName = undefined, key = 'code', defaultReturn = '') => {
    const found = propList.find(elem => elem[key] == code)
    if (found) {
        if (labelName && found[labelName]) {
            return found[labelName]
        }
        if (found.name) {
            return found.name
        }
        if (found.label) {
            return found.label
        }
        if (found.title) {
            return found.title
        }
    }
    const found2 = propList.find(elem => elem.id == code)
    if (found2) {
        if (labelName && found2[labelName]) {
            return found2[labelName]
        }
        if (found2.name) {
            return found2.name
        }
        if (found2.label) {
            return found2.label
        }
        if (found2.title) {
            return found2.title
        }
    }
    return defaultReturn
}

const filterHeaders = (headers, filter) => {
    return headers.filter(h => !filter.includes(h))
}

/**
 * @deprecated n'a jamais été bon à utiliser
 */
const getComponentWithId = (id) => {
    const dom = $(id)[0]
    if (!dom) {
        return null
    }
    let internalInstance = dom[Object.keys(dom).find(key => key.startsWith('__reactInternalInstance$'))]
    if (!internalInstance) {
        internalInstance = dom[Object.keys(dom).find(key => key.startsWith('__reactFiber$'))]
        if (!internalInstance) {
            return null
        }
    }
    return internalInstance._debugOwner ? internalInstance._debugOwner.stateNode : internalInstance.return.stateNode
}

const deleteKeys = (obj, tab = []) => {
    return Object.keys(obj).filter(k => !tab.find(o => o === k)).reduce((acc, val) => {
        const r = {}
        r[val] = obj[val]
        return Object.assign({}, acc, r)
    }, {})
}

const removeNullKeys = (obj) => {
    return Object.keys(obj).filter(k => !(obj[k] === undefined || obj[k] === null)).reduce((acc, key) => {
        const r = {}
        r[key] = obj[key]
        return Object.assign({}, acc, r)
    }, {})
}

/**
 * @deprecated use useSandreList
 */
const getSandreList = (sandreCodes, field, withReference = false) => {
    const list = sandreCodes.filter(c => c.field === field).map(sc => ({
        ...sc,
        name: sc.name || `[${sc.code}]`,
    }))
    if (withReference) {
        return list.map(s => ({ ...s, code: s.reference }))
    }
    return list
}

const getLabelTruncate = (label = '', code = '', nbChar = 25) => code ? label ? `${substringText(label, nbChar)} [${code}]` : `[${code}]` : ''

const getTruncate = (text, shortNumber = 50) => {
    if (text) {
        if (text.length > shortNumber) {
            return `${text.substring(text, shortNumber)}...`
        }
        return text
    }
    return ''
}

const updateIfExists = (previousList, newOne, key) => {
    const idx = previousList.findIndex(elem => elem[key] === newOne[key])
    if (idx !== -1) {
        previousList[idx] = newOne
        return previousList
    }
    return previousList
}

export {
    deleteKeys,
    getMapStateToProps,
    getPropTypes,
    getFetchFunctions,
    getAddFunctions,
    getDeleteFunctions,
    getResetFunctions,
    arrayOf,
    instanceOf,
    getPropType,
    whenReceiveProps,
    getLabel,
    filterHeaders,
    getComponentWithId,
    getUpdateFunctions,
    whenPropsAreReceived,
    getSandreList,
    removeNullKeys,
    getObjectLabel,
    createIndex,
    objectOf,
    getLabelTruncate,
    updateIfExists,
    getTruncate,
}
