import { push } from '@lagunovsky/redux-react-router'
import { compact, groupBy, keys } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import {
    H_DISTRIBUTION_MODULE,
    H_HYDRO_MODULE,
    H_INSTALLATION_MODULE,
    H_PIEZO_MODULE,
    H_PLUVIO_MODULE,
    H_PRODUCTION_MODULE,
    H_QUALITO_MODULE,
    H_RESOURCE_MODULE,
} from '../../../account/constants/AccessRulesConstants'
import Checkbox from '../../../components/forms/Checkbox'
import ListComponent from '../../../components/list/tableList/ListComponent'
import SieauAction from '../../../components/sieau/SieauAction'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import AppStore from '../../../store/AppStore'
import { getColorCircleElement, getColorFromPalette } from '../../../utils/ColorUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { getSiteUrl } from '../../../utils/mapUtils/SiteTypes'
import { execByType } from '../../../utils/StationUtils'
import { arrayOf, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import { STATION_NAME_ASSOCIATION } from '../../constants/StationConstants'
import DtoAssociatedStation from '../../dto/DtoAssociatedStation'
import AssociatedStationsEditModal from './AssociatedStationsEditModal'
import { CardTitle } from 'components/card/NewCard'
import { CardContent, Card } from '@mui/material'
import { NewTable } from 'components/datatable/NewTable'
import { greyBlue } from 'utils/constants/ColorTheme'

const propsToFetch = {
    qualitometers: false,
    installations: false,
    hydrometricStations: false,
    productionUnits: false,
    distributionUnits: false,
    stationTypes: false,
}

class LinkedStationsPanel extends Component {
    constructor(props) {
        super(props)
        this.state = { uncheckedStations: [] }
    }

    onLinkStation = (link) => {
        AppStore.dispatch(push(link))
        this.props.onReMount?.()
    }

    setLinkedStationsModal = () => {
        const id = uuidv4()

        const buttons = (
            <div>
                <a className='waves-effect waves-teal btn-flat modal-close'>{i18n.close}</a>
            </div>
        )
        const popup = {
            id,
            header: i18n.editAssociatedStations,
            actions: buttons,
            fixedFooter: true,
            content: <AssociatedStationsEditModal station={this.props.station} filteredStations={this.props.filteredStations} />,
        }
        AppStore.dispatch(SieauAction.setPopup(popup))
    }

    onCheckStation = (checked, station) => {
        if (checked) {
            const newStations = this.state.uncheckedStations.filter(s => s.typeName !== station.typeName || s.stationLinkedId !== station.stationLinkedId)
            this.props.changeUncheckedStations(newStations)
            this.setState({ uncheckedStations: newStations })
        } else {
            const newStations = [
                ...this.state.uncheckedStations,
                station,
            ]
            this.props.changeUncheckedStations(newStations)
            this.setState({ uncheckedStations: newStations })
        }
    }

    getStations = () => {
        const { associatedSites, dashboardMode, filteredStations, coloredCard } = this.props
        if (associatedSites.length || !dashboardMode) {
            const groupedStations = groupBy(associatedSites, 'typeName')
            const gsKeys = keys(groupedStations)
            const filteredkeys = filteredStations?.length ? gsKeys.filter(gsk => filteredStations.includes(gsk)) : gsKeys
            const groupTables = filteredkeys.map(typeName => {
                const tableData = groupedStations[typeName].map((s, index) => {
                    const isCheck = !this.state.uncheckedStations.find(st => st.stationLinkedId === s.stationLinkedId && s.typeName === st.typeName)
                    return {
                        code: { value: s.stationLinkedCode, onClick: () => this.onLinkStation(`/station/${s.typeName}/${s.stationLinkedId}`) },
                        name: { value: s.stationLinkedName, onClick: () => this.onLinkStation(`/station/${s.typeName}/${s.stationLinkedId}`) },
                        nullValue: {
                            value: (
                                <Checkbox
                                    checked={isCheck}
                                    onChange={v => this.onCheckStation(v, s)}
                                />
                            ),
                        },
                        nullValue2: this.props.withColors && typeName === 'piezometry' ? { value: getColorCircleElement(getColorFromPalette(index), true) } : { value: '' },
                    }
                })
                const nbElement = groupedStations[typeName].length > 1 ? i18n.elements : i18n.element
                return {
                    title: (
                        <div className='col s12 valign-wrapper padding-top-1 padding-bottom-1'>
                            <div className='col s2 no-margin'>
                                <img src={getSiteUrl(STATION_NAME_ASSOCIATION[typeName])}
                                    style={{ maxHeight: '30px' }}
                                />
                            </div>
                            <div className='col s10 no-margin'>
                                <h6>{`${i18n[typeName]} (${groupedStations[typeName][0].countType} ${nbElement})`}</h6>
                            </div>
                        </div>
                    ),
                    component: (
                        <NewTable
                            rows={tableData}
                            headers={compact([this.props.changeUncheckedStations && 'nullValue', this.props.withColors && 'nullValue2', 'code', 'name'])
                            }
                        />
                    ),
                    habilitation: execByType(typeName, {
                        quality: () => H_QUALITO_MODULE,
                        piezometry: () => H_PIEZO_MODULE,
                        pluviometry: () => H_PLUVIO_MODULE,
                        hydrometry: () => H_HYDRO_MODULE,
                        installation: () => H_INSTALLATION_MODULE,
                        productionUnit: () => H_PRODUCTION_MODULE,
                        distributionUnit: () => H_DISTRIBUTION_MODULE,
                        resource: () => H_RESOURCE_MODULE,
                    }),
                }
            }).filter(l => componentHasHabilitations(l.habilitation))
            const actions = []
            if (this.props.editMode) {
                actions.push({
                    icon: 'edit',
                    color: coloredCard ? 'white' : 'black',
                    onClick: () => this.setLinkedStationsModal(),
                })
            }
            if (associatedSites.length > 0) {
                actions.push({
                    icon: 'file_download',
                    color: coloredCard ? 'white' : 'black',
                    onClick: () => {
                        const allData = associatedSites.map(s => ({ type: i18n[s.typeName], code: s.stationLinkedCode, name: s.stationLinkedName }))
                        const exportData = allData.map((d, i) => {
                            if (i === 0) {
                                return { ...d, headers: ['type', 'code', 'name'] }
                            }
                            return d
                        })
                        exportFile({
                            data: exportData,
                            exportType: 'xlsx',
                            titleFile: `${this.props.station.name} - ${i18n.associatedStations}`,
                        })
                    },
                })
            }
            return (
                <Card className={`${this.props.className}`}>
                    {!this.props.hideTitle && <CardTitle title={this.props.title || i18n.associatedStations} actions={actions} color={coloredCard && greyBlue} />}
                    <CardContent noPadding>
                        <ListComponent tables={groupTables} collapsible={this.props.collapsible} hideTitle={this.props.hideListTitle} />
                    </CardContent>
                </Card>
            )
        }
        return null
    }

    render() {
        if (this.props.stationTypes.length !== 0) {
            return this.getStations()
        }
        return null
    }
}

LinkedStationsPanel.propTypes = getPropTypes(propsToFetch, {
    onReMount: PropTypes.func,
    displayedStations: PropTypes.arrayOf(PropTypes.string),
    editMode: PropTypes.bool,
    station: PropTypes.object,
    smallCard: PropTypes.bool,
    dashboardMode: PropTypes.bool,
    changeUncheckedStations: PropTypes.func,
    withColors: PropTypes.bool,
    piezometers: arrayOf(DtoPiezometerLight),
    filteredStations: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
    className: PropTypes.string,
    noMargin: PropTypes.bool,
    pluviometers: arrayOf(PluviometerDto),
    associatedSites: arrayOf(DtoAssociatedStation),
    hideTitle: PropTypes.bool,
    collapsible: PropTypes.bool,
    coloredCard: PropTypes.bool,
    hideListTitle: PropTypes.bool,
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    associatedSites: store.StationReducer.associatedSites,
})

export default connect(mapStateToProps)(LinkedStationsPanel)
