import { push } from '@lagunovsky/redux-react-router'
import { template } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import UserAction from '../../../administration/components/user/actions/UserAction'
import ActionComponent from '../../../components/ActionComponent'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import UpdatePanel from '../../../components/updatePanel/UpdatePanel'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactItem from '../../../referencial/components/contact/dto/ContactItem'
import WatermassDto from '../../../referencial/components/watermass/dto/WatermassDto'
import WatershedDto from '../../../referencial/components/watershed/dto/WatershedDto'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import StationAltimetrySystemsPanel from '../../../station/components/link/StationAltimetrySystemsPanel'
import StationContactsPanel from '../../../station/components/link/StationContactsPanel'
import StationDespoliationPanel from '../../../station/components/link/StationDespoliationPanel'
import StationLandmarkPanel from '../../../station/components/link/StationLandmarkPanel'
import StationLithologyPanel from '../../../station/components/link/StationLithologyPanel'
import StationLocationPanel from '../../../station/components/link/StationLocationPanel'
import StationMeasureMethodPanel from '../../../station/components/link/StationMeasureMethodPanel'
import StationNetworkPanel from '../../../station/components/link/StationNetworkPanel'
import StationPiezoContributorPanel from '../../../station/components/link/StationContributorPanel'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import { NEW, STATION_QUALITOMETER_NAMES_HYDRO } from '../../../station/constants/StationConstants'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLogin } from '../../../utils/SettingUtils'
import { getBookmarks, getLinks } from '../../../utils/StationUtils'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import HydrometryAction from '../../actions/HydrometryAction'
import HydrometerDto from '../../dto/DtoHydrometricStation'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import UserDto from '../../../administration/components/user/dto/UserDto'
import WatermassAction from '../../../referencial/components/watermass/actions/WatermassAction'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import StationPointPrelPanel from '../../../station/components/link/StationPointPrelPanel'
import StationTempRefPanel from '../../../station/components/link/StationTempRefPanel'
import { Card, CardContent, Grid2 } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'
import Checkbox from 'components/forms/Checkbox'
import DtoHydrogeologicalEntity from 'station/dto/DtoHydrogeologicalEntity'
import { filterStationCoordinates } from 'utils/mapUtils/CoordinateUtils'

class HydrometryDescriptionPanel extends ActionComponent {
    state = {
        hydrometer: { ...this.props.hydrometer },
        readMode: true,
    }

    componentDidMount() {
        this.props.fetchWatermasses()
        this.props.fetchWatersheds()
        if (this.props.hydrometer.id) {
            this.setState({ hydrometer: this.props.hydrometer })
        }
        if (this.props.id === NEW) {
            this.setEditMode()
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.hydrometer.id && prevProps.hydrometer.id) {
            this.setState({ hydrometer: this.props.hydrometer })
        }
    }

    onSave = () => {
        if (!this.state.hydrometer.code) {
            this.props.toastrError(template(i18n.fieldNotSet)({ field: i18n.code }))
        } else if (this.props.id === NEW && this.props.hydrometers.find(q => q.code === this.state.hydrometer.code)) {
            this.props.toastrError(i18n.codeMeteoFranceAlreadyExists)
        } else if (this.props.id === NEW) {
            this.props.toastrInfo(i18n.inDeveloppmentFunctionnality)
        } else {
            this.props.updateHydrometer(this.state.hydrometer)
            this.setReadOnlyMode()
        }
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            delete: () => this.props.deleteHydrometer(this.props.hydrometer.id, () => this.props.push('/hydrometry')),
            save: () => this.onSave(),
            cancel: () => {
                if (this.props.id === NEW) {
                    this.props.push('/hydrometry')
                } else {
                    this.setState({
                        hydrometer: this.props.hydrometer,
                    })
                    this.setReadOnlyMode()
                }
            },
            links: getLinks(this.props.hydrometer, this.props),
            arrowNav: getStationArrowNav('hydrometry', this.props.hydrometers, this.props.hydrometer.id, s => this.props.push(`/station/hydrometry/${s.id}/description`)),
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        const { hydrometer, hydrometers, user, typeEnvironmentModels } = this.props
        this.setState({ readMode: true })
        if (user.consultant === '1') {
            this.setActions({
                exportmodel: () => ({
                    stationId: hydrometer.id.toString(),
                    stationCode: hydrometer.code,
                    stationType: hydrometer.typeName,
                    environmentModels: typeEnvironmentModels,
                }),
                links: getLinks(hydrometer, this.props),
                arrowNav: getStationArrowNav('hydrometry', hydrometers, hydrometer.id, s => this.props.push(`/station/hydrometry/${s.id}/description`)),
            })
        } else {
            this.setActions({
                exportmodel: () => ({
                    stationId: hydrometer.id.toString(),
                    stationCode: hydrometer.code,
                    stationType: hydrometer.typeName,
                    environmentModels: typeEnvironmentModels,
                }),
                edit: () => this.setEditMode(),
                delete: () => this.props.deleteHydrometer(hydrometer.id, () => this.props.push('/hydrometry')),
                links: getLinks(hydrometer, this.props),
                arrowNav: getStationArrowNav('hydrometry', hydrometers, hydrometer.id, s => this.props.push(`/station/hydrometry/${s.id}/description`)),
            })
        }
    }

    getUpdatePanel = () => {
        if (this.state.hydrometer.updateLogin || this.state.hydrometer.updateDate) {
            return (
                <UpdatePanel updateLogin={this.state.hydrometer.updateLogin}
                    updateDate={this.state.hydrometer.updateDate}
                />
            )
        }
        return null
    }

    onChangeHydrometer = (value) => this.setState({ hydrometer: { ...this.state.hydrometer, ...value } })

    onChangeWork = (value) => {
        const { hydrometer } = this.state
        const hydrometerWork = hydrometer.link_work[0] || {}
        this.onChangeHydrometer({
            link_work: [{
                ...hydrometerWork,
                idStation: hydrometer.id,
                ...value,
            }],
        })
    }

    onChangeGeoData = (value) => {
        const { hydrometer } = this.state
        const linkGeo = hydrometer.link_geo && hydrometer.link_geo[0] || {}
        this.onChangeHydrometer({ link_geo: [{ ...linkGeo, idStation: hydrometer.id, ...value }] })
    }

    setBookmark = (deleteBookmark = false) => {
        const { hydrometer } = this.state
        if (deleteBookmark) {
            this.props.deleteBookmark({ identifiant: hydrometer.code, login: getLogin(), stationType: 4 })
        } else {
            this.props.createBookmark({ identifiant: hydrometer.code, login: getLogin(), stationType: 4 })
        }
    }

    render() {
        const { hydrometer = {}, readMode } = this.state
        const params = {
            station: hydrometer,
            onChange: this.onChangeHydrometer,
            readMode,
        }
        const work = hydrometer.link_work[0] || {}
        const geoData = hydrometer.link_geo && hydrometer.link_geo[0] || {}

        return (
            <Grid2 container spacing={1} sx={{ marginBottom: '100px', padding: '10 10 0 20' }} alignItems='flex-start'>
                <Grid2 container size={9} columnSpacing={1}>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.description} />
                            <CardContent>
                                <Grid2 container columnSpacing={1}>
                                    <Grid2 size={6}>
                                        <Input
                                            value={hydrometer.code}
                                            title={i18n.code}
                                            disabled={this.props.id !== NEW}
                                            onChange={v => this.onChangeHydrometer({ code: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={5}>
                                        <Input
                                            value={hydrometer.name}
                                            title={i18n.name}
                                            onChange={v => this.onChangeHydrometer({ name: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 container size={1} justifyContent='flex-end' alignItems='center'>
                                        {getBookmarks(hydrometer.code, getUserBookmarksByStationType(this.props.userBookmarks, 4), true, this.setBookmark)}
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            style={{ padding: 0 }}
                                            value={hydrometer.creationDate}
                                            id='creation'
                                            label={i18n.creationDate}
                                            onChange={(v) => this.onChangeHydrometer({ creationDate: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            style={{ padding: 0 }}
                                            value={hydrometer.closeDate}
                                            id='close'
                                            label={i18n.closeDate}
                                            onChange={(v) => this.onChangeHydrometer({ closeDate: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            value={hydrometer.stationType}
                                            label={i18n.stationTypeLabel}
                                            options={STATION_QUALITOMETER_NAMES_HYDRO}
                                            onChange={v => this.onChangeHydrometer({ stationType: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Textarea
                                            value={hydrometer.comment}
                                            title={i18n.comment}
                                            onChange={v => this.onChangeHydrometer({ comment: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.context} />
                            <CardContent>
                                <Grid2 container columnSpacing={1}>
                                    <Grid2 size={6}>
                                        <Select
                                            value={hydrometer.link_watermasses[0] && hydrometer.link_watermasses[0].watermassCode}
                                            label={i18n.watermass}
                                            options={this.props.watermasses}
                                            displayWithCode
                                            onChange={v => this.onChangeHydrometer({ link_watermasses: [{ idStation: hydrometer.id, watermassCode: v }] })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            value={hydrometer.watershedCode}
                                            label={i18n.watershed}
                                            options={this.props.watersheds}
                                            keyValue='id'
                                            onChange={v => this.onChangeHydrometer({ watershedCode: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            value={hydrometer.link_lithologicType[0] && hydrometer.link_lithologicType[0].lithologyType}
                                            label={i18n.lithologyType}
                                            options={getSandreList(this.props.sandreCodes, SANDRE.LITHOLOGY_TYPE)}
                                            nullLabel='&nbsp;'
                                            onChange={v => this.onChangeHydrometer({ link_lithologicType: [{ idStation: hydrometer.id, lithologyType: v }] })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            label={i18n.hydrogeologicalEntities}
                                            value={hydrometer.hydrographicCode}
                                            options={this.props.hydrogeologicalEntities}
                                            keyValue='id'
                                            onChange={v => this.onChangeHydrometer({ hydrographicCode: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Checkbox
                                            label={i18n.watershedHead}
                                            checked={hydrometer.watershedHead}
                                            onChange={v => this.onChangeHydrometer({ watershedHead: v })}
                                            disabled={readMode}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                            <Grid2 sx={{ padding: '0 10 10' }}>
                                <StationLithologyPanel {...params} />
                            </Grid2>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <StationPiezoContributorPanel {...params} />
                    </Grid2>
                    <Grid2 size={12}>
                        <StationAltimetrySystemsPanel {...params} />
                    </Grid2>
                    <Grid2 size={12}>
                        <StationLandmarkPanel {...params} />
                    </Grid2>
                    <Grid2 size={12}>
                        <StationNetworkPanel {...params} />
                    </Grid2>
                    <Grid2 size={12}>
                        <StationDespoliationPanel {...params} />
                    </Grid2>
                    <Grid2 size={12}>
                        <StationMeasureMethodPanel {...params} />
                    </Grid2>
                    <Grid2 size={12}>
                        <StationPointPrelPanel {...params} st='hydrometry' />
                    </Grid2>
                    <Grid2 size={12}>
                        <StationTempRefPanel {...params} st='hydrometry' />
                    </Grid2>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.technicalCharacteristics} />
                            <CardContent>
                                <Grid2 container columnSpacing={1}>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.stationType}
                                            value={work.stationType}
                                            onChange={v => this.onChangeWork({ stationType: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.phone}
                                            value={work.phone}
                                            onChange={v => this.onChangeWork({ phone: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.sensorType}
                                            value={work.sensorType}
                                            onChange={v => this.onChangeWork({ sensorType: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <StationLocationPanel {...params} />
                    </Grid2>
                    <Grid2 size={12}>
                        <StationContactsPanel {...params} />
                    </Grid2>
                </Grid2>
                <Grid2 container size={3} columnSpacing={1}>
                    <Grid2 size={12}>
                        <StationUpdatePanel className='no-padding no-margin' station={hydrometer} />
                    </Grid2>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.contact} />
                            <CardContent>
                                <Select
                                    value={hydrometer.contactCode}
                                    options={this.props.contacts}
                                    keyValue='id'
                                    onChange={v => this.onChangeHydrometer({ contactCode: v })}
                                    readMode={readMode}
                                />
                            </CardContent>
                        </Card>
                    </Grid2>
                    {filterStationCoordinates(hydrometer, this.props.citiesIndex) && (
                        <Grid2 size={12}>
                            <StationMapDashboardPanel
                                noMarkerTooltip
                                station={hydrometer}
                                type={'hydrometry'}
                            />
                        </Grid2>
                    )}
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.localisation} />
                            <CardContent>
                                <Grid2 container columnSpacing={1}>
                                    <Grid2 size={12}>
                                        <Input
                                            title={i18n.address}
                                            value={hydrometer.address}
                                            onChange={v => this.onChangeHydrometer({ address: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Select
                                            value={hydrometer.townCode}
                                            label={i18n.city}
                                            options={this.props.cities}
                                            keyValue='id'
                                            displayWithCode
                                            onChange={v => this.onChangeHydrometer({ townCode: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={12} displayIf={hydrometer.declarationTownCode !== hydrometer.townCode || !readMode}>
                                        <Select
                                            value={hydrometer.declarationTownCode}
                                            label={i18n.declarationTownCode}
                                            options={this.props.cities}
                                            keyValue='id'
                                            displayWithCode
                                            onChange={v => this.onChangeHydrometer({ declarationTownCode: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    {hydrometer.townCode &&
                                        <Grid2 size={12}>
                                            <Input
                                                value={hydrometer.townCode.substring(0, 2)}
                                                title={i18n.departmentNumber}
                                                disabled
                                                readMode={readMode}
                                            />
                                        </Grid2>
                                    }
                                    <Grid2 size={12}>
                                        <Input
                                            value={hydrometer.countryCode}
                                            title={i18n.countryCode}
                                            onChange={v => this.onChangeHydrometer({ countryCode: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Textarea
                                            noMargin={false}
                                            title={i18n.localisation}
                                            value={hydrometer.localization}
                                            onChange={v => this.onChangeHydrometer({ localization: v })} readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            title='X'
                                            value={hydrometer.x}
                                            floatValue
                                            onChange={v => this.onChangeHydrometer({ x: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            title='Y'
                                            value={hydrometer.y}
                                            floatValue
                                            onChange={v => this.onChangeHydrometer({ y: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Select
                                            label={i18n.projection}
                                            value={hydrometer.projection}
                                            options={getSandreList(this.props.sandreCodes, SANDRE.PROJECTION)}
                                            onChange={v => this.onChangeHydrometer({ projection: v })}
                                            readMode={readMode}
                                            integerValue
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <NumberField
                                            value={hydrometer.altitude}
                                            title={i18n.altitudeM}
                                            floatValue
                                            onChange={v => this.onChangeHydrometer({ altitude: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.ignMap}
                                            value={geoData.ignMap}
                                            onChange={v => this.onChangeGeoData({ ignMap: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.geologicalMap}
                                            value={geoData.geologicalMap}
                                            onChange={v => this.onChangeGeoData({ geologicalMap: v })} readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.section}
                                            value={geoData.parcel}
                                            onChange={v => this.onChangeGeoData({ parcel: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.parcel}
                                            value={geoData.section}
                                            onChange={v => this.onChangeGeoData({ section: v })}
                                            readMode={readMode}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <LinkedStationsPanel onReMount={this.props.onRemount} station={hydrometer} editMode={!readMode} />
                    </Grid2>
                </Grid2>
            </Grid2>
        )
    }
}

HydrometryDescriptionPanel.propTypes = {
    user: PropTypes.instanceOf(UserDto),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactItem)),
    hydrometers: PropTypes.arrayOf(PropTypes.instanceOf(HydrometerDto)),
    hydrometer: PropTypes.instanceOf(HydrometerDto),
    id: PropTypes.number,
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    watermasses: arrayOf(WatermassDto),
    watersheds: arrayOf(WatershedDto),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
    hydrogeologicalEntities: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrogeologicalEntity)),
}

const mapStateToProps = store => ({
    user: store.AccountReducer.accountUser,
    cities: store.CityReducer.cities,
    contacts: store.ContactReducer.contacts,
    hydrometers: store.HydrometryReducer.hydrometricStations,
    hydrometer: store.HydrometryReducer.hydrometricStation,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    watersheds: store.WatershedReducer.watersheds,
    watermasses: store.WatermassReducer.watermasses,
    userBookmarks: store.UserReducer.userBookmarks,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
    hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
    citiesIndex: store.CityReducer.citiesIndex,
})

const mapDispatchToProps = {
    push,
    toastrInfo: ToastrAction.info,
    toastrError: ToastrAction.error,
    updateHydrometer: HydrometryAction.updateHydrometer,
    deleteBookmark: UserAction.deleteBookmark,
    createBookmark: UserAction.createBookmark,
    deleteHydrometer: HydrometryAction.deleteHydrometer,
    fetchWatermasses: WatermassAction.fetchWatermasses,
    fetchWatersheds: WatershedAction.fetchWatersheds,
}

export default connect(mapStateToProps, mapDispatchToProps)(HydrometryDescriptionPanel)
