import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import LastEventPanel from '../../../station/components/event/LastEventPanel'
import ContactCard from '../../../referencial/components/contact/components/ContactCard'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import InstallationInfosPanel from '../installations/components/InstallationInfosPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import InstallationComElecPanel from '../installations/components/InstallationComElecPanel'
import InstallationAccessSitePanel from '../installations/components/InstallationAccessSitePanel'
import InstallationAccessCasingPanel from '../installations/components/InstallationAccessCasingPanel'
import StationLocationInfoPanel from '../../../station/components/location/StationLocationInfoPanel'
import InstallationStaffSafetyPanel from '../installations/components/InstallationStaffSafetyPanel'
import { getVisitSelectChange } from '../../../utils/VisitUtils'
import LocalisationMapModal from '../installations/components/modal/LocalisationMapModal'
import StationSamplersPanel from 'station/components/link/StationSamplersPanel'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import StationContributorPanel from '../../../station/components/link/StationContributorPanel'
import InstallationDataOriginPanel from './InstallationDataOriginPanel'
import { Card, CardContent, Grid2 } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'
import { isDisplayed } from 'utils/InstallationUtils'
import { useSelector } from 'react-redux'

const GenericInstallationDescription = ({
    readMode = false,
    installation = {},
    onChangeInstallation = () => { },
    onChangeVisit,
    descElements,
    otherElements,
    linkedStationTypes,
}) => {
    const {
        applicationSettings,
        contacts,
        accountHabilitations,
        installationEvents,
        installation: installationProps,
    } = useSelector(store => ({
        applicationSettings: store.AdministrationReducer.applicationSettings,
        contacts: store.ContactReducer.contacts,
        accountHabilitations: store.AccountReducer.accountHabilitations,
        installationEvents: store.InstallationReducer.installationEvents,
        installation: store.InstallationReducer.installation,
    }))

    const [localizeMap, setLocalizeMap] = useState(false)
    // const { installation, readMode, localizeMap } = this.state
    // const { accountHabilitations, applicationSettings } = this.props
    const mode = { readMode, editMode: !readMode }
    const params = {
        station: installation,
        onChange: onChangeInstallation,
        onChangeVisit,
        readMode,
    }

    return (
        <Grid2 container spacing={1} sx={{ marginBottom: '100px', padding: '10 10 0 20' }} alignItems='flex-start'>
            <Grid2 container size={9} columnSpacing={1}>
                <Grid2 size={12}>
                    <Card>
                        <CardTitle title={i18n.description} />
                        <CardContent>
                            <Grid2 container columnSpacing={1}>
                                <InstallationInfosPanel {...params} />
                                {descElements}
                            </Grid2>
                        </CardContent>
                    </Card>
                </Grid2>
                {otherElements}
                {isDisplayed(applicationSettings, 'ELEC_COM', installation.installationType) && (
                    <Grid2 size={12}>
                        <InstallationComElecPanel {...params} />
                    </Grid2>
                )}
                {isDisplayed(applicationSettings, 'ACCESS_SITE', installation.installationType) && (
                    <Grid2 size={12}>
                        <InstallationAccessSitePanel {...params} />
                    </Grid2>
                )}
                {isDisplayed(applicationSettings, 'ACCESS_CASING', installation.installationType) && (
                    <Grid2 size={12}>
                        <InstallationAccessCasingPanel {...params} />
                    </Grid2>
                )}
                {isDisplayed(applicationSettings, 'STAFF_SAFETY', installation.installationType) && (
                    <Grid2 size={12}>
                        <InstallationStaffSafetyPanel {...params} />
                    </Grid2>
                )}
                {isDisplayed(applicationSettings, 'CONTRIBUTORS', installation.installationType) && (
                    <Grid2 size={12}>
                        <StationContributorPanel {...params} />
                    </Grid2>
                )}
                {isDisplayed(applicationSettings, 'USAGES', installation.installationType) && (
                    <Grid2 size={12}>
                        <StationUsagePanel {...params} />
                    </Grid2>
                )}
                {isDisplayed(applicationSettings, 'ARRANGEMENTS', installation.installationType) && (
                    <Grid2 size={12}>
                        <StationArrangementPanel {...params} />
                    </Grid2>
                )}
                {accountHabilitations.some((h) => h.habilitation === AGRI) && isDisplayed(applicationSettings, 'SAMPLERS', installation.installationType) && (
                    <Grid2 size={12}>
                        <StationSamplersPanel {...params} />
                    </Grid2>
                )}
            </Grid2>
            <Grid2 container size={3} columnSpacing={1}>
                <Grid2 size={12}>
                    <StationUpdatePanel station={installation} />
                </Grid2>
                <Grid2 size={12}>
                    <InstallationDataOriginPanel {...params} />
                </Grid2>
                <Grid2 size={12}>
                    <ContactCard
                        title={i18n.contact}
                        contactCode={installation.ownerCode}
                        onChange={v => {
                            onChangeInstallation({ ownerCode: v })
                            onChangeVisit(getVisitSelectChange(contacts, 'code', i18n.contact, installation.ownerCode, v))
                        }}
                        readMode={readMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <LastEventPanel
                        id={installation.id}
                        events={installationEvents}
                        stationId={installation.id}
                        stationType='installation'
                    />
                </Grid2>
                <Grid2 size={12}>
                    <Card>
                        <CardTitle
                            title={i18n.map}
                            actions={
                                !readMode ?
                                    [{
                                        icon: 'my_location',
                                        onClick: () => setLocalizeMap(true),
                                    }] : []
                            }
                        />
                        <StationMapDashboardPanel noMarkerTooltip station={installationProps} type={'installation'} />
                        <LocalisationMapModal
                            onChangeInstallation={onChangeInstallation}
                            installation={installation}
                            readMode={readMode}
                            open={localizeMap}
                            onClose={() => setLocalizeMap(false)}
                        />
                    </Card>
                </Grid2>
                <Grid2 size={12}>
                    <Card>
                        <CardTitle title={i18n.localisation} />
                        <CardContent>
                            <StationLocationInfoPanel
                                onChange={onChangeInstallation}
                                onChangeVisit={onChangeVisit}
                                station={installation}
                                readMode={readMode}
                            />
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 size={12}>
                    <LinkedStationsPanel
                        noMargin={false}
                        station={installation}
                        filteredStations={linkedStationTypes}
                        {...mode}
                    />
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

GenericInstallationDescription.propTypes = {
    installation: PropTypes.object, // DtoInstallation
    readMode: PropTypes.bool,
    onChangeInstallation: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    descElements: PropTypes.element, // specific description elements
    otherElements: PropTypes.element, // other specific elements
    linkedStationTypes: PropTypes.arrayOf(PropTypes.string), // CaptureDescriptionPanel
}

export default GenericInstallationDescription
