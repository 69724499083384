import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { push } from '@lagunovsky/redux-react-router'
import { groupBy, isNil, keys, maxBy, orderBy, round, sum, uniq, uniqBy } from 'lodash'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getLabel } from '../../utils/StoreUtils'
import ProgressBar from '../progress/ProgressBar'
import {
    getLocalizationPicto,
    getStatusAndResult,
    calculateAverage,
    searchMaxAnalysis,
    filterValid,
    filterResult,
    filterQuantification,
    searchP90Value,
    searchMinValue,
    searchMaxValue,
} from '../../utils/AnalyseUtils'
import { getDate } from '../../utils/DateUtil'
import { nbPerPageLabel } from '../../referencial/constants/ReferencialConstants'
import SampleModal from '../../quality/components/qualityResearch/SampleModal'
import { exportFile } from '../../utils/ExportDataUtil'
import Card from '../card/Card'
import Icon from '../icon/Icon'
import { getI18nTitleDataLength } from '../../utils/StringUtil'
import { Accordion, AccordionDetails, Grid2 } from '@mui/material'
import useListIndexed from 'utils/customHook/useListIndexed'
import useBoolean from 'utils/customHook/useBoolean'
import PaginatedList from 'components/list/PaginatedList'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import { HydrobioGraphModal } from 'quality/components/qualityComponents/HydrobioGraph'
import { AccordionTitle } from 'components/styled/Accordions'
import { NewTable } from 'components/datatable/NewTable'

const CollapsibleTable = ({
    displayHydrobio = {},
    hydrobioList = [],
    isOnlyOneHydrobio = false,
    thresholds = [],

    openSampleModal = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        qualitometers,
        taxonsList,
        settings,
        contributors,
        qualifications,
        status,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        taxonsList: store.TaxonReducer.taxons,
        settings: store.AdministrationReducer.settings,
        contributors: store.ContributorReducer.contributors,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
    }), shallowEqual)

    const taxonsIndexed = useListIndexed(taxonsList, 'code')
    const contributorsIndexed = useListIndexed(contributors, 'id')

    const {
        value: isOpen,
        setFalse: close,
        setTrue: open,
    } = useBoolean(false)

    const formattedHydrobios = useMemo(() => hydrobioList.map(a => ({
        determiner: contributorsIndexed[a.determinator]?.name ?? '',
        date: getDate(a.operationDate),
        statusAndResult: { value: getStatusAndResult(a, a.value, a.color, true) },
        nullValue: getLocalizationPicto(a.localization),
        id: a.id,
    })), [hydrobioList, contributorsIndexed])

    const hydrobioPhases = useMemo(() => sum([displayHydrobio.enumerationA, displayHydrobio.enumerationB, displayHydrobio.enumerationC, displayHydrobio.enumerationC2].filter(e => !!e)) || 0, [displayHydrobio.enumerationA, displayHydrobio.enumerationB, displayHydrobio.enumerationC, displayHydrobio.enumerationC2])

    const abundance = useMemo(() => {
        const operationHydrobios = uniqBy(hydrobioList.filter(t => t.operation === displayHydrobio.operation), 'taxon')
        const operationsPhases = sum(operationHydrobios.flatMap(t => [ t.enumerationA, t.enumerationB, t.enumerationC, t.enumerationC2 ]).filter(e => !!e))

        const abundanceCalculated = operationsPhases && (hydrobioPhases * 100) / operationsPhases
        return abundanceCalculated ? `${round(abundanceCalculated, 2)}%` : i18n.incalculable
    }, [displayHydrobio.operation, hydrobioPhases, hydrobioList])

    const qualitometer = qualitometers.find(q => q.id === hydrobioList[0]?.qualitometer)

    const hydrobiosFiltered = hydrobioList.filter(t => t.taxon === displayHydrobio.taxon)

    const validHydrobios = filterValid(filterResult(hydrobiosFiltered))
    const maxHydrobios = searchMaxAnalysis(hydrobiosFiltered)
    const averageValue = calculateAverage(hydrobiosFiltered, settings)

    const occurency = useMemo(() => {
        const groupedHydrobios = groupBy(hydrobiosFiltered, 'operation')
        const samples = sum(keys(groupedHydrobios).map(key => sum(groupedHydrobios[key]?.flatMap(tax => tax.result))))

        const supports = uniq(hydrobiosFiltered.map(t => t.support).filter(t => !!t))
        const hydrobiosToEnumerate = hydrobioList.filter(t => supports.includes(t.support))
        const allSamples = sum(hydrobiosToEnumerate.map(t => t.result))


        const occurrenceFrequency = allSamples && (samples / allSamples) * 100

        return occurrenceFrequency ? `${round(occurrenceFrequency, 2)}%` : i18n.incalculable
    }, [hydrobioList, hydrobiosFiltered])

    const nbHydrobios = hydrobiosFiltered.length
    const nbQuantification = filterQuantification(validHydrobios).length
    const nbTaxons = uniqBy(hydrobioList, 'taxon').length
    const lastDate = maxBy(hydrobioList, 'operationDate')?.operationDate

    const exportHydrobios = () => {
        const data = hydrobioList.map(a => ({
            stationCode: qualitometer.code,
            stationName: qualitometer.name,
            date: getDate(a.operationDate),
            taxonCode: { value: a.taxon, cellType: 'right' },
            taxon: taxonsIndexed[a.taxon]?.labelWithCode || '',
            result: { value: a.value, color: a.color, cellType: 'right' },
            status: getLabel(status, a.status),
            qualification: getLabel(qualifications, a.qualification),
            determiner: getLabel(contributors, a.determinator),
            x: { value: qualitometer.x, format: '0.0000000000', cellType: 'number' },
            y: { value: qualitometer.y, format: '0.0000000000', cellType: 'number' },
            projection: { value: qualitometer.projection, format: '0', cellType: 'number' },
        }))
        const dataWithHeaders = data.length ? [
            { ...data[0], headers: Object.keys(data[0]) },
            ...data.slice(1),
        ] : []
        exportFile({
            data: dataWithHeaders,
            exportType: 'xlsx',
            titleFile: `${qualitometer.code}_${qualitometer.name}`,
        })
    }

    return (
        <>
            <Accordion>
                <AccordionTitle
                    fontColor='black'
                    color='white'
                    title={(
                        <Grid2 container columnSpacing={1} rowSpacing={0.5} alignItems='center' sx={{ fontSize: '13px' }}>
                            <Grid2 size={3}>
                                <Grid2 size={12}>
                                    <span>{qualitometer?.code}</span>
                                </Grid2>
                                <Grid2 size={12}>
                                    <span>{qualitometer?.name}</span>
                                </Grid2>
                            </Grid2>
                            <Grid2 size={2} className={displayHydrobio.color}>
                                <Grid2 size={12}>
                                    <span>
                                        {getDate(displayHydrobio.operationDate ?? lastDate)}
                                    </span>
                                </Grid2>
                                <Grid2 size={12}>
                                    <span>
                                        {hydrobioPhases} - {abundance}
                                    </span>
                                </Grid2>
                            </Grid2>
                            <Grid2 size={4}>
                                {
                                    isNil(displayHydrobio.taxon) && (
                                        <Grid2 size={12}>
                                            <span>
                                                {nbTaxons} {i18n.taxons}
                                            </span>
                                        </Grid2>
                                    )
                                }
                                {
                                    !isNil(displayHydrobio.taxon) && (
                                        <>
                                            <Grid2 size={12}>
                                                <span>
                                                    {taxonsIndexed[displayHydrobio.taxon]?.labelWithCode ?? ''}
                                                </span>
                                            </Grid2>
                                            <Grid2 size={12}>
                                                <span>
                                                    {i18n.max}: {maxHydrobios?.value ?? ''} - {i18n.average}: {!isNil(averageValue) ? averageValue : ''}
                                                </span>
                                            </Grid2>
                                            <Grid2 size={12}>
                                                <span>
                                                    {i18n.occurency} {occurency}
                                                </span>
                                            </Grid2>
                                        </>
                                    )
                                }
                            </Grid2>
                            <Grid2 size={2}>
                                <ProgressBar
                                    progress={nbHydrobios !== 0 ? nbQuantification * 100 / nbHydrobios : 0}
                                    tooltip={`${nbQuantification} ${i18n.quantificationsRatio} ${nbHydrobios} ${i18n.results}`}
                                />
                            </Grid2>
                            <Grid2 size={1}>
                                <Grid2 size={12}>
                                    <Icon
                                        icon='info'
                                        style={{
                                            color: 'black',
                                            fontSize: '1.5rem',
                                        }}
                                        onClick={e => {
                                            e.stopPropagation()
                                            dispatch(push(`/station/quality/${qualitometer?.id}`))
                                        }}
                                        tooltip={i18n.accessToTheFile}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Icon
                                        icon='file_download'
                                        style={{
                                            color: 'black',
                                            fontSize: '1.5rem',
                                        }}
                                        onClick={e => {
                                            e.stopPropagation()
                                            exportHydrobios()
                                        }}
                                        tooltip={i18n.export}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Icon
                                        icon='insert_chart'
                                        style={{
                                            color: 'black',
                                            fontSize: '1.5rem',
                                        }}
                                        onClick={e => {
                                            e.stopPropagation()
                                            open()
                                        }}
                                        tooltip={i18n.graph}
                                    />
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    )}
                />
                <AccordionDetails sx={{ padding: 0 }}>
                    <NewTable
                        rows={formattedHydrobios}
                        headers={['determiner', 'date', { key: 'statusAndResult', value: `${formattedHydrobios.length} ${getI18nTitleDataLength(i18n.result, i18n.results, formattedHydrobios.length)}` }, 'nullValue']}

                        rowsPerPageOptions={nbPerPageLabel}
                        onClickRow={({ id }) => openSampleModal(id)}
                    />
                </AccordionDetails>
            </Accordion>
            <HydrobioGraphModal
                isOpen={isOpen}
                closeGraph={close}
                hydrobioList={hydrobiosFiltered}
                thresholds={thresholds}
                hideActions={!isOnlyOneHydrobio}
            />
        </>
    )
}

CollapsibleTable.propTypes = {
    displayHydrobio: PropTypes.shape({
        // DtoSearchHydrobioLight,
        // calculateThresholdResult,
    }),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    hydrobioList: PropTypes.arrayOf(PropTypes.shape({
        // DtoSearchHydrobio ou DtoSearchHydrobioLight
        // calculateThresholdResult
    })),
    researchType: PropTypes.string,
    isOnlyOneHydrobio: PropTypes.bool,
    openSampleModal: PropTypes.func,
}

const CartographyHydrobiosPanel = ({
    stationsPanelTitle = '',
    thresholds = [],
    hydrobioList = [],
}) => {
    const {
        settings,
        qualitometers,
        units,
        qualifications,
    } = useSelector(store => ({
        settings: store.AdministrationReducer.settings,
        qualitometers: store.QualityReducer.qualitometersLight,
        units: store.UnitReducer.units,
        qualifications: store.QualityReducer.qualifications,
    }), shallowEqual)

    const isOnlyOneHydrobio = useMemo(() => {
        const uniqTax = uniqBy(hydrobioList, 'taxon')
        return uniqTax.length === 1
    }, [hydrobioList])

    const {
        value: isOpen,
        setFalse: close,
        setTrue: open,
    } = useBoolean(false)
    const [selectedHydroBio, setSelectedHydrobio] = useState()

    const groupByStations = useMemo(() => {
        const orderedHydrobios = orderBy(hydrobioList, 'operationDate')
        return groupBy(orderedHydrobios, 'qualitometer')
    }, [hydrobioList])
    const stationsIds = keys(groupByStations).map(id => parseInt(id))

    const getDisplayedHydrobio = listTaxons => maxBy(listTaxons, 'operationDate')

    const hydrobio = useMemo(() => hydrobioList.find(a => a.id === selectedHydroBio), [hydrobioList, selectedHydroBio])

    const exportStations = () => {
        const data = stationsIds.map(id => {
            const qualitometer = qualitometers.find(q => q.id === id)
            const stationHydrobios = groupByStations[id]
            const displayHydrobio = getDisplayedHydrobio(stationHydrobios)

            const validHydrobios = filterValid(filterResult(stationHydrobios))

            const p90 = searchP90Value(validHydrobios)
            const min = searchMinValue(validHydrobios)
            const max = searchMaxValue(validHydrobios)
            const average = calculateAverage(validHydrobios, settings)

            return {
                stationCode: qualitometer.code,
                stationName: qualitometer.name,
                cityCode: { value: qualitometer.townCode, cellType: 'right' },
                lastOperation: { value: getDate(displayHydrobio.operationDate), format: 'dd/MM/yyy', cellType: 'date' },
                result: { value: displayHydrobio.value, color: displayHydrobio.color, cellType: 'right' },
                min: { value: min, cellType: 'right' },
                max: { value: max, cellType: 'right' },
                average: { value: average, cellType: 'right' },
                percentile90: { value: p90, cellType: 'right' },
                codeUnit: { value: displayHydrobio.unit, cellType: 'right' },
                unit: getLabel(units, displayHydrobio.unit, 'symbol'),
                qualification: getLabel(qualifications, displayHydrobio.qualification),
                x: { value: qualitometer.x, format: '0.0000000000', cellType: 'number' },
                y: { value: qualitometer.y, format: '0.0000000000', cellType: 'number' },
                projection: { value: qualitometer.projection, format: '0', cellType: 'number' },
            }
        })
        const dataWithHeaders = data.length ? [
            { ...data[0], headers: keys(data[0]) },
            ...data.slice(1),
        ] : []
        exportFile({
            data: dataWithHeaders,
            exportType: 'xlsx',
            titleFile: i18n.research,
        })
    }

    const actions = [{
        iconName: 'file_download',
        tooltip: i18n.export,
        onClick: exportStations,
    }]

    if (hydrobioList.length === 0) {
        return (
            <h5 className='center-align'>{i18n.noAssociatedStations}</h5>
        )
    }

    return (
        <div>
            <Card title={`${stationsPanelTitle} - ${stationsIds.length} ${i18n.stations}`} actions={actions}>
                <Grid2 container spacing={1} padding={1}>
                    <PaginatedList
                        nbElements={stationsIds.length}
                        nbElementsByPage={7}
                    >
                        {
                            (startId, endId) => {
                                const subList = stationsIds.slice(startId, endId)
                                return subList.map(id => {
                                    const stationHydrobios = groupByStations[id]
                                    const displayHydrobio = getDisplayedHydrobio(stationHydrobios)
                                    return (
                                        <Grid2 size={12} key={id}>
                                            <CollapsibleTable
                                                displayHydrobio={displayHydrobio}
                                                hydrobioList={stationHydrobios}
                                                isOnlyOneHydrobio={isOnlyOneHydrobio}
                                                thresholds={thresholds}
                                                openSampleModal={hydrobioId => {
                                                    setSelectedHydrobio(hydrobioId)
                                                    open()
                                                }}
                                            />
                                        </Grid2>
                                    )
                                })
                            }
                        }
                    </PaginatedList>
                </Grid2>
            </Card>
            <SampleModal
                isOpen={isOpen}
                close={close}
                analyse={hydrobio}
            />
        </div>
    )
}

CartographyHydrobiosPanel.propTypes = {
    stationsPanelTitle: PropTypes.string,
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualityThreshold)),
    hydrobioList: PropTypes.arrayOf(PropTypes.shape({
        // DtoSearchHydrobio ou DtoSearchHydrobioLight
        // calculateThresholdResult
    })),
    researchType: PropTypes.number,
    sortingType: PropTypes.string,
}

export default CartographyHydrobiosPanel