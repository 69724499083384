'use strict'
import PropTypes from 'prop-types'
import {
    DELETE_FILTER,
    DELETE_PIEZOMETER,
    RECEIVE_ASSOCIATED_SITES,
    RECEIVE_CONTRIBUTOR_LINKS,
    RECEIVE_EXPORT_JOBS_TMP,
    RECEIVE_EXTERNAL_SITES,
    RECEIVE_FILES_FTP,
    RECEIVE_FILTER_RESULTS,
    RECEIVE_FILTERS,
    RECEIVE_MAP_SITUATIONS,
    RECEIVE_MAP_SITUATIONS_RESULTS,
    RECEIVE_PIEZOMETER,
    RECEIVE_SITES_TYPES,
    RECEIVE_STATION_FILES,
    RECEIVE_STATION_PICTURES,
    RESET_STATION,
    SELECT_FILTER,
    UPDATE_PIEZOMETER,
    RESET_ASSOCIATED_SITES,
} from '../constants/StationConstants'
import { RECEIVE_ALL_WATERMASS, RECEIVE_STATION_WATERMASS } from '../constants/WaterMassConstants'
import { RECEIVE_ALL_PROJECTION, RECEIVE_PROJECTION } from '../constants/ProjectionConstants'
import { RECEIVE_ALL_NATURE, RECEIVE_NATURE } from '../constants/NatureConstants'
import { RECEIVE_ALL_NETWORK, RECEIVE_QUALITOMETER_NETWORK_ALL } from '../constants/NetworkConstants'
import {
    RECEIVE_ALTIMETRIC_SYSTEM_ALTIMETRIC_SYSTEM,
    RECEIVE_ALTIMETRIC_SYSTEM_NATURE,
    RECEIVE_ALTIMETRIC_SYSTEM_PRODUCTION_MODE,
} from '../constants/AltimetricSystemConstants'
import { RECEIVE_LANDMARKS_PRODUCTION_MODE } from '../constants/LandmarkConstants'
import { RECEIVE_ALL_QUALITOMETER_TYPE, RECEIVE_ALL_TYPE } from '../constants/QualitometerTypeConstants'
import { RECEIVE_ALL_STATE } from '../constants/StateConstants'
import { RECEIVE_ALL_QUALITY } from '../constants/QualityConstants'
import { RECEIVE_ALL_FIELD_MODE } from '../constants/FieldModeConstants'
import { RECEIVE_ALL_HYDROGEOLOGICAL_ENTITIES } from '../constants/HydrogeologicalEntityConstants'
import { RECEIVE_ALL_QUALITOMETER_REPRESENTIVENESS } from '../constants/QualitometerRepresentivenessConstants'
import {
    ADD_ALL_SELECTED_PROBLEM,
    ADD_ALL_SELECTED_SOLUTION,
    ADD_SELECTED_LINK,
    ADD_SELECTED_PROBLEM,
    RECEIVE_ALL_EVENT_PROBLEM_SOLUTION_LINKS,
    RECEIVE_ALL_EVENT_PROBLEMS,
    RECEIVE_ALL_EVENT_SOLUTIONS,
    REMOVE_SELECTED_PROBLEM,
    REMOVE_SELECTED_SOLUTION,
    RESET_SELECTED_PROBLEM,
    RESET_SELECTED_SOLUTION,
} from '../constants/ProblemSolutionEventConstants'

import AppStore from 'store/AppStore'

import DtoContributorLink from 'station/dto/DtoContributorLink'

import DtoProjection from '../dto/DtoProjection'
import DtoAltimetrySystemProductionMode from '../dto/DtoAltimetrySystemProductionMode'
import DtoAltimetrySystemNature from '../dto/DtoAltimetrySystemNature'
import DtoAltimetrySystemAltimetrySystem from '../dto/DtoAltimetrySystemAltimetrySystem'
import DtoLandmark from '../dto/DtoLandmark'
import DtoType from '../dto/DtoType'
import DtoState from '../dto/DtoState'
import DtoQuality from '../dto/DtoQuality'
import DtoFieldMode from '../dto/DtoFieldMode'
import DtoHydrogeologicalEntity from '../dto/DtoHydrogeologicalEntity'
import DtoQualitometerRepresentiveness from '../dto/DtoQualitometerRepresentiveness'
import DtoPicture from '../dto/DtoPicture'
import DtoFile from '../../components/file/dto/DtoFile'
import DtoAssociatedStation from '../dto/DtoAssociatedStation'
import DtoPiezometer from '../../piezometry/dto/DtoPiezometer'
import DtoSiteType from '../dto/sites/DtoSiteType'
import DtoSite from '../dto/sites/DtoSite'
import DtoFilter from '../dto/DtoFilter'
import WatermassDto from '../../referencial/components/watermass/dto/WatermassDto'
import StationAction from '../actions/StationAction'
import { isEqual, remove, uniqWith } from 'lodash'
import { arrayOf, instanceOf } from '../../utils/StoreUtils'
import DtoProblem from '../dto/sites/DtoProblem'
import DtoSolution from '../dto/sites/DtoSolution'
import DtoNature from '../dto/DtoNature'
import DtoProblemSolutionLink from '../dto/sites/DtoProblemSolutionLink'
import DtoFileFTP from '../dto/DtoFileFTP'
import {
    RECEIVE_STATION_CENTRAL,
    RECEIVE_STATION_CENTRAL_ASSIGNMENT,
    RECEIVE_STATION_CENTRAL_PATH,
    RECEIVE_STATION_CENTRAL_RANGE,
    RECEIVE_STATION_EQUIPMENT,
    RECEIVE_STATION_EQUIPMENT_ASSIGNMENT,
    RECEIVE_STATION_POWER_SUPPLY,
    RECEIVE_STATION_POWER_SUPPLY_ASSIGNMENT,
    RECEIVE_STATION_SENSOR,
    RECEIVE_STATION_SENSOR_ASSIGNMENT,
    RECEIVE_STATION_SIM,
    RECEIVE_STATION_SIM_ASSIGNMENT,
    RECEIVE_STATION_SUBSCRIPTION,
    RECEIVE_STATION_SUBSCRIPTION_ASSIGNMENT,
    RECEIVE_STATION_TELECOM,
    RECEIVE_STATION_TELECOM_ASSIGNMENT,
    RECEIVE_STATION_VARIOUS_MATERIEL,
    RECEIVE_STATION_VARIOUS_MATERIEL_ASSIGNMENT,
} from '../constants/StationMaterielConstant'
import DtoStationCentralAssignment from '../dto/materiel/DtoStationCentralAssignment'
import DtoCentral from '../dto/materiel/DtoCentral'
import DtoStationPowerSupplyAssignment from '../dto/materiel/DtoStationPowerSupplyAssignment'
import DtoPowerSupply from '../dto/materiel/DtoPowerSupply'
import DtoStationSensorAssignment from '../dto/materiel/DtoStationSensorAssignment'
import DtoSensor from '../dto/materiel/DtoSensor'
import DtoStationSimAssignment from '../dto/materiel/DtoStationSimAssignment'
import DtoStationTelecomAssignment from '../dto/materiel/DtoStationTelecomAssignment'
import DtoTelecom from '../dto/materiel/DtoTelecom'
import DtoStationVariousMaterielAssignment from '../dto/materiel/DtoStationVariousMaterielAssignment'
import DtoVariousMateriel from '../dto/materiel/DtoVariousMateriel'
import DtoCentralChannel from '../../materiel/components/central/dto/DtoCentralChannel'
import DtoCentralRange from '../../materiel/components/central/dto/DtoCentralRange'
import DtoSim from '../dto/materiel/DtoSim'
import DtoStationEquipmentAssignment from '../dto/materiel/DtoStationEquipmentAssignment'
import EquipmentWithMaterielsDto from '../../materiel/components/equipment/dto/EquipmentWithMaterielsDto'
import DtoStationSubscriptionAssignment from '../dto/materiel/DtoStationSubscriptionAssignment'
import DtoSubscription from '../dto/materiel/DtoSubscription'

import { RECEIVE_ALL_DATATYPES } from '../constants/DataTypeConstants'
import DtoDataType from '../dto/DtoDataType'
import DtoExportJob from '../dto/DtoExportJob'
import DtoMapSituation from '../components/mapSituation/dto/DtoMapSituation'
import DtoMapSituationResult from '../components/mapSituation/dto/DtoMapSituationResult'
import { DOCUMENT, PICTURE } from 'components/file/constant/FileConstants'

export const store = {
    nature: {},
    natures: [],
    stationWatermass: {},
    allWatermass: [],
    projections: [],
    networks: [],
    qualitometerContributors: [],
    locations: [],
    operations: [],
    altimetricSystemProductionMode: [],
    altimetricSystemNature: [],
    altimetricSystemAltimetricSystem: [],
    landmarks: [],
    qualitometerType: [],
    types: [],
    states: [],
    qualities: [],
    fieldModes: [],
    hydrogeologicalEntity: {},
    hydrogeologicalEntities: [],
    qualitometerRepresentiveness: [],
    pictures: [],
    files: [],
    piezometer: {},
    associatedSites: [],
    externalSites: [],
    sitesTypes: [],
    stationKMLExists: {},
    filters: [],
    filterResults: [],
    eventProblems: [],
    selectedProblems: [],
    problemSolutionLinks: [],
    eventSolutions: [],
    selectedLinks: [],
    contributorLinks: [],
    stationCentralAssignments: [],
    stationCentral: [],
    stationCentralPaths: [],
    stationCentralRanges: [],
    stationPowerSupplyAssignments: [],
    stationPowerSupply: [],
    stationSensorAssignments: [],
    stationSensor: [],
    stationSimAssignments: [],
    stationSim: [],
    stationTelecomAssignments: [],
    stationTelecom: [],
    stationVariousMaterielAssignments: [],
    stationVariousMateriel: [],
    stationEquipmentAssignments: [],
    stationEquipment: [],
    stationSubscriptionAssignments: [],
    stationSubscription: [],
    aquifers: [],
    filteredDataTypes: [],
    piezoDataTypes: [],
    hydroDataTypes: [],
    pluvioDataTypes: [],
    exportJobsTmp: [],
    filesFtp: [],
    mapSituations: [],
    mapSituationResults: [],
}

export function StationReducer(state = {}, action) {
    switch (action.type) {
        case SELECT_FILTER:
            return {
                ...state,
                filter: action.filter,
            }
        case RECEIVE_STATION_WATERMASS:
            return {
                ...state,
                stationWatermass: new WatermassDto(action.watermass),
            }
        case RECEIVE_ALL_WATERMASS:
            return {
                ...state,
                allWatermass: action.allWatermass,
            }
        case RECEIVE_PROJECTION:
            return {
                ...state,
                projection: action.projection,
            }
        case RECEIVE_ALL_PROJECTION:
            return {
                ...state,
                projections: action.projections.map(el => new DtoProjection(el)),
            }
        case RECEIVE_NATURE:
            return {
                ...state,
                nature: new DtoNature(action.nature),
            }
        case RECEIVE_ALL_NATURE:
            return {
                ...state,
                natures: action.natures.map(n => new DtoNature(n)),
            }
        case RECEIVE_QUALITOMETER_NETWORK_ALL:
            return {
                ...state,
                networks: action.networks,
            }
        case RECEIVE_ALL_NETWORK:
            return {
                ...state,
                networks: action.networks,
            }
        case RECEIVE_ALTIMETRIC_SYSTEM_PRODUCTION_MODE:
            return {
                ...state,
                altimetricSystemProductionMode: action.altimetricSystemProductionMode.map(el => new DtoAltimetrySystemProductionMode(el)),
            }
        case RECEIVE_ALTIMETRIC_SYSTEM_NATURE:
            return {
                ...state,
                altimetricSystemNature: action.altimetricSystemNature.map(el => new DtoAltimetrySystemNature(el)),
            }
        case RECEIVE_ALTIMETRIC_SYSTEM_ALTIMETRIC_SYSTEM:
            return {
                ...state,
                altimetricSystemAltimetricSystem: action.altimetricSystemAltimetricSystem.map(el => new DtoAltimetrySystemAltimetrySystem(el)),
            }
        case RECEIVE_LANDMARKS_PRODUCTION_MODE:
            return {
                ...state,
                landmarks: action.landmarks.map(el => new DtoLandmark(el)),
            }
        case RECEIVE_ALL_QUALITOMETER_TYPE:
            return {
                ...state,
                qualitometerType: action.qualitometerType.map(el => new DtoType(el)),
            }
        case RECEIVE_ALL_TYPE:
            return {
                ...state,
                types: action.types.map(el => new DtoType(el)),
            }
        case RECEIVE_ALL_STATE:
            return {
                ...state,
                states: action.states.map(el => new DtoState(el)),
            }
        case RECEIVE_ALL_QUALITY:
            return {
                ...state,
                qualities: action.qualities.map(el => new DtoQuality(el)),
            }
        case RECEIVE_ALL_FIELD_MODE:
            return {
                ...state,
                fieldModes: action.fieldModes.map(el => new DtoFieldMode(el)),
            }
        case RECEIVE_ALL_HYDROGEOLOGICAL_ENTITIES:
            return {
                ...state,
                hydrogeologicalEntities: action.hydrogeologicalEntities.map(el => new DtoHydrogeologicalEntity(el)),
            }
        case RECEIVE_ALL_QUALITOMETER_REPRESENTIVENESS:
            return {
                ...state,
                qualitometerRepresentiveness: action.qualitometerRepresentiveness.map(el => new DtoQualitometerRepresentiveness(el)),
            }
        case RECEIVE_STATION_PICTURES:
            return {
                ...state,
                pictures: action.pictures.map(el => new DtoFile(el, PICTURE)),
            }
        case RECEIVE_STATION_FILES:
            return {
                ...state,
                files: action.files.map(el => new DtoFile(el, DOCUMENT)),
            }
        case RECEIVE_PIEZOMETER:
            return {
                ...state,
                piezometer: new DtoPiezometer(action.piezo),
            }
        case UPDATE_PIEZOMETER:
            return {
                ...state,
                piezometer: new DtoPiezometer(action.piezometer),
            }
        case DELETE_PIEZOMETER:
            return {
                ...state,
                piezometer: {},
            }
        case RECEIVE_ASSOCIATED_SITES:
            return {
                ...state,
                associatedSites: action.sites.map(site => new DtoAssociatedStation(site)),
            }
        case RESET_ASSOCIATED_SITES:
            return {
                ...state,
                associatedSites: [],
            }
        case RECEIVE_EXTERNAL_SITES:
            return {
                ...state,
                externalSites: action.sites.map(site => new DtoSite(site)),
            }
        case RECEIVE_SITES_TYPES:
            return {
                ...state,
                sitesTypes: action.types.map(type => new DtoSiteType(type)),
            }
        case RECEIVE_FILTERS:
            return {
                ...state,
                filters: action.filters.map(filter => new DtoFilter(filter)),
            }
        case RECEIVE_FILTER_RESULTS:
            return {
                ...state,
                filterResults: action.filterResults,
            }
        case DELETE_FILTER:
            return {
                ...state,
                filters: state.filters.filter(f => !(f.module === action.filter.module && f.codeList === action.filter.code)),
            }
        case RECEIVE_ALL_EVENT_PROBLEMS:
            return {
                ...state,
                eventProblems: action.problems.map(t => new DtoProblem(t)),
            }
        case ADD_ALL_SELECTED_PROBLEM:
            return {
                ...state,
                selectedProblems: uniqWith([].concat(state.selectedProblems, action.problems), isEqual),
            }
        case ADD_SELECTED_PROBLEM:
            return {
                ...state,
                selectedProblems: [].concat(state.selectedProblems, action.problem),
            }
        case REMOVE_SELECTED_PROBLEM:
            const result = remove(state.selectedProblems, (o) => {
                return o.id != action.problem.id
            })
            return {
                ...state,
                selectedProblems: result,
            }
        case RESET_SELECTED_PROBLEM:
            return {
                ...state,
                selectedProblems: [],
            }
        case RECEIVE_ALL_EVENT_SOLUTIONS:
            return {
                ...state,
                eventSolutions: action.solutions.map(t => new DtoSolution(t)),
            }
        case RECEIVE_STATION_CENTRAL_ASSIGNMENT:
            return {
                ...state,
                stationCentralAssignments: action.stationCentralAssignments.map(el => new DtoStationCentralAssignment(el)),
            }
        case RECEIVE_STATION_CENTRAL:
            return {
                ...state,
                stationCentral: action.stationCentral.map(el => new DtoCentral(el)),
            }
        case RECEIVE_STATION_CENTRAL_PATH:
            return {
                ...state,
                stationCentralPaths: action.stationCentralPaths.map(el => new DtoCentralChannel(el)),
            }
        case RECEIVE_STATION_CENTRAL_RANGE:
            return {
                ...state,
                stationCentralRanges: action.stationCentralRanges.map(el => new DtoCentralRange(el)),
            }
        case RECEIVE_STATION_POWER_SUPPLY_ASSIGNMENT:
            return {
                ...state,
                stationPowerSupplyAssignments: action.stationPowerSupplyAssignments.map(el => new DtoStationPowerSupplyAssignment(el)),
            }
        case RECEIVE_STATION_POWER_SUPPLY:
            return {
                ...state,
                stationPowerSupply: action.stationPowerSupply.map(el => new DtoPowerSupply(el)),
            }
        case RECEIVE_STATION_SENSOR_ASSIGNMENT:
            return {
                ...state,
                stationSensorAssignments: action.stationSensorAssignments.map(el => new DtoStationSensorAssignment(el)),
            }
        case RECEIVE_STATION_SENSOR:
            return {
                ...state,
                stationSensor: action.stationSensor.map(el => new DtoSensor(el)),
            }
        case RECEIVE_STATION_SIM_ASSIGNMENT:
            return {
                ...state,
                stationSimAssignments: action.stationSimAssignments.map(el => new DtoStationSimAssignment(el)),
            }
        case RECEIVE_STATION_SIM:
            return {
                ...state,
                stationSim: action.stationSim.map(el => new DtoSim(el)),
            }
        case RECEIVE_STATION_TELECOM_ASSIGNMENT:
            return {
                ...state,
                stationTelecomAssignments: action.stationTelecomAssignments.map(el => new DtoStationTelecomAssignment(el)),
            }
        case RECEIVE_STATION_TELECOM:
            return {
                ...state,
                stationTelecom: action.stationTelecom.map(el => new DtoTelecom(el)),
            }
        case RECEIVE_STATION_VARIOUS_MATERIEL_ASSIGNMENT:
            return {
                ...state,
                stationVariousMaterielAssignments: action.stationVariousMaterielAssignments.map(el => new DtoStationVariousMaterielAssignment(el)),
            }
        case RECEIVE_STATION_VARIOUS_MATERIEL:
            return {
                ...state,
                stationVariousMateriel: action.stationVariousMateriel.map(el => new DtoVariousMateriel(el)),
            }
        case RECEIVE_STATION_EQUIPMENT_ASSIGNMENT:
            return {
                ...state,
                stationEquipmentAssignments: action.stationEquipmentAssignments.map(el => new DtoStationEquipmentAssignment(el)),
            }
        case RECEIVE_STATION_EQUIPMENT:
            return {
                ...state,
                stationEquipment: action.stationEquipment.map(el => new EquipmentWithMaterielsDto(el)),
            }
        case RECEIVE_STATION_SUBSCRIPTION_ASSIGNMENT:
            return {
                ...state,
                stationSubscriptionAssignments: action.stationSubscriptionAssignments.map(el => new DtoStationSubscriptionAssignment(el)),
            }
        case RECEIVE_STATION_SUBSCRIPTION:
            return {
                ...state,
                stationSubscription: action.stationSubscription.map(el => new DtoSubscription(el)),
            }
        case RECEIVE_ALL_EVENT_PROBLEM_SOLUTION_LINKS:
            return {
                ...state,
                problemSolutionLinks: action.problemSolutionLinks.map(l => new DtoProblemSolutionLink(l)),
            }
        case ADD_ALL_SELECTED_SOLUTION:
            return {
                ...state,
                selectedLinks: uniqWith([].concat(state.selectedLinks, action.solutionLinks), isEqual),
            }
        case ADD_SELECTED_LINK:
            return {
                ...state,
                selectedLinks: [].concat(state.selectedLinks, action.solutionLink),
            }
        case REMOVE_SELECTED_SOLUTION:
            const resultSolution = remove(state.selectedLinks, (o) => {
                return o.id != action.solutionLink.id
            })
            return {
                ...state,
                selectedLinks: resultSolution,
            }
        case RESET_SELECTED_SOLUTION:
            return {
                ...state,
                selectedLinks: [],
            }
        case RESET_STATION:
            return {
                ...state,
                ...store,
                filterResults: state.filterResults,
                mapSituations: state.mapSituations,
                mapSituationResults: state.mapSituationResults,
            }
        case RECEIVE_CONTRIBUTOR_LINKS:
            return {
                ...state,
                contributorLinks: action.contributorLinks.map(d => new DtoContributorLink(d)),
            }
        case RECEIVE_ALL_DATATYPES:
            return {
                ...state,
                filteredDataTypes: action.filteredDataTypes.map(d => new DtoDataType(d)),
                piezoDataTypes: action.piezo.map(d => new DtoDataType(d)),
                hydroDataTypes: action.hydro.map(d => new DtoDataType(d)),
                pluvioDataTypes: action.pluvio.map(d => new DtoDataType(d)),
            }
        case RECEIVE_EXPORT_JOBS_TMP:
            return {
                ...state,
                exportJobsTmp: action.exportJobsTmp.map(d => new DtoExportJob(d)),
            }
        case RECEIVE_FILES_FTP:
            return {
                ...state,
                filesFtp: action.filesFtp.map(s => new DtoFileFTP(s)),
            }
        case RECEIVE_MAP_SITUATIONS:
            return {
                ...state,
                mapSituations: action.data.map(s => new DtoMapSituation(s)),
            }
        case RECEIVE_MAP_SITUATIONS_RESULTS:
            return {
                ...state,
                mapSituationResults: action.data.map(s => new DtoMapSituationResult(s)),
            }
        default:
            return state
    }
}

export const StationStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        station: {
            reset: StationAction.resetStation,
        },
        piezometer: {
            fetch: StationAction.fetchPiezometer,
            store: appStore.StationReducer.piezometer,
            proptype: instanceOf(DtoPiezometer),
        },
        associatedSites: {
            fetch: StationAction.fetchAssociatedSites,
            store: appStore.StationReducer.associatedSites,
            propType: arrayOf(DtoAssociatedStation),
            add: StationAction.addAssociatedSite,
            delete: StationAction.deleteAssociatedSite,
        },
        filters: {
            fetch: StationAction.fetchFilters,
            store: appStore.StationReducer.filters,
            propType: arrayOf(DtoFilter),
        },
        filterResults: {
            fetch: StationAction.fetchFilterResults,
            store: appStore.StationReducer.filterResults,
            propType: arrayOf(PropTypes.number),
        },
        eventProblems: {
            fetch: StationAction.fetchProblems,
            store: appStore.StationReducer.eventProblems,
            propType: arrayOf(DtoProblem),
        },
        stationWatermass: {
            fetch: StationAction.fetchQualitomerWaterMass,
            store: appStore.StationReducer.stationWatermass,
            propType: instanceOf(WatermassDto),
        },
        pictures: {
            fetch: StationAction.fetchPictures,
            store: appStore.StationReducer.pictures,
            propType: arrayOf(DtoPicture),
        },
        natures: {
            fetch: StationAction.fetchNatures,
            store: appStore.StationReducer.natures,
            propType: arrayOf(DtoNature),
        },
        qualities: {
            fetch: StationAction.fetchQualities,
            store: appStore.StationReducer.qualities,
            propType: arrayOf(DtoQuality),
        },
        fieldModes: {
            fetch: StationAction.fetchFieldModes,
            store: appStore.StationReducer.fieldModes,
            propType: arrayOf(DtoFieldMode),
        },
        qualitometerTypes: {
            fetch: StationAction.fetchQualitometerType,
            store: appStore.StationReducer.qualitometerType,
            propType: arrayOf(DtoType),
        },
        qualitometerRepresentiveness: {
            fetch: StationAction.fetchQualitometerRepresentiveness,
            store: appStore.StationReducer.qualitometerRepresentiveness,
            propType: arrayOf(DtoQualitometerRepresentiveness),
        },
        waterSourceTypes: {
            fetch: StationAction.fetchTypes,
            store: appStore.StationReducer.types,
            propType: arrayOf(DtoType),
        },
        stationStates: {
            fetch: StationAction.fetchStates,
            store: appStore.StationReducer.states,
            propType: arrayOf(DtoState),
        },
    }
}
