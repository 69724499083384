/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import WatermassAction from 'referencial/components/watermass/actions/WatermassAction'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import StationDescriptionTable from './StationDescriptionTable'
import { Card, CardContent } from '@mui/material'
import Select from 'components/forms/Select'

const headers = ['watermass']

const WatermassDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const {
        watermasses,
    } = useSelector(store => ({
        watermasses: store.WatermassReducer.watermasses,
    }), shallowEqual)

    return (
        <Card>
            <CardContent>
                <Select
                    value={stateElement.watermassCode}
                    label={i18n.watermass}
                    options={watermasses}
                    keyValue='code'
                    displayWithCode
                    onChange={v => onChangeElement({ watermassCode: v })}
                />
            </CardContent>
        </Card>
    )
}

WatermassDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const StationWatermassPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const dispatch = useDispatch()

    const {
        watermasses,
    } = useSelector(store => ({
        watermasses: store.WatermassReducer.watermasses,
    }), shallowEqual)

    useEffect(() => {
        if (!watermasses.length) {
            dispatch(WatermassAction.fetchWatermasses())
        }
    }, [])

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_watermasses'
            formatFunction={w => {
                const watermass = watermasses.find(ws => ws.code === w.watermassCode)
                return {
                    ...w,
                    watermass: `${watermass?.name || ''} [${watermass?.code || ''}]`,
                }
            }}
            titles={{
                title: i18n.watermasses,
                edit: i18n.editWatermass,
                new: i18n.newWatermass,
            }}
            headers={headers}
            dialogContent={WatermassDialogContent}
        />
    )
}

StationWatermassPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default StationWatermassPanel