module.exports = {
    RECEIVE_PIEZOMETER_MEASURES: 'RECEIVE_PIEZOMETER_MEASURES',
    RECEIVE_PIEZOMETER_PICTURES: 'RECEIVE_PIEZOMETER_PICTURES',
    RECEIVE_PIEZOMETER_FILES: 'RECEIVE_PIEZOMETER_FILES',
    RESET_PIEZOMETER_STATION: 'RESET_PIEZOMETER_STATION',
    RECEIVE_PIEZOMETER_SAMPLES: 'RECEIVE_PIEZOMETER_SAMPLES',
    RECEIVE_PIEZOMETER_INDICATORS: 'RECEIVE_PIEZOMETER_INDICATORS',
    RECEIVE_PIEZOMETER_MEASURES_BRUTE: 'RECEIVE_PIEZOMETER_MEASURES_BRUTE',
    RECEIVE_ALL_PIEZOMETER_THRESHOLDS: 'RECEIVE_ALL_PIEZOMETER_THRESHOLDS',
    RECEIVE_PIEZOMETER_THRESHOLDS: 'RECEIVE_PIEZOMETER_THRESHOLDS',
    RECEIVE_PIEZOMETER_ADDITIONAL_MEASURES: 'RECEIVE_PIEZOMETER_ADDITIONAL_MEASURES',
    RECEIVE_MULTIPLE_PIEZOMETERS_ADDITIONAL_MEASURES: 'RECEIVE_MULTIPLE_PIEZOMETERS_ADDITIONAL_MEASURES',
    UPDATE_PIEZOMETER_MEASURES: 'UPDATE_PIEZOMETER_MEASURES',
    DELETE_PIEZOMETER_MEASURES: 'DELETE_PIEZOMETER_MEASURES',
    UPDATE_PIEZOMETER_ADDITIONAL_MEASURES: 'UPDATE_PIEZOMETER_ADDITIONAL_MEASURES',
    UPDATE_PIEZOMETER_THRESHOLDS: 'UPDATE_PIEZOMETER_THRESHOLDS',
    RECEIVE_PIEZOMETER_CHART_OPTIONS: 'RECEIVE_PIEZOMETER_CHART_OPTIONS',
    UPDATE_PIEZOMETER_CHART_OPTIONS: 'UPDATE_PIEZOMETER_CHART_OPTIONS',
    RECEIVE_PIEZOMETER_CHART_MEASURES: 'RECEIVE_PIEZOMETER_CHART_MEASURES',
    UPDATE_PIEZOMETER_MEASURES_BRUTE: 'UPDATE_PIEZOMETER_MEASURES_BRUTE',
    DELETE_PIEZOMETER_MEASURES_BRUTE: 'DELETE_PIEZOMETER_MEASURES_BRUTE',
    RECEIVE_PIEZOMETER_STATISTICS: 'RECEIVE_PIEZOMETER_STATISTICS',
    RECEIVE_PIEZO_VALIDATION_CHART_MEASURES: 'RECEIVE_PIEZO_VALIDATION_CHART_MEASURES',
    SPI_TYPE_ID: 1,
    PIEZOMETER_SPI_TRENDS: [
        { code: 0, label: 'en hausse', trendImg: 'VERY_GOOD', imgLabel: 'up' },
        { code: 1, label: 'en baisse', trendImg: 'NOT_GOOD', imgLabel: 'down' },
        { code: 2, label: 'stable', trendImG: 'GOOD', imgLabel: 'right' },
        { code: 3, label: 'autre', trendImg: 'UNKNOWN', imgLabel: 'right' },
    ],
    PIEZOMETER_SPI_CLASS: [
        { code: 1, label: 'dry10y', color: 'RED', imgColor: 'red', textColor: 'white' },
        { code: 2, label: 'dry5y', color: 'ORANGE', imgColor: 'orange', textColor: 'black' },
        { code: 3, label: 'dry25y5', color: 'YELLOW', imgColor: 'yellow', textColor: 'black' },
        { code: 4, label: 'dry25yWet25y', color: 'GREEN', imgColor: 'green', textColor: 'white' },
        { code: 5, label: 'wet25y5', color: 'LIGHTBLUE', imgColor: 'blue', textColor: 'black' },
        { code: 6, label: 'wet5y10', color: 'BLUE', imgColor: 'purple', textColor: 'white' },
        { code: 7, label: 'wet10y', color: 'DARKBLUE', imgColor: 'bluedark', textColor: 'white' },
    ],
    PIEZOMETER_THRESHOLD_CLASS: [
        { code: 0, label: '', color: 'BLACK', imgColor: 'black', textColor: 'white' },
        { code: 1, label: '', color: 'BLUE', imgColor: 'blue', textColor: 'white' },
        { code: 2, label: '', color: 'GREEN', imgColor: 'green', textColor: 'white' },
        { code: 3, label: '', color: 'CYAN', imgColor: 'cyan', textColor: 'white' },
        { code: 4, label: '', color: 'RED', imgColor: 'red', textColor: 'white' },
        { code: 5, label: '', color: 'DARKMAGENTA', imgColor: 'darkmagenta', textColor: 'white' },
        { code: 6, label: '', color: 'YELLOW', imgColor: 'yellow', textColor: 'black' },
        { code: 7, label: '', color: 'WHITE', imgColor: 'white', textColor: 'black' },
        { code: 8, label: '', color: 'GREY', imgColor: 'grey', textColor: 'white' },
        { code: 9, label: '', color: 'LIGHTBLUE', imgColor: 'lightblue', textColor: 'white' },
        { code: 10, label: '', color: 'LIGHTGREEN', imgColor: 'lightgreen', textColor: 'white' },
        { code: 11, label: '', color: 'LIGHTCYAN', imgColor: 'lightcyan', textColor: 'white' },
        { code: 12, label: '', color: 'INDIANRED', imgColor: 'indianred', textColor: 'white' },
        { code: 13, label: '', color: 'MAGENTA', imgColor: 'magenta', textColor: 'white' },
        { code: 14, label: '', color: 'LIGHTYELLOW', imgColor: 'lightyellow', textColor: 'black' },
        { code: 15, label: '', color: 'WHITESMOKE', imgColor: 'whitesmoke', textColor: 'black' },
    ],
    MAP_SITUATION_CLASS: [ // DON'T CHANGE THIS ORDER !!!
        { code: null, order: 15, label: '', color: 'BLUE', imgColor: 'deepskyblue', textColor: 'white', chartColor: '#b8b8ff' },
        { code: null, order: 4, label: '', color: 'RED', imgColor: 'red', textColor: 'white', chartColor: '#ff9494' },
        { code: null, order: 7, label: '', color: 'ORANGE', imgColor: 'orange', textColor: 'white', chartColor: '#ffe6b8' },
        { code: null, order: 12, label: '', color: 'GREEN', imgColor: 'limegreen', textColor: 'white', chartColor: '#b8dcb8' },
        { code: null, order: 16, label: '', color: 'DARKBLUE', imgColor: 'darkblue', textColor: 'white', chartColor: '#9494ce' },
        { code: null, order: 8, label: '', color: 'YELLOW', imgColor: 'yellow', textColor: 'black', chartColor: '#ffffb8' },
        { code: null, order: 13, label: '', color: 'DARKGREEN', imgColor: 'darkgreen', textColor: 'white', chartColor: '#94be94' },
        { code: null, order: 6, label: '', color: 'BROWN', imgColor: 'brown', textColor: 'white', chartColor: '#d29b9b' },
        { code: null, order: 17, label: '', color: 'PURPLE', imgColor: 'purple', textColor: 'white', chartColor: '#dcb8dc' },
        { code: null, order: 18, label: '', color: 'DARKMAGENTA', imgColor: 'darkmagenta', textColor: 'white', chartColor: '#ae66ad' },
        { code: null, order: 10, label: '', color: 'WHITE', imgColor: 'white', textColor: 'black', chartColor: '#ffffff' },
        { code: null, order: 1, label: '', color: 'BLACK', imgColor: 'black', textColor: 'white', chartColor: '#b8b8b8' },
        { code: null, order: 11, label: '', color: 'LIGHTGREEN', imgColor: 'lightgreen', textColor: 'black', chartColor: '#e0fbe0' },
        { code: null, order: 2, label: '', color: 'PINK', imgColor: 'pink', textColor: 'white', chartColor: '#ffc0cb66' },
        { code: null, order: 14, label: '', color: 'LIGHTBLUE', imgColor: 'lightblue', textColor: 'black', chartColor: '#afe2f3' },
        { code: null, order: 3, label: '', color: 'MAGENTA', imgColor: 'magenta', textColor: 'white', chartColor: '#dfb8df' },
        { code: null, order: 5, label: '', color: 'INDIANRED', imgColor: 'indianred', textColor: 'white', chartColor: '#cd5c5c' },
        { code: null, order: 9, label: '', color: 'LIGHTYELLOW', imgColor: 'lightyellow', textColor: 'black', chartColor: '#ffffe0' },
    ],
    MAP_SITUATION_TYPE: [ // DON'T CHANGE THIS ORDER !!!
        { code: 1, label: 'piezometerSPI' },
        { code: 2, label: 'thresholdsIndicators' },
        { code: 3, label: 'frequencyStats' },
        { code: 4, label: 'lowWaterOnde' },
    ],
}
