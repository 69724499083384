import AdministrationAction from 'administration/actions/AdministrationAction'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import { setActions } from 'components/ActionUtil'
import Card from 'components/card/Card'
import Select from 'components/forms/Select'
import SieauAction from 'components/sieau/SieauAction'
import { PATH_ADMINISTRATION, PATH_ADMINISTRATION_PARAMETERS } from 'home/constants/RouteConstants'
import { sortBy, uniqBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { nbPerPageLabelMedium } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import PopupAddParameter from './PopupAddParameter'
import Input from 'components/forms/Input'
import { Button, CardContent, Grid2 } from '@mui/material'
import { searchAllCharacters } from 'utils/StringUtil'
import PropTypes from 'prop-types'
import { NewTable } from '../../../components/datatable/NewTable'
import ParameterDto from '../../../referencial/components/parameter/dto/ParameterDto'

const Filter = ({
    filterParent,
    parameters,
    changeFilterParent,
}) => {
    const [filter, setFilter] = useState(filterParent)
    return (
        <Card>
            <CardContent>
                <Grid2 container spacing={1} justifyContent={'space-between'} alignItems='center'>
                    <Grid2 size={4}>
                        <Input
                            title={ i18n.search }
                            value={ filter.searchValue }
                            onChange={ v => setFilter(prev => ({ ...prev, searchValue: v })) }
                            onEnterKeyPress={ v => changeFilterParent({ ...filter, searchValue: v }) }
                            data-cy={'search_input'}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <Select
                            label={ i18n.parameters }
                            value={ filter.param }
                            options={ parameters }
                            onChange={ v => setFilter(prev => ({ ...prev, param: v })) }
                            returnNull={ true }
                            keyValue='parameter'
                            keyLabel='parameter'
                        />
                    </Grid2>
                    <Grid2 size={4} spacing={1} container justifyContent='flex-end'>
                        <Grid2>
                            <Button
                                variant='outlined'
                                onClick={() => {
                                    setFilter({})
                                    changeFilterParent({})
                                }}
                                data-cy={'reset_button'}
                            >
                                {i18n.reinit}
                            </Button>
                        </Grid2>
                        <Grid2>
                            <Button
                                variant='contained'
                                onClick={() => changeFilterParent(filter)}
                                data-cy={'search_button'}
                            >
                                {i18n.search}
                            </Button>
                        </Grid2>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}
Filter.propTypes = {
    filterParent: PropTypes.shape({
        searchValue: PropTypes.string,
    }),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    changeFilterParent: PropTypes.func,
}

const AdminParameterApp = () => {
    const {
        globalParameters,
        login,
    } = useSelector(store => ({
        globalParameters: store.AdministrationReducer.globalParameters,
        login: store.AccountReducer.accountUser.login,
    }), shallowEqual)

    const [editMode, setEditMode] = useState(false)
    const [openAddModale, setOpenAddModale] = useState(false)
    const [openEditModale, setOpenEditModale] = useState(false)
    const [parameter, setParameter] = useState({})
    const [parameters, setParameters] = useState([])
    const [filter, setFilter] = useState({})

    const dispatch = useDispatch()

    const headers = ['parameter', 'module', 'updateDate', 'value', 'updateLogin']

    const exportParameters = () => {
        const parametersToExport = parameters.map((param, i) => {
            return i === 0 ? { headers, ...param } : param
        })
        return {
            data: parametersToExport,
            exportType: 'xlsx',
            titleFile: i18n.parameters,
        }
    }

    useEffect(() => {
        dispatch(AdministrationAction.fetchGlobalParameters())
    }, [])

    useEffect(() => {
        setParameters(globalParameters.map(param => ({
            ...param,
            updateDate: param.updateDate ? moment(param.updateDate).format('DD/MM/YYYY') : '',
        })))
    }, [globalParameters])

    useEffect(() => {
        dispatch(SieauAction.forceFetch('title', [{
            title: i18n.administration,
            href: PATH_ADMINISTRATION,
        }, {
            title: i18n.parameters,
            href: PATH_ADMINISTRATION_PARAMETERS,
        }]))
        const actions = () => {
            if (editMode) {
                return {
                    new: () => setOpenAddModale(true),
                    cancel: () => setEditMode(false),
                    export: () => exportParameters(),
                }
            }
            return {
                edit: () => setEditMode(true),
                export: () => exportParameters(),
            }
        }
        setActions(actions())
    })

    const onDelete = (deleteParam) => {
        const newParams = parameters.filter(param => param !== deleteParam)
        setParameters(newParams)
        dispatch(AdministrationAction.deleteGlobalParameter(new GlobalParametersDto({
            parameter: deleteParam.parameter,
            module: deleteParam.module,
            value: deleteParam.value,
            updateDate: moment(deleteParam.value).valueOf(),
            updateLogin: deleteParam.updateLogin,
        })))
    }

    const onAdd = (addParam) => {
        dispatch(AdministrationAction.createGlobalParameter(new GlobalParametersDto({
            parameter: addParam.parameter,
            module: addParam.module,
            value: addParam.value,
        })))
        setParameters([...parameters, addParam])
        setOpenAddModale(false)
    }

    const onEdit = (editParameter) => {
        setParameter(editParameter)
        setOpenEditModale(true)
    }

    const onUpdate = (updateParam) => {
        const updateParameters = parameters.filter(param => param !== parameter)
        const param = new GlobalParametersDto({
            parameter: updateParam.parameter,
            module: updateParam.module,
            value: updateParam.value,
            updateDate: moment().valueOf(),
            updateLogin: login,
        })
        setParameters([...updateParameters, { ...param, updateDate: moment(param.updateDate).format('DD/MM/YYYY') }])
        dispatch(AdministrationAction.updateGlobalParameter(param))
        setOpenEditModale(false)
    }

    const optionsParameters = sortBy(uniqBy(parameters, 'parameter'), 'parameter')

    const parametersFiltered = useMemo(() => {
        const filterSearch = filter.searchValue ? parameters.filter(p => headers.some((k) => searchAllCharacters(p[k])?.includes(searchAllCharacters(filter.searchValue)))) : parameters
        return filter.param ? filterSearch.filter(p => p.parameter === filter.param) : filterSearch
    }, [parameters, filter, headers])

    return (
        <Grid2 container spacing={1} sx={{ padding: '10 10 100 10' }}>
            <Grid2 size={12}>
                <Filter
                    filterParent={ filter }
                    parameters={ optionsParameters }
                    changeFilterParent={v => setFilter(v)}
                />
            </Grid2>
            <Grid2 size={12}>
                <NewTable
                    headers={headers}
                    rowsPerPageOptions={nbPerPageLabelMedium}
                    rows={parametersFiltered}
                    data-cy={'parameter_table'}
                    lineActions={[
                        { icon: 'delete', onClick: onDelete, displayed: editMode },
                        { icon: 'edit', onClick: onEdit, displayed: editMode },
                    ]}
                />
            </Grid2>
            { openAddModale && (
                <PopupAddParameter
                    openDialog={openAddModale}
                    addParameter={onAdd}
                    closeDialog={() => setOpenAddModale(false)}
                />
            )}
            { openEditModale && (
                <PopupAddParameter
                    parameter={ parameter }
                    openDialog={openEditModale}
                    addParameter={onUpdate}
                    closeDialog={() => setOpenEditModale(false)}
                />
            )}
        </Grid2>

    )
}

export default AdminParameterApp
