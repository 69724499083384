/* eslint-disable react/forbid-prop-types */
import { Button, Grid2, Icon, IconButton, Popover, Tooltip } from '@mui/material'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import RadioButtons from 'components/forms/RadioButtons'
import Select from 'components/forms/Select'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import React, { useMemo, useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import useBoolean from 'utils/customHook/useBoolean'
import PropTypes from 'prop-types'
import {
    SUM_AUTO,
    ALL,
    SUM_HOUR,
    SUM_DAY,
    SUM_WEEK,
    SUM_DECADE,
    SUM_MONTH,
    SUM_YEAR,
    CUMUL,
    DAY,
    HOUR,
    CUMUL_PERSO_MAX,
    CUMUL_PERSO_MIN,
    CUMUL_PERSO_SUM,
    CUMUL_PERSO_AVERAGE,
} from 'pluviometry/constants/PluvioOptionConstant'

const PluvioCumulOption = ({
    defaultGroupType = SUM_AUTO,
    onChangeGroupType = () => {},
    disabled = false,
}) => {
    const {
        defaultOptionTime,
        defaultNbTime,
        defaultCumul,
    } = useMemo(() => {
        if (!defaultGroupType.startsWith(CUMUL)) {
            return {}
        }
        const [c, p, o, nbHourString] = defaultGroupType.split('_')
        const nbHour = parseInt(nbHourString)
        if (isNaN(nbHour)) {
            return {}
        }
        const isDay = nbHour % 24 === 0
        return {
            defaultOptionTime: isDay ? DAY : HOUR,
            defaultNbTime: isDay ? nbHour / 24 : nbHour,
            defaultCumul: `${c}_${p}_${o}`,
        }
    }, [defaultGroupType])

    const [groupType, setGroupType] = useState(defaultGroupType)
    const [optionTime, setOptionTime] = useState(defaultOptionTime)
    const [nbTime, setNbTime] = useState(defaultNbTime)
    const [cumul, setCumul] = useState(defaultCumul)


    const optionDayHour = useMemo(() => [
        { value: HOUR, label: i18n.hour },
        { value: DAY, label: i18n.day },
    ], [])

    const optionCumul = useMemo(() => [
        { value: CUMUL_PERSO_MAX, label: i18n.max },
        { value: CUMUL_PERSO_MIN, label: i18n.min },
        { value: CUMUL_PERSO_SUM, label: i18n.sum },
        { value: CUMUL_PERSO_AVERAGE, label: i18n.average },
    ], [])

    const checkCumul = groupType.startsWith(CUMUL)

    return (
        <Grid2 container direction='column' style={{ width: '350px', padding: '5' }}>
            <Grid2>
                <h5 className='center'>{i18n.pluvioTotal}</h5>
            </Grid2>
            <Grid2 container>
                <Grid2 size='auto'>
                    <Checkbox
                        checked={groupType === SUM_AUTO}
                        label={i18n.auto}
                        disabled={disabled}
                        onChange={() => {
                            onChangeGroupType(SUM_AUTO)
                            setGroupType(SUM_AUTO)
                        }}
                    />
                </Grid2>
                <Grid2 size='auto'>
                    <Tooltip
                        title={(
                            <span style={{ whiteSpace: 'pre-wrap' }}>
                                {i18n.descriptionCumulAutoPluvio}
                            </span>
                        )}
                    >
                        <Icon sx={{ fontSize: 18, color: 'grey' }}>info</Icon>
                    </Tooltip>
                </Grid2>
            </Grid2>
            <Grid2>
                <Checkbox
                    checked={groupType === ALL}
                    label={i18n.allMeasures}
                    disabled={disabled}
                    onChange={() => {
                        onChangeGroupType(ALL)
                        setGroupType(ALL)
                    }}
                />
            </Grid2>
            <Grid2>
                <Checkbox
                    checked={groupType === SUM_HOUR}
                    label={i18n.hourTotal}
                    disabled={disabled}
                    onChange={() => {
                        onChangeGroupType(SUM_HOUR)
                        setGroupType(SUM_HOUR)
                    }}
                />
            </Grid2>
            <Grid2>
                <Checkbox
                    checked={groupType === SUM_DAY}
                    label={i18n.dailyTotal}
                    disabled={disabled}
                    onChange={() => {
                        onChangeGroupType(SUM_DAY)
                        setGroupType(SUM_DAY)
                    }}
                />
            </Grid2>
            <Grid2>
                <Checkbox
                    checked={groupType === SUM_WEEK}
                    label={i18n.weeklyTotal}
                    disabled={disabled}
                    onChange={() => {
                        onChangeGroupType(SUM_WEEK)
                        setGroupType(SUM_WEEK)
                    }}
                />
            </Grid2>
            <Grid2>
                <Checkbox
                    checked={groupType === SUM_DECADE}
                    label={i18n.decadeTotal}
                    disabled={disabled}
                    onChange={() => {
                        onChangeGroupType(SUM_DECADE)
                        setGroupType(SUM_DECADE)
                    }}
                />
            </Grid2>
            <Grid2>
                <Checkbox
                    checked={groupType === SUM_MONTH}
                    label={i18n.monthlyTotal}
                    disabled={disabled}
                    onChange={() => {
                        onChangeGroupType(SUM_MONTH)
                        setGroupType(SUM_MONTH)
                    }}
                />
            </Grid2>
            <Grid2>
                <Checkbox
                    checked={groupType === SUM_YEAR}
                    label={i18n.yearTotal}
                    disabled={disabled}
                    onChange={() => {
                        onChangeGroupType(SUM_YEAR)
                        setGroupType(SUM_YEAR)
                    }}
                />
            </Grid2>

            <Grid2>
                <Checkbox
                    checked={checkCumul}
                    label={i18n.pluvioTotalPersonalized}
                    disabled={disabled}
                    onChange={() => setGroupType(CUMUL)}
                />
            </Grid2>
            <Grid2>
                <StyledFieldSet>
                    <StyledLegend>{i18n.personalizedAccumulation}</StyledLegend>
                    <Grid2 container direction='column' rowSpacing={1}>
                        <Grid2>
                            <RadioButtons
                                colOption={6}
                                label={i18n.modeDaysHours}
                                elements={optionDayHour}
                                selected={optionTime}
                                onChange={setOptionTime}
                                disabled={!checkCumul || disabled}
                            />
                        </Grid2>
                        <Grid2>
                            <Select
                                label={i18n.modeCumul}
                                options={optionCumul}
                                value={cumul}
                                onChange={setCumul}
                                disabled={!checkCumul || disabled}
                            />
                        </Grid2>
                        <Grid2>
                            <Input
                                title={i18n.numberHoursDays}
                                value={nbTime}
                                onChange={setNbTime}
                                disabled={!checkCumul || disabled}

                            />
                        </Grid2>
                        <Grid2 align='center'>
                            <Button
                                onClick={() => {
                                    const pluvioGrouped = `${cumul}_${optionTime === DAY ? nbTime * 24 : nbTime}`
                                    onChangeGroupType(pluvioGrouped)
                                }}
                                variant='outlined'
                                disabled={!checkCumul || disabled}
                            >
                                {i18n.calculate}
                            </Button>
                        </Grid2>
                    </Grid2>
                </StyledFieldSet>
            </Grid2>
        </Grid2>
    )
}

PluvioCumulOption.propTypes = {
    defaultGroupType: PropTypes.string,
    onChangeGroupType: PropTypes.func,
    disabled: PropTypes.bool,
}

const PluvioCumulOptionIcon = ({
    defaultGroupType,
    onChangeGroupType,
    color = '#161832',
    disabled = false,
    'data-cy': dataCy,
}) => {
    const {
        value: isPluvioCumulOptionOpen,
        toggle: togglePluvioCumulOption,
    } = useBoolean(false)
    const anchorEl = useRef()

    return (
        <>
            <Tooltip title={i18n.pluvioTotal}>
                <IconButton
                    sx={{ fontSize: '32px' }}
                    onClick={togglePluvioCumulOption}
                    data-cy={dataCy}
                    ref={anchorEl}
                >
                    <Icon fontSize='inherit' sx={{ color }}>settings</Icon>
                </IconButton>
            </Tooltip>
            <Popover
                open={isPluvioCumulOptionOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={togglePluvioCumulOption}
            >
                <PluvioCumulOption
                    defaultGroupType={defaultGroupType}
                    onChangeGroupType={onChangeGroupType}
                    disabled={disabled}
                />
            </Popover>
        </>
    )
}

PluvioCumulOptionIcon.propTypes = {
    defaultGroupType: PropTypes.string,
    onChangeGroupType: PropTypes.func,
    disabled: PropTypes.bool,
    color: PropTypes.string,
    'data-cy': PropTypes.string,
}

export { PluvioCumulOptionIcon, PluvioCumulOption }