import PopupFactory from '../../../utils/mapUtils/PopupFactory'
import { getSiteType } from '../../../utils/mapUtils/SiteTypes'
import SingleStationPoint from '../../../utils/mapUtils/layers/SingleStationPoint'
import { allLayersProps } from '../constants/MapLayersConstants'
import { flatten, groupBy, uniqBy } from 'lodash'
import { STATION_NAME_ASSOCIATION } from '../../../station/constants/StationConstants'
import KML from '../../../utils/mapUtils/layers/KML'
import WMS from '../../../utils/mapUtils/layers/WMS'
import SitesPoint from '../../../utils/mapUtils/layers/SitesPoint'
import { hasValue } from '../../../utils/NumberUtil'
import { filterStationCoordinates, filterStationsCoordinates } from '../../../utils/mapUtils/CoordinateUtils'
import { getComponentWithId } from '../../../utils/StoreUtils'
import { parseNowVariableURL } from '../../../utils/UrlUtils'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'


const addLayersIfPossible = (props, layersGroup, layerIndexes, layersToAdd) => {
    layersToAdd.map(layerName => {
        const layerProps = allLayersProps(props, props.station)[layerName]
        if (layerProps.serviceUrl && layerProps.shouldAdd) {
            const layer = layerProps.layerType({
                url: layerProps.serviceUrl(layerProps.codeToFetch),
                style: layerProps.color,
                headers: true,
                popup: PopupFactory.popupKmlCity,
                extractStyles: !layerProps.color,
            })
            layersGroup.push(layer)
            layerIndexes[layerName] = layersGroup.length - 1
        }
    })
}

const setStationPointScale = (station, huge, layerName = undefined) => {
    getComponentWithId('#sieau-map').setPointScale(station, huge, layerName)
}

const setStationsOpacity = (stations, opacity) => {
    getComponentWithId('#sieau-map').setPointsOpacity(stations, opacity)
}

const setAndReturnLayerOpacity = (layerName, opacity) => {
    return getComponentWithId('#sieau-map').setAndReturnLayerOpacity(layerName, opacity)
}

const setCartographyBackground = (backgroundType) => {
    return getComponentWithId('#sieau-map').setCartographyBackground(backgroundType)
}

const addStationPoint = (props, layersGroup) => {
    const station = props.station
    if (props.exists.STATION) {
        const stationWithCoordinates = filterStationCoordinates(station, props.citiesIndex)

        if (stationWithCoordinates) {
            layersGroup.push(
                SingleStationPoint({
                    site: stationWithCoordinates,
                    type: getSiteType(STATION_NAME_ASSOCIATION[stationWithCoordinates.typeName], 1),
                }),
            )
        }
    }
}

const addAssociatedStationPoints = (props, layersGroup, layerIndexes, stationsNumber) => {
    if (props.exists.STATIONS_POINTS && props.stationsPoints.length !== 0) {
        if (props.analysisMode) {
            const layer = SitesPoint({
                site: filterStationsCoordinates(props.stationsPoints, props.citiesIndex),
                typeParams: [STATION_NAME_ASSOCIATION.quality, stationsNumber],
                visible: true,
            })
            layersGroup.push(layer)
            layerIndexes[STATION_NAME_ASSOCIATION.quality] = layersGroup.length - 1
        } else {
            const associatedStations = props.colorMode && props.stationsPoints[0].color ?
                groupBy(props.stationsPoints.map(station => Object.assign({}, station, { color: `${STATION_NAME_ASSOCIATION[station.typeName || 'quality']}_${station.color.toUpperCase()}` })), 'color')
                : groupBy(props.stationsPoints, 'typeName')
            Object.keys(associatedStations).forEach(type => {
                const stationsWithCoordinates = filterStationsCoordinates(associatedStations[type], props.citiesIndex)
                if (type === 'quality') {
                    const qualityGroups = groupBy(stationsWithCoordinates, 'stationType')
                    Object.keys(qualityGroups).forEach(qualityType => {
                        const layerName = `3_${qualityType}`
                        if (props.exists[layerName]) {
                            const layer = SitesPoint({
                                site: qualityGroups[qualityType],
                                typeParams: [layerName, stationsNumber],
                                visible: props.visible[layerName],
                            })
                            layersGroup.push(layer)
                            layerIndexes[layerName] = layersGroup.length - 1
                        }
                    })
                } else if (type === 'installation') {
                    const installationGroups = groupBy(stationsWithCoordinates, 'installationType')
                    Object.keys(installationGroups).forEach(installationType => {
                        const layerName = `7_${installationType}`
                        if (props.exists[layerName]) {
                            const layer = SitesPoint({
                                site: installationGroups[installationType],
                                typeParams: [layerName, stationsNumber],
                                visible: props.visible[layerName],
                            })
                            layersGroup.push(layer)
                            layerIndexes[layerName] = layersGroup.length - 1
                        }
                    })
                } else {
                    const layerName = STATION_NAME_ASSOCIATION[type] || type
                    if (props.exists[layerName]) {
                        const layer = SitesPoint({
                            site: stationsWithCoordinates,
                            typeParams: [layerName, stationsNumber],
                            visible: props.visible[layerName],
                        })
                        layersGroup.push(layer)
                        layerIndexes[layerName] = layersGroup.length - 1
                    }
                }
            })
        }
    }
}

const addExternalSites = (layersGroup1, layerIndexes, props, stationsNumber) => {
    if (props.exists.POLLUTED_SOIL || props.exists.INDUSTRIAL_SITE) {
        const groupedSites = groupBy(props.externalSites, 'type_id')
        Object.keys(groupedSites).map(typeId => {
            const type = props.sitesTypes.find(elem => elem.id === parseInt(typeId)).parameter
            const layer = SitesPoint({
                site: groupedSites[typeId],
                typeParams: [STATION_NAME_ASSOCIATION[type] || type, stationsNumber],
            })
            layersGroup1.push(layer)
            layerIndexes[type] = layersGroup1.length - 1
        })
    }
}

const getLayer = (layer, visible, setting) => {
    if (layer && hasValue(layer.type)) {
        const color = layer.color ? layer.color : false
        switch (parseInt(layer.typeLayer)) {
            case 0 :
                return WMS({
                    url: parseNowVariableURL(layer.url, setting),
                    params: { layers: layer.layer },
                    layerParam: layer.layer,
                    opacity: layer.opacity,
                    projection: layer.projection,
                    visible,
                })
            case 1 :
                return KML({
                    url: parseNowVariableURL(layer.url, setting),
                    headers: true,
                    style: color,
                    popup: PopupFactory.popupKmlCity,
                    extractStyles: !color,
                })
            default:
                return null
        }
    }
    return null
}

const addThemeLayers = (props, layersGroup, layerIndexes, setting) => {
    uniqBy(props.themeLayers, 'layer').map(layer => {
        if (props.exists[layer.layer]) {
            const createdLayer = getLayer(layer, props.visible[layer.layer], setting)
            if (createdLayer) {
                layersGroup.push(createdLayer)
                layerIndexes[layer.layer] = layersGroup.length - 1
            }
        }
    })
}

const addOneThemeLayer = (props, layersGroup, layerIndexes, setting) => {
    if (props.exists.ONE_THEME_LAYER && props.oneThemeLayer.name && props.oneThemeLayer.url && props.oneThemeLayer.layer) {
        const createdLayer = getLayer(props.oneThemeLayer, true, setting)
        if (createdLayer) {
            layersGroup.push(createdLayer)
            layerIndexes[props.oneThemeLayer.layer] = layersGroup.length - 1
        }
    }
}

const getPointsNumber = (props) => {
    const totalStations = []
    if (props.exists.POLLUTED_SOIL || props.exists.INDUSTRIAL_SITE) {
        totalStations.push(props.externalSites)
    }
    if (props.exists.STATIONS_POINTS) {
        totalStations.push(props.stationsPoints)
    }
    return uniqBy(flatten(totalStations), s => {
        if (s.localisation && s.localisation.x && s.localisation.y) {
            return s.localisation.x + s.localisation.y
        }
        if (s.xCoordinate && s.yCoordinate) {
            return s.xCoordinate + s.yCoordinate
        }
        if (s.townCode) {
            return parseFloat(s.townCode)
        }
        return 0
    }).length
}

const addNewLayers = (props, stateLayerIndexes, cartographyBackground, setting) => {
    const layersGroup = [cartographyBackground]
    const layerIndexes = Object.assign({}, stateLayerIndexes)
    const stationsNumber = getPointsNumber(props)

    addThemeLayers(props, layersGroup, layerIndexes, setting)
    addOneThemeLayer(props, layersGroup, layerIndexes, setting)
    addLayersIfPossible(props, layersGroup, layerIndexes, props.layers)
    addExternalSites(layersGroup, layerIndexes, props, stationsNumber)
    addAssociatedStationPoints(props, layersGroup, layerIndexes, stationsNumber)
    addStationPoint(props, layersGroup)
    return { layers: layersGroup, layerIndexes }
}


export { addNewLayers, setStationPointScale, setStationsOpacity, setAndReturnLayerOpacity, setCartographyBackground }
