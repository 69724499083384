import React, { isValidElement } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid2 } from '@mui/material'
import { styled } from '@mui/styles'
import Icon from 'components/icon/Icon'
import Action from 'components/action/Action'
import { isUndefined } from 'lodash'
import PropTypes from 'prop-types'
import { mainBlue } from 'utils/constants/ColorTheme'

export const AccordionMUI = styled(({ round, boxShadow, sx, ...props }) => (
    <Accordion
        disableGutters
        slotProps={{ transition: { unmountOnExit: !props.noUnmount, timeout: 250 } }}
        sx={{
            borderRadius: round ? '5px' : 0,
            boxShadow: boxShadow && '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 2px 10px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
            ...sx,
        }}
        {...props}
    />))({})

export const AccordionSummaryMUI = styled(({ sx, iconColor, ...otherProps }) => (
    <AccordionSummary
        expandIcon={
            <Icon
                size='small'
                icon='expand_more'
                style={{
                    borderRadius: '50%',
                    border: `solid ${iconColor || 'white'} 2px`,
                    color: iconColor || 'white',
                }}
            />
        }
        sx={{
            color: 'white',
            background: (theme) => otherProps.color || theme.palette.primary.main,
            borderBottom: 'solid 1px grey',
            minHeight: 40,
            height: 40,
            fontSize: '15px',
            fontWeight: 'bold',
            paddingLeft: '25',
            borderRadius: otherProps.round ? '5px' : 0,
            ...sx,
        }}
        {...otherProps}
    />))({
})

export const AccordionDetailsMUI = styled(AccordionDetails)({
    padding: (props) => (props.nopadding ? 0 : 'auto'), // nopadding create a warning, use this instead: sx={{ padding: 0 }}
})

const AccordionTitle = ({
    title = '',
    subTitle = '',
    subtitleSize = 2,
    color = mainBlue,
    fontColor = 'white',
    actions = [],
}) => {
    const filteredActions = actions.filter(a => a.displayed || isUndefined(a.displayed))
    return (
        <AccordionSummary color={color} fontColor={fontColor}>
            <Grid2 container size={12} spacing={2} alignItems='center' sx={{ width: '100%' }}>
                <Grid2 size='grow'>
                    {isValidElement(title) ? title : (<span style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>{title}</span>)}
                </Grid2>
                {!!subTitle && (
                    <Grid2 size={subTitle ? subtitleSize : 'auto'}>
                        {isValidElement(subTitle) ? subTitle : (<span style={{ fontSize: '1.4rem' }}>{subTitle}</span>)}
                    </Grid2>
                )}
                {filteredActions.length > 0 && (
                    <Grid2 container size='auto' spacing={0.5} onClick={e => e.stopPropagation()}>
                        {filteredActions.map(action => (
                            <Grid2 key={actions.indexOf(action)} size='auto'>
                                {isValidElement(action) ? action : (<Action {...action} />)}
                            </Grid2>
                        ))}
                    </Grid2>
                )}
            </Grid2>
        </AccordionSummary>
    )
}

AccordionTitle.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitleSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    actions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.shape({
            icon: PropTypes.string,
            tooltip: PropTypes.string,
            onClick: PropTypes.func,
            color: PropTypes.string,
            displayed: PropTypes.bool,
            'data-cy': PropTypes.string,
        }),
    ])),
    color: PropTypes.string,
    fontColor: PropTypes.string,
}

export {
    AccordionTitle,
}