import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import SieauAction from '../../../components/sieau/SieauAction'
import Input from '../../../components/forms/Input'
import Checkbox from '../../../components/forms/Checkbox'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import { getUser } from '../../../utils/SettingUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import DtoTreatmentStation from './dto/DtoTreatmentStation'
import QualityAction from '../../../quality/actions/QualityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import InstallationEquipmentsPanel from '../installations/components/InstallationEquipmentsPanel'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import NumberField from '../../../components/forms/NumberField'
import { arrayOf } from '../../../utils/StoreUtils'
import { downloadURI } from '../../../utils/ExportDataUtil'
import {
    getVisitCheckBoxChange,
    getVisitDatePickerChange,
} from '../../../utils/VisitUtils'
import { getDate } from '../../../utils/DateUtil'
import { onChangeDate } from '../../../utils/FormUtils'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import ElecmecEquipmentPanel from '../installations/components/ElecmecEquipmentPanel'
import GenericInstallationDescription from '../installationDefault/GenericInstallationDescription'
import { Grid2 } from '@mui/material'
import { isDisplayed } from 'utils/InstallationUtils'

class TreatmentStationDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            usedWater: {},
            treatment: new DtoTreatmentStation({}),
            readMode: true,
        }
    }

    componentDidMount = () => {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            this.props.fetchInstallationTreatment(this.props.installation.id).then(() => {
                this.setState({ treatment: { ...this.props.installationTreatment, idStation: this.props.installation.id } })
            })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () => this.props.push('/installation'))
    }

    onSave = (visit) => {
        const { installation, treatment } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    treatment,
                },
            ).then(() => this.setReadOnlyMode())
        }
        return this.props.updateInstallationTreatment(
            installation,
            treatment,
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, treatment: this.props.installationTreatment })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) => this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangeTreatment = (newObject) => this.setState({ treatment: { ...this.state.treatment, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    render() {
        const { installation, readMode, treatment } = this.state
        const { applicationSettings } = this.props

        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
            sandreCode: SANDRE.EQUIPEMENTS_ST_TRAITEMENT,
        }

        const paramsTreatment = {
            station: treatment,
            onChange: this.onChangeTreatment,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <GenericInstallationDescription
                readMode={readMode}
                installation={installation}
                onChangeInstallation={this.onChangeInstallation}
                onChangeVisit={this.onChangeVisit}
                descElements={
                    <>
                        <Grid2 size={6}>
                            <Checkbox
                                checked={treatment.monitoringProgram}
                                label={i18n.monitoringProgram}
                                onChange={v => {
                                    this.onChangeTreatment({ monitoringProgram: v })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.monitoringProgram, treatment.monitoringProgram, v))
                                }}
                                disabled={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={treatment.exemptionOrder}
                                title={i18n.exemptionOrder}
                                onChange={v => {
                                    this.onChangeTreatment({ exemptionOrder: v })
                                    this.onChangeVisit({ previousValue: treatment.exemptionOrder, newValue: v, field: i18n.exemptionOrder })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={treatment.nominalCapacity}
                                title={i18n.nominalCapacity}
                                onChange={(v) => {
                                    this.onChangeTreatment({ nominalCapacity: v })
                                    this.onChangeVisit({ previousValue: treatment.nominalCapacity, newValue: v, field: i18n.nominalCapacity })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={treatment.nominalHourlyCapacity}
                                title={i18n.nominalHourlyCapacity}
                                onChange={(v) => {
                                    this.onChangeTreatment({ nominalHourlyCapacity: v })
                                    this.onChangeVisit({ previousValue: treatment.nominalHourlyCapacity, newValue: v, field: i18n.nominalHourlyCapacity })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={treatment.treatedParams}
                                title={i18n.treatedParams}
                                onChange={(v) => {
                                    this.onChangeTreatment({ treatedParams: v })
                                    this.onChangeVisit({ previousValue: treatment.treatedParams, newValue: v, field: i18n.treatedParams })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={treatment.processingSector}
                                title={i18n.processingSector}
                                onChange={(v) => {
                                    this.onChangeTreatment({ processingSector: v })
                                    this.onChangeVisit({ previousValue: treatment.processingSector, newValue: v, field: i18n.processingSector })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox
                                checked={treatment.autorisationDecree}
                                label={i18n.autorisationDecree}
                                onChange={(v) => {
                                    this.onChangeTreatment({ autorisationDecree: v })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.autorisationDecree, treatment.autorisationDecree, v))
                                }}
                                disabled={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={treatment.numDecree}
                                title={i18n.numDecree}
                                onChange={(v) => {
                                    this.onChangeTreatment({ numDecree: v })
                                    this.onChangeVisit({ previousValue: treatment.numDecree, newValue: v, field: i18n.numDecree })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={getDate(treatment.autorisationDecreeDate)}
                                title={i18n.autorisationDecreeDate}
                                onChange={v => {
                                    onChangeDate(v, v2 => this.onChangeTreatment({ autorisationDecreeDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.autorisationDecreeDate, treatment.autorisationDecreeDate, v))
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={treatment.decreeDuration}
                                title={i18n.decreeDuration}
                                onChange={(v) => {
                                    this.onChangeTreatment({ decreeDuration: v })
                                    this.onChangeVisit({ previousValue: treatment.decreeDuration, newValue: v, field: i18n.decreeDuration })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Checkbox
                                checked={treatment.rejectAutorisation}
                                label={i18n.rejectAutorisation}
                                onChange={(v) => {
                                    this.onChangeTreatment({ rejectAutorisation: v })
                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.rejectAutorisation, treatment.rejectAutorisation, v))
                                }}
                                disabled={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Input
                                value={getDate(treatment.rejectAutorisationDate)}
                                title={i18n.rejectAutorisationDate}
                                onChange={v => {
                                    onChangeDate(v, v2 => this.onChangeTreatment({ rejectAutorisationDate: v2 }))
                                    this.onChangeVisit(getVisitDatePickerChange(i18n.rejectAutorisationDate, treatment.rejectAutorisationDate, v))
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                            <InstallationEquipmentsPanel {...params} />
                        </Grid2>
                        <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                            <BuildingsPanel {...params} />
                        </Grid2>
                        {isDisplayed(applicationSettings, 'ELECMEC', installation.installationType) && (
                            <Grid2 size={12}>
                                <ElecmecEquipmentPanel {...paramsTreatment} />
                            </Grid2>
                        )}
                    </>
                }
            />
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

TreatmentStationDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    installationTreatment: store.InstallationReducer.installationTreatment,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    installationVisits: store.InstallationReducer.installationVisits,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    push,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationTreatment: InstallationAction.updateInstallationTreatment,
    fetchInstallationTreatment: InstallationAction.fetchInstallationTreatment,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentStationDescriptionPanel)
