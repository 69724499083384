import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { getSandreList } from '../../../../utils/StoreUtils'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import InstallationKeysPossessorPanel from './InstallationKeysPossessorPanel'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Input from '../../../../components/forms/Input'
import { getVisitCheckBoxChange, getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import Select from '../../../../components/forms/Select'
import { round } from 'lodash'
import { Card, CardContent, Grid2 } from '@mui/material'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { CardTitle } from 'components/card/NewCard'

const InstallationAccessSitePanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    station = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const onChangeAccessSite = (value) => {
        const accessSite = station.link_accessSites[0] || {}
        onChange({
            link_accessSites: [{
                idStation: station.id,
                ...accessSite,
                ...value,
            }],
        })
    }

    const accessSite = station.link_accessSites[0] || {}

    return (
        <Card>
            <CardTitle title={i18n.accessSite} />
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Checkbox
                            label={i18n.fence}
                            checked={accessSite.fence}
                            onChange={v => {
                                onChangeAccessSite({ fence: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.fence, accessSite.fence, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.fenceType}
                            value={accessSite.fenceType}
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPECLOTURE)}
                            integerValue
                            keyvalue='code'
                            onChange={v => {
                                onChangeAccessSite({ fenceType: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPECLOTURE, i18n.fenceType, accessSite.fenceType, v))
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            title={i18n.fenceHeight}
                            value={round(accessSite.fenceHeight, 1)}
                            onChange={v => {
                                const roundedValue = round(v, 1)
                                onChangeAccessSite({ fenceHeight: roundedValue })
                                onChangeVisit({ previousValue: accessSite.fenceHeight, newValue: roundedValue, field: i18n.fenceHeight })
                            }}
                            floatValue
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.fenceState}
                            value={accessSite.fenceState}
                            keyvalue='code'
                            onChange={v => {
                                onChangeAccessSite({ fenceState: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT, i18n.fenceState, accessSite.fenceState, v))
                            }}
                            integerValue
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT)}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <hr />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={accessSite.portal}
                            label={i18n.portal}
                            onChange={v => {
                                onChangeAccessSite({ portal: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.portal, accessSite.portal, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={accessSite.portalType}
                            label={i18n.portalType}
                            integerValue
                            keyvalue='code'
                            onChange={v => {
                                onChangeAccessSite({ portalType: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEPORTAIL, i18n.portalType, accessSite.portalType, v))
                            }}
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEPORTAIL)}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.closingBrand}
                            value={accessSite.closingBrand}
                            onChange={v => {
                                onChangeAccessSite({ closingBrand: v })
                                onChangeVisit({ previousValue: accessSite.closingBrand, newValue: v, field: `${i18n.accessSite} - ${i18n.closingBrand}` })
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.closingModel}
                            value={accessSite.closingModel}
                            onChange={v => {
                                onChangeAccessSite({ closingModel: v })
                                onChangeVisit({ previousValue: accessSite.closingModel, newValue: v, field: `${i18n.accessSite} - ${i18n.closingModel}` })
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={accessSite.portalState}
                            label={i18n.portalState}
                            integerValue
                            keyvalue='code'
                            onChange={v => {
                                onChangeAccessSite({ portalState: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT, i18n.portalState, accessSite.portalState, v))
                            }}
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT)}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={accessSite.panelAccessRestriction}
                            label={i18n.panelAccessRestriction}
                            onChange={v => {
                                onChangeAccessSite({ panelAccessRestriction: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.panelAccessRestriction, accessSite.panelAccessRestriction, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={accessSite.panelState}
                            label={i18n.panelState}
                            integerValue
                            keyvalue='code'
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT)}
                            onChange={v => {
                                onChangeAccessSite({ panelState: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT, i18n.panelState, accessSite.panelState, v))
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <hr />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={accessSite.keyBox}
                            label={i18n.keyBox}
                            onChange={v => {
                                onChangeAccessSite({ keyBox: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.keyBox, accessSite.keyBox, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={accessSite.typekeys}
                            label={i18n.typekeys}
                            integerValue
                            keyvalue='code'
                            onChange={v => {
                                onChangeAccessSite({ typekeys: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPECLES, i18n.typekeys, accessSite.typekeys, v))
                            }}
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPECLES)}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={accessSite.keyBoxState}
                            label={i18n.keyBoxState}
                            integerValue
                            keyvalue='code'
                            onChange={v => {
                                onChangeAccessSite({ keyBoxState: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT, i18n.keyBoxState, accessSite.keyBoxState, v))
                            }}
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT)}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12} sx={{ marginBottom: '10px' }}>
                        <InstallationKeysPossessorPanel onChange={onChange} station={station} readMode={readMode} />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.descriptionKeyPossessors}
                            value={accessSite.descriptionKeyPossessors}
                            onChange={v => {
                                onChangeAccessSite({ descriptionKeyPossessors: v })
                                onChangeVisit({ previousValue: accessSite.descriptionKeyPossessors, newValue: v, field: i18n.descriptionKeyPossessors })
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                </Grid2>

            </CardContent>
        </Card>
    )
}

InstallationAccessSitePanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}


export default InstallationAccessSitePanel
