/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { getFullDate, getHour } from '../../../utils/DateUtil'
import { getLabel } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import MaterielAction from '../../../materiel/actions/MaterielAction'
import { hasValue } from 'utils/NumberUtil'
import VariousMaterielAction from 'materiel/components/variousMateriel/actions/VariousMaterielAction'
import DtoVariousMateriel from 'station/dto/materiel/DtoVariousMateriel'
import { shallowEqual } from 'react-redux'
import StationDescriptionTable from './StationDescriptionTable'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { Card, CardContent, Grid2 } from '@mui/material'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import { onChangeHour } from 'utils/FormUtils'
import Input from 'components/forms/Input'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import useSandreList from 'utils/customHook/useSandreList'
import DtoVariousMaterielType from 'materiel/components/variousMateriel/dto/DtoVariousMaterielType'

const headers = ['startDate', 'endDate', 'description', 'value', 'type', 'key']


const ArrangementDialogContent = ({
    stateElement,
    onChangeElement = () => { },
    matKeys = [],
}) => {
    const arrangementTypeList = useSandreList(SANDRE.ARRANGEMENT_TYPES)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            label={i18n.startDate}
                            onChange={v => onChangeElement({ startDate: v })}
                            max={stateElement.endDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(stateElement.startDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateElement.endDate }, stateElement.startDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            label={i18n.endDate}
                            onChange={v => onChangeElement({ endDate: v })}
                            min={stateElement.startDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(stateElement.endDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateElement.startDate }, stateElement.endDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={arrangementTypeList}
                            label={i18n.type}
                            value={stateElement.arrangementType}
                            onChange={v => onChangeElement({ arrangementType: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={matKeys}
                            value={stateElement.keyCode}
                            label={i18n.key}
                            keyLabel='reference'
                            onChange={v => onChangeElement({ keyCode: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.value}
                            title={i18n.value}
                            onChange={v => onChangeElement({ value: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.description}
                            value={stateElement.descriptive}
                            onChange={v => onChangeElement({ descriptive: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

ArrangementDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
    matKeys: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMaterielType)),
}

const StationArrangementPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const dispatch = useDispatch()
    const [matKeys, setMatKeys] = useState([])

    const {
        variousMaterielTypes,
        sandreCodes,
    } = useSelector(store => ({
        variousMaterielTypes: store.VariousMaterielReducer.variousMaterielTypes,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const idType = variousMaterielTypes.find(v => v.name === 'CLE')?.id

    useEffect(() => {
        if (!variousMaterielTypes.length) {
            dispatch(VariousMaterielAction.fetchVariousMaterielTypes())
        }
    }, [variousMaterielTypes])

    useEffect(() => {
        if (hasValue(idType)) {
            MaterielAction.promiseVariousMaterielFromType(idType).then(keys => setMatKeys(keys.map(k => new DtoVariousMateriel(k))))
        }
    }, [idType])

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_arrangements'
            formatFunction={c => ({
                ...c,
                startDate: getFullDate(c.startDate),
                endDate: getFullDate(c.endDate),
                type: getSandreLabel(sandreCodes, SANDRE.ARRANGEMENT_TYPES, c.arrangementType),
                description: c.descriptive,
                key: c.keyCode ? getLabel(matKeys, c.keyCode, 'reference', 'id') : undefined,
            })}
            titles={{
                title: i18n.arrangements,
                edit: i18n.editArrangement,
                new: i18n.newArrangement,
            }}
            headers={headers}
            dialogContent={props => <ArrangementDialogContent {...props} matKeys={matKeys} />}
        />
    )
}

StationArrangementPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default StationArrangementPanel