import React from 'react'
import PropTypes from 'prop-types'
import { connect, shallowEqual, useSelector } from 'react-redux'
import { arrayOf } from '../../../utils/StoreUtils'
import i18n from 'simple-react-i18n'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getFullDate, getHour } from '../../../utils/DateUtil'
import { getSandreLabel } from '../../../utils/StringUtil'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { onChangeHour } from '../../../utils/FormUtils'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import { getUpdatedLandmarkDate } from '../../../utils/PiezometryUtils'
import { Card, CardContent, Grid2 } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import useSandreList from 'utils/customHook/useSandreList'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import NumberField from 'components/forms/NumberField'
import Textarea from 'components/forms/Textarea'
import StationDescriptionTable from './StationDescriptionTable'

const headers = ['startDate', 'endDate', 'nature', 'altitude', 'comment', 'altimetrySystems', 'productionMode']
const mandatoryFields = [{ field: 'altitude' }, { field: 'startDate' }, { field: 'natureCode', i18n: 'nature' }]

const AltimetrySystemDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const systemList = useSandreList(SANDRE.ALTIMETRIC_SYSTEM)
    const natureList = useSandreList(SANDRE.ALTIMETRIC_SYSTEM_NATURE)
    const precisionList = useSandreList(SANDRE.ALTIMETRIC_SYSTEM_PRECISION)
    const obtentionList = useSandreList(SANDRE.ALTIMETRIC_SYSTEM_OBTENTION)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            label={i18n.startDate}
                            onChange={v => onChangeElement({ startDate: v })}
                            max={stateElement.endDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(stateElement.startDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateElement.endDate }, stateElement.startDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            label={i18n.endDate}
                            onChange={v => onChangeElement({ endDate: v })}
                            min={stateElement.startDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(stateElement.endDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateElement.startDate }, stateElement.endDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={natureList}
                            label={i18n.nature}
                            value={stateElement.natureCode}
                            onChange={v => onChangeElement({ natureCode: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.altitude}
                            title={i18n.altitude}
                            onChange={v => onChangeElement({ altitude: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={systemList}
                            keyLabel='name'
                            label={i18n.altimetrySystems}
                            value={stateElement.altimetrySystem}
                            onChange={v => onChangeElement({ altimetrySystem: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={obtentionList}
                            label={i18n.productionMode}
                            value={stateElement.productionMode}
                            onChange={v => onChangeElement({ productionMode: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={precisionList}
                            label={i18n.precision}
                            value={stateElement.precision}
                            onChange={v => onChangeElement({ precision: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Textarea
                            title={i18n.comment}
                            value={stateElement.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>

    )
}

AltimetrySystemDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const StationAltimetrySystemsPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
    createOnly = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={(changes, selectedLandmark) => {
                onChange({
                    ...changes,
                    ...getUpdatedLandmarkDate(station, selectedLandmark),
                })
            }}
            keyList='link_altimetrySystems'
            formatFunction={as => ({
                ...as,
                startDate: getFullDate(as.startDate),
                endDate: getFullDate(as.endDate),
                nature: getSandreLabel(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_NATURE, as.natureCode),
                altimetrySystems: getSandreLabel(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM, as.altimetrySystem),
                productionMode: getSandreLabel(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_OBTENTION, as.productionMode),
            })}
            titles={{
                title: i18n.altimetrySystems,
                edit: i18n.editSystem,
                new: i18n.newSystem,
            }}
            headers={headers}
            dialogContent={AltimetrySystemDialogContent}
            mandatoryFields={mandatoryFields}
            editable={!createOnly}
            deletable={!createOnly}
        />
    )
}

StationAltimetrySystemsPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    createOnly: PropTypes,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(StationAltimetrySystemsPanel)