import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { sieauTooltip } from '../../utils/FormUtils'
import { searchAllCharacters } from '../../utils/StringUtil'

class Button extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.id || searchAllCharacters(`${props.title}button${uuidv4()}`).replaceAll(' ', '_').replace('numero', '').replace('nom', 'libelle'),
        }
    }

    getButton = () => {
        const tooltip = this.props.tooltip && sieauTooltip(this.props.tooltip, this.state.id)
        const href = this.props.href ? { href: this.props.href } : {}
        return (
            <a className={ `${this.props.className} btn ${this.props.col ? ` col s${this.props.col}` : ''}${this.props.disabled ? ' grey' : ' waves-effect waves-light'}` }
                onClick={ !this.props.disabled ? this.props.onClick : undefined } id={ this.state.id } {...tooltip} {...href} style={ this.props.style }
            >
                <i className='material-icons left'>{ this.props.icon }</i>
                { this.props.title }
            </a>
        )
    }

    render() {
        return this.getButton()
    }

    componentDidMount() {
        $('.sieauButtonTooltip').tooltip()
    }
}

Button.propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    col: PropTypes.number,
    icon: PropTypes.string,
    tooltip: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
}

Button.defaultProps = {
    onClick: () => {},
    icon: '',
    className: '',
    href: null,
    title: '',
    style: {},
}

export default Button
