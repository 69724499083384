import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Table from '../../../../components/datatable/Table'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import Input from '../../../../components/forms/Input'
import { nbPerPageLabelTiny } from '../../../../referencial/constants/ReferencialConstants'
import Job from '../../../dto/DtoJob'
import TideGaugeStationDto from '../../../dto/TideGaugeStationDto'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import DtoParametrageDataType from '../../../../piezometry/dto/DtoParametrageDataType'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import HydrometryAction from '../../../../hydrometry/actions/HydrometryAction'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import DtoContributor from '../../../../station/dto/DtoContributor'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import Row from '../../../../components/react/Row'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import ReferencialAction from '../../../../referencial/action/ReferencialAction'
import { getIntegrationModes } from '../../../../utils/JobUtils'
import { removeNullKeys } from '../../../../utils/StoreUtils'
import DtoHydrometricStation from '../../../../hydrometry/dto/DtoHydrometricStation'
import { getStations } from '../../../../utils/StationUtils'


class TideGaugeOceanogramPanel extends Component {
    state = {
        measureOptions: [
            {
                value: '',
                label: i18n.noDataIntegration,
            },
            {
                value: 'dataIntegration',
                label: i18n.lastDataIntegration,
            },
            {
                value: 'dataFullIntegration',
                label: i18n.fullDataIntegration,
            },
        ],
    }

    componentDidMount() {
        if (!this.props.hydrometryDataTypes.length) {
            this.props.fetchHydrometryDataTypes()
        }
        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
    }

    onDeleteCode = ({ tidegaugeCode }) => {
        if (this.props.isEditMode) {
            const codes = this.props.job.parameters.parameters.filter(p => p != tidegaugeCode)
            this.props.addCode(codes)
        }
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || ''
        }
        return ''
    }

    addCode = () => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddCode.value
            const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                this.props.toastrError(i18n.youMustEnterAtLeastOneCode)
            } else if (exist) {
                this.props.toastrError(i18n.youHaveAlreadyEnteredThisCode)
            } else {
                this.props.addCode([
                    code,
                    ...this.props.job.parameters.parameters,
                ])
                this.refs.txtAddCode.value = ''
            }
        }
    }

    getAllStations = () => {
        return this.props.hydrometricStations
            .filter(s => s.stationType === 4) // filtrer sur les stations de type millieu marin
            .map(s => ({ ...s, name: s.code ? `[${s.code}] ${s.name}` : s.name }))
    }


    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return {
            height: true,
            forecastTide: true,
            surcharge: true,
            importMode: 'DATA_ADD_NEW_DATA',
            ...newFilters }
    }

    getOptionHauteur = () => {
        return [{
            id: 4,
            label: i18n.height,
        }]
    }

    onChangeParametersNew = (currentParameter, index, changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            parameters: this.props.job.parameters.parameters.map((parameter, i) => i === index ? JSON.stringify(removeNullKeys({ ...currentParameter, ...changes })) : parameter),
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    getParametersNew = () => this.props.job.parameters.parameters.map(p => JSON.parse(p))


    getParametersData = (filters, parameters) => {
        const disabled = { disabled: !this.props.isEditMode }

        // this.props.jobList.map(job => ({ ...job, icon: null }))
        // nullValue: <Icon icon='delete' tooltip={ i18n.delete } onClick={ () => this.onDeleteParameter(index) } />,
        return parameters.map((p, index) => {
            return {
                station: <Select value={ p.station } options={ this.getAllStations() } onChange={ v => this.onChangeParametersNew(p, index, { station: v }) } {...disabled }/>,
                offset: <Input value={ p.offset } onChange={ v => this.onChangeParametersNew(p, index, { offset: v }) } {...disabled }/>,
                index,
            }
        })
    }
    addParameterNew = () => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: [ ...this.props.job.parameters.parameters, '{}'],
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }
    onDeleteParameter = (elt) => {
        if (this.props.isEditMode) {
            const jobParameters = {
                ...this.props.job.parameters,
                parameters: this.props.job.parameters.parameters.filter((_, i) => i !== elt.index),
            }
            this.props.onChangeJob({ parameters: jobParameters })
        }
    }


    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const filters = this.getFilters()
        const parameters = this.getParametersData(filters, this.getParametersNew())
        const baseHeadersT = [ 'station', 'offset' ]

        const actions = [
            {
                onClick: this.addParameterNew,
                iconName: 'add_box',
                tooltip: `${i18n.add}`,
            },
        ]

        const shomDataTypes = ['height', 'surchargeReal', 'tide']

        const dataTypes = shomDataTypes.map(shomDt => {
            switch (shomDt) {
                case 'tide': return {
                    nullValue: <Checkbox checked={ filters[shomDt] } onChange={ v =>this.onChangeFilters({ [shomDt]: v }) } {...disabled} />,
                    name: i18n.tide,
                    datatype: '',
                }
                default: return {
                    nullValue: <Checkbox checked={ filters[shomDt] }
                        onChange={ v => this.onChangeFilters({ [shomDt]: v }) }
                        { ...disabled }
                    />,
                    name: i18n[shomDt],
                    dataType: <Select value={ filters[`${shomDt}Dt`] }
                        options={ this.props.hydrometryDataTypes }
                        onChange={ v => this.onChangeFilters({ [`${shomDt}Dt`]: v })}
                        { ...disabled }
                    />,
                }
            }
        })
        return (
            <div>
                <div className='row no-margin valign-wrapper padding-bottom-1'>
                    <div className='col s4 no-margin'>
                        <Select options={ getIntegrationModes() }
                            obligatory={true}
                            label={ i18n.dataIntegration }
                            value={ filters.importMode }
                            labelSpan={ integrationModeHelpIcon() }
                            onChange={ v => this.onChangeFilters({ importMode: v }) }
                            { ...disabled }
                        />
                    </div>
                    <div className='col s3 no-margin' >
                        <Input
                            col={2}
                            title={i18n.delta}
                            floatValue={false}
                            obligatory
                            value={ filters.delta }
                            onChange={(d) =>this.onChangeFilters({ delta: d }) }
                        />
                    </div>
                    <div className='col s3 no-margin' />
                </div>
                <div className='row no-margin padding-bottom-1'>
                    <fieldset>
                        <legend>&nbsp;{i18n.defaultValue}&nbsp;</legend>
                        <div className='row no-margin'>
                            <Row className='padding-top-1'>
                                <Select col={ 3 }
                                    label={ i18n.status }
                                    value={ filters.status }
                                    onChange={ v => this.onChangeFilters({ status: v }) }
                                    options={ getStatusSelectOptions() }
                                    nullLabel='&nbsp;'
                                    { ...disabled }
                                />
                                <Select col={ 3 }
                                    label={ i18n.qualification }
                                    value={ filters.qualification }
                                    onChange={ v => this.onChangeFilters({ qualification: v }) }
                                    options={ getQualificationSelectOptions() }
                                    nullLabel='&nbsp;'
                                    { ...disabled }
                                />
                                <MultiContributorsAutocomplete col={ 6 }
                                    label={ i18n.producer }
                                    values={ filters.producer }
                                    onChange={ v => this.onChangeFilters({ producer: v }) }
                                    options={ this.props.contributors }
                                    { ...disabled }
                                />
                            </Row>
                            <Row>
                                <Select col={ 2 }
                                    label={ i18n.measureNature }
                                    value={ filters.nature }
                                    onChange={ v => this.onChangeFilters({ nature: v }) }
                                    options={ getSandreList(this.props.sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, true) }
                                    nullLabel='&nbsp;'
                                    { ...disabled }
                                />
                                <Select col={ 2 }
                                    label={ i18n.obtainningMode }
                                    value={ filters.obtainingMode }
                                    onChange={ v => this.onChangeFilters({ obtainingMode: v }) }
                                    options={ getSandreList(this.props.sandreCodes, SANDRE.OBTAINING_MODE) }
                                    nullLabel='&nbsp;'
                                    { ...disabled }
                                />
                                <MultiContributorsAutocomplete col={ 4 }
                                    label={ i18n.administrator }
                                    values={ filters.manager }
                                    onChange={ v => this.onChangeFilters({ manager: v }) }
                                    options={ this.props.contributors }
                                    { ...disabled }
                                />
                                <MultiContributorsAutocomplete col={ 4 }
                                    label={ i18n.validator } values={ filters.validator }
                                    onChange={ v => this.onChangeFilters({ validator: v }) }
                                    options={ this.props.contributors }
                                    { ...disabled }
                                />
                            </Row>
                        </div>
                    </fieldset>
                </div>
                <div className='row no-margin  padding-bottom-1'>
                    <fieldset>
                        <legend>&nbsp;{i18n.dataTypes}&nbsp;</legend>
                        <div className='row no-margin'>
                            <Table data={ dataTypes }
                                showTitle={false}
                                type={ { headers: ['nullValue', 'name', 'dataType'] }}
                                condensed
                                sortable
                                showNbElements={ false }
                            />
                        </div>
                    </fieldset>
                </div>
                <div className='job-parameter-table'>
                    <Table
                        data={ parameters }
                        nbPerPageLabel={ nbPerPageLabelTiny }
                        type={ { headers: baseHeadersT } }
                        sortable
                        condensed
                        paging
                        customHeaders={ baseHeadersT }
                        activeHeader
                        actions={ actions }
                        deletable={ this.props.isEditMode }
                        onDelete={ this.onDeleteParameter }
                    />
                </div>
            </div>
        )
    }
}

TideGaugeOceanogramPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    addCode: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    onChangeJob: PropTypes.func.isRequired,
    toastrError: PropTypes.func,
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
    fetchHydrometryDataTypes: PropTypes.func,
    sandreCodes: arrayOf(DtoSandreCode),
    contributors: arrayOf(DtoContributor),
    fetchSandreCodes: PropTypes.func,
    hydrometricStations: arrayOf(DtoHydrometricStation),
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
    fetchHydrometryDataTypes: HydrometryAction.fetchHydrometryDataTypes,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
}
const mapStateToProps = store => ({
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    stations: store.StationReducer.stations,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TideGaugeOceanogramPanel)