import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import CampaignAction from '../../../../campaign/actions/CampaignAction'
import CampaignDto from '../../../../campaign/dto/CampaignDto'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import Select from '../../../../components/forms/Select'
import Row from '../../../../components/react/Row'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoQualification from '../../../../quality/dto/DtoQualification'
import DtoStatus from '../../../../quality/dto/DtoStatus'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import ContributorDto from '../../../../referencial/components/contributor/dto/ContributorDto'
import FractionAction from '../../../../referencial/components/fraction/actions/FractionAction'
import FractionDto from '../../../../referencial/components/fraction/dto/FractionDto'
import SupportAction from '../../../../referencial/components/support/actions/SupportAction'
import SupportDto from '../../../../referencial/components/support/dto/SupportDto'
import { setModal } from '../../../../utils/FormUtils'
import Job from '../../../dto/DtoJob'
import ImportQualityDefaultValue from '../ImportQualityDefaultValue'
import ImportFileModal from './ImportFileModal'

class ImportSiapLabPanel extends Component {
    constructor(props) {
        super(props)
        const parameter = props.job.parameters.parameters.length ? JSON.parse(props.job.parameters.parameters[0]) : {}
        this.state = {
            directory: undefined,
            rename: false,
            ...parameter,
        }
    }

    componentDidMount = () => {
        if (!this.props.contributors.length) {
            this.props.fetchContributors()
        }
        if (!this.props.qualifications.length) {
            this.props.fetchQualifications()
        }
        if (!this.props.status.length) {
            this.props.fetchStatus()
        }
        if (!this.props.fractions.length) {
            this.props.fetchFractions()
        }
        if (!this.props.supports.length) {
            this.props.fetchSupports()
        }
        this.props.fetchQualityCampaigns()
        this.onChangeFilters({ pipeSeparator: true })
    }

    setAdvancedFtpModal =() => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={this.state.directory} />,
        })
    }
    getFilters = () => {
        const newFilters = this.props.job.parameters.filters.length ? JSON.parse(this.props.job.parameters.filters[0]) : {}
        return { ...newFilters }
    }

    onChangeFilters = (changes) => {
        const parameters = {
            ...this.props.job.parameters,
            filters: [JSON.stringify({
                ...this.getFilters(),
                ...changes,
            })],
        }
        this.props.onChangeJob({ parameters })
    }

    onChangeJobParameter = (changes) => {
        const jobParameters = {
            ...this.props.job.parameters,
            ...changes,
        }
        this.props.onChangeJob({ parameters: jobParameters })
    }

    onChangeParameter = obj => {
        this.setState(obj)
        this.props.handleChangeParameters([JSON.stringify(
            {
                ...this.state,
                ...obj,
            },
        )])
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || ''
        }
        return ''
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('data'))
            if (integration) {
                return [...data, integration]
            }
            return data
        })()
        this.props.onChangeDataTypes(newDataTypes)
    }

    handleChangeStationIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('station'))
            if (integration) {
                return [...data, 'stationIntegration']
            }
            return data
        })()
        this.props.onChangeDataTypes(newDataTypes)
    }

    render() {
        const {
            rename,
            histoPurge,
            stationArs,
            dept,
            campaignId,
            directory,
            ...defaultValue
        } = this.state
        const filters = this.getFilters()
        const disabled = { disabled: !this.props.isEditMode }
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <Select
                        value={campaignId}
                        options={this.props.qualityCampaigns.filter(cs => cs.campaignType === 3)}
                        label={i18n.campaign}
                        col={4}
                        onChange={v => this.onChangeParameter({ campaignId: v })}
                        {...disabled}
                    />
                    <div className='col s2'>
                        <Checkbox
                            checked={rename}
                            label={i18n.fileRenaming}
                            onChange={v => this.onChangeParameter({ rename: v })}
                            {...disabled}
                        />
                    </div>
                    <div className='col s2'>
                        <Checkbox
                            checked={histoPurge}
                            label={i18n.histoPurge}
                            onChange={v => this.onChangeParameter({ histoPurge: v })}
                            {...disabled}
                        />
                    </div>
                    <div className='col s2'>
                        <Checkbox
                            checked={stationArs}
                            label={i18n.stationArs}
                            onChange={v => this.onChangeParameter({ stationArs: v })}
                            {...disabled}
                        />
                    </div>
                    {
                        stationArs ? (
                            <div className='col s2'>
                                <Input title={i18n.departmentNumber} value={dept} onChange={e => this.onChangeParameter({ dept: e })} {...disabled}/>
                            </div>
                        ):(<div className='col s2'> </div>)
                    }
                </div>
                <div className='row no-margin valign-wrapper'>
                    <fieldset className='width-100'>
                        <legend>&nbsp;{ i18n.separator }&nbsp;</legend>
                        <Checkbox col={ 3 } label={ i18n.comma } onChange={ v => this.onChangeFilters({ commaSeparator: v }) } disabled={ disabled } checked={ filters.commaSeparator } />
                        <Checkbox col={3} label={i18n.semicolon } onChange={ v => this.onChangeFilters({ semicolonSeparator: v }) } disabled={ disabled } checked={ filters.semicolonSeparator } />
                        <Checkbox col={ 3 } label={ i18n.space } onChange={ v => this.onChangeFilters({ spaceSeparator: v }) } disabled={ disabled } checked={ filters.spaceSeparator } />
                        <Checkbox col={ 3 } label={ i18n.tabulation } onChange={ v => this.onChangeFilters({ tabulationSeparator: v }) } disabled={ disabled } checked={ filters.tabulationSeparator } />
                    </fieldset>
                </div>
                <Row className='valign-wrapper'>
                    <Input col={ 6 } title={i18n.selectDirectory} value={directory} onChange={e => this.onChangeParameter({ directory: e })} {...disabled}/>
                    <div className='col s2'>
                        <a className={`btn btn-small secondary-color-back ${disabled.disabled ? 'disabled' : ''}`} onClick={ () => this.setAdvancedFtpModal()} ><span title={ i18n.importLabelFtp }> <i className='material-icons'>input </i> </span></a>
                    </div>
                    <Input col={ 4 } title={i18n.filter} value={ 'SIAPLAB*, SIAPLABR*'} onChange={e => this.onChangeParameter({ filter: e })} disabled= { true }/>
                </Row>
                <ImportQualityDefaultValue
                    defaultValue={defaultValue}
                    onChange={obj => this.onChangeParameter(obj)}
                    isEditMode={this.props.isEditMode}
                />
            </div>
        )
    }
}

ImportSiapLabPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    handleChangeParameters: PropTypes.func.isRequired,
    onChangeJob: PropTypes.func.isRequired,
    onChangeDataTypes: PropTypes.func.isRequired,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    qualifications: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualification)),
    status: PropTypes.arrayOf(PropTypes.instanceOf(DtoStatus)),
    fractions: PropTypes.arrayOf(PropTypes.instanceOf(FractionDto)),
    supports: PropTypes.arrayOf(PropTypes.instanceOf(SupportDto)),
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    fetchContributors: PropTypes.func,
    fetchQualifications: PropTypes.func,
    fetchStatus: PropTypes.func,
    fetchFractions: PropTypes.func,
    fetchSupports: PropTypes.func,
    fetchQualityCampaigns: PropTypes.func,
}

const mapStateToProps = store => ({
    contributors: store.ContributorReducer.contributors,
    qualifications: store.QualityReducer.qualifications,
    status: store.QualityReducer.status,
    fractions: store.FractionReducer.fractions,
    supports: store.SupportReducer.supports,
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
})

const mapDispatchToProps = {
    fetchContributors: ContributorAction.fetchContributors,
    fetchQualifications: QualityAction.fetchQualifications,
    fetchStatus: QualityAction.fetchStatus,
    fetchFractions: FractionAction.fetchFractions,
    fetchSupports: SupportAction.fetchSupports,
    fetchQualityCampaigns: CampaignAction.fetchQualityCampaigns,
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportSiapLabPanel)
