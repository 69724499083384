import { mean, minBy, orderBy, sum, partition, isUndefined, keys, groupBy, uniqBy, max, last } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../components/card/ProgressCard'
import Axis from '../../../components/echart/Axis'
import EChart from '../../../components/echart/EChart'
import {
    automaticValues,
    chartLine,
    chartSymbol,
    exportExcelIcon,
    exportPictureIcon,
    fullScreenIcon,
    getSubstractTime,
    histogramIcon,
    legendSymbol,
    lineIcon,
    logIcon,
    setLogOptions,
    thresholdIcon,
    yAutomaticScale,
} from '../../../components/echart/EChartUtils'
import Bar from '../../../components/echart/series/Bar'
import Line from '../../../components/echart/series/Line'
import {
    generateGradient, getColorCircleElement, getColorFromPalette2,
    getColorFromPalettePluvio,
    getThresholdColor,
} from '../../../utils/ColorUtil'
import {
    enumerateBetweenDates,
    getDate,
    getDuration,
    getFullDate,
} from '../../../utils/DateUtil'
import { exportFile } from '../../../utils/ExportDataUtil'
import { DEFROUNDVALUE, hasBooleanValue, hasValue, round } from '../../../utils/NumberUtil'
import { arrayOf } from '../../../utils/StoreUtils'
import PluviometryAction from '../../actions/PluviometryAction'
import DtoPluviometerStats from '../../dto/DtoPluviometerStats'
import DtoPluvioMeasures from '../../dto/measures/DtoPluvioMeasures'
import PluviometerDto from '../../dto/PluviometerDto'
import AppStore from '../../../store/AppStore'
import Row from 'components/react/Row'
import { WhiteCard } from 'components/styled/Card'
import { Grid } from '@mui/material'
import ToastrAction from '../../../toastr/actions/ToastrAction'
import { tooltipDatePluvio } from '../../../utils/PluviometryUtils'
import { getLocalStorageJson, setLocalStorageJson } from '../../../utils/FormUtils'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import PluviometerThresholdDto from '../../dto/PluviometerThresholdDto'
import { STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import SimpleChartTabsPluvio from '../../../components/echart/SimpleChartTabsPluvio'
import {
    CUMUL_PERSO_MAX,
    PLUVIO_TAB_DISPLAY_MODES,
    SUM_AUTO,
    SUM_DAY,
    SUM_DECADE,
    SUM_HOUR,
    SUM_MONTH,
    SUM_WEEK,
    SUM_YEAR,
} from '../../constants/PluvioOptionConstant'
import { repeatList } from '../../../utils/ListUtil'
import ChartTabPredData from '../../../station/components/iaeauModels/ChartTabPredData'
import { getExportFormat, getI18nOrLabel } from '../../../utils/StringUtil'
import MultiBand from '../../../components/echart/series/MultiBand'
import { CHART_SELECTED_TIME, OTHER } from 'quality/constants/ChartConstant'
import { HISTO, J90 } from 'alerting/constants/ChartFollowContants'
import MUIChartTabs from 'components/echart/MUIChartTabs'
import { BAR, LINE } from 'components/echart/QualityChart'
import { getMeasureJplus } from '../../../iaeau/utils/IAEauUtils'
import { calculatePercentile90 } from '../../../utils/AnalyseUtils'

// TODO ATTENTION ! Composant réutilisé dans plusieurs écrans
const DAY = 'DAY'
const THRESHOLD = 0
const NOTHING = 2

class PluviometerChartPanel extends Component {
    constructor(props) {
        super(props)
        this.optionCumulSelected = CUMUL_PERSO_MAX
        this.hoursCumul = 1
        const chartSelectedTime = getLocalStorageJson(CHART_SELECTED_TIME)
        const lastSelectedTime = chartSelectedTime === HISTO ? J90 : (chartSelectedTime ? parseInt(chartSelectedTime) : J90)
        this.state = {
            fullScreen: false,
            minDate: lastSelectedTime === HISTO ? undefined : getSubstractTime(lastSelectedTime),
            maxDate: lastSelectedTime === HISTO ? moment().valueOf() : undefined,
            time: lastSelectedTime,
            showGroupedDateDropdown: false,
            pluvioGrouped: SUM_AUTO,
            displayModes: getLocalStorageJson(PLUVIO_TAB_DISPLAY_MODES) ?? {
                auto: true,
                all: false,
                sumHour: false,
                sumDay: false,
                sumWeek: false,
                sumDecade: false,
                sumMonth: false,
                sumYear: false,
                personalizedGrouping: false,
            },
            dataLoaded: false,
            progess: 0,
            chartTab: null,
            isEditMode: false,
            optionDayHourSelected: 'HOUR',
            pluvioEndDataArea: getLocalStorageJson('PLUVIO_END_DATA_AREA') ?? false,
            modelData: {
                predMeasures: [],
                selectedPred: undefined,
                predDate: undefined,
                predStats: [],
            },
            chartType: LINE,
            stack: undefined,
            isLogActive: false,
            displayLegend: true,
            stateThreshold: THRESHOLD,
            displayMarker: false,
            displayLine: true,
        }
    }

    // getGroupFunc = label => {
    //     switch (label) {
    //         case 'auto': return AUTO
    //         case 'sumHour': return SUM_HOUR
    //         case 'sumDay': return SUM_DAY
    //         case 'sumWeek': return SUM_WEEK
    //         case 'sumDecade': return SUM_DECADE
    //         case 'sumMonth': return SUM_MONTH
    //         case 'sumYear': return SUM_YEAR
    //         case 'personalizedGrouping': return this.state.displayModes.personalizedGroupingValue
    //         case 'all': default: return label
    //     }
    // }

    loadData = () => {
        const cParams = this.getChartParams()
        const defaultGroupMode = this.state.minDate && this.state.minDate > moment(this.state.maxDate || moment().valueOf()).subtract('1', 'month').valueOf() ? 'all' : 'MAX'
        const cumulPerso = this.state.displayModes?.personalizedGrouping ? this.state.displayModes?.personalizedGroupingValue : undefined
        const inputs = uniqBy(this.props.pluviometerStatistics, 'typeId').map(stat => ({
            stationId: this.props.id,
            dataType: stat.typeId,
            groupFunc: cumulPerso ?? ([-1, 1, 3].includes(stat.typeId) ? cParams.cumulPluvio : defaultGroupMode),
            chartMode: true,
            startDate: this.state.minDate,
            endDate: this.state.maxDate,
            distinctByCodePoint: true,
        }))
        this.props.loadPluvioChronicMeasures(inputs, p => this.setState({ progress: p })).then(() => this.setState({ dataLoaded: true }))
    }

    componentDidMount() {
        AppStore.dispatch(PluviometryAction.fetchPluviometer(this.props.id))
        AppStore.dispatch(PluviometryAction.fetchPluviometerMeasuresStats(this.props.id))
        AppStore.dispatch(PluviometryAction.fetchPluviometryDataTypes())
        AppStore.dispatch(PluviometryAction.fetchPluviometerThresholds(this.props.id))
        this.loadData()
    }

    getExportData = () => {
        const { pluviometerStatistics, pluviometryDataTypes, pluvioMeasures, pluviometer } = this.props
        const { selectedPred, predMeasures } = this.state.modelData
        const isMultiPoint = pluviometerStatistics.some(stat => pluviometerStatistics.some(stat2 => stat.typeId === stat2.typeId && stat.codepoint !== stat2.codepoint))
        const data = pluviometerStatistics.flatMap(stat => {
            const roundValue = pluviometryDataTypes.find(pdt => pdt.id === stat.typeId)?.numberDecimal || DEFROUNDVALUE

            const labelSerie = selectedPred?.[stat.typeId]?.source
            const predData = (selectedPred && selectedPred[stat.typeId] ? predMeasures[stat.typeId] : []).map(predMeasure => ({
                stationCode: { value: pluviometer.code },
                stationName: { value: pluviometer.name },
                date: { value: getFullDate(predMeasure.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
                value: { value: predMeasure.value, format: getExportFormat(roundValue), cellType: 'number' },
                type: { value: `${labelSerie}${predMeasure.serieName ? ` - ${predMeasure.serieName}` : ''} ${getMeasureJplus(predMeasure)}` },
            }))

            const filteredData = pluvioMeasures.find(obj => obj.dataType === stat.typeId && obj.codepoint !== stat.codepoint)?.measures || []
            const obsData = filteredData.map(d => ({
                stationCode: { value: pluviometer.code },
                stationName: { value: pluviometer.name },
                date: { value: getFullDate(d.date), format: 'dd/MM/yyyy HH:mm:ss', cellType: 'date' },
                value: { value: d.value, format: getExportFormat(roundValue), cellType: 'number' },
                type: { value: isMultiPoint ? `${stat.label} - ${stat.namePoint ?? ''}` : stat.label },
            }))
            return [...obsData, ...predData]
        })

        if (data.length) {
            data[0].headers = ['stationCode', 'stationName', 'date', 'value', 'type']
        }
        return data
    }

    getTooltip(bigDic, cParams) {
        return {
            trigger: 'axis',
            formatter: (params) => {
                const isBar = params[0]?.axisType?.includes('category')
                const date = params[0].value[3].date
                const dateStr = isBar ? params[0].value[0] : tooltipDatePluvio(params[0].value[3].date, cParams.cumulPluvio)
                const paramsOrder = bigDic[getDate(date, cParams.categoryFormat)] ?? []
                const grouped = groupBy(paramsOrder, p => `${p.value[3].typeId}:${p.value[3].codepoint}`)
                const paramsCorrected = cParams.cumulPluvio === SUM_DAY ? paramsOrder : (() => {
                    if (isBar) {
                        return Object.keys(grouped).map(key => {
                            const measures = grouped[key]
                            if ([-1, 1, 3].includes(measures[0].value[3].typeId)) { // mesures en category
                                return measures[0]
                            }
                            const avg = round(mean(measures.map(m => m.value[1])))
                            return { ...measures[0], serieName: `${measures[0].value[4]} (Moyenne)`, calculatedValue: avg }
                        })
                    }
                    return Object.keys(grouped).map(key => {
                        const measures = grouped[key]
                        if ([-1, 1, 3].includes(measures[0].value[3].typeId)) { // mesures en category
                            return { ...measures[0], serieName: `${measures[0].value[4]} (${measures[0].value[0]})` }
                        }
                        return measures.find(m => getDate(m.value[3].date) === getDate(date))
                    })
                })()
                const paramsFormat = (paramsCorrected ?? []).filter(o => hasValue(o?.value[1])).map(o => ({
                    marker: `${getColorCircleElement(o.value[5])}`,
                    seriesName: o.serieName ?? o.value[4],
                    value: o.calculatedValue ?? o.value[1],
                    unit: o.value[2],
                    jPlus: getMeasureJplus(o?.value[3]),
                }))
                const result = paramsFormat.map(o => `${o.marker} ${o.seriesName} : ${o.value} ${o.unit} ${o.jPlus}`).join('<br/>')
                return `${dateStr}<br />${result}`
            },
        }
    }

    changeEndDataArea = () => {
        setLocalStorageJson('PLUVIO_END_DATA_AREA', !this.state.pluvioEndDataArea)
        this.setState({ pluvioEndDataArea: !this.state.pluvioEndDataArea })
    }

    addGrid = (series, grids, xAxis, yAxis, gridsHeights, stat, cParams, lineColor) => {
        const { chartMinDate, chartMaxDate, categoryDates, categoryFormat } = cParams
        const { predMeasures, selectedPred } = this.state.modelData

        grids.push({
            top: sum(gridsHeights) + 30,
            right: '2%',
            height: 210,
            left: 60,
        })

        // ajout des axes : si category, un seul axe x category, sinon 1 axe category + un axe time caché sur lequel on met les données
        const isBar = [1, -1, 3].includes(stat.typeId) || this.state.chartType === BAR
        const unit = stat?.unit ? `[${stat.unit}]` : ''
        xAxis.push(Axis({
            type: 'category',
            position: 'bottom',
            data: categoryDates,
            gridIndex: grids.length - 1,
            axisLabel: { show: true, rotate: 45 },
            axisLine: { show: true },
            axisTick: { show: true },
            showSplitLine: true,
            ...(isBar ? {} : { axisPointer: { show: false } }),
        }))
        if (!isBar) { // axe caché
            xAxis.push(Axis({
                type: 'time',
                position: 'bottom',
                min: chartMinDate,
                max: chartMaxDate,
                gridIndex: grids.length - 1,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                showSplitLine: false,
            }))
        }

        const thresholds = this.state.stateThreshold === THRESHOLD ? this.props.pluviometerThresholds.filter(t => !isUndefined(t.value) && t.dataType === stat.typeId.toString()).map(t => ({
            yAxis: t.value,
            symbol: 'none',
            label: {
                show: true,
                position: 'middle',
                formatter: () => t.title ? `${t.title} : ${t.value}` : '',
            },
            lineStyle: {
                normal: {
                    color: t.htmlColor || getThresholdColor(t.color),
                    type: 'dashed',
                },
            },
        })) : []

        const measuresObj = this.props.pluvioMeasures.find(obj => obj.dataType === stat.typeId)
        const color = (() => {
            if (stat.typeId === 1) {
                return '#0000ff'
            }
            if (stat.typeId === 3) {
                return '#009dbd'
            }
            return lineColor
        })()

        // sert à mettre l'option correctement pour afficher le label au dessus des bars
        const p90Bar = !isBar ? undefined : (() => {
            const obsData = measuresObj?.measures.map(m => m.value) ?? []
            const predData = (selectedPred && selectedPred[stat.typeId] ? predMeasures[stat.typeId] : []).map(m => m.value)
            const data = [...obsData, ...predData]
            const tmp = data.length ? calculatePercentile90(orderBy(data, v => v), 1-10/data.length) : undefined
            return tmp === 0 ? 0.1 : tmp
        })()

        const data = isBar ? (measuresObj?.measures.map(m => ({
            value: [getDate(m.date, categoryFormat), m.value, unit, m],
            label: p90Bar && m.value >= p90Bar ? { show: true, position: m.value > 0 ? 'top' : 'bottom', formatter: ({ value }) => round(value[1], 0) } : undefined,
        })) || []) : (measuresObj?.measures.map(m => ({
            value: [m.date, m.value, unit, m],
            symbolSize: this.state.displayMarker ? 6 : 0,
            symbol: this.state.displayMarker ? 'circle' : 'none',
        })) || [])

        const allpredMeasures = []
        // données prévisionnelles
        this.getModelSeries(stat.typeId, grids.length - 1, cParams, isBar, xAxis.length, unit, p90Bar).forEach(modelSerie => {
            series.push(modelSerie)
            allpredMeasures.push(modelSerie.obj.bands ? modelSerie.obj.bands.flatMap(b => b.data) : modelSerie.obj.data)
        })

        const allDatas = automaticValues(data, allpredMeasures.flat())
        const yScale = yAutomaticScale(data, allpredMeasures.flat())

        const defaultOptions = {
            lineStyle: {
                opacity: this.state.displayLine ? 1 : 0,
            },
            max: () => {
                if (this.state.isLogActive) {
                    return undefined
                }
                return yScale.max
            },
            min: () => {
                if (this.state.isLogActive) {
                    return undefined
                }
                if (this.state.chartType === BAR || this.state.stack) {
                    return 0
                }
                return yScale.min
            },
        }

        yAxis.push(Axis({
            type: this.state.isLogActive ? 'log' : 'value',
            nameLocation: 'middle',
            name: `${stat.label} ${unit}`,
            gridIndex: grids.length - 1,
            nameGap: 40,
            showSplitLine: true,
            ...yScale,
            ...setLogOptions(this.state.isLogActive, allDatas),
        }))

        // série vide pour les seuils
        if (thresholds.length) {
            series.push(Line({
                name: stat.label,
                yAxisIndex: grids.length - 1,
                xAxisIndex: xAxis.length - 1,
                data: [],
                markLine: { silent: false, data: thresholds },
                areaStyle: {},
                color: 'blue',
                boundaryGap: ['50%', '50%'],
                clip: false,
                barGap: '0%',
            }))
        }

        if (data.length && data.find(m => data[0].value[3].codepoint !== m.value[3].codepoint)) { // séries par point de prélèvement
            const colors = repeatList(generateGradient(color, '#FFF', 5), 10)
            const group = groupBy(data, m => m.value[3].codepoint)
            Object.keys(group).forEach((key, idx) => {
                const pointData = group[key]
                const serieName = `${stat.label} - ${this.props.pluviometer.link_pointPrels.find(p => p.point === parseInt(key))?.name ?? 'Point inconnu'}`
                pointData.forEach(d => {
                    d.value[4] = serieName
                    d.value[5] = colors[idx]
                })
                series.push((isBar ? Bar : Line)({
                    name: serieName,
                    yAxisIndex: grids.length - 1,
                    xAxisIndex: xAxis.length - 1,
                    data: pointData,
                    color: colors[idx],
                    barGap: '-100%',
                    barWidth: `${80 / Object.keys(group).length}%`,
                    // showBackground: true,
                    showSymbol: true,
                    ...defaultOptions,
                }))
            })
        } else if (stat.typeId === -1) { // Série spéciale pour la pluie efficace
            const [superior, inferior] = partition(data, d => d.value[1] > 0)
            superior.forEach(d => {
                d.value[4] = stat.label
                d.value[5] = '#6a37ff'
            })
            inferior.forEach(d => {
                d.value[4] = stat.label
                d.value[5] = '#ff3312'
            })
            series.push(Bar({
                name: stat.label,
                barWidth: '80%',
                data: superior,
                yAxisIndex: grids.length - 1,
                xAxisIndex: xAxis.length - 1,
                markLine: { silent: false, data: thresholds },
                color: '#6a37ff',
                boundaryGap: ['50%', '50%'],
                barGap: '-100%',
                serieId: `additional${stat.typeId}`,
                showSymbol: true,
                ...defaultOptions,
            }))
            series.push(Bar({
                name: stat.label,
                barWidth: '80%',
                data: inferior,
                yAxisIndex: grids.length - 1,
                xAxisIndex: xAxis.length - 1,
                color: '#ff3312',
                boundaryGap: ['50%', '50%'],
                barGap: '-100%',
                serieId: `additional${stat.typeId}`,
                showSymbol: true,
                ...defaultOptions,
            }))
        } else {
            data.forEach(d => {
                d.value[4] = stat.label
                d.value[5] = color
            })
            series.push((isBar ? Bar : Line)({
                name: stat.label,
                yAxisIndex: grids.length - 1,
                xAxisIndex: xAxis.length - 1,
                data,
                barWidth: allpredMeasures.length ? '40%' : '80%',
                color,
                barGap: '0%',
                // showBackground: true,
                showSymbol: true,
                ...defaultOptions,
            }))
        }

        gridsHeights.push(275)
    }

    getModelSeries = (typeId, gridIndex, cParams, isBar, xAxisLength, unit, p90Bar) => {
        const { predMeasures, selectedPred, iaeauModels } = this.state.modelData
        const toEchartMeasure = isBar ? (v, measure, labelSerie, color) =>
            ({ value: [getDate(measure.date, cParams.categoryFormat), v, unit, measure, labelSerie, color], label: isBar && p90Bar && v >= p90Bar ? { show: true, position: 'top', formatter: ({ value }) => round(value[1], 0) } : undefined }) :
            (v, measure, labelSerie, color) => ({ value: [measure.date, v, unit, measure, labelSerie, color] })
        if (selectedPred && selectedPred[typeId] && predMeasures[typeId]?.length) {
            const pred = selectedPred.idModel ? { ...selectedPred[typeId], model: iaeauModels.find(m => m.idModel === selectedPred[typeId].idModel) } : selectedPred[typeId]
            const allSeries = groupBy(predMeasures[typeId], 'serieName')
            const nbLineSeries = Object.keys(allSeries).filter(key => hasValue(allSeries[key][0].value)).length

            const nbSeries = Object.keys(allSeries).length
            return Object.keys(allSeries).flatMap((serieName, idx) => {
                const orderedMeasures = orderBy(allSeries[serieName], 'date')
                const labelSerie = `${pred.source}${serieName && serieName !== 'undefined' ? ` - ${getI18nOrLabel(serieName)}` : ''}`
                if (hasValue(orderedMeasures[0].doubtMin)) {
                    const lower = {
                        showSymbol: false,
                        color: pred?.model?.color || 'grey',
                        data: orderedMeasures.map(m => toEchartMeasure(m.doubtMin, m, labelSerie, pred?.model?.color || 'grey')),
                        name: labelSerie,
                        gridIndex,
                        xAxisIndex: xAxisLength - 1,
                        yAxisIndex: gridIndex,
                    }
                    const upper = {
                        showSymbol: false,
                        color: pred?.model?.color || 'grey',
                        data: orderedMeasures.map(m => toEchartMeasure(m.doubtMax, m, labelSerie, pred?.model?.color || 'grey')),
                        name: labelSerie,
                        gridIndex,
                        xAxisIndex: xAxisLength - 1,
                        yAxisIndex: gridIndex,
                    }
                    return [MultiBand({ bands: [lower, upper], noSort: true, noGap: true, stack: `${pred.source}${idx}` })]
                }
                const color = nbLineSeries > 1 ? getColorFromPalette2(idx) : (pred?.model?.color || '#6a37ff')
                return (isBar ? Bar : Line)({
                    data: orderedMeasures.map(m => toEchartMeasure(m.value, m, labelSerie, color)),
                    name: labelSerie,
                    color,
                    connectNulls: true,
                    gridIndex,
                    xAxisIndex: xAxisLength - 1,
                    yAxisIndex: gridIndex,
                    lineStyle: {
                        normal: {
                            color,
                            width: pred?.model?.lineWidth || 2,
                            type: pred?.model?.lineType || 'dashed',
                            opacity: pred?.model?.lineOpacity || 1,
                        },
                    },
                    itemStyle: {
                        normal: {
                            color,
                        },
                    },
                    showSymbol: false,
                    barGap: '0%',
                    barWidth: `${40/nbSeries}%`,
                })
            })
        }
        return []
    }

    getLegend = () => ({
        top: 20,
        left: '5%',
        right: `${30 * 8 + 50}px`,
        type: 'scroll',
        show: this.state.displayLegend,
    })

    getToolbox = () => {
        const {
            chartType,
            stack,
            isLogActive,
            displayLegend,
            stateThreshold,
            displayMarker,
            displayLine,
        } = this.state

        const {
            pluviometer,
            pluviometerThresholds,
        } = this.props

        const stationTitle = `[${pluviometer.code}] ${pluviometer.name}`

        return {
            top: 20,
            right: '35px',
            showTitle: false,
            itemSize: 18,
            tooltip: {
                show: true,
                position: 'bottom',
            },
            feature: {
                myToolLine: {
                    show: true,
                    title: i18n.lines,
                    icon: lineIcon,
                    onclick: () => this.setState({ stack: undefined, chartType: LINE }),
                    iconStyle: {
                        borderColor: chartType === LINE ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolBar: {
                    show: true,
                    title: i18n.histogram,
                    icon: histogramIcon,
                    onclick: () => this.setState({ stack: undefined, chartType: BAR, isLogActive: false }),
                    iconStyle: {
                        borderColor: chartType === BAR && !stack ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolLog: {
                    show: true,
                    title: i18n.logarithm,
                    icon: logIcon,
                    onclick: () => this.setState(prev => ({ stack: undefined, chartType: LINE, isLogActive: !prev.isLogActive })),
                    iconStyle: {
                        borderColor: isLogActive ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolThreshold: {
                    show: !!pluviometerThresholds.length,
                    title: i18n.toggleThreshold,
                    icon: thresholdIcon,
                    onclick: () => this.setState(prev => ({ stateThreshold: prev.stateThreshold === THRESHOLD ? NOTHING : THRESHOLD })),
                    iconStyle: {
                        borderColor: stateThreshold !== NOTHING ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolToggleLegend: {
                    show: true,
                    title: i18n.toggleLegend,
                    icon: legendSymbol,
                    onclick: () => this.setState(prev => ({ displayLegend: !prev.displayLegend })),
                    iconStyle: {
                        borderColor: displayLegend ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolToggleMarker: {
                    show: true,
                    title: i18n.toggleMarker,
                    icon: chartSymbol,
                    onclick: () => this.setState(prev => ({ displayMarker: !prev.displayMarker })),
                    iconStyle: {
                        borderColor: displayMarker ? '#4d93c9' : '#5c5c5c',
                    },
                },
                myToolToggleLine: {
                    show: true,
                    title: i18n.toggleLine,
                    icon: chartLine,
                    onclick: () => this.setState(prev => ({ displayLine: !prev.displayLine })),
                    iconStyle: {
                        borderColor: displayLine ? '#4d93c9' : '#5c5c5c',
                    },
                },
                saveAsImage: {
                    show: true,
                    title: i18n.pictureExport,
                    icon: exportPictureIcon,
                    name: `${i18n.graph} ${stationTitle}`,
                    iconStyle: {
                        borderColor: '#5c5c5c',
                    },
                },
                myToolExport: {
                    show: true,
                    title: i18n.excelExport,
                    icon: exportExcelIcon,
                    onclick: () => {
                        exportFile({
                            data: this.getExportData(),
                            exportType: 'xlsx',
                            titleFile: `${i18n.overview} ${stationTitle}`,
                        })
                    },
                    iconStyle: {
                        borderColor: '#5c5c5c',
                    },
                },
                myToolFullScreen: {
                    show: true,
                    title: i18n.fullScreen,
                    icon: fullScreenIcon,
                    onclick: () => this.setState({ fullScreen: !this.state.fullScreen }),
                    iconStyle: {
                        borderColor: '#5c5c5c',
                    },
                },
                restore: {
                    show: true,
                    title: i18n.restore,
                    iconStyle: {
                        borderColor: '#5c5c5c',
                    },
                },
            },
        }
    }

    getChart = (cParams) => {
        if (!this.state.dataLoaded) {
            return <ProgressCard progress={this.state.progress} />
        }

        const [series, grids, xAxis, yAxis, gridsHeights] = [[], [], [], [], [25]]

        const stats = orderBy(this.props.pluviometerStatistics, 'typeId')
        const baseStats = stats.filter(s => s.typeId > 0 && s.typeId <= 3)
        const calulatedStats = stats.filter(s => s.typeId < 0)
        const otherStats = stats.filter(s => s.typeId > 3)
        const orderedStats = uniqBy(orderBy([...baseStats, ...calulatedStats, ...otherStats], ['order', 'typeId'], 'asc'), 'typeId')

        orderedStats.filter(stat => !hasBooleanValue(stat.showData) || stat.showData).forEach((stat, idx) => {
            this.addGrid(series, grids, xAxis, yAxis, gridsHeights, stat, cParams, stat.color || getColorFromPalettePluvio(idx))
        })

        const bigDic = {}
        const allMeasures = series.flatMap(s => s.obj.data)
        allMeasures.forEach(m => {
            const key = getDate(m.value[3].date, cParams.categoryFormat)
            bigDic[key] = [ ...(bigDic[key] ?? []), m]
        })

        const options = {
            series,
            tooltip: this.getTooltip(bigDic, cParams),
            grid: grids,
            xAxis,
            yAxis,
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
            setDataZoom: true,
            height: sum(gridsHeights),
            toolbox: this.getToolbox(),
            legend: this.getLegend(),
        }
        return (
            <EChart options={options} id='pluviometryDashboardChart' scrollable={this.state.fullScreen} hasBars/>
        )
    }

    changeGroupedDate = (group) => {
        this.setState({ pluvioGrouped: group, showGroupedDateDropdown: false, dataLoaded: false }, this.loadData)
    }

    changeGroupedDatePerzonalize = () => {
        const day = `${this.optionCumulSelected}_${this.hoursCumul * 24}`
        const hour = `${this.optionCumulSelected}_${this.hoursCumul}`
        this.setState({
            pluvioGrouped: this.state.optionDayHourSelected === DAY ? day : hour,
            dataLoaded: false,
            showGroupedDateDropdown: false,
        }, this.loadData)
    }

    personalizedGrouping = () => {
        this.setState({ pluvioGrouped: 'CUMUL' })
    }

    getChartParams = () => {
        const { minDate, maxDate, modelData, time } = this.state
        const { pluviometerStatistics } = this.props
        const { predMeasures } = modelData
        // calcul du cumul qui est réellement utilisé
        const groupModeFunc = keys(this.state.displayModes).find(mode => this.state.displayModes[mode])
        const cumulPluvio = (() => {
            if (groupModeFunc?.includes('sum')) {
                return groupModeFunc.toUpperCase().replace('SUM', 'SUM_')
            }
            if (groupModeFunc === 'auto') {
                if (minDate && minDate > moment(maxDate || moment().valueOf()).subtract('2', 'days').valueOf()) { // - de 48 heures -> cumul horaire
                    return SUM_HOUR
                }
                if (minDate && minDate > moment(maxDate || moment().valueOf()).subtract('3', 'month').valueOf()) { // - de 3 mois -> cumul journalier
                    return SUM_DAY
                }
                if (minDate && minDate > moment(maxDate || moment().valueOf()).subtract('18', 'month').valueOf()) { // - de 18 mois -> cumul hebdo
                    return SUM_WEEK
                }
                if (minDate && minDate > moment(maxDate || moment().valueOf()).subtract('5', 'years').valueOf()) { // - de 5 ans -> cumul hebdo
                    return SUM_MONTH
                }
                return SUM_YEAR
            }
            if (groupModeFunc === 'all') {
                return SUM_HOUR
            }
            if (groupModeFunc === 'personalizedGrouping') {
                const groupValue = this.state.displayModes.personalizedGroupingValue
                const nbHours = parseInt(last(groupValue.split('_')))
                if (nbHours < 24) {
                    return SUM_HOUR
                }
                if (nbHours < (24*7)) {
                    return SUM_DAY
                }
                if (nbHours < (24*30)) {
                    return SUM_WEEK
                }
                if (nbHours < (24*365)) {
                    return SUM_MONTH
                }
                return SUM_YEAR
            }
            return SUM_DAY
        })()

        // définition des dates de début et de fin du graphique. On en fixe une au début/fin de l'année si mode HISTO
        const tmpMinDate = minDate ?? minBy(pluviometerStatistics, 'startDate')?.startDate ?? moment().valueOf()
        const chartMinDateWithoutCumul = moment(time === HISTO ? moment(tmpMinDate).startOf('year').valueOf() : tmpMinDate).startOf('day').valueOf()
        const tmpMaxDate = maxDate ?? moment().valueOf()
        const tmpChartMaxDate = time === HISTO ? moment(tmpMaxDate).endOf('year').valueOf() : tmpMaxDate // maxDate sans données de prévision

        // on change la date de fin si il y a des données de prévision (date actuelle + tab sélectionnée OU max des données prévi si mode OTHER ou HISTO)
        const predMeasuresMaxDate = Object.keys(predMeasures ?? {}).length ?
            moment(max(Object.keys(predMeasures).map(key => last(orderBy(predMeasures[key], 'date')).date))).endOf('day').valueOf() : undefined
        const predMeasuresMaxDateWithTabIncluded = [HISTO, OTHER].includes(time) ? (predMeasuresMaxDate ?? moment().valueOf()) : (predMeasuresMaxDate ? moment().add(time, 'day').valueOf() : tmpChartMaxDate)
        const chartMaxDateWithoutCumul = moment(max([tmpChartMaxDate, predMeasuresMaxDateWithTabIncluded])).endOf('day').valueOf()


        // on ajuste les dates de début et de fin en fonction du cumul choisi
        const [chartMinDate, chartMaxDate] = (() => {
            switch (cumulPluvio) {
                case SUM_WEEK:
                    return [moment(chartMinDateWithoutCumul).startOf('week').valueOf(), moment(chartMaxDateWithoutCumul).endOf('week').valueOf()]
                case SUM_MONTH:
                    return [moment(chartMinDateWithoutCumul).startOf('month').valueOf(), moment(chartMaxDateWithoutCumul).endOf('month').valueOf()]
                case SUM_YEAR:
                    return [moment(chartMinDateWithoutCumul).startOf('year').valueOf(), moment(chartMaxDateWithoutCumul).endOf('year').valueOf()]
                default:
                    return [chartMinDateWithoutCumul, chartMaxDateWithoutCumul]
            }
        })()

        // calcul des catégories
        const daysTotal = getDuration(chartMinDate, chartMaxDate)
        const [categoryDates, categoryFormat] = (() => {
            switch (cumulPluvio) {
                case SUM_HOUR:
                    return [enumerateBetweenDates(chartMinDate, chartMaxDate, 'hours'), 'DD/MM HH[h]']
                case SUM_DAY:
                    return [enumerateBetweenDates(chartMinDate, chartMaxDate, 'days'), daysTotal > 365 ? 'DD/MM/YYYY' : 'DD/MM']
                case SUM_WEEK:
                    return [enumerateBetweenDates(chartMinDate, chartMaxDate, 'weeks'), daysTotal > 365 ? '[S]w YYYY' : '[S]w']
                case SUM_DECADE:
                    return [enumerateBetweenDates(chartMinDate, chartMaxDate, 'days'), daysTotal > 365 ? 'DD/MM/YYYY' : 'DD/MM']
                case SUM_MONTH:
                    return [enumerateBetweenDates(chartMinDate, chartMaxDate, 'months'), 'MM/YYYY']
                case SUM_YEAR:
                    return [enumerateBetweenDates(chartMinDate, chartMaxDate, 'years'), 'YYYY']
                default:
                    return [enumerateBetweenDates(chartMinDate, chartMaxDate, 'days'), daysTotal > 365 ? 'DD/MM/YYYY' : 'DD/MM']
            }
        })()

        return {
            cumulPluvio,
            chartMinDate,
            chartMaxDate,
            categoryDates: categoryDates.map(d => d.format(categoryFormat)),
            categoryFormat,
            chartTab: this.state.time,
            daysTotal,
        }
    }

    render() {
        if (!this.props.pluviometerStatistics.length) {
            return null
        }

        const cParams = this.getChartParams()
        return (
            <WhiteCard round noBoxShadow={this.props.noBoxShadow}>
                <Row className={this.state.fullScreen ? 'fullscreen-chart' : ''}>
                    <div className='col row no-margin padding-top-1' style={{ paddingLeft: 48 }}>
                        <Grid container sx={{ alignItems: 'center', justifyContent: 'center' }}>
                            <Grid item>
                                <MUIChartTabs
                                    time={this.state.time}
                                    onChangeTime={dates => {
                                        this.setState({ ...dates, dataLoaded: false }, this.loadData)
                                    }}
                                    stationType={STATION_TYPE_NAME.pluviometry}
                                />
                            </Grid>
                            <Grid item>
                                <ChartTabPredData
                                    stationType={STATION_TYPE_NAME.pluviometry}
                                    id={this.props.id}
                                    setParentModelData={modelData => this.setState({ modelData })}
                                    cumulPluvio={cParams.cumulPluvio}
                                />
                            </Grid>
                            <Grid item sx={{ paddingLeft: '20px' }}>
                                <SimpleChartTabsPluvio
                                    displayModes={ this.state.displayModes }
                                    displayModesUniqChoise={ true }
                                    changeParent={ v => this.setState({
                                        displayModes: v,
                                        dataLoaded: false,
                                        showGroupedDateDropdown: false,
                                    }, this.loadData) }
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div className='col s12 row no-margin'>
                        { this.getChart(cParams) }
                    </div>
                </Row>
            </WhiteCard>
        )
    }
}

PluviometerChartPanel.propTypes = {
    id: PropTypes.number,
    pluviometer: PropTypes.instanceOf(PluviometerDto),
    loadPluvioChronicMeasures: PropTypes.func,
    pluviometerStatistics: arrayOf(DtoPluviometerStats),
    pluvioMeasures: arrayOf(DtoPluvioMeasures),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    toastrWarning: PropTypes.func,
    noBoxShadow: PropTypes.bool,
    pluviometerThresholds: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerThresholdDto)),
}

const mapStateToProps = store => ({
    pluviometer: store.PluviometryReducer.pluviometer,
    pluviometerStatistics: store.PluviometryReducer.pluviometerStatistics,
    pluvioMeasures: store.PluviometryReducer.pluvioMeasures,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    pluviometerThresholds: store.PluviometryReducer.pluviometerThresholds,
})

const mapDispatchToProps = {
    loadPluvioChronicMeasures: PluviometryAction.loadPluvioChronicMeasures,
    toastrWarning: ToastrAction.warning,
}

export default connect(mapStateToProps, mapDispatchToProps)(PluviometerChartPanel)