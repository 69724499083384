import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import SieauAction from '../../../components/sieau/SieauAction'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import { getSetting, getUser } from '../../../utils/SettingUtils'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import DtoNetwork from './dto/DtoNetwork'
import QualityAction from '../../../quality/actions/QualityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import { downloadURI } from '../../../utils/ExportDataUtil'
import {
    getVisitSandreSelectChange,
} from '../../../utils/VisitUtils'
import Select from '../../../components/forms/Select'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import NumberField from 'components/forms/NumberField'
import { Grid2 } from '@mui/material'
import GenericInstallationDescription from '../installationDefault/GenericInstallationDescription'

class NetworkDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            usedWater: {},
            readMode: true,
            network: new DtoNetwork({}),
        }
    }

    componentDidMount = () => {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            this.props.fetchInstallationNetwork(this.props.installation.id).then(() => {
                this.setState({
                    network: { ...this.props.installationNetwork, idStation: this.props.installation.id },
                })
            })

            if (!this.props.installationEvents.length) {
                this.props.fetchInstallationEvents(this.props.installation.id)
            }

            this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
                if (this.props.onChangeVisit) {
                    this.setState({ readMode: false })
                } else if (this.state.readMode) {
                    this.setReadOnlyMode()
                }
            })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationsLight.length) {
            this.props.fetchInstallationsLight()
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () =>
            this.props.push('/installation'),
        )
    }

    onSave = (visit) => {
        const { installation, network } = this.state
        if (this.props.onChangeVisit) {
            this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    network,
                },
            ).then(() => this.setReadOnlyMode())
        }
        return this.props.updateInstallationNetwork(
            installation,
            network,
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, network: this.props.installationNetwork })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav(
                'installation',
                this.props.installationTable,
                this.props.installation.id,
                (s) => this.props.push(`/station/installation/${s.id}/description`),
            ),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav(
                'installation',
                this.props.installationTable,
                this.props.installation.id,
                (s) => this.props.push(`/station/installation/${s.id}/description`),
            ),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) =>
        this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangeNetwork = (newObject) => this.setState({ network: { ...this.state.network, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return (
            getSetting(
                this.props.applicationSettings,
                `PANEL_${this.state.installation.installationType}_${panel}`,
            ) !== 'hidden'
        )
    }

    render() {
        const { installation, readMode, network } = this.state
        const { sandreCodes } = this.props
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <GenericInstallationDescription
                readMode={readMode}
                installation={installation}
                onChangeInstallation={this.onChangeInstallation}
                onChangeVisit={this.onChangeVisit}
                descElements={
                    <>
                        <Grid2 size={6}>
                            <Select
                                value={network.typeCode}
                                label={i18n.type}
                                options={getSandreList(sandreCodes, SANDRE.RESEAUX_TYPE)}
                                keyvalue='code'
                                onChange={(v) => {
                                    this.onChangeNetwork({ typeCode: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.RESEAUX_TYPE, i18n.type, network.typeCode, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={network.natureCode}
                                label={i18n.nature}
                                options={getSandreList(sandreCodes, SANDRE.RESEAUX_NATURE)}
                                keyvalue='code'
                                onChange={(v) => {
                                    this.onChangeNetwork({ natureCode: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.RESEAUX_NATURE, i18n.nature, network.natureCode, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <Select
                                value={network.codeMaterial}
                                label={i18n.matter}
                                options={getSandreList(sandreCodes, SANDRE.RESEAUX_MATIERE)}
                                keyvalue='code'
                                onChange={(v) => {
                                    this.onChangeNetwork({ codeMaterial: v })
                                    this.onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.RESEAUX_MATIERE, i18n.material, network.codeMaterial, v))
                                }}
                                integerValue
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={network.length}
                                title={i18n.length}
                                onChange={(v) => {
                                    this.onChangeNetwork({ length: v })
                                    this.onChangeVisit({ previousValue: network.length, newValue: v, field: i18n.length })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={network.section}
                                title={i18n.section}
                                onChange={(v) => {
                                    this.onChangeNetwork({ section: v })
                                    this.onChangeVisit({ previousValue: network.section, newValue: v, field: i18n.section })
                                }}
                                readMode={readMode}
                            />
                        </Grid2>
                        <Grid2 size={12}>
                            <BuildingsPanel {...params} />
                        </Grid2>
                    </>
                }
            />
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (
            nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM &&
            nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)
        ) {
            return false
        }
        return true
    }
}

NetworkDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    installationNetwork: store.InstallationReducer.installationNetwork,
    installationVisits: store.InstallationReducer.installationVisits,
    installationsLight: store.InstallationReducer.installationsLight,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    push,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationNetwork: InstallationAction.updateInstallationNetwork,
    fetchInstallationNetwork: InstallationAction.fetchInstallationNetwork,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    fetchInstallationsLight: InstallationAction.fetchInstallationsLight,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkDescriptionPanel)
