import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { push } from '@lagunovsky/redux-react-router'
import AgriAction from '../../../agriAdministration/actions/AgriAction'
import FormFilterPARs from '../panels/FormFilterPARs'
import { groupBy, orderBy, reverse } from 'lodash'
import { formatMilliers, getSandreLabel, searchAllCharacters } from 'utils/StringUtil'
import { nbPerPageLabel } from 'referencial/constants/ReferencialConstants'
import ReferencialAction from 'referencial/action/ReferencialAction'
import { Accordion, AccordionDetails, AccordionSummary, Grid2 } from '@mui/material'
import { getUser } from '../../../utils/SettingUtils'
import ProgressCard from 'components/card/ProgressCard'
import TabList from 'components/list/TabList'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import CreatePARDialog from '../modals/CreatePARDialog'
import { NewTable } from 'components/datatable/NewTable'

const emptyPAR = { periode: 1, tanks: false, statusCode: 1, linkSurveys: [], onlyDeclarationsCompleted: true }

const PARsListApp = ({}) => {
    const [filter, setFilter] = useState({})
    const [sortBy, setSortBy] = useState('date')
    const [open, setOpen] = useState(false)
    const [parsLoaded, setPARsLoaded] = useState(false)

    const {
        sandreCodes,
        PARs,
        surveysWithStats,
        contributors,
        accountUser,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        PARs: store.AgriReducer.PARs,
        surveysWithStats: store.AgriReducer.surveysWithStats,
        contributors: store.ContributorReducer.contributors,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AgriAction.fetchPARs()).then(() => setPARsLoaded(true))
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!surveysWithStats.length) {
            dispatch(AgriAction.fetchSurveysWithStats())
        }
    }, [])

    useTitle(() => [{
        title: i18n.planning,
        href: 'planning/dashboard',
    }, {
        title: 'PARs',
        href: 'planning/par',
    }], [])

    useActions(() => {
        return getUser().isAdmin === '1' || getUser().metadata === '1' ? {
            new: () => setOpen(true),
        } : {}
    }, [])

    const getPanelList = (groups, title) => (
        <div className='padding-1'>
            <Accordion defaultExpanded round>
                <AccordionSummary round>
                    {title}
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                    <NewTable
                        rows={groups}
                        rowsPerPageOptions={nbPerPageLabel}
                        headers={[
                            { key: 'nameDisplay', value: i18n.name },
                            { key: 'yearDisplay', value: i18n.year },
                            'detentions',
                            { key: 'statusDisplay', value: i18n.status },
                            { key: 'uge', value: i18n.UGvalidated },
                            'lowWater',
                            'notLowWater',
                            'reserve',
                            { key: 'organismDisplay', value: i18n.organism },
                        ]}
                        onClickRow={p => dispatch(push(`/par/${p.id}`))}
                    />
                </AccordionDetails>
            </Accordion>
        </div>
    )

    const getPARsByStatus = data => {
        const groupedPARs = groupBy(data, 'statusCode')
        return Object.keys(groupedPARs).map(key => {
            return getPanelList(groupedPARs[key], getSandreLabel(sandreCodes, 'AGRI_PAR.STATUT', key))
        })
    }

    const getPARsByDate = data => {
        const groupedPARs = groupBy(data, 'year')
        return reverse(Object.keys(groupedPARs).map(key => {
            return getPanelList(groupedPARs[key], key)
        }))
    }

    const getPARsByOrganism = data => {
        const groupedPARs = groupBy(data, 'organism')
        return Object.keys(groupedPARs).map(key => {
            return getPanelList(groupedPARs[key], key === '' ? i18n.none : key)
        })
    }

    const getHash = (par) => {
        return searchAllCharacters(['name', 'year', 'description'].map(key => par[key] || ''))
    }

    const getFilteredPARs = () => {
        const yearFilter = filter.year ? PARs.filter(par => par.year === filter.year) : PARs
        const statusFilter = filter.status ? yearFilter.filter(par => par.statusCode === filter.status) : yearFilter
        const searchValueFilter = filter.searchValue ? statusFilter.filter(i => getHash(i).includes(filter.searchValue)) : statusFilter

        return orderBy(searchValueFilter, 'year', 'desc')
    }

    const getPARs = () => {
        const filteredPARs = getFilteredPARs().map((p) => ({
            ...p,
            nameDisplay: { value: p.name },
            yearDisplay: { value: p.year },
            statusDisplay: { value: getSandreLabel(sandreCodes, 'AGRI_PAR.STATUT', p.statusCode) },
            detentions: { value: p.tanks ? i18n.yes : i18n.no },
            organismDisplay: { value: p.organism ? contributors.find((c) => c.id === p.organism)?.name : '' },
            uge: { value: `${p.nbUGValidated ?? ''} / ${p.nbUG ?? ''}` },
            lowWater: { value: formatMilliers(p.volumeLowWater) },
            notLowWater: { value: formatMilliers(p.volumeNotLowWater) },
            reserve: { value: formatMilliers(p.volumeReserved) },
        }))
        switch (sortBy) {
            case 'statut':
                return getPARsByStatus(filteredPARs)
            case 'date':
                return getPARsByDate(filteredPARs)
            case 'organism':
                return getPARsByOrganism(filteredPARs)
            default:
                return []
        }
    }

    const handleOnSave = (newPAR) => {
        dispatch(AgriAction.createPAR(newPAR)).then((v) => {
            setOpen(false)
            dispatch(push(`/par/${v}`))
        })
    }

    return (
        <div className='row no-margin' style={{ padding: 10 }}>
            <Grid2 container spacing={2} alignItems='flex-start'>
                <Grid2 size={12}>
                    <FormFilterPARs onChange={obj => setFilter(obj)} />
                </Grid2>
            </Grid2>
            <TabList
                onChangeTab={(v) => setSortBy(v)}
                defaultTab='date'
                tabs={[{
                    value: 'statut',
                    label: i18n.byStatus,
                    icon: 'edit',
                },
                {
                    value: 'date',
                    label: i18n.perYear,
                    icon: 'insert_invitation',
                },
                {
                    value: 'organism',
                    label: i18n.byOrganism,
                    icon: 'person',
                }]}
            >
                { parsLoaded ? getPARs() : <ProgressCard indeterminate round /> }
            </TabList>
            <CreatePARDialog
                onValidate={handleOnSave}
                onClose={() => setOpen(false)}
                par={{ ...emptyPAR, organism: accountUser.contributorCode }}
                open={open}
            />
        </div>
    )
}

export default PARsListApp
