import { useDispatch } from 'react-redux'
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import i18n from 'simple-react-i18n'
import { Alert, Card, CardContent, Grid2, Icon, IconButton } from '@mui/material'
import Input from '../../../../../components/forms/Input'
import Button from '../../../../../components/forms/Button'
import ColorPicker from '../../../../../components/forms/ColorPicker'
import Checkbox from '../../../../../components/forms/Checkbox'
import NumberField from '../../../../../components/forms/NumberField'
import { range, padStart } from 'lodash'
import { getMonthList } from '../../../../../utils/DateUtil'
import SwapComponent from '../../../../../components/card/SwapComponent'
import { hasValue } from '../../../../../utils/NumberUtil'
import { getThresholdColorHtml } from '../../../../../utils/ColorUtil'
import Row from '../../../../../components/react/Row'
import { getUser } from 'utils/SettingUtils'
import StationAction from '../../../../../station/actions/StationAction'
import HydrometryAction from '../../../../actions/HydrometryAction'
import PluviometryAction from '../../../../../pluviometry/actions/PluviometryAction'
import { STATION_TYPE_NAME } from '../../../../../station/constants/StationConstants'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'

const getMonthKey = (m) => `month${padStart(m, 2, '0')}`

const HydroSuiviThresholds = ({
    id,
    dataType,
    stationType,
    hydrometryThresholds,
}) => {
    const dispatch = useDispatch()
    const isConsultant = getUser().consultant === '1'

    const [swap, setSwap] = useState({})
    const changeSwap = (key, v) => setSwap(({ ...swap, [key]: v }))

    const [baseThresholds, setBaseThresholds] = useState([]) // data at start
    const [tmpThresholds, setTmpThresholds] = useState([]) // data during updates in this form
    const [thresholds, setThresholds] = useState([]) // data applied (visible on chart) but not saved yet
    const [readyApply, setReadyApply] = useState(false)

    const fetchThreshold = type => {
        switch (type) {
            case STATION_TYPE_NAME.pluviometry:
                dispatch(PluviometryAction.fetchPluviometerAllThresholds())
                break
            case STATION_TYPE_NAME.hydrometry:
            default:
                dispatch(HydrometryAction.fetchHydrometricThresholds())
        }
    }

    const change = (idx, changes) => {
        const newThresholds = tmpThresholds.slice()
        newThresholds[idx] = { ...newThresholds[idx], ...changes }
        setTmpThresholds(newThresholds)
        setReadyApply(true)
    }
    const changeIsMonth = (idx, threshold, v) => {
        if (v) {
            change(idx, { value: null, isMonth: true, month01: threshold.value || 1 })
        } else {
            const nullMonth = range(1, 13).reduce((acc, m) => ({ ...acc, [getMonthKey(m)]: null }), {})
            change(idx, { value: threshold.month01, ...nullMonth, isMonth: false })
        }
    }

    const saveChanges = () => {
        const formatThreshold = {
            isOverrunThreshold: '0',
            code: '',
            htmlColor: '#000000',
            ...tmpThresholds[tmpThresholds.length-1],
            title: tmpThresholds[tmpThresholds.length-1]?.name,
            stationId: id.toString(),
            dataType: dataType.toString(),
        }
        tmpThresholds[tmpThresholds.length-1] = formatThreshold
        dispatch(StationAction.updateThresholdsWithDataTupe(id, tmpThresholds, stationType, dataType)).then(() => {
            setBaseThresholds(tmpThresholds)
            setThresholds(tmpThresholds)
            setReadyApply(false)
            fetchThreshold(stationType)
        })
    }

    const onCancel = () => {
        setTmpThresholds(baseThresholds)
        setThresholds(baseThresholds)
        setReadyApply(false)
    }

    // fetching thresholds
    useEffect(() => {
        const newThresholdsList = hydrometryThresholds.filter(t => parseInt(t.stationId) === id && parseInt(t.dataType) === dataType).map(th => {
            const isMonth = range(1, 13).some(m => hasValue(th[getMonthKey(m)]))
            return isMonth ? { ...th, value: null, isMonth } : th
        })
        setTmpThresholds(newThresholdsList)
        setThresholds(newThresholdsList)
        setBaseThresholds(newThresholdsList)
    }, [hydrometryThresholds])


    const isThresholdsCorrect = useMemo(() => {
        return !tmpThresholds.some(threshold => !threshold.name)
    }, [tmpThresholds])

    const monthList = useMemo(getMonthList, [])

    return (
        <div>
            <StyledFieldSet>
                <StyledLegend>{ `${thresholds.length} ${thresholds.length > 1 ? i18n.thresholds : i18n.threshold}` }</StyledLegend>
                {
                    tmpThresholds.map((threshold, idx) => {
                        const left = (
                            <Card sx={{ margin: '2% 5%' }} elevation={10}>
                                <CardContent elevation={10}>
                                    <Grid2 container alignItems='center' size={12} spacing={1}>
                                        { !isConsultant && (
                                            <IconButton
                                                tooltip={i18n.delete}
                                                size={'custom'}
                                                sx={{
                                                    height: '25px',
                                                    width: '25px',
                                                    color: 'white',
                                                    backgroundColor: 'red',
                                                }}
                                                onClick={() => {
                                                    setTmpThresholds(tmpThresholds.filter((_, idx2) => idx !== idx2))
                                                    setReadyApply(true)
                                                }}
                                            >
                                                <Icon
                                                    style={{
                                                        fontSize: 15,
                                                    }}
                                                >
                                                    {'delete'}
                                                </Icon>
                                            </IconButton>
                                        )}
                                        <Grid2 container alignItems={'center'} size={ isConsultant ? 11 : 10}>
                                            <Grid2 size={7}>
                                                <Input
                                                    title={i18n.name}
                                                    value={threshold.name}
                                                    onChange={v => change(idx, { name: v })}
                                                    obligatory
                                                />
                                            </Grid2>
                                            <Grid2 size={{ xs: 5 }}>
                                                <ColorPicker label={i18n.color} value={getThresholdColorHtml(threshold)} onChange={v => change(idx, { htmlColor: v, color: null })}/>
                                            </Grid2>
                                            <Grid2 container alignItems='center' spacing={1}>
                                                <Grid2 size={3}>
                                                    <NumberField
                                                        title={i18n.valueLabel}
                                                        value={ threshold.value }
                                                        onChange={v => {
                                                            change(idx, { value: v })
                                                        }}
                                                        disabled={threshold.isMonth}
                                                        floatValue
                                                    />
                                                </Grid2>
                                                <Grid2 size={5}>
                                                    <Checkbox
                                                        checked={threshold.isOverrunThreshold === '1'}
                                                        label={i18n.thresholdOverrun}
                                                        onChange={v => change(idx, { isOverrunThreshold: v ? '1' : '0' })}
                                                    />
                                                </Grid2>
                                                <Grid2 size={4}>
                                                    <Checkbox
                                                        checked={threshold.isMonth}
                                                        label={i18n.isMonthThreshold}
                                                        onChange={v => changeIsMonth(idx, threshold, v)}
                                                    />
                                                </Grid2>
                                            </Grid2>
                                        </Grid2>
                                        <Grid2 container direction={'column'} size={1}>
                                            <IconButton>
                                                <ChevronRight
                                                    onClick={() => changeSwap(idx, true)}
                                                />
                                            </IconButton>
                                        </Grid2>

                                        { !threshold.name && (
                                            <Grid2 size={12}>
                                                <Alert severity='warning' sx={{ fontSize: '1rem', lineHeight: 'inherit' }}>{i18n.pleaseFillInAllTheRequiredFields}</Alert>
                                            </Grid2>
                                        )}
                                    </Grid2>
                                </CardContent>
                            </Card>
                        )
                        const right = (
                            <Card sx={{ margin: '2% 5%' }} elevation={10}>
                                <CardContent elevation={10}>
                                    <Grid2 container spacing={1} alignItems={'center'}>
                                        <Grid2 size={1}>
                                            <IconButton>
                                                <ChevronLeft
                                                    onClick={() => changeSwap(idx, false)}
                                                />
                                            </IconButton>
                                        </Grid2>
                                        <Grid2 container size={11} alignItems={'center'}>
                                            <Grid2 size={7}>
                                                <Input
                                                    title={i18n.name}
                                                    value={threshold.name}
                                                    onChange={v => change(idx, { name: v })}
                                                />
                                            </Grid2>
                                            <Grid2 size={5}>
                                                <ColorPicker
                                                    label={i18n.color}
                                                    value={threshold.htmlColor}
                                                    onChange={v => change(idx, { htmlColor: v })}
                                                />
                                            </Grid2>
                                            {
                                                monthList.map((m, i) => {
                                                    const monthKey = getMonthKey(i + 1)
                                                    return (
                                                        <Grid2 size={3} key={i}>
                                                            <NumberField
                                                                title={m.name}
                                                                value={threshold[monthKey]}
                                                                onChange={ v => change(idx, { [monthKey]: v }) }
                                                                disabled={!threshold.isMonth}
                                                                floatValue
                                                            />
                                                        </Grid2>
                                                    )
                                                })
                                            }
                                        </Grid2>
                                    </Grid2>
                                </CardContent>
                            </Card>
                        )
                        return (
                            <SwapComponent key={idx} left={ left } right={ right } isSwapped={swap[idx]}/>
                        )
                    })
                }

                { !isConsultant && (
                    <Row className='padding-bottom-1 padding-top-1 center-align'>
                        <Button
                            tooltip={ i18n.add }
                            onClick={() => {
                                setTmpThresholds([...thresholds, {}])
                                setReadyApply(true)
                            }}
                            icon='note_add'
                            className='green btn-floating btn-large margin-left-2 '
                        />
                        <Button
                            tooltip={ i18n.cancel }
                            onClick={ onCancel }
                            icon='cancel'
                            className='red btn-floating btn-large margin-left-2 margin-right-2'
                            disabled={ !readyApply }
                        />
                        <Button
                            tooltip={ i18n.save }
                            onClick={ saveChanges }
                            icon='save'
                            className={`btn-floating btn-large ${isThresholdsCorrect && readyApply ? 'pulse' : ''}`}
                            disabled={ !isThresholdsCorrect || !readyApply }
                        />
                    </Row>
                )}
            </StyledFieldSet>
        </div>
    )
}

HydroSuiviThresholds.propTypes = {
    id: PropTypes.number,
    stationType: PropTypes.string,
    dataType: PropTypes.number,
    hydrometryThresholds: PropTypes.arrayOf(PropTypes.object),
}

export default HydroSuiviThresholds