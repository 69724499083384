import React from 'react'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'

import { sieauTooltip } from '../../../utils/FormUtils'
import ActionComponent from '../../../components/ActionComponent'
import AgriAction from '../../../agriAdministration/actions/AgriAction'
import { getLabel } from '../../../utils/StoreUtils'
import DtoExploitation from '../../../exploitations/dto/DtoExploitation'
import DtoSurvey from '../../dto/DtoSurvey'
import Row from '../../../components/react/Row'
import ContributorDto from '../../../referencial/components/contributor/dto/ContributorDto'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import HomeAction from '../../../home/actions/HomeAction'
import { CardTable, NewTable } from 'components/datatable/NewTable'
import { compact, groupBy, some, uniq } from 'lodash'
import { nbPerPageLabel, nbPerPageLabelHuge, SANDRE } from '../../../referencial/constants/ReferencialConstants'
import Card from '../../../components/card/Card'
import {
    formatMilliers,
    formatPhone,
    getI18nTitleDataLength,
    getSandreLabel,
    searchAllCharacters,
} from '../../../utils/StringUtil'
import { downloadURI, formatData } from '../../../utils/ExportDataUtil'
import { setModal } from '../../../utils/FormUtils'
import Button from '../../../components/forms/Button'
import Checkbox from '../../../components/forms/Checkbox'
import DeclarationValidationModal from '../modals/DeclarationValidationModal'
import { hasValue, round } from '../../../utils/NumberUtil'
import { getUser } from 'utils/SettingUtils'
import WatershedDto from 'referencial/components/watershed/dto/WatershedDto'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import DisplayedValue from 'components/forms/DisplayedValue'
import InstallationAction from 'installation/actions/InstallationAction'
import CulturesFamilyAction from 'referencial/components/culturesFamilies/actions/CulturesFamilyAction'
import CultureAction from 'referencial/components/cultures/actions/CultureAction'
import UsageAction from 'referencial/components/usage/actions/UsageAction'
import { Dialog, DialogActions, DialogContent, Button as ButtonMUI, Grid2, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import QualityIntegrationOverviewAction from 'quality/components/integrationOverview/actions/QualityIntegrationOverviewAction'
import ProgressCard from 'components/card/ProgressCard'
import FormFilterValidation from '../panels/FormFilterValidation'
import WaitAction from 'wait/WaitAction'
import { AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { DefaultDialogTitle, DialogActionsMUI, DialogContentMUI } from 'components/styled/Dialog'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import moment from 'moment'
import ToastrAction from 'toastr/actions/ToastrAction'
import { darkBlue } from 'utils/constants/ColorTheme'
import ExportAction from 'export/actions/ExportAction'
import { getDateExport } from 'utils/DateUtil'

const exportHeaders = ['codification', 'city', 'samplerName', 'structureType', 'nbPtReal', 'realVolume', 'nbPtPrevi', 'prevVolume', 'status']
const warningHeaders = ['codification', 'city', 'samplerName', 'warning']

class SurveyValidationApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            survey: { ...props.survey },
            open: false,
            selectedIds: [],
            selectedVolumes: [],
            selectedIdDeclaration: null,
            selectedIdExploitation: null,
            dialogTitle: '',
            filters: {
                search: null,
                city: null,
                structure: null,
                managementUnit: null,
                subManagementUnit: null,
                watershed: null,
                tanks: false,
                pointsShared: false,
                materielsShared: false,
            },
            warningProgress: 0,
            warningDataLoaded: false,
            openWarningModal: false,
            excludeValidated: true,
            openValidateVolumesModal: false,
            openValidateModal: false,
            validateChoice: undefined,
            authorisationDate: moment().valueOf(),
        }
    }

    componentDidMount() {
        this.setReadMode()
        this.props.setFormationPath('valider-une-enquete')
        this.props.setHelpLink('enquete-usagers/gerer-une-enquete', '')
        if (!this.props.culturesFamilies.length) {
            this.props.fetchCulturesFamilies()
        }

        if (!this.props.cultures.length) {
            this.props.fetchCultures()
        }

        if (!this.props.modesIrrigations.length) {
            this.props.fetchModesIrrigations()
        }

        if (!this.props.usages.length) {
            this.props.fetchUsages()
        }

        if (!this.props.analyseErrorTypes.length) {
            this.props.fetchAnalyseErrorTypes()
        }

        this.setTitle()
    }

    componentWillUnmount() {
        this.props.setFormationPath('')
        this.props.setHelpLink('', '')
    }

    componentDidUpdate = (prevProps) => {
        const { survey } = this.props
        if (prevProps.survey.idSurvey !== survey.idSurvey) {
            this.setState({ survey })
        }
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        this.setActions({
            validate: () => {
                if (this.state.selectedIds.length) {
                    this.setState({ openValidateModal: true })
                } else {
                    this.props.warning(i18n.noElementSelected)
                }
            },
            cancel: this.onCancel,
        })
    }

    getRequestControl = (excludeValidated = true) => {
        this.setState({ openWarningModal: true, warningDataLoaded: false })
        const {
            exploitations,
            contributors,
            survey,
        } = this.props
        const { filters } = this.state
        const { city, structure, managementUnit, subManagementUnit, watershed, tanks } = filters

        const declarations = survey.link_declarations
        const filteredExploitations = compact(declarations.map(dec => {
            if (excludeValidated && dec.statusCode === 4) {
                return null
            }
            const foundExploitation = exploitations.find(exploitation => exploitation.idExploitation === dec.idExploitation) || {}
            const foundContributor = contributors.find(c => c.code === foundExploitation.operatorCode) || {}
            if ((city && city !== foundContributor.cityCode) ||
                (structure && structure !== foundContributor.structureType) ||
                (managementUnit && !dec?.link_points?.find((l) => l.managementCode === parseInt(managementUnit))) ||
                (subManagementUnit && !dec?.link_points?.find((l) => l.subManagementCode === parseInt(subManagementUnit))) ||
                (watershed && !dec?.link_points?.find((l) => l.bvCode === parseInt(watershed))) ||
                (tanks && !dec?.link_points?.find((l) => l.volDetentionsEligibles))) {
                return null
            }
            if (this.searchFilter(foundExploitation, foundContributor, filters)) {
                return dec.idExploitation
            }
            return null
        }))

        this.props.controlDeclarations(
            {
                idSurvey: survey.idSurvey,
                forceCheck: true,
                year: survey.year,
            },
            filteredExploitations,
            p => this.setState({ warningProgress: p }),
            () => this.setState({ warningDataLoaded: true }),
        )
    }

    setReadMode = () => {
        const { survey } = this.state
        this.setState({ readMode: true })
        const actions = getUser()?.consultant === '1' ? {} : {
            edit: this.setEditMode,
            export: () => {
                const data = survey.link_declarations
                    .map(dec => this.getTableElement(dec))
                    .filter(d => !!d)
                return {
                    data: [{ ...data[0], headers: exportHeaders }, ...data.slice(1, data.length)],
                    titleFile: i18n.validation,
                }
            },
        }
        this.setActions(actions)
    }

    onSave = () => {
        const { selectedIds, survey, selectedVolumes, validateChoice } = this.state
        this.props.validateDeclarations(survey.idSurvey, selectedIds, selectedVolumes, validateChoice === 1, validateChoice === 2).then(() => {
            this.props.fetchSurvey(survey.idSurvey).then(() => {
                this.setState({ survey: this.props.survey, selectedVolumes: [], selectedIds: [] })
            })
        })
        this.setReadMode()
    }

    onCancel = () => {
        this.setState({ selectedIds: [], selectedVolumes: [] })
        this.setReadMode()
    }

    setTitle = () => {
        const { survey } = this.props
        const { params } = this.props
        this.props.setTitle([{
            title: i18n.planning,
            href: 'planning',
        }, {
            title: i18n.surveys,
            href: 'planning/surveys',
        }, {
            title: `${survey.name || ''} [${survey.year || ''}]`,
            href: `survey/${params.id}`,
        }, {
            title: i18n.validation,
            href: `survey/${params.id}/validation`,
        }])
    }

    createAndDownloadEdition = (id) => {
        this.props.getEditionDeclaration(id).then((json) => downloadURI(json.targetPath))
    }

    createSynthesisEdition = (id) => {
        this.props.getSynthesisDeclaration(id).then((json) => downloadURI(json.targetPath))
    }

    getPopup = (id, codification = '') => {
        const url = (window.location.href || document.URL).split('#')[0]
        const displayDev = url.includes('nice') || url.includes('localhost') || url.includes('psp.integration')
        setModal({
            title: `${i18n.exportName} ${codification}`,
            content: (
                <div>
                    <Row>
                        <Button col={3} onClick={() => this.createAndDownloadEdition(id)} title={i18n.exportEmpty} className='margin-right-1' />
                        <Button col={2} onClick={() => this.createSynthesisEdition(id)} title={i18n.synthesis} className='margin-right-1' />
                        <Button col={2} onClick={() => this.props.exportOasis(id)} title={i18n.oasis} className='margin-right-1' />
                    </Row>
                    <Row displayIf={ displayDev }>
                        <fieldset>
                            <legend>&nbsp;{ 'En dev uniquement' }&nbsp;</legend>
                            <Row>
                                <Button col={ 3 } onClick={ () => this.props.exportEdition(id) } title='Export Vide JSON' className='margin-right-1'/>
                                <Button col={ 3 } onClick={ () => this.props.exportEditionFull(id) } title='Synthèse JSON' className='margin-right-1'/>
                            </Row>
                        </fieldset>
                    </Row>
                </div>
            ),
        })
    }

    onSelectAll = (value, tableData) => {
        const { selectedIds, selectedVolumes } = this.state
        const selectedIdsArray = selectedIds.map(si => si.idExploitation)
        const allFinalizedDec = tableData
            .filter(td => !selectedIdsArray.includes(td.idExploitation) && !hasValue(td?.volDetentionsEligibles?.value))
            .map(td => ({ idExploitation: td.idExploitation, attributionComment: ' ' }))
        if (value) {
            this.setState({
                selectedIds: [
                    ...selectedIds,
                    ...allFinalizedDec,
                ],
            })
        } else {
            const tableDataIds = tableData.map(td => td.idExploitation)
            this.setState({
                selectedIds: selectedIds.filter(si => !tableDataIds.includes(si.idExploitation)),
                selectedVolumes: selectedVolumes.filter(si => !tableDataIds.includes(si.idExploitation)),
            })
        }
    }

    isAllChecked = (tableData) => {
        const { selectedIds } = this.state
        const selectedIdsArray = selectedIds.map(si => si.idExploitation)
        return !tableData.filter(td => !selectedIdsArray.includes(td.idExploitation)).length
    }

    getCollapsibleTable = (tableData, title, active = false) => {
        const { readMode } = this.state
        const checkBox = !readMode && title === 'finalizedDeclarations' ? { key: 'checkBox', value: <Checkbox checked={this.isAllChecked(tableData)} onChange={value => this.onSelectAll(value, tableData)} /> } : undefined
        return (
            <AccordionMUI defaultExpanded={active}>
                <AccordionSummaryMUI round>
                    {`${i18n[title]} (${tableData.length} ${getI18nTitleDataLength(i18n.element, i18n.elements, tableData.length)})`}
                </AccordionSummaryMUI>
                <NewTable
                    rows={tableData}
                    headers={compact([
                        checkBox,
                        'codification',
                        'city',
                        'sampler',
                        { key: 'structureType', value: i18n.structure },
                        { key: 'nbPtReal', value: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbPtRealWrap}</span> },
                        { key: 'realVolume', value: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.realVolumeWrap}</span> },
                        { key: 'nbPtPrevi', value: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbPtPreviWrap}</span> },
                        { key: 'prevVolume', value: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.prevVolumeWrap}</span> },
                        { key: 'volDetentionsEligibles', value: <span style={{ whiteSpace: 'pre-wrap' }}>{'m3 de\nsubstitution'}</span> },
                        { key: 'nullvalue', value: ' ' },
                    ])}
                    rowsPerPageOptions={nbPerPageLabelHuge}
                />
            </AccordionMUI>
        )
    }

    getCell = (statusCode, value, declaration, options) => {
        const { readMode } = this.state
        return {
            value,
            onClick: !readMode && statusCode !== 4 ? (() => {
                this.props.waitStart()
                this.props.fetchInstallationsByExploitationId(declaration.idExploitation)
                this.props.fetchDeclaration(declaration.idDeclaration).then(() => {
                    this.props.waitStop()
                    this.setState({
                        selectedIdExploitation: declaration.idExploitation,
                        selectedIdDeclaration: declaration.idDeclaration,
                        open: true,
                        dialogTitle: declaration.dialogTitle,
                    })
                })
            }) : undefined,
            ...options,
        }
    }

    onCheckChange = (value, declaration) => {
        const { selectedIds, selectedVolumes } = this.state
        if (value) {
            this.setState({
                selectedIds: [
                    ...selectedIds,
                    { idExploitation: declaration.idExploitation, attributionComment: ' ' },
                ],
            })
        } else {
            this.setState({
                selectedIds: selectedIds.filter(sd => sd.idExploitation !== declaration.idExploitation),
                selectedVolumes: selectedVolumes.filter(sv => sv.idExploitation !== declaration.idExploitation),
            })
        }
    }

    searchFilter = (foundExploitation, foundContributor, filters) => {
        return searchAllCharacters([
            foundExploitation.codification,
            foundContributor.name,
        ]).includes(searchAllCharacters(filters.search))
    }

    getTableElement = (dec) => {
        const {
            selectedIds,
            filters,
        } = this.state
        const { city, structure, managementUnit, subManagementUnit, watershed, tanks } = filters

        const {
            exploitations,
            contributors,
            sandreCodes,
            citiesIndex,
        } = this.props
        const foundExploitation = exploitations.find(exploitation => exploitation.idExploitation === dec.idExploitation) || {}
        const foundContributor = contributors.find(c => c.code === dec.idContributor) || {}
        const declaration = { ...dec, dialogTitle: `${foundContributor.name} [${foundExploitation.codification}]` }
        if ((city && city !== foundContributor.cityCode) ||
            (structure && structure !== foundContributor.structureType) ||
            (managementUnit && !dec?.link_points?.find((l) => l.managementCode === parseInt(managementUnit))) ||
            (subManagementUnit && !dec?.link_points?.find((l) => l.subManagementCode === parseInt(subManagementUnit))) ||
            (watershed && !dec?.link_points?.find((l) => l.bvCode === parseInt(watershed))) ||
            (tanks && !dec?.link_points?.find((l) => l.volDetentionsEligibles))) {
            return null
        }
        if (this.searchFilter(foundExploitation, foundContributor, filters)) {
            const cityName = (citiesIndex[foundContributor.cityCode] || {}).name
            // const volDetentionsEligibles = compact(dec?.link_points?.map((l) => l.volDetentionsEligibles)).reduce((acc, p) => acc + p, 0) || null
            return {
                ...foundExploitation,
                ...declaration,
                ...foundContributor,
                dataRealVolume: declaration.realVolume || 0,
                dataPrevVolume: declaration.prevVolume || 0,

                // table values
                codification: this.getCell(dec.statusCode, foundExploitation.codification, declaration),
                city: this.getCell(dec.statusCode, foundContributor.cityCode ? `${cityName || ''} [${foundContributor.cityCode || ''}]` : '', declaration),
                sampler: this.getCell(dec.statusCode, (
                    <span
                        className='clickable'
                        {...sieauTooltip(`${i18n.email} : ${foundContributor.email || ''} \n${i18n.phoneTel} : ${formatPhone(foundContributor.phoneTel) || ''} \n${i18n.mobile} : ${formatPhone(foundContributor.mobile) || ''}`, null, 'bottom')}
                    >
                        { foundContributor.name }
                    </span>
                ), declaration, { sortValue: foundContributor.name },
                ),
                structureType: this.getCell(dec.statusCode, getSandreLabel(sandreCodes, SANDRE.INTERVENANTS_TYPE_STRUCTURE, foundContributor.structureType), declaration),
                nbPtReal: this.getCell(dec.statusCode, formatMilliers(declaration.nbPtReal), declaration),
                realVolume: this.getCell(dec.statusCode, hasValue(declaration.realVolume) ? `${formatMilliers(declaration.realVolume) || 0} m3` : '', declaration),
                nbPtPrevi: this.getCell(dec.statusCode, formatMilliers(declaration.nbPtPrevi), declaration),
                prevVolume: this.getCell(dec.statusCode, hasValue(declaration.prevVolume) ? `${formatMilliers(declaration.prevVolume) || 0} m3` : '', declaration),
                volDetentionsEligibles: this.getCell(dec.statusCode, hasValue(dec.volDetentionsEligibles) ? `${formatMilliers(round(dec.volDetentionsEligibles, 0))} m3` : '', declaration),
                nullvalue: {
                    value: <i className='material-icons clickable' {...sieauTooltip(i18n.export, null, 'bottom')}>file_download</i>,
                    onClick: () => this.getPopup(declaration.idDeclaration, foundExploitation.codification),
                },
                checkBox: {
                    value: (
                        <Checkbox
                            checked={selectedIds.some(sd => sd.idExploitation === declaration.idExploitation)}
                            onChange={value => this.onCheckChange(value, declaration)}
                            disabled={hasValue(dec.volDetentionsEligibles)}
                            tooltip={i18n.mustBeValidatedManuallyClickRow}
                        />
                    ),
                },

                // export values
                validatedDec: declaration.lastStep === 5 && declaration.statusCode === 3 ? i18n.yes : i18n.no,
                cityLabel: foundContributor.cityCode ? cityName : '',
                samplerName: foundContributor.name,
                phoneTel: formatPhone(foundContributor.phoneTel),
                mobile: formatPhone(foundContributor.mobile),
                status: getSandreLabel(sandreCodes, SANDRE.DECLARATIONS_STATUT, declaration.statusCode),
            }
        }
        return null
    }

    getTableData = () => {
        const {
            survey,
            filters: { pointsShared, materielsShared },
        } = this.state
        const declarations = survey.link_declarations.filter((d) => {
            const findPointsShared = pointsShared ? (() => {
                const idsPoints = d.link_points.map((p) => p.idInstallation)
                const find = survey.link_declarations.find((dbis) => dbis.link_points.find((pbis) => dbis.idExploitation !== d.idExploitation && idsPoints.includes(pbis.idInstallation)))
                return !!find
            })() : false
            const findMaterielsShared = materielsShared ? (() => {
                const codesMateriels = uniq(d.link_points.flatMap((p) => p?.materiels?.split(', ')))
                const find = survey.link_declarations.find((dbis) => dbis.link_points.find((pbis) => dbis.idExploitation !== d.idExploitation && codesMateriels.some(c => pbis?.materiels?.includes(c))))
                return !!find
            })() : false
            if (!pointsShared && !materielsShared) {
                return true
            }
            return findPointsShared || findMaterielsShared
        })
        return {
            finalizedDeclarations: [],
            validatedDeclarations: [],
            uncompletedDeclarations: [],
            ...groupBy(declarations
                .map(dec => this.getTableElement(dec))
                .filter(d => !!d), s => {
                switch (s.statusCode) {
                    case 3:
                        return 'finalizedDeclarations'
                    case 4:
                        return 'validatedDeclarations'
                    default:
                        return 'uncompletedDeclarations'
                }
            }),
        }
    }

    onChange = (value) => this.setState(value)

    validateAttribution = (idAndComment, volumes) => {
        this.setState(({ selectedIds, selectedVolumes }) => ({
            selectedIds: [
                ...selectedIds.filter(si => idAndComment.idExploitation !== si.idExploitation),
                idAndComment,
            ],
            selectedVolumes: [
                ...selectedVolumes.filter(sv => !(some(volumes, v => v.idProvisionalUsage === sv.idProvisionalUsage))),
                ...volumes,
            ],
        }))
    }

    onClose = () => {
        this.setState({
            selectedIdExploitation: null,
            selectedIdDeclaration: null,
            open: false,
            dialogTitle: '',
        })
    }

    getVolumes = (data) => {
        const volumeReal = Math.round(data.reduce((acc, d) => acc + (d.dataRealVolume || 0), 0))
        const volumePrevi = Math.round(data.reduce((acc, d) => acc + (d.dataPrevVolume || 0), 0))
        const volumeAttributed = Math.round(data.reduce((acc, d) => acc + (d.attributedVolume || 0), 0))
        const volumeAuthorized = Math.round(data.reduce((acc, d) => acc + (d.authorizedVolume || 0), 0))
        return { volumeReal, volumePrevi, volumeAttributed, volumeAuthorized }
    }

    getWarningData = (warnings) => {
        const {
            exploitations,
            contributors,
            citiesIndex,
            analyseErrorTypes,
        } = this.props

        return warnings.map(warning => {
            const foundExploitation = exploitations.find(exploitation => exploitation.idExploitation === warning.idExploitation) || {}
            const foundContributor = contributors.find(c => c.code === foundExploitation.operatorCode) || {}
            const cityName = (citiesIndex[foundContributor.cityCode] || {}).name
            return {
                ...warning,
                codification: foundExploitation.codification,
                samplerName: foundContributor.name,
                city: foundContributor.cityCode ? `${cityName} [${foundContributor.cityCode || ''}]` : '',
                warning: getLabel(analyseErrorTypes, warning.warning),
                headers: warningHeaders,
            }
        })
    }

    onCloseModal = () => this.setState({ warningProgress: 0, warningDataLoaded: false, openWarningModal: false })

    onSelectValidResquests = (declarationsWarnings) => {
        const { survey } = this.props
        const declarations = survey.link_declarations.filter((d) => !declarationsWarnings.find((w) => w.idExploitation === d.idExploitation) && !hasValue(compact(d?.link_points?.map((l) => l.volDetentionsEligibles)).reduce((acc, p) => acc + p, 0) || null))
        this.onSelectAll(true, declarations)
        this.onCloseModal()
        this.setEditMode()
    }

    getRequestControlModal = () => {
        const {
            warningDataLoaded,
            warningProgress,
            openWarningModal,
        } = this.state
        const { declarationsWarnings } = this.props
        const data = this.getWarningData(declarationsWarnings)
        return (
            <Dialog
                open={openWarningModal}
                fullWidth
                maxWidth='lg'
            >
                <DialogContent>
                    {warningDataLoaded && (
                        <CardTable
                            title={i18n.requestsControls}
                            rows={data}
                            rowsPerPageOptions={nbPerPageLabel}
                            headers={warningHeaders}
                            actions={[{
                                icon: 'file_download',
                                color: 'white',
                                tooltip: i18n.export,
                                onClick: () => this.props.export(formatData(data), 'xlsx', `${i18n.control}_${getDateExport()}`),
                            }]}
                            color={darkBlue}
                        />
                    ) || (
                        <ProgressCard
                            progress={warningProgress}
                            withMessage
                            withCancel
                            whenCancel={this.onCloseModal}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    {warningDataLoaded && (
                        <>
                            <ButtonMUI
                                onClick={() => this.onSelectValidResquests(declarationsWarnings)}
                                variant='outlined'
                            >
                                {i18n.validateCompliantFiles}
                            </ButtonMUI>
                            <ButtonMUI
                                onClick={this.onCloseModal}
                                variant='contained'
                                color='primary'
                            >
                                {i18n.close}
                            </ButtonMUI>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        )
    }

    getValidateModal = () => {
        const { openValidateModal, validateChoice } = this.state
        return (
            <Dialog
                open={openValidateModal}
                fullWidth
                maxWidth='lg'
            >
                <DefaultDialogTitle
                    title={i18n.selectValidationType}
                    onClose={() => this.setState({ openValidateModal: false })}
                />
                <DialogContentMUI>
                    <p className='bold'>{i18n.onceValidationDoneDescr}</p>
                    <RadioGroup
                        name='idUsage'
                        value={validateChoice}
                        onChange={(e) => this.setState({ validateChoice: Number(e.target.value) })}
                    >
                        <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label={i18n.validateContactsOnly}
                            sx={{ color: 'black' }}
                        />
                        <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label={i18n.validateDeclaWithoutTransferVolumes}
                            sx={{ color: 'black' }}
                        />
                        <FormControlLabel
                            value={3}
                            control={<Radio />}
                            label={i18n.validateDeclaAndTransferVolumes}
                            sx={{ color: 'black' }}
                        />
                    </RadioGroup>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <ButtonMUI
                        variant='outlined'
                        onClick={() => this.setState({ openValidateModal: false })}
                    >
                        {i18n.cancel}
                    </ButtonMUI>
                    <ButtonMUI
                        variant='contained'
                        onClick={this.onSave}
                        disabled={!validateChoice}
                    >
                        {i18n.confirm}
                    </ButtonMUI>
                </DialogActionsMUI>
            </Dialog>
        )
    }

    render = () => {
        const {
            survey,
            open,
            selectedIdDeclaration,
            selectedIdExploitation,
            selectedVolumes,
            dialogTitle,
            excludeValidated,
            openValidateVolumesModal,
            authorisationDate,
        } = this.state

        if (survey.idSurvey) {
            const { finalizedDeclarations = [], validatedDeclarations = [], uncompletedDeclarations = [] } = this.getTableData()
            const { volumeReal, volumePrevi, volumeAttributed, volumeAuthorized } = this.getVolumes([...finalizedDeclarations, ...validatedDeclarations, ...uncompletedDeclarations])

            return (
                <div className='padding-1' style={{ paddingBottom: 80 }}>
                    <Card className='padding-1' round>
                        <FormFilterValidation setFilters={(filters) => this.setState({ filters })} />
                    </Card >
                    <Card noMargin={false} className='margin-top-1' round>
                        <Grid2 size={12} container direction='row' alignContent='flex-start' sx={{ padding: '10px 10px 10px 10px' }}>
                            <Grid2 size={6} container justifyContent='space-evenly' alignItems='flex-start' direction='column'>
                                <DisplayedValue
                                    label={i18n.realVolume}
                                    value={`${formatMilliers(volumeReal) || 0} m3`}
                                />
                                <DisplayedValue
                                    label={i18n.requestedVolume}
                                    value={`${formatMilliers(volumePrevi) || 0} m3`}
                                />
                                <DisplayedValue
                                    label={i18n.allocatedVolume}
                                    value={`${formatMilliers(volumeAttributed) || 0} m3`}
                                />
                                <DisplayedValue
                                    label={i18n.allowedVolume}
                                    value={`${formatMilliers(volumeAuthorized) || 0} m3`}
                                />
                            </Grid2>
                            <Grid2 size={6} container justifyContent='space-between' alignItems='flex-end' direction='column'>
                                <Checkbox
                                    label={i18n.alsoCheckValidatedRequests}
                                    checked={!excludeValidated}
                                    onChange={value => this.setState({ excludeValidated: !value })}
                                />
                                <ButtonMUI
                                    className='margin-top-1'
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.getRequestControl(excludeValidated)}
                                >
                                    {i18n.requestsControl}
                                </ButtonMUI>
                                <ButtonMUI
                                    className='margin-top-1'
                                    variant='contained'
                                    color='primary'
                                    onClick={() => this.setState({ openValidateVolumesModal: true })}
                                >
                                    {i18n.validateAttributedVolumes}
                                </ButtonMUI>
                            </Grid2>
                        </Grid2>
                    </Card >
                    <Card noMargin={false} className='margin-top-1' round>
                        {this.getCollapsibleTable(finalizedDeclarations, 'finalizedDeclarations', true)}
                    </Card >
                    <Card noMargin={false} className='margin-top-1' round>
                        {this.getCollapsibleTable(validatedDeclarations, 'validatedDeclarations')}
                    </Card >
                    <Card noMargin={false} className='margin-top-1' round>
                        {this.getCollapsibleTable(uncompletedDeclarations, 'uncompletedDeclarations')}
                    </Card >
                    {open && (
                        <DeclarationValidationModal
                            open={open}
                            title={dialogTitle}
                            idDeclaration={selectedIdDeclaration}
                            idExploitation={selectedIdExploitation}
                            selectedVolumes={selectedVolumes}
                            onChange={this.onChange}
                            validateAttribution={this.validateAttribution}
                            onClose={this.onClose}
                        />
                    )}
                    {this.getRequestControlModal()}
                    {this.getValidateModal()}
                    {openValidateVolumesModal && (
                        <Dialog open>
                            <DialogContentMUI>
                                <Grid2 container>
                                    <Grid2 size={12} className='margin-top-2 margin-bottom-2'>
                                        {i18n.validateAttributedVolumesConfirm}
                                    </Grid2>
                                    <Grid2 size={6} className='margin-top-3 margin-bottom-3'>
                                        <SimpleDatePicker
                                            calendarPosition='right'
                                            label={i18n.authorisationDate}
                                            onChange={(v) => this.setState({ authorisationDate: v })}
                                            value={authorisationDate}
                                        />
                                    </Grid2>
                                </Grid2>
                            </DialogContentMUI>
                            <DialogActionsMUI>
                                <ButtonMUI
                                    variant='outlined'
                                    onClick={() => {
                                        this.setState({ openValidateVolumesModal: false })
                                    }}
                                >
                                    {i18n.cancel}
                                </ButtonMUI>
                                <ButtonMUI
                                    variant='contained'
                                    onClick={() => {
                                        this.setState({ openValidateVolumesModal: false })
                                        this.props.validateVolumesSurvey(survey.idSurvey, authorisationDate)
                                    }}
                                >
                                    {i18n.confirm}
                                </ButtonMUI>
                            </DialogActionsMUI>
                        </Dialog>
                    )}
                </div>
            )
        }
        return null
    }
}

SurveyValidationApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    exploitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoExploitation)),
    survey: PropTypes.instanceOf(DtoSurvey),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    watersheds: PropTypes.arrayOf(PropTypes.instanceOf(WatershedDto)),
    citiesIndex: PropTypes.shape({}),
}

const mapStateToProps = store => ({
    survey: store.AgriReducer.survey,
    exploitations: store.AgriReducer.exploitations,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    cities: store.CityReducer.cities,
    citiesIndex: store.CityReducer.citiesIndex,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    watersheds: store.WatershedReducer.watersheds,
    culturesFamilies: store.CulturesFamilyReducer.culturesFamilies,
    cultures: store.CultureReducer.cultures,
    modesIrrigations: store.AgriReducer.modesIrrigations,
    usages: store.UsageReducer.usages,
    declarationsWarnings: store.AgriReducer.declarationsWarnings,
    analyseErrorTypes: store.QualityIntegrationOverviewReducer.analyseErrorTypes,
})

const mapDispatchToProps = {
    push,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    fetchSurvey: AgriAction.fetchSurvey,
    setTitle: HomeAction.setTitle,
    getEditionDeclaration: AgriAction.getEditionDeclaration,
    getSynthesisDeclaration: AgriAction.getSynthesisDeclaration,
    exportOasis: AgriAction.exportOasis,
    exportEdition: AgriAction.exportEditionJson,
    exportEditionFull: AgriAction.exportEditionFullJson,
    validateDeclarations: AgriAction.validateDeclarations,
    fetchDeclaration: AgriAction.fetchDeclaration,
    fetchInstallationsByExploitationId: InstallationAction.fetchInstallationsByExploitationId,
    fetchCulturesFamilies: CulturesFamilyAction.fetchCulturesFamilies,
    fetchCultures: CultureAction.fetchCultures,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchUsages: UsageAction.fetchUsages,
    controlDeclarations: AgriAction.controlDeclarations,
    validateVolumesSurvey: AgriAction.validateVolumesSurvey,
    fetchAnalyseErrorTypes: QualityIntegrationOverviewAction.fetchAnalyseErrorTypes,
    setFormationPath: HomeAction.setFormationPath,
    setHelpLink: HomeAction.setHelpLink,
    warning: ToastrAction.warning,
    export: ExportAction.export,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyValidationApp)
