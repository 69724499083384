/* eslint-disable camelcase */
import { Button, Grid2 } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'

const ServicesFilters = ({
    setSearch = () => { },
    setCity = () => { },
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    const [search, setTmpSearch] = useState('')
    const [city, setTmpCity] = useState('')

    const setFilters = () => {
        setSearch(search)
        setCity(city)
    }

    return (
        <StyledFieldSet style={{ marginTop: 0 }}>
            <StyledLegend>{i18n.filters}</StyledLegend>
            <Grid2 container alignItems='center' spacing={1}>
                <Input
                    title={i18n.search}
                    value={search}
                    onChange={setTmpSearch}
                    onEnterKeyPress={setFilters}
                />
                <Select
                    label={i18n.city}
                    value={city}
                    onChange={setTmpCity}
                    options={cities }
                    displayWithCode
                />
                <Grid2 offset='auto'>
                    <Button
                        variant='contained'
                        onClick={setFilters}
                    >
                        {i18n.search}
                    </Button>
                </Grid2>
            </Grid2>
        </StyledFieldSet>
    )
}

ServicesFilters.propTypes = {
    setSearch: PropTypes.func,
    setCity: PropTypes.func,
}

export default ServicesFilters
