import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import CampaignDto from '../../../../campaign/dto/CampaignDto'
import Select from '../../../../components/forms/Select'
import QualityAction from '../../../../quality/actions/QualityAction'
import DtoThreshold from '../../../../quality/dto/DtoThreshold'


class AquasysPivEauPanel extends Component {
    state = {
        isThresholdsShowed: false,
    }

    render() {
        const { qualityCampaigns, thresholds } = this.props
        return (
            <div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col s3'>
                        <label>{i18n.importFile}</label>
                    </div>
                    <div className='file-field col s9 no-padding'>
                        <div className='input-field col s3'>
                            <a className='waves-effect waves-light btn col s12'>
                                {i18n.importLabel}
                                <input type='file' multiple disabled={!this.props.isEditMode} />
                            </a>
                        </div>
                        <div className='input-field col s9 file-path-wrapper'>
                            <input className='file-path validate' type='text' disabled placeholder={i18n.selectFile} />
                        </div>
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className=' input-field col s3'>
                        <label>{i18n.defineDirectory}</label>
                    </div>
                    <div className='input-field col s9'>
                        <input id='txtDirDef' type='text' placeholder={i18n.selectDirectory} />
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col s3'>
                        <label>{i18n.extensionToProceed}</label>
                    </div>

                    <div className='input-field col s9'>
                        <input id='txtExtensionToProceed' type='text' placeholder={i18n.xlsExample} />
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col s3'>
                        <label>{i18n.fileRenaming}</label>
                    </div>
                    <div className='input-field col s9'>
                        <input id='txtFileRenaming' type='text' placeholder={i18n.renamingExample} />
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col s3'>
                        <label>{i18n.defaultHeader}</label>
                    </div>
                    <div className='input-field col s9'>
                        <input id='txtHeaderExample' type='text' placeholder={i18n.headerExample} />
                        <label className='tinyLabel'>
                            <p>
                                Pour la définition des entêtes, se rapporter au manuel DT Aquasys - Manuel de synchronisation des Données.pdf.
                            </p>
                        </label>
                    </div>
                </div>
                <div className='row no-margin'>
                    <div className='input-field col s12'>
                        <label className='tinyLabel'>
                            Si un fichier integrationSIQ.properties est présent dans le répertoire, il sera mis à jour par le paramétrage ci dessous.
                        </label>
                    </div>
                </div>
                <div className='row no-margin valign-wrapper'>
                    <div className='input-field col s3'>
                        <label>{i18n.campaign}</label>
                    </div>
                    <Select
                        options={
                            qualityCampaigns.map(({ id, name }) => ({
                                value: id,
                                label: name,
                            }))
                        }
                        value='0'
                        col={9}
                    />
                </div>
                <div className='row padding-top-10-px valign-wrapper'>
                    <div className='bold col s3'>
                        <label>{i18n.controlData}</label>
                    </div>
                    <div className='switch col s1'>
                        <label>
                            <input
                                type='checkbox'
                                id='controlData'
                                onChange={ () => this.setState(({ isThresholdsShowed }) => ({ isThresholdsShowed: !isThresholdsShowed })) }
                                checked={this.state.isThresholdsShowed}
                            />
                            <span className='lever' />
                        </label>
                    </div>
                    {
                        this.state.isThresholdsShowed ? (
                            <Select
                                options={
                                    thresholds.sort((a, b) => +a.code - +b.code)
                                        .map(({ code, name }) => ({
                                            value: code,
                                            label: `${name} [${code}]`,
                                        }))
                                }
                                col={8}
                                value='0'
                            />
                        ) : (
                            <div className='col s9' />
                        )
                    }
                </div>
            </div>
        )
    }
}

AquasysPivEauPanel.propTypes = {
    qualityCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(CampaignDto)),
    thresholds: PropTypes.arrayOf(PropTypes.instanceOf(DtoThreshold)),
    // fetchQualityCampaigns: PropTypes.func,
    fetchThresholds: PropTypes.func,
    isEditMode: PropTypes.bool,
}

const mapStateToProps = store => ({
    qualityCampaigns: store.QualityReducer.qualityCampaigns,
    thresholds: store.QualityReducer.thresholds,
})

const mapDispatchToProps = {
    // fetchQualityCampaigns: CampaignAction.fetchQualityCampaigns,
    fetchThresholds: QualityAction.fetchThresholds,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AquasysPivEauPanel)