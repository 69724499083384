import { withStyles } from '@mui/styles'
import ActionComponent from 'components/ActionComponent'
import HomeAction from 'home/actions/HomeAction'
import LayerDto from 'home/dto/LayerDto'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import Textarea from '../../../components/forms/Textarea'
import IAEauAction from '../../../iaeau/IAEauAction'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import { FR, nbPerPageLabelTiny, SANDRE, SUPPORTED_LANGUAGES } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import StationAction from '../../../station/actions/StationAction'
import DtoPublicPicture from '../../../station/dto/DtoPublicPicture'
import { sieauTooltip } from '../../../utils/FormUtils'
import { getSetting, getSettingInt, getSettingJson, getUser, isDeveloppmentEnv } from '../../../utils/SettingUtils'
import { getSandreList } from '../../../utils/StoreUtils'
import { i18nize } from '../../../utils/StringUtil'
import AdministrationAction from '../../actions/AdministrationAction'
import { NAME_LOGIN_PICTURE, NAME_MENU_PICTURE } from '../../constants/AdministrationConstants'
import SieauParameterDto from '../../dto/SieauParameterDto'
import { CardTable } from 'components/datatable/NewTable'
import { compact } from 'lodash'
import { linkContributorsDepartments } from 'carto/constants/CartoConstants'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { hasValue } from 'utils/NumberUtil'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import SettingSms from './smsConfig/SetttingSms'
import { DEFAULT_CONTRIBUTOR_TYPE } from '../user/constants/UserConstants'
import { Card, Grid2 } from '@mui/material'
import { darkBlue } from 'utils/constants/ColorTheme'

const regexBase = {
    uppercase: '(?=(.*?[A-Z])',
    numeric: ')(?=(.*?[0-9])',
    special: ')(?=(.*?[^\\w\\s])',
    characters: ').',
    end: '$',
}


const styles = (theme) => ({
    tab: {
        fontWeight: 'bold',
    },
    tabSelected: {
        fontWeight: 'bold',
        backgroundColor: `${theme.palette.third.main} !important`,
        color: 'white !important',
    },
})

const regexType = ['uppercase', 'numeric', 'special', 'characters']

class SettingApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            applicationSettings: props.applicationSettings,
            files: [],
            tab: 'settings',
        }
    }

    getNameSettings(setting) {
        const { applicationSettings } = this.props
        return setting === NAME_MENU_PICTURE ?
            getSetting(applicationSettings, NAME_MENU_PICTURE):
            getSetting(applicationSettings, NAME_LOGIN_PICTURE)
    }

    componentDidMount() {
        const { sandreCodes, menuPicture, loginPicture, themeLayers, contributors } = this.props
        this.props.setHelpLink('administration', '93')
        this.props.setTitle([{
            title: i18n.administration,
            href: 'administration',
        }, {
            title: i18n.settings,
            href: 'administration',
        }])
        if (!sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        if (!menuPicture.length) {
            this.props.fetchPublicPicture(this.getNameSettings(NAME_MENU_PICTURE), NAME_MENU_PICTURE)
        }
        if (!loginPicture.length) {
            this.props.fetchPublicPicture(this.getNameSettings(NAME_LOGIN_PICTURE), NAME_LOGIN_PICTURE)
        }
        if (!themeLayers.length) {
            this.props.fetchThemeLayers()
        }
        if (!contributors.length) {
            this.props.fetchContributors()
        }
        this.setReadOnlyMode()
    }

    onChangeSetting = (setting, value) => {
        const stateObj = this.state.applicationSettings.filter(e => e.parameter !== setting)
        this.setState({ applicationSettings: [...stateObj, { parameter: setting, value }] })
    }

    onSaveSettings = () => {
        const applicationSettings = this.state.applicationSettings.filter(e => e.value !== getSetting(this.props.applicationSettings, e.parameter))
        if (applicationSettings.length) {
            this.props.updateSieauParameters(applicationSettings)
        } else if (!applicationSettings.length && !this.state.files) {
            this.props.info(i18n.nothingToChange)
        }
        this.setReadOnlyMode()
    }

    saveFile = () => {
        const { files } = this.state
        this.props.addPublicFiles(files).then(() => {
            this.setState({ files: [] })
            const menuPicture = files.find(f => f.stationType === NAME_MENU_PICTURE)
            const loginPicture = files.find(f => f.stationType === NAME_LOGIN_PICTURE)
            if (menuPicture) {
                this.props.fetchPublicPicture(menuPicture.name, NAME_MENU_PICTURE)
            }
            if (loginPicture) {
                this.props.fetchPublicPicture(loginPicture.name, NAME_LOGIN_PICTURE)
            }
            this.props.fetchApplicationSettings()
        })
    }

    setEditMode = () => {
        const actions = {
            cancel: () => {
                $('.tooltipped').tooltip('remove')
                this.setState({ applicationSettings: this.props.applicationSettings })
                this.setReadOnlyMode()
            },
        }
        if (!(getUser().consultant === '1')) {
            actions.save = () => {
                $('.tooltipped').tooltip('remove')
                if (this.state.files.length > 0) {
                    this.saveFile()
                }
                this.onSaveSettings()
            }
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({ disabled: true })
        if (!(getUser().consultant === '1')) {
            const actions = {
                edit: () => {
                    $('.tooltipped').tooltip('remove')
                    this.setState({ disabled: false })
                    this.setEditMode()
                },
            }
            this.setActions(actions)
        } else {
            this.setActions({})
        }
    }

    isTrue = (value) => {
        return ['True', '1', 'OUI', true].includes(value)
    }

    onChangePolicy = (v, type) => {
        const parser = this.parsePolicy()
        const regex = regexType.reduce((acc, t) => {
            const tmp = acc + regexBase[t]
            if (type === t) {
                return `${tmp}{${v && v !== 'undefined' ? v : 0},}`
            }
            return `${tmp}{${parser[t]},}`
        }, '') + regexBase.end
        this.onChangeSetting('passwordPolicy', regex)
    }

    uploadLoginPicture = (event) => {
        this.onUploadFile(event, NAME_LOGIN_PICTURE)
    }

    uploadMenuPicture = (event) => {
        this.onUploadFile(event, NAME_MENU_PICTURE)
    }

    onUploadFile = (event, fileType) => {
        // Read File
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader()
            const file = event.target.files[0]
            reader.onload = (e) => {
                const files = (() => {
                    return [
                        ...this.state.files.filter(f => f.stationType !== fileType),
                        { name: file.name, content: e.target.result, stationType: fileType },
                    ]
                })()
                this.setState({ files })
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    parsePolicy = () => {
        const policy = getSetting(this.state.applicationSettings, 'passwordPolicy') || ''
        const regexSplit = policy.split(',}').map(e => e.split('{')[1])
        return {
            uppercase: regexSplit[0],
            numeric: regexSplit[1],
            special: regexSplit[2],
            characters: regexSplit[3],
        }
    }

    getActive = panel => {
        const { classes } = this.props
        const { tab } = this.state
        return panel === tab ? classes.tabSelected : classes.tab
    }

    onChangeContributorsData = (values, actualValue, newValue) => {
        this.onChangeSetting(linkContributorsDepartments, JSON.stringify(compact(values.map((v) => {
            if (v.contributor === actualValue.contributor) {
                return newValue
            }
            return v
        }))))
    }

    getDataContributorsTable = (values) => {
        const { disabled } = this.state
        const { contributorsIndex, contributors } = this.props
        return values.map((v) => {
            return {
                contributor: {
                    value: disabled ? (contributorsIndex[v.contributor] || {}).name : (
                        <MultiContributorsAutocomplete
                            col={12}
                            options={contributors}
                            values={v.contributor}
                            label=''
                            onChange={c => this.onChangeContributorsData(values, v, { contributor: c, departments: v.departments })}
                            disabled={disabled}
                        />
                    ),
                    sortValue: v.contributor,
                    style: { width: '100%' },
                },
                departments: {
                    value: disabled ? v.departments : (
                        <Input
                            col={12}
                            value={v.departments}
                            onChange={d => this.onChangeContributorsData(values, v, { contributor: v.contributor, departments: d })}
                            placeholder={'ex : 44,85,92'}
                            disabled={!hasValue(v.contributor)}
                        />
                    ),
                    sortValue: v.departments,
                    style: { width: '100%' },
                },
            }
        })
    }

    getPanel = () => {
        const { tab, applicationSettings, disabled: disabledState } = this.state
        const { sandreCodes, menuPicture, loginPicture, themeLayers } = this.props

        const logLevelOption = [
            { value: '', label: '' },
            { value: '1', label: i18n.debug },
            { value: '2', label: i18n.information },
            { value: '3', label: i18n.warning },
            { value: '4', label: i18n.error },
        ]

        const editionNameOption = [
            { value: '', label: '' },
            { value: '1', label: i18n.login },
            { value: '2', label: i18n.loginAndCityName },
            { value: '3', label: i18n.cityNameAndLogin },
        ]

        const contributorTypeList = getSandreList(sandreCodes, SANDRE.CONTRIBUTOR_TYPES).map(c => ({ ...c, name: `[${c.code}] ${c.name}` }))

        const disabled = { disabled: disabledState, active: !disabledState }

        const parser = this.parsePolicy()

        const regexLine = isDeveloppmentEnv() ? (
            <Grid2 size={12}>
                <Input title={ i18n.passwordPolicy } value={ getSetting(applicationSettings, 'passwordPolicy') } disabled />
            </Grid2>
        ) : null
        const change = this.onChangeSetting

        const imgStyle = { width: '100%', height: '150px', objectFit: 'cover' }
        const imgMenu = menuPicture.url || '../pictures/background.jpg'
        const dataContributorsDpts = getSettingJson(applicationSettings, linkContributorsDepartments) || []
        switch (tab) {
            default: case 'settings':
                return (
                    <Card sx={{ border: (theme) => `2px solid ${theme.palette.third.main}`, padding: '10px' }}>
                        <StyledFieldSet className='margin-1 padding-1'>
                            <StyledLegend>{i18n.directories}</StyledLegend>
                            <Grid2 container spacing={1}>
                                <Grid2 size={6}>
                                    <Input title={ i18n.modelDirectory } value={ getSetting(applicationSettings, 'CheminModeles') }
                                        onChange={ v => change('CheminModeles', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input title={ i18n.exportDirectory } value={ getSetting(applicationSettings, 'CheminExport') }
                                        onChange={ v => change('CheminExport', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input title={ i18n.editionDirectory } value={ getSetting(applicationSettings, 'CheminEdition') }
                                        onChange={ v => change('CheminEdition', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Select
                                        label={i18n.editionName}
                                        options={editionNameOption}
                                        keyLabel='label'
                                        onChange={v => change('Nommage_Fichier', v)}
                                        value={ getSetting(applicationSettings, 'Nommage_Fichier')}
                                        noNullValue
                                        {...disabled}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input title={ i18n.documentDirectory } value={ getSetting(applicationSettings, 'CheminDocuments') }
                                        onChange={ v => change('CheminDocuments', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input title={ i18n.pictureDirectory } value={ getSetting(applicationSettings, 'CheminPhotos') }
                                        onChange={ v => change('CheminPhotos', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input title={ i18n.scriptRdirectory } value={ getSetting(applicationSettings, 'CheminR') }
                                        onChange={ v => change('CheminR', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Checkbox label={i18n.stationDirectoryOrganization} checked={ this.isTrue(getSetting(applicationSettings, 'docFolderOrganisation')) } { ...disabled }
                                        onChange={(e) => change('docFolderOrganisation', e ? 'OUI' : 'NON')}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1 padding-1'>
                            <StyledLegend>{i18n.contributors}</StyledLegend>
                            <Grid2 container spacing={1}>
                                <Grid2 size={6}>
                                    <Select
                                        label={i18n.referentContributorType}
                                        options={contributorTypeList}
                                        onChange={v => change('contributorTypeAdministrator', v.toString())}
                                        value={getSetting(applicationSettings, 'contributorTypeAdministrator') || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR}
                                        {...disabled}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Select label={`${i18n.type} ${i18n.contributor} ${i18n.operator}`} value={getSetting(applicationSettings, 'contributorTypeOperator') || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR }
                                        options={contributorTypeList} onChange={ v => change('contributorTypeOperator', v.toString()) } { ...disabled } keyLabel='name'
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1 padding-1'>
                            <StyledLegend>{i18n.cartography}</StyledLegend>
                            <Grid2 container spacing={1}>
                                <Grid2 size={6} container>
                                    <Grid2 size={6}>
                                        <Select
                                            label={i18n.layerESU}
                                            options={themeLayers}
                                            noNullValue
                                            keyLabel='name'
                                            keyValue='id'
                                            onChange={v => change('themeCartoESU', v.toString())}
                                            value={getSettingInt(applicationSettings, 'themeCartoESU')}
                                            {...disabled}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            label={i18n.layerESO}
                                            options={themeLayers}
                                            noNullValue
                                            keyLabel='name'
                                            keyValue='id'
                                            onChange={v => change('themeCartoESO', v.toString())}
                                            value={getSettingInt(applicationSettings, 'themeCartoESO')}
                                            {...disabled}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input title={ i18n.arrestDepartments } value={ getSetting(applicationSettings, 'arrestDepartments') }
                                            onChange={ v => change('arrestDepartments', v) } { ...disabled }
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input title={ i18n.meteoAlertDepartments } value={ getSetting(applicationSettings, 'meteoAlertDepartments') }
                                            onChange={ v => change('meteoAlertDepartments', v) } { ...disabled }
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <NumberField title={i18n.numberOfStationsMaxOnMap} value={getSettingInt(applicationSettings, 'cartoStationsMaxNumber') || 15000}
                                            onChange={ v => change('cartoStationsMaxNumber', v.toString()) } { ...disabled }
                                        />
                                    </Grid2>
                                </Grid2>
                                <Grid2 size={6}>
                                    <CardTable
                                        title={i18n.linksContributorsDepartments}
                                        rows={this.getDataContributorsTable(dataContributorsDpts)}
                                        rowsPerPageOptions={nbPerPageLabelTiny}
                                        headers={['contributor', 'departments']}
                                        lineActions={[{
                                            icon: 'clear',
                                            onClick: (c) => change(linkContributorsDepartments, JSON.stringify(compact(dataContributorsDpts.filter((v) => v.contributor !== c.contributor.sortValue)))),
                                            displayed: !disabledState,
                                        }]}
                                        actions={disabledState ? [] : [{
                                            onClick: () => change(linkContributorsDepartments, JSON.stringify([...dataContributorsDpts.filter((v) => v.contributor), { contributor: null }])),
                                            icon: 'note_add',
                                            tooltip: i18n.add,
                                            color: 'white',
                                        }]}
                                        color={darkBlue}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1 padding-1'>
                            <StyledLegend>{i18n.others}</StyledLegend>
                            <Grid2 container spacing={1}>
                                <Grid2 size={3}>
                                    <Checkbox label={i18n.microsoftOffice} checked={ this.isTrue(getSetting(applicationSettings, 'exportExcel')) } { ...disabled }
                                        onChange={(e) => change('exportExcel', e ? '1' : '0')}
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <Checkbox label={i18n.libreOffice} checked={ this.isTrue(getSetting(applicationSettings, 'exportCalc')) } { ...disabled }
                                        onChange={(e) => change('exportCalc', e ? '1' : '0')}
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <Checkbox label={i18n.csv} checked={ this.isTrue(getSetting(applicationSettings, 'exportCSV')) } { ...disabled }
                                        onChange={(e) => change('exportCSV', e ? '1' : '0')}
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <Checkbox label={i18n.exploitationAEP} checked={ this.isTrue(getSetting(applicationSettings, 'ExploitationAEP')) } { ...disabled }
                                        onChange={(e) => change('ExploitationAEP', e ? 'OUI' : 'NON')}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Select
                                        label={i18n.logLevel}
                                        options={logLevelOption}
                                        noNullValue
                                        keyLabel='label'
                                        onChange={v => change('LogLevel', v)}
                                        value={getSetting(applicationSettings, 'LogLevel')}
                                        {...disabled}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <NumberField
                                        title={i18n.purgeJobDays}
                                        onChange={v => change('jobPurgeNbDays', v)}
                                        value={ getSettingInt(applicationSettings, 'jobPurgeNbDays') || 90 }
                                        {...disabled}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Checkbox label={i18n.internationalContext} checked={ this.isTrue(getSetting(applicationSettings, 'ContexteInternational')) } { ...disabled }
                                        onChange={(e) => change('ContexteInternational', e ? 'OUI' : 'NON')}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Checkbox label={i18n.dontSendMail} checked={ this.isTrue(getSetting(applicationSettings, 'dontSendMailOnCreateUser')) } { ...disabled }
                                        onChange={(e) => change('dontSendMailOnCreateUser', e ? 'OUI' : 'NON')}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </Card>
                )
            case 'security':
                return (
                    <Card sx={{ border: (theme) => `2px solid ${theme.palette.third.main}`, padding: '10px' }}>
                        <Grid2 container spacing={1}>
                            { regexLine }
                            <Grid2 size={12}>
                                <Textarea title={ i18n.securityPasswordDescription } value={ getSetting(applicationSettings, 'securityPasswordDescription') }
                                    onChange={ (v) => change('securityPasswordDescription', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField title={ i18n.minimumNumberCharacters } min={ parseInt(parser.numeric) + parseInt(parser.uppercase) + parseInt(parser.special) } value={ parser.characters }
                                    onChange={ (v) => this.onChangePolicy(v, 'characters') } { ...disabled } dispatchOnNull
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField title={ i18n.minimumNumberDigits } min={0} value={ parser.numeric }
                                    onChange={ (v) => this.onChangePolicy(v, 'numeric') } { ...disabled } dispatchOnNull
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField title={ i18n.minimumNumberUppercases } min={0} value={ parser.uppercase }
                                    onChange={ (v) => this.onChangePolicy(v, 'uppercase') } { ...disabled } dispatchOnNull
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField title={ i18n.minimumNumberSpecials } min={0} value={ parser.special }
                                    onChange={ (v) => this.onChangePolicy(v, 'special') } { ...disabled } dispatchOnNull
                                />
                            </Grid2>
                            <Grid2 size={3}>
                                <NumberField title={ i18n.maximumNumberTry } value={ parseInt(getSetting(applicationSettings, 'maximumNumberTry') || 5) }
                                    onChange={ (v) => change('maximumNumberTry', v ? v.toString() : '5') }{ ...disabled }
                                />
                            </Grid2>
                        </Grid2>
                    </Card>
                )
            case 'support':
                return (
                    <Card sx={{ border: (theme) => `2px solid ${theme.palette.third.main}`, padding: '10px' }}>
                        <Grid2 container spacing={1}>
                            <Grid2 size={4}>
                                <Input title={ i18n.supportMailAddress } value={ getSetting(applicationSettings, 'supportMailAddress') }
                                    onChange={ (v) => change('supportMailAddress', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Input title={ i18n.supportPhoneNumber } value={ getSetting(applicationSettings, 'supportPhoneNumber') }
                                    onChange={ (v) => change('supportPhoneNumber', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Input title={ i18n.supportContactSchedule } value={ getSetting(applicationSettings, 'supportContactSchedule') }
                                    onChange={ (v) => change('supportContactSchedule', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Input title={ i18n.companyAddress } value={ getSetting(applicationSettings, 'companyAddress') }
                                    onChange={ (v) => change('companyAddress', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Input title={ i18n.siretNumber } value={ getSetting(applicationSettings, 'siretNumber') }
                                    onChange={ (v) => change('siretNumber', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Input title={ i18n.companyPhoneNumber } value={ getSetting(applicationSettings, 'companyPhoneNumber') }
                                    onChange={ (v) => change('companyPhoneNumber', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Input title={ i18n.urlHelpCenter } value={ getSetting(applicationSettings, 'urlHelpCenter') }
                                    onChange={ (v) => change('urlHelpCenter', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Input title={ i18n.urlFormationPlatform } value={ getSetting(applicationSettings, 'urlFormationPlatform') }
                                    onChange={ (v) => change('urlFormationPlatform', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Input title={ i18n.formationToken } value={ getSetting(applicationSettings, 'formationToken') }
                                    onChange={ (v) => change('formationToken', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Input title={ i18n.locationHelp } value={ getSetting(applicationSettings, 'FichierAide') }
                                    onChange={ v => change('FichierAide', v) } { ...disabled }
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Select
                                    label={i18n.supportLanguage}
                                    options={i18nize(SUPPORTED_LANGUAGES)}
                                    noNullValue
                                    keyLabel='label'
                                    value={getSetting(applicationSettings, 'supportLanguage') || FR}
                                    onChange={v => change('supportLanguage', v)}
                                    {...disabled}
                                />
                            </Grid2>
                        </Grid2>
                    </Card>
                )
            case 'appParams':
                return (
                    <Card sx={{ border: (theme) => `2px solid ${theme.palette.third.main}`, padding: '10px' }}>
                        <StyledFieldSet className='margin-1 padding-1 padding-top-0'>
                            <StyledLegend>{i18n.application}</StyledLegend>
                            <Grid2 container spacing={1} sx={{ paddingTop: '10px' }}>
                                <Grid2 size={4}>
                                    <Input title={ i18n.applicationTitle } value={ getSetting(applicationSettings, 'applicationTitle') }
                                        onChange={ (v) => change('applicationTitle', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input title={ i18n.applicationName } value={ getSetting(applicationSettings, 'applicationName') }
                                        onChange={ (v) => change('applicationName', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input title={ i18n.homePage } value={ getSetting(applicationSettings, 'PageApresConnexion') }
                                        onChange={ v => change('PageApresConnexion', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input title={ i18n.applicationFavicon } value={ getSetting(applicationSettings, 'applicationFavicon') }
                                        onChange={ (v) => change('applicationFavicon', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input title={ i18n.applicationLogoConnexion } value={ getSetting(applicationSettings, 'applicationLogoConnexion') }
                                        onChange={ (v) => change('applicationLogoConnexion', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Input title={ i18n.twitterAccount } value={ `@${getSetting(applicationSettings, 'TwitterUser') || ''}` }
                                        onChange={ v => change('TwitterUser', v.substr(1)) } { ...disabled }
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1 padding-1 padding-top-0'>
                            <StyledLegend>{i18n.meteoNappes}</StyledLegend>
                            <Grid2 container spacing={1} sx={{ paddingTop: '10px' }}>
                                <Grid2 size={6}>
                                    <Input title={ 'Email Météo Nappes' } value={ getSetting(applicationSettings, 'MeteoNappesEmail') }
                                        onChange={ v => change('MeteoNappesEmail', v) } { ...disabled }
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input title={ `${i18n.password} Météo Nappes` } value={ getSetting(applicationSettings, 'MeteoNappesPassword') }
                                        onChange={ v => change('MeteoNappesPassword', v) } { ...disabled } passwordType
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1 padding-1 padding-top-0'>
                            <StyledLegend>{i18n.smsMail}</StyledLegend>
                            <SettingSms
                                change={change}
                                applicationSettings={this.state.applicationSettings}
                                disabled={disabled}
                            />
                        </StyledFieldSet>
                        <StyledFieldSet className='margin-1 padding-1 padding-top-0'>
                            <StyledLegend>{i18n.others}</StyledLegend>
                            <Grid2 container spacing={1} sx={{ paddingTop: '10px' }}>
                                <Grid2 size={12}>
                                    <Input
                                        title={i18n.googleCalendarId}
                                        value={getSetting(applicationSettings, 'GoogleCalendarID')}
                                        onChange={(v) => change('GoogleCalendarID', v)}
                                        {...disabled}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input title={ i18n.version } value={ process.env.REACT_APP_VERSION } disabled/>
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input title={ i18n.lastCommit } value={ process.env.REACT_APP_COMMIT } disabled/>
                                </Grid2>
                                <Grid2 size={6}>
                                    <label style={{ fontSize: '1rem', fontWeight: 'bold', marginLeft: '1.2rem' }}>{i18n.menuPicture}</label>
                                </Grid2>
                                <Grid2 size={6}>
                                    <label style={{ fontSize: '1rem', fontWeight: 'bold', marginLeft: '1.2rem' }}>{i18n.loginPicture}</label>
                                </Grid2>
                                <Grid2 size={6}>
                                    {(!disabled.disabled && menuPicture.url) && (<a className='btn-floating halfway-fab waves-effect waves-light blue' style={{ left: '46%', bottom: '265px' }}>
                                        <i className='material-icons clickable' onClick={() => this.props.deleteFile({
                                            name: menuPicture.name,
                                            fileType: 'public',
                                        })}
                                        >clear</i>
                                    </a>)}
                                    <img src={imgMenu} alt={i18n.menuPicture} style={imgStyle} />
                                </Grid2>
                                <Grid2 size={6}>
                                    {loginPicture.url && (
                                        <>
                                            {!disabled.disabled && (<a className='btn-floating halfway-fab waves-effect waves-light blue' style={{ right: '0px', bottom: '265px' }}>
                                                <i className='material-icons clickable' onClick={() => this.props.deleteFile({
                                                    name: loginPicture.name,
                                                    fileType: 'public',
                                                })}
                                                >clear</i>
                                            </a>)}
                                            <img src={loginPicture.url} alt={i18n.loginPicture} style={imgStyle} />
                                        </>
                                    )}
                                </Grid2>
                            </Grid2>
                            {!disabled.disabled && (
                                <Grid2 container spacing={1} justifyContent='center'>
                                    <Grid2 size={6}>
                                        <div className='file-field input-field'>
                                            <div className='btn'>
                                                <span>{ i18n.changeMenuPicture }</span>
                                                <input
                                                    accept='image/*'
                                                    id='uploadFile'
                                                    type='file'
                                                    multiple
                                                    onChange={ this.uploadMenuPicture }
                                                />
                                            </div>
                                            <div className='file-path-wrapper'>
                                                <input className='file-path validate' type='text' />
                                            </div>
                                        </div>
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <div className='file-field input-field'>
                                            <div className='btn'>
                                                <span>{ i18n.changeLoginPicture }</span>
                                                <input
                                                    accept='image/*'
                                                    id='uploadFile'
                                                    type='file'
                                                    multiple
                                                    onChange={ this.uploadLoginPicture }
                                                />
                                            </div>
                                            <div className='file-path-wrapper'>
                                                <input className='file-path validate' type='text' />
                                            </div>
                                        </div>
                                    </Grid2>
                                    <h6>{i18n.messageChangeMenuPicture}</h6>
                                </Grid2>
                            )}
                        </StyledFieldSet>
                    </Card>
                )
        }
    }

    render() {
        return (
            <div className='row no-margin padding-top-1' style={{ padding: '0 0.75rem' }}>
                <ul className='col s12 tabs' style={{ padding: 0 }}>
                    <li className='tab col s3' id='settings'onClick={() => this.setState({ tab: 'settings' })} {...sieauTooltip(i18n.settings, null, 'bottom')}>
                        <a className={this.getActive('settings')}>{i18n.settings}</a>
                    </li>
                    <li className='tab col s3' id='security' onClick={() => this.setState({ tab: 'security' })} {...sieauTooltip(i18n.security, null, 'bottom')}>
                        <a className={this.getActive('security')}>{i18n.security}</a>
                    </li>
                    <li className='tab col s3' id='support' onClick={() => this.setState({ tab: 'support' })} {...sieauTooltip(i18n.support, null, 'bottom')}>
                        <a className={this.getActive('support')}>{i18n.support}</a>
                    </li>
                    <li className='tab col s3' id='appParams' onClick={() => this.setState({ tab: 'appParams' })} {...sieauTooltip(i18n.applicationParameters, null, 'bottom')}>
                        <a className={this.getActive('appParams')}>{i18n.applicationParameters}</a>
                    </li>
                </ul>
                <div className='col s12' style={{ padding: 0 }}>
                    {this.getPanel()}
                </div>
            </div>
        )
    }
}

SettingApp.propTypes = {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    themeLayers: PropTypes.arrayOf(PropTypes.instanceOf(LayerDto)),
    addPublicFiles: PropTypes.func,
    fetchPublicPicture: PropTypes.func,
    menuPicture: PropTypes.instanceOf(DtoPublicPicture),
    loginPicture: PropTypes.instanceOf(DtoPublicPicture),
    deleteFile: PropTypes.func,
    setGenericMeasures: PropTypes.func,
    classes: PropTypes.instanceOf(PropTypes.object),
    setHelpLink: PropTypes.func,
    setTitle: PropTypes.func,
    fetchSandreCodes: PropTypes.func,
    updateSieauParameters: PropTypes.func,
    info: PropTypes.func,
    fetchApplicationSettings: PropTypes.func,
}

const mapStateToProps = store => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    menuPicture: store.AdministrationReducer.menuPicture,
    loginPicture: store.AdministrationReducer.loginPicture,
    themeLayers: store.HomeReducer.themeLayers,
    contributors: store.ContributorReducer.contributors,
    contributorsIndex: store.ContributorReducer.contributorsIndex,
})

const mapDispatchToProps = {
    addPublicFiles: AdministrationAction.addPublicFiles,
    fetchPublicPicture: AdministrationAction.fetchPublicPicture,
    deleteFile: StationAction.deleteFile,
    setGenericMeasures: IAEauAction.setGenericMeasures,
    setHelpLink: HomeAction.setHelpLink,
    setTitle: HomeAction.setTitle,
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    updateSieauParameters: AdministrationAction.updateSieauParameters,
    fetchThemeLayers: HomeAction.fetchThemeLayers,
    fetchContributors: ContributorAction.fetchContributors,
    info: ToastrAction.info,
    fetchApplicationSettings: AdministrationAction.fetchApplicationSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingApp))
