/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getExport } from '../../../../../utils/linkUtils'
import { getLabel } from '../../../../../utils/StoreUtils'
import { CardTable } from 'components/datatable/NewTable'
import { greyBlue } from 'utils/constants/ColorTheme'
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import DtoInstallationBorehole from 'installation/dto/borehole/DtoInstallationBorehole'
import { hasValue } from 'utils/NumberUtil'
import SimpleTextArea from 'components/forms/SimpleTextArea'

const headers = ['aquifer', 'depth', 'comment']

const AquiferDialog = ({
    idStation,
    selectedElement = {},
    onChange = () => { },
    isOpen = false,
    setIsOpen = () => { },
}) => {
    const {
        aquifers,
    } = useSelector(store => ({
        aquifers: store.AquiferReducer.aquifers,
    }), shallowEqual)

    const [stateElement, setStateElement] = useState({ ...selectedElement, idStation, order: 1 })

    const onChangeElement = (changes) => setStateElement(prev => ({ ...prev, ...changes }))

    const onSave = () => {
        onChange(stateElement)
        setIsOpen(false)
    }

    return (
        <Dialog
            maxWidth='lg'
            open={isOpen}
        >
            <DefaultDialogTitle title={i18n.stratigraphy} onClose={() => setIsOpen(false)} />
            <DialogContent>
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={1}>
                            <Grid2 size={6}>
                                <Select
                                    value={stateElement.aquiferCode}
                                    label={i18n.aquifer}
                                    options={aquifers}
                                    onChange={v => onChangeElement({ aquiferCode: v })}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    value={stateElement.depth}
                                    title={i18n.depth}
                                    onChange={v => onChangeElement({ depth: v })}
                                    floatValue
                                />
                            </Grid2>

                            <Grid2 size={12}>
                                <SimpleTextArea
                                    value={stateElement.comment}
                                    title={i18n.comment}
                                    onChange={v => onChangeElement({ comment: v })}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onSave()
                    }}
                    variant='contained'
                >
                    {i18n.register}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

AquiferDialog.propTypes = {
    idStation: PropTypes.number,
    selectedElement: PropTypes.object,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

const BoreholeAquiferTable = ({
    borehole = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        aquifers,
    } = useSelector(store => ({
        aquifers: store.AquiferReducer.aquifers,
    }), shallowEqual)

    const [isOpen, setIsOpen] = useState(false)
    const [selectedElement, setSelectedElement] = useState({})

    const stationAquifers = borehole.link_aquifers.map((c, index) => ({
        ...c,
        index,
    }))

    const tableData = stationAquifers.map(c => ({
        ...c,
        aquifer: getLabel(aquifers, c.aquiferCode),
    })).filter(a => a.order === 1)

    const exportAction = getExport(tableData, i18n.aquifers, headers)
    const actions = !readMode ? [{
        icon: 'note_add',
        tooltip: i18n.add,
        color: 'white',
        onClick: () => {
            setSelectedElement({})
            setIsOpen(true)
        },
    }, exportAction] : [exportAction]

    const updateElements = (newElement) => {
        if (!hasValue(selectedElement.index)) {
            onChange({ link_aquifers: [...stationAquifers, newElement] }, newElement)
        } else {
            onChange({
                link_aquifers: stationAquifers.map((d, i) => i === selectedElement.index ? newElement : d),
            }, newElement)
        }
    }

    const lineActions = [{
        icon: 'close',
        onClick: (element) => onChange({ link_aquifers: stationAquifers.filter(l => l.index !== element.index) }),
        displayed: !readMode,
    }, {
        icon: 'edit',
        onClick: (element) => {
            setSelectedElement(stationAquifers.find(l => l.index === element.index))
            setIsOpen(true)
        },
        displayed: !readMode,
    }]

    return (
        <>
            <CardTable
                title={i18n.stratigraphy}
                rows={tableData}
                headers={headers}
                actions={actions}
                color={greyBlue}
                lineActions={lineActions}
            />
            {isOpen && (
                <AquiferDialog
                    idStation={borehole.id || borehole.idStation}
                    selectedElement={selectedElement}
                    onChange={updateElements}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
        </>
    )
}

BoreholeAquiferTable.propTypes = {
    borehole: PropTypes.instanceOf(DtoInstallationBorehole),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default BoreholeAquiferTable