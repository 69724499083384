/* eslint-disable no-console */
import ToastrAction from 'toastr/actions/ToastrAction'
import { isDeveloppmentEnv } from './SettingUtils'
import AppStore from 'store/AppStore'
import i18n from 'simple-react-i18n'

const latestGreaterThanCurrent = (latestVersion, currentVersion) => {
    const latestBuildVersion = Number(latestVersion?.replace('.', '')) || 0
    const currentBuildVersion = Number(currentVersion?.replace('.', '')) || 0

    return latestBuildVersion !== currentBuildVersion
}

const cacheChecker = (toasterOnly = false) => {
    if (!isDeveloppmentEnv()) {
        fetch('/meta.json', { cache: 'reload' })
            .then((response) => response.json())
            .then((meta) => {
                const currentVersion = process.env.REACT_APP_VERSION
                const { version: latestVersion } = meta

                if (latestGreaterThanCurrent(latestVersion, currentVersion)) {
                    // ne pas supp c'est volontaire
                    console.log(`Current version : ${currentVersion}`)
                    console.log(`Latest version : ${latestVersion}`)
                    if (caches) {
                        caches.keys().then((names) => {
                            for (const name of names) {
                                caches.delete(name)
                            }
                        })
                    }
                    if (toasterOnly) {
                        AppStore.dispatch(ToastrAction.info(
                            i18n.updateAppNotif,
                            `${i18n.update} !`,
                            {
                                preventDuplicates: true,
                                tapToDismiss: false,
                                closeButton: true,
                                timeOut: 0,
                                extendedTimeOut: 0,
                                positionClass: 'toast-top-right',
                                toastClass: 'toast non-opac',
                            },
                        ))
                    } else {
                        window.location.reload(true)
                    }
                }
            })
    }
}

export { cacheChecker }