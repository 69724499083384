import ReactECharts from 'echarts-for-react'
import echarts from 'echarts/lib/echarts'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { isUndefined, max, maxBy, min, minBy, round, uniqBy } from 'lodash'
import { exportExcelIcon, exportPictureIcon, getAxisIntervalFormatter, setYOptions, toEchartsData, yAutomaticScaleValues } from 'components/echart/EChartUtils'
import { getDate, getFullDate } from 'utils/DateUtil'
import DtoPluvioMeasures from 'pluviometry/dto/measures/DtoPluvioMeasures'
import { exportFile } from 'utils/ExportDataUtil'
import { getColorFromPalettePluvio } from 'utils/ColorUtil'

const barTypes = [-1, 1, 3]

const DEFAULT_GRAPH_HEIGHT = 150
const DEFAULT_GAP = 50
const DEFAULT_HEADER_HEIGHT = 40
const DEFAULT_FOOTER_HEIGHT = 75

const PluvioChart = ({
    dataMeasures = [],
    startDate,
    endDate,

    headerHeight = DEFAULT_HEADER_HEIGHT,
    footerHeight = DEFAULT_FOOTER_HEIGHT,
}) => {
    const {
        pluviometers,
        pluviometryDataTypes,
    } = useSelector(store => ({
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const echartRef = useRef()
    const displayToolbox = useRef(false)

    const minDate = startDate ?? min(dataMeasures.map(d => minBy(d.measures, 'date')?.date).filter(d => !isUndefined(d)))
    const maxDate = endDate ?? max(dataMeasures.map(d => maxBy(d.measures, 'date')?.date).filter(d => !isUndefined(d)))

    const {
        formatter,
        interval,
    } = getAxisIntervalFormatter(moment(maxDate), moment(minDate))

    const listDataTypes = uniqBy(dataMeasures, 'dataType').map(m => m.dataType)

    const series = listDataTypes.flatMap((dt, i) => {
        const dataType = pluviometryDataTypes.find(p => p.id === dt)
        const measures = dataMeasures.filter(m => m.dataType === dt)
        return measures.map((m, mi) => {
            const pluvio = pluviometers.find(p => p.id === m.stationId)
            return {
                type: barTypes.includes(m.dataType) ? 'bar' : 'line',
                name: `${pluvio?.name ?? ''} (${dataType?.name ?? ''})`,
                data: toEchartsData(m.measures),
                color: getColorFromPalettePluvio(mi),
                connectNulls: false,
                yAxisIndex: i,
                xAxisIndex: i,
                lineStyle: {
                    type: dataType?.lineType ?? 'solid',
                    opacity: dataType?.lineOpacity ?? 1,
                    width: dataType?.lineWidth ?? 2,
                },
                barMaxWidth: '10px',
            }
        })
    })

    let tmpGap = headerHeight
    const grid = listDataTypes.map((_, i) => {
        const tmpTop = tmpGap + (i === 0 ? 0 : DEFAULT_GAP)
        const tmpGrid = {
            top: tmpTop,
            height: DEFAULT_GRAPH_HEIGHT,
            left: '70px',
            right: '40px',
        }
        tmpGap = tmpTop + DEFAULT_GRAPH_HEIGHT
        return tmpGrid
    })

    const xAxis = listDataTypes.map((_, i) => {
        return {
            type: 'time',
            boundaryGap: true,
            axisLabel: {
                rotate: 0,
                formatter,
                show: true,
            },
            axisLine: { show: true },
            axisTick: { show: true },
            splitLine: { show: true },
            interval,
            gridIndex: i,
            min: minDate,
            max: maxDate,
        }
    })

    const yAxis = listDataTypes.map((dt, i) => {
        const dataType = pluviometryDataTypes.find(p => p.id === dt)
        const filteredData = series.filter(d => d.yAxisIndex === i)
        const allMeasures = filteredData.flatMap(d => d.data?.map(m => m.value[1]) ?? [])
        const yScale = yAutomaticScaleValues(allMeasures)
        return {
            type: 'value',
            nameLocation: 'middle',
            name: dataType ? `${dataType.label ?? ''} ${dataType.unit ? `[${dataType.unit}]` : ''}` : i18n.unknown,
            gridIndex: i,
            nameGap: 40,
            showSplitLine: true,
            ...setYOptions(null, yScale),
        }
    })

    const getLegend = () => ({
        top: 10,
        left: 70,
        right: displayToolbox.current ? `${30 * 4 + 35}px` : 40,
        type: 'scroll',
        show: true,
    })

    const getToolbox = () => ({
        top: 5,
        right: 40,
        showTitle: false,
        itemSize: 18,
        tooltip: {
            show: true,
            position: 'bottom',
        },
        feature: displayToolbox.current ? {
            saveAsImage: {
                title: i18n.pictureExport,
                icon: exportPictureIcon,
                name: i18n.dataPluvio,
            },
            myToolExport: {
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: () => {
                    const exportData = dataMeasures.flatMap(dm => {
                        const pluvio = pluviometers.find(({ id }) => id === dm.stationId)
                        const dataType = pluviometryDataTypes.find(d => d.id === dm.dataType)
                        return dm.measures.map(m => ({
                            date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                            value: { value: m.value, format: '0.00000', cellType: 'number' },
                            name: pluvio?.name || dm.stationId,
                            dataType: dataType?.label ?? '',
                        }))
                    })
                    exportFile({
                        data: exportData.length ? [
                            {
                                ...exportData[0],
                                headers: ['name', 'dataType', 'date', 'value'],
                            },
                            ...exportData.slice(1),
                        ] : [],
                        exportType: 'xlsx',
                        titleFile: i18n.dataPluvio,
                    }, true)
                },
            },
            restore: { title: i18n.restore },
        } : {},
    })

    const options = {
        series,
        legend: getLegend(),
        xAxis,
        yAxis,
        tooltip: {
            trigger: 'axis',
            formatter: params => {
                const date = getFullDate(params[0].value[0])
                const result = params.map(o => `${o.marker} ${o.seriesName} : ${round(o.value[1], 2)} ${o.unit ?? ''}`).join('<br />')
                return `${date} <br /> ${result}`
            },
        },
        dataZoom: [{
            type: 'slider',
            xAxisIndex: listDataTypes.map((_, index) => index),
            filterMode: 'none',
        }, {
            bottom: '10px',
            type: 'inside',
            filterMode: 'filter',
            xAxisIndex: listDataTypes.map((_, index) => index),
            handleStyle: {
                color: '#fff',
                shadowBlur: 3,
                shadowColor: 'rgba(0, 0, 0, 0.6)',
                shadowOffsetX: 2,
                shadowOffsetY: 2,
            },
        }],
        grid,
        toolbox: getToolbox(),
    }

    const componentHeight = headerHeight + listDataTypes.length * DEFAULT_GRAPH_HEIGHT + (listDataTypes.length - 1) * DEFAULT_GAP + footerHeight

    return (
        <div
            onMouseOver={() => {
                displayToolbox.current = true
                echartRef.current?.getEchartsInstance().setOption({
                    ...options,
                    legend: getLegend(),
                    toolbox: getToolbox(),
                }, true, true)
            }}
            onMouseOut={() => {
                displayToolbox.current = false
                echartRef.current?.getEchartsInstance().setOption({
                    ...options,
                    legend: getLegend(),
                    toolbox: getToolbox(),
                }, true, true)
            }}
        >
            <ReactECharts
                echarts={echarts}
                option={options}
                notMerge
                lazyUpdate
                style={{ height: componentHeight }}
                ref={e => {
                    echartRef.current = e
                }}
            />
        </div>
    )
}

PluvioChart.propTypes = {
    dataMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoPluvioMeasures)),
    startDate: PropTypes.number,
    endDate: PropTypes.number,

    headerHeight: PropTypes.number,
    footerHeight: PropTypes.number,
}

export default PluvioChart