/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { getExport } from '../../../utils/linkUtils'
import { CardTable } from 'components/datatable/NewTable'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { greyBlue } from 'utils/constants/ColorTheme'
import { hasValue } from 'utils/NumberUtil'
import { checkMandatoryFields } from 'utils/FormUtils'
import { compact } from 'lodash'

const ElementDialog = ({
    idStation,
    selectedElement = {},
    onChange = () => { },
    isOpen = false,
    setIsOpen = () => { },
    titles = {},
    dialogContent,
    mandatoryFields = [],
    station = {},
    idStationKey = '',
}) => {
    const [stateElement, setStateElement] = useState({ ...selectedElement, [idStationKey]: idStation })

    const onChangeElement = (changes) => setStateElement(prev => ({ ...prev, ...changes }))

    const onSave = () => {
        onChange(stateElement)
        setIsOpen(false)
    }

    return (
        <Dialog
            maxWidth='lg'
            open={isOpen}
            PaperProps={{
                style: {
                    overflow: 'visible',
                },
            }}
        >
            <DefaultDialogTitle title={hasValue(stateElement.index) ? titles.edit : titles.new} onClose={() => setIsOpen(false)} />
            <DialogContent>
                {dialogContent({ onChangeElement, stateElement, setStateElement, station })}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        if (mandatoryFields.length) {
                            checkMandatoryFields(mandatoryFields, stateElement, onSave)
                        } else {
                            onSave()
                        }
                    }}
                    variant='contained'
                >
                    {i18n.register}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ElementDialog.propTypes = {
    idStation: PropTypes.number,
    selectedElement: PropTypes.object,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    titles: PropTypes.shape({
        title: PropTypes.string,
        edit: PropTypes.string,
        new: PropTypes.string,
    }),
    dialogContent: PropTypes.element,
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    mandatoryFields: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        i18n: PropTypes.string,
    })),
    idStationKey: PropTypes.string,
}

const StationDescriptionTable = ({
    station = {},
    readMode = true,
    onChange = () => { },

    keyList = '',
    formatFunction = e => e,
    titles = {},
    headers = [],
    dialogContent,
    mandatoryFields = [],

    idStationKey = 'idStation',
    onClick,
    orderFunction = list => list,
    exportHeaders = [],
    invertedHeaderStyle = false,
    deletable = true,
    editable = true,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedElement, setSelectedElement] = useState({})

    const indexedElements = station[keyList].map((l, index) => ({
        ...l,
        index,
    }))
    const tableData = orderFunction(indexedElements.map(d => formatFunction(d)))
    const exportAction = tableData.length && getExport(tableData, titles.title, exportHeaders || headers, null, null, invertedHeaderStyle ? 'black' : 'white')
    const actions = compact(!readMode ? [{
        icon: 'note_add',
        tooltip: i18n.add,
        color: 'white',
        onClick: () => {
            setSelectedElement({})
            setIsOpen(true)
        },
    }, exportAction] : [exportAction])

    const updateElements = (newElement) => {
        if (!hasValue(selectedElement.index)) {
            onChange({ [keyList]: [...indexedElements, newElement] }, newElement)
        } else {
            onChange({
                [keyList]: indexedElements.map((d, i) => i === selectedElement.index ? newElement : d),
            }, newElement)
        }
    }

    const lineActions = [{
        icon: 'close',
        onClick: (element) => onChange({ [keyList]: indexedElements.filter(l => l.index !== element.index) }),
        displayed: !readMode && deletable,
    }, {
        icon: 'edit',
        onClick: (element) => {
            setSelectedElement(indexedElements.find(l => element.index === l.index))
            setIsOpen(true)
        },
        displayed: !readMode && editable,
    }]

    return (
        <>
            <CardTable
                title={titles.title}
                rows={tableData}
                headers={headers}
                actions={actions}
                color={invertedHeaderStyle ? 'white' : greyBlue}
                lineActions={lineActions}
                onClickRow={onClick}
            />
            {isOpen && (
                <ElementDialog
                    idStation={station.id || station.idStation}
                    selectedElement={selectedElement}
                    onChange={updateElements}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    dialogContent={dialogContent}
                    titles={titles}
                    mandatoryFields={mandatoryFields}
                    station={station}
                    idStationKey={idStationKey}
                />
            )}
        </>
    )
}

StationDescriptionTable.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]).isRequired,
    readMode: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    titles: PropTypes.shape({
        title: PropTypes.string,
        edit: PropTypes.string,
        new: PropTypes.string,
    }).isRequired,
    dialogContent: PropTypes.element.isRequired,
    keyList: PropTypes.string.isRequired,
    formatFunction: PropTypes.func.isRequired,
    headers: PropTypes.arrayOf(PropTypes.string).isRequired,
    mandatoryFields: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        i18n: PropTypes.string,
    })),
    createOnly: PropTypes.bool,
    idStationKey: PropTypes.string,
    onClick: PropTypes.func,
    orderFunction: PropTypes.func,
    exportHeaders: PropTypes.arrayOf(PropTypes.string),
    invertedHeaderStyle: PropTypes.func,
    editable: PropTypes.func,
    deletable: PropTypes.func,
}

export default StationDescriptionTable
