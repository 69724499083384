import { WhiteCard } from '../../../../components/styled/Card'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import Row from '../../../../components/react/Row'
import Select from '../../../../components/forms/Select'
import { hasValue } from '../../../../utils/NumberUtil'
import Button from '../../../../components/forms/Button'
import { maxBy, minBy, pick, uniqBy } from 'lodash'
import AppStore from '../../../../store/AppStore'
import ToastrAction from '../../../../toastr/actions/ToastrAction'
import Input from '../../../../components/forms/Input'
import { getFullDate, getHour } from '../../../../utils/DateUtil'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { Button as MuiButton, Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DtoJobParameters from '../../../../import/dto/DtoJobParameters'
import { getLogin, getSettingInt } from '../../../../utils/SettingUtils'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import CSVGenericPiezoManualPanel
    from '../../../../piezometry/components/validation/tools/manualImport/CSVGenericPiezoManualPanel'
import JobAction from '../../../../import/actions/JobAction'
import HydrometryAction from '../../../actions/HydrometryAction'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import SieauParameterDto from '../../../../administration/dto/SieauParameterDto'
import DtoHydrometricStation from '../../../dto/DtoHydrometricStation'
import DtoHydroMeasures from '../../../dto/chronicMeasures/DtoHydroMeasures'
import { integrationModeHelpIcon } from '../../../../import/utils/ImportUtils'
import { StyledFieldSet, StyledLegend } from '../../../../components/StyledElements'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from '../../../../components/styled/Dialog'
import Icon from '../../../../components/icon/Icon'
import moment from 'moment'

const ImportHydroToolPanel = ({
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    applicationSettings,
    selectedDataType,
    id,
    hydrometricStation,
    hydroMeasuresFormat,
    isChange,
    setIsChange,
    hydroLastMeasures,
    createTechnicalEvent,
}) => {
    const [validateActive, setValidateActive] = useState(false)
    const [jobType, setJobType] = useState('csvAll')
    const [open, setOpen] = useState(false)
    const [job, setJob] = useState(new DtoJobParameters({ import: {
        measureCote: 2,
        producer: getSettingInt(applicationSettings, 'validationDefaultProducer'),
        manager: getSettingInt(applicationSettings, 'validationDefaultManager'),
        validator: getSettingInt(applicationSettings, 'validationDefaultValidator'),
    } }))
    const [fileContent, setFileContent] = useState(null)
    const [logs, setLogs] = useState([])
    const [selectedJob, setSelectedJob] = useState(null)
    const [display, setDisplay] = useState('cumul')
    const [hydrometerImportedMeasure, setHydrometerImportedMeasure] = useState({})

    const { date: lastMeasureDate } = hydroLastMeasures || { date: moment().valueOf() }

    const dispatch = useDispatch()

    const {
        sandreCodes,
        contributors,
        jobs,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
        jobs: store.JobReducer.jobs,

    }), shallowEqual)


    useEffect(() => {
        dispatch(JobAction.fetchJobs())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const qualifications = getQualificationSelectOptions()
    const status = getStatusSelectOptions()


    const displays = [
        { code: 'cumul', label: i18n.cumulate },
        { code: 'addEnd', label: i18n.addEnd },
        /* { code: 'replace', label: i18n.replacing },*/
    ].filter(c => !!c)

    const getPossibleImports = () => {
        return [
            {
                value: 'csvAll',
                label: i18n.CSVGeneric,
            },
        ]
    }

    const onUploadFile = (e) => {
        const reader = new FileReader()
        const file = e.target.files[0]
        if (file) {
            reader.onloadend = (event) => {
                const split = event.target.result.split(',')
                setFileContent(split[1])
            }
            reader.readAsDataURL(file)
        }
    }

    const onCancel = () => {
        setHydrometerImportedMeasure({})
        setValidateActive(false)
        setJobType('')
        setOpen(false)
        setJob(new DtoJobParameters())
        setFileContent(null)
    }

    const onSave = () => {
        const filteredImportedMeasure = hydrometerImportedMeasure.map(m => { // .filter(measure => measure.date >= startDateImport && measure.date <= endDateImport)
            if (!m.isNew) {
                return m
            }
            createTechnicalEvent({ mode: 'import' })
            return {
                ...m,
                ...job.import,
            }
        })
        if (display === 'addEnd') {
            const measuresToImportFilter = filteredImportedMeasure.filter(f => f.date > lastMeasureDate)
            dispatch(HydrometryAction.updateMeasures(measuresToImportFilter.filter(f => f.date >= startDate && f.date <= endDate))).then(() => setIsChange(!isChange))
        } else if (display === 'cumul') {
            const measuresImport = hydroMeasuresFormat.map(m => (
                {
                    ...m,
                    update: true,
                }
            ))
            const measuresToImportFilter = uniqBy([
                ...measuresImport,
                ...filteredImportedMeasure,
            ], m => getFullDate(m.date)).filter(m => m.updated || m.isNew)
            dispatch(HydrometryAction.updateMeasures(measuresToImportFilter.filter(f => f.date >= startDate && f.date <= endDate))).then(() => setIsChange(!isChange))
        }
        /* else if (display === 'replace') {
            })
        */
    }

    const getDialogContent = () => {
        switch (jobType) {
            case 'csvAll':
                return (
                    <CSVGenericPiezoManualPanel
                        job={{ parameters: job }}
                        selectedDataType={ selectedDataType }
                        onChangeJob={(changes) => setJob({ ...job, ...changes.parameters })}
                        isEditMode
                        isPiezo={false}
                        hydrometricStation={hydrometricStation}
                    />
                )
            default:
                return i18n.controlErrors
        }
    }

    const importData = () => {
        setLogs([])

        const jobParameters = new DtoJobParameters({
            ...job,
            user: getLogin(),
            jobType,
            fileContent,
            isManualImport: true,
            returnsData: true,
            routingKey: 'import',
            jobExecutionId: -1,
        })
        dispatch(JobAction.executeJobManually(jobParameters, returnData => {
            const measures = (returnData?.measures || []).filter(m => m.dataType === selectedDataType)
            if (measures.length) {
                const hydrometerImportedMeasure = measures.map(measure => {
                    return {
                        hydroId: id,
                        status: job.import.status,
                        qualification: job.import.qualification,
                        nature: job.import.nature,
                        updateLogin: getLogin(),
                        initialValue: measure.value,
                        value: measure.value,
                        date: measure.date,
                        validator: job.import.validator,
                        manager: job.import.manager,
                        producer: job.import.producer,
                        initialPoint: undefined,
                        measureMode: 1,
                        dataType: selectedDataType,
                        isNew: true,
                    }
                })
                const minImportDate = minBy(measures, (v) => v.date)
                const maxImportDate = maxBy(measures, (v) => v.date)
                setHydrometerImportedMeasure(hydrometerImportedMeasure)
                setStartDate(minImportDate?.date)
                setEndDate(maxImportDate?.date)
                setValidateActive(true)
                setOpen(false)
            } else {
                AppStore.dispatch(ToastrAction.warning(i18n.noDataToDisplay))
                setLogs(returnData.logs)
            }
        }))
    }

    const setJobImport = (dataType, changes) => {
        switch (dataType) {
            case 'import':
                setJob({ ...job, import: { ...job.import, ...changes } })
                break
            case 'parameters':
                setJob({ ...job, parameters: { ...job.parameters, ...changes } })
                break
            case 'filters':
                setJob({ ...job, filters: { ...job.filters, ...changes } })
                break
            case 'data':
                const newData = job.data.filter(d => d.id !== changes.id).concat(changes)
                setJob({ ...job, data: newData })
                break
            default:
                break
        }
    }

    return (
        <WhiteCard title={i18n.importName} round>
            <div style={{ padding: '10px' }}>
                <StyledFieldSet className='padding-1' >
                    <StyledLegend>{i18n.fileToImport}</StyledLegend>
                    <Row>
                        <Select
                            col={12}
                            value={jobType}
                            options={getPossibleImports()}
                            label={i18n.format}
                            onChange={v => {
                                setJobType(v)
                                setSelectedJob(null)
                            }}
                            nullLabel='&nbsp;'
                        />
                    </Row>
                    <Row>
                        <StyledFieldSet className='padding-1'>
                            <StyledLegend>{i18n.useExistingTreatment}</StyledLegend>
                            <Row>
                                <Select
                                    col={12}
                                    label={ i18n.job }
                                    options={ jobs.filter(j => j.jobType === jobType) }
                                    value={ selectedJob?.id }
                                    disabled={ !hasValue(jobType) }
                                    onChange={ (_, v) => setSelectedJob(v) }
                                    keyValue='id'
                                />
                            </Row>
                            <Row>
                                <Button
                                    col={12}
                                    title={i18n.apply}
                                    disabled={!hasValue(selectedJob)}
                                    onClick={() => {
                                        setJob({ ...job, ...pick(JSON.parse(selectedJob.parameters), ['parameters', 'filters', 'dataTypes']) },
                                            () => AppStore.dispatch(ToastrAction.success(i18n.paramsSettingsApplied)),
                                        )
                                    }}
                                    icon='settings'
                                />
                            </Row>
                        </StyledFieldSet>
                    </Row>
                    <Row>
                        <div className='col s10 no-margin padding-left-1 padding-right-1 height-24'>
                            <div className='file-field input-field'>
                                <div className='btn'>
                                    <span>{i18n.importLabel}</span>
                                    <input
                                        type='file'
                                        onChange={onUploadFile}
                                    />
                                </div>
                                <div className='file-path-wrapper'>
                                    <input className='file-path validate' type='text' />
                                </div>
                            </div>
                        </div>
                        <div className='no-margin padding-bottom-1 padding-top-1 col s2'>
                            <Button
                                tooltip={i18n.parameters}
                                disabled={!(hasValue(fileContent) && hasValue(jobType))}
                                onClick={() => setOpen(true) }
                                icon='settings'
                                className='btn-floating z-index-10'
                            />
                        </div>
                    </Row>
                </StyledFieldSet>
                <StyledFieldSet className={`margin-1 ${hydrometerImportedMeasure.length ? '' : 'hide'}`}>
                    <legend>&nbsp;{i18n.importParam}&nbsp;</legend>
                    <Row>
                        <SimpleDatePicker
                            col={6}
                            title={i18n.startDate}
                            value={startDate}
                            onChange={v => setStartDate(v)}
                        />
                        <Input
                            col={6}
                            title={i18n.startHour}
                            value={getHour(startDate)}
                            onChange={v => setStartDate(v)}
                            disabled
                        />
                    </Row>
                    <Row>
                        <SimpleDatePicker
                            col={6}
                            title={i18n.endDate}
                            value={endDate}
                            onChange={v => setEndDate(v)}

                        />
                        <Input
                            col={6}
                            title={i18n.endHour}
                            value={getHour(endDate)}
                            disabled
                        />
                    </Row>
                    <Row>
                        <Select col={12} label={i18n.dataIntegration} value={display} options={displays} onChange={ v => setDisplay(v) } labelSpan={ integrationModeHelpIcon() } />
                    </Row>
                </StyledFieldSet>
                <StyledFieldSet className={`margin-1 ${hydrometerImportedMeasure.length ? '' : 'hide'}`}>
                    <legend>&nbsp;{i18n.qualificationData}&nbsp;</legend>
                    <Row>
                        <Select
                            col={6}
                            label={i18n.status}
                            value={job.import.status}
                            onChange={v => setJobImport('import', { status: v })}
                            options={status}
                            nullLabel='&nbsp;'
                        />
                        <Select
                            col={6}
                            label={i18n.qualification}
                            value={job.import.qualification}
                            onChange={v => setJobImport('import', { qualification: v })}
                            options={qualifications}
                            nullLabel='&nbsp;'
                        />
                    </Row>
                    <Row>
                        <Select
                            col={6}
                            label={i18n.nature}
                            value={job.import.nature}
                            onChange={v => setJobImport('import', { nature: v })}
                            options={getSandreList(sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, true)}
                            nullLabel='&nbsp;'
                        />
                    </Row>
                    <Row>
                        <Select
                            col={12}
                            label={i18n.producer}
                            value={job.import.producer}
                            onChange={v => setJobImport('import', { producer: v })}
                            options={contributors}
                        />
                    </Row>
                    <Row>
                        <Select
                            col={6}
                            label={i18n.administrator}
                            value={job.import.manager}
                            onChange={v => setJobImport('import', { manager: v })}
                            options={contributors}
                        />
                        <Select
                            col={6}
                            label={i18n.validator}
                            value={job.import.validator}
                            onChange={v => setJobImport('import', { validator: v })}
                            options={contributors}
                        />
                    </Row>
                </StyledFieldSet>
                <Row className='padding-bottom-1 center-align'>
                    <Button
                        tooltip={i18n.cancel}
                        onClick={onCancel}
                        icon='cancel'
                        className='red btn-floating btn-large margin-left-2 margin-right-2 z-index-10 '
                        disabled={!validateActive}
                    />
                    <Button
                        tooltip={i18n.register}
                        onClick={onSave}
                        icon='save'
                        disabled={!validateActive}
                        className={`btn-floating btn-large z-index-10 ${validateActive ? 'pulse' : ''}`}
                    />
                </Row>
                <Dialog
                    fullWidth
                    maxWidth='lg'
                    open={open}
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.importParam}
                        <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => setOpen(false) } />
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        {getDialogContent()}
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <MuiButton onClick={importData} variant='contained' color='primary'>
                            {i18n.importLabel}
                        </MuiButton>
                    </DialogActionsMUI>
                </Dialog>
            </div>
        </WhiteCard>
    )
}

ImportHydroToolPanel.propTypes = {
    startDate: PropTypes.number,
    setStartDate: PropTypes.func,
    endDate: PropTypes.number,
    setEndDate: PropTypes.func,
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
    selectedDataType: PropTypes.number,
    id: PropTypes.number,
    hydrometricStation: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    hydroMeasuresFormat: arrayOf(DtoHydroMeasures),
    isChange: PropTypes.bool,
    setIsChange: PropTypes.func,
    hydroLastMeasures: arrayOf(DtoHydroMeasures),
    createTechnicalEvent: PropTypes.func,
}

export default ImportHydroToolPanel
