import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import RadioButtons from '../forms/RadioButtons'
import { groupBy } from 'lodash'
import { hasValue } from 'utils/NumberUtil'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'

const ExportFileModal = ({
    open = false,
    data = [],
    title = '',
    maxWidth = 'lg',
    groups = [],
    onClose = () => { },
    closeOnExport = false,
    settingsStyle = {},
}) => {
    const [dataChoice, setDataChoice] = useState(0)
    const [formatChoice, setFormatChoice] = useState(data?.[0]?.formats?.[0]?.type)

    const exportObj = data[dataChoice]

    const getCallback = () => {
        exportObj.formats.find(d => d.type === formatChoice)?.callback()
        if (closeOnExport) {
            onClose()
        }
    }

    const dataOptions = data.map((d, i) => ({
        ...d,
        code: i,
        name: d.name,
        group: d.group,
    }))
    const groupByDatas = groupBy(data, 'group')

    return (
        <Dialog
            fullWidth
            maxWidth={maxWidth}
            open={open}
        >
            <DefaultDialogTitle
                title={title || i18n.chooseExportFormat}
                onClose={onClose}
            />
            <DialogContent>
                <Grid2 container justifyContent='space-between'>
                    <Grid2 size={6}>
                        {Object.keys(groupByDatas).sort().map((key) => {
                            const groupFind = groups.find((g) => g.value === parseInt(key))
                            return (
                                <StyledFieldSet key={key}>
                                    <StyledLegend className='padding-left-1 padding-right-1'>{(groupFind?.name || i18n.export)}</StyledLegend>
                                    <RadioButtons
                                        col={12}
                                        colOption={12}
                                        className='no-padding no-margin'
                                        elements={dataOptions.filter((d) => groupFind ? d.group === parseInt(key) : !hasValue(d.group))}
                                        selected={dataChoice}
                                        onChange={(v) => {
                                            setDataChoice(v)
                                            setFormatChoice(dataOptions[v]?.formats?.[0]?.type)
                                        }}
                                        disabled={groupFind?.disabled}
                                    />
                                </StyledFieldSet>
                            )
                        })}
                    </Grid2>
                    <Grid2 size={6} className='padding-left-1' style={settingsStyle}>
                        {exportObj && exportObj.settingsContent && (
                            <Grid2 size={12}>
                                <StyledFieldSet>
                                    <StyledLegend className='padding-left-1 padding-right-1'>{i18n.settings}</StyledLegend>
                                    {exportObj.settingsContent}
                                </StyledFieldSet>
                            </Grid2>
                        )}
                        <StyledFieldSet>
                            <StyledLegend>{i18n.type}</StyledLegend>
                            <RadioButtons
                                col={12}
                                colOption={6}
                                elements={exportObj?.formats?.map((d) => ({ ...d, code: d.type, name: d.type }))}
                                selected={formatChoice}
                                onChange={setFormatChoice}
                            />
                        </StyledFieldSet>
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={getCallback} variant='contained' color='primary'>{i18n.export}</Button>
            </DialogActions>
        </Dialog>
    )
}

ExportFileModal.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    closeOnExport: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        group: PropTypes.number,
        settingsContent: PropTypes.element,
        formats: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string,
            callback: PropTypes.func,
        })),
        disabled: PropTypes.bool,
    })).isRequired,
    grouped: PropTypes.bool,
    groups: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        name: PropTypes.string,
    })),
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    settingsStyle: PropTypes.shape({}),
}

export default ExportFileModal