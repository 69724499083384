/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import NumberField from '../../../../components/forms/NumberField'
import DtoLiftingStation from '../dto/DtoLiftingStation'
import Select from '../../../../components/forms/Select'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import { Grid2 } from '@mui/material'
import useSandreList from 'utils/customHook/useSandreList'

const LiftingEquipmentsPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    lifting = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const detectionList = useSandreList(SANDRE.INSTALLATIONS_TYPEDETECTION)

    const onChangeEquipments = (value) => {
        onChange({
            link_equipments: [
                {
                    idStation: lifting.idStation,
                    ...lifting.link_equipments[0],
                    ...value,
                },
            ],
        })
    }

    const equipments = lifting.link_equipments[0] || {}

    return (
        <>
            <Grid2 size={6}>
                <NumberField
                    value={equipments.numberPumps}
                    title={i18n.numberPumps}
                    onChange={(v) => {
                        onChangeEquipments({ numberPumps: v })
                        onChangeVisit({ previousValue: equipments.numberPumps, newValue: v, field: i18n.numberPumps })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={equipments.nominalFlow}
                    title={i18n.nominalFlow}
                    onChange={(v) => {
                        onChangeEquipments({ nominalFlow: v })
                        onChangeVisit({ previousValue: equipments.nominalFlow, newValue: v, field: i18n.nominalFlow })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={equipments.hmt}
                    title={i18n.hmt}
                    onChange={(v) => {
                        onChangeEquipments({ hmt: v })
                        onChangeVisit({ previousValue: equipments.hmt, newValue: v, field: i18n.hmt })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={12}>
                <hr />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={equipments.typeDetectionLevel}
                    label={i18n.typeDetectionLevel}
                    keyValue='code'
                    options={detectionList}
                    onChange={(v) => {
                        onChangeEquipments({ typeDetectionLevel: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEDETECTION, i18n.typeDetectionLevel, equipments.typeDetectionLevel, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

LiftingEquipmentsPanel.propTypes = {
    lifting: PropTypes.instanceOf(DtoLiftingStation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default LiftingEquipmentsPanel
