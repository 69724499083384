import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { groupBy, keys, round, uniqBy } from 'lodash'
import moment from 'moment'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { Card, CardContent, Grid2 } from '@mui/material'
import ProgressCard from 'components/card/ProgressCard'
import ChartTabs from 'components/echart/ChartTabs'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import MultiChart from 'components/echart/MultiChart'
import DtoEvent from 'events/dto/DtoEvent'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { getDate, getDateWithHour, getDayDiff, getFullDate } from 'utils/DateUtil'
import { getEventColor, getRGBColor } from 'utils/ColorUtil'
import { HYDROMETER_HEIGHT_TYPE } from 'hydrometry/constants/HydrometryConstants'
import { renderToString } from 'react-dom/server'
import { statusIcon } from 'utils/StatusUtil'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoInstallationAnalysisMeasureLight from 'installation/dto/installation/DtoInstallationAnalysisMeasureLight'
import DtoInstallationAnalysisMeasures from 'installation/dto/installation/DtoInstallationAnalysisMeasures'
import AdministrationAction from 'administration/actions/AdministrationAction'
import Icon from 'components/icon/Icon'
// import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
// import { getI18nOrLabel } from 'utils/StringUtil'
import { /* INSTALLATION_TYPES,*/ INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
// import { ButtonMUI } from 'components/styled/Buttons'
import GlobalParametersDto from 'administration/dto/GlobalParameterDto'
import { SIEAU } from 'administration/components/user/constants/StatisticConstants'
// import StationAction from 'station/actions/StationAction'
// import { getStationTypeCodeFromType } from 'utils/StationUtils'
import ParameterAction from 'referencial/components/parameter/actions/ParameterAction'
import { EVENT_TYPES_CODES } from 'events/constants/EventsConstants'
import DtoInstallationAnalysisStats from '../installations/dto/DtoInstallationAnalysisStats'
import { InstallationActionConstant } from 'installation/reducers/InstallationReducer'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import { getLocalStorageJson, setLocalStorageJson } from 'utils/FormUtils'

const INSTALLATION_TYPE_PARAMETER = 'ROE_INSTALLATIONS_TYPE'
const DATA_TYPE_PARAMETER = 'ROE_INSTALLATIONS_VARIABLES'
// const installationTypesAccepted = [INSTALLATION_TYPE.STRAIT, INSTALLATION_TYPE.ELEC_COUNTER]

const InstallationKeyFigurePanel = ({
    instStats = [],
}) => {
    const headers = [i18n.statistics, i18n.begin, i18n.end, i18n.nbMeasures]

    const tableHeader = headers.map((o, i) => (
        <th
            className={`${i === 0 ? 'left' : 'center'}`}
            style={{
                color: 'black',
                lineHeight: '25px',
                padding: i === 0 ? '5 0 5 25' : 5,
                fontSize: '15px',
            }}
        >
            {o}
        </th>
    ))

    const tableContent = instStats.map(stat => ({
        ...stat,
        startDate: getDate(stat.startDate),
        endDate: getDate(stat.endDate),
    })).map(hist => (
        <tr style={{ fontSize: '14.3px', lineHeight: '20px' }}>
            {keys(hist).map((key, index) => {
                if (index === 0) {
                    return (
                        <td className='right right-align bold '>
                            {hist[key]}
                        </td>
                    )
                }
                return (
                    <td className='center'>
                        {hist[key]}
                    </td>
                )
            })}
        </tr>
    ))

    return (
        <Grid2 container justifyContent='center'>
            <Card>
                <CardContent sx={{ border: '1px solid grey', borderRadius: '5px', width: '80%' }}>
                    <table className='table condensed' >
                        <thead>
                            <tr>{tableHeader}</tr>
                        </thead>
                        {!!history.length && (
                            <tbody>
                                {tableContent}
                            </tbody>
                        )}
                    </table>
                    {!history.length && (
                        <Grid2 justifyContent='center' alignItems='center' style={{ fontSize: 14.3, color: 'black' }}>
                            <Icon style={{ fontSize: 50 }}>cloud_off</Icon>
                            {i18n.noDataToDisplay}
                        </Grid2>
                    )}
                </CardContent>
            </Card>
        </Grid2>
    )
}

InstallationKeyFigurePanel.propTypes = {
    instStats: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationAnalysisStats)),
}

// const ChartOptions = ({
//     setInstallationTypesChart = () => { },
//     showTitle = true,
// }) => {
//     const {
//         globalParameters,
//     } = useSelector(store => ({
//         globalParameters: store.AdministrationReducer.globalParameters,
//     }), shallowEqual)

//     const defaultInstallationTypes = useMemo(() => {
//         const value = globalParameters.find(param => param.parameter === INSTALLATION_TYPE_PARAMETER)?.value
//         return !isNil(value) ? value.split(',').map(Number) : [INSTALLATION_TYPE.STRAIT]
//     }, [globalParameters])

//     const [showChartOptions, setShowChartOptions] = useState(false)
//     const [installationTypes, setInstallationTypes] = useState(defaultInstallationTypes)

//     const installationTypesOptions = useMemo(() => installationTypesAccepted.map(instType => {
//         const installationType = INSTALLATION_TYPES.find(type => type.code === instType) || {}
//         return { code: installationType.code, name: getI18nOrLabel(installationType.label) }
//     }), [])

//     const onValidate = () => {
//         setInstallationTypesChart(installationTypes)
//         setShowChartOptions(!showChartOptions)
//     }

//     const onCancel = () => {
//         setInstallationTypes(defaultInstallationTypes)
//         setInstallationTypesChart(defaultInstallationTypes)
//         setShowChartOptions(!showChartOptions)
//     }

//     return (
//         <Grid container sx={{ left: '560px', position: 'absolute', width: '24px', top: `${(showTitle ? 52 : 12)}`, zIndex: 10 }}>
//             <Grid container item sx={{ position: 'relative' }}>
//                 <Grid item>
//                     <Icon
//                         className='relative'
//                         tooltip={i18n.chartOptions}
//                         style={{ top: '4px', margin: '0 15px' }}
//                         icon={'settings'}
//                         onClick={() => setShowChartOptions(!showChartOptions)}
//                     />
//                 </Grid>
//                 {showChartOptions && (
//                     <Grid container item sx={{ position: 'absolute', backgroundColor: '#fff', boxShadow: '0px 3px 16px -5px rgb(0 0 0 / 50%)', padding: '10px', width: '450px', left: '35px', borderRadius: '4px' }}>
//                         <Grid item xs={12}>
//                             <h5 className='center'>{i18n.chartOptions}</h5>
//                         </Grid>
//                         <Grid item xs={12} sx={{ paddingTop: '10px' }}>
//                             <SuperMultiAutocomplete
//                                 col={12}
//                                 options={installationTypesOptions}
//                                 values={installationTypes}
//                                 label={'Types d\'installations'}
//                                 onChange={setInstallationTypes}
//                                 keyValue='code'
//                                 keyLabel='name'
//                                 multiple
//                             />
//                         </Grid>
//                         <Grid container item xs={12} justifyContent='flex-end' sx={{ marginTop: '30px' }}>
//                             <Grid item>
//                                 <ButtonMUI variant='outlined' sx={{ marginRight: 1 }} onClick={onCancel}>
//                                     {i18n.cancel}
//                                 </ButtonMUI>
//                             </Grid>
//                             <Grid item>
//                                 <ButtonMUI variant='contained' onClick={onValidate}>
//                                     {i18n.validate}
//                                 </ButtonMUI>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 )}
//             </Grid>
//         </Grid>
//     )
// }
// ChartOptions.propTypes = {
//     hydroStats: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydroStats)),
//     setInstallationTypesChart: PropTypes.func,
//     setDataTypesChart: PropTypes.func,
//     showTitle: PropTypes.bool,
// }

const ROUND_VALUE = 3

const InstallationCounterMultiGraph = ({
    stationId,
    graphicHeight,
    stationsEvents = [],
    instMeasures = [],
    onFullScreen = () => { },
    defaultMinDate,
    defaultMaxDate,
}) => {
    const {
        accountUser,
        installationsLight,
        userSettings,
        installation,
        parameters,
    } = useSelector(store => ({
        accountUser: store.AccountReducer.accountUser,
        installationsLight: store.InstallationReducer.installationsLight,
        userSettings: store.AccountReducer.accountUserSettings,
        installation: store.InstallationReducer.installation,
        parameters: store.ParameterReducer.parameters,
    }), shallowEqual)

    const dispatch = useDispatch()

    const legendParameterName = `selectedLegend_${STATION_TYPE_NAME.installation}_${stationId}`

    useEffect(() => {
        dispatch(AdministrationAction.fetchUserSettings(accountUser.login))
    }, [])

    const { isLoaded: parametersLoaded } = useProgressDispatch(() => [dispatch(AdministrationAction.fetchUserSettings(accountUser.login))], [])

    const legendSettingValue = userSettings.find(setting => setting.parameter === legendParameterName)?.value
    const legendSetting = legendSettingValue && JSON.parse(legendSettingValue)

    const events = stationsEvents.filter(e => {
        if (e.eventType === 'T') {
            return false
        }
        if (e.date) {
            return e.graph === '1' || e.typeName === STATION_TYPE_NAME.installation
        }
        return false
    })

    const eventsFormatted = useMemo(() => {
        const eventsToWatch = events.filter(e => e.eventType === EVENT_TYPES_CODES.TO_MONITOR)

        const dataCustomToWatch = eventsToWatch.map(e => {
            const startDate = e.startDate || getDateWithHour(e.date, e.eventHour).valueOf()
            const endDate = e.endDate || moment().add(1, 'days').valueOf()
            return {
                value: [startDate, endDate, e],
                itemStyle: { color: getRGBColor(getEventColor(e.eventType)) },
            }
        })

        const dataBar = events.map(e => ({
            date: e.startDate || getDateWithHour(e.date, e.eventHour).valueOf(),
            value: 1,
            color: getRGBColor(getEventColor(e.eventType)),
        }))

        return [{
            gridIndex: 0,
            name: i18n.events,
            serieId: 'events',
            gridName: i18n.events,
            dataList: [],
            type: 'custom',
            overrideSerie: {
                data: dataCustomToWatch,
                tooltip: {
                    trigger: 'item',
                    formatter: params => {
                        const { marker, seriesName, value: [startDate, , statusObj], data: { unit = '' } } = params
                        return `${getFullDate(startDate)}<br/>${marker} ${seriesName}: 1 ${unit} <div style="display: inline-grid; vertical-align: middle;">${renderToString(statusIcon(statusObj, 20))}</div>`
                    },
                },
                itemStyle: {
                    normal: {
                        opacity: 1,
                    },
                },
                areaStyle: {
                    normal: {},
                },
                renderItem: (_, api) => {
                    const start = api.coord([api.value(0), 0])
                    const end = api.coord([api.value(1), 0])
                    const height = api.size([0, 1])[1]
                    return {
                        type: 'rect',
                        shape: {
                            x: start[0],
                            y: start[1] - height,
                            width: end[0] - start[0],
                            height,
                        },
                        style: api.style(),
                    }
                },
            },
        }, {
            gridIndex: 0,
            name: i18n.events,
            serieId: 'events',
            gridName: i18n.events,
            dataList: dataBar,
            type: 'bar',
            barWidth: '10px',
        }]
    }, [events])

    const instGroupedMeasures = useMemo(() => groupBy(instMeasures, 'parameterCode'), [instMeasures])
    const instSortedKeys = useMemo(() => keys(instGroupedMeasures), [instGroupedMeasures])

    const instFormatted = useMemo(() => instSortedKeys.flatMap((key, index) => {
        const values = instGroupedMeasures[key]
        return values.map((value) => {
            const station = installationsLight.find(a => a.id === value.stationId)
            const stationName = station?.name || ''
            const parameter = parameters.find(p => p.code === key)
            return {
                gridIndex: index + 1,
                name: `${stationName}${parameter?.name && ` - ${parameter?.name}` || ''}`,
                lineStyle: 'solid',
                color: 'black',
                gridName: parameter?.name || key,
                dataList: value.measures,
                sampling: 'lttb',
                parameterCode: key,
            }
        })
    }), [instSortedKeys, instGroupedMeasures, installationsLight, parameters])

    const dataFormatted = [...eventsFormatted, ...instFormatted]

    const eventGrids = useMemo(() => [{
        gridIndex: 0,
        name: i18n.events,
        yOptions: {
            type: 'value',
            nameRotate: 0,
            nameGap: 20,
            minInterval: 1,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: { show: false },
        },
        xOptions: {
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
        },
        gridOptions: {
            top: 25,
            left: '100px',
            height: 40,
        },
    }], [])

    const instGrids = useMemo(() => instFormatted.length ? instSortedKeys.map((key, index) => {
        const parameter = parameters.find(p => p.code === key)
        return {
            gridIndex: index + 1,
            name: `${parameter?.name || key}`,
            gridOptions: {
                left: '100px',
                top: 35,
                height: graphicHeight,
            },
        }
    }) : [], [graphicHeight, instFormatted.length, instGroupedMeasures, instSortedKeys, parameters])

    const grids = [...eventGrids, ...instGrids]

    const formatTooltip = params => {
        return getFullDate(params[0].axisValue) + params.map(({ marker, seriesName, value: [, result, statusObj], data: { unit = '' } }) => `<br/>${marker} ${seriesName}: ${result && round(result, ROUND_VALUE)} ${unit} <div style="display: inline-grid; vertical-align: middle;">${renderToString(statusIcon(statusObj, 20))}</div>`).join('')
    }

    const onChangeLegend = (selectedLegend) => {
        dispatch(AdministrationAction.updateSetting(legendParameterName, JSON.stringify(selectedLegend), !legendSettingValue))
    }

    return parametersLoaded && (
        <MultiChart
            data={dataFormatted}
            grids={grids}
            stationsEvents={stationsEvents}
            roundValue={ROUND_VALUE}
            footerHeight={70}
            exportName={installation.name || ''}
            withToolTypeLine
            withToolTypeBar
            withToolThreshold
            withToolLine
            withDataZoom
            withToolLog
            withFullScreen
            onFullScreen={onFullScreen}
            defaultDisplayMarker={false}
            tooltipFormatter={formatTooltip}
            defaultMinDate={defaultMinDate}
            defaultMaxDate={defaultMaxDate}
            defaultSelectedLegend={legendSetting}
            onChangeLegend={onChangeLegend}
        />
    )
}

InstallationCounterMultiGraph.propTypes = {
    stationId: PropTypes.number,
    graphicHeight: PropTypes.number,
    stationsEvents: PropTypes.arrayOf(PropTypes.instanceOf(DtoEvent)),
    instMeasures: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationAnalysisMeasures)),
    onFullScreen: PropTypes.func,
    defaultMinDate: PropTypes.number,
    defaultMaxDate: PropTypes.number,
}

const InstallationCounterChart = ({
    stationId,
    instIds = [],
    // setInstallationTypesChart = () => { },
    // setDataTypesChart = () => { },
    showTitle = true,
    showKeyFigures = false,
    inPopup = false,
}) => {
    const {
        installationEvents,
        installationAnalysisStats,
        installationAnalysisParameters,
    } = useSelector(store => ({
        installationEvents: store.InstallationReducer.installationEvents,
        installationAnalysisStats: store.InstallationReducer.installationAnalysisStats,
        installationAnalysisParameters: store.InstallationReducer.installationAnalysisParameters,
    }), shallowEqual)

    const graphicHeight = 150

    const [paramsLoaded, setParamsLoaded] = useState(false)

    const [minDate, setMinDate] = useState()
    const [maxDate, setMaxDate] = useState(moment().valueOf())
    const [chartTab, setChartTab] = useState()
    const [instMeasures, setInstMeasures] = useState([])

    const [fullScreen, setFullScreen] = useState(false)
    const [expanded, setExpanded] = useState(getLocalStorageJson('DASHBOARD_DISPLAY')?.INST?.OVERVIEW)

    const dispatch = useDispatch()

    const setAccordion = (e) => {
        const actualLocalStorage = getLocalStorageJson('DASHBOARD_DISPLAY') || {}
        const newDisplay = {
            ...actualLocalStorage,
            INST: {
                ...actualLocalStorage?.INST,
                ['OVERVIEW']: e,
            },
        }
        setLocalStorageJson('DASHBOARD_DISPLAY', newDisplay)
        setExpanded(e)
    }

    useEffect(() => {
        dispatch(InstallationAction.fetchInstallationEvents(stationId))
        dispatch(InstallationAction.fetchInstallationAnalysisStats(stationId))
        dispatch(InstallationAction.fetchInstallationAnalysisParameters([stationId])).then(() => setParamsLoaded(true))
        return () => {
            dispatch(InstallationActionConstant.resetInstallationDashboard())
            setInstMeasures([])
            setParamsLoaded(false)
        }
    }, [])

    const {
        isLoaded: instIsLoaded,
        progress: instProgress,
    } = useProgressDispatch((cancelRef) => {
        if (!chartTab || !paramsLoaded) {
            return []
        }
        setInstMeasures([])
        return instIds.flatMap(id => {
            return installationAnalysisParameters.map(param => {
                return dispatch(InstallationAction.fetchInstallationAnalysisByCriterias(id, { lightMode: true, startDate: minDate, endDate: maxDate, parameterCode: param, group: (chartTab === 'HISTO' || getDayDiff(maxDate, minDate) > 90) ? 'MAX' : undefined }))
                    .then(data => {
                        const measuresFiltered = uniqBy(data.map(j => new DtoInstallationAnalysisMeasureLight(j)).filter(d => (d.date >= minDate && d.date <= maxDate)), 'date')
                        return !cancelRef.current && setInstMeasures(prev => [...prev, new DtoInstallationAnalysisMeasures({ stationId: id, dataType: 4, measures: measuresFiltered, parameterCode: param })])
                    })
            })
        })
    }, [chartTab, instIds, stationId, paramsLoaded, installationAnalysisParameters])


    const stationsEvents = [...installationEvents.map(instEvent => ({ ...instEvent, typeName: STATION_TYPE_NAME.installation }))]

    const fullScreenStyle = fullScreen ? {
        position: 'fixed',
        top: 0,
        left: 0,
        height: !inPopup ? `calc(${window.innerHeight}px - 5rem)` : `calc(${window.innerHeight}px)`,
        width: `calc(${window.innerWidth}px)`,
        zIndex: 9,
        marginTop: !inPopup ? '5rem' : '0',
        overflowY: 'auto',
        backgroundColor: 'white',
    } : {}

    const chartHeight = !fullScreen ? graphicHeight : (window.innerHeight / 2.1)

    return (
        <AccordionMUI round sx={{ marginTop: '10px' }} expanded={expanded} onChange={() => setAccordion(!expanded)}>
            <AccordionSummaryMUI round iconColor='black' style={{ background: 'white', color: 'black' }}>{i18n.overview}</AccordionSummaryMUI>
            <AccordionDetailsMUI>
                <>
                    {(showKeyFigures && instIsLoaded && installationAnalysisStats.length) && (
                        <InstallationKeyFigurePanel instStats={installationAnalysisStats} />
                    ) || null}
                    {installationAnalysisParameters.length && (
                        <Card>
                            <CardContent>
                                <Grid2 container sx={fullScreenStyle} spacing={2}>
                                    <Grid2 size={12}>
                                        <ChartTabs
                                            style={{ left: 100 }}
                                            onChangeDate={dates => {
                                                if (dates.chartTab) {
                                                    setMinDate(dates.minDate)
                                                    setMaxDate(dates.maxDate ?? moment().valueOf())
                                                    setChartTab(dates.chartTab)
                                                }
                                            }}
                                        />
                                        {/* <ChartOptions
                                        setInstallationTypesChart={setInstallationTypesChart}
                                        setDataTypesChart={setDataTypesChart}
                                        showTitle={showTitle}
                                    /> */}
                                    </Grid2>
                                    <Grid2 size={12}>
                                        {!instIsLoaded ?
                                            <ProgressCard progress={instProgress} className='padding-top-4' />
                                            : (
                                                <InstallationCounterMultiGraph
                                                    stationId={stationId}
                                                    graphicHeight={chartHeight}
                                                    stationsEvents={stationsEvents}
                                                    instMeasures={instMeasures}
                                                    onFullScreen={() => setFullScreen(prevFullScreen => !prevFullScreen)}
                                                    defaultMinDate={minDate}
                                                    defaultMaxDate={maxDate}
                                                    showTitle={showTitle}
                                                />
                                            )
                                        }
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card >
                    ) || null}
                </>
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}

InstallationCounterChart.propTypes = {
    stationId: PropTypes.number,
    graphicHeight: PropTypes.number,
    instIds: PropTypes.arrayOf(PropTypes.number),
    setInstallationTypesChart: PropTypes.func,
    setDataTypesChart: PropTypes.func,
    showTitle: PropTypes.bool,
    showKeyFigures: PropTypes.bool,
    inPopup: PropTypes.bool,
}

const InstallationCounterPanel = ({
    stationId,
    // stationCode,
    showTitle,
    showKeyFigures,
    inPopup,
}) => {
    const {
        // associatedSites,
        installationsLight,
        accountUser,
    } = useSelector(store => ({
        // associatedSites: store.StationReducer.associatedSites,
        installationsLight: store.InstallationReducer.installationsLight,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const [installationTypesChart, setInstallationTypesChart] = useState([INSTALLATION_TYPE.STRAIT])
    const [dataTypesChart, setDataTypesChart] = useState([HYDROMETER_HEIGHT_TYPE])

    const dispatch = useDispatch()

    const {
        isLoaded: dataIsLoaded,
    } = useProgressDispatch(() => {
        const promisesInst = !installationsLight.length ? [dispatch(InstallationAction.fetchInstallationsLight())] : []
        return [
            dispatch(AdministrationAction.fetchGlobalParameters()),
            // dispatch(StationAction.fetchAssociatedSites(stationCode, getStationTypeCodeFromType(STATION_TYPE_NAME.installation))),
            dispatch(ParameterAction.fetchParameters()),
            ...promisesInst,
        ]
    }, [])


    const instIds = useMemo(() => {
        // if (!associatedSites.length || !installationsLight.length) {
        //     return undefined
        // }
        if (!dataTypesChart.length) {
            return []
        }
        // const associatedStationsInst = [stationId /* ...associatedSites.filter(as => as.typeName === STATION_TYPE_NAME.installation && as.stationLinkedId !== stationId).map(as => as.stationLinkedId)*/]
        // return installationsLight.filter(h => associatedStationsInst.includes(h.id) && installationTypesChart.includes(h.installationType)).map(inst => inst.id)
        return [stationId]
    }, [/* associatedSites,*/ installationTypesChart, installationsLight, dataTypesChart.length, stationId])

    const onChangeInstallationTypes = (value) => {
        setInstallationTypesChart(value)
        const param = new GlobalParametersDto({
            parameter: INSTALLATION_TYPE_PARAMETER,
            module: SIEAU,
            value: value.toString(),
            updateDate: moment().valueOf(),
            updateLogin: accountUser.login,
        })
        dispatch(AdministrationAction.updateGlobalParameter(param)).then(() => dispatch(AdministrationAction.fetchGlobalParameters()))
    }

    const onChangeDataTypes = (value) => {
        setDataTypesChart(value)
        const param = new GlobalParametersDto({
            parameter: DATA_TYPE_PARAMETER,
            module: SIEAU,
            value: value.toString(),
            updateDate: moment().valueOf(),
            updateLogin: accountUser.login,
        })
        dispatch(AdministrationAction.updateGlobalParameter(param)).then(() => dispatch(AdministrationAction.fetchGlobalParameters()))
    }

    return (dataIsLoaded && instIds) ? (
        <InstallationCounterChart
            stationId={stationId}
            instIds={instIds}
            setInstallationTypesChart={onChangeInstallationTypes}
            setDataTypesChart={onChangeDataTypes}
            showTitle={showTitle}
            showKeyFigures={showKeyFigures}
            inPopup={inPopup}
        />
    ) : null
}

InstallationCounterPanel.propTypes = {
    stationId: PropTypes.number.isRequired,
    // stationCode: PropTypes.string.isRequired,
    showTitle: PropTypes.bool,
    showKeyFigures: PropTypes.bool,
    inPopup: PropTypes.bool,
}

export default InstallationCounterPanel