/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import { Card, CardContent, Grid2 } from '@mui/material'
import NumberField from 'components/forms/NumberField'
import Checkbox from 'components/forms/Checkbox'
import DtoInstallation from 'installation/dto/installation/DtoInstallation'
import Input from 'components/forms/Input'

const headers = ['numGroup', 'nameGroup', 'nominalFlow', 'hmt', 'numberPumps', 'waterHammer']

const ElecMecDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.numGroup}
                            title={i18n.numGroup}
                            onChange={v => onChangeElement({ numGroup: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            value={stateElement.nameGroup}
                            title={i18n.nameGroup}
                            onChange={v => onChangeElement({ nameGroup: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.nominalFlow}
                            title={i18n.nominalFlow}
                            onChange={v => onChangeElement({ nominalFlow: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={stateElement.waterHammer}
                            label={i18n.waterHammer}
                            onChange={v => onChangeElement({ waterHammer: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.hmt}
                            title={i18n.hmt}
                            floatValue
                            onChange={v => onChangeElement({ hmt: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.numberPumps}
                            title={i18n.numberPumps}
                            onChange={v => onChangeElement({ numberPumps: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card >
    )
}

ElecMecDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const ElecmecEquipmentPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_elecmecEquipments'
            formatFunction={c => ({
                ...c,
                waterHammer: c.waterHammer ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
            })}
            titles={{
                title: i18n.elecmecEquipments,
                edit: i18n.editLithology,
                new: i18n.editElecmecEquipments,
            }}
            headers={headers}
            dialogContent={ElecMecDialogContent}
        />
    )
}

ElecmecEquipmentPanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default ElecmecEquipmentPanel