import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import Job from '../../../import/dto/DtoJob'
import JobAlert from './JobAlert'
import { arrayOf } from '../../../utils/StoreUtils'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import AppStore from '../../../store/AppStore'
import { getThresholdFormat } from '../../utils/MeasureUtil'

const DEFAULT_HYDRO_DATA_TYPE = '4'

class AlertHydro extends Component {
    componentDidMount() {
        this.props.fetchHydrometers()
        this.props.fetchHydrometricThresholds()
        if (!this.props.hydrometryDataTypes.length) {
            AppStore.dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
    }

    render() {
        const {
            hydrometryDataTypes,
            hydrometryThresholds,
            job,
            isEditMode,
            hydrometers,
            onChangeEmails,
            onChangeContactsIds,
            onChangeStation,
            onChangeAlertTypes,
        } = this.props
        const formatThresholds = hydrometryThresholds.map(t => {
            const dt = hydrometryDataTypes?.find(d => d.id === parseInt(t.dataType ?? DEFAULT_HYDRO_DATA_TYPE))
            return dt ? getThresholdFormat(t, dt) : {}
        })
        return (
            <JobAlert
                stationType='hydrometry'
                dataTypes={hydrometryDataTypes}
                job={job}
                isEditMode={isEditMode}
                stations={hydrometers}
                thresholds={formatThresholds}
                onChangeEmails={onChangeEmails}
                onChangeContactsIds={onChangeContactsIds}
                onChangeStation={onChangeStation}
                onChangeAlertTypes={onChangeAlertTypes}
                defaultDt={4}
            />
        )
    }
}

AlertHydro.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    isEditMode: PropTypes.bool,
    onChangeEmails: PropTypes.func,
    onChangeContactsIds: PropTypes.func,
    onChangeStation: PropTypes.func,
    onChangeAlertTypes: PropTypes.func,
    hydrometers: PropTypes.arrayOf(PropTypes.object),
    hydrometryThresholds: PropTypes.arrayOf(PropTypes.object),
    fetchHydrometers: PropTypes.func,
    fetchHydrometricThresholds: PropTypes.func,
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
}

const mapStateToProps = store => ({
    hydrometers: store.HydrometryReducer.hydrometricStations.map(({ id, code, name }) => ({
        id,
        code: `${id}`,
        name: `[${code}] ${name}`,
    })),
    hydrometryThresholds: store.HydrometryReducer.hydrometryThresholds,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
})

const mapDispatchToProps = {
    fetchHydrometers: HydrometryAction.fetchHydrometricStations,
    fetchHydrometricThresholds: HydrometryAction.fetchHydrometricThresholds,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AlertHydro)