/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { getFullDate, getHour } from '../../../utils/DateUtil'
import { getSandreLabel } from '../../../utils/StringUtil'
import StationDescriptionTable from './StationDescriptionTable'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { Card, CardContent, Grid2 } from '@mui/material'
import useSandreList from 'utils/customHook/useSandreList'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Input from 'components/forms/Input'
import { onChangeHour } from 'utils/FormUtils'
import Select from 'components/forms/Select'

const headers = ['startDate', 'endDate', 'usage', 'description']

const UsageDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const genUsagesList = useSandreList(SANDRE.USAGES_GENERAL)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            label={i18n.startDate}
                            onChange={v => onChangeElement({ startDate: v })}
                            max={stateElement.endDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(stateElement.startDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateElement.endDate }, stateElement.startDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            label={i18n.endDate}
                            onChange={v => onChangeElement({ endDate: v })}
                            min={stateElement.startDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(stateElement.endDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateElement.startDate }, stateElement.endDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={genUsagesList}
                            label={i18n.usage}
                            value={stateElement.usageCode}
                            onChange={v => onChangeElement({ usageCode: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.description}
                            value={stateElement.descriptive}
                            onChange={v => onChangeElement({ descriptive: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

UsageDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const StationUsagePanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_usages'
            formatFunction={u => ({
                ...u,
                startDate: getFullDate(u.startDate),
                endDate: getFullDate(u.endDate),
                usage: getSandreLabel(sandreCodes, SANDRE.USAGES_GENERAL, u.usageCode),
                description: u.descriptive,
            })}
            titles={{
                title: i18n.usages,
                edit: i18n.editUsageLink,
                new: i18n.newUsageLink,
            }}
            headers={headers}
            dialogContent={UsageDialogContent}
        />

    )
}

StationUsagePanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default StationUsagePanel