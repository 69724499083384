import { orderBy } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import SieauParameterDto from '../../../administration/dto/SieauParameterDto'
import ActionComponent from '../../../components/ActionComponent'
import GlobalCardList from '../../../components/list/cardList/GlobalCardList'
import SieauAction from '../../../components/sieau/SieauAction'
import { arrestsPath } from '../../../conf/SieauConstants'
import { getArrestColor } from '../../../utils/ColorUtil'
import { getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'


const propsToFetch = {
    arrests: false,
}

class ArrestsApp extends ActionComponent {
    componentDidMount() {
        this.props.forceFetch('title', [{
            title: i18n.homepage,
            href: '',
        },
        {
            title: i18n.arrests,
            href: 'arrests',
        }])
        const actions = {
            links: [
                {
                    href: arrestsPath,
                    img: 'assets/pictures/logos/propluvia.png',
                    label: 'Propluvia',
                },
            ],
        }
        this.setActions(actions)
    }

    getArrests = arrests => orderBy(
        arrests,
        [a => a.dateBegin],
        ['desc'],
    ).reduce((acc, v) => {
        const year = moment(v.dateBegin).year()
        return {
            ...acc,
            [year]: {
                title: year,
                cards: [
                    ...([year].cards || []),
                    {
                        content: this.getArrestRender(v),
                        color: 'null',
                    },
                ],
            },
        }
    }, [])

    getArrestRender = arrest => (
        <a href={ arrestsPath } target='_new'>
            <div
                key={arrest.id}
                className={ `collection-item row no-margin valign-wrapper border-bottom-light ${getArrestColor(arrest.typeArrest)}` }
            >
                <div className='col s2'>
                    {/* <h5 className='thin'>{ moment(arrest.dateBegin.split('T')[0], 'YYYY-MM-DD').format('DD MM YYYY') }</h5>*/}
                    <p className='center-align no-margin bold'>
                        { [
                            arrest.typeArrest.split(' ')[0],
                            <br/>,
                            arrest.typeArrest.split(' ')[1] || '',
                        ] }
                    </p>
                </div>
                <div className='col s10 white'>
                    <h4 className='clickable bold' >{ arrest.title }</h4>
                    <h6 className='clickable' >{ arrest.comment }</h6>
                </div>
            </div>
        </a>
    )

    render() {
        if (this.props.arrests.length) {
            return (
                <div className='row no-margin'>
                    <div className='col s8 offset-s2'>
                        <GlobalCardList
                            data={ this.getArrests(this.props.arrests) }
                            title={ i18n.arrests }
                            collapsible
                        />
                    </div>
                </div>
            )
        }
        return <h4 className='center-align'>{ i18n.noArrestsToDisplay }</h4>
    }
}

ArrestsApp.propTypes = getPropTypes(propsToFetch, {
    applicationSettings: PropTypes.arrayOf(PropTypes.instanceOf(SieauParameterDto)),
})

const mapStateToProps = store => getMapStateToProps(propsToFetch, {
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    fetch: SieauAction.fetch,
    forceFetch: SieauAction.forceFetch,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ArrestsApp)
