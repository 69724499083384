import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import { exportFile } from '../../../../utils/ExportDataUtil'
import DtoManagementUnit from '../dto/DtoManagementUnit'
import StationManagementUnitDialog from './StationManagementUnitDialog'
import ToastrAction from 'toastr/actions/ToastrAction'
import ManagementUnitAction from '../actions/ManagementUnitAction'
import { connect } from 'react-redux'
import { orderBy } from 'lodash'
import { getLabel } from 'utils/StoreUtils'
import Checkbox from 'components/forms/Checkbox'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import DtoPiezometerLight from 'piezometry/dto/DtoPiezometerLight'
import { hasValue } from 'utils/NumberUtil'

const headersTab = ['nullValue', 'station', 'type', 'historic', 'coeffHisto', 'scenario', 'coeffScena']

const stationsType = [{
    code: 1,
    label: 'piezometry',
}, {
    code: 2,
    label: 'hydrometry',
}, {
    code: 4,
    label: 'pluviometry',
}]

class TableManagementUnitStations extends Component {
    constructor(props) {
        super(props)
        this.state = {
            managementUnit: {},
            linkStationToUpdate: {},
            linkStationItem: {},
            openDialogStation: false,
        }
    }

    componentDidMount() {
        const { managementUnit } = this.props
        this.setState({ linkStationToUpdate: managementUnit.linkStations })
    }

    getStationsData = () => {
        const { isEdit, managementUnit } = this.props
        const linkStationsOrdered = orderBy(managementUnit.linkStations, 'year', 'desc')
        const sumCoeffHisto = linkStationsOrdered.filter((link) => link.real).map((link) => link.coeffReal).reduce((acc, c) => acc + c, 0)
        const warnHisto = !sumCoeffHisto || sumCoeffHisto != 1
        const sumCoeffPrevi = linkStationsOrdered.filter((link) => link.previ).map((link) => link.coeffPrevi).reduce((acc, c) => acc + c, 0)
        const warnPrevi = !sumCoeffPrevi || sumCoeffPrevi != 1
        return managementUnit.linkStations ? linkStationsOrdered.map((link) => {
            const station = this.getStation(link.stationType, link.stationId)
            return {
                link,
                station: {
                    value: `[${station.code}] ${station.name}`,
                },
                type: {
                    value: i18n[getLabel(stationsType, link.stationType)],
                },
                historic: {
                    value: link.real ? <Checkbox checked disabled /> : false,
                    sortValue: link.real,
                },
                coeffHisto: {
                    value: link.coeffReal,
                    color: warnHisto && link.real ? 'orange' : '',
                },
                scenario: {
                    value: link.previ ? <Checkbox checked disabled /> : false,
                    sortValue: link.previ,
                },
                coeffScena: {
                    value: link.coeffPrevi,
                    color: warnPrevi && link.previ ? 'orange' : '',
                },
                nullValue: {
                    value: isEdit ? (<i className='material-icons no-display right' style={{ cursor: 'pointer' }} onClick={() => this.setState({ openDialogStation: true, linkStationToUpdate: link, linkStationItem: link })}>edit</i>) : '',
                },
            }
        }) : []
    }

    getStation = (stationType, stationId) => {
        const { piezometersLight, hydrometricStations, pluviometers } = this.props
        switch (stationType) {
            case 1:
                return piezometersLight.find((s) => s.id === stationId) || {}
            case 2:
                return hydrometricStations.find((s) => s.id === stationId) || {}
            case 4:
                return pluviometers.find((s) => s.id === stationId) || {}
            default:
                return {}
        }
    }

    getExportDataStations = (stationsData) => {
        return stationsData.map((vyt, i) => {
            return i === 0 ? { headers: headersTab, ...vyt } : vyt
        })
    }

    getExportDataStationsAdditional = (stationsData) => {
        return stationsData.map((vyt, i) => {
            return i === 0 ? { headers: ['nameUge', headersTab], ...vyt } : vyt
        })
    }

    validStationUpdate = () => {
        const { linkStationToUpdate, linkStationItem } = this.state
        const { managementUnit } = this.props
        if (hasValue(linkStationToUpdate.stationType) && hasValue(linkStationToUpdate.stationId)) {
            if (linkStationItem.stationId !== linkStationToUpdate.stationId &&
                managementUnit.linkStations.find((s) => s.stationId === linkStationToUpdate.stationId && s.stationType === linkStationToUpdate.stationType)) {
                this.props.toastrError(i18n.elementsAlreadyLinked)
            } else {
                const newMu = (() => {
                    if (managementUnit.linkStations.find((s) => s.stationId === linkStationItem.stationId && s.stationType === linkStationItem.stationType)) {
                        const newLinksStation = managementUnit.linkStations.map((s) => {
                            return s.stationId === linkStationItem.stationId && s.stationType === linkStationItem.stationType ? linkStationToUpdate : s
                        })
                        return {
                            ...managementUnit,
                            linkStations: newLinksStation,
                        }
                    }
                    const newLinksStation = [
                        ...managementUnit.linkStations,
                        {
                            managementCode: managementUnit.managementCode,
                            ...linkStationToUpdate,
                        },
                    ]
                    return {
                        ...managementUnit,
                        linkStations: newLinksStation,
                    }
                })()
                if (newMu.linkStations) {
                    this.props.changeManagementUnit(newMu)
                    this.setState({ openDialogStation: false })
                }
            }
        } else {
            this.props.toastrError(i18n.pleaseCompleteAllRequiredField)
        }
    }

    onDelete = (line) => {
        const { managementUnit } = this.props
        this.props.changeManagementUnit({
            ...managementUnit,
            linkStations: managementUnit.linkStations.filter((s) => s.stationType !== line.link.stationType || s.stationId !== line.link.stationId),
        })
    }

    exportStations = (Stations) => {
        exportFile(
            {
                data: this.getExportDataStations(Stations),
                exportType: 'xlsx',
                titleFile: i18n.stations,
            },
            null,
            {
                data: this.getExportDataStationsAdditional(Stations),
                exportType: 'xlsx',
                titleFile: i18n.stations,
            },
            true,
        )
    }

    getDialogStation = () => {
        const { openDialogStation, linkStationToUpdate } = this.state
        return openDialogStation && (
            <StationManagementUnitDialog
                openDialog={openDialogStation}
                validUpdate={this.validStationUpdate}
                onChange={(v) => this.setState({ linkStationToUpdate: { ...linkStationToUpdate, ...v } })}
                linkStationToUpdate={linkStationToUpdate}
                closeDialog={() => this.setState({ openDialogStation: false })}
            />
        )
    }

    render() {
        const { isEdit } = this.props
        const stationsData = this.getStationsData()
        const addAction = isEdit ? [{
            iconName: 'note_add',
            tooltip: i18n.add,
            onClick: () => this.setState({ openDialogStation: true, linkStationToUpdate: {}, linkStationItem: {} }),
        }] : []

        return (
            <div className='row margin-top-2'>
                <div className='card row no-margin'>
                    <Table
                        title={i18n.stations}
                        condensed sortable
                        data={stationsData}
                        type={{ headers: headersTab }}
                        actions={[{
                            iconName: 'file_download',
                            tooltip: i18n.export,
                            onClick: () => this.exportStations(stationsData),
                        }, ...addAction]}
                        deletable={isEdit}
                        onDelete={(s) => this.onDelete(s)}
                        color
                    />
                    {this.getDialogStation()}
                </div>
            </div>
        )
    }
}

TableManagementUnitStations.propTypes = {
    isEdit: PropTypes.bool,
    managementUnit: PropTypes.instanceOf(DtoManagementUnit),
    fetchManagementUnits: PropTypes.func,
    updateManagementUnit: PropTypes.func,
    changeManagementUnit: PropTypes.func,
    toastrError: PropTypes.func,
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    piezometersLight: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
}

const mapStateToProps = store => {
    return {
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        pluviometers: store.PluviometryReducer.pluviometers,
        piezometersLight: store.PiezometryReducer.piezometersLight,
    }
}

const mapDispatchToProps = {
    fetchManagementUnits: ManagementUnitAction.fetchManagementUnits,
    updateManagementUnit: ManagementUnitAction.updateManagementUnit,
    toastrError: ToastrAction.error,
}

export default connect(mapStateToProps, mapDispatchToProps)(TableManagementUnitStations)
