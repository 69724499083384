import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../../utils/DateUtil'
import { onChangeDate } from '../../../../utils/FormUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import {
    getVisitCheckBoxChange,
    getVisitDatePickerChange,
    getVisitSandreSelectChange,
} from '../../../../utils/VisitUtils'
import DtoInstallationBorehole from '../../../dto/borehole/DtoInstallationBorehole'
import { Grid2 } from '@mui/material'
import { shallowEqual, useSelector } from 'react-redux'

const BoreholeManagementPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    borehole = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const onChangeBorehole = (value) => {
        onChange({
            link_managements: [
                {
                    idStation: borehole.id,
                    ...borehole.link_managements[0],
                    ...value,
                },
            ],
        })
    }

    const management = borehole.link_managements[0] || {}

    return (
        <>
            <Grid2 size={6}>
                <Checkbox
                    checked={management.monitoringProgram}
                    label={i18n.monitoringProgram}
                    onChange={(v) => {
                        onChangeBorehole({ monitoringProgram: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.monitoringProgram, management.monitoringProgram, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={management.lowFlowReduction}
                    title={i18n.lowFlowReduction}
                    onChange={(v) => {
                        onChangeBorehole({ lowFlowReduction: v })
                        onChangeVisit({ previousValue: management.lowFlowReduction, newValue: v, field: i18n.lowFlowReduction })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    value={getDate(management.hydroNoticeDate)}
                    title={i18n.hydroNoticeDate}
                    onChange={v => {
                        onChangeDate(v, v2 => onChangeBorehole({ hydroNoticeDate: v2 }))
                        onChangeVisit(getVisitDatePickerChange(i18n.hydroNoticeDate, management.hydroNoticeDate, v))
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    value={getDate(management.CODERSTDate)}
                    title={i18n.CODERSTDate}
                    onChange={v => {
                        onChangeDate(v, v2 => onChangeBorehole({ CODERSTDate: v2 }))
                        onChangeVisit(getVisitDatePickerChange(i18n.CODERSTDate, management.CODERSTDate, v))
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    value={getDate(management.DUPdecreeDate)}
                    title={i18n.DUPdecreeDate}
                    onChange={v => {
                        onChangeDate(v, v2 => onChangeBorehole({ DUPdecreeDate: v2 }))
                        onChangeVisit(getVisitDatePickerChange(i18n.DUPdecreeDate, management.DUPdecreeDate, v))
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={12}>
                <Select
                    value={management.advancementProc}
                    label={i18n.advancementProc}
                    keyValue='code'
                    options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_AVANCEMENTPROCEDURE)}
                    onChange={(v) => {
                        onChangeBorehole({ advancementProc: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_AVANCEMENTPROCEDURE, i18n.advancementProc, management.advancementProc, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={management.prescriptionFollowUp}
                    label={i18n.prescriptionFollowUp}
                    onChange={(v) => {
                        onChangeBorehole({ prescriptionFollowUp: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.prescriptionFollowUp, management.prescriptionFollowUp, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={management.needRevision}
                    label={i18n.needRevision}
                    onChange={(v) => {
                        onChangeBorehole({ needRevision: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.needRevision, management.needRevision, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={management.vulnerability}
                    label={i18n.vulnerability}
                    keyValue='code'
                    options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_VULNERABILITE)}
                    onChange={(v) => {
                        onChangeBorehole({ vulnerability: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_VULNERABILITE, i18n.vulnerability, management.vulnerability, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

BoreholeManagementPanel.propTypes = {
    borehole: PropTypes.instanceOf(DtoInstallationBorehole),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default BoreholeManagementPanel
