import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import PropTypes from 'prop-types'
import { push } from '@lagunovsky/redux-react-router'
import useSandreList from 'utils/customHook/useSandreList'
import AgriAction from '../../../agriAdministration/actions/AgriAction'
import { nbPerPageLabelTiny, nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import ReferencialAction from 'referencial/action/ReferencialAction'
import DtoSurveyWithStats from '../../dto/DtoSurveyWithStats'
import { getUser } from '../../../utils/SettingUtils'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import Checkbox from 'components/forms/Checkbox'
import MultiAutocomplete from 'components/forms/MultiAutocomplete'
import Textarea from 'components/forms/Textarea'
import { compact, isNil, orderBy, partition, uniq } from 'lodash'
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { formatMilliers, getSandreLabel, searchAllCharacters } from 'utils/StringUtil'
import FormFilterScenarios from '../panels/FormFilterScenarios'
import Other from 'components/actions/Other'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import { LEVELS, PERIODES } from 'agriAdministration/constants/AgriConstants'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import moment from 'moment'
import ExportAction from 'export/actions/ExportAction'
import { formatData } from 'utils/ExportDataUtil'
import { darkBlue, mainBlue } from 'utils/constants/ColorTheme'
import { styled } from '@mui/styles'
import Card from 'components/card/Card'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { ManagementUnitsPARChart } from '../panels/ManagementUnitsPARChart'
import SimpleCheckbox from 'components/forms/SimpleCheckbox'
import { RepartitionPARChart } from '../panels/RepartitionPARChart'
import CreatePARDialog from '../modals/CreatePARDialog'
import { sieauTooltip } from 'utils/FormUtils'
import { useParams } from 'react-router'
import { ALIGN, CardTable, NewTable } from 'components/datatable/NewTable'

const STATUT_ENVOI_PREFECTURE = 4
const STATUT_VALIDE_PREFECTURE = 5

const Step = ({ number, disabled }) => (
    <Grid2
        container
        justifyContent='center'
        alignItems='center'
        style={{
            borderRadius: 50,
            height: 30,
            width: 30,
            backgroundColor: disabled ? 'rgba(0, 0, 0, 0.12)' : mainBlue,
            color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'white',
        }}
        className='margin-right-1 bold'
    >
        {number}
    </Grid2>
)

Step.propTypes = {
    number: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
}

const DialogValidateAttributed = ({
    listManagementUnits = [],
    open,
    onClose = () => {},
    onValidate = () => {},
}) => {
    const [changeStatus, setChangeStatus] = useState(true)
    const [attributionDate, setAttributionDate] = useState(moment().valueOf())

    const [ugWithoutScenario, ugWithScenario] = useMemo(() => partition(listManagementUnits, (u) => !u.hasScenario), [listManagementUnits])
    const [ugNotOK, ugOK] = useMemo(() => partition(ugWithoutScenario, (u) => u.color === 'red'), [ugWithoutScenario])

    return (
        <Dialog open={open} maxWidth='xl'>
            <DefaultDialogTitle
                title={i18n.payAttention}
                onClose={onClose}
            />
            <DialogContent>
                <Grid2 container className='padding-top-1' spacing={1}>
                    <Grid2 size={4}>
                        <CardTable
                            title={i18n.managementUnitsWithScenarioValidated}
                            data={ugWithScenario}
                            type={{ headers: ['id', 'name'] }}
                            nbPerPageLabel={nbPerPageLabelShort}
                            condensed
                            sortable
                            round
                            paging
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <CardTable
                            title={i18n.managementUnitsWithoutScenarioNotOverrun}
                            data={ugOK}
                            type={{ headers: ['id', 'name'] }}
                            nbPerPageLabel={nbPerPageLabelShort}
                            condensed
                            sortable
                            round
                            paging
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <CardTable
                            title={i18n.managementUnitsWithoutScenarioOverrun}
                            data={ugNotOK}
                            type={{ headers: ['id', 'name'] }}
                            nbPerPageLabel={nbPerPageLabelShort}
                            condensed
                            sortable
                            round
                            paging
                        />
                    </Grid2>
                    <Grid2 size={4} className='margin-top-1'>
                        <SimpleDatePicker
                            calendarPosition='right'
                            label={i18n.attributionDate}
                            onChange={(v) => setAttributionDate(v)}
                            value={attributionDate}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleCheckbox
                            label={i18n.changeStatusModalAllocatedVolumesPAR}
                            checked={changeStatus}
                            onToggle={() => setChangeStatus(prev => !prev)}
                        />
                    </Grid2>
                    <Grid2 size={12} className='margin-top-1 margin-bottom-1 bold' style={{ whiteSpace: 'pre-wrap' }}>{i18n.descriptionModalAllocatedVolumesPAR}</Grid2>
                    <Grid2 size={12} className='margin-top-1 margin-bottom-1'>
                        <h5 id='modal-description' className='bold' style={{ color: 'red' }}>{i18n.warningModalAllocatedVolumesPAR}</h5>
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='outlined'
                    onClick={onClose}
                >
                    {i18n.cancel}
                </Button>
                <Button
                    variant='contained'
                    onClick={() => onValidate(attributionDate, changeStatus)}
                >
                    {i18n.confirm}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DialogValidateAttributed.propTypes = {
    listManagementUnits: PropTypes.arrayOf(PropTypes.shape({})),
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
}

const DialogValidateAuthorized = ({
    open,
    onClose = () => {},
    onValidate = () => {},
}) => {
    const [authorisationDate, setAuthorisationDate] = useState(moment().valueOf())
    return (
        <Dialog open={open}>
            <DefaultDialogTitle
                title={i18n.payAttention}
                onClose={onClose}
            />
            <DialogContent>
                <Grid2 container>
                    <Grid2 size={12} className='margin-top-2 margin-bottom-2'>
                        {i18n.validateAttributedVolumesConfirm}
                    </Grid2>
                    <Grid2 size={6} className='margin-top-3 margin-bottom-3'>
                        <SimpleDatePicker
                            calendarPosition='right'
                            label={i18n.authorisationDate}
                            onChange={(v) => setAuthorisationDate(v)}
                            value={authorisationDate}
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='outlined'
                    onClick={onClose}
                >
                    {i18n.cancel}
                </Button>
                <Button
                    variant='contained'
                    onClick={() => onValidate(authorisationDate)}
                >
                    {i18n.confirm}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

DialogValidateAuthorized.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
}

const GridValue = styled(Grid2)({
    display: 'flex',
    justifyContent: 'flex-end',
})

const headersLight = ['managementUnit', 'subManagementUnit', 'totalAUP', 'lowWaterAUP', 'notLowWaterAUP', 'totalAsked', 'lowWaterAsked', 'notLowWaterAsked', 'totalAllocated', 'lowWaterAllocated', 'notLowWaterAllocated']
const headersLightLowWater = ['managementUnit', 'subManagementUnit', 'totalAUP', 'lowWaterAUP', 'totalAsked', 'lowWaterAsked', 'totalAllocated', 'lowWaterAllocated']
const headersLightNotLowWater = ['managementUnit', 'subManagementUnit', 'totalAUP', 'notLowWaterAUP', 'totalAsked', 'notLowWaterAsked', 'totalAllocated', 'notLowWaterAllocated']

const PARDashboardApp = () => {
    const { id } = useParams()

    const {
        sandreCodes,
        PAR,
        surveysWithStats,
        managementUnits,
        contributors,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        PAR: store.AgriReducer.PAR,
        surveysWithStats: store.AgriReducer.surveysWithStats,
        managementUnits: store.ManagementUnitReducer.managementUnits,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const [localPAR, setLocalPAR] = useState({})
    const [editMode, setEditMode] = useState(false)
    const [openDuplicate, setOpenDuplicate] = useState(false)
    const [filter, setFilter] = useState({
        incomplete: true,
        conform: true,
        closeOverrun: true,
        overrun: true,
        ugWithoutScenario: true,
    })
    const [openScenario, setOpenScenario] = useState(false)
    const [openModalValidateAttributed, setOpenModalValidateAttributed] = useState(false)
    const [openModalValidateAuthorized, setOpenModalValidateAuthorized] = useState(false)
    const [newScenario, setNewScenario] = useState({})

    const duplicatePAR = useMemo(() => {
        return {
            ...PAR,
            name: `${PAR?.name || ''} (${i18n.copy})`,
            linkSurveys: PAR.linkSurveys.map(v => v.idSurvey),
            id: undefined,
        }
    }, [PAR])

    const dispatch = useDispatch()

    useEffect(() => {
        if (PAR.id !== Number(id)) {
            dispatch(AgriAction.fetchPAR(id)).then(() => setLocalPAR({ ...PAR, linkSurveys: PAR.linkSurveys.map(v => v.idSurvey) }))
        }
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!surveysWithStats.length) {
            dispatch(AgriAction.fetchSurveysWithStats())
        }
    }, [PAR.id, id])

    const getColors = (volumeAUP, volume) => {
        if (isNil(volumeAUP) ?? isNil(volume)) {
            return { color: 'grey', level: LEVELS.incomplete }
        }
        if (volume > volumeAUP) {
            return { color: 'red', level: LEVELS.overrun }
        }
        if (((volume / volumeAUP) * 100) >= 80) {
            return { color: 'orange', level: LEVELS.closeOverrun }
        }
        return { color: 'green', level: LEVELS.conform }
    }

    useTitle(() => [{
        title: i18n.planning,
        href: 'planning/dashboard',
    }, {
        title: 'PARs',
        href: 'planning/par',
    }, {
        title: PAR.name,
        href: `par/${id}`,
    }], [PAR.name, id])

    const duplicateAction = () => {
        setOpenDuplicate(true)
    }

    const ugeToShow = useMemo(() => {
        const [ugeWithParents, idsParents] = [managementUnits.filter((m) => m.parent), uniq(managementUnits.map((m) => m.parent))]
        const ugeWithoutSubUnits = managementUnits.filter((m) => !idsParents.includes(m.managementCode))
        const ugeFormatted = uniq([...ugeWithParents, ...ugeWithoutSubUnits]).map((mu) => ({
            ...mu,
            id: mu.id,
            parent: hasValue(mu.parent) ? (managementUnits.find((m) => m.managementCode === mu.parent) || {}).id : '',
            idForOrder: mu.parent || mu.managementCode,
        }))
        return orderBy(ugeFormatted, ['idForOrder', 'parent', 'id'])
    }, [managementUnits])

    const datas = useMemo(() => {
        return compact(ugeToShow.map((uge) => {
            const volumes = PAR.linkVolumesUGE.find((r) => (r.subManagementCode ?? r.managementCode) === uge.managementCode) || {}

            const scenarioValidate = PAR.linkScenarios.find((sc) => sc.managementCode === uge.managementCode && sc.statusCode === 1)
            const aupFound = orderBy(uge.link_aup.filter((u) => u.year <= PAR.year), 'year', 'desc')[0] || {}
            const { volumeToCompare, volumeAllocated, volumeAllocatedLowWater, volumeAllocatedNotLowWater, volumeAUP } = (() => {
                if (scenarioValidate) {
                    const volumesAllocated = PAR.linkScenariosVolumesAllocated.find((v) => v.idScenario === scenarioValidate.id) || {}
                    switch (PAR.periode) {
                        case (PERIODES.ALL): default:
                            return { ...volumesAllocated, volumeToCompare: volumesAllocated?.volumeAllocated, volumeAUP: aupFound.aup }
                        case (PERIODES.LOW_WATER):
                            return { ...volumesAllocated, volumeToCompare: volumesAllocated?.volumeAllocatedLowWater, volumeAUP: aupFound.aupLowWater }
                        case (PERIODES.NOT_LOW_WATER):
                            return { ...volumesAllocated, volumeToCompare: volumesAllocated?.volumeAllocatedNotLowWater, volumeAUP: aupFound.aupExceptLowWater }
                    }
                }
                switch (PAR.periode) {
                    case (PERIODES.ALL): default:
                        return { volumeToCompare: PAR.tanks ? volumes.volume - volumes.tanks : volumes.volume, volumeAUP: aupFound.aup }
                    case (PERIODES.LOW_WATER):
                        return { volumeToCompare: volumes?.volumeAskedLowWater, volumeAUP: aupFound.aupLowWater }
                    case (PERIODES.NOT_LOW_WATER):
                        return { volumeToCompare: volumes?.volumeAskedNotLowWater, volumeAUP: aupFound.aupExceptLowWater }
                }
            })()
            const { color, level } = getColors(volumeAUP, volumeToCompare)
            if ((level === LEVELS.overrun && !filter.overrun) ||
                (level === LEVELS.closeOverrun && !filter.closeOverrun) ||
                (level === LEVELS.conform && !filter.conform) ||
                (level === LEVELS.incomplete && !filter.incomplete)) {
                return null
            }
            return {
                ...aupFound,
                ...volumes,
                ...uge,
                color,
                level,
                title: `[${uge.id}] ${uge.name || ''} (${scenarioValidate?.name || i18n.noScenarioSelected})`,
                hasScenario: !!scenarioValidate,
                managementUnit: uge?.codeWithLabel,
                subManagementUnit: uge.codeWithLabel,
                totalAUP: aupFound.aup,
                lowWaterAUP: aupFound.aupLowWater,
                notLowWaterAUP: aupFound.aupExceptLowWater,
                totalAsked: volumes.volume,
                lowWaterAsked: volumes.volumeAskedLowWater,
                notLowWaterAsked: volumes.volumeAskedNotLowWater,
                totalAllocated: hasValue(volumeAllocated) ? volumeAllocated : volumes.volume,
                lowWaterAllocated: hasValue(volumeAllocatedLowWater) ? volumeAllocatedLowWater : volumes.volumeAskedLowWater,
                notLowWaterAllocated: hasValue(volumeAllocatedNotLowWater) ? volumeAllocatedNotLowWater : volumes.volumeAskedNotLowWater,
                lowWaterCompliance: volumeAllocatedLowWater <= aupFound.aupLowWater ? i18n.yes : i18n.no,
                notLowWaterCompliance: volumeAllocatedNotLowWater <= aupFound.aupExceptLowWater ? i18n.yes : i18n.no,
            }
        }))
    }, [PAR, ugeToShow, filter])

    const getExport = (data, headersExport, type, fileName) => {
        const orderedData = orderBy(data, 'codification')
        const dataWithHeader = orderedData.length > 0 ? [{ ...orderedData[0], headers: headersExport }, ...orderedData.slice(1)] : []
        dispatch(ExportAction.export(formatData(dataWithHeader), type, fileName))
    }

    const getDataExport = (data) => {
        const headersLightFiltered = (() => {
            switch (PAR.periode) {
                case PERIODES.ALL: default:
                    return headersLight
                case PERIODES.LOW_WATER:
                    return headersLightLowWater
                case PERIODES.NOT_LOW_WATER:
                    return headersLightNotLowWater
            }
        })()
        return {
            exportChoices: [{
                name: i18n.syntheticExport,
                formats: [{
                    type: i18n.exportXLSX,
                    callback: () => getExport(data, headersLightFiltered, 'xlsx', `${PAR.name}_${PAR.year}_${i18n.syntheticExport}`),
                },
                {
                    type: i18n.csv,
                    callback: () => getExport(data, headersLightFiltered, 'csv', `${PAR.name}_${PAR.year}_${i18n.syntheticExport}`),
                }],
                helpTooltip: i18n.helpExportSyntheticPAR,
            }, {
                name: i18n.exportFull,
                formats: [{
                    type: i18n.exportXLSX,
                    callback: () => dispatch(ExportAction.exportPARFull(PAR.id, 'xlsx', `${PAR.name}_${PAR.year}_${i18n.exportFull}`)),
                },
                {
                    type: i18n.csv,
                    callback: () => dispatch(ExportAction.exportPARFull(PAR.id, 'csv', `${PAR.name}_${PAR.year}_${i18n.exportFull}`)),
                }],
                helpTooltip: i18n.helpExportCompletPAR,
            }],
        }
    }

    useActions(() => {
        if (getUser().isAdmin === '1' || getUser().metadata === '1') {
            return editMode ? {
                cancel: () => {
                    setLocalPAR({ ...PAR, linkSurveys: PAR.linkSurveys.map(v => v.idSurvey) })
                    setEditMode(false)
                },
                save: () => {
                    if (localPAR?.name && localPAR?.year && localPAR?.periode && localPAR?.linkSurveys?.length) {
                        dispatch(AgriAction.updatePAR({ ...localPAR, linkSurveys: localPAR.linkSurveys.map(v => ({ idPAR: localPAR.id, idSurvey: v })) }))
                        setEditMode(false)
                    } else {
                        dispatch(ToastrAction.error(i18n.pleaseCompleteAllRequiredField))
                    }
                },
            } : {
                delete: () => dispatch(AgriAction.deletePAR(id)).then(() => dispatch(push('/planning/par'))),
                edit: () => setEditMode(true),
                duplicate: () => duplicateAction(localPAR),
                other: {
                    other: (
                        <Other
                            className='clickable'
                            tooltip={i18n.createNewScenario}
                            icon='data_saver_on'
                            onClick={() => setOpenScenario(true)}
                        />
                    ),
                },
                exportChoice: getDataExport(datas),
            }
        }
        return {}
    }, [editMode, localPAR, datas])

    const onDuplicate = (newPAR) => {
        dispatch(AgriAction.createPAR(newPAR)).then((newId) => {
            setOpenDuplicate(false)
            dispatch(push(`/par/${newId}`))
        })
    }

    const onCreateScenario = () => {
        if (newScenario.name && newScenario?.managementUnits?.length) {
            const scenarios = newScenario.managementUnits.map((m) => ({
                ...newScenario,
                idPAR: parseInt(id),
                managementCode: m,
            }))
            dispatch(AgriAction.createMultipleScenarios(scenarios)).then(() => {
                setOpenScenario(false)
                setNewScenario({})
                dispatch(AgriAction.fetchPAR(id))
            })
        } else {
            dispatch(ToastrAction.error(i18n.pleaseCompleteAllRequiredField))
        }
    }

    useEffect(() => {
        setLocalPAR({ ...PAR, linkSurveys: PAR.linkSurveys.map(v => v.idSurvey) })
    }, [PAR, PAR.linkScenarios])

    const onValidateAuthorizedVolumes = (authorisationDate) => {
        setOpenModalValidateAuthorized(false)
        PAR.linkSurveys.forEach(s => {
            dispatch(AgriAction.validateVolumesSurvey(s.idSurvey, authorisationDate))
        })
        dispatch(AgriAction.updatePAR({ ...localPAR, statusCode: STATUT_VALIDE_PREFECTURE, linkSurveys: localPAR.linkSurveys.map(v => ({ idPAR: localPAR.id, idSurvey: v })) }))
    }

    const getModalScenario = () => (
        <Dialog
            fullWidth
            open={openScenario}
            maxWidth='md'
        >
            <DefaultDialogTitle
                title={i18n.createNewScenario}
                onClose={() => setOpenScenario(false)}
            />
            <DialogContent className='padding-top-1' sx={{ height: '350px' }}>
                <Grid2 container className='padding-top-1 padding-bottom-1' spacing={1}>
                    <Grid2 size={6}>
                        <Input
                            col={12}
                            title={i18n.name}
                            value={newScenario.name}
                            onChange={value => setNewScenario({ ...newScenario, name: value })}
                            obligatory
                        />
                        <Textarea
                            col={12}
                            title={i18n.description}
                            value={newScenario.description}
                            onChange={value => setNewScenario({ ...newScenario, description: value })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <MultiAutocomplete
                            col={12}
                            label={i18n.managementUnits}
                            values={newScenario.managementUnits}
                            onChange={values => setNewScenario({ ...newScenario, managementUnits: values })}
                            clearFunction
                            integerValue
                            noNullValue
                            elements={orderBy(ugeToShow, 'id')}
                            keyName='labelWithCode'
                            keyId='managementCode'
                            obligatory
                        />
                    </Grid2>
                </Grid2>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCreateScenario} variant='contained' color='primary'>
                    {i18n.create}
                </Button>
            </DialogActions>
        </Dialog>
    )

    const getHash = (scenario) => {
        return searchAllCharacters(['name', 'description'].map(key => scenario[key] || ''))
    }

    const getFilteredScenarios = (scenarios, ugeData) => {
        const searchValueFilter = filter.searchValue ? scenarios.filter(sc => getHash(sc).includes(filter.searchValue)) : scenarios
        const lowWaterComplianceFilter = filter.lowWaterCompliance ? searchValueFilter.filter(sc => localPAR.linkScenariosVolumesAllocated.find((v) => v.idScenario === sc.id)?.volumeAllocatedLowWater <= ugeData.lowWaterAUP) : searchValueFilter
        const notLowWaterComplianceFilter = filter.notLowWaterCompliance ? lowWaterComplianceFilter.filter(sc => localPAR.linkScenariosVolumesAllocated.find((v) => v.idScenario === sc.id)?.volumeAllocatedNotLowWater <= ugeData.notLowWaterAUP) : lowWaterComplianceFilter
        return orderBy(notLowWaterComplianceFilter, 'statusCode')
    }

    const datasFiltered = useMemo(() => {
        return filter.ugWithoutScenario ? datas : datas.filter((data) => localPAR?.linkScenarios?.some((sc) => sc.managementCode === data.managementCode))
    }, [filter.ugWithoutScenario, datas, localPAR])

    const getTooltipVolumesTypePrel = (volumeESU, volumeESO) => sieauTooltip(
        <>
            <span>{`${i18n.superficial} : ${isNil(volumeESU) || isNaN(volumeESU) ? i18n.unknown : `${formatMilliers(volumeESU)} m3`}`}</span><br />
            <span>{`${i18n.underground} : ${isNil(volumeESO) || isNaN(volumeESO) ? i18n.unknown : `${formatMilliers(volumeESO)} m3`}`}</span>
        </>,
    )

    const statusList = useSandreList('AGRI_PAR.STATUT')

    return (
        <div className='padding-1' style={{ paddingBottom: 80 }}>
            <Grid2 container spacing={1} justifyContent='stretch'>
                <Grid2 size={12}>
                    <Accordion>
                        <AccordionSummary color={darkBlue} round sx={{ fontSize: '18px' }}>{i18n.generalInfos}</AccordionSummary>
                        <AccordionDetails>
                            <Grid2 container>
                                <Grid2 size={9} container spacing={1}>
                                    <Grid2 size={3}>
                                        <Input
                                            title={i18n.name}
                                            value={localPAR.name}
                                            onChange={value => setLocalPAR({ ...localPAR, name: value })}
                                            disabled={!editMode}
                                            obligatory
                                        />
                                    </Grid2>
                                    <Grid2 size={2}>
                                        <NumberField
                                            title={i18n.year}
                                            value={localPAR.year}
                                            onChange={value => setLocalPAR({ ...localPAR, year: value })}
                                            disabled={!editMode}
                                            obligatory
                                        />
                                    </Grid2>
                                    <Grid2 size={2}>
                                        <Select
                                            label={i18n.status}
                                            options={statusList}
                                            value={localPAR.statusCode}
                                            integerValue
                                            keyValue='code'
                                            onChange={value => setLocalPAR({ ...localPAR, statusCode: value })}
                                            disabled={!editMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={2}>
                                        <Select
                                            label={i18n.periode}
                                            options={[{
                                                value: PERIODES.ALL,
                                                label: i18n.all,
                                            }, {
                                                value: PERIODES.LOW_WATER,
                                                label: i18n.lowWater,
                                            }, {
                                                value: PERIODES.NOT_LOW_WATER,
                                                label: i18n.notLowWater,
                                            }]}
                                            value={localPAR.periode}
                                            onChange={value => setLocalPAR({ ...localPAR, periode: value })}
                                            disabled={!editMode}
                                            integerValue
                                            noNullValue
                                            obligatory
                                            noSort
                                        />
                                    </Grid2>
                                    <Grid2 size={3}>
                                        <Select
                                            label={i18n.organism}
                                            options={contributors}
                                            value={localPAR.organism}
                                            onChange={value => setLocalPAR({ ...localPAR, organism: value })}
                                            keyValue='id'
                                            disabled={!editMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={7}>
                                        <Textarea
                                            title={i18n.description}
                                            value={localPAR.description}
                                            onChange={value => setLocalPAR({ ...localPAR, description: value })}
                                            disabled={!editMode}
                                        />
                                    </Grid2>
                                    <Grid2 size={5}>
                                        <MultiAutocomplete
                                            label={i18n.surveys}
                                            values={localPAR.linkSurveys}
                                            onChange={values => setLocalPAR({ ...localPAR, linkSurveys: values })}
                                            disabled={!editMode}
                                            clearFunction
                                            integerValue
                                            noNullValue
                                            obligatory
                                            elements={orderBy(surveysWithStats.filter((sc) => sc.year === localPAR.year), 'year', 'desc')}
                                            keyName='nameWithYear'
                                            keyId='idSurvey'
                                            tooltip={i18n.surveysFromYearSelected}
                                        />
                                    </Grid2>
                                </Grid2>
                                <Grid2 size={3} container className='padding-1'>
                                    <Checkbox
                                        col={12}
                                        checked={localPAR.tanks}
                                        label={i18n.takeWaterTanks}
                                        tooltip={i18n.takeWaterTanksDescription}
                                        onChange={v => setLocalPAR({ ...localPAR, tanks: v })}
                                        disabled={!editMode}
                                        truncate={false}
                                    />
                                    <Checkbox
                                        col={12}
                                        checked={localPAR.onlyDeclarationsCompleted}
                                        label={i18n.takeOnlyDeclarationsFull}
                                        onChange={v => setLocalPAR({ ...localPAR, onlyDeclarationsCompleted: v })}
                                        disabled={!editMode}
                                        truncate={false}
                                    />
                                </Grid2>
                            </Grid2>
                        </AccordionDetails>
                    </Accordion>
                </Grid2>
                <Grid2 size={12}>
                    <Card title={i18n.procedureMonitoringPAR} round>
                        <Grid2 container className='padding-1' justifyContent='space-around' alignItems='center' spacing={1}>
                            <Grid2 container size='auto'>
                                <Step number={1} disabled />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled
                                >
                                    {i18n.closedSurvey}
                                </Button>
                            </Grid2>
                            <Grid2 container size='auto'>
                                <Step number={2} disabled />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    disabled
                                >
                                    {i18n.createdPAR}
                                </Button>
                            </Grid2>
                            <Grid2 container size='auto'>
                                <Step number={3} disabled={localPAR.statusCode >= STATUT_ENVOI_PREFECTURE} />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setOpenModalValidateAttributed(true)}
                                    disabled={localPAR.statusCode >= STATUT_ENVOI_PREFECTURE}
                                >
                                    {i18n.validateAttributedVolumes}
                                </Button>
                            </Grid2>
                            <Grid2 container size='auto'>
                                <Step number={4} disabled={localPAR.statusCode >= STATUT_ENVOI_PREFECTURE} />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => dispatch(AgriAction.updatePAR({ ...localPAR, statusCode: STATUT_ENVOI_PREFECTURE, linkSurveys: localPAR.linkSurveys.map(v => ({ idPAR: localPAR.id, idSurvey: v })) }))}
                                    disabled={localPAR.statusCode >= STATUT_ENVOI_PREFECTURE}
                                >
                                    {i18n.sendToPrefecture}
                                </Button>
                            </Grid2>
                            <Grid2 container size='auto'>
                                <Step number={5} />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setOpenModalValidateAuthorized(true)}
                                >
                                    {i18n.changeAssignedVolumesToAuthorized}
                                </Button>
                            </Grid2>
                        </Grid2>
                    </Card>
                </Grid2>
                <Grid2 size={12}>
                    <FormFilterScenarios onChange={obj => setFilter(obj)} />
                </Grid2>
                <Grid2 size={12}>
                    <Card round>
                        <Grid2 container className='padding-1' justifyContent='space-between'>
                            <StyledFieldSet>
                                <StyledLegend>{i18n.statuts}</StyledLegend>
                                <ManagementUnitsPARChart datas={datasFiltered} />
                            </StyledFieldSet>
                            <StyledFieldSet>
                                <StyledLegend>{i18n.volumes}</StyledLegend>
                                <RepartitionPARChart datas={datasFiltered} />
                            </StyledFieldSet>
                        </Grid2>
                    </Card>
                </Grid2>
                {compact(datasFiltered.map((data, i) => {
                    const scenariosUG = localPAR?.linkScenarios?.filter((sc) => sc.managementCode === data.managementCode)
                    return (
                        <Grid2 size={12} className='padding-top-1' key={data.managementCode}>
                            <Accordion defaultExpanded={i === 0} round>
                                <AccordionSummary
                                    round
                                    style={{
                                        backgroundColor: data.color,
                                        color: 'white',
                                        borderBottom: 'none',
                                    }}
                                >
                                    {data.title}
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 0 }}>
                                    <NewTable
                                        rows={[{ id: -1, name: i18n.initialRequest }, ...getFilteredScenarios(scenariosUG, data)].map((d) => {
                                            const volAllocated = localPAR.linkScenariosVolumesAllocated?.find((v) => v.idScenario === d.id) || {}
                                            return {
                                                ...data,
                                                color: d.id === -1 ? 'grey lighten-1' : undefined,
                                                ...d,
                                                nullValue: d.statusCode === 1 && <Icon style={{ color: 'green' }}>check_circle</Icon>,
                                                status: d.id === -1 ? i18n.initial : (getSandreLabel(sandreCodes, 'AGRI_SCENARIO.STATUT', d.statusCode) || i18n.unknown),
                                                scenario: d.name,
                                                periode: (
                                                    <Grid2 container direction='column' spacing={1}>
                                                        <Grid2>{i18n.lowWater}</Grid2>
                                                        <Grid2>{i18n.notLowWater}</Grid2>
                                                        <Grid2>{i18n.total}</Grid2>
                                                    </Grid2>
                                                ),
                                                AUP: (
                                                    <Grid2 container direction='column' sx={{ paddingRight: '50%' }} spacing={1}>
                                                        <GridValue {...getTooltipVolumesTypePrel(data.aupLowWaterSup, data.aupLowWaterSout)}>{isNil(data.lowWaterAUP) ? i18n.unknown : formatMilliers(data.lowWaterAUP)}</GridValue>
                                                        <GridValue {...getTooltipVolumesTypePrel(data.aupExceptLowWaterSup, data.aupExceptLowWaterSout)}>{isNil(data.notLowWaterAUP) ? i18n.unknown : formatMilliers(data.notLowWaterAUP)}</GridValue>
                                                        <GridValue {...getTooltipVolumesTypePrel(data.aupLowWaterSup + data.aupExceptLowWaterSup, data.aupLowWaterSout + data.aupExceptLowWaterSout)}>{isNil(data.totalAUP) ? i18n.unknown : formatMilliers(data.totalAUP)}</GridValue>
                                                    </Grid2>
                                                ),
                                                requestedVolumes: (
                                                    <Grid2 container direction='column' sx={{ paddingRight: '50%' }} spacing={1}>
                                                        <GridValue {...getTooltipVolumesTypePrel(data.volumeAskedLowWaterSup, data.volumeAskedLowWaterSout)}>{isNil(data.lowWaterAsked) ? i18n.unknown : formatMilliers(data.lowWaterAsked)}</GridValue>
                                                        <GridValue {...getTooltipVolumesTypePrel(data.volumeAskedNotLowWaterSup, data.volumeAskedNotLowWaterSout)}>{isNil(data.notLowWaterAsked) ? i18n.unknown : formatMilliers(data.notLowWaterAsked)}</GridValue>
                                                        <GridValue {...getTooltipVolumesTypePrel(data.volumeAskedSup, data.volumeAskedSout)}>{isNil(data.totalAsked) ? i18n.unknown : formatMilliers(data.totalAsked)}</GridValue>
                                                    </Grid2>
                                                ),
                                                attributedVolumes: d.id === -1 ? ' ' : (
                                                    <Grid2 container direction='column' sx={{ paddingRight: '50%' }} spacing={1}>
                                                        <GridValue {...getTooltipVolumesTypePrel(volAllocated.volumeAllocatedLowWaterSup, volAllocated.volumeAllocatedLowWaterSout)}>{isNil(volAllocated?.volumeAllocatedLowWater) ? i18n.unknown : formatMilliers(volAllocated?.volumeAllocatedLowWater)}</GridValue>
                                                        <GridValue {...getTooltipVolumesTypePrel(volAllocated.volumeAllocatedNotLowWaterSup, volAllocated.volumeAllocatedNotLowWaterSout)}>{isNil(volAllocated?.volumeAllocatedNotLowWater) ? i18n.unknown : formatMilliers(volAllocated?.volumeAllocatedNotLowWater)}</GridValue>
                                                        <GridValue {...getTooltipVolumesTypePrel(volAllocated.volumeAllocatedSup, volAllocated.volumeAllocatedSout)}>{isNil(volAllocated?.volumeAllocated) ? i18n.unknown : formatMilliers(volAllocated?.volumeAllocated)}</GridValue>
                                                    </Grid2>
                                                ),
                                                conformity: (
                                                    <Grid2 container direction='column' spacing={1}>
                                                        <Grid2>{(d.id === -1 ? data.lowWaterAsked : volAllocated?.volumeAllocatedLowWater) <= data.lowWaterAUP ? i18n.yes : i18n.no}</Grid2>
                                                        <Grid2>{(d.id === -1 ? data.notLowWaterAsked : volAllocated?.volumeAllocatedNotLowWater) <= data.notLowWaterAUP ? i18n.yes : i18n.no}</Grid2>
                                                        <Grid2>{(d.id === -1 ? data.totalAsked : volAllocated?.volumeAllocated) <= data.totalAUP ? i18n.yes : i18n.no}</Grid2>
                                                    </Grid2>
                                                ),
                                                skimming: volAllocated.hasSkimmingRules ? { value: <SimpleCheckbox checked disabled />, align: ALIGN.CENTER } : ' ',
                                                arbitration: volAllocated.hasArbitrationRules ? { value: <SimpleCheckbox checked disabled />, align: ALIGN.CENTER } : ' ',
                                            }
                                        })}
                                        headers={['nullValue', 'scenario', 'status', 'periode', 'AUP', 'requestedVolumes', 'attributedVolumes', 'conformity', 'skimming', 'arbitration' ]}
                                        rowsPerPageOptions={nbPerPageLabelTiny}
                                        onClickRow={s => s.id !== -1 && dispatch(push(`/scenario/${s.id}`))}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid2>
                    )
                }))}
            </Grid2>
            {getModalScenario()}
            <CreatePARDialog
                title={i18n.duplicatePAR}
                onValidate={onDuplicate}
                onClose={() => setOpenDuplicate(false)}
                par={duplicatePAR}
                open={openDuplicate}
            />
            <DialogValidateAttributed
                listManagementUnits={datas}
                open={openModalValidateAttributed}
                onClose={() => setOpenModalValidateAttributed(false)}
                onValidate={(attributionDate, changeStatus) => {
                    setOpenModalValidateAttributed(false)
                    dispatch(AgriAction.validatePAR(PAR.id, attributionDate, changeStatus))
                }}
            />
            <DialogValidateAuthorized
                open={openModalValidateAuthorized}
                onClose={() => setOpenModalValidateAuthorized(false)}
                onValidate={onValidateAuthorizedVolumes}
            />
        </div>
    )
}

PARDashboardApp.propTypes = {
    surveysWithStats: PropTypes.arrayOf(DtoSurveyWithStats),
    managementUnits: PropTypes.arrayOf(DtoManagementUnit),
    params: PropTypes.shape({ id: PropTypes.string }),
}

export default PARDashboardApp