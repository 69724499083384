import ActionComponent from 'components/ActionComponent'
import { push } from '@lagunovsky/redux-react-router'
import HomeAction from 'home/actions/HomeAction'
import { isEqual, omit } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Card from '../../../components/card/Card'
import Table from '../../../components/datatable/Table'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import Row from '../../../components/react/Row'
import { contentsPath } from '../../../conf/basepath'
import { PATH_ADMINISTRATION, PATH_ADMINISTRATION_CARTOGRAPHY } from '../../../home/constants/RouteConstants'
import { nbPerPageLabelShort } from '../../../referencial/constants/ReferencialConstants'
import StationAction from '../../../station/actions/StationAction'
import { hexToRGBColor, RGBToHexColor } from '../../../utils/ColorUtil'
import { setChoiceModal } from '../../../utils/FormUtils'
import { getProjectionsList } from '../../../utils/mapUtils/CoordinateUtils'
import { getUser } from '../../../utils/SettingUtils'
import AdministrationAction from '../../actions/AdministrationAction'
import { LAYER_TYPES } from '../../constants/AdministrationConstants'
import LayerThemeDto from '../../dto/LayerThemeDto'
import ThemeDto from '../../dto/ThemeDto'

const DivWarning = (obj) => {
    return (
        <div style={{
            backgroundColor: obj.isError ? 'red' : '#F89406',
            borderRadius: 2,
            padding: '5px 10px',
            margin: '10px 0',
            fontWeight: 'bold',
            fontSize: 16,
            color: 'white' }}
        >
            {obj.label}
        </div>
    )
}

class ThemeApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            theme: {
                ...new ThemeDto(props.theme),
                color: RGBToHexColor(props.theme.color),
            },
            layers: props.layers,
            documentLoading: false,
            directoryCartoExist: true,
        }
    }

    handleChange = attr => value => {
        this.setState(prevState => ({
            theme: {
                ...prevState.theme,
                [attr]: value,
            },
        }))
    }

    handleChangeColor = e => {
        const color = e.target.value
        this.setState(prevState => ({
            theme: {
                ...prevState.theme,
                color,
            },
        }))
    }

    componentWillUnmount() {
        this.props.resetTheme()
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (this.props.params.id !== 'new') {
            this.props.fetchTheme(this.props.params.id)
        }
        this.applyActions()
        this.setTitle()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.params.id !== this.props.params.id) {
            this.props.fetchTheme(this.props.params.id)
        }
        if (!isEqual(prevProps.theme, this.props.theme)) {
            this.setState({
                theme: {
                    ...this.props.theme,
                    color: RGBToHexColor(this.props.theme.color),
                },
            })
            this.setTitle()
            this.applyActions()
        }
        if (!isEqual(prevProps.layers, this.props.layers)) {
            this.setState({ layers: this.props.layers })
        }
        if (prevState.directoryCartoExist !== this.state.directoryCartoExist) {
            this.applyActions()
        }
    }

    setTitle = (props = this.props) => {
        const title = (() => {
            if (props.params.id !== 'new') {
                const { theme } = this.props
                if (theme) {
                    return theme.name || theme.id
                }
                return ''
            }
            return i18n.newTheme
        })()
        this.props.setTitle([
            {
                title: i18n.administration,
                href: PATH_ADMINISTRATION,
            },
            {
                title: i18n.cartography,
                href: `${PATH_ADMINISTRATION}/${PATH_ADMINISTRATION_CARTOGRAPHY}`,
            },
            {
                title,
                href: `${PATH_ADMINISTRATION}/${PATH_ADMINISTRATION_CARTOGRAPHY}/${props.params.id}`,
            },
        ])
    }

    handleSave = () => {
        const theme = {
            ...this.state.theme,
            color: hexToRGBColor(this.state.theme.color),
            layers: this.state.layers,
        }
        const layersRow = this.getLayersRow()
        const layersNoUrlWarning = this.getLayerNoUrlWarning(layersRow)
        if (layersNoUrlWarning) {
            this.props.warning(i18n.warningNoUrlLayer)
        } else if (this.props.params.id !== 'new') {
            this.props.updateTheme(this.props.params.id, theme)
        } else {
            this.props.createTheme(theme)
        }
    }

    handleDelete = () => {
        this.props.deleteTheme(this.props.params.id)
    }

    applyActions = (props = this.props) => {
        const { directoryCartoExist } = this.state
        const actions = (() => {
            if (props.params.id === 'new' && !directoryCartoExist) {
                return {}
            }
            const actns = {
                save: this.handleSave,
                cancel: () => this.props.push('/administration/cartography'),
            }
            if (props.params.id !== 'new') {
                return {
                    ...actns,
                    delete: this.handleDelete,
                }
            }
            return actns
        })()
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'edit', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    addParameter = () => {
        const layers = [
            new LayerThemeDto({
                theme: this.state.theme.id,
            }),
            ...this.state.layers,
        ]
        this.setState({ layers })
    }

    onUploadKML = (event) => {
        // Read File
        // const { stationId, stationCode } = this.state
        if (event.target.files && event.target.files[0]) {
            this.setState({ documentLoading: true })
            let reader = new FileReader()
            const file = event.target.files[0]
            const name = file.name.replace(/ /g, '_')
            reader.onload = (e) => {
                this.saveKML({
                    stationType: -1,
                    stationId: -1,
                    path: '',
                    name: name.toUpperCase(),
                    content: e.target.result,
                }, file.name)
            }
            reader.readAsDataURL(event.target.files[0])
        }
    }

    saveKML = (file, name) => {
        this.props.uploadKMLFileTheme(file).then(() => {
            const layers = [
                new LayerThemeDto({
                    name: file.name,
                    typeLayer: 1,
                    url: `${contentsPath}CARTO/${file.name}`,
                    layer: name,
                }),
                ...this.state.layers,
            ]
            this.setState({ layers, documentLoading: false })
        })
    }

    handleDeleteLayer = e => {
        if (e) {
            const layers = this.state.layers.filter((o, i) => i != e.idx)
            const layerToDelete = this.state.layers.filter((o, i) => i == e.idx)
            if (layerToDelete[0]) {
                const deleteLayer = layerToDelete[0]
                if (deleteLayer.url?.includes(contentsPath)) {
                    setChoiceModal(i18n.wantToDeleteLayerFile, {
                        yes: () => {
                            const fileToDelete = {
                                name: deleteLayer.url.split(`${contentsPath}CARTO`)[1],
                                fileType: 'carto',
                            }
                            this.props.deleteFile(fileToDelete)
                        },
                    })
                }
            }
            this.setState({ layers })
        }
    }

    handleDuplicateLayer = e => {
        if (e) {
            const { layers } = this.state
            const start = layers.slice(0, e.idx)
            const end = layers.slice(e.idx)
            this.setState({
                layers: [...start, layers[e.idx], ...end],
            })
        }
    }

    handleChangeLayers = (param, layerIdx) => value => {
        const layers = [...this.state.layers]
        layers[layerIdx] = {
            ...layers[layerIdx],
            [param]: value,
        }
        this.setState({ layers })
    }

    getLayersHeader = layers => ({
        headers: Object.keys(layers[0]).filter(k => k !== 'idx'),
    })

    getLayersRow = () => {
        const { layers } = this.state
        return layers.map((l, i) => {
            return {
                idx: i,
                name: (
                    <Input
                        className='min-width-100'
                        value={ l.name }
                        onChange={ this.handleChangeLayers('name', i) }
                        noInputFieldClass
                    />
                ),
                type: (
                    <Select
                        selectClassName='min-width-100'
                        options={ LAYER_TYPES }
                        onChange={ this.handleChangeLayers('typeLayer', i) }
                        value={ l.typeLayer }
                        keyvalue='code'
                    />
                ),
                url: (
                    <Input
                        className='min-width-100'
                        value={ l.url }
                        onChange={ this.handleChangeLayers('url', i) }
                        noInputFieldClass
                    />
                ),
                code: (
                    <Input
                        className='min-width-100'
                        value={ l.layer }
                        onChange={ this.handleChangeLayers('layer', i) }
                        noInputFieldClass
                    />
                ),
                source: (
                    <Input
                        className='min-width-100'
                        value={ l.source }
                        onChange={ this.handleChangeLayers('source', i) }
                        noInputFieldClass
                    />
                ),
                projection: (
                    <Select
                        selectClassName='min-width-100'
                        value={ l.projection }
                        options={ getProjectionsList() }
                        onChange={ this.handleChangeLayers('projection', i) }
                        keyvalue='code'
                    />
                ),
            }
        })
    }

    getLayerNoUrlWarning = (layersRow) => {
        const layersWithNoUrl = layersRow.filter(l => !l.url.props.value)
        return layersWithNoUrl.length > 0
    }

    render() {
        const { theme, documentLoading, directoryCartoExist } = this.state
        const layersRow = this.getLayersRow()
        const layersNoUrlWarning = this.getLayerNoUrlWarning(layersRow)
        return (
            <div className='margin-1'>
                <div id='file' className='col s12'>
                    <Card className='padding-1'>
                        <Row>
                            <Input
                                col={ 2 }
                                title={ i18n.name }
                                value={ theme.name }
                                onChange={ this.handleChange('name') }
                            />
                            <div className='col s2'>
                                <div className={ this.props.noInputFieldClass ? '' : 'input-field' } >
                                    <input
                                        type='color'
                                        className='sieau-input form-control input-sm '
                                        data-attribute='color'
                                        id='title-color'
                                        value={ theme.color }
                                        onChange={ this.handleChangeColor }
                                    />
                                    <label htmlFor='title-color' >{ i18n.color }</label>
                                </div>
                            </div>
                            <div className='col s2 no-padding'>
                                <div className='col s12 no-padding'>
                                    <a className='right waves-effect waves-light btn' onClick={ this.addParameter }>
                                        <i className='material-icons left'>add_box</i>
                                        { i18n.add }
                                    </a>
                                </div>
                            </div>
                            <div className='col s2 file-field'>
                                { documentLoading ? (
                                    <i className={ 'material-icons rotate' } style={{ fontSize: '30px', width: '30px', height: '30px' }}>sync</i>
                                ) : (
                                    <div className='btn'>
                                        <span>{ i18n.importLabel }</span>
                                        <input
                                            accept='.kml'
                                            id='uploadKML'
                                            type='file'
                                            onChange={ this.onUploadKML }
                                        />
                                    </div>
                                )}
                            </div>
                        </Row>
                    </Card>
                    {!directoryCartoExist && <DivWarning label={i18n.warningDirectoryCartoNotExist} isError={true} />}
                    {layersNoUrlWarning && <DivWarning label={i18n.warningNoUrlLayer} />}
                    <Card>
                        {(layersRow.length > 0) && (
                            <div className='job-parameter-table'>
                                <Table
                                    showTitle={ false }
                                    data={ layersRow }
                                    nbPerPageLabel={ nbPerPageLabelShort }
                                    type={ this.getLayersHeader(layersRow) }
                                    customHeaders={{
                                        url: `${i18n.url} ${i18n.obligatoryField}`,
                                        type: `${i18n.type} ${i18n.obligatoryField}`,
                                    }}
                                    onDelete={ this.handleDeleteLayer }
                                    onDuplicate={ this.handleDuplicateLayer }
                                    deletable
                                    duplicable
                                    noFlexCell
                                    sortable
                                    paging
                                />
                            </div>
                        )}
                    </Card>
                    <Card>
                        { i18n.keyWordNOW }
                    </Card>
                </div>
            </div>
        )
    }
}

ThemeApp.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.number,
    }),
    theme: PropTypes.instanceOf(ThemeDto),
    layers: PropTypes.arrayOf(PropTypes.instanceOf(LayerThemeDto)),
    checkUrl: PropTypes.func,
    push: PropTypes.func,
}

const mapStateToProps = store => ({
    theme: store.AdministrationReducer.theme,
    layers: store.AdministrationReducer.layers,
})

const mapDispatchToProps = {
    deleteFile: StationAction.deleteFile,
    fetchTheme: AdministrationAction.fetchTheme,
    updateTheme: AdministrationAction.updateTheme,
    uploadKMLFileTheme: AdministrationAction.uploadKMLFileTheme,
    deleteTheme: AdministrationAction.deleteTheme,
    resetTheme: AdministrationAction.resetTheme,
    createTheme: AdministrationAction.createTheme,
    checkUrl: AdministrationAction.checkUrl,
    warning: ToastrAction.warning,
    setTitle: HomeAction.setTitle,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeApp)
