import Row from '../../../../components/react/Row'
import { POINT } from '../../../constants/HydrometryConstants'
import i18n from 'simple-react-i18n'
import { hasValue } from '../../../../utils/NumberUtil'
import { getQualificationColor, getStatusIcon } from '../../../../utils/AnalyseUtils'
import { arrayOf, getLabel } from '../../../../utils/StoreUtils'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getColorCircleElement, getRGBColor } from '../../../../utils/ColorUtil'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import PropTypes from 'prop-types'
import DtoHydroMeasure from '../../../dto/chronicMeasures/DtoHydroMeasure'
import { getDate, getFullDate } from '../../../../utils/DateUtil'
import DtoJobExecution from '../../../../import/dto/DtoJobExecution'
import DtoJob from '../../../../import/dto/DtoJob'
import JobAction from '../../../../import/actions/JobAction'

const DescriptionMeasure = ({
    selection,
    measure,
}) => {
    const {
        sandreCodes,
        contributors,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    const [job, setJob] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        if (measure?.jobExecutionId) {
            dispatch(JobAction.getJobByJobExecutionId(measure.jobExecutionId)).then(res => {
                setJob(res)
            })
        }
    }, [measure])

    const statusList = getStatusSelectOptions()
    const qualificationsList = getQualificationSelectOptions()

    const color = measure ? getQualificationColor(measure.qualification) : ''
    const colorQualification = measure ? getRGBColor(color) : ''
    const getColorCircle = measure ? getColorCircleElement(colorQualification, true) : ''

    return (<div>
        <Row>
            <div className='col s12'>
                <h6 className='bold'>
                    { selection === POINT ? i18n.selectedMeasureDetails : i18n.firstMeasureDetails }
                </h6>
            </div>
        </Row>
        <Row>
            { measure.updateDate && measure.updateLogin && <Row><div className='col s12'>{ `${i18n.updatedOn + getDate(measure.updateDate)} ${i18n.by} ${measure.updateLogin}` }</div></Row> || '' }
        </Row>
        <Row>
            { hasValue(measure.status) && <div className='col s10'>{ getStatusIcon(measure.status, status) }{ getLabel(statusList, measure.status) }</div> || '' }
            { hasValue(measure.qualification) && <div className='col s6'>{ getColorCircle }{ getLabel(qualificationsList, measure.qualification) }</div> || '' }
        </Row>
        <Row>
            { hasValue(measure.nature) && <div className='col s6'>{ (`${i18n.nature} : ${getSandreLabel(sandreCodes, SANDRE.PIEZOMETER_MEASURE_NATURE, measure.nature)}`) }</div> || '' }
            { hasValue(measure.producer) && <div className='col s6'>{ (`${i18n.producer} : ${getLabel(contributors, measure.producer, 'mnemonique')}`) }</div> || '' }
        </Row>
        <Row>
            { hasValue(measure.initialPoint) && <div className='col s6'>{ `${i18n.initialPoint} : ${measure.initialPoint === 1 ? i18n.yes : i18n.no}` }</div> || '' }
        </Row>
        <Row>
            { hasValue(measure.manager) && <div className='col s6'>{ (`${i18n.administrator} : ${getLabel(contributors, measure.manager, 'mnemonique')}`) }</div> || '' }
            { hasValue(measure.validator) && <div className='col s6'>{ (`${i18n.validator} : ${getLabel(contributors, measure.validator, 'mnemonique')}`) }</div> || '' }
        </Row>
        <Row>
            { hasValue(measure.value) && <div className='col s6'>{ (`${i18n.value} : ${measure.value}`) }</div> || '' }
        </Row>
        <Row>
            { measure.importDate && <Row><div className='col s12'>{ `${i18n.createdOn} ${getFullDate(measure.importDate)}` }</div></Row> || '' }
            { job && <Row><div className='col s12'>{ `${i18n.importName} : ${job.name || ''} ${job.login || ''}`}</div></Row> || '' }</Row>
    </div>)
}
DescriptionMeasure.propTypes = {
    jobs: arrayOf(DtoJob),
    jobExecutions: arrayOf(DtoJobExecution),
    selection: PropTypes.string,
    measure: PropTypes.instanceOf(DtoHydroMeasure),
}
export default DescriptionMeasure