import { shallowEqual, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import AppStore from '../../../../../store/AppStore'
import PiezometryAction from '../../../../../piezometry/actions/PiezometryAction'
import { genericPromise } from '../../../../../utils/ActionUtils'
import { path } from '../../../../../conf/basepath'
import Row from '../../../../../components/react/Row'
import Button from '../../../../../components/forms/Button'
import Table from '../../../../../components/datatable/Table'
import { nbPerPageLabel } from '../../../../../referencial/constants/ReferencialConstants'
import { setConfirmationModal } from '../../../../../utils/FormUtils'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import i18n from 'simple-react-i18n'
import SelectStationComponent from '../../../../../components/modal/SelectStationComponent'
import { intersection } from 'lodash'
import ToastrAction from '../../../../../toastr/actions/ToastrAction'

const CorrectV6ExportApp = ({}) => {
    const {
        piezosIndex,
        networkPiezoLink,
    } = useSelector(store => ({
        piezosIndex: store.PiezometryReducer.piezosIndex,
        networkPiezoLink: store.PiezometryReducer.networkPiezoLink,
    }), shallowEqual)
    const [popupOpen, setPopupOpen] = useState(false)
    const [dataLoading, setDataLoading] = useState(false)
    const [filterStations, setFilterStations] = useState([])
    const [correctedIds, setCorrectedIds] = useState([]) // ChReperePbOutput

    const loadCorrectedPiezos = () => {
        setDataLoading(true)
        genericPromise(`${path}piezometer/dataTypes/correctV6`, 'POST', { mode: 'CH_REPERE_CORRECTED', stationIds: [] }).then(json => {
            setDataLoading(false)
            setCorrectedIds(json)
        })
    }

    const exportOnePiezo = (id) => {
        setDataLoading(true)
        genericPromise(`${path}piezometer/dataTypes/correctV6`, 'POST', { mode: 'UPDATE_ADES_JOURNALISATION', stationIds: [id] }).then(() => {
            setDataLoading(false)
            AppStore.dispatch(ToastrAction.success('Mise à jour avec succès !'))
            loadCorrectedPiezos()
        })
    }
    const filteredIds = filterStations.length ? intersection(filterStations.map(p => p.id), correctedIds) : correctedIds
    const exportAllPiezo = () => {
        setDataLoading(true)
        genericPromise(`${path}piezometer/dataTypes/correctV6`, 'POST', { mode: 'UPDATE_ADES_JOURNALISATION', stationIds: filteredIds }).then(() => {
            setDataLoading(false)
            AppStore.dispatch(ToastrAction.success('Mise à jour avec succès !'))
            loadCorrectedPiezos()
        })
    }


    const foundPiezos = filteredIds.map(p => ({
        ...piezosIndex[p],
    }))

    useEffect(() => {
        loadCorrectedPiezos()
    }, [])

    return (
        <div>
            <Row>
                <Button col={3} title='Filtrer les stations' onClick={() => setPopupOpen(true)}/>
                <div className='col s3'>{filterStations.length} stations filtrées</div>
                <Button col={3} title='Lancer la recherche' onClick={loadCorrectedPiezos}/>
                <Button col={3} title='Exporter tous les points' onClick={() => setConfirmationModal(() => exportAllPiezo(), 'Confirmer la mise à jour de ades_journalisation pour tous ces points ?')}/>
            </Row>
            {
                dataLoading && <h5>En cours de calcul ...</h5>
            }
            <Row>
                <Table
                    title='Stations corrigées'
                    condensed
                    data={foundPiezos}
                    type={{ headers: ['id', 'code', 'name'] }}
                    sortable
                    onClick={p => {
                        setConfirmationModal(() => exportOnePiezo(p.id), 'Confirmer la mise à jour de ades_journalisation pour ce point ?')
                    }}
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                />
            </Row>
            <Dialog
                onClose={() => setPopupOpen(false)}
                fullWidth
                maxWidth='lg'
                open={popupOpen}
                classes='no-padding'
                scroll='body'
            >
                <DialogTitle>{i18n.calculationParams}</DialogTitle>
                <DialogContent>
                    <SelectStationComponent
                        selectedStations={filterStations}
                        campaignEvents={[]}
                        stationType='piezometry'
                        onChangeSelectedStation={tmpStations => setFilterStations(tmpStations)}
                        networkLinks={networkPiezoLink}
                        // minHeight='30vh'
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}

CorrectV6ExportApp.propTypes = {
}

export default CorrectV6ExportApp
