import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import StationDescriptionTable from './StationDescriptionTable'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import Checkbox from 'components/forms/Checkbox'
import NumberField from 'components/forms/NumberField'

const headers = ['code', 'name', 'population', 'servedPopulation', 'memberDisplay']
const exportHeaders = ['code', 'name', 'population', 'servedPopulation', 'l']

const AssociatedTownDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Select
                            value={stateElement.city}
                            label={i18n.cities}
                            onChange={v => onChangeElement({ city: v })}
                            options={cities}
                        />
                    </Grid2>
                    <Grid2 size={6} style={{ padding: '15 0 0 20' }}>
                        <Checkbox
                            checked={stateElement.member}
                            label={i18n.member}
                            onChange={v => onChangeElement({ member: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.servedPopulation}
                            title={i18n.servedPopulation}
                            onChange={v => onChangeElement({ servedPopulation: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

AssociatedTownDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}


const StationAssociatedTownsPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
    invertedHeaderStyle = false,
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_associatedTowns'
            formatFunction={at => {
                const city = cities.find(c => c.code === at.city) || {}
                return {
                    ...city,
                    ...at,
                    memberDisplay: at.member ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
                    memberExport: at.member ? i18n.yes : i18n.no,
                }
            }}
            titles={{
                title: i18n.cities,
                edit: i18n.editTown,
                new: i18n.newTown,
            }}
            headers={headers}
            dialogContent={AssociatedTownDialogContent}
            exportHeaders={exportHeaders}
            invertedHeaderStyle={invertedHeaderStyle}
        />

    )
}

StationAssociatedTownsPanel.propTypes = {
    station: PropTypes.instanceOf(DtoDistributionUnit),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    invertedHeaderStyle: PropTypes.bool,
}

export default StationAssociatedTownsPanel