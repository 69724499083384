import ApplicationConf from '../../conf/ApplicationConf'
import { checkAuth, checkAuthBis, checkError, getJson } from 'utils/ActionUtils'
import { getAuthorization } from '../../utils/ActionUtils'
import ToastrAction from 'toastr/actions/ToastrAction'
import i18n from 'simple-react-i18n'
import LogAction from '../../log/actions/LogAction'
import JobAction from './JobAction'
import WaitAction from 'wait/WaitAction'

const IntegrationAction = {
    integrationEdilabo: param => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.integration.edilabo(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(param),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then(() => {
                dispatch(ToastrAction.success(i18n.importFinish))
            }).catch(err => {
                dispatch(LogAction.logError(`${i18n.importError} : ${err}`))
                dispatch(ToastrAction.error(i18n.importError))
            })
    },

    getUpdateExecuteJob: (id, param) => dispatch => {
        return JobAction.promiseJob(id).then(job => {
            const parameters = job.parameters.length ? JSON.parse(job.parameters) : {}
            const newJob = {
                ...job,
                parameters: {
                    ...parameters,
                    parameters: [JSON.stringify(param)],
                },
            }
            return JobAction.promiseUpdateJob(newJob).then(() => {
                return fetch(ApplicationConf.job.execute(id), {
                    method: 'POST',
                    headers: getAuthorization(),
                }).then(() => {
                    dispatch(ToastrAction.success(i18n.jobLaunched))
                    dispatch(WaitAction.waitStop())
                })
            }).catch(err => {
                dispatch(LogAction.logError(`${i18n.jobUpdateError} : ${err}`))
                dispatch(ToastrAction.error(`${i18n.jobUpdateError}: ${err}`))
                dispatch(WaitAction.waitStop())
            })
        }).catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError} : ${err}`))
            dispatch(ToastrAction.error(`${i18n.fetchError}: ${err}`))
            dispatch(WaitAction.waitStop())
        })
    },

    integration: (id, param, parameters, validation, paramValidation, toastr = true) => dispatch => {
        dispatch(WaitAction.waitStart())
        return JobAction.promisseUploadFile(parameters).then(() => {
            if (toastr) {
                dispatch(ToastrAction.success(i18n.importFinish))
            }
            if (validation) {
                return fetch(ApplicationConf.integration.xsdValidation(), {
                    method: 'POST',
                    headers: getAuthorization(),
                    body: JSON.stringify(paramValidation),
                })
                    .then(checkAuthBis)
                    .then(getJson)
                    .then(checkError)
                    .then(() => {
                        dispatch(ToastrAction.success(i18n.validationXsdOK))
                        dispatch(IntegrationAction.getUpdateExecuteJob(id, param))
                    }).catch(err => {
                        dispatch(LogAction.logError(`${i18n.validationXsdKO} : ${err}`))
                        dispatch(ToastrAction.error(`${i18n.validationXsdKO}: ${err}`))
                        dispatch(WaitAction.waitStop())
                    })
            }
            return dispatch(IntegrationAction.getUpdateExecuteJob(id, param))
        })
    },
}


export default IntegrationAction
