import ReactEchartsCore from 'echarts-for-react/lib/core'
import 'echarts/lib/chart/bar'
import echarts from 'echarts/lib/echarts'
import { filter, orderBy, reduce } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import i18n from 'simple-react-i18n'
import { exportExcelIcon, exportPictureIcon } from '../../../../../../components/echart/EChartUtils'
import { enumerateBetweenDates, getFullDate, getMonthYear } from '../../../../../../utils/DateUtil'
import { exportFile } from '../../../../../../utils/ExportDataUtil'
import DtoUserStatistic from '../../../dto/DtoUserStatistic'
import { Card } from '@mui/material'


const AccessChartPanel = ({
    userStatistics,
}) => {
    let echartRef = useRef(undefined)
    let displayToolbox = useRef(false)

    const getReduceStatistics = (statistics) => {
        return reduce(statistics, (a, b) => {
            (a[getMonthYear(b.statisticDate)] || (a[getMonthYear(b.statisticDate)] = [])).push(b)
            return a
        }, {})
    }


    const getInitXAxis = (statistics) => {
        const orderStatistics = orderBy(filter(statistics, o => o.value), 'statisticDate', 'asc')
        const firstDate = orderStatistics[0]
        if (firstDate) {
            const rangeDate = enumerateBetweenDates(firstDate.statisticDate, moment(), 'months')
            return rangeDate.map((o) => getMonthYear(o))
        }
        return []
    }

    const getExportData = () => {
        const data = userStatistics.map((d, i) => {
            const stat = {
                login: d.login,
                statisticDate: getFullDate(d.statisticDate),
                module: d.module,
                value: d.value,
            }
            if (i === 0) {
                return { ...stat, headers: ['login', 'statisticDate', 'module', 'value'] }
            }
            return stat
        })
        return data
    }

    const getToolbox = () => ({
        right: 20,
        feature: {
            restore: {
                show: displayToolbox.current,
                title: i18n.restore,
            },
            saveAsImage: {
                show: displayToolbox.current,
                title: i18n.export,
                icon: exportPictureIcon,
                name: i18n.userConnexionStatistic,
            },
            myToolExport: {
                show: displayToolbox.current,
                title: i18n.excelExport,
                icon: exportExcelIcon,
                onclick: () => {
                    exportFile({
                        data: getExportData(),
                        exportType: 'xlsx',
                        titleFile: i18n.userConnexionStatistic,
                    })
                },
            },
        },
    })

    const getOptions = (statistics) => {
        const xAxisDatas = getInitXAxis(statistics)
        const reduceDatas = getReduceStatistics(statistics)
        const dataSeries = xAxisDatas.map((o) => {
            if (reduceDatas[o]) {
                return reduceDatas[o].length
            }
            return 0
        })
        return {
            color: ['blue'],
            grid: {
                height: 120,
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true,
            },
            xAxis: {
                type: 'category',
                data: xAxisDatas,
            },
            yAxis: {
                type: 'value',
            },
            series: [{
                type: 'bar',
                data: dataSeries,
            }],
            toolbox: getToolbox(),
        }
    }

    const statistics = filter(userStatistics, o => o.value)
    if (!statistics.length) {
        return null
    }

    return (
        <Card>
            <div
                onMouseOver={() => {
                    displayToolbox.current = true
                    echartRef.current?.getEchartsInstance().setOption({
                        toolbox: getToolbox(),
                    })
                }}
                onMouseOut={() => {
                    displayToolbox.current = false
                    echartRef.current?.getEchartsInstance().setOption({
                        toolbox: getToolbox(),
                    })
                }}
            >
                <ReactEchartsCore
                    echarts={ echarts }
                    option={ getOptions(statistics) }
                    notMerge={ true }
                    lazyUpdate={ true }
                    className={ 'row no-margin' }
                    style={ { height: 155 } }
                    ref={e => {
                        echartRef.current = e
                    }}
                />
            </div>
        </Card>
    )
}
AccessChartPanel.propTypes = {
    userStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
}
export default AccessChartPanel
