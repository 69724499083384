/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../../utils/DateUtil'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'

const headers = ['name', 'code', 'startDate', 'endDate', 'comment']

const AssociatedIndustriesDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const {
        installationsLight,
    } = useSelector(store => ({
        installationsLight: store.InstallationReducer.installationsLight,
    }), shallowEqual)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={12}>
                        <Select
                            label={i18n.industry}
                            value={stateElement.code}
                            options={installationsLight.filter(i => i.installationType === 1)}
                            keyValue='id'
                            onChange={v => onChangeElement({ code: parseInt(v) })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            id='startDate'
                            style={{ padding: 0 }}
                            label={i18n.startDate}
                            onChange={v => onChangeElement({ startDate: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            style={{ padding: 0 }}
                            id='endDate'
                            label={i18n.endDate}
                            onChange={v => onChangeElement({ endDate: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={stateElement.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

AssociatedIndustriesDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}


const STEPAssociatedIndustriesPanel = ({
    STEP = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        installationsLight,
    } = useSelector(store => ({
        installationsLight: store.InstallationReducer.installationsLight,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={STEP}
            readMode={readMode}
            onChange={onChange}
            keyList='link_associatedIndustries'
            formatFunction={c => {
                const foundInstallation = installationsLight.find(i => parseInt(i.id) === c.code) || {}
                return {
                    ...c,
                    ...foundInstallation,
                    startDate: getDate(c.startDate),
                    endDate: getDate(c.endDate),
                }
            }}
            titles={{
                title: i18n.industries,
                edit: i18n.editIndustry,
                new: i18n.newIndustry,
            }}
            headers={headers}
            dialogContent={AssociatedIndustriesDialogContent}
        />

    )
}

STEPAssociatedIndustriesPanel.propTypes = {
    STEP: PropTypes.object, // DtoStep
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default STEPAssociatedIndustriesPanel