import React, { Component } from 'react'
import AppStore from '../../../store/AppStore'
import i18n from 'simple-react-i18n'
import { connect } from 'react-redux'
import HomeAction from '../../../home/actions/HomeAction'
import Card from '../../../components/card/Card'
import Input from '../../../components/forms/Input'
import RadioButtons from '../../../components/forms/RadioButtons'
import Row from '../../../components/react/Row'
import { getLogin, getSettingJson } from '../../../utils/SettingUtils'
import { arrayOf, getLabel } from '../../../utils/StoreUtils'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import DtoMapSituation from './dto/DtoMapSituation'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import PropTypes from 'prop-types'
import MessageCard from '../../../components/card/MessageCard'
import { Button, Dialog, Grid } from '@mui/material'
import Table from '../../../components/datatable/Table'
import { setActions } from '../../../components/ActionUtil'
import moment from 'moment'
import { push } from '@lagunovsky/redux-react-router'
import { nbPerPageLabel } from '../../../referencial/constants/ReferencialConstants'
import ProgressBar from '../../../components/progress/ProgressBar'
import { getFullDateMini } from '../../../utils/DateUtil'
import Icon from '../../../components/icon/Icon'
import { setConfirmationModal } from '../../../utils/FormUtils'
import { searchAllCharacters } from '../../../utils/StringUtil'
import { getFrequencyStatDefaultParams, getFrequencyStatsDialog } from './paramsDialog/FrequencyStatDialog'
import StationAction from '../../actions/StationAction'
import { maxBy, orderBy, uniq } from 'lodash'
import { getIPSDefaultParams, getIPSDialog } from './paramsDialog/IPSDialog'
import {
    getThresholdIndicatorDefaultParams,
    getThresholdIndicatorDialog,
} from './paramsDialog/ThresholdIndicatorDialog'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import DtoParametrageDataType from '../../../piezometry/dto/DtoParametrageDataType'
import { execByType } from '../../../utils/StationUtils'
import Checkbox from '../../../components/forms/Checkbox'
import { getOndeDialog, getOndesDefaultParams } from './paramsDialog/OndeDialog'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import User from 'account/dto/User'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import AccountAction from '../../../account/actions/AccountAction'
import DtoAccountSettings from '../../../account/dto/DtoAccountSettings'

const STEP = {
    STATIONS: 0,
    STATS: 1,
    TREND: 2,
    CALCULATE: 3,
}

const INDICATOR = {
    IPS: 1,
    THRESHOLD: 2,
    FREQUENCY_STAT: 3,
    ONDE: 4,
}

class MapSituationAdminApp extends Component {
    constructor(props) {
        super(props)
        this.st = (window.location.href || document.URL).split('#')[1].split('/')[1]
        this.state = {
            dataLoaded: false,
            searchValue: '',
            paramsDialogIsOpen: false,
            msSelected: {},
            step: STEP.STATIONS,
            newMapDialogIsOpen: false,
            networkLinks: [],
            contributorsSelected: [],
        }
        this.hasQuit = false
        this.INDICATOR_LIST = (() => {
            switch (this.st) {
                case 'piezometry':
                    return [
                        {
                            code: INDICATOR.IPS,
                            name: i18n.piezometerSPI,
                        },
                        {
                            code: INDICATOR.THRESHOLD,
                            name: i18n.thresholdsIndicators,
                        },
                        {
                            code: INDICATOR.FREQUENCY_STAT,
                            name: i18n.frequencyStats,
                        },
                    ]
                case 'hydrometry':
                    return [
                        // {
                        //     code: INDICATOR.IPS,
                        //     name: i18n.piezometerSPI,
                        // },
                        {
                            code: INDICATOR.THRESHOLD,
                            name: i18n.thresholdsIndicators,
                        },
                        {
                            code: INDICATOR.FREQUENCY_STAT,
                            name: i18n.frequencyStats,
                        },
                        {
                            code: INDICATOR.ONDE,
                            name: 'ONDE',
                        },
                    ]
                case 'pluviometry':
                    return [
                        // {
                        //     code: INDICATOR.IPS,
                        //     name: i18n.piezometerSPI,
                        // },
                        {
                            code: INDICATOR.THRESHOLD,
                            name: i18n.thresholdsIndicators,
                        },
                        {
                            code: INDICATOR.FREQUENCY_STAT,
                            name: i18n.frequencyStats,
                        },
                        {
                            code: INDICATOR.ONDE,
                            name: 'ONDE',
                        },
                    ]
                default:
                    return []
            }
        })()
    }

    setTitle = () => {
        AppStore.dispatch(HomeAction.setTitle([{
            title: i18n[this.st],
            href: `${this.st}`,
        }, {
            title: i18n.mapSituations,
            href: `${this.st}/mapSituations`,
        }, {
            title: i18n.parameters,
            href: `${this.st}/mapSituations/settings`,
        }]))
    }

    componentWillUnmount() {
        setActions({})
        this.hasQuit = true
    }

    componentDidMount() {
        this.setTitle()
        execByType(this.st, {
            piezometry: () => {
                if (!this.props.piezometers.length) {
                    this.props.fetchPiezometers()
                }
                if (!this.props.piezometryDataTypes.length) {
                    this.props.fetchPiezometryDataTypes()
                }
                PiezometryAction.promiseNetworkPiezoLink().then(links => this.setState({ networkLinks: links }))
            },
            hydrometry: () => {
                if (!this.props.hydrometricStations.length) {
                    this.props.fetchHydrometricStations()
                }
                if (!this.props.hydrometryDataTypes.length) {
                    this.props.fetchHydrometryDataTypes()
                }
            },
            pluviometry: () => {
                if (!this.props.pluviometers.length) {
                    AppStore.dispatch(PluviometryAction.fetchPluviometryDataTypes())
                }
                if (!this.props.pluviometryDataTypes.length) {
                    this.props.fetchPluviometryDataTypes()
                }
            },
        })
        this.props.fetchMapSituations(this.st).then(() => {
            this.setState({ dataLoaded: true })
            if (this.props.mapSituations.find(ms => ms.status === 0)) {
                this.setAutoReload()
            }
        })
        setActions({
            new: () => this.setState({
                newMapDialogIsOpen: true,
                step: STEP.STATIONS,
                msSelected: {
                    id: 0,
                    indicatorType: INDICATOR.FREQUENCY_STAT,
                    calculType: 'now',
                    startPeriod: moment().valueOf(),
                    endPeriod: moment().valueOf(),
                    recurrence: 'day',
                    status: 1,
                    typeId: -1,
                },
            }),
        })
    }

    onSave = (launch = false) => {
        const ms = {
            ...this.state.msSelected,
            updateLogin: getLogin(),
        }
        this.props.updateMapSituation(this.st, ms, launch).then(() => {
            this.setState({ paramsDialogIsOpen: false })
            this.setAutoReload()
        })
    }

    setAutoReload = () => {
        if (!this.hasQuit) {
            setTimeout(() => {
                this.props.fetchMapSituations(this.st).then(() => {
                    if (this.props.mapSituations.find(ms => ms.status === 0)) {
                        this.setAutoReload()
                    }
                })
            }, 5000)
        }
    }

    getStations = () => {
        return execByType(this.st, {
            piezometry: () => this.props.piezometers,
            hydrometry: () => this.props.hydrometricStations,
            pluviometry: () => this.props.pluviometers,
        })
    }

    getParamsDialog = () => {
        const { contributors } = this.props
        const { msSelected, paramsDialogIsOpen } = this.state
        if (!paramsDialogIsOpen) {
            return null
        }
        switch (msSelected.indicatorType) {
            case INDICATOR.IPS:
                return getIPSDialog(this.state, changes => this.setState(changes), this.onSave, this.getStations(), this.getDataTypes(), this.st, contributors)
            case INDICATOR.THRESHOLD:
                return getThresholdIndicatorDialog(this.state, changes => this.setState(changes), this.onSave, this.getStations(), this.getDataTypes(), this.st, contributors)
            case INDICATOR.ONDE:
                return getOndeDialog(this.state, changes => this.setState(changes), this.onSave, this.getStations(), this.getDataTypes(), this.st, contributors)
            default:
                return getFrequencyStatsDialog(this.state, changes => this.setState(changes), this.onSave, this.getStations(), this.getDataTypes(), this.st, contributors)
        }
    }

    getDefaultParams = () => {
        switch (this.state.msSelected.indicatorType) {
            case INDICATOR.IPS:
                return getIPSDefaultParams()
            case INDICATOR.THRESHOLD:
                return getThresholdIndicatorDefaultParams()
            case INDICATOR.ONDE:
                return getOndesDefaultParams()
            default:
                return getFrequencyStatDefaultParams()
        }
    }

    getDataTypes = () => execByType(this.st, {
        piezometry: () => this.props.piezometryDataTypes,
        hydrometry: () => this.props.hydrometryDataTypes,
        pluviometry: () => this.props.pluviometryDataTypes,
        // installation: () => this.props.installationDataTypes,
        default: () => [],
    })

    onSaveNew = () => {
        this.setState({ newMapDialogIsOpen: false })
        const msSelected = { ...this.state.msSelected, params: JSON.stringify(this.getDefaultParams()) }
        this.props.createMapSituation(this.st, msSelected).then(() => {
            this.props.fetchMapSituations(this.st).then(() => {
                const newMap = maxBy(this.props.mapSituations, 'updateDate')
                this.setState({ msSelected: newMap, paramsDialogIsOpen: true })
            })
        })
    }

    getNewMapDialog = () => {
        const { newMapDialogIsOpen, msSelected } = this.state
        if (!newMapDialogIsOpen) {
            return null
        }
        const change = changes => this.setState({ msSelected: { ...msSelected, ...changes } })
        return (
            <Dialog
                onClose={() => this.setState({ newMapDialogIsOpen: false })}
                fullWidth
                maxWidth='lg'
                open={newMapDialogIsOpen}
                classes='no-padding'
                scroll='body'
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.mapSituationNew}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => this.setState({ newMapDialogIsOpen: false })} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <Row className='padding-top-1'>
                        <Input col={ 6 } title={ i18n.name } onChange={ v => change({ name: v }) } value={ msSelected.name }/>
                        <RadioButtons
                            col={ 6 }
                            activeOneBelowOther={true}
                            elements={this.INDICATOR_LIST}
                            title={i18n.mapType}
                            selected={msSelected.indicatorType}
                            onChange={ (v) => change({ indicatorType: v }) }
                        />
                    </Row>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={this.onSaveNew} variant='contained' color='primary'>
                        {i18n.save}
                    </Button>
                </DialogActionsMUI>
            </Dialog>
        )
    }

    changeDefault = (ms) => {
        const previous = this.props.mapSituations.find(m => m.default)
        if (previous) {
            this.props.updateMapSituation(this.st, { ...previous, default: false }, false).then(() => {
                this.props.updateMapSituation(this.st, { ...ms, default: true }, false).then(() => {
                    this.props.fetchMapSituations(this.st)
                })
            })
        } else {
            this.props.updateMapSituation(this.st, { ...ms, default: true }, false).then(() => {
                this.props.fetchMapSituations(this.st)
            })
        }
    }

    changeWidgetOnDashboard = (ms) => {
        const obj = getSettingJson(this.props.accountUserSettings, 'MAP_SITUATION_DASHBOARD_WIDGET') ?? {}
        const key = `${this.st}-${ms.id}`
        const newObj = {
            ...obj,
            [key]: !obj[key],
        }
        AppStore.dispatch(AccountAction.updateOrCreateSetting(getLogin(), 'MAP_SITUATION_DASHBOARD_WIDGET', JSON.stringify(newObj)))
    }

    render() {
        const { contributors, accountUser, mapSituations, accountUserSettings } = this.props
        const { contributorsSelected, dataLoaded, searchValue } = this.state
        this.st = (window.location.href || document.URL).split('#')[1].split('/')[1]
        if (!dataLoaded) {
            return <MessageCard>{i18n.loading}</MessageCard>
        }

        const dashboardWidgetObj = getSettingJson(accountUserSettings, 'MAP_SITUATION_DASHBOARD_WIDGET') ?? {}
        const contributorsSituations = uniq(mapSituations.map(m => m.contributorCode).filter(c => c))
        const contributorsFiltered = contributors.filter(c => contributorsSituations.includes(c.code))
        const mapSituationsByContributors = contributorsSelected?.length ? mapSituations.filter(m => contributorsSelected.includes(m.contributorCode)) : mapSituations
        const data = orderBy(mapSituationsByContributors, 'id').filter(m => (!m.contributorCode || accountUser.isAdmin === '1' || (m.contributorCode === accountUser.contributorCode)) && searchAllCharacters(m.name).includes(searchAllCharacters(searchValue))).map(ms => {
            const dashboardWidgetKey = `${this.st}-${ms.id}`
            const params = JSON.parse(ms.params)
            return {
                nullValue: {
                    leftIcon: 'clear',
                    leftIconColor: 'black',
                    leftIconClick: () => setConfirmationModal(() => this.props.deleteMapSituation(this.st, ms).then(() => this.props.fetchMapSituations(this.st))),
                    value: (
                        <Icon
                            icon='edit'
                            style={{ color: 'black' }}
                            onClick={() => this.setState({
                                paramsDialogIsOpen: true,
                                msSelected: ms,
                                step: STEP.STATIONS,
                            })}
                        />
                    ),
                    rightIcon: 'play_circle_outline',
                    rightIconColor: 'black',
                    rightIconClick: () => this.props.launchMapSituation(this.st, ms.id).then(this.setAutoReload),
                },
                name: { value: ms.name },
                nbStats: { value: params.stats?.length || 0 },
                nbStations: { value: params.stationIds.length },
                nbValues: { value: ms.nbValues },
                date: {
                    value: ms.status === 0 ?
                        <ProgressBar progress={ms.progress} withMessage/> : getFullDateMini(ms.date),
                },
                type: { value: getLabel(this.INDICATOR_LIST, ms.indicatorType) },
                editBy: { value: ms.updateLogin },
                update: { value: getFullDateMini(ms.updateDate) },
                nullValue2: {
                // value: hasValue(ms.nbValues) && ms.nbValues > 0 ? <Icon icon='remove_red_eye' color='black' onClick={ () => this.props.push(`/piezometry/mapSituations/${ms.id}`) }/> : null
                    value: null,
                },
                displayByDefault: { value: <Checkbox checked={ms.default} onChange={ v => v ? this.changeDefault(ms) : null }/> },
                administrator: {
                    value: contributors.find(c => c.code === ms.contributorCode)?.name || '',
                },
                widgetOnDashboard: { value: <Checkbox checked={dashboardWidgetObj[dashboardWidgetKey]} onChange={ () => this.changeWidgetOnDashboard(ms) }/> },
            }
        })
        return (
            <div className='margin-right-1 margin-top-1'>
                <Card className='padding-1'>
                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <Input
                                title={i18n.search}
                                value={searchValue}
                                onChange={v => this.setState({ searchValue: v })}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <MultiContributorsAutocomplete
                                label={i18n.administrator}
                                options={contributorsFiltered}
                                multiple
                                onChange={v => this.setState({ contributorsSelected: v })}
                            />
                        </Grid>
                    </Grid>
                </Card>
                <Table
                    data={data}
                    nbPerPageLabel={nbPerPageLabel}
                    color
                    type={{ headers: ['nullValue', 'type', 'name', 'nbStats', 'nbStations', 'date', 'nbValues', 'editBy', 'update', 'displayByDefault', 'widgetOnDashboard', 'administrator', 'nullValue2'] }}
                    sortable
                    condensed
                    paging
                />
                { this.getParamsDialog() }
                { this.getNewMapDialog() }
            </div>
        )
    }
}

MapSituationAdminApp.propTypes = {
    mapSituations: arrayOf(DtoMapSituation),
    fetchMapSituations: PropTypes.func,
    deleteMapSituation: PropTypes.func,
    launchMapSituation: PropTypes.func,
    push: PropTypes.func,
    piezometers: arrayOf(DtoPiezometerLight),
    hydrometricStations: arrayOf(DtoHydrometricStation),
    pluviometers: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    updateMapSituation: PropTypes.func,
    createMapSituation: PropTypes.func,
    fetchPiezometers: PropTypes.func,
    fetchHydrometricStations: PropTypes.func,
    fetchPluviometers: PropTypes.func,
    piezometryDataTypes: arrayOf(DtoParametrageDataType),
    hydrometryDataTypes: arrayOf(DtoParametrageDataType),
    pluviometryDataTypes: arrayOf(DtoParametrageDataType),
    fetchPiezometryDataTypes: PropTypes.func,
    fetchHydrometryDataTypes: PropTypes.func,
    fetchPluviometryDataTypes: PropTypes.func,
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorItem)),
    accountUser: PropTypes.instanceOf(User),
    accountUserSettings: PropTypes.arrayOf(PropTypes.instanceOf(DtoAccountSettings)),
}

const mapStateToProps = store => ({
    mapSituations: store.StationReducer.mapSituations,
    piezometers: store.PiezometryReducer.piezometersLight,
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    pluviometers: store.PluviometryReducer.pluviometers,
    piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
    hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    contributors: store.ContributorReducer.contributors,
    accountUser: store.AccountReducer.accountUser,
    accountUserSettings: store.AccountReducer.accountUserSettings,
})

const mapDispatchToProps = {
    fetchMapSituations: StationAction.fetchMapSituations,
    deleteMapSituation: StationAction.deleteMapSituation,
    launchMapSituation: StationAction.launchMapSituation,
    createMapSituation: StationAction.createMapSituation,
    updateMapSituation: StationAction.updateMapSituation,
    fetchPiezometers: PiezometryAction.fetchPiezometersLight,
    fetchHydrometricStations: HydrometryAction.fetchHydrometricStations,
    fetchPluviometers: PluviometryAction.fetchPluviometers(),
    push,
    fetchPiezometryDataTypes: PiezometryAction.fetchPiezometryDataTypes,
    fetchHydrometryDataTypes: HydrometryAction.fetchHydrometryDataTypes,
    fetchPluviometryDataTypes: PluviometryAction.fetchPluviometryDataTypes,

}

export default connect(mapStateToProps, mapDispatchToProps)(MapSituationAdminApp)
