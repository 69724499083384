import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Select from '../../../components/forms/Select'
import Checkbox from '../../../components/forms/Checkbox'
import DtoIndustrialSite from './dto/DtoIndustrialSite'
import { getSandreList } from '../../../utils/StoreUtils'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import NumberField from '../../../components/forms/NumberField'
import { shallowEqual, useSelector } from 'react-redux'
import { Grid2 } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

const IndustrialSiteActivityPanel = ({
    industrialSite,
    readMode,
    onChangeIndustrialSite,
}) => {
    const {
        sandreCodes,
        activities,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        activities: store.InstallationReducer.activities,
    }), shallowEqual)

    return (
        <>
            <Grid2 size={6}>
                <Select
                    label={i18n.activityStatus}
                    options={getSandreList(sandreCodes, SANDRE.INDUSTRIAL_SITE_STATUS)}
                    value={industrialSite.activityStatus ? industrialSite.activityStatus : 0}
                    onChange={(element) => onChangeIndustrialSite({ activityStatus: element })}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    label={i18n.icpe}
                    checked={industrialSite.icpe}
                    onChange={(element) => onChangeIndustrialSite({ icpe: element })}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    label={i18n.effectiveTranche}
                    options={getSandreList(sandreCodes, SANDRE.SITE_INDUS_EFFECTIF)}
                    value={industrialSite.effectiveTranche ? industrialSite.effectiveTranche : -1}
                    onChange={(element) => onChangeIndustrialSite({ effectiveTranche: element })}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    title={i18n.effective}
                    value={industrialSite.staff}
                    onChange={(element) => onChangeIndustrialSite({ staff: element })}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    label={i18n.categoryActivity}
                    options={getSandreList(sandreCodes, SANDRE.ACTIVITES_CATEGORIE)}
                    value={industrialSite.categoryActivity ? industrialSite.categoryActivity : 0}
                    onChange={(element) => onChangeIndustrialSite({ categoryActivity: element })}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    label={i18n.mainActivity}
                    options={activities}
                    value={industrialSite.activityCode}
                    onChange={(element) => onChangeIndustrialSite({ activityCode: element })}
                    displayWithCode
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    label={i18n.activity2}
                    options={activities}
                    value={industrialSite.activityCode2}
                    onChange={(element) => onChangeIndustrialSite({ activityCode2: element })}
                    displayWithCode
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    label={i18n.activity3}
                    options={activities}
                    value={industrialSite.activityCode3}
                    onChange={(element) => onChangeIndustrialSite({ activityCode3: element })}
                    displayWithCode
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    label={i18n.activity4}
                    options={activities}
                    value={industrialSite.activityCode4}
                    onChange={(element) => onChangeIndustrialSite({ activityCode4: element })}
                    displayWithCode
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    label={i18n.redevelopedSite}
                    checked={industrialSite.redevelopedSite}
                    onChange={(element) => onChangeIndustrialSite({ redevelopedSite: element })}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    label={i18n.refurbishedSite}
                    checked={industrialSite.refurbishedSite}
                    onChange={(element) => onChangeIndustrialSite({ refurbishedSite: element })}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    label={i18n.dischargeAgreement}
                    checked={industrialSite.dischargeAgreement}
                    onChange={(element) => onChangeIndustrialSite({ dischargeAgreement: element })}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <SimpleDatePicker
                    value={industrialSite.agreementStartDate}
                    style={{ padding: 0 }}
                    id='endDate'
                    label={i18n.startDate}
                    onChange={v => onChangeIndustrialSite({ agreementStartDate: v })}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <SimpleDatePicker
                    style={{ padding: 0 }}
                    value={industrialSite.agreementEndDate}
                    id='endDate'
                    label={i18n.endDate}
                    onChange={v => onChangeIndustrialSite({ agreementEndDate: v })}
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}


IndustrialSiteActivityPanel.propTypes = {
    industrialSite: PropTypes.instanceOf(DtoIndustrialSite),
    readMode: PropTypes.bool,
    onChangeInstallationIndusSite: PropTypes.func,
    onChangeIndustrialSite: PropTypes.func,
}

export default IndustrialSiteActivityPanel
