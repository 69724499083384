export default class MortgageDto {
    constructor(obj) {
        this.mortgageId = obj.mortgageId // Option[Int],
        this.orderId = obj.orderId // Option[Int],
        this.deedDate = obj.deedDate // Option[DateTime],
        this.comment = obj.comment // Option[String],
        this.publicationDate = obj.publicationDate // Option[DateTime],
        this.amount = obj.amount // Option[String],
        this.publicationNumber = obj.publicationNumber // Option[String],
        this.nextMortgageId = obj.nextMortgageId // Option[Int],
        this.reworkId = obj.reworkId // Option[String],
        this.ownersNumber = obj.ownersNumber // Option[Int]
    }
}