import { orderBy, template } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import MessageCard from '../../../../components/card/MessageCard'
import DtoJob from '../../../../import/dto/DtoJob'
import QualityExecutionDto from '../../../../import/dto/QualityExecutionDto'
import { getFullDateMini, getHour } from '../../../../utils/DateUtil'
import { NewTable } from 'components/datatable/NewTable'
import { Card, CardContent, Grid2 } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'
import PaginatedList from 'components/list/PaginatedList'

class JobTab extends Component {
    jobExecutionCard = exec => {
        const job = this.props.qualityJobs.find(({ id }) => id === exec.jobId) || {}
        const formatExec = {
            status: exec.status ? i18n.success : i18n.error,
            begin: getHour(exec.date),
            end: getHour(exec.date + exec.duration),
            stations: `${exec.stations}`,
            operations: `${exec.operations}`,
            analysis: `${exec.analysis}`,
            parameter: `${exec.parameters}`,
            errors: `${exec.errors.length}`,
            warnings: `${exec.warnings.length}`,
            comment: exec.comment || '',
        }
        return (
            <Card>
                <CardTitle title={`${job.name ? `${job.name}: ` : ''}${template(i18n.executionOf)({ date: getFullDateMini(exec.date) })} [${exec.id}]` } className={'padding-bottom-1'} />
                <CardContent>
                    <NewTable
                        rows={[formatExec]}
                        headers={['status', 'begin', 'end', 'stations', 'operations', 'analysis', 'parameter', 'errors', 'warnings', 'comment']}
                    />
                    {/* {*/}
                    {/*    !!exec.files.length && (*/}
                    {/*        <div>*/}
                    {/*            <div style={{ backgroundColor: '#B6E1FE', paddingLeft: '14px' }}>*/}
                    {/*                {i18n.files}*/}
                    {/*            </div>*/}
                    {/*            <Table*/}
                    {/*                data={exec.files}*/}
                    {/*                type={{ headers: ['value', 'comment'] }}*/}
                    {/*                condensed*/}
                    {/*                showTitle={false}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/* }*/}
                    {!!exec.errors.length && (
                        <div>
                            <div style={{ backgroundColor: 'red', paddingLeft: '14px', color: 'white' }}>
                                {i18n.errors}
                            </div>
                            <NewTable
                                rows={exec.errors}
                                headers={['value', 'comment']}
                            />
                        </div>
                    )}
                    {!!exec.warnings.length && (
                        <div>
                            <div style={{ backgroundColor: 'orange', paddingLeft: '14px' }}>
                                {i18n.warnings}
                            </div>
                            <NewTable
                                rows={exec.warnings}
                                headers={['value', 'comment']}
                            />
                        </div>
                    )}
                </CardContent>
            </Card>
        )
    }

    render = () => {
        const {
            jobId,
            // jobStartDate,
            // jobEndDate,
            jobExecutionId,
        } = this.props.filter
        if (jobId || jobExecutionId) {
            if (this.props.qualityExecutions.length) {
                const sortExecution = orderBy(this.props.qualityExecutions, 'date', 'desc')
                return (
                    <Card>
                        <CardContent>
                            <PaginatedList
                                nbElements={sortExecution.length}
                                nbElementsByPage={10}
                            >
                                {
                                    (startId, endId) => {
                                        const subList = sortExecution.slice(startId, endId)
                                        return (
                                            <Grid2 container spacing={1}>
                                                {subList.map(exec => (
                                                    <Grid2 key={exec.id} size={12}>
                                                        {this.jobExecutionCard(exec)}
                                                    </Grid2>
                                                ))}
                                            </Grid2>
                                        )
                                    }
                                }
                            </PaginatedList>
                        </CardContent>
                    </Card>
                )
            }
            return <MessageCard>{i18n.noJobAvailableForTheseCriteria}</MessageCard>
        }
        return <MessageCard>{i18n.pleaseSelectJobAndDate}</MessageCard>
    }
}

JobTab.propTypes = {
    filter: PropTypes.shape({
        job: PropTypes.string,
        jobId: PropTypes.number,
        jobStartDate: PropTypes.number,
        jobEndDate: PropTypes.number,
        jobExecutionId: PropTypes.number,
    }),
    qualityJobs: PropTypes.arrayOf(PropTypes.instanceOf(DtoJob)),
    qualityExecutions: PropTypes.arrayOf(PropTypes.instanceOf(QualityExecutionDto)),
}

const mapStateToProps = store => ({
    qualityJobs: store.JobReducer.qualityJobs,
    qualityExecutions: store.JobReducer.qualityExecutions,
})

export default connect(mapStateToProps)(JobTab)