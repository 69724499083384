import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { arrayOf, getSandreList } from '../../../../utils/StoreUtils'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import DtoInstallationBorehole from '../../../dto/borehole/DtoInstallationBorehole'
import { Grid2 } from '@mui/material'

const BoreholeEquipmentsPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    borehole = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const onChangeBoreholeEquipments = (value) => {
        onChange({
            link_equipments: [
                {
                    idStation: borehole.id,
                    ...borehole.link_equipments[0],
                    ...value,
                },
            ],
        })
    }

    const equipments = borehole.link_equipments[0] || {}

    return (
        <>
            <Grid2 size={6}>
                <NumberField
                    value={equipments.numberPumps}
                    title={i18n.numberPumps}
                    onChange={(v) => {
                        onChangeBoreholeEquipments({ numberPumps: v })
                        onChangeVisit({ previousValue: equipments.numberPumps, newValue: v, field: i18n.numberPumps })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={equipments.nominalFlow}
                    title={i18n.nominalFlow}
                    onChange={(v) => {
                        onChangeBoreholeEquipments({ nominalFlow: v })
                        onChangeVisit({ previousValue: equipments.nominalFlow, newValue: v, field: i18n.nominalFlow })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={equipments.hmt}
                    title={i18n.hmt}
                    onChange={(v) => {
                        onChangeBoreholeEquipments({ hmt: v })
                        onChangeVisit({ previousValue: equipments.hmt, newValue: v, field: i18n.hmt })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={equipments.pumpType}
                    label={i18n.pumpType}
                    keyValue='code'
                    options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEPOMPE)}
                    onChange={(v) => {
                        onChangeBoreholeEquipments({ pumpType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEPOMPE, i18n.pumpType, equipments.pumpType, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={equipments.depthCrepine}
                    title={i18n.depthCrepine}
                    onChange={(v) => {
                        onChangeBoreholeEquipments({ depthCrepine: v })
                        onChangeVisit({ previousValue: equipments.depthCrepine, newValue: v, field: i18n.depthCrepine })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={equipments.dewateringColDiamExt}
                    title={i18n.dewateringColDiamExt}
                    onChange={(v) => {
                        onChangeBoreholeEquipments({ dewateringColDiamExt: v })
                        onChangeVisit({ previousValue: equipments.dewateringColDiamExt, newValue: v, field: i18n.dewateringColDiamExt })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={equipments.dewateringColNature}
                    label={i18n.dewateringColNature}
                    keyValue='code'
                    options={getSandreList(sandreCodes, SANDRE.COLONNEEXHAURE_MATERIAU)}
                    onChange={(v) => {
                        onChangeBoreholeEquipments({ dewateringColNature: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.COLONNEEXHAURE_MATERIAU, i18n.dewateringColNature, equipments.dewateringColNature, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={equipments.dewateringColType}
                    label={i18n.dewateringColType}
                    keyValue='code'
                    options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPECOLONNEEXHAURE)}
                    onChange={(v) => {
                        onChangeBoreholeEquipments({ dewateringColType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPECOLONNEEXHAURE, i18n.dewateringColType, equipments.dewateringColType, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={equipments.typeDetectionLevel}
                    label={i18n.typeDetectionLevel}
                    keyValue='code'
                    options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TYPEDETECTION)}
                    onChange={(v) => {
                        onChangeBoreholeEquipments({ typeDetectionLevel: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPEDETECTION, i18n.typeDetectionLevel, equipments.typeDetectionLevel, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={equipments.depthProbe}
                    title={i18n.depthProbe}
                    onChange={(v) => {
                        onChangeBoreholeEquipments({ depthProbe: v })
                        onChangeVisit({ previousValue: equipments.depthProbe, newValue: v, field: `${i18n.equipments} - ${i18n.depthProbe}` })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

BoreholeEquipmentsPanel.propTypes = {
    borehole: PropTypes.instanceOf(DtoInstallationBorehole),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
    setPopup: PropTypes.func,
}

export default BoreholeEquipmentsPanel
