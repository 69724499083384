import 'echarts/lib/chart/bar'
import { filter, isEqual, orderBy, reduce, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getDate, getFullDateMini } from '../../../../../../utils/DateUtil'
import DtoUser from '../../../dto/DtoUser'
import DtoUserStatistic from '../../../dto/DtoUserStatistic'
import DtoUserStatisticItem from '../../../dto/DtoUserStatisticItem'
import ExportAction from '../../../../../../export/actions/ExportAction'
import { formatData } from '../../../../../../utils/ExportDataUtil'
import { nbPerPageLabelMedium } from '../../../../../../referencial/constants/ReferencialConstants'
import { greyBlue } from '../../../../../../utils/constants/ColorTheme'
import { CardTable } from '../../../../../../components/datatable/NewTable'

class StatisticsPanel extends Component {
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.userStatistics, this.props.userStatistics)
            || !isEqual(nextProps.user, this.props.user)
    }

    getReduceStatistics = (statistics) => {
        return reduce(statistics, (a, b) => {
            if (!a[`${getDate(b.statisticDate)}_${b.value}`]) {
                a[`${getDate(b.statisticDate)}_${b.value}`] = {
                    module: b.module,
                    value: b.value,
                    datas: [],
                }
            }
            a[`${getDate(b.statisticDate)}_${b.value}`].datas.push(b)
            return a
        }, {})
    }

    getDatas = (statistics) => {
        const reduceValues = this.getReduceStatistics(statistics)
        return orderBy(Object.keys(reduceValues).map((o) => {
            const statisticObject = reduceValues[o]
            const listOfStatistics = statisticObject.datas
            const lastVisit = orderBy(listOfStatistics, 'statisticDate', 'desc')[0]
            return new DtoUserStatisticItem({
                login: statisticObject.login,
                module: statisticObject.module,
                value: statisticObject.value,
                lastAccess: getFullDateMini(lastVisit.statisticDate),
                statisticDate: lastVisit.statisticDate,
                nbAccess: listOfStatistics.length,
            })
        }), 'statisticDate', 'desc')
    }

    render() {
        const login = this.props.user.login || i18n.users
        const statistics = filter(this.props.userStatistics, o => o.value)
        if (statistics.length) {
            const data = take(this.getDatas(statistics), 5)
            return (
                <CardTable
                    actions={[{
                        icon: 'download',
                        onClick: () => this.props.export(formatData(data), 'xlsx', `${i18n.userStatisticsExport + login}`),
                        color: 'white',
                    }]}
                    title={ `5 ${i18n.userStatistics}` }
                    headers={['lastAccess', 'module', 'value', 'nbAccess']}
                    rowsPerPageOptions={nbPerPageLabelMedium}
                    rows={ data }
                    data-cy={'user_statistics_table'}
                    color={greyBlue}
                    hideNbElements
                    displayHeaders={false}
                />
            )
        }
        return null
    }
}

StatisticsPanel.propTypes = {
    user: PropTypes.instanceOf(DtoUser),
    userStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
    export: PropTypes.func,
}
const mapStateToProps = (store) => {
    return {
        user: store.UserReducer.user,
    }
}
const mapDispatchToProps = {
    export: ExportAction.export,
}
export default connect(mapStateToProps, mapDispatchToProps)(StatisticsPanel)
