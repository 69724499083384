import { groupBy, maxBy, orderBy, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import CMSCategoryDto from '../../../../../../events/dto/CMSCategoryDto'
import { getDate } from '../../../../../../utils/DateUtil'
import DtoUser from '../../../dto/DtoUser'
import DtoUserCmsStatistic from '../../../dto/DtoUserCmsStatistic'
import DtoUserCmsStatisticItem from '../../../dto/DtoUserCmsStatisticItem'
import ExportAction from '../../../../../../export/actions/ExportAction'
import { formatData } from '../../../../../../utils/ExportDataUtil'
import { nbPerPageLabelMedium } from '../../../../../../referencial/constants/ReferencialConstants'
import { CardTable } from '../../../../../../components/datatable/NewTable'
import { greyBlue } from '../../../../../../utils/constants/ColorTheme'

class CmsStatisticsPanel extends Component {
    render() {
        const login = this.props.user.login || i18n.users
        if (this.props.userCmsStatistics.length) {
            const group = groupBy(this.props.userCmsStatistics, 'value')
            const visits = take(orderBy(Object.keys(group), key => group[key].length, ['desc']), 10).map(key => {
                const allCms = group[key]
                const lastOne = maxBy(allCms, 'statisticDate')
                return new DtoUserCmsStatisticItem({
                    category: this.props.cmsCategories.find(c => c.id == lastOne.module)?.title,
                    title: lastOne.value,
                    visitsCount: allCms.length,
                    lastAccess: getDate(lastOne.statisticDate),
                })
            })
            const data = take(visits, 10)
            return (
                <CardTable
                    actions={[{
                        icon: 'download',
                        onClick: () => this.props.export(formatData(data), 'xlsx', `${i18n.userCmsStatisticsExport + login}`),
                        color: 'white',
                    }]}
                    title={ `10 ${i18n.userCmsStatistics}` }
                    headers={ ['category', 'title', 'visitsCount', 'lastAccess'] }
                    rowsPerPageOptions={nbPerPageLabelMedium}
                    rows={data}
                    data-cy={'user_cms_statistics_table'}
                    color={greyBlue}
                    hideNbElements
                />
            )
        }
        return null
    }
}

CmsStatisticsPanel.propTypes = {
    user: PropTypes.instanceOf(DtoUser),
    userCmsStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserCmsStatistic)),
    cmsCategories: PropTypes.arrayOf(PropTypes.instanceOf(CMSCategoryDto)),
    export: PropTypes.func,
}
const mapStateToProps = (store) => {
    return {
        user: store.UserReducer.user,
        cmsCategories: store.EventsReducer.cmsCategories,
    }
}
const mapDispatchToProps = {
    export: ExportAction.export,
}
export default connect(mapStateToProps, mapDispatchToProps)(CmsStatisticsPanel)
