import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { push } from '@lagunovsky/redux-react-router'
import { template } from 'lodash'
import moment from 'moment'
import DtoProductionUnitLink from 'productionUnit/dto/DroProductionUnitLink'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import useActions from 'utils/customHook/useActions'
import Checkbox from '../../components/forms/Checkbox'
import Input from '../../components/forms/Input'
import NumberField from '../../components/forms/NumberField'
import Select from '../../components/forms/Select'
import QualityAction from '../../quality/actions/QualityAction'
import ReferencialAction from '../../referencial/action/ReferencialAction'
import { SANDRE } from '../../referencial/constants/ReferencialConstants'
import StationMapDashboardPanel from '../../station/components/dashboard/component/map/StationMapDashboardPanel'
import StationUpdatePanel from '../../station/components/update/StationUpdatePanel'
import { getStationArrowNav } from '../../utils/ActionUtils'
import { getLogin } from '../../utils/SettingUtils'
import { getLinks } from '../../utils/StationUtils'
import ProductionUnitAction from '../actions/ProductionUnitAction'
import ProductionUnitLinks from './ProductionUnitLinks'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { CardContent, Grid2, Card } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'
import useSandreList from 'utils/customHook/useSandreList'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import StationContributorPanel from 'station/components/link/StationContributorPanel'

const initialState = {
    addedLinks: [],
    deletedLinks: [],
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return {
                addedLinks: [...state.addedLinks, ...action.links],
                deletedLinks: state.deletedLinks.filter(dl => !action.links.some(lk => dl.siteType === lk.siteType && dl.siteCode === lk.siteCode && dl.linkType === lk.linkType)),
            }
        case 'delete':
            return {
                addedLinks: state.addedLinks.filter(dl => !action.links.some(lk => dl.siteType === lk.siteType && dl.siteCode === lk.siteCode && dl.linkType === lk.linkType)),
                deletedLinks: [...state.deletedLinks, ...action.links],
            }
        case 'reset':
            return {
                addedLinks: [],
                deletedLinks: [],
            }
        default:
            throw new Error()
    }
}

const ProductionUnitDescriptionPanel = ({
    id = -1,
    stationTypes,
}) => {
    const dispatch = useDispatch()

    const [readMode, setReadMode] = useState(true)
    const [stateProdUnit, setStateProdUnit] = useState({})
    const [stateLinks, dispatchStateLinks] = useReducer(reducer, initialState)

    const {
        productionUnit,
        productionUnits,
        cities,
        watermasses,
        sandreCodes,
        users,
        status,
        sectors,
    } = useSelector(store => ({
        productionUnit: store.ProductionUnitReducer.productionUnit,
        productionUnits: store.ProductionUnitReducer.productionUnits,
        cities: store.CityReducer.cities,
        watermasses: store.WatermassReducer.watermasses,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        users: store.UserReducer.users,
        status: store.QualityReducer.status,
        sectors: store.SectorReducer.sectors,
    }), shallowEqual)

    useEffect(() => {
        if (!sandreCodes.length) {
            dispatch(ReferencialAction.fetchSandreCodes())
        }
        if (!status.length) {
            dispatch(QualityAction.fetchStatus())
        }
    }, [sandreCodes])

    useEffect(() => {
        if (!productionUnit.id) {
            dispatch(ProductionUnitAction.fetchProductionUnit(id))
        } else {
            dispatch(ProductionUnitAction.fetchProductionUnitLinks(productionUnit.id))
            setStateProdUnit(productionUnit)
        }
    }, [productionUnit])

    const onSave = () => {
        if (!stateProdUnit.code) {
            dispatch(ToastrAction.error(template(i18n.fieldNotSet)({ field: i18n.code })))
        } else {
            dispatch(ProductionUnitAction.updateProductionUnit(stateProdUnit))
            if (stateLinks.deletedLinks.length || stateLinks.addedLinks.length) {
                const links = {
                    addedLinks: stateLinks.addedLinks.map(ad => new DtoProductionUnitLink(ad)),
                    deletedLinks: stateLinks.deletedLinks.map(ad => new DtoProductionUnitLink(ad)),
                }
                dispatch(ProductionUnitAction.updateProductionUnitLinks(stateProdUnit.id, links)).then(() => {
                    dispatchStateLinks({ type: 'reset' })
                })
            }
            setReadMode(true)
        }
    }

    useActions(() => {
        return readMode ?
            {
                edit: () => setReadMode(false),
                delete: () => dispatch(ProductionUnitAction.deleteProductionUnit(productionUnit.id, () => dispatch(push('/productionUnit')))),
                links: getLinks(productionUnit, { stationTypes, watermasses }),
                arrowNav: getStationArrowNav('productionUnit', productionUnits, productionUnit.id, s => dispatch(push(`/station/productionUnit/${s.id}/description`))),
            } : {
                cancel: () => {
                    dispatchStateLinks({ type: 'reset' })
                    setStateProdUnit(productionUnit)
                    setReadMode(true)
                },
                save: onSave,
                delete: () => dispatch(ProductionUnitAction.deleteProductionUnit(productionUnit.id, () => dispatch(push('/productionUnit')))),
                links: getLinks(productionUnit, { stationTypes, watermasses }),
                arrowNav: getStationArrowNav('productionUnit', productionUnits, productionUnit.id, s => dispatch(push(`/station/productionUnit/${s.id}/description`))),
            }
    }, [readMode, productionUnit, productionUnits, stateLinks, stateProdUnit])

    const onChangeProductionUnit = value => setStateProdUnit({ ...stateProdUnit, ...value })

    const generalList = useSandreList(SANDRE.USAGES_GENERAL)
    const stateList = useSandreList(SANDRE.CODE_ETAT)
    const projectionList = useSandreList(SANDRE.PROJECTION)

    const readModeObj = { readMode, editMode: !readMode }
    if (stateProdUnit.id) {
        return (
            <Grid2 container spacing={1} sx={{ marginBottom: '100px', padding: '10 10 0 20' }} alignItems='flex-start'>
                <Grid2 container size={9} columnSpacing={1}>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.description} />
                            <CardContent>
                                <Grid2 container columnSpacing={1}>
                                    <Grid2 size={6}>
                                        <Input
                                            value={stateProdUnit.code}
                                            title={i18n.code}
                                            disabled {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ code: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            value={stateProdUnit.name}
                                            title={i18n.name}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ name: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            value={stateProdUnit.directUsage}
                                            options={generalList}
                                            label={i18n.directUsage}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ directUsage: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            value={stateProdUnit.mnemonic}
                                            title={i18n.mnemonic}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ mnemonic: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            id='beginDate'
                                            style={{ padding: 0 }}
                                            label={i18n.beginDate}
                                            value={stateProdUnit.startDate}
                                            onChange={v => onChangeProductionUnit({ startDate: v })}
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            id='endDate'
                                            style={{ padding: 0 }}
                                            label={i18n.endDate}
                                            value={stateProdUnit.endDate}
                                            onChange={v => onChangeProductionUnit({ endDate: v })}
                                            {...readModeObj}
                                            min={stateProdUnit.startDate}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.controlFrequency}
                                            title={i18n.controledFrequency}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ controlFrequency: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.realisedFrequency}
                                            title={i18n.doneFrequency}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ realisedFrequency: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.decretFrequency}
                                            title={i18n.decreeFrequency}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ decretFrequency: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            id='datePrefectoralDecree'
                                            style={{ padding: 0 }}
                                            label={i18n.datePrefectoralDecree}
                                            value={stateProdUnit.prefectoralArrestDate}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ prefectoralArrestDate: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.arrestDuration}
                                            title={i18n.durationPrefectoralDecree}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ arrestDuration: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.exploitationCode}
                                            title={i18n.exploitation}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ exploitationCode: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            value={stateProdUnit.statusCode}
                                            options={stateList}
                                            label={i18n.state}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ statusCode: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            value={stateProdUnit.sector}
                                            options={sectors}
                                            label={i18n.sector}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ sector: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleTextArea
                                            value={stateProdUnit.description}
                                            title={i18n.description}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ description: v })}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <ProductionUnitLinks
                        readMode={readMode}
                        stateLinks={stateLinks}
                        dispatchStateLinks={dispatchStateLinks}
                    />
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.characteristics} />
                            <CardContent>
                                <Grid2 container columnSpacing={1}>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.authorizedFlow}
                                            title={i18n.authorizedFlow}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ authorizedFlow: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.annualWaterAmountExploited}
                                            title={i18n.annualWaterVolumeExploited}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ annualWaterAmountExploited: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.volumeAllowDay}
                                            title={i18n.volumeAllowDay}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ volumeAllowDay: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.nbStationInService}
                                            title={i18n.numberActiveStation}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ nbStationInService: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.builtArea}
                                            title={i18n.builtArea}
                                            {...readModeObj}
                                            floatValue
                                            onChange={v => onChangeProductionUnit({ builtArea: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            value={stateProdUnit.nonBuiltArea}
                                            title={i18n.nonBuiltArea}
                                            {...readModeObj}
                                            floatValue
                                            onChange={v => onChangeProductionUnit({ nonBuiltArea: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            id='renovationDate'
                                            label={i18n.renovationDate}
                                            value={stateProdUnit.renovationDate}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ renovationDate: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Checkbox
                                            checked={stateProdUnit.perimeter === '1'}
                                            label={i18n.protectionPerimeter}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ perimeter: v ? '1' : '0' })}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleTextArea
                                            value={stateProdUnit.comments}
                                            title={i18n.comments}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ comments: v })}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <StationContributorPanel
                            station={stateProdUnit}
                            onChange={onChangeProductionUnit}
                            keyList='contributors'
                            {...readModeObj}
                        />
                    </Grid2>
                </Grid2>
                <Grid2 container size={3} columnSpacing={1}>
                    <Grid2 size={12}>
                        <StationUpdatePanel station={stateProdUnit} />
                    </Grid2>
                    <Grid2 size={12}>
                        <Card>
                            <CardContent>
                                <Grid2 container columnSpacing={1}>

                                    <Grid2 size={12}>
                                        <Input
                                            title={i18n.dataOrigin}
                                            value={stateProdUnit.dataOrigin}
                                            onChange={v => onChangeProductionUnit({ dataOrigin: v })}
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Select
                                            label={i18n.status}
                                            value={stateProdUnit.status}
                                            onChange={v => onChangeProductionUnit({
                                                status: v,
                                                statusLogin: getLogin(),
                                                statusDate: moment().valueOf(),
                                            })}
                                            options={status}
                                            integerValue
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleDatePicker
                                            id='statusDate'
                                            label={i18n.controlDoneAt}
                                            value={stateProdUnit.statusDate}
                                            {...readModeObj}
                                            onChange={v => onChangeProductionUnit({ statusDate: v })}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Select
                                            label={i18n.controlDoneBy}
                                            value={stateProdUnit.statusLogin}
                                            onChange={v => onChangeProductionUnit({ statusLogin: v })}
                                            options={users.map(u => ({ code: u.login, name: u.name || u.login }))}
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <StationMapDashboardPanel noMarkerTooltip station={stateProdUnit} type={STATION_TYPE_NAME.productionUnit} />
                    </Grid2>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.location} />
                            <CardContent>
                                <Grid2 container columnSpacing={1}>
                                    <Grid2 size={12}>
                                        <Input
                                            title={i18n.address}
                                            value={stateProdUnit.address}
                                            onChange={v => onChangeProductionUnit({ address: v })}
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Select
                                            value={stateProdUnit.townCode}
                                            label={i18n.city}
                                            options={cities} keyValue='id' displayWithCode
                                            onChange={v => onChangeProductionUnit({ townCode: v })}
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                    {(stateProdUnit.declarationTownCode !== stateProdUnit.townCode || !readMode) && (
                                        <Grid2 size={12}>
                                            <Select
                                                value={stateProdUnit.declarationTownCode}
                                                label={i18n.declarationTownCode}
                                                options={cities}
                                                keyValue='id'
                                                displayWithCode
                                                onChange={v => onChangeProductionUnit({ declarationTownCode: v })} {...readModeObj}
                                            />
                                        </Grid2>
                                    )}

                                    <Grid2 size={12}>
                                        <Input
                                            value={stateProdUnit.countryCode}
                                            title={i18n.countryCode}
                                            onChange={v => onChangeProductionUnit({ countryCode: v })}
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleTextArea
                                            noMargin={false}
                                            classname={!readMode && 'margin-bottom-1' || ''}
                                            title={i18n.localisation}
                                            value={stateProdUnit.location}
                                            onChange={v => onChangeProductionUnit({ location: v })}
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            title='X'
                                            value={stateProdUnit.x}
                                            floatValue
                                            onChange={v => onChangeProductionUnit({ x: v })}
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            title='Y'
                                            value={stateProdUnit.y}
                                            floatValue
                                            onChange={v => onChangeProductionUnit({ y: v })}
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Select
                                            label={i18n.projection}
                                            value={stateProdUnit.projection}
                                            options={projectionList}
                                            onChange={v => onChangeProductionUnit({ projection: v })}
                                            {...readModeObj}
                                            integerValue
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <NumberField
                                            value={stateProdUnit.altitude}
                                            title={i18n.altitude}
                                            floatValue
                                            onChange={(_, v) => onChangeProductionUnit({ altitude: v })}
                                            {...readModeObj}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                </Grid2>
            </Grid2>
        )
    }
    return null
}

ProductionUnitDescriptionPanel.propTypes = {
    stationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    id: PropTypes.number,
}

export default ProductionUnitDescriptionPanel
