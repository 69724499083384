/* eslint-disable react/forbid-prop-types */
import React, { isValidElement, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Paper, TableContainer, TableHead, TableRow, Table, TablePagination, TableCell, TableBody, Icon, Card, CardContent, Tooltip, IconButton, TableSortLabel, Box, Grid2, Typography } from '@mui/material'
import { floor, isNil, isNumber, isObject, isUndefined, orderBy, partition, sum } from 'lodash'
import { getI18nOrLabel } from 'utils/StringUtil'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { styled } from '@mui/styles'
import { CardTitle } from 'components/card/NewCard'
import CloudOffIcon from '@mui/icons-material/CloudOff'

// https://virtuoso.dev/mui-table-virtual-scroll/

const SORT = {
    ASC: 'asc',
    DESC: 'desc',
}

const ALIGN = {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
    TOP: 'top',
    BOTTOM: 'bottom',
    SPACE_BETWEEN: 'space-between',
}

const isDefined = v => !((isNumber(v) && isNaN(v)) || isNil(v) || v === '') || v === 0
const sortRows = (rows, sort = {}) => {
    const { column, direction } = sort
    if (isUndefined(column) || isUndefined(direction)) return rows

    const [withValue, withoutValue] = partition(rows, elem => {
        if (isValidElement(elem[column])) return false
        if (!isObject(elem[column])) return isDefined(elem[column])
        const {
            value,
            sortValue = value,
        } = elem[column]
        if (isValidElement(sortValue)) return false
        return isDefined(sortValue)
    })
    const orderedRows = orderBy(withValue, elem => isObject(elem[column]) ? elem[column]?.sortValue ?? elem[column]?.value : elem[column], direction)
    return [...orderedRows, ...withoutValue]
}

const WrapperBox = styled(Box)({
    padding: '0px 10px',
})

const WrapperPaper = styled(Paper)({
    padding: '0px 10px',
})

const WrapperCardContent = styled(CardContent)({
    width: '100%',
    overflow: 'hidden',
    padding: '0px 10px !important', // just to override the theme
})

const WrapperAccordionDetails = styled(Paper)({
    padding: '0px 10px',
})

const NewTable = ({
    rows = [],
    headers = [],
    headersLabel = {}, // deprecated
    lineActions = [],

    onClickRow,
    defaultSort = {},
    onSort,
    rowsPerPageOptions = nbPerPageLabelShort,

    maxHeight,
    displayHeaders = true,
    WrapperComponent = WrapperPaper,
    'data-cy': dataCy = 'table',
}) => {
    const [sort, setSort] = useState(defaultSort)

    useUpdateEffect(() => {
        onSort?.(sort)
    }, [sort])

    const sortedRows = useMemo(() => sortRows(rows, sort), [rows, sort])

    const minRowsPerPage = rowsPerPageOptions[0]?.value ?? rowsPerPageOptions[0]

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(minRowsPerPage)

    const filteredRows = minRowsPerPage > 0 ? sortedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
    ) : sortedRows

    useEffect(() => {
        if (page > 0 && rows.length <= page * rowsPerPage) {
            setPage(floor(rows.length / rowsPerPage))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows.length])

    const filteredLineActions = lineActions.filter(a => a.displayed || isUndefined(a.displayed))
    const [rightActions, leftActions] = partition(filteredLineActions, a => a.align === ALIGN.RIGHT)

    const offsetLeftAction = leftActions.length * 23
    const offsetRightAction = rightActions.length * 23

    const listLeftSize = headers.map(h => h.sticky === ALIGN.LEFT && h.width || 0)
    const listRightSize = headers.map(h => h.sticky === ALIGN.RIGHT && h.width || 0).toReversed()

    const offsetLeftStickyHeaders = listLeftSize.reduce((acc, width, i) => {
        acc.push(offsetLeftAction + sum(listLeftSize.slice(0, i)) + width)
        return acc
    }, [offsetLeftAction])

    const offsetRightStickyHeaders = listRightSize.reduce((acc, width, i) => {
        acc.push(offsetRightAction + sum(listRightSize.slice(0, i)) + width)
        return acc
    }, [offsetRightAction]).toReversed()


    return (
        <WrapperComponent>
            <TableContainer
                sx={{
                    maxHeight,
                    overflowY: maxHeight ? 'auto' : 'hidden',

                    // Ne pas toucher !! Obligatoire pour fixer la taille du tableau en cas d'un grand nombre de colonnes. Voir avec Thomas
                    width: 0,
                    minWidth: '100%',
                    overflowX: 'auto',
                }}
            >
                <Table stickyHeader aria-label='sticky table' size='small' sx={{ ['& tr:last-child td']: { borderBottom: minRowsPerPage < rows.length ? undefined : 'none' } }}>
                    <TableHead sx={displayHeaders ? {} : { display: 'none' }}>
                        <TableRow>
                            {leftActions.map((a, i) => (<TableCell key={lineActions.indexOf(a)} variant='headerIcon' sx={{ left: `${i * 23}px` }} />))}
                            {headers.map((header, i) => {
                                const {
                                    key,
                                    value,
                                    onClick,
                                    sticky,
                                    width,
                                    color,
                                    tooltip,
                                } = isObject(header) ? header : { key: header }
                                const isNullValue = key.startsWith('nullValue')
                                const isSorted = sort.column === key
                                const tableCell = (
                                    <TableCell
                                        variant='head'
                                        sx={{
                                            backgroundColor: color,
                                            minWidth: width,
                                            ...(!isNil(sticky) ? {
                                                position: 'sticky',
                                                zIndex: 3,
                                                [sticky]: `${sticky === ALIGN.LEFT ? offsetLeftStickyHeaders[i] : offsetRightStickyHeaders[i + 1]}px`,
                                            } : {}),
                                        }}
                                        key={i}
                                        sortDirection={isSorted ? sort?.direction : false}
                                    >
                                        {!isNullValue && (
                                            <TableSortLabel
                                                active={isSorted}
                                                direction={isSorted ? sort?.direction : undefined}
                                                hideSortIcon
                                                onClick={onClick ?? (() => {
                                                    setSort(({ direction, column }) => {
                                                        if (column !== key) return { direction: SORT.ASC, column: key }
                                                        if (direction === SORT.ASC) return { direction: SORT.DESC, column: key }
                                                        return {} // direction === SORT.DESC
                                                    })
                                                })}
                                            >
                                                {value ?? headersLabel[key] ?? getI18nOrLabel(key)}
                                            </TableSortLabel>
                                        )}
                                    </TableCell>
                                )
                                if (tooltip) {
                                    return (
                                        <Tooltip key={i} title={tooltip}>
                                            {tableCell}
                                        </Tooltip>
                                    )
                                }
                                return tableCell
                            })}
                            {rightActions.map((a, i) => (<TableCell key={lineActions.indexOf(a)} variant='headerIcon' sx={{ right: `${i * 23}px` }} />))}
                        </TableRow>
                    </TableHead>
                    <TableBody data-cy={`${dataCy}_body`}>
                        {
                            filteredRows.map((row) => {
                                const ir = rows.indexOf(row)
                                const funcOnClickRow = onClickRow && (() => onClickRow(row, ir))

                                return (
                                    <TableRow key={ir} sx={{ backgroundColor: row.colorLine ?? 'unset' }}>
                                        {leftActions.map((a, i) => {
                                            const icon = (
                                                <IconButton
                                                    sx={{ color: a.color ?? '#000' }}
                                                    size='actionTable'
                                                    onClick={() => a.onClick?.(row, ir)}
                                                >
                                                    <Icon fontSize='inherit'>{a.icon}</Icon>
                                                </IconButton>
                                            )
                                            return (
                                                <TableCell
                                                    key={lineActions.indexOf(a)}
                                                    variant='bodyIcon'
                                                    align={ALIGN.CENTER}
                                                    sx={{ left: `${i * 23}px` }}
                                                >
                                                    {a.tooltip ? (
                                                        <Tooltip key={i} title={a.tooltip}>
                                                            {icon}
                                                        </Tooltip>
                                                    ) : icon}
                                                </TableCell>
                                            )
                                        })}
                                        {headers.map((header, i) => {
                                            const {
                                                key,
                                                width,
                                                sticky,
                                            } = isObject(header) ? header : { key: header }

                                            if (!isObject(row[key]) || isValidElement(row[key])) {
                                                return (
                                                    <TableCell
                                                        key={i}
                                                        variant='body'
                                                        sx={{
                                                            backgroundColor: row.colorLine ?? 'white',
                                                            cursor: isUndefined(funcOnClickRow) ? 'default' : 'pointer',

                                                            color: 'black',
                                                            maxWidth: width,
                                                            ...(!isNil(sticky) ? {
                                                                position: 'sticky',
                                                                [sticky]: `${sticky === ALIGN.LEFT ? offsetLeftStickyHeaders[i] : offsetRightStickyHeaders[i + 1]}px`,
                                                            } : {}),
                                                        }}
                                                        onClick={funcOnClickRow}
                                                        padding={isNil(row[key]) ? 'none' : 'normal'}
                                                    >
                                                        {row[key]}
                                                    </TableCell>
                                                )
                                            }
                                            const {
                                                value,
                                                tooltip,
                                                align = ALIGN.LEFT,
                                                verticalAlign,
                                                color = row.colorLine ?? 'white',
                                                fontColor = 'black', // getTextColorByHexColor(color)
                                                onClick: onClickCell = funcOnClickRow,
                                                noPadding,

                                                rightIcon = {},
                                                leftIcon = {},
                                            } = row[key] ?? {}

                                            const {
                                                icon: leftIconName,
                                                color: leftIconColor = 'grey',
                                            } = leftIcon

                                            const {
                                                icon: rightIconName,
                                                color: rightIconColor = 'grey',
                                            } = rightIcon

                                            const tableCell = (
                                                <TableCell
                                                    key={i}
                                                    variant='body'
                                                    sx={{
                                                        backgroundColor: color,
                                                        cursor: isUndefined(onClickCell) ? 'default' : 'pointer',

                                                        color: fontColor,
                                                        maxWidth: width,
                                                        ...(!isNil(sticky) ? {
                                                            position: 'sticky',
                                                            [sticky]: `${sticky === ALIGN.LEFT ? offsetLeftStickyHeaders[i] : offsetRightStickyHeaders[i + 1]}px`,
                                                        } : {}),
                                                        verticalAlign,
                                                    }}
                                                    onClick={onClickCell}
                                                    padding={noPadding || (isNil(value) && isNil(leftIconName) && isNil(rightIconName)) ? 'none' : 'normal'}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: align, '& div:first-child': { width: '100%' } }}>
                                                        {!isUndefined(leftIconName) && (
                                                            <Icon sx={{ color: leftIconColor, marginRight: '5px' }}>{leftIconName}</Icon>
                                                        )}
                                                        {value ?? ''}
                                                        {!isUndefined(rightIconName) && (
                                                            <Icon sx={{ color: rightIconColor, marginLeft: '5px' }}>{rightIconName}</Icon>
                                                        )}
                                                    </Box>
                                                </TableCell>
                                            )
                                            if (tooltip) {
                                                return (
                                                    <Tooltip key={i} title={tooltip}>
                                                        {tableCell}
                                                    </Tooltip>
                                                )
                                            }
                                            return tableCell
                                        })}
                                        {rightActions.map((a, i) => {
                                            const icon = (
                                                <IconButton
                                                    sx={{ color: a.color ?? '#000' }}
                                                    size='actionTable'
                                                    onClick={() => a.onClick?.(row, ir)}
                                                >
                                                    <Icon fontSize='inherit'>{a.icon}</Icon>
                                                </IconButton>
                                            )
                                            return (
                                                <TableCell
                                                    key={lineActions.indexOf(a)}
                                                    variant='bodyIcon'
                                                    align={ALIGN.CENTER}
                                                    sx={{ right: `${i * 23}px` }}
                                                >
                                                    {a.tooltip ? (
                                                        <Tooltip key={i} title={a.tooltip}>
                                                            {icon}
                                                        </Tooltip>
                                                    ) : icon}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {minRowsPerPage < rows.length && (
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component='div'
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(_, p) => setPage(p)}
                    onRowsPerPageChange={(e) => {
                        setRowsPerPage(+e.target.value)
                        setPage(0)
                    }}
                />
            )}
            {
                !rows.length && (
                    <Grid2 container size={12} justifyContent='center' alignItems='center' spacing={1} sx={{ padding: '5 0' }}>
                        <CloudOffIcon />
                        <Typography variant='h6' fontWeight='bold'>Aucune donnée à afficher</Typography>
                    </Grid2>
                )
            }
        </WrapperComponent>
    )
}

NewTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    // rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    //     PropTypes.number,
    //     PropTypes.string,
    //     PropTypes.element,
    //     PropTypes.shape({
    //         value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    //         sortValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    //         onClick: PropTypes.func,
    //         color: PropTypes.string,
    //         fontColor: PropTypes.string,
    //         tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    //         align: PropTypes.oneOf([ALIGN.LEFT, ALIGN.RIGHT, ALIGN.CENTER]),
    //         noPadding: PropTypes.bool,
    //         leftIcon: PropTypes.shape({
    //             icon: PropTypes.string,
    //             color: PropTypes.string,
    //         }),
    //         rightIcon: PropTypes.shape({
    //             icon: PropTypes.string,
    //             color: PropTypes.string,
    //         }),
    //     }),
    // ]))).isRequired,
    headers: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
            ]),
            sticky: PropTypes.oneOf([ALIGN.LEFT, ALIGN.RIGHT]),
            width: PropTypes.number,
            color: PropTypes.string,
            tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }),
    ])).isRequired,
    /**
     * @deprecated use property value on headers instead
     */
    headersLabel: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
    lineActions: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        align: PropTypes.oneOf([ALIGN.LEFT, ALIGN.RIGHT]),
        displayed: PropTypes.bool,
        color: PropTypes.string,
        tooltip: PropTypes.string,
    })),

    onClickRow: PropTypes.func,
    defaultSort: PropTypes.shape({
        column: PropTypes.string.isRequired, // header
        direction: PropTypes.oneOf([SORT.ASC, SORT.DESC]).isRequired,
    }),
    onSort: PropTypes.func,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
    })),

    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    displayHeaders: PropTypes.bool,
    WrapperComponent: PropTypes.elementType,
    'data-cy': PropTypes.string,
}

const CardTable = ({
    title = '',
    subTitle = '',
    subtitleSize,
    actions = [],
    hideNbElements = false,
    color = '#FFF',

    rows = [],
    headers = [],
    headersLabel = {}, // deprecated
    lineActions = [],

    onClickRow,
    defaultSort,
    onSort,
    rowsPerPageOptions,

    maxHeight,
    displayHeaders = true,
    'data-cy': dataCy,
}) => {
    const formattedTitle = useMemo(() => {
        if (hideNbElements) {
            return title
        }
        const nbElements = rows.length
        return (
            <>
                {isValidElement(title) ? title : <span style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>{title}</span>}
                <span style={{ fontSize: '1.4rem', paddingLeft: '5px' }}>({nbElements} {nbElements > 1 ? i18n.elements : i18n.element})</span>
            </>
        )
    }, [hideNbElements, rows.length, title])

    return (
        <Card>
            <CardTitle
                title={formattedTitle}
                subTitle={subTitle}
                actions={actions}
                color={color}
                subtitleSize={subtitleSize}
            />
            <NewTable
                rows={rows}
                headers={headers}
                headersLabel={headersLabel}
                lineActions={lineActions}

                onClickRow={onClickRow}
                defaultSort={defaultSort}
                onSort={onSort}
                rowsPerPageOptions={rowsPerPageOptions}

                maxHeight={maxHeight}
                displayHeaders={displayHeaders}
                WrapperComponent={WrapperCardContent}
                data-cy={dataCy}
            />
        </Card>
    )
}

CardTable.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitleSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    actions: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.shape({
            icon: PropTypes.string,
            tooltip: PropTypes.string,
            onClick: PropTypes.func,
            color: PropTypes.string,
            displayed: PropTypes.bool,
            'data-cy': PropTypes.string,
        }),
    ])),
    hideNbElements: PropTypes.bool,
    color: PropTypes.string,

    rows: PropTypes.arrayOf(PropTypes.object).isRequired,
    // rows: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([
    //     PropTypes.number,
    //     PropTypes.string,
    //     PropTypes.element,
    //     PropTypes.shape({
    //         value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    //         sortValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    //         onClick: PropTypes.func,
    //         color: PropTypes.string,
    //         fontColor: PropTypes.string,
    //         tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    //         align: PropTypes.oneOf([ALIGN.LEFT, ALIGN.RIGHT, ALIGN.CENTER]),
    //         noPadding: PropTypes.bool,
    //         leftIcon: PropTypes.shape({
    //             icon: PropTypes.string,
    //             color: PropTypes.string,
    //         }),
    //         rightIcon: PropTypes.shape({
    //             icon: PropTypes.string,
    //             color: PropTypes.string,
    //         }),
    //     }),
    // ]))).isRequired,
    headers: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element,
            ]),
            sticky: PropTypes.oneOf([ALIGN.LEFT, ALIGN.RIGHT]),
            width: PropTypes.number,
            color: PropTypes.string,
            tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
        }),
    ])).isRequired,
    /**
     * @deprecated use property value on headers instead
     */
    headersLabel: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
    lineActions: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        align: PropTypes.oneOf([ALIGN.LEFT, ALIGN.RIGHT]),
        displayed: PropTypes.bool,
        color: PropTypes.string,
        tooltip: PropTypes.string,
    })),

    onClickRow: PropTypes.func,
    defaultSort: PropTypes.shape({
        column: PropTypes.string.isRequired, // header
        direction: PropTypes.oneOf([SORT.ASC, SORT.DESC]).isRequired,
    }),
    onSort: PropTypes.func,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
    })),

    maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    displayHeaders: PropTypes.bool,
    'data-cy': PropTypes.string,
}

export {
    NewTable,
    CardTable,
    WrapperBox,
    WrapperPaper,
    WrapperCardContent,
    WrapperAccordionDetails,
    sortRows,
    SORT,
    ALIGN,
}