import { find, isEqual, orderBy, reduce, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { geti18n } from 'utils/StringUtil'
import HydrometryAction from '../../../../../../hydrometry/actions/HydrometryAction'
import DtoHydrometricStation from '../../../../../../hydrometry/dto/DtoHydrometricStation'
import PiezometryAction from '../../../../../../piezometry/actions/PiezometryAction'
import DtoPiezometerLight from '../../../../../../piezometry/dto/DtoPiezometerLight'
import QualityAction from '../../../../../../quality/actions/QualityAction'
import DtoQualitometerLight from '../../../../../../quality/dto/DtoQualitometerLight'
import CityDto from '../../../../../../referencial/components/city/dto/CityDto'
import { getFullDateMini } from '../../../../../../utils/DateUtil'
import { getStationTypeNameFromType } from '../../../../../../utils/StationUtils'
import { HYDROMETRY, PIEZOMETRY, QUALITY } from '../../../constants/StatisticConstants'
import DtoUser from '../../../dto/DtoUser'
import DtoUserStationStatistic from '../../../dto/DtoUserStationStatistic'
import DtoUserStationStatisticItem from '../../../dto/DtoUserStationStatisticItem'
import ExportAction from '../../../../../../export/actions/ExportAction'
import { formatData } from '../../../../../../utils/ExportDataUtil'
import { nbPerPageLabelMedium } from '../../../../../../referencial/constants/ReferencialConstants'
import { CardTable } from '../../../../../../components/datatable/NewTable'
import { greyBlue } from '../../../../../../utils/constants/ColorTheme'

class StationStatisticsPanel extends Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        if (!this.props.piezometers.length) {
            this.props.fetchPiezometersLight()
        }
        if (!this.props.qualitometers.length) {
            this.props.fetchQualitometersLight()
        }
        if (!this.props.hydrometricStations.length) {
            this.props.fetchHydrometricStations()
        }
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.userStationStatistics, this.props.userStationStatistics)
            || !isEqual(nextProps.qualitometers, this.props.qualitometers)
            || !isEqual(nextProps.piezometers, this.props.piezometers)
            || !isEqual(nextProps.hydrometricStations, this.props.hydrometricStations)
            || !isEqual(nextProps.cities, this.props.cities)
            || !isEqual(nextProps.user, this.props.user)
    }

    getReduceStatistics = () => {
        return reduce(this.props.userStationStatistics, (a, b) => {
            if (!a[`${b.module}_${b.value}`]) {
                a[`${b.module}_${b.value}`] = {
                    module: b.module,
                    code: b.value,
                    datas: [],
                }
            }
            a[`${b.module}_${b.value}`].datas.push(b)
            return a
        }, {})
    }

    getStations = (module) => {
        switch (module) {
            case QUALITY:
                return this.props.qualitometers
            case PIEZOMETRY:
                return this.props.piezometers
            case HYDROMETRY:
                return this.props.hydrometricStations
            default:
                return []
        }
    }

    getCity = (station) => {
        if (station && station.townCode) {
            const cityFound = find(this.props.cities, (c) => {
                return c.code === station.townCode
            })
            if (cityFound) {
                if (cityFound.name) {
                    return `${cityFound.name} - [${cityFound.code}]`
                }
                return `[${cityFound.code}]`
            }
        }
        return ''
    }

    getStation = (module, code) => {
        const stations = this.getStations(module)
        if (stations.length) {
            return find(stations, (o) => {
                const codeDesignation = (() => {
                    const result = o.code
                    if (o.designation) {
                        return `${result}/${o.designation}`
                    }
                    return result
                })()
                return codeDesignation === code
            })
        }
        return null
    }

    getDatas = () => {
        const reduceValues = this.getReduceStatistics()
        return orderBy(Object.keys(reduceValues).map((o) => {
            const statisticObject = reduceValues[o]
            const code = (() => {
                const result = statisticObject.code
                if (result.includes(' - ')) {
                    return result.split(' - ')[0]
                }
                return result
            })()
            const listOfStatistics = statisticObject.datas
            const lastVisit = orderBy(listOfStatistics, 'statisticDate', 'desc')[0]
            const station = this.getStation(statisticObject.module, code)
            const city = this.getCity(station)
            const finalCode = (() => {
                if (station && station.name) {
                    return `${code} - ${station.name}`
                }
                return code
            })()
            return new DtoUserStationStatisticItem({
                stationType: geti18n(getStationTypeNameFromType(statisticObject.module.toLowerCase())),
                code: finalCode,
                city,
                lastAccess: getFullDateMini(lastVisit.statisticDate),
                nbAccess: listOfStatistics.length,
            })
        }), 'nbAccess', 'desc')
    }

    render() {
        const login = this.props.user.login || i18n.users
        if (this.props.userStationStatistics.length) {
            const data = take(this.getDatas(), 10)
            return (
                <CardTable
                    actions={[{
                        icon: 'download',
                        onClick: () => this.props.export(formatData(data), 'xlsx', `${i18n.userStationStatisticsExport + login}`),
                        color: 'white',
                    }]}
                    title={ `10 ${i18n.userStationStatistics}` }
                    headers={['stationType', 'code', 'city', 'nbAccess', 'lastAccess']}
                    rowsPerPageOptions={nbPerPageLabelMedium}
                    rows={ data }
                    data-cy={'station_stats_table'}
                    color={greyBlue}
                    hideNbElements
                    displayHeaders={false}
                />
            )
        }
        return null
    }
}

StationStatisticsPanel.propTypes = {
    user: PropTypes.instanceOf(DtoUser),
    userStationStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStationStatistic)),
    piezometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoPiezometerLight)),
    qualitometers: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),
    hydrometricStations: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    fetchPiezometersLight: PropTypes.func,
    fetchQualitometersLight: PropTypes.func,
    fetchHydrometricStations: PropTypes.func,
    export: PropTypes.func,
}
const mapStateToProps = (store) => {
    return {
        piezometers: store.PiezometryReducer.piezometersLight,
        qualitometers: store.QualityReducer.qualitometersLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        cities: store.CityReducer.cities,
        user: store.UserReducer.user,
    }
}

const mapDispatchToProps = {
    fetchPiezometersLight: PiezometryAction.fetchPiezometersLight,
    fetchQualitometersLight: QualityAction.fetchQualitometersLight,
    fetchHydrometricStations: HydrometryAction.fetchHydrometricStations,
    export: ExportAction.export,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationStatisticsPanel)
