'use strict'
import AppStore from 'store/AppStore'
import {
    ADD_ANALYSIS,
    RECEIVE_ALL_PC_SELECTION,
    RECEIVE_ASSOCIATED_STATIONS_ANALYSIS,
    RECEIVE_ASSOCIATED_STATIONS_OPERATIONS,
    RECEIVE_ASSOCIATED_STATIONS_POINTS,
    RECEIVE_ASSOCIATED_STATIONS_SAMPLES,
    RECEIVE_HYDROMETRIC_STATION_MEASURE,
    RECEIVE_OPERATIONS,
    RECEIVE_PIEZOMETER_MEASURE,
    RECEIVE_QUALITY_THRESHOLD,
    RESET_SUIVI_PC,
} from '../constants/SuivipcConstants'
import DtoOperation from '../dto/DtoOperation'
import DtoAnalysis from 'quality/dto/DtoAnalysis'
import DtoQualityThreshold from '../dto/DtoQualityThreshold'
import DtoPiezometerMeasure from '../dto/DtoPiezometerMeasure'
import DtoHydrometricStationMeasure from '../dto/DtoPiezometerMeasure'
import DtoSelection from '../dto/DtoSelection'
import { arrayOf, instanceOf } from '../../../../../utils/StoreUtils'
import SuivipcAction from '../actions/SuivipcAction'
import DtoSample from '../../../../../quality/dto/DtoSample'
import DtoPoint from '../../../../../quality/dto/DtoPoint'

export function SuivipcReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_OPERATIONS:
            return {
                ...state,
                operations: action.operations.map(el => new DtoOperation(el)),
            }
        case ADD_ANALYSIS:
            return {
                ...state,
                analysis: action.analysis.map(el => new DtoAnalysis(el, action.stationId)),
            }
        case RECEIVE_QUALITY_THRESHOLD:
            return {
                ...state,
                qualityThresholds: action.qualityThresholds.map(el => new DtoQualityThreshold(el)),
            }
        case RECEIVE_PIEZOMETER_MEASURE:
            return {
                ...state,
                piezometerMeasures: action.piezometerMeasures.map(measure => new DtoPiezometerMeasure(measure)),
            }
        case RECEIVE_HYDROMETRIC_STATION_MEASURE:
            return {
                ...state,
                hydrometricStationMeasures: action.hydrometricStationMeasures.map(measure => new DtoHydrometricStationMeasure(measure)),
            }
        case RECEIVE_ALL_PC_SELECTION:
            return {
                ...state,
                selections: action.selections.map(sel => new DtoSelection(sel)),
            }
        case RECEIVE_ASSOCIATED_STATIONS_OPERATIONS:
            return {
                ...state,
                associatedStationsOperations: action.operations.map(el => new DtoOperation(el)),
            }
        case RECEIVE_ASSOCIATED_STATIONS_SAMPLES:
            return {
                ...state,
                associatedStationsSamples: action.samples.map(s => new DtoSample(s)),
            }
        case RECEIVE_ASSOCIATED_STATIONS_ANALYSIS:
            return {
                ...state,
                associatedStationsAnalysis: action.analysis.map(el => new DtoAnalysis(el)),
            }
        case RECEIVE_ASSOCIATED_STATIONS_POINTS:
            return {
                ...state,
                associatedStationsPoints: action.points.map(p => new DtoPoint(p)),
            }
        case RESET_SUIVI_PC:
            return {
                ...state,
                operations: [],
                analysis: [],
                samples: [],
                parameters: [],
                analysisParameters: [],
                qualityThresholds: [],
                qualifications: [],
                piezometerMeasures: [],
                hydrometricStationMeasures: [],
                selections: [],
                associatedStationsOperations: [],
                associatedStationsSamples: [],
                associatedStationsAnalysis: [],
            }

        default:
            return state
    }
}

export const store = {
    operations: [],
    analysis: [],
    samples: [],
    analysisParameters: [],
    analysisSupports: [],
    selectedSupports: [],
    qualityThresholds: [],
    qualifications: [],
    piezometerMeasures: [],
    hydrometricStationMeasures: [],
    selections: [],
    associatedStationsOperations: [],
    associatedStationsSamples: [],
    associatedStationsAnalysis: [],
    associatedStationsPoints: [],
}

export const SuiviPcStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        suiviPC: {
            reset: SuivipcAction.fetchReset,
        },
        operations: {
            fetch: SuivipcAction.fetchOperations,
            store: appStore.SuivipcReducer.operations,
            propType: instanceOf(DtoOperation),
        },
        analysis: {
            fetch: null,
            store: appStore.SuivipcReducer.analysis,
            propType: arrayOf(DtoAnalysis),
        },
    }
}