import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import StationAccessibilitiesPanel from '../../../station/components/link/StationAccessibilitiesPanel'
import StationAltimetrySystemsPanel from '../../../station/components/link/StationAltimetrySystemsPanel'
import StationArrangementPanel from '../../../station/components/link/StationArrangementPanel'
import StationCasingPanel from '../../../station/components/link/StationCasingPanel'
import StationDespoliationPanel from '../../../station/components/link/StationDespoliationPanel'
import StationFunctionPanel from '../../../station/components/link/StationFunctionPanel'
import StationHydrogeologicalEntityPanel from '../../../station/components/link/StationHydrogeologicalEntityPanel'
import StationLandmarkPanel from '../../../station/components/link/StationLandmarkPanel'
import StationLithologyPanel from '../../../station/components/link/StationLithologyPanel'
import StationLocationPanel from '../../../station/components/link/StationLocationPanel'
import StationMeasureMethodPanel from '../../../station/components/link/StationMeasureMethodPanel'
import StationNetworkPanel from '../../../station/components/link/StationNetworkPanel'
import StationTempRefPanel from '../../../station/components/link/StationTempRefPanel'
import StationUsagePanel from '../../../station/components/link/StationUsagePanel'
import StationWatermassPanel from '../../../station/components/link/StationWatermassPanel'
import { getSandreList } from '../../../utils/StoreUtils'
import DtoPiezometer from '../../dto/DtoPiezometer'
import StationPointPrelPanel from '../../../station/components/link/StationPointPrelPanel'
import { Card, CardContent, Grid2 } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import StationContributorPanel from '../../../station/components/link/StationContributorPanel'

const PiezometerDescriptionTablesPanel = ({
    piezometer = {},
    onChangePiezometer = () => { },
    editMode = false,
    onShowContact = () => { },
    changePiezometerWork = () => { },
}) => {
    const {
        sandreCodes,
        watersheds,
        aquifers,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        watersheds: store.WatershedReducer.watersheds,
        aquifers: store.AquiferReducer.aquifers,
    }), shallowEqual)

    const onChangeCheckReset = (linkName, valueName, valueToCheck) => {
        if (valueToCheck) {
            onChangePiezometer({ [linkName]: [{ idStation: piezometer.id, [valueName]: valueToCheck }] })
        } else {
            onChangePiezometer({ [linkName]: [] })
        }
    }

    const params = {
        station: piezometer,
        onChange: onChangePiezometer,
        readMode: !editMode,
    }
    const mode = { readMode: !editMode, freezeOpti: true }
    const work = piezometer.link_work[0] || {}

    return (
        <>
            <Grid2 size={12}>
                <Card>
                    <CardTitle title={i18n.context} />
                    <CardContent>
                        <Grid2 container columnSpacing={1}>
                            <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                                <StationWatermassPanel {...params} className='blue' />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    value={piezometer.link_aquifers[0] && piezometer.link_aquifers[0].aquiferCode}
                                    label={i18n.aquifer}
                                    options={aquifers}
                                    displayWithCode
                                    onChange={v => onChangeCheckReset('link_aquifers', 'aquiferCode', v)}
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    value={piezometer.watershedCode}
                                    label={i18n.watershed}
                                    options={watersheds}
                                    keyValue='id'
                                    onChange={v => onChangePiezometer({ watershedCode: v })}
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    value={piezometer.link_lithologicType[0] && piezometer.link_lithologicType[0].lithologyType}
                                    label={i18n.lithologyType}
                                    options={getSandreList(sandreCodes, SANDRE.LITHOLOGY_TYPE)}
                                    onChange={v => onChangePiezometer({ link_lithologicType: [{ idStation: piezometer.id, lithologyType: v }] })}
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={12} sx={{ marginBottom: '10px' }}>
                                <StationLithologyPanel {...params} />
                            </Grid2>
                            <Grid2 size={12}>
                                <StationHydrogeologicalEntityPanel {...params} />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12}>
                <StationContributorPanel {...params} />
            </Grid2>
            <Grid2 size={12}>
                <StationAltimetrySystemsPanel {...params} className='blue' />
            </Grid2>
            <Grid2 size={12}>
                <StationLandmarkPanel {...params} className='blue' />
            </Grid2>
            <Grid2 size={12}>
                <StationMeasureMethodPanel {...params} className='blue' />
            </Grid2>
            <Grid2 size={12}>
                <StationPointPrelPanel {...params} st='piezometry' />
            </Grid2>
            <Grid2 size={12}>
                <StationTempRefPanel {...params} st='piezometry' />
            </Grid2>
            <Grid2 size={12}>
                <StationNetworkPanel {...params} />
            </Grid2>
            <Grid2 size={12}>
                <StationFunctionPanel {...params} />
            </Grid2>
            <Grid2 size={12}>
                <StationUsagePanel {...params} />
            </Grid2>
            <Grid2 size={12}>
                <StationArrangementPanel {...params} />
            </Grid2>
            <Grid2 size={12}>
                <StationAccessibilitiesPanel {...params} onShowContact={onShowContact} />
            </Grid2>
            <Grid2 size={12}>
                <StationDespoliationPanel {...params} />
            </Grid2>
            <Grid2 size={12}>
                <Card>
                    <CardTitle title={i18n.technicalCharacteristics} />
                    <CardContent>
                        <Grid2 container columnSpacing={1}>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.stationType}
                                    value={work.stationType}
                                    keyObj='stationType'
                                    changeObj={changePiezometerWork}
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.phone}
                                    value={work.phone}
                                    keyObj='phone'
                                    changeObj={changePiezometerWork}
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.sensorType}
                                    value={work.sensorType}
                                    keyObj='sensorType'
                                    changeObj={changePiezometerWork}
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.serialNumber}
                                    value={work.serialNumber}
                                    keyObj='serialNumber'
                                    changeObj={changePiezometerWork}
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.workDepth}
                                    value={work.workDepth}
                                    keyObj='workDepth'
                                    changeObj={changePiezometerWork}
                                    floatValue
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.sensorDepth}
                                    value={work.sensorDepth}
                                    keyObj='sensorDepth'
                                    changeObj={changePiezometerWork}
                                    floatValue
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.internalDiameter}
                                    value={work.internalDiameter}
                                    keyObj='internalDiameter'
                                    changeObj={changePiezometerWork}
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    title={i18n.externalDiameter}
                                    value={work.externalDiameter}
                                    keyObj='externalDiameter'
                                    changeObj={changePiezometerWork}
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <SimpleTextArea
                                    title={i18n.comment}
                                    value={work.comment}
                                    keyObj='comment'
                                    changeObj={changePiezometerWork}
                                    {...mode}
                                />
                            </Grid2>
                            <Grid2 size={12} sx={{ marginTop: '5px' }}>
                                <StationCasingPanel {...params} />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
            </Grid2>
            <Grid2 size={12}>
                <StationLocationPanel {...params} />
            </Grid2>
        </>
    )
}

PiezometerDescriptionTablesPanel.propTypes = {
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    editMode: PropTypes.bool,
    onChangePiezometer: PropTypes.func,
    onShowContact: PropTypes.func,
    changePiezometerWork: PropTypes.func,
}

export default PiezometerDescriptionTablesPanel
