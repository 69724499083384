import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { round } from '../../utils/NumberUtil'
import { Grid2, LinearProgress, Tooltip } from '@mui/material'
import { isNil } from 'lodash'

const ProgressBar = ({
    indeterminate = false,
    progress = 0,
    withMessage = false,
    message = i18n.progressLoading,
    tooltip,
}) => {
    const progressComponent = (
        <Grid2 container justifyContent='center'>
            {withMessage && (
                <Grid2 size='auto'>
                    <h6>{message}{indeterminate ? '' : ` (${round(progress, 0)} %)`}</h6>
                </Grid2>
            )}
            <Grid2 size={12}>
                <LinearProgress variant={indeterminate ? 'indeterminate' : 'determinate'} value={progress}/>
            </Grid2>
        </Grid2>
    )
    if (isNil(tooltip)) return progressComponent

    return (
        <Tooltip title={tooltip}>
            {progressComponent}
        </Tooltip>
    )
}

ProgressBar.propTypes = {
    indeterminate: PropTypes.bool,
    progress: PropTypes.number,
    withMessage: PropTypes.bool,
    message: PropTypes.string,
    tooltip: PropTypes.string,
}

export default ProgressBar