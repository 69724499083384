import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Grid, Icon, Popover } from '@mui/material'
import { useDispatch } from 'react-redux'
import { maxBy } from 'lodash'
import { HISTO, H_24, H_48, J30, J365, J7, J90, OTHER, CIVIL_YEAR, HYDRO_YEAR } from 'alerting/constants/ChartFollowContants'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { mainBlue, mainWhite } from 'utils/constants/ColorTheme'
import { ButtonMUI } from 'components/styled/Buttons'
import { getLocalStorageJson, onChangeDate, setLocalStorageJson } from 'utils/FormUtils'
import AdministrationAction from 'administration/actions/AdministrationAction'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import { CHART_SELECTED_TIME, CHART_SELECTED_TIME_OTHER } from 'quality/constants/ChartConstant'
import { getHoverColor } from 'utils/ColorUtil'
import Input from 'components/forms/Input'
import { getDate } from 'utils/DateUtil'
import moment from 'moment'
import { getSubstractTime } from './EChartUtils'

const CHRONIC_TYPE = -1

const TooManyDataDialog = ({
    isOpen = false,
    onClose = () => {},
    onValidate = () => {},
}) => (
    <DialogMUI open={isOpen} PaperProps={{ sx: { minHeight: 'auto', maxHeight: 'auto' } }}>
        <DialogTitleMUI sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item>
                <h3 style={{ margin: 0, fontWeight: '600', fontSize: '20px' }}>{i18n.payAttention}</h3>
            </Grid>
            <Grid item>
                <Icon
                    sx={{ color: mainWhite, cursor: 'pointer' }}
                    size='small'
                    icon='close'
                    onClick={onClose}
                >
                        close
                </Icon>
            </Grid>
        </DialogTitleMUI>
        <DialogContentMUI>
            <Grid container>
                <Grid item xs={12} sx={{ padding: '2.5rem 0 1rem', fontSize: '16px' }}>
                    {i18n.tooManyDataConfirmationMessage}
                </Grid>
            </Grid>
        </DialogContentMUI>
        <DialogActionsMUI>
            <Grid container item xs={12} justifyContent='flex-end' alignItems='center'>
                <Grid item>
                    <ButtonMUI
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            onValidate()
                            onClose()
                        }}
                    >
                        {i18n.validate}
                    </ButtonMUI>
                </Grid>
            </Grid>
        </DialogActionsMUI>
    </DialogMUI>
)

TooManyDataDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onValidate: PropTypes.func,
}

const MUIChartTabs = ({
    time = J30,
    onChangeTime = () => {},

    statistics = [],
    stationType,
    withSuiviTabs = false,
    timeKey = CHART_SELECTED_TIME,
    otherTimeKey = CHART_SELECTED_TIME_OTHER,
}) => {
    const [otherEl, setOtherEl] = useState()
    const [tempOtherMinDate, setTempOtherMinDate] = useState(time === OTHER ? getLocalStorageJson(otherTimeKey).minDate : moment().subtract('91', 'day').valueOf())
    const [tempOtherMaxDate, setTempOtherMaxDate] = useState(time === OTHER ? getLocalStorageJson(otherTimeKey).maxDate : moment().valueOf())

    const [isOpenHisto, setIsOpenHisto] = useState(false)

    const getBackgroundColor = (timeScale) => time === timeScale ? mainBlue : mainWhite
    const getColor = (timeScale) => time === timeScale ? mainWhite : mainBlue

    const dispatch = useDispatch()

    const onChange = (newTime) => {
        const minDate = (() => {
            if (newTime === HISTO) {
                return undefined
            }
            if (newTime === CIVIL_YEAR) {
                return moment().startOf('year').valueOf()
            }
            if (newTime === HYDRO_YEAR) {
                return moment().month(9).year(moment().month() >= 9 ? moment().year() : moment().year() - 1).startOf('month').valueOf()
            }
            return getSubstractTime(newTime)
        })()
        const maxDate = (() => {
            if (newTime === HISTO) {
                return moment().valueOf()
            }
            if (newTime === HYDRO_YEAR) {
                return moment().month(9).year(moment().month() >= 9 ? moment().year() : moment().year() - 1).add(1, 'year').month(9).startOf('month').valueOf()
            }
            return undefined
        })()
        onChangeTime({ minDate, maxDate, time: newTime })
        dispatch(AdministrationAction.updateSetting(timeKey, `${newTime}`))
        setLocalStorageJson(timeKey, `${newTime}`)
    }

    const onValidateOtherDates = () => {
        setOtherEl()
        onChangeTime({
            minDate: tempOtherMinDate && moment(tempOtherMinDate).startOf('day').valueOf(),
            maxDate: tempOtherMaxDate && moment(tempOtherMaxDate).startOf('day').valueOf(),
            time: OTHER,
        })
        dispatch(AdministrationAction.updateSetting(timeKey, OTHER))
        setLocalStorageJson(timeKey, OTHER)
        setLocalStorageJson(otherTimeKey, {
            minDate: tempOtherMinDate && moment(tempOtherMinDate).startOf('day').valueOf(),
            maxDate: tempOtherMaxDate && moment(tempOtherMaxDate).startOf('day').valueOf(),
        })
    }

    const isPiezo = stationType === STATION_TYPE_NAME.piezometry

    const checkConfirmation = (toCheck, setIsOpen = () => {}, cb = () => {}) => {
        if (isPiezo && statistics.length) {
            const statBrute = statistics.find(stat => stat.typeId === CHRONIC_TYPE)
            if (toCheck && statBrute && statBrute.countTotal > 10000) {
                setIsOpen(true)
            } else {
                cb()
            }
        } else if ([STATION_TYPE_NAME.hydrometry, STATION_TYPE_NAME.pluviometry].includes(stationType) && statistics.length) {
            if (maxBy(statistics, c => c.countTotal).countTotal > 10000) {
                setIsOpen(true)
            } else {
                cb()
            }
        } else {
            cb()
        }
    }

    return (
        <Grid item xs={12}>
            <Grid
                container
                sx={{ width: withSuiviTabs ? 600 : 480 }}
                alignItems='center'
                justifyContent='space-between'
            >
                <Grid
                    container item xs={10.25}
                    sx={{
                        boxShadow: '0px 0px 16px 0px rgb(0 0 0 / 6%)',
                        textAlign: 'center',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        backgroundColor: mainWhite,
                        border: `solid 2px ${mainBlue}`,
                        '& div': {
                            lineHeight: '1.1',
                            fontWeight: 600,
                            textAlign: 'center',
                            padding: '0.75rem',
                            transition: 'all 0.3s ease-in-out',
                        },
                        '& div.MuiGrid-grid-xs-true': {
                            padding: '0.75rem 0',
                        },
                        '& div:not(:last-child)': {
                            borderRight: `solid 2px ${mainBlue}`,
                        },
                    }}
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Grid
                        item xs
                        className='clickable'
                        onClick={() => onChange(H_24)}
                        sx={{
                            backgroundColor: getBackgroundColor(H_24),
                            '&:hover': {
                                backgroundColor: getHoverColor(getBackgroundColor(H_24)),
                            },
                            color: getColor(H_24),
                        }}
                    >
                        {i18n.h24}
                    </Grid>
                    <Grid
                        item xs
                        className='clickable'
                        onClick={() => onChange(H_48)}
                        sx={{
                            backgroundColor: getBackgroundColor(H_48),
                            '&:hover': {
                                backgroundColor: getHoverColor(getBackgroundColor(H_48)),
                            },
                            color: getColor(H_48),
                        }}
                    >
                        {i18n.h48}
                    </Grid>
                    <Grid
                        item xs
                        className='clickable'
                        onClick={() => onChange(J7)}
                        sx={{
                            backgroundColor: getBackgroundColor(J7),
                            '&:hover': {
                                backgroundColor: getHoverColor(getBackgroundColor(J7)),
                            },
                            color: getColor(J7),
                        }}
                    >
                        {i18n.j7}
                    </Grid>
                    <Grid
                        item xs
                        className='clickable'
                        onClick={() => onChange(J30)}
                        sx={{
                            backgroundColor: getBackgroundColor(J30),
                            '&:hover': {
                                backgroundColor: getHoverColor(getBackgroundColor(J30)),
                            },
                            color: getColor(J30),
                        }}
                    >
                        {i18n.j30}
                    </Grid>
                    <Grid
                        item xs
                        className='clickable'
                        onClick={() => onChange(J90)}
                        sx={{
                            backgroundColor: getBackgroundColor(J90),
                            '&:hover': {
                                backgroundColor: getHoverColor(getBackgroundColor(J90)),
                            },
                            color: getColor(J90),
                        }}
                    >
                        {i18n.j90}
                    </Grid>
                    <Grid
                        item xs
                        className='clickable'
                        onClick={() => onChange(J365)}
                        sx={{
                            backgroundColor: getBackgroundColor(J365),
                            '&:hover': {
                                backgroundColor: getHoverColor(getBackgroundColor(J365)),
                            },
                            color: getColor(J365),
                        }}
                    >
                        {i18n.j365}
                    </Grid>
                    {
                        withSuiviTabs && (
                            <>
                                <Grid
                                    item
                                    className='clickable'
                                    onClick={() => onChange(CIVIL_YEAR)}
                                    sx={{
                                        backgroundColor: getBackgroundColor(CIVIL_YEAR),
                                        '&:hover': {
                                            backgroundColor: getHoverColor(getBackgroundColor(CIVIL_YEAR)),
                                        },
                                        color: getColor(CIVIL_YEAR),
                                        padding: '0.75rem',
                                    }}
                                >
                                    {i18n.civilYear}
                                </Grid>
                                <Grid
                                    item
                                    className='clickable'
                                    onClick={() => onChange(HYDRO_YEAR)}
                                    sx={{
                                        backgroundColor: getBackgroundColor(HYDRO_YEAR),
                                        '&:hover': {
                                            backgroundColor: getHoverColor(getBackgroundColor(HYDRO_YEAR)),
                                        },
                                        color: getColor(HYDRO_YEAR),
                                        padding: '0.75rem',
                                    }}
                                >
                                    {i18n.hydroYear}
                                </Grid>
                            </>
                        )
                    }
                    <Grid
                        item xs
                        className='clickable'
                        onClick={() => checkConfirmation(true, setIsOpenHisto, () => onChange(HISTO))}
                        sx={{
                            backgroundColor: getBackgroundColor(HISTO),
                            '&:hover': {
                                backgroundColor: getHoverColor(getBackgroundColor(HISTO)),
                            },
                            color: getColor(HISTO),
                            padding: '0.75rem 0',
                        }}
                    >
                        {i18n.histo}
                    </Grid>
                </Grid>
                <Grid
                    item xs={1.5}
                    className='clickable'
                    onClick={event => setOtherEl(event.currentTarget)}
                    sx={{
                        boxShadow: '0px 0px 16px 0px rgb(0 0 0 / 6%)',
                        textAlign: 'center',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        border: `solid 2px ${mainBlue}`,
                        lineHeight: '1.1',
                        fontWeight: 600,
                        padding: '0.75rem 0',
                        transition: 'all 0.3s ease-in-out',
                        backgroundColor: getBackgroundColor(OTHER),
                        '&:hover': {
                            backgroundColor: getHoverColor(getBackgroundColor(OTHER)),
                        },
                        color: getColor(OTHER),
                    }}
                >
                    {i18n.other}
                </Grid>
            </Grid>
            <Popover
                open={!!otherEl}
                anchorEl={otherEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ borderRadius: '8px' }}
                onClose={() => setOtherEl(undefined)}
            >
                <Grid container sx={{ width: '200px', padding: '1rem' }}>
                    <Grid item xs={12}>
                        <Input
                            title={i18n.startDate}
                            value={getDate(tempOtherMinDate)}
                            onChange={v => onChangeDate(v, v2 => setTempOtherMinDate(v2))}
                            onEnterKeyPress={onValidateOtherDates}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            title={i18n.endDate}
                            value={getDate(tempOtherMaxDate)}
                            onChange={v => onChangeDate(v, v2 => setTempOtherMaxDate(v2))}
                            onEnterKeyPress={onValidateOtherDates}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '1rem' }}>
                        <ButtonMUI
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={onValidateOtherDates}
                        >
                            {i18n.toLoad}
                        </ButtonMUI>
                    </Grid>
                </Grid>
            </Popover>
            <TooManyDataDialog
                isOpen={isOpenHisto}
                onClose={() => setIsOpenHisto(false)}
                onValidate={() => onChange(HISTO)}
            />
        </Grid>
    )
}

MUIChartTabs.propTypes = {
    time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChangeTime: PropTypes.func,
    statistics: PropTypes.arrayOf(PropTypes.shape({})),
    stationType: PropTypes.string,
    setDisplayCote: PropTypes.func,
    setDiplayModes: PropTypes.func,
    withSuiviTabs: PropTypes.bool,
    timeKey: PropTypes.string,
    otherTimeKey: PropTypes.string,
}

export default MUIChartTabs