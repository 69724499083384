module.exports = {
    SELECT_FILTER: 'SELECT_FILTER',
    RECEIVE_JOB_ROWS: 'RECEIVE_JOB_ROWS',
    RESET_JOB_ROWS: 'RESET_JOB_ROWS',
    RECEIVE_JOB: 'RECEIVE_JOB',
    RECEIVE_JOB_EXECUTIONS: 'RECEIVE_JOB_EXECUTIONS',
    RESET_JOB_EXECUTIONS: 'RESET_JOB_EXECUTIONS',
    RECEIVE_JOB_FILES: 'RECEIVE_JOB_FILES',
    RECEIVE_JOB_LOGS: 'RECEIVE_JOB_LOGS',
    RECEIVE_JOBS_LOGS: 'RECEIVE_JOBS_LOGS',
    ADD_JOB_EXECUTIONS: 'ADD_JOB_EXECUTIONS',
    START_DATA: 'start_data',
    END_DATA: 'end_data',
    START_CALL_WS: 'start_call_ws',
    END_CALL_WS: 'end_call_ws',
    VOLUME: 'volume',
    VOLUME_ANALYSIS: 'volume_analysis',
    VOLUME_SAMPLES: 'volume_samples',
    ERROR: 'error',
    SUCCESS: 'success',
    RELOAD_JOB_LOGS: 'RELOAD_JOB_LOGS',
    RECEIVE_QUALITY_JOB: 'RECEIVE_QUALITY_JOB',
    RECEIVE_QUALITY_EXECUTION: 'RECEIVE_QUALITY_EXECUTION',
    RECEIVE_EXPORT_HISTORY: 'RECEIVE_EXPORT_HISTORY',
    RECEIVE_JOB_TABLE: 'RECEIVE_JOB_TABLE',
    JOB_CACHE: 'JOB_CACHE',
    FILE_TO_PROCESS: 'FILE_TO_PROCESS',
}
