import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { searchAllCharacters } from '../../utils/StringUtil'
import { TextField } from '@mui/material'
import { makeStyles } from '@mui/styles'
import useDebounce from 'utils/customHook/useDebounce'
import DisplayedValue from './DisplayedValue'

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: '15px !important',
        position: 'relative',
        marginBottom: '5px !important',

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'solid grey 1px',
            },
            '&.Mui-focused fieldset': {
                border: '#53a1ff solid 1px',
            },
        },

        '& .MuiInputLabel-outlined': {
            top: '-20px',
            color: ({ disabled }) => disabled && '#9e9e9e' || '#161832',
            fontWeight: 'bold',
            left: '2px',
            fontSize: '1rem',
        },

        '& .MuiInputLabel-outlined.Mui-focused': {
            color: '#53a1ff',
        },

        '& .MuiInputLabel-outlined.Mui-disabled': {
            color: 'grey',
        },

        '& .MuiInputBase-multiline': {
            padding: '0 5px',
            height: '100%',
        },
    },
}))

const SimpleTextArea = ({
    id = null,
    value = '',
    onEnterKeyPress,
    onChange = () => { },
    readMode = false,
    title = '',
    hideNull = false,
    maxLength = null,
    rows = 3,
    disabled = false,
    className = '',
}) => {
    const classes = useStyles({ disabled })

    const [textareaId] = useState(id || searchAllCharacters(title + uuidv4()).replaceAll(' ', '_').replace('numero', ''))

    const [stateValue, setStateValue] = useState(value)

    useEffect(() => {
        setStateValue(value)
    }, [value])

    useDebounce(() => onChange(stateValue), 500, [stateValue])

    if (readMode) {
        return (
            <DisplayedValue
                label={title}
                value={stateValue}
                hideNull={hideNull}
                className={className}
            // obligatory={obligatory}
            />
        )
    }

    return (
        <TextField
            id={textareaId}
            value={stateValue}
            onChange={e => setStateValue(e.target.value)}
            multiline
            rows={rows}
            label={title}
            fullWidth
            InputLabelProps={{
                shrink: false,
            }}
            disabled={disabled}
            classes={classes}
            className={className}
            onKeyDown={e => {
                if (e.key === 'Enter' && onEnterKeyPress) {
                    setStateValue(e.target.value)
                    onEnterKeyPress()
                    e.preventDefault()
                } else {
                    setStateValue(e.target.value)
                }
            }}
        />
    )
}

SimpleTextArea.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    onEnterKeyPress: PropTypes.func,
    onChange: PropTypes.func,
    readMode: PropTypes.bool,
    title: PropTypes.string,
    hideNull: PropTypes.bool,
    maxLength: PropTypes.number,
    rows: PropTypes.number,
    disabled: PropTypes.bool,
    className: PropTypes.string,
}

export default SimpleTextArea
