/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import { Card, CardContent, Grid2 } from '@mui/material'
import NumberField from 'components/forms/NumberField'
import useSandreList from 'utils/customHook/useSandreList'
import Select from 'components/forms/Select'

const headers = ['type', 'nature', 'amount', 'status']

const LithologyDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const accessTypeList = useSandreList(SANDRE.INSTALLATION_TYPE_ACCES)
    const accessNatureList = useSandreList(SANDRE.INSTALLATION_NATURE_ACCES)
    const equipmentStateList = useSandreList(SANDRE.INSTALLATIONS_ETATEQUIPEMENT)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.type}
                            options={accessTypeList}
                            value={stateElement.typeAccess}
                            onChange={v => onChangeElement({ typeAccess: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.nature}
                            options={accessNatureList}
                            value={stateElement.nature}
                            onChange={v => onChangeElement({ nature: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            title={i18n.amount}
                            value={stateElement.amount}
                            onChange={v => onChangeElement({ amount: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.status}
                            options={equipmentStateList}
                            value={stateElement.status}
                            onChange={v => onChangeElement({ status: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

LithologyDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const InstallationAccessTypePanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_accessTypes'
            formatFunction={c => ({
                ...c,
                type: getSandreLabel(sandreCodes, SANDRE.INSTALLATION_TYPE_ACCES, c.typeAccess),
                nature: getSandreLabel(sandreCodes, SANDRE.INSTALLATION_NATURE_ACCES, c.nature),
                status: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_ETATEQUIPEMENT, c.status),
            })}
            titles={{
                title: i18n.accessType,
                edit: i18n.editAccessType,
                new: i18n.newAccessType,
            }}
            headers={headers}
            dialogContent={LithologyDialogContent}
        />
    )
}

InstallationAccessTypePanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default InstallationAccessTypePanel