/* eslint-disable max-nested-callbacks */
import MessageCard from 'components/card/MessageCard'
import PluviometryAction from 'pluviometry/actions/PluviometryAction'
import PluviometerDto from 'pluviometry/dto/PluviometerDto'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { orderBy, uniqBy } from 'lodash'
import { getBeginingOfTheYear, getDate, getEndOfTheYear } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'
import { hasValue } from 'utils/NumberUtil'
import { PluvioCumulOptionIcon } from 'catchment/components/catchment/dashboard/pluviometry/PluvioOption'
import { intParser } from 'utils/customHook/useApplicationSetting'
import { Card, CardContent, Grid2 } from '@mui/material'
import useStartPeriod from 'catchment/utils/useStartPeriod'
import useEndPeriod from 'catchment/utils/useEndPeriod'
import { CardTitle } from 'components/card/NewCard'
import PluvioChart from './PluvioChart'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel'

const barTypes = [-1, 1, 3]

const CatchmentFollowUpPluvio = ({
    pluviometersLinked = [],
}) => {
    const dispatch = useDispatch()

    const {
        catchment,
        pluviometers,
        pluviometryDataTypes,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const startPeriod = useStartPeriod(intParser)
    const endPeriod = useEndPeriod(intParser)

    const startDate = getBeginingOfTheYear(startPeriod)
    const endDate = getEndOfTheYear(endPeriod)

    const [dataTypes, setDataTypes] = useLocalStorage('CATCHMENT_PLUVIO_DATATYPES', [1]) // pluie
    const [dataMeasures, setDataMeasures] = useState([])
    const [group, setGroup] = useLocalStorage('CATCHMENT_PLUVIO_GROUP', 'SUM_AUTO')

    const filteredDataTypes = useMemo(() => pluviometryDataTypes.filter(dt => dt.showData || dataTypes.includes(dt.id)), [dataTypes, pluviometryDataTypes])

    const getDefaultSelectPointId = () => {
        const mainPointFound = catchment.catchmentPoints?.find(({ mainPoint }) => mainPoint)
        const code = mainPointFound?.codeWithoutDesignation
        const idMainPoint = mainPointFound && pluviometers.find(q => q.code === code)?.id
        if (hasValue(idMainPoint) && dataMeasures.find(data => data.stationId === idMainPoint)) {
            return [idMainPoint]
        }
        return dataMeasures.length ? [parseInt(dataMeasures[0]?.stationId)] : []
    }

    const [stations, setStations] = useState(getDefaultSelectPointId)

    useEffect(() => {
        setStations(getDefaultSelectPointId)
    }, [catchment, pluviometers, dataMeasures])

    useEffect(() => {
        dispatch(PluviometryAction.fetchPluviometryDataTypes())
    }, [dispatch])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch((cancelRef) => {
        setDataMeasures(prev => prev.filter(dm => dataTypes.includes(dm.dataType)))
        const listInputs = pluviometersLinked.flatMap(p => {
            return dataTypes
                .filter(dt => !dataMeasures.some(dm => dm.stationId === p.id && dm.dataType === dt && dm.groupFunc === group))
                .map(dataType => ({
                    groupFunc: group.startsWith('CUMUL') && group || barTypes.includes(dataType) && group || 'MAX',
                    chartMode: true,
                    startDate: getBeginingOfTheYear(startPeriod),
                    endDate: getEndOfTheYear(endPeriod),
                    dataType,
                    stationId: p.id,
                }))
        })

        return listInputs.map(input => dispatch(PluviometryAction.getPluvioChronicMeasures(input))
            .then(data => !cancelRef.current && setDataMeasures(prev => uniqBy([data, ...prev], d => `${d.stationId}#${d.dataType}`))))
    }, [group, dataTypes])

    const dataMeasuresFiltered = useMemo(() => {
        const orderedDataMeasures = orderBy(dataMeasures, 'dataType')
        if (stations.length === 0) return orderedDataMeasures
        return orderedDataMeasures.filter(({ stationId }) => stations.includes(stationId))
    }, [dataMeasures, stations])

    const actions = [
        <PluvioCumulOptionIcon
            key={0}
            defaultGroupType={group}
            onChangeGroupType={setGroup}
            disabled={!isLoaded}
        />,
        {
            icon: 'file_download',
            tooltip: i18n.export,
            onClick: () => {
                const exportData = dataMeasures.flatMap(dm => {
                    const pluvio = pluviometers.find(({ id }) => id === dm.stationId)
                    const dataType = pluviometryDataTypes.find(d => d.id === dm.dataType)
                    return dm.measures.map(m => ({
                        date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                        value: { value: m.value, format: '0.00000', cellType: 'number' },
                        name: pluvio?.name || dm.stationId,
                        dataType: dataType?.label ?? '',
                    }))
                })
                exportFile({
                    data: exportData.length ? [
                        {
                            ...exportData[0],
                            headers: ['name', 'dataType', 'date', 'value'],
                        },
                        ...exportData.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.dataPluvio,
                }, true)
            },
        },
    ]

    return (
        <Card data-cy='follow_up_pluvio'>
            <CardTitle title={i18n.pluviometry} actions={actions} />
            <CardContent>
                <Grid2 container columnSpacing={2} alignItems='center'>
                    <Grid2 size={6}>
                        <SuperMultiAutocomplete
                            label={i18n.pluviometer}
                            options={pluviometersLinked}
                            values={stations}
                            onChange={setStations}
                            keyValue={'id'}
                            multiple
                            limit={2}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <SuperMultiAutocomplete
                            label={i18n.dataTypes}
                            options={filteredDataTypes}
                            onChange={setDataTypes}
                            values={dataTypes}
                            keyValue='id'
                            multiple
                            limit={3}
                        />
                    </Grid2>
                </Grid2>
                <div style={{ position: 'relative' }}>
                    {!isLoaded && (<CircularProgressWithLabel value={progress} />) }
                    {dataMeasuresFiltered.length > 0 && (
                        <PluvioChart
                            dataMeasures={dataMeasuresFiltered}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    )}
                    {dataMeasuresFiltered.length === 0 && (<MessageCard>{i18n.noDataToDisplay}</MessageCard>)}
                </div>
            </CardContent>
        </Card>
    )
}

CatchmentFollowUpPluvio.propTypes = {
    pluviometersLinked: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)).isRequired,
}

export default CatchmentFollowUpPluvio