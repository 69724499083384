import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from 'distributionUnit/dto/DtoDistributionUnit'
import InstallationAction from 'installation/actions/InstallationAction'
import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
import DtoNetworkTableLine from 'installation/components/network/dto/DtoNetworkTableLine'
import { push } from '@lagunovsky/redux-react-router'
import { useDispatch } from 'react-redux'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import Input from 'components/forms/Input'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'

const headers = ['network', 'code', 'name']

const NetworkDialogContent = ({
    stateElement,
    onChangeElement = () => { },
    networks = [],
}) => {
    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={12}>
                        <Select
                            options={networks}
                            label={i18n.network}
                            value={stateElement.idNetwork}
                            onChange={(v, obj) => onChangeElement({ idNetwork: v, ...obj })}
                            displayWithCode
                            keyValue='id'
                            noNullValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.code}
                            value={stateElement.code}
                            disabled
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.name}
                            value={stateElement.name}
                            disabled
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

NetworkDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
    networks: PropTypes.arrayOf(PropTypes.instanceOf(DtoNetworkTableLine)),

}

const DistributionUnitNetworksPanel = ({
    station = [],
    onChange = () => { },
    readMode = false,
    invertedHeaderStyle = false,
}) => {
    const dispatch = useDispatch()

    const [installationNetworks, setInstallationNetworks] = useState([])

    useEffect(() => {
        InstallationAction.promiseNetworkTable({ installationType: INSTALLATION_TYPE.NETWORK })
            .then(json => {
                setInstallationNetworks(json?.data?.map(d => new DtoNetworkTableLine(d)))
            })
    }, [])


    return (
        <>
            <StationDescriptionTable
                station={station}
                readMode={readMode}
                onChange={onChange}
                keyList='link_networks'
                idStationKey='idInstallation'
                formatFunction={dn => {
                    return {
                        ...dn,
                        network: dn.idNetwork,
                    }
                }}
                titles={{
                    title: i18n.networks,
                    new: i18n.newNetwork,
                }}
                headers={headers}
                dialogContent={props => (
                    <NetworkDialogContent
                        {...props}
                        networks={installationNetworks.filter(inet => !station.link_networks.map(d => d.id).includes(inet.id))}
                    />
                )}
                onClick={element => dispatch(push(`/station/installation/${element.idNetwork}/dashboard`))}
                invertedHeaderStyle={invertedHeaderStyle}
                editable={false}
            />
        </>
    )
}

DistributionUnitNetworksPanel.propTypes = {
    station: PropTypes.instanceOf(DtoDistributionUnit),
    onChange: PropTypes.func,
    readMode: PropTypes.bool,
    invertedHeaderStyle: PropTypes.bool,
}

export default DistributionUnitNetworksPanel