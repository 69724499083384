/* eslint-disable camelcase */
import { Button, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import Input from 'components/forms/Input'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import { searchAllCharacters } from 'utils/StringUtil'
import DtoExploitation from '../../dto/DtoExploitation'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { ALIGN, CardTable } from 'components/datatable/NewTable'
import { darkBlue } from 'utils/constants/ColorTheme'

class SelectPointsPrelModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            link_points: [...props.exploitation.link_samplings],
            searchValue: '',
        }
    }

    addPoint = (point) => {
        const { link_points } = this.state
        const { exploitation } = this.props
        this.setState({
            link_points: [
                ...link_points,
                {
                    idInstallation: point.id,
                    idExploitation: exploitation.idExploitation,
                    stateCode: 1,
                }],
        })
    }

    removePoint = (point) => {
        const { link_points } = this.state
        const newLinks = link_points.filter((p) => p.idInstallation !== point.id)
        this.setState({ link_points: newLinks })
    }

    getFilteredData = (data) => {
        const { searchValue } = this.state
        const includesValue = searchAllCharacters(searchValue || '')
        return data.filter(i => this.getHash(i).includes(includesValue))
    }

    getHash = (point) => {
        return searchAllCharacters(point.headers.map(key => point[key]))
    }

    getPoints = () => {
        const { installationsWithGeo, citiesIndex } = this.props
        const { link_points } = this.state
        const filteredPoints = this.getFilteredData(installationsWithGeo.filter((i) => !link_points.find((pExploit) => i.id === pExploit.idInstallation)))
        const formatedPoints = filteredPoints.map((p) => {
            const city = citiesIndex[p.townCode] || {}
            return {
                point: p,
                code: p.code,
                name: p.name,
                city: city.labelWithCode,
                codeParcelle: `${p.parcel || ''} ${p.section || ''}`,
                lieuDit: p.location,
                addButton: (<i className='material-icons clickable' onClick={() => this.addPoint(p)}>add</i>),
            }
        })
        return (
            <CardTable
                title={i18n.pointsPrelevement}
                rows={formatedPoints}
                headers={[
                    'code',
                    'city',
                    { key: 'codeParcelle', value: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.codeParcelleWrap}</span> },
                    'lieuDit',
                    { key: 'addButton', value: '', sticky: ALIGN.RIGHT },
                ]}
                onClickRow={(p) => this.addPoint(p.point)}
                rowsPerPageOptions={nbPerPageLabelShort}
                color={darkBlue}
            />
        )
    }

    getSelectedPoints = () => {
        const { installationsWithGeo, citiesIndex } = this.props
        const { link_points } = this.state
        const filteredPoints = this.getFilteredData(link_points.filter((pExploit) => installationsWithGeo.find((i) => i.id === pExploit.idInstallation)).map((pExploit) => {
            const pointFound = installationsWithGeo.find((i) => i.id === pExploit.idInstallation) || {}
            const city = citiesIndex[pointFound.townCode] || {}
            return {
                ...pointFound,
                city: city.labelWithCode,
                codeParcelle: `${pointFound.parcel || ''} ${pointFound.section || ''}`,
                lieuDit: pointFound.location,
            }
        }))
        return (
            <CardTable
                title={i18n.selectedPointsPrelevement}
                rows={filteredPoints}
                headers={[
                    'code',
                    'city',
                    { key: 'codeParcelle', value: <span style={{ whiteSpace: 'pre-wrap' }}>{i18n.codeParcelleWrap}</span> },
                    'lieuDit',
                ]}
                lineActions={[{
                    icon: 'clear',
                    tooltip: i18n.remove,
                    onClick: (p) => this.removePoint(p),
                }]}
                onClickRow={(p) => this.removePoint(p)}
                rowsPerPageOptions={nbPerPageLabelShort}
                color={darkBlue}
            />
        )
    }

    onCancel = () => {
        const { exploitation } = this.props
        this.setState({ link_points: exploitation.link_samplings, searchValue: '' })
        this.props.onCancel()
    }

    onValidate = () => {
        const { link_points } = this.state
        this.setState({ searchValue: '' })
        this.props.onValidate(link_points)
    }

    onCreateNew = () => {
        this.onValidate()
        this.props.onCreateNew()
    }

    render() {
        const { open } = this.props
        const { searchValue } = this.state

        return (
            <Dialog
                fullWidth
                maxWidth='lg'
                open={open}
            >
                <DefaultDialogTitle
                    title={i18n.selection}
                    onClose={this.onCancel}
                />
                <DialogContent>
                    <Grid2 container spacing={1}>
                        <Grid2 size={4}>
                            <Input
                                title={i18n.search}
                                value={searchValue}
                                onChange={(value) => this.setState({ searchValue: value })}
                            />
                        </Grid2>
                        <Grid2 size={8} />
                        <Grid2 size={6}>
                            {this.getPoints()}
                        </Grid2>
                        <Grid2 size={6}>
                            {this.getSelectedPoints()}
                        </Grid2>
                    </Grid2>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between' }}>
                    <Button onClick={this.onCreateNew} variant='contained' color='primary'>
                        {i18n.createNewPointPrel}
                    </Button>
                    <Button onClick={this.onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

SelectPointsPrelModal.propTypes = {
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    onCancel: PropTypes.func.isRequired,
    onValidate: PropTypes.func.isRequired,
    onCreateNew: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    citiesIndex: PropTypes.shape({}),
    installationsWithGeo: PropTypes.arrayOf(PropTypes.object),
}

const mapStateToProps = (store) => {
    return {
        sandreCodes: store.ReferencialReducer.sandreCodes,
        citiesIndex: store.CityReducer.citiesIndex,
        installationsWithGeo: store.InstallationReducer.installationsWithGeo,
    }
}

export default connect(mapStateToProps)(SelectPointsPrelModal)
