import React, { useMemo, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import DtoEvent from '../../../../../events/dto/DtoEvent'
import { getYear } from '../../../../../utils/DateUtil'
import TechniqueEventCard from '../../../../../station/components/event/TechniqueEventCard'
import SmallEventCard from '../../../../../station/components/event/SmallEventCard'
import { EVENT_TYPES, EVENT_TYPES_CODES } from '../../../../../events/constants/EventsConstants'
import GlobalCardList from '../../../../../components/list/cardList/GlobalCardList'
import { groupBy, omit, orderBy } from 'lodash'
import { getEventColor } from '../../../../../utils/ColorUtil'
import Select from '../../../../../components/forms/Select'
import { getI18nTitleData, i18nize } from '../../../../../utils/StringUtil'
import { WhiteCard } from '../../../../../components/styled/Card'
import { Grid2 } from '@mui/material'
import Checkbox from '../../../../../components/forms/Checkbox'

const Title = ({ stationEvents }) => {
    return (
        <div>
            {stationEvents.length ? `${i18n.events} (${stationEvents.length} ${getI18nTitleData(i18n.element, i18n.elements, stationEvents)})` : i18n.noEvents}
        </div>
    )
}

Title.propTypes = {
    stationEvents: PropTypes.arrayOf(DtoEvent),
}

const PiezoSuiviEventsTab = ({ id, displayEvents, changeParent }) => {
    const [eventType, setEventType] = useState('')

    const {
        stationEvents,
    } = useSelector(store => ({
        stationEvents: store.EventsReducer.stationEvents,
    }), shallowEqual)

    const getEventCard = event => {
        return event.eventType === EVENT_TYPES_CODES.TECHNIQUE ?
            <TechniqueEventCard
                event={event}
                shortDate
                link={`station/piezometry/${id}/event/`}
            />
            : <SmallEventCard
                event={event}
                shortDate
                link={`station/piezometry/${id}/event/`}
            />
    }

    const events = eventType ? stationEvents.filter(d => d.eventType === eventType) : stationEvents

    const data = useMemo(() => {
        const groupedEvents = groupBy(events, d => getYear(d.date))
        return Object.keys(groupedEvents).map(key => ({
            title: <div className='valign-wrapper no-margin no-padding'>
                {`${key} (${groupedEvents[key].length} ${getI18nTitleData(i18n.element, i18n.elements, groupedEvents[key])})`}
            </div>,
            cards: orderBy(groupedEvents[key], e => e.date || e.eventDate, 'desc').map(e => ({
                color: getEventColor(e.eventType),
                content: getEventCard(e),
            })),
        }))
    }, [events])

    return (
        <WhiteCard
            className='transparent no-box-shadow'
            title={(<Title stationEvents={events}/>)}
            round
        >
            <Grid2 container spacing={1} justifyContent='center' alignItems='center'>
                <Grid2 size={6} className='padding-top-1'>
                    <Select
                        className='no-margin'
                        nullLabel='&nbsp;'
                        value={eventType}
                        options={i18nize(EVENT_TYPES).map(d => omit(d, 'icon'))}
                        label={i18n.eventTypes}
                        onChange={v => setEventType(v)}
                    />
                </Grid2>
                <Grid2 size={6} className='padding-top-2'>
                    <Checkbox
                        checked={displayEvents}
                        label={i18n.displayEventsOnChart}
                        onChange={v => changeParent({ displayEvents: v })}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <GlobalCardList
                        smallTitle
                        data={data}
                        collapsible
                    />
                </Grid2>
            </Grid2>
        </WhiteCard>
    )
}

PiezoSuiviEventsTab.propTypes = {
    id: PropTypes.number,
    stationEvents: PropTypes.arrayOf(DtoEvent),
    displayEvents: PropTypes.bool,
    changeParent: PropTypes.func,
}

export default PiezoSuiviEventsTab