/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from 'components/forms/Checkbox'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import NumberField from 'components/forms/NumberField'

const headers = ['name', 'code', 'population', 'totalSevice', 'nbFollow']

const AssociatedTownDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Select
                            value={stateElement.code}
                            label={i18n.city}
                            options={cities}
                            keyValue='id'
                            displayWithCode
                            onChange={v => onChangeElement({ code: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            label={i18n.totalSevice}
                            checked={stateElement.totalSevice}
                            onChange={v => onChangeElement({ totalSevice: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.nbFollow}
                            title={i18n.nbFollow}
                            onChange={v => onChangeElement({ nbFollow: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

AssociatedTownDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const STEPAssociatedTownsPanel = ({
    STEP = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        cities,
    } = useSelector(store => ({
        cities: store.CityReducer.cities,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={STEP}
            readMode={readMode}
            onChange={onChange}
            keyList='link_associatedTowns'
            formatFunction={t => {
                const foundCity = cities.find((c) => c.code === t.code) || {}
                return {
                    ...t,
                    ...foundCity,
                    totalSevice: t.totalSevice ? (<Checkbox checked disabled />) : false,
                }
            }}
            titles={{
                title: i18n.cities,
                edit: i18n.editCity,
                new: i18n.newCity,
            }}
            headers={headers}
            dialogContent={AssociatedTownDialogContent}
        />

    )
}

STEPAssociatedTownsPanel.propTypes = {
    STEP: PropTypes.object, // DtoStep
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default STEPAssociatedTownsPanel
