import { findIndex } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { sieauTooltip } from '../../utils/FormUtils'

class FastArrowNav extends Component {
    getArrowElement(item, direction) {
        const onClick = item ? () => this.props.nav.onClick(item) : () => { }
        const tooltip = item ? this.props.nav.labels.map(o => {
            const labelItem = item[o]
            if (labelItem) {
                return (labelItem.value || labelItem.value === '') ? labelItem.value : labelItem
            }
            return ''
        }).join(' - ') : ''
        const disable = item ? 'anim-hover' : 'item-disable non-clickable'
        const tooltipObj = item ? sieauTooltip(tooltip, null, 'bottom') : null
        const additionalClass = direction === 'down' ? 'no-margin' : ''
        return (
            <i key={item ? item.id : 0} className={`material-icons right ${additionalClass} ${disable}`}
                {...tooltipObj}
                onClick={onClick}
                data-cy={`arrow_${direction}`}
            >
                {`arrow_drop_${direction}`}
            </i>
        )
    }

    render() {
        const array = this.props.nav.array
        const current = findIndex(array, o => o.id === this.props.nav.currentId)
        const position = `${current + 1} sur ${array.length}`
        const prevArrow = this.getArrowElement(current === 0 ? null : array[current - 1], 'up')
        const postArrow = this.getArrowElement(current + 1 === array.length ? null : array[current + 1], 'down')
        return (
            <div>
                <a className='dropdown-button non-clickable' id='fast_arrow_nav'>
                    {position}
                    {postArrow}
                    {prevArrow}
                </a>
            </div>
        )
    }
}

FastArrowNav.propTypes = {
    nav: PropTypes.shape({
        currentId: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        array: PropTypes.array,
        labels: PropTypes.arrayOf(PropTypes.string),
        onClick: PropTypes.func,
    }),
}

export default FastArrowNav
