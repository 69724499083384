import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import { getExport } from '../../../utils/linkUtils'
import { CardTable } from 'components/datatable/NewTable'
import SelectionTableModal from 'components/modal/SelectionTableModal'
import { searchAllCharacters } from 'utils/StringUtil'
import useDebounce from 'utils/customHook/useDebounce'
import { Grid2 } from '@mui/material'
import Input from 'components/forms/Input'
import { greyBlue } from 'utils/constants/ColorTheme'
import { authorizeFilter } from 'utils/HabilitationUtil'
import { getStationTypeCodeFromType } from 'utils/StationUtils'

const dialogHeaders = ['name', 'mobile']
const headers = ['name', 'city', 'cityCode', 'phoneTel', 'mobile', 'email']

const SimpleFilterField = ({
    filter = {},
    setFilter = () => { },
}) => {
    const [stateFilter, setStateFilter] = useState(filter)

    useDebounce(() => {
        setFilter(stateFilter)
    }, 500, [stateFilter])

    return (
        <Grid2 container spacing={1}>
            <Grid2 size={6}>
                <Input
                    title={i18n.search}
                    value={stateFilter.searchValue}
                    onChange={searchValue => setStateFilter(prev => ({ ...prev, searchValue }))}
                />
            </Grid2>
        </Grid2>
    )
}

SimpleFilterField.propTypes = {
    filter: PropTypes.shape({
        searchValue: PropTypes.string,
    }),
    setFilter: PropTypes.func,
}

const StationContactsPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        cities,
        contacts,
        accountUser,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        cities: store.CityReducer.cities,
        accountUser: store.AccountReducer.accountUser,
    }))

    const dispatch = useDispatch()
    const [isOpen, setIsOpen] = useState(false)
    const stationContacts = contacts.filter(c => station.link_contacts.find(lc => c.code === lc.contactCode))

    useEffect(() => {
        dispatch(CityAction.fetchCities())
        dispatch(ContactAction.fetchContacts())
    }, [])

    const tableData = stationContacts.map((value) => {
        const city = cities.find(c => c.code && value.cityCode && c.code === value.cityCode)
        return ({
            id: value.id,
            name: { value: value.name, color: 'white' },
            city: { value: city ? city.name : '', color: 'white' },
            cityCode: { value: value.cityCode, color: 'white' },
            phoneTel: { value: value.phoneTel, color: 'white' },
            mobile: { value: value.mobile, color: 'white' },
            email: { value: value.email, color: 'white' },
        })
    })

    const exportAction = getExport(tableData, i18n.peopleToContact, headers)
    const actions = !readMode ? [{
        icon: 'note_add',
        tooltip: i18n.add,
        color: 'white',
        onClick: () => {
            setIsOpen(true)
        },
    }, exportAction] : [exportAction]

    const SimpleFilterFunction = (list, { searchValue }) => {
        const searchValueFormat = searchAllCharacters(searchValue)
        return searchValue ? list.filter(p => searchAllCharacters(dialogHeaders.map(key => p[key])).includes(searchValueFormat)) : list
    }

    const authorizedContacts = authorizeFilter(contacts, accountUser, undefined, 'contributor')

    const lineActions = [{
        icon: 'close',
        onClick: (element) => {
            onChange({
                link_contacts: station.link_contacts.filter(cl => cl.contactCode !== element.id),
            })
        },
        displayed: !readMode,
    }]

    return (
        <>
            <CardTable
                title={i18n.peopleToContact}
                rows={tableData}
                headers={headers}
                actions={actions}
                color={greyBlue}
                lineActions={lineActions}
            />
            <SelectionTableModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onValidate={contactList => {
                    onChange({
                        link_contacts: contactList.map(c => ({
                            stationCode: station.id,
                            stationType: getStationTypeCodeFromType(station.typeName),
                            contactCode: c,
                        })),
                    })
                    setIsOpen(false)
                }}
                listHeaders={dialogHeaders}
                title={i18n.contacts}
                selectionListTitle={i18n.toContact}
                listTitle={i18n.contacts}
                listData={authorizedContacts}
                defaultSelectionList={station.link_contacts.map(lc => lc.contactCode)}
                filterField={SimpleFilterField}
                filterFunction={SimpleFilterFunction}
            />
        </>
    )
}

StationContactsPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(PluviometerDto),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default StationContactsPanel