'use strict'
import {
    DELETE_PIEZOMETER_MEASURES,
    DELETE_PIEZOMETER_MEASURES_BRUTE,
    RECEIVE_MULTIPLE_PIEZOMETERS_ADDITIONAL_MEASURES,
    RECEIVE_PIEZO_VALIDATION_CHART_MEASURES,
    RECEIVE_PIEZOMETER_ADDITIONAL_MEASURES,
    RECEIVE_PIEZOMETER_CHART_MEASURES,
    RECEIVE_PIEZOMETER_CHART_OPTIONS,
    RECEIVE_PIEZOMETER_FILES,
    RECEIVE_PIEZOMETER_INDICATORS,
    RECEIVE_PIEZOMETER_MEASURES,
    RECEIVE_PIEZOMETER_MEASURES_BRUTE,
    RECEIVE_PIEZOMETER_PICTURES,
    RECEIVE_PIEZOMETER_SAMPLES,
    RECEIVE_PIEZOMETER_STATISTICS,
    RECEIVE_ALL_PIEZOMETER_THRESHOLDS,
    RECEIVE_PIEZOMETER_THRESHOLDS,
    RESET_PIEZOMETER_STATION,
    UPDATE_PIEZOMETER_ADDITIONAL_MEASURES,
    UPDATE_PIEZOMETER_CHART_OPTIONS,
    UPDATE_PIEZOMETER_MEASURES,
    UPDATE_PIEZOMETER_MEASURES_BRUTE,
    UPDATE_PIEZOMETER_THRESHOLDS,
} from '../constants/piezo/PiezometerStationConstants'
import DtoPiezometryStationMeasure from '../dto/piezometer/DtoPiezometryStationMeasure'
import DtoPiezometerSample from '../dto/piezometer/DtoPiezometerSample'
import DtoPiezoPicture from '../../piezometry/dto/DtoPiezometryPicture'
import DtoPiezoFile from '../../piezometry/dto/DtoPiezometryFile'
import DtoPiezoThreshold from '../dto/piezometer/DtoPiezoThreshold'
import PiezometerStationAction from '../actions/PiezometerStationAction'
import AppStore from '../../store/AppStore'
import { arrayOf, instanceOf } from '../../utils/StoreUtils'
import DtoPiezometerAdditionalMeasures from '../dto/piezometer/DtoPiezometerAdditionalMeasures'
import { orderBy } from 'lodash'
import DtoPiezometerChartOptions from '../dto/piezometer/DtoPiezometerChartOptions'
import DtoMeasureStats from '../dto/piezometer/DtoMeasureStats'
import DtoPiezometerIndicator from '../dto/piezometer/DtoPiezometerIndicator'
import DtoPiezoMeasureLight from '../../piezometry/dto/chart/DtoPiezoMeasureLight'

export const store = {
    measures: [],
    piezometerMeasureBrute: [],
    samples: [],
    pictures: [],
    files: [],
    allPiezometerThresholds: [],
    piezometerThresholds: [],
    piezometerAdditionalMeasures: [],
    piezometerChartOptions: [],
    chartMeasures: [],
    piezometerStatistics: [],
    piezoValidationChartMeasures: [],
    indicators: [],
}
export function PiezometerStationReducer(state = {}, action) {
    switch (action.type) {
        case RECEIVE_PIEZOMETER_MEASURES:
            return {
                ...state,
                measures: action.measures.map(measure => new DtoPiezometryStationMeasure(measure)),
            }
        case UPDATE_PIEZOMETER_MEASURES:
            return {
                ...state,
                piezometerChartMeasureMax: action.measures.map(measure => new DtoPiezometryStationMeasure(measure)),
            }
        case DELETE_PIEZOMETER_MEASURES:
            return {
                ...state,
                piezometerChartMeasureMax: action.measures.map(measure => new DtoPiezometryStationMeasure(measure)),
            }
        case UPDATE_PIEZOMETER_ADDITIONAL_MEASURES:
            const otherTypes = state.piezometerAdditionalMeasures.filter(t => t.type !== action.dataType)
            return {
                ...state,
                piezometerChartMeasureAdditional: orderBy(otherTypes.concat([new DtoPiezometerAdditionalMeasures({ type: action.dataType, measures: action.measures })]), 'type'),
            }
        case RECEIVE_PIEZOMETER_SAMPLES:
            return {
                ...state,
                samples: action.samples.map(sample => new DtoPiezoMeasureLight(sample)), // /
            }
        case RECEIVE_PIEZOMETER_PICTURES:
            return {
                ...state,
                pictures: action.pictures.map(picture => new DtoPiezoPicture(picture)),
            }
        case RECEIVE_PIEZOMETER_FILES:
            return {
                ...state,
                files: action.files.map(file => new DtoPiezoFile(file)),
            }
        case RECEIVE_PIEZOMETER_THRESHOLDS:
            return {
                ...state,
                piezometerThresholds: action.piezometerThresholds.map(o => new DtoPiezoThreshold(o)),
            }
        case RECEIVE_ALL_PIEZOMETER_THRESHOLDS:
            return {
                ...state,
                allPiezometerThresholds: action.piezometerThresholds.map(o => new DtoPiezoThreshold(o)),
            }
        case UPDATE_PIEZOMETER_THRESHOLDS:
            return {
                ...state,
                piezometerThresholds: action.thresholds.map(o => new DtoPiezoThreshold(o)),
            }
        case RECEIVE_PIEZOMETER_MEASURES_BRUTE:
            return {
                ...state,
                piezometerMeasureBrute: orderBy(action.piezometerMeasureBrute.map(measure => new DtoPiezometryStationMeasure(measure)), ['date', 'hour']),
            }
        case UPDATE_PIEZOMETER_MEASURES_BRUTE:
            const updatedData = orderBy(action.measures.map(measure => new DtoPiezometryStationMeasure(measure)), 'date')
            return {
                ...state,
                piezometerChartMeasureRaw: updatedData,
                piezometerMeasureBrute: updatedData,
            }
        case DELETE_PIEZOMETER_MEASURES_BRUTE:
            return {
                ...state,
                piezometerChartMeasureRaw: action.measures.map(measure => new DtoPiezometryStationMeasure(measure)),
            }
        case RECEIVE_PIEZO_VALIDATION_CHART_MEASURES:
            return {
                ...state,
                piezoValidationChartMeasures: orderBy(action.data.map(measure => new DtoPiezometryStationMeasure(measure)), 'date'),
            }
        case RECEIVE_PIEZOMETER_ADDITIONAL_MEASURES:
            return {
                ...state,
                piezometerAdditionalMeasures: action.piezometerAdditionalMeasures.map(typeObj => new DtoPiezometerAdditionalMeasures(typeObj)),
            }
        case RECEIVE_MULTIPLE_PIEZOMETERS_ADDITIONAL_MEASURES:
            return {
                ...state,
                multiplePiezometersAdditionalMeasures: action.multiplePiezometersAdditionalMeasures.map(typeObj => new DtoPiezometerAdditionalMeasures(typeObj)),
            }
        case RECEIVE_PIEZOMETER_CHART_OPTIONS:
            return {
                ...state,
                piezometerChartOptions: action.chartOptions.map(options => new DtoPiezometerChartOptions(options)),
            }
        case UPDATE_PIEZOMETER_CHART_OPTIONS:
            return {
                ...state,
                piezometerChartOptions: action.chartOptions.map(options => new DtoPiezometerChartOptions(options)),
            }
        case RECEIVE_PIEZOMETER_STATISTICS:
            return {
                ...state,
                piezometerStatistics: action.data.map(stat => new DtoMeasureStats(stat)),
            }
        case RECEIVE_PIEZOMETER_INDICATORS:
            return {
                ...state,
                indicators: action.indicators.map(stat => new DtoPiezometerIndicator(stat)),
            }
        case RECEIVE_PIEZOMETER_CHART_MEASURES:
            return {
                ...state,
                chartMeasures: action.data, // liste de DtoPiezoChartMeasures
            }
        case RESET_PIEZOMETER_STATION :
            return {
                ...state,
                ...store,
            }
        default:
            return state
    }
}

export const PiezometerStationStoreInfos = () => {
    const appStore = AppStore.getState()
    return {
        measures: {
            fetch: PiezometerStationAction.fetchPiezometerMeasures,
            store: appStore.PiezometerStationReducer.measures,
            propType: arrayOf(DtoPiezometryStationMeasure),
        },
        samples: {
            fetch: PiezometerStationAction.fetchPiezometerSamples,
            store: appStore.PiezometerStationReducer.samples,
            propType: instanceOf(DtoPiezometerSample),
        },
    }
}
