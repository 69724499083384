import { Grid, Grid2, Icon } from '@mui/material'
import { WhiteCard } from 'components/styled/Card'
import { groupBy, isUndefined, maxBy, orderBy, round, sum, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import QualityAction from 'quality/actions/QualityAction'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ProgressCard from '../../../components/card/ProgressCard'
import { getQualificationColor, getStatusColor, calculateThresholdResult } from '../../../utils/AnalyseUtils'
import { getColorCircleElement, getRGBColor } from '../../../utils/ColorUtil'
import { getDate } from '../../../utils/DateUtil'
import { getLabel } from '../../../utils/StoreUtils'
import { SuperParameterGraphModal } from '../qualityComponents/ParameterGraph'
import PopupParameterAdministration from './PopupParameterAdministration'
import NewMoleculeDetectedChart from './NewMoleculeDetectedChart'
import OperationsCountChart from './OperationsCountChart'
import useBoolean from 'utils/customHook/useBoolean'
import SliderPanel from 'components/slider/SliderPanel'
import useListIndexed from 'utils/customHook/useListIndexed'
import OperationAction from '../operation/actions/OperationAction'
import SmallPie from 'components/echart/SmallPie'
import { THRESHOLD } from 'quality/constants/QualityConstants'
import DtoAnalysis from 'quality/dto/analyse/DtoAnalysis'
import DtoAnalysisLight from 'quality/dto/analyse/DtoAnalysisLight'
import DtoPrettyAnalysis from 'quality/dto/analyse/DtoPrettyAnalysis'

const QualityIndices = ({
    stationType,
    analysis = [],
}) => {
    const dispatch = useDispatch()
    const {
        qualitometer,
        qualityIndicators,
        qualityThresholds,
        parameters,
        units,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualityIndicators: store.QualityReducer.qualityIndicators,
        qualityThresholds: store.QualityReducer.qualityThresholds,
        parameters: store.ParameterReducer.parameters,
        units: store.UnitReducer.units,
    }), shallowEqual)

    const parametersIndex = useListIndexed(parameters, 'code')
    const unitsIndex = useListIndexed(units, 'id')

    const foundIndicators = qualityIndicators.find(q => q.stationType == stationType)?.indicators || []

    useEffect(() => {
        const unloadThresholds = uniqBy(foundIndicators, 'threshold').map(i => `${i.threshold}`).filter(code => !qualityThresholds.some(t => t.thresholdCode === code))
        unloadThresholds.forEach(code => dispatch(QualityAction.fetchQualityThresholds([{ code, thresholdType: THRESHOLD.PC }])))
    }, [dispatch, foundIndicators])

    const [parameter, setParameter] = useState()

    const {
        value: isGraphOpen,
        setTrue: openGraph,
        setFalse: closeGraph,
    } = useBoolean(false)

    const groupParam = groupBy(analysis, 'parameter')

    const datas = orderBy(foundIndicators.map((indicator) => {
        const paramCode = `${indicator.id}`
        const thresholdCode = `${indicator.threshold}`
        const thresholds = qualityThresholds.find(qt => qt.thresholdCode === thresholdCode)?.thresholds || []
        const lastAnalysis = maxBy((groupParam[paramCode] || []).filter(a => !isUndefined(a.result)), a => a.sampleDate ?? a.analysisDate)

        if (isUndefined(lastAnalysis?.result)) {
            return {
                title: parametersIndex[paramCode]?.name ?? `<${paramCode}>`,
                subtitle: i18n.noResults,
                parameterCode: paramCode,
            }
        }

        const formattedAnalysis = { ...lastAnalysis, ...calculateThresholdResult(lastAnalysis, thresholds) }
        if (formattedAnalysis?.threshold) {
            return {
                title: parametersIndex[paramCode]?.name ?? `<${paramCode}>`,
                subtitle: `${formattedAnalysis.value} ${unitsIndex[formattedAnalysis.unit]?.symbol ?? ''} le ${getDate(formattedAnalysis.sampleDate)}`,
                threshold: formattedAnalysis.threshold,
                analysis: formattedAnalysis,
                parameterCode: paramCode,
            }
        }

        return {
            title: parametersIndex[paramCode]?.name ?? `<${paramCode}>`,
            subtitle: `${formattedAnalysis.value} ${unitsIndex[formattedAnalysis.unit]?.symbol ?? ''} le ${getDate(formattedAnalysis.sampleDate)}`,
            parameterCode: paramCode,
        }
    }), ['indices'], ['asc'])

    const thresholds = useMemo(() => {
        const found = datas.find(d => d.parameterCode === parameter)
        return found ? [{ ...found.threshold, parameterCode: parameter }] : []
    }, [parameter, datas])

    return (
        <>
            <Grid2 container spacing={1}>
                {
                    datas.map((data, i) => {
                        return (
                            <Grid2 key={data.parameterCode} size={12}>
                                <SliderPanel
                                    key={i}
                                    title={data.title}
                                    subtitle={data.subtitle}
                                    analysis={data.analysis}
                                    threshold={data.threshold}
                                    onOpen={() => {
                                        setParameter(data.parameterCode)
                                        openGraph()
                                    }}
                                />
                            </Grid2>
                        )
                    })
                }
                {
                    !datas.length && (
                        <Grid2 container size={12} sx={{ fontSize: 14, color: 'black' }} justifyContent='center' alignItems='center' direction='column' spacing={0}>
                            <Grid2 size='auto'>
                                <Icon style={{ fontSize: 50 }}>cloud_off</Icon>
                            </Grid2>
                            <Grid2 size='auto'>
                                {i18n.noResults}
                            </Grid2>
                        </Grid2>
                    )
                }
            </Grid2>
            <SuperParameterGraphModal
                isOpen={isGraphOpen}
                closeGraph={closeGraph}

                parameter={parameter}
                qualitometer={qualitometer.id}
                thresholds={thresholds}
            />
        </>
    )
}

QualityIndices.propTypes = {
    stationType: PropTypes.string,
    analysis: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysis)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoAnalysisLight)),
        PropTypes.arrayOf(PropTypes.instanceOf(DtoPrettyAnalysis)),
        PropTypes.arrayOf(PropTypes.shape({ })),
    ]),
}

// todo optimize the rerender when openning the admin popin
const DashboardQualitySituationPanel = ({
    stationType,
}) => {
    const dispatch = useDispatch()

    const {
        qualitometer,
        qualitoDashboard,
        qualifications,
        status,
        accountUser,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualitoDashboard: store.QualityReducer.qualitoDashboard,
        qualifications: store.QualityReducer.qualifications,
        status: store.QualityReducer.status,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const {
        value: isLoaded,
        setTrue: loaded,
        setFalse: notLoaded,
    } = useBoolean(false)

    useEffect(() => {
        dispatch(OperationAction.fetchRemarks())
        dispatch(QualityAction.fetchQualifications())
        dispatch(QualityAction.fetchStatus())
    }, [])

    useEffect(() => {
        notLoaded()
        dispatch(QualityAction.fetchQualitoDashboard(qualitometer.id)).finally(loaded)
    }, [qualitometer.id])

    const {
        value: isAdminOpen,
        setTrue: openAdmin,
        setFalse: closeAdmin,
    } = useBoolean(false)

    const { stats = {} } = qualitoDashboard

    const listQualification = [
        { id: 4, value: stats.qbrute },
        { id: 3, value: stats.qincertaine },
        { id: 2, value: stats.qincorrecte },
        { id: 1, value: stats.qcorrecte },
    ]

    const countQualification = sum(listQualification.map(q => q.value))
    const dataQualifications = listQualification.map(q => {
        const percentage = countQualification ? round(q.value * 100 / countQualification, 2) : 0
        const percentageLabel = `${percentage}%`
        const color = getRGBColor(getQualificationColor(q.id))
        return {
            name: percentageLabel,
            value: percentage,
            itemStyle: {
                normal: {
                    color: color === 'white' ? 'lightgrey' : color,
                },
            },
            tooltip: {
                formatter: (params) => {
                    return `${i18n.qualification} (${percentageLabel})</br>
                        ${getColorCircleElement(params.color)}${getLabel(qualifications, q.id) || i18n.notDefined}: ${q.value} ${i18n.of} ${countQualification} ${i18n.operations}`
                },
            },
        }
    })

    const listStatus = [
        { id: 1, value: stats.sbrute },
        { id: 2, value: stats.snv1 },
        { id: 3, value: stats.snv2 },
        { id: 4, value: stats.sinterpretee },
    ]

    const countStatus = sum(listStatus.map(s => s.value))

    const dataStatus = listStatus.map(s => {
        const percentage = countStatus ? round(s.value * 100 / countStatus, 2) : 0
        const percentageLabel = `${percentage}%`
        const color = getRGBColor(getStatusColor(s.id))
        return {
            name: percentageLabel,
            value: percentage,
            itemStyle: {
                normal: {
                    color: color === 'white' ? 'lightgrey' : color,
                },
            },
            tooltip: {
                formatter: (params) => {
                    return `${i18n.status} (${percentageLabel})</br>
                        ${getColorCircleElement(params.color)}${getLabel(status, s.id) || i18n.notDefined}: ${s.value} ${i18n.of} ${countQualification} ${i18n.operations}`
                },
            },
        }
    })

    /*
    getNoncompliances = (natureList, simpleI18n, pluralI18n) => {
        const thresholdPC = getSetting(this.props.applicationSettings, 'SEUILS')
        const defaultThreshold = thresholdPC && this.props.qualityThresholds.find(th => th.thresholdCode == thresholdPC)
        if (this.props.analysis.length && this.props.parameters.length && defaultThreshold) {
            const microbiologicalAnalysis = this.props.analysis.filter(analysis => {
                const parameter = this.props.parameters.find(p => p.code == analysis.parameter)
                if (!parameter) {
                    AppStore.dispatch(LogAction.logError(`${i18n.unknownParameter} : ${analysis.parameter}`))
                    return false
                }
                return parameter.code ? natureList.includes(parseInt(parameter.typeParam)) : false
            })
            const defaultQualityThresholds = defaultThreshold.thresholds
            const analysisWithThreshold = microbiologicalAnalysis.reduce((acc, analysis) => {
                const foundThreshold = defaultQualityThresholds.find(th => th.parameterCode == analysis.parameter)
                if (foundThreshold) {
                    acc.push(analysis.result > foundThreshold.threshold1)
                }
                return acc
            }, [])
            const noncompliances = analysisWithThreshold.filter(a => !!a).length
            const percent = ceil(noncompliances * 100 / analysisWithThreshold.length, 2) || 0
            const tooltip = (
                <p className='no-margin left-align' >
                    {microbiologicalAnalysis.length } { i18n.analysis } { pluralI18n }<br />
                    { analysisWithThreshold.length } { i18n.withThreshold }<br />
                    {noncompliances } { i18n.noncompliances } {`(${percent}`}{i18n.percentOf } { `${analysisWithThreshold.length})`}
                </p>
            )
            return (
                <div>
                    <div className='row no-margin' {...sieauTooltip(() => tooltip)} >
                        <h6 className='center-align' style={{ fontWeight: 'bold' }}>{simpleI18n}</h6>
                    </div>
                    <div className='row no-margin padding-left-1 padding-right-1'>
                        <ProgressBar progress={percent} color='red' tooltip={tooltip} height={15}/>
                    </div>
                </div>
            )
        }
        return null
    }
    */

    const action = accountUser.isAdmin === '1' ? [{ onClick: openAdmin, iconName: 'settings', color: 'black' }] : []

    return (
        <>
            {
                !isLoaded && (
                    <ProgressCard indeterminate />
                )
            }
            {
                isLoaded && qualitoDashboard?.stationsData?.length && (
                    <>
                        <WhiteCard
                            round
                            actions={action}
                            title={i18n.qualitySituation}
                        >
                            <Grid container >
                                <Grid item xs={10}>
                                    <div className='card-content no-padding'>
                                        <Grid container style={{ paddingTop: '5px' }}>
                                            <Grid item xs={5} className='center-align no-margin' style={{ fontWeight: 'bold', fontSize: '14.3px' }}>{i18n.qualityIndicators}</Grid>
                                            <Grid item xs={7} className='center-align no-margin' style={{ fontWeight: 'bold', fontSize: '14.3px' }}>{i18n.mostDetectedMolecules}</Grid>
                                        </Grid>
                                        <div className='row no-margin' style={{ display: 'flex' }}>
                                            <div className='col s5' style={{ paddingTop: '5px' }}>
                                                <QualityIndices
                                                    stationType={stationType}
                                                    analysis={qualitoDashboard?.stationsData?.find(s => s.stationId === qualitometer.id)?.analysis}
                                                />
                                            </div>
                                            <div className='col s7 no-padding'>
                                                <NewMoleculeDetectedChart quantifications={qualitoDashboard?.quantifications || []} />
                                            </div>
                                        </div>
                                        <Grid className='center-align no-margin' style={{ fontWeight: 'bold', fontSize: '14.3px' }}>{i18n.operationsHistory}</Grid>
                                        <OperationsCountChart />
                                    </div>
                                </Grid>
                                <Grid item xs={2}>
                                    <div className='col s12 no-padding'>
                                        <SmallPie data={dataQualifications} name={i18n.qualification} />
                                        <SmallPie data={dataStatus} name={i18n.status} />
                                        {/* {this.getNoncompliances([PARAMETER_NATURES.CHEMICAL, PARAMETER_NATURES.PHYSICAL], i18n.physicochemic, i18n.physicochemics)} */}
                                        {/* {this.getNoncompliances([PARAMETER_NATURES.MICROBIOLOGICAL], i18n.microbiological, i18n.microbiologicals)} */}
                                    </div>
                                </Grid>
                            </Grid>
                        </WhiteCard>
                        <PopupParameterAdministration
                            isOpen={isAdminOpen}
                            onClose={ (parametersHasBeenSaved) => {
                                if (parametersHasBeenSaved) {
                                    notLoaded()
                                    dispatch(QualityAction.fetchQualitoDashboard(qualitometer.id)).finally(() => {
                                        loaded()
                                        closeAdmin()
                                    })
                                } else {
                                    closeAdmin()
                                }
                            }}
                        />
                    </>
                )
            }
        </>
    )
}

DashboardQualitySituationPanel.propTypes = {
    stationType: PropTypes.string,
}

export default DashboardQualitySituationPanel
export {
    QualityIndices,
}
