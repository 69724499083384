import { H_ADMINISTRATION } from 'account/constants/AccessRulesConstants'
import AdministrationAction from 'administration/actions/AdministrationAction'
import FixedSlideNav from 'components/navbar/FixedSlideNav'
import BoundaryError from 'log/components/BoundaryError'
import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useParams } from 'react-router-dom'
import ContactAction from 'referencial/components/contact/actions/ContactAction'
import ContributorAction from 'referencial/components/contributor/actions/ContributorAction'
import i18n from 'simple-react-i18n'
import { componentHasHabilitations } from 'utils/HabilitationUtil'
import UserAction from '../actions/UserAction'
import { push } from '@lagunovsky/redux-react-router'

const UserRouterApp = () => {
    const dispatch = useDispatch()
    const { login } = useParams()

    useEffect(() => {
        if (!componentHasHabilitations(H_ADMINISTRATION)) { // A modifier quand react-router sera à jour
            dispatch(push('/unauthorized'))
            return
        }
    })

    const navLinks = useMemo(() => {
        return [
            {
                icons: 'contact_page_icon',
                name: i18n.file,
                href: `/administration/user/${login}/dashboard`,
                habilitation: H_ADMINISTRATION,
            },
            {
                icons: 'view_module_icon',
                name: i18n.modules,
                href: `/administration/user/${login}/modules`,
                habilitation: H_ADMINISTRATION,
            },
            {
                icons: 'approval_icon',
                name: i18n.access,
                href: `/administration/user/${login}/access`,
                habilitation: H_ADMINISTRATION,
            },
            {
                icons: 'map_icon',
                name: i18n.map,
                href: `/administration/user/${login}/map`,
                habilitation: H_ADMINISTRATION,
            },
            {
                icons: 'data_usage',
                name: i18n.statistics,
                href: `/administration/user/${login}/statistics`,
                habilitation: H_ADMINISTRATION,
            },
        ].filter(l => componentHasHabilitations(l.habilitation))
    }, [login])

    useEffect(() => {
        dispatch(UserAction.fetchApplicationHabilitations())
        dispatch(ContactAction.fetchContacts())
        dispatch(ContributorAction.fetchContributors())
    }, [dispatch, login])

    useEffect(() => {
        if (login !== 'new') {
            dispatch(UserAction.fetchUser(login))
            dispatch(AdministrationAction.fetchUserSettings(login))
            dispatch(AdministrationAction.fetchProfilePicture(login))
        }
    }, [dispatch, login])

    return (
        <div className='row no-margin'>
            <FixedSlideNav links={navLinks} />
            <main>
                <div className='row no-margin padding-left-1'>
                    <div className='content-page no-padding fullscreen'>
                        <BoundaryError>
                            <Outlet />
                        </BoundaryError>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default UserRouterApp
