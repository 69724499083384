/* eslint-disable camelcase */
import { push } from '@lagunovsky/redux-react-router'
import { compact, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { hasValue } from 'utils/NumberUtil'
import { getLabel } from '../../../utils/StoreUtils'
import { CardTable } from 'components/datatable/NewTable'
import { greyBlue } from 'utils/constants/ColorTheme'

const headers = ['name', 'status', 'begin', 'end', 'division']

const StationSamplersPanel = ({
    station = {},
}) => {
    const dispatch = useDispatch()

    const {
        exploitationsLight,
    } = useSelector(store => ({
        exploitationsLight: store.AgriReducer.exploitationsLight,
    }), shallowEqual)

    const statusOptions = [
        { value: 1, name: i18n.used },
        { value: 2, name: i18n.notUsed },
        { value: 3, name: i18n.closed },
    ]

    const data = orderBy(compact(station?.link_exploitationsAgri.map((linkExploit) => {
        const exploitation = exploitationsLight.find((e) => e.idExploitation === linkExploit.idExploitation)
        if (exploitation) {
            return {
                ...linkExploit,
                name: `${exploitation.name} [${exploitation.codification}]`,
                status: getLabel(statusOptions, linkExploit.stateCode, 'name', 'value'),
                begin: getDate(linkExploit.startDate),
                end: getDate(linkExploit.endDate),
                division: hasValue(linkExploit.distribution) ? `${linkExploit.distribution} %` : i18n.unknown,
            }
        }
        return null
    })), 'name')

    return (
        <div id='exploitations'>
            <CardTable
                title={i18n.samplers}
                rows={data}
                headers={headers}
                color={greyBlue}
                onClickRow={s => dispatch(push(`/dossiers/${s.idExploitation}/dashboard`))}
            />
        </div>
    )
}

StationSamplersPanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
}

export default StationSamplersPanel