/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { getSandreList } from '../../../../utils/StoreUtils'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getVisitCheckBoxChange, getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import Select from '../../../../components/forms/Select'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { Card, CardContent, Grid2 } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'

const InstallationComElecPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    station = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const onChangeComElec = (value) => {
        const comElec = station.link_elecComs[0] || {}
        onChange({
            link_elecComs: [{
                idStation: station.id,
                ...comElec,
                ...value,
            }],
        })
    }

    const comElec = station.link_elecComs[0] || {}

    return (
        <Card>
            <CardTitle title={i18n.communicationElectricity} />
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={comElec.remoteManagement}
                            label={i18n.remoteManagement}
                            onChange={v => {
                                onChangeComElec({ remoteManagement: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.remoteManagement, comElec.remoteManagement, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={comElec.supervision}
                            label={i18n.supervision}
                            onChange={v => {
                                onChangeComElec({ supervision: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.supervision, comElec.supervision, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleTextArea
                            value={comElec.remoteDescription}
                            title={i18n.remoteDescription}
                            onChange={v => {
                                onChangeComElec({ remoteDescription: v })
                                onChangeVisit({ previousValue: comElec.remoteDescription, newValue: v, field: i18n.remoteDescription })
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            value={comElec.supervisionDescription}
                            title={i18n.supervisionDescription}
                            onChange={v => {
                                onChangeComElec({ supervisionDescription: v })
                                onChangeVisit({ previousValue: comElec.supervisionDescription, newValue: v, field: i18n.supervisionDescription })
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <hr />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={comElec.communicationMode}
                            label={i18n.communicationMode}
                            keyvalue='code'
                            integerValue
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATION_MODE_TELECOM)}
                            onChange={v => {
                                onChangeComElec({ communicationMode: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATION_MODE_TELECOM, i18n.communicationMode, comElec.communicationMode, v))
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={comElec.transformer}
                            label={i18n.transformer}
                            keyvalue='code'
                            integerValue
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_TRANSFORMATEUR)}
                            onChange={v => {
                                onChangeComElec({ transformer: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TRANSFORMATEUR, i18n.transformer, comElec.transformer, v))
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Select
                            value={comElec.rescueDevice}
                            label={i18n.rescueDevice}
                            keyvalue='code'
                            integerValue
                            options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_SECOURS)}
                            onChange={v => {
                                onChangeComElec({ rescueDevice: v })
                                onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_SECOURS, i18n.rescueDevice, comElec.rescueDevice, v))
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <Input
                            title={i18n.alimentationWorking}
                            readMode
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={comElec.normalMode}
                            label={i18n.normalMode}
                            onChange={v => {
                                onChangeComElec({ normalMode: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.normalMode, comElec.normalMode, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={comElec.degradedMode}
                            label={i18n.degradedMode}
                            onChange={v => {
                                onChangeComElec({ degradedMode: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.degradedMode, comElec.degradedMode, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={comElec.prerequisiteInstallation}
                            label={i18n.prerequisiteInstallation}
                            onChange={v => {
                                onChangeComElec({ prerequisiteInstallation: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.prerequisiteInstallation, comElec.prerequisiteInstallation, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={comElec.availableSpace}
                            label={i18n.availableSpace}
                            onChange={v => {
                                onChangeComElec({ availableSpace: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.availableSpace, comElec.availableSpace, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={comElec.cableWitdh}
                            title={i18n.cableWitdh}
                            onChange={v => {
                                onChangeComElec({ cableWitdh: v })
                                onChangeVisit({ previousValue: comElec.cableWitdh, newValue: v, field: i18n.cableWitdh })
                            }}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={comElec.dimmer}
                            label={i18n.dimmers}
                            onChange={v => {
                                onChangeComElec({ dimmer: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.dimmers, comElec.dimmer, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={comElec.starter}
                            label={i18n.starters}
                            onChange={v => {
                                onChangeComElec({ starter: v })
                                onChangeVisit(getVisitCheckBoxChange(i18n.starters, comElec.starters, v))
                            }}
                            disabled={readMode}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

InstallationComElecPanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default InstallationComElecPanel
