import { push } from '@lagunovsky/redux-react-router'
import { isNil, minBy, orderBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../../components/datatable/Table'
import PiezometerAccessibilitieDto from '../../../../piezometry/dto/PiezometerAccessibilitieDto'
import DtoQualitometerLight from '../../../../quality/dto/DtoQualitometerLight'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import ContactDto from '../../../../referencial/components/contact/dto/ContactDto'
import { nbPerPageLabel } from '../../../../referencial/constants/ReferencialConstants'
import { hasValue } from '../../../../utils/NumberUtil'
import { getLabel } from '../../../../utils/StoreUtils'
import Icon from 'components/icon/Icon'

const hasLocalisationError = (x, y, projection) => {
    const count = isNil(x) + isNil(y) + isNil(projection) // add boolean give number
    return count === 1 || count === 2
}

const ListQualityCampaignStation = ({
    editMode = false,
    onDeleteStation = () => { },
    accessibilities = [],
    stations = [],

    cities = [],
    contacts = [],

    redirect = () => { },
}) => {
    const getMonitoringIcon = ({ monitoring }) => monitoring !== 0 && (
        <i className='material-icons small blue-text tooltipped clickable' data-tooltip={i18n.monitoredStation}>notifications</i>
    )

    const getPlanIcon = ({ toPlanEventLength }) => toPlanEventLength !== 0 && (
        <i className='material-icons small blue-text tooltipped clickable' data-tooltip={i18n.toPlan}>assignment</i>
    )

    const getContact = contact => {
        if (contact) {
            const postalCode = contact.postalCode ? `${contact.postalCode}, ` : ''
            const tooltip = ReactDOMServer.renderToString(
                <div className='row no-margin left-align'>
                    <h6>{contact.name}</h6>
                    <h6>{contact.address}</h6>
                    <h6>{postalCode + getLabel(cities, contact.cityCode)}</h6>
                    <h6>{contact.phoneTel || contact.mobile}</h6>
                </div>,
            )
            return (
                <div className='row no-margin valign-wrapper tooltipped' data-tooltip={tooltip}>
                    <i className='material-icons'>account_circle</i>
                    {contact.phoneTel || contact.mobile ? <i className='material-icons'>call</i> : null}
                    {contact.name}
                </div>
            )
        }
        return null
    }

    const getAccessibilitieDescription = access => {
        const descriptions = access.filter(a => a.description).map(a => <h6>{a.description}</h6>)
        const foundDescription = access.find(a => a.description)
        const firstDescription = (foundDescription && foundDescription.description) || ''
        if (descriptions.length) {
            const tooltip = ReactDOMServer.renderToString(
                <div className='row no-margin left-align'>
                    {descriptions}
                </div>,
            )
            return (
                <div className='row no-margin valign-wrapper tooltipped' data-tooltip={tooltip}>
                    <i className='material-icons small'>directions</i>
                    {firstDescription.length > 80 ? `${firstDescription.slice(0, 77)}...` : firstDescription}
                </div>
            )
        }
        return null
    }

    const selectedStations = stations.map(station => {
        const stationAccess = accessibilities.filter(access => access.id === station.id)
        const access = stationAccess.length && stationAccess.find(a => hasValue(a.order)) ? minBy(stationAccess, 'order') : (stationAccess.length ? stationAccess[0] : null)
        const contact = contacts.find(c => c.id === (access && access.contactCode ? access.contactCode : station.contactCode))
        return {
            id: station.id,
            nullValue2: hasLocalisationError(station.x, station.y, station.projection) ? <Icon size='small' icon='warning' tooltip={i18n.localisationIncomplete} /> : '',
            monitoringEvents: station.monitoringEvents,
            toPlanEvents: station.toPlanEvents,
            nullValue: [getMonitoringIcon(station), getPlanIcon(station)],
            code: {
                value: station.code,
                color: 'white',
                onClick: () => redirect(`/station/quality/${station.id}`),
            },
            city: station.townCode ? `${getLabel(cities, station.townCode)} [${station.townCode}]` : null,
            name: station.name,
            contact: getContact(contact),
            exportContact: contact ? [contact.name || '', contact.address, contact.postalCode, getLabel(cities, contact.cityCode), contact.phoneTel || contact.mobile].filter(str => !!str).join(', ') : '',
            estimated: station.previsionalVisitNumber,
            access: getAccessibilitieDescription(stationAccess),
            exportAccess: orderBy(stationAccess, 'order').map(a => a.description).join(', '),
        }
    })

    return (
        <Table
            showTitle={false}
            data={selectedStations}
            type={{ headers: ['nullValue2', 'code', 'city', 'name', 'contact', 'access', 'nullValue'] }}
            sortable
            deletable={editMode}
            onDelete={onDeleteStation}
            condensed
            paging
            nbPerPageLabel={nbPerPageLabel}
        />
    )
}

ListQualityCampaignStation.propTypes = {
    editMode: PropTypes.bool,
    onDeleteStation: PropTypes.func,
    accessibilities: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.instanceOf(PiezometerAccessibilitieDto),
    ])),
    stations: PropTypes.arrayOf(PropTypes.instanceOf(DtoQualitometerLight)),

    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),

    redirect: PropTypes.func,
}

const mapStateToProps = store => ({
    cities: store.CityReducer.cities,
    contacts: store.ContactReducer.contacts,
})

const mapDispatchToProps = {
    redirect: push,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListQualityCampaignStation)