import { Accordion, AccordionDetails, Grid2 } from '@mui/material'
import React, { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import LinkedStationsPanel from 'station/components/associatedStation/LinkedStationsPanel'
import StationDescription from 'station/components/description/StationDescription'
import { getStationTitle } from 'utils/StationUtils'
import i18n from 'simple-react-i18n'
import useTitle from 'utils/customHook/useTitle'
import { STATION_QUALITOMETER_NAMES, STATION_TYPE_NAME } from 'station/constants/StationConstants'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import { OPTIONS } from 'resource/constants/ResourceConstants'
import { CardFollowUpNitrates } from 'quality/components/qualityComponents/FollowUpNitrates'
import { CardFollowUpPesticides } from 'quality/components/qualityComponents/FollowUpPesticides'
import { getBeginingOfTheYear, getEndOfTheYear } from 'utils/DateUtil'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'
import { AccordionTitle } from 'components/styled/Accordions'
import { groupBy, keys, uniqBy } from 'lodash'
import UnitQualityPanel from 'productionUnit/components/UnitQualityPanel'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import FollowUpPluvio from 'catchment/components/catchment/dashboard/pluviometry/FollowUpPluvio'
import FollowUpPiezo from 'catchment/components/catchment/dashboard/piezometry/FollowUpPiezo'
import FollowUpHydro from 'catchment/components/catchment/dashboard/hydrometry/FollowUpHydro'

const NewUnitQualityPanel = ({
    ids = [],
    expanded = false,
    setExpanded = () => {},
}) => {
    const {
        qualitometers,
        qualityIndicators,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        qualityIndicators: store.QualityReducer.qualityIndicators,
    }), shallowEqual)

    const groupIds = groupBy(ids, id => qualitometers.find(q => q.id === id)?.stationType ?? 0)

    return (
        <Accordion expanded={expanded} onChange={setExpanded}>
            <AccordionTitle title={i18n.quality} color='white' fontColor='black'/>
            <AccordionDetails>
                {keys(groupIds).map((stationType, i) => {
                    const foundIndicators = qualityIndicators.find(q => `${q.stationType}` === stationType)?.indicators || []
                    const thresholds = uniqBy(foundIndicators, 'threshold').map(indicator => `${indicator.threshold}`)

                    const title = STATION_QUALITOMETER_NAMES.find(t => `${t.code}` === stationType)?.name
                    return (
                        <UnitQualityPanel
                            key={stationType}
                            ids={groupIds[stationType]}
                            additionalTitle={title ? `${title}: ` : ''}
                            thresholds={thresholds}
                            defaultExpanded={i === 0}
                        />
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}

NewUnitQualityPanel.propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number),
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
}

const ResourceDashboardApp = () => {
    const { id } = useParams()

    const {
        resource,
        associatedSites,
        pluviometers,
        piezometers,
        hydrometricStations,
    } = useSelector(store => ({
        resource: store.ResourceReducer.resource,
        associatedSites: store.StationReducer.associatedSites,
        pluviometers: store.PluviometryReducer.pluviometers,
        piezometers: store.PiezometryReducer.piezometersLight,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
    }), shallowEqual)

    const [dashboardDisplay, setDashboardDisplay] = useLocalStorage('DASHBOARD_DISPLAY', {})

    const startPeriod = useApplicationSetting(OPTIONS.RESOURCE_START_PERIOD, setting => setting ? getBeginingOfTheYear(parseInt(setting)) : undefined)
    const endPeriod = useApplicationSetting(OPTIONS.RESOURCE_END_PERIOD, setting => setting ? getEndOfTheYear(parseInt(setting)) : undefined)
    const nitratesThreshold = useApplicationSetting(OPTIONS.RESOURCE_NITRATES_THRESHOLD, setting => setting ? parseFloat(setting) : undefined)
    const pesticideThreshold1 = useApplicationSetting(OPTIONS.RESOURCE_PESTICIDE_THRESHOLD_1, setting => setting ? parseFloat(setting) : undefined)
    const pesticideThreshold2 = useApplicationSetting(OPTIONS.RESOURCE_PESTICIDE_THRESHOLD_2, setting => setting ? parseFloat(setting) : undefined)
    const pesticides = useApplicationSetting(OPTIONS.RESOURCE_PESTICIDES, setting => setting?.split(',').filter(c => !!c) || [])
    const listSumPesticides = useApplicationSetting(OPTIONS.RESOURCE_LIST_SUM_PESTICIDES, setting => {
        const list = setting?.split(',').filter(p => !!p)
        if (list?.length) {
            return list
        }
        return pesticides
    })
    const excludeProducer = useApplicationSetting(OPTIONS.RESOURCE_PRODUCERS_EXCLUDED, setting => setting?.split(',').filter(c => !!c).map(idString => parseInt(idString)) || [])

    useTitle(() => [{
        title: i18n.resource,
        href: 'resource',
    }, {
        title: getStationTitle(resource),
        href: `station/resource/${id}`,
    }], [resource, id])

    const qualitometerIds = useMemo(() => {
        return associatedSites?.filter(a => a?.typeName === STATION_TYPE_NAME.quality)?.map(q => q?.stationLinkedId) ?? []
    }, [associatedSites])

    const hydrometricStationsLinked = useMemo(() => {
        return associatedSites?.filter(a => a?.typeName === STATION_TYPE_NAME.hydrometry)?.map(link => {
            return hydrometricStations.find(hydro => hydro.id === link?.stationLinkedId)
        }).filter(q => !!q) || []
    }, [associatedSites, hydrometricStations])

    const piezometersLinked = useMemo(() => {
        return associatedSites?.filter(a => a?.typeName === STATION_TYPE_NAME.piezometry)?.map(link => {
            return piezometers.find(piezo => piezo.id === link?.stationLinkedId)
        }).filter(q => !!q) || []
    }, [associatedSites, piezometers])

    const pluviometersLinked = useMemo(() => {
        return associatedSites?.filter(a => a?.typeName === STATION_TYPE_NAME.pluviometry)?.map(link => {
            return pluviometers.find(pluvio => pluvio.id === link?.stationLinkedId)
        }).filter(q => !!q) || []
    }, [associatedSites, pluviometers])

    return (
        <Grid2 container sx={{ padding: '5 5 100 5' }} spacing={1} alignItems='flex-start'>
            <Grid2 container size={9}>
                <Grid2 size={12}>
                    <StationDescription
                        id={id}
                        stationTypes={['resource']}
                        noOwner
                    />
                </Grid2>
                {pluviometersLinked.length > 0 && (
                    <Grid2 size={12}>
                        <FollowUpPluvio
                            pluviometersLinked={pluviometersLinked}
                            stationConstant='RESOURCE'
                            expanded={dashboardDisplay.RESOURCE?.PLUVIO}
                            setExpanded={(_, v) => setDashboardDisplay(prev => ({ ...prev, RESOURCE: { ...prev.RESOURCE, PLUVIO: v } }))}
                        />
                    </Grid2>
                )}
                {piezometersLinked.length > 0 && (
                    <Grid2 size={12}>
                        <FollowUpPiezo
                            piezometersLinked={piezometersLinked}
                            stationConstant='RESOURCE'
                            expanded={dashboardDisplay.RESOURCE?.PIEZO}
                            setExpanded={(_, v) => setDashboardDisplay(prev => ({ ...prev, RESOURCE: { ...prev.RESOURCE, PIEZO: v } }))}
                        />
                    </Grid2>
                )}
                {hydrometricStationsLinked.length > 0 && (
                    <Grid2 size={12}>
                        <FollowUpHydro
                            hydrometricStationsLinked={hydrometricStationsLinked}
                            stationConstant='RESOURCE'
                            expanded={dashboardDisplay.RESOURCE?.HYDRO}
                            setExpanded={(_, v) => setDashboardDisplay(prev => ({ ...prev, RESOURCE: { ...prev.RESOURCE, HYDRO: v } }))}
                        />
                    </Grid2>
                )}
                {qualitometerIds.length > 0 && (
                    <Grid2 size={12}>
                        <NewUnitQualityPanel
                            ids={qualitometerIds}
                            expanded={dashboardDisplay.RESOURCE?.QUALI}
                            setExpanded={(_, v) => setDashboardDisplay(prev => ({ ...prev, RESOURCE: { ...prev.RESOURCE, QUALI: v } }))}
                        />
                    </Grid2>
                )}
            </Grid2>
            <Grid2 size={3}>
                <LinkedStationsPanel
                    onReMount={() => { }}
                    dashboardMode
                    checkable
                    changeUncheckedStations={() => {}}
                    station={resource}
                    withColors
                />
            </Grid2>
            {
                !!qualitometerIds?.length && (
                    <Grid2 size={12}>
                        <CardFollowUpNitrates
                            stationIds={qualitometerIds}
                            startPeriod={startPeriod}
                            endPeriod={endPeriod}
                            producerExcluded={excludeProducer}
                            nitratesThreshold={nitratesThreshold}
                        />
                    </Grid2>
                )
            }
            {
                !!qualitometerIds?.length && (
                    <Grid2 size={12}>
                        <CardFollowUpPesticides
                            stationIds={qualitometerIds}
                            startPeriod={startPeriod}
                            endPeriod={endPeriod}
                            producerExcluded={excludeProducer}
                            threshold1={pesticideThreshold1}
                            threshold2={pesticideThreshold2}
                            pesticides={pesticides}
                            listSumPesticides={listSumPesticides}
                        />
                    </Grid2>
                )
            }
        </Grid2>
    )
}

export default ResourceDashboardApp