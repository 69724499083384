/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import DtoSandreCode from '../../../../referencial/dto/DtoSandreCode'
import { getSandreLabel, searchAllCharacters } from '../../../../utils/StringUtil'
import { getDate } from '../../../../utils/DateUtil'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import useSandreList from 'utils/customHook/useSandreList'
import { Card, CardContent, Grid2 } from '@mui/material'
import { compact, orderBy } from 'lodash'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import Select from 'components/forms/Select'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { shallowEqual, useSelector } from 'react-redux'
import { hasValue } from 'utils/NumberUtil'

const headers = ['name', 'installation', 'withdrawal', 'comment']

const EquipmentDialogContent = ({
    stateElement,
    onChangeElement = () => { },
    availableEquipments = [],
    sandreList = [],
}) => {
    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 container size={12}>
                        <Grid2 size={6}>
                            <Select
                                value={stateElement.equipmentCode}
                                label={i18n.name}
                                options={hasValue(stateElement.index) ? sandreList : orderBy(availableEquipments, sandreCode => searchAllCharacters(sandreCode.name))}
                                onChange={v => onChangeElement({ equipmentCode: v })}
                                integerValue
                                disabled={hasValue(stateElement.index)}
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            id='startDate'
                            label={i18n.installation}
                            onChange={v => onChangeElement({ startDate: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            id='endDate'
                            label={i18n.withdrawal}
                            onChange={v => onChangeElement({ endDate: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={stateElement.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

EquipmentDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
    availableEquipments: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    sandreList: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

const InstallationEquipmentsPanel = ({
    station = {},
    readMode = false,
    sandreCode = '',
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const sandreList = useSandreList(sandreCode)

    const availableEquipments = compact(sandreList.map(element => {
        return station.link_equipments.some(le => element.code === le.equipmentCode) ? null : element
    }))

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_equipments'
            formatFunction={ie => ({
                ...ie,
                installation: getDate(ie.startDate),
                withdrawal: getDate(ie.endDate),
                name: getSandreLabel(sandreCodes, sandreCode, ie.equipmentCode),
            })}
            titles={{
                title: i18n.equipmentList,
                edit: i18n.equipment,
                new: i18n.newEquipment,
            }}
            headers={headers}
            dialogContent={props => <EquipmentDialogContent {...props} availableEquipments={availableEquipments} sandreList={sandreList} />}
        />
    )
}

InstallationEquipmentsPanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
    sandreCode: PropTypes.string.isRequired,
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default InstallationEquipmentsPanel