import { shallowEqual, useSelector } from 'react-redux'
import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CircularProgress, Grid2 } from '@mui/material'
import { getSiteUrl } from '../../../../../utils/mapUtils/SiteTypes'
import { STATION_NAME_ASSOCIATION } from '../../../../../station/constants/StationConstants'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../../components/forms/Checkbox'
import { getDate } from '../../../../../utils/DateUtil'
import PiezometerStationAction from '../../../../../station/actions/PiezometerStationAction'
import HydrometryAction from '../../../../../hydrometry/actions/HydrometryAction'
import PluviometryAction from '../../../../../pluviometry/actions/PluviometryAction'
import { groupBy, unzip, flatten, orderBy, round, keys } from 'lodash'
import { execByType } from '../../../../../utils/StationUtils'
import { MEASURE_COTE } from '../../../../constants/PiezometryConstants'
import Button from '../../../../../components/forms/Button'
import Row from '../../../../../components/react/Row'
import Line from '../../../../../components/echart/series/Line'
import { getColorFromPalette, getThresholdColorHtml } from '../../../../../utils/ColorUtil'
import Bar from '../../../../../components/echart/series/Bar'
import PiezometryAction from '../../../../actions/PiezometryAction'
import NumberField from '../../../../../components/forms/NumberField'
import Select from '../../../../../components/forms/Select'
import DtoPiezometer from '../../../../dto/DtoPiezometer'
import { instanceOf } from '../../../../../utils/StoreUtils'
import { WhiteCard } from 'components/styled/Card'

import {
    CUMUL_PERSO_AVERAGE,
    CUMUL_PERSO_MAX,
    CUMUL_PERSO_MIN,
    CUMUL_PERSO_SUM,
} from '../../../../../pluviometry/constants/PluvioOptionConstant'
import { LINKEDSTATIONS } from '../../constants/PiezometerSuiviConstants'
import { StyledFieldSet, StyledLegend } from '../../../../../components/StyledElements'
import RadioButtons from '../../../../../components/forms/RadioButtons'
import { DAY, HOUR } from '../../../../../quality/constants/ChartConstant'

const getPromise = (stationType) => execByType(stationType, {
    piezometry: () => PiezometerStationAction.promisePiezoChartMeasures,
    hydrometry: () => HydrometryAction.promiseHydroChronicMeasures,
    pluviometry: () => PluviometryAction.promisePluvioChronicMeasures,
})

const getGroups = () => [
    { code: 'MAX', name: 'Max' },
    { code: 'MIN', name: 'Min' },
    { code: 'AVERAGE', name: i18n.mean },
    { code: 'SUM', name: i18n.sum },
    { code: 'all', name: i18n.brute },
]

const PiezoSuiviAssociationsTab2 = ({
    tab,
    displayCote,
    landmarkValue, // sers à caluler la profondeur : depth = landmarkValue - NGF
    changeParent, // met à jour les state du parent (dont les séries liées à cette tab)
    minDate,
    maxDate,
    piezometer,
    time,
    typeId: typeIdSelected,
}) => {
    const {
        associatedSites,
    } = useSelector(store => ({
        associatedSites: store.StationReducer.associatedSites,
    }), shallowEqual)

    const [stats, setStats] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false)

    const [statMin, setStatMin] = useState({})
    const [statMax, setStatMax] = useState({})
    const [pluvioMin, setPluvioMin] = useState(undefined)
    const [pluvioMax, setPluvioMax] = useState(undefined)
    const [statGroup, setStatGroup] = useState({})
    const changeStat = (func, obj, key, v) => func({ ...obj, [key]: v })
    const [allStatObj, setAllStatObj] = useState({})

    const [stations, setStations] = useState([])
    const [readyApply, setReadyApply] = useState(false)
    const [preselectedAssociations, setPreselected] = useState([])
    const [selectedAssociations, setSelected] = useState([])
    const changeSelected = (key, v) => {
        setPreselected(v ? [...preselectedAssociations, key] : preselectedAssociations.filter(key2 => key2 !== key))
        setReadyApply(true)
    }
    const applyChanges = () => {
        setReadyApply(false)
        setAllStatObj({ statMin, statMax, statGroup })
        setSelected(preselectedAssociations)
    }
    const [chartLandmarks, setChartLandmarks] = useState([])

    const [axisPluvio, setAxisPluvio] = useState(true)
    const [nbTime, setNbTime] = useState(1)
    const [dayOrHour, setDayOrHour] = useState(DAY)
    const [cumul, setCumul] = useState(CUMUL_PERSO_SUM)
    const optionCumul = useMemo(() => [
        { value: CUMUL_PERSO_MAX, label: i18n.max },
        { value: CUMUL_PERSO_MIN, label: i18n.min },
        { value: CUMUL_PERSO_SUM, label: i18n.sum },
        { value: CUMUL_PERSO_AVERAGE, label: i18n.average },
        { value: 'all', label: i18n.brute },
    ], [])
    const optionDayHour = [
        { value: HOUR, label: i18n.hour },
        { value: DAY, label: i18n.day },
    ]

    const toEchartMeasure = (m, lastLandmark, unit) => ({ value: [m[0], displayCote === MEASURE_COTE.NGF ? m[1] : lastLandmark - m[1], { NGF: m[1], depth: lastLandmark - m[1] }, m[2], m[3]], isPiezo: true, unit: unit ?? undefined })

    useEffect(() => {
        const thisStation = { typeName: 'piezometry', stationLinkedCode: piezometer.code, stationLinkedName: piezometer.name, stationLinkedId: piezometer.id }
        const piezos = [ thisStation, ...associatedSites.filter(ass => ass.typeName === 'piezometry') ]
        const piezoPromises = piezos.map(ass => PiezometerStationAction.promisePiezoMeasuresStats(ass.stationLinkedId))
        const hydros = associatedSites.filter(ass => ass.typeName === 'hydrometry')
        const hydrosPromises = hydros.map(ass => HydrometryAction.promiseHydroStatistics(ass.stationLinkedId))
        const pluvios = associatedSites.filter(ass => ass.typeName === 'pluviometry')
        const pluviosPromises = pluvios.map(ass => PluviometryAction.promisePluviometerMeasuresStats(ass.stationLinkedId))
        Promise.all([...piezoPromises, ...hydrosPromises, ...pluviosPromises]).then(jsonTab => {
            setStats(jsonTab)
            setStations([...piezos, ...hydros, ...pluvios])
            setDataLoaded(true)
            PiezometryAction.promisePiezometerChartLandmarks(piezos.map(ass => ass.stationLinkedId)).then(res => setChartLandmarks(res))
        })
    }, [])

    const getThresholds = (cb) => {
        const otherStations = selectedAssociations.filter(key => key.split(':')[2] !== '-1')
        if (!otherStations.length) {
            cb([])
        } else {
            const promises = otherStations.map(key => {
                // eslint-disable-next-line no-unused-vars
                const [stationType, id, _] = key.split(':')
                switch (stationType) {
                    case 'piezometry':
                        return PiezometerStationAction.promisePiezometerThresholds(id)
                    case 'hydrometry':
                        return HydrometryAction.promiseHydroThresholds(id)
                    default:
                        return PluviometryAction.promisePluviometerThresholds(id)
                }
            })
            Promise.all(promises).then(jsonTab => {
                cb(otherStations.reduce((acc, key, idx) => {
                    acc[key] = jsonTab[idx]
                    return acc
                }, {}))
            })
        }
    }

    useEffect(() => {
        const grouped = groupBy(selectedAssociations, key => key.split(':')[0])
        const [promises, infos] = unzip(Object.keys(grouped).flatMap(stationType => {
            return grouped[stationType].map(key => {
                const [, stationId, typeId, codepoint, label, isPiezo] = key.split(':')
                const groupFunc = allStatObj.statGroup[key] ?? (parseInt(typeId) === -2 || (stationType === 'pluviometry' && parseInt(typeId) === 1) ? 'SUM' : 'MAX')
                const input = {
                    stationId: parseInt(stationId),
                    displayCote: parseInt(typeId) === -1 || isPiezo === 'true' ? MEASURE_COTE.NGF : null,
                    dataType: parseInt(typeId),
                    codepoint: parseInt(codepoint),
                    startDate: minDate,
                    endDate: maxDate,
                    chartMode: true,
                    groupFunc,
                    group: groupFunc,
                    label,
                    link: stations.find(s => s.typeName === stationType && s.stationLinkedId === parseInt(stationId)),
                    isPiezo: isPiezo === 'true',
                    stationType,
                    key,
                }
                if (stationType === 'pluviometry' && input.dataType === 1) {
                    const pluvioInput = { ...input, groupFunc: `${cumul}_${nbTime * (dayOrHour === DAY ? 24 : 1)}`, group: `${cumul}_${nbTime * (dayOrHour === DAY ? 24 : 1)}` }
                    return [getPromise(stationType)(pluvioInput), pluvioInput]
                }
                return [getPromise(stationType)(input), input]
            })
        }))
        Promise.all(promises || []).then(jsonTab => {
            getThresholds(thresholds => {
                const [associationsSeries, associationsAxis] = jsonTab.reduce(([series, axis], measures, idx) => {
                    const { label, isPiezo, link, stationType, dataType, codepoint, stationId, key } = infos[idx]
                    const isPluvio = stationType === 'pluviometry' && dataType === 1
                    const isFirstAxis = (isPiezo && stationId === piezometer.id) || dataType === -1
                    const axisName = label // + (isPiezo ? ` [${link.stationLinkedCode}]` : '')
                    const statFound = flatten(stats).find(s => s.typeId === dataType && s.label === label && s.codepoint === codepoint)
                    const hasOtherPoint = stats.some(s => statFound.typeId === s.typeId && statFound.codepoint !== s.codepoint)
                    const newAxis = axis.some(a => a.name === label) || isFirstAxis ? [] : [{
                        name: axisName,
                        isPluvio: axisPluvio ? isPluvio : false,
                        isPiezo,
                        min: (isPluvio ? (pluvioMin ?? 0) : undefined) ?? allStatObj.statMin?.[key],
                        max: (isPluvio && pluvioMax) ?? allStatObj?.statMax?.[key],
                        dontUseBandCorrection: true,
                        unit: statFound?.unit ?? '',
                    }]
                    const thresholdPart = dataType !== -1 ? (() => {
                        const found = thresholds[key].filter(t => parseInt(t.dataType) === dataType && parseInt(t.code) === stationId)
                        if (found.length) {
                            return {
                                markLine: { data: found.map(t => ({
                                    yAxis: round(t.value, 2),
                                    position: 'middle',
                                    lineStyle: {
                                        color: getThresholdColorHtml(t),
                                    },
                                    label: {
                                        show: true,
                                        position: 'middle',
                                        formatter: () => `${t.name ? `${t.name} : ${round(t.value, 2)}` : ''} [${statFound?.unit ?? ''}]`,
                                    },
                                })), symbol: 'none' },
                            }
                        }
                        return {}
                    })() : {}
                    if (!measures.length) {
                        if (!keys(thresholdPart).length) {
                            return [series, [...axis, ...newAxis]]
                        }
                        const emptySerie = Line({
                            data: [],
                            axisName,
                            isPiezo,
                            dontUseBandCorrection: !isFirstAxis,
                            isFirstAxis,
                            ...thresholdPart,
                        })
                        return [[...series, emptySerie], [...axis, ...newAxis]]
                    }
                    if (isPluvio) {
                        const pluvioSerie = Bar({
                            // data: measures.map(m => ({ value: m, unit: statFound?.unit ?? undefined })),
                            data: measures.map(m => ({ value: [getDate(m[0], 'DD/MM/YYYY'), m[1], statFound?.unit, m], unit: statFound?.unit })),
                            name: `${label} [${link.stationLinkedCode}] ${link.stationLinkedName}${hasOtherPoint ? ` - ${statFound.namePoint}` : ''}`,
                            isPluvio,
                            dontUseBandCorrection: true,
                            itemStyle: {
                                normal: {
                                    color: statFound?.color || getColorFromPalette(idx),
                                },
                            },
                            axisName,
                            xAxisIndex: 1,
                            ...thresholdPart,
                        })
                        return [[...series, pluvioSerie], [...axis, ...newAxis]]
                    }
                    const newSerie = (isPluvio || dataType === -2 ? Bar : Line)({
                        data: isPiezo ? measures.map(m => toEchartMeasure(m, chartLandmarks.find(c => c.id === stationId)?.lastlandmark || landmarkValue)) : measures.map(m => ({ value: m, unit: statFound?.unit ?? undefined })),
                        name: `${label} [${link.stationLinkedCode}] ${link.stationLinkedName}${hasOtherPoint ? ` - ${statFound.namePoint}` : ''}`,
                        isPiezo,
                        dontUseBandCorrection: !isFirstAxis,
                        connectNulls: false,
                        showSymbol: false,
                        lineStyle: {
                            normal: {
                                color: statFound?.color || getColorFromPalette(idx),
                                width: statFound?.lineWidth || 2,
                                type: statFound?.lineType || 'solid',
                                opacity: statFound?.lineOpacity || 1,
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: statFound?.color || getColorFromPalette(idx),
                            },
                        },
                        axisName,
                        isFirstAxis,
                        ...thresholdPart,
                    })
                    return [[...series, newSerie], [...axis, ...newAxis]]
                }, [[], []])
                changeParent({ associationsSeries, associationsAxis, selectedAssociations })
            })
        })
    }, [selectedAssociations, allStatObj, time])

    useEffect(() => {
        setPreselected([])
        setSelected([])
    }, [typeIdSelected])

    if (tab !== LINKEDSTATIONS) {
        return null
    }

    if (!dataLoaded) {
        return <Grid2 container justifyContent='center' alignItems='center'><CircularProgress/></Grid2>
    }

    return (
        <>
            {
                !!stations.filter(f => f.stationType === 1).length &&
                <WhiteCard title={`${i18n.associatedStations} ${i18n.and} ${i18n.additionalData}`} sx={{ width: '100%' }}>
                    <div className='padding-1'>
                        <StyledFieldSet>
                            <StyledLegend>{i18n.rainDataSettings}</StyledLegend>
                            <Grid2 container justifyContent='center' alignItems='center' spacing={2}>
                                <Grid2 size={1}>
                                    <img src={getSiteUrl(STATION_NAME_ASSOCIATION.pluviometer)} style={{ maxHeight: '30px' } } />
                                </Grid2>
                                <Grid2 size={2}>
                                    <h6>{i18n.pluviometry}</h6>
                                </Grid2>
                                <Grid2 size={3}>
                                    <Select
                                        options={optionCumul}
                                        label={i18n.modeCumul}
                                        value={cumul}
                                        onChange={setCumul}
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <NumberField
                                        title={dayOrHour === DAY ? i18n.numberDays : i18n.numberHours}
                                        value={nbTime}
                                        onChange={setNbTime}
                                        min={0}
                                    />
                                </Grid2>
                                <Grid2 size={3}>
                                    <RadioButtons
                                        colOption={6}
                                        label={i18n.modeDaysHours}
                                        elements={optionDayHour}
                                        selected={dayOrHour}
                                        onChange={setDayOrHour}
                                    />
                                </Grid2>
                            </Grid2>
                            <Grid2 container justifyContent='center' alignItems='center' spacing={2}>
                                <Grid2 size={4}>
                                    <div className='padding-left-10'>{i18n.chartScale}</div>
                                </Grid2>
                                <Grid2 size={2}>
                                    <NumberField
                                        value={pluvioMin}
                                        title='Min'
                                        onChange={setPluvioMin}
                                    />
                                </Grid2>
                                <Grid2 size={2}>
                                    <NumberField
                                        value={pluvioMax}
                                        title='Max'
                                        onChange={setPluvioMax}
                                    />
                                </Grid2>
                                <Grid2 size={4}>
                                    <Checkbox
                                        checked={axisPluvio}
                                        label={'Histograme en haut'}
                                        onChange={() => setAxisPluvio(!axisPluvio)}
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </div>
                </WhiteCard>
            }
            {
                stations.map((station, idx) => {
                    const statsPanel = orderBy(stats[idx], 'order').map(originalStat => {
                        const sStat = originalStat.typeId === -1 && station.typeName === 'pluviometry' ? { ...originalStat, countTotal: i18n.calculatedMeasures } : originalStat
                        const key = `${station.typeName}:${station.stationLinkedId}:${sStat.typeId}:${sStat.codepoint}:${sStat.label}:${sStat.isPiezo || sStat.typeId === -1 || false}`
                        const hasOtherPoint = stats[idx].some(s => originalStat.typeId === s.typeId && originalStat.codepoint !== s.codepoint)
                        const disabled = (originalStat.typeId === -1 && station.stationLinkedId === piezometer.id) || // c'est la station et le typeId sélectionné
                            (station.stationType === 1 && originalStat.typeId === 1) ? { disabled: true } : {} // c'est de la pluie
                        return (
                            <StyledFieldSet key={key}>
                                <StyledLegend>{`${sStat.label} ${hasOtherPoint ? `(${sStat.namePoint})` : ''}`}</StyledLegend>
                                <Grid2 container justifyContent='center' alignItems='center' spacing={1}>
                                    <Grid2 size={1}>
                                        <Checkbox checked={preselectedAssociations.includes(key)} onChange={v => changeSelected(key, v) } disabled={originalStat.typeId === typeIdSelected && station.stationLinkedId === piezometer.id}/>
                                    </Grid2>
                                    <Grid2 size={3}>
                                        <Grid2 container justifyContent='center' alignItems='center' spacing={0}>
                                            <h6 style={{ margin: 1, fontSize: '1rem' }}>{`${sStat.countTotal} ${originalStat.typeId === -1 && station.typeName === 'pluviometry' ? '' : i18n.measures}`}</h6>
                                            <h6 style={{ margin: 1, fontSize: '1rem' }}>{`${i18n.fromDate} ${getDate(sStat.startDate)}`}</h6>
                                            <h6 style={{ margin: 1, fontSize: '1rem' }}>{`${i18n.to} ${getDate(sStat.endDate)}`}</h6>
                                        </Grid2>
                                    </Grid2>
                                    <Grid2 size={2}>
                                        <NumberField
                                            value={statMin[key]}
                                            title='Min'
                                            onChange={v => changeStat(setStatMin, statMin, key, v)}
                                            {...disabled}
                                        />
                                    </Grid2>
                                    <Grid2 size={2}>
                                        <NumberField
                                            value={statMax[key]}
                                            title='Max'
                                            onChange={v => changeStat(setStatMax, statMax, key, v)}
                                            {...disabled}
                                        />
                                    </Grid2>
                                    <Grid2 size={4}>
                                        <Select
                                            options={getGroups()}
                                            label={i18n.dailyRegroup}
                                            value={statGroup[key] ?? (parseInt(sStat.typeId) === -2 || (station.typeName === 'pluviometry' && parseInt(sStat.typeId) === 1) ? 'SUM' : 'MAX')}
                                            onChange={v => changeStat(setStatGroup, statGroup, key, v)}
                                            {...disabled}
                                        />
                                    </Grid2>
                                </Grid2>
                            </StyledFieldSet>
                        )
                    })
                    return (
                        <Grid2 container justifyContent='center' alignItems='center' spacing={3} className='margin-top-1' key={`${station.stationLinkedCode} - ${station.stationLinkedName}`}>
                            <Card sx={{ width: '100%' }} elevation={10}>
                                <CardContent elevation={10}>
                                    <Grid2 container justifyContent='center' alignItems='center' spacing={3}>
                                        <Grid2 size={1}>
                                            <img src={getSiteUrl(STATION_NAME_ASSOCIATION[station.typeName])} style={{ maxHeight: '30px' } } />
                                        </Grid2>
                                        <Grid2 size={2}>
                                            <h6>{i18n[station.typeName]}</h6>
                                        </Grid2>
                                        <Grid2 size={8}>
                                            <h6>{`${station.stationLinkedCode} - ${station.stationLinkedName}`}</h6>
                                        </Grid2>
                                    </Grid2>
                                    <div>
                                        { statsPanel }
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid2>

                    )
                })
            }
            <Row className='padding-bottom-1 padding-top-1 center-align'>
                <Button tooltip={ i18n.apply } onClick={ applyChanges } icon='border_color' className={`btn-floating btn-large ${readyApply ? 'pulse' : ''}`}/>
            </Row>
        </>
    )
}

PiezoSuiviAssociationsTab2.propTypes = {
    displayCote: PropTypes.number,
    piezometer: instanceOf(DtoPiezometer),
    landmarkValue: PropTypes.number,
    changeParent: PropTypes.func,
    tab: PropTypes.string,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    typeId: PropTypes.number,
    time: PropTypes.oneOf([
        PropTypes.number,
        PropTypes.string,
    ]),
}

export default PiezoSuiviAssociationsTab2