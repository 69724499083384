import React from 'react'
import i18n from 'simple-react-i18n'
import { PropTypes } from 'prop-types'
import { Button, Grid, Step, StepLabel, Stepper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Icon from 'components/icon/Icon'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { useState } from 'react'
import JobAction from 'import/actions/JobAction'
import { hasValue } from 'utils/NumberUtil'
import ImportTypeStep from './importSteps/ImportTypeStep'
import { IMPORT_STEPS } from 'installation/constants/InstallationConstants'
import ImportOptionsStep from './importSteps/ImportOptionsStep'
import ImportExecutionStep from './importSteps/ImportExecutionStep'
import { getLogin } from 'utils/SettingUtils'
import { useDispatch } from 'react-redux'
import ToastrAction from 'toastr/actions/ToastrAction'

const useStyles = makeStyles(() => ({
    root: {
        fontSize: '15px',
        fontWeight: 'bold',
        minHeight: 40,
        maxHeight: 40,
        '&.Mui-expanded': {
            minHeight: 45,
            maxHeight: 45,
        },
    },
    label: {
        '&.MuiStepLabel-alternativeLabel': {
            marginTop: '3px !important',
        },
    },
}))


const STEPCollectionsBasinsImportModal = ({
    open = false,
    setOpen = () => { },
    STEP = {},
}) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const [importStep, setImportStep] = useState(0)
    const [fileContent, setFileContent] = useState()
    const [fileName, setFileName] = useState()
    const [focus, setFocus] = useState('')
    const [jobExecutionId, setJobExecutionId] = useState()

    const callExecuteJob = (id, cb) => JobAction.promiseExecuteJob(id).then(json => {
        if (hasValue(json.execution)) {
            cb(json.execution)
        } else {
            setTimeout(() => JobAction.promiseExecuteJob(id).then(newJson => {
                if (hasValue(newJson.execution)) {
                    cb(newJson.execution)
                } else {
                    throw new Error('Impossible to launch Job')
                }
            }), 5000)
        }
    })

    const executeJob = () => {
        const job = {
            id: -500,
            jobType: 'importBasins',
            name: 'Import manuel de bassin de collecte',
            routingKey: 'integration.installation.basins.import',
            parameters: {
                jobType: 'importBasins',
                routingKey: 'integration.installation.basins.import',
                parameters: [JSON.stringify({
                    data: fileContent,
                    idInstallation: STEP.idStation,
                    fileName,
                })],
            }, login: getLogin(),
        }
        JobAction.promiseUpdateJob(job).then(res => {
            if (res.status !== 200) {
                dispatch(ToastrAction.error(i18n.updateError + i18n.jobs))
            } else {
                callExecuteJob(job.id, jobId => {
                    setJobExecutionId(jobId)
                    setImportStep(IMPORT_STEPS.EXECUTION)
                })
            }
        })
    }

    const reset = () => {
        setFileContent()
        setFileName()
    }

    return (
        <DialogMUI open={open}>
            <DialogTitleMUI style={{ margin: '0', padding: '0' }}>
                <Grid container justifyContent='space-between' alignItems='center' style={{ padding: '10 20' }}>
                    <Grid item >
                        {i18n.manualImport}
                    </Grid>
                    <Grid item>
                        <Icon
                            style={{ color: 'white' }}
                            size='small'
                            icon={'close'}
                            onClick={() => {
                                setImportStep(0)
                                setOpen(false)
                                reset()
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' style={{ backgroundColor: 'white', paddingTop: '5px' }}>
                    <Grid item xs={6}>
                        <Stepper
                            alternativeLabel
                            activeStep={importStep}
                            style={{ zoom: '2' }}
                        >
                            <Step>
                                <StepLabel classes={classes} onClick={() => setImportStep(IMPORT_STEPS.IMPORT)}>{i18n.importName}</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel classes={classes} onClick={() => setImportStep(IMPORT_STEPS.OPTIONS)}>{i18n.options}</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel classes={classes}>{i18n.execution}</StepLabel>
                            </Step>
                        </Stepper>
                    </Grid>
                </Grid>
            </DialogTitleMUI>
            <DialogContentMUI>
                {importStep === IMPORT_STEPS.IMPORT && <ImportTypeStep focus={focus} setFocus={setFocus} setImportStep={setImportStep} />}
                {importStep === IMPORT_STEPS.OPTIONS && <ImportOptionsStep setFileContent={setFileContent} setFileName={setFileName} />}
                {importStep === IMPORT_STEPS.EXECUTION && <ImportExecutionStep jobExecutionId={jobExecutionId} />}
            </DialogContentMUI>
            <DialogActionsMUI>
                <Grid container justifyContent='space-evenly'>
                    <Grid item>
                        <Button
                            variant='contained'
                            disabled={importStep === IMPORT_STEPS.IMPORT}
                            onClick={() => {
                                reset()
                                setImportStep(importStep - 1)
                            }}
                        >
                            {i18n.previous}
                        </Button>
                    </Grid>
                    {
                        importStep === IMPORT_STEPS.OPTIONS && (
                            <Grid item>
                                <Button
                                    variant='contained'
                                    disabled={!fileContent}
                                    onClick={executeJob}
                                >
                                    {i18n.importLabel}
                                </Button>
                            </Grid>
                        ) || (
                            <Grid item>
                                <Button
                                    variant='contained'
                                    disabled={importStep === IMPORT_STEPS.EXECUTION}
                                    onClick={() => {
                                        reset()
                                        setImportStep(importStep + 1)
                                    }}
                                >
                                    {i18n.next}
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
            </DialogActionsMUI>
        </DialogMUI>
    )
}

STEPCollectionsBasinsImportModal.propTypes = {
    STEP: PropTypes.object, // DtoStep
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default STEPCollectionsBasinsImportModal