import loading from 'assets/pictures/loading.gif'
import { push } from '@lagunovsky/redux-react-router'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Card from '../../components/card/Card'
import { getUserBookmarksByStationType } from '../../utils/UserUtil'
import { componentHasHabilitations } from '../../utils/HabilitationUtil'
import { H_AGRI_MODULE } from '../../account/constants/AccessRulesConstants'
import UserThunk from 'administration/components/user/actions/UserThunk'
import useStateProgress from 'utils/customHook/useStateProgress'
import AgriThunk from 'agriAdministration/actions/AgriThunk'
import CurrentSurveysPanel from 'survey/components/panels/CurrentSurveysPanel'
import { NewTable } from 'components/datatable/NewTable'
import { Grid2, Icon } from '@mui/material'
import ProgressBar from 'components/progress/ProgressBar'

const AgriCard = () => {
    const {
        userBookmarks,
        exploitationsLight,
        userBookmarksStatus,
        exploitationsLightStatus,
    } = useSelector(store => ({
        userBookmarks: store.UserReducer.userBookmarks,
        exploitationsLight: store.AgriReducer.exploitationsLight,
        userBookmarksStatus: store.DataManagerReducer.user.userBookmarksStatus,
        exploitationsLightStatus: store.DataManagerReducer.agri.exploitationsLightStatus,
    }), shallowEqual)

    const { progress: progressBar, isLoaded: dataLoaded } = useStateProgress([userBookmarksStatus, exploitationsLightStatus])

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(UserThunk.fetchBookmarks())
        dispatch(AgriThunk.fetchExploitationsLight())
    }, [])

    const bookmarks = useMemo(() => getUserBookmarksByStationType(userBookmarks, 'agriculture'), [userBookmarks])

    const data = useMemo(() => ({
        exploitations: exploitationsLight,
        bookmarks,
        habilitation: componentHasHabilitations(H_AGRI_MODULE),
    }), [bookmarks, exploitationsLight])

    const redirect = (href = '') => dispatch(push(href))

    if (dataLoaded) {
        if (!(data.habilitation && (data.bookmarks.length > 0 || data?.exploitations?.length > 0))) {
            return null
        }
        const exploitations = exploitationsLight.filter((e) => data.bookmarks.includes(String(e.idExploitation)))
        return (
            <div className='padding-top-1'>
                <Card
                    title={(
                        <Grid2 container alignItems='center' spacing={1} onClick={() => redirect('/dossiers')}>
                            <Icon fontSize='large'>local_florist</Icon>
                            {i18n.agriculture}
                        </Grid2>
                    )}
                >
                    <Grid2 container spacing={1}>
                        <Grid2 size={12}>
                            <CurrentSurveysPanel />
                        </Grid2>
                        {exploitations?.length ? (
                            <Grid2 size={12}>
                                <NewTable
                                    rows={exploitations}
                                    headers={[
                                        { key: 'icon', value: <Icon>folder</Icon>, tooltip: i18n.exploitations, onClick: () => redirect('/dossiers') },
                                        'codification',
                                        'name',
                                        'inseeCode',
                                        'city',
                                        'address',
                                    ]}
                                    onClickRow={(e) => redirect(`/dossiers/${e.idExploitation}/dashboard`)}
                                />
                            </Grid2>
                        ) : ''}
                    </Grid2>
                </Card>
            </div>
        )
    }
    return (
        <div className='padding-top-1'>
            <Card round>
                <Grid2 container spacing={1} justifyContent='center' sx={{ paddingTop: '10px' }}>
                    <img src={loading} />
                    <Grid2 size={12} sx={{ padding: '10px', paddingBottom: 0 }}>
                        <ProgressBar withMessage message={i18n.agriCardInProgress} progress={progressBar} />
                    </Grid2>
                </Grid2>
            </Card>
        </div>
    )
}

export default AgriCard