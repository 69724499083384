import { getSortedTableData } from 'components/datatable/TableUtils'
import { push } from '@lagunovsky/redux-react-router'
import { flatten, keys, orderBy, some } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContactDto from 'referencial/components/contact/dto/LocationApiDataGouvDto'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import { H_HYDRO_DASHBOARD } from '../../../account/constants/AccessRulesConstants'
import MessageCard from '../../../components/card/MessageCard'
import ProgressCard from '../../../components/card/ProgressCard'
import Table from '../../../components/datatable/Table'
import CartographyPanel from '../../../components/map/CartographyPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import HomeAction from '../../../home/actions/HomeAction'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import { nbPerPageLabelMedium } from '../../../referencial/constants/ReferencialConstants'
import StationStatisticPanel from '../../../station/components/dashboard/component/keyfigure/StationStatisticPanel'
import { MAP, STATION_LIST, STATION_TYPE_CONSTANT, STATION_TYPE_NAME } from '../../../station/constants/StationConstants'
import DtoFilter from '../../../station/dto/DtoFilter'
import { getDate } from '../../../utils/DateUtil'
import { componentHasHabilitations } from '../../../utils/HabilitationUtil'
import { hasValue } from '../../../utils/NumberUtil'
import { getSetting, getUser } from '../../../utils/SettingUtils'
import { getBookmarks, getStationType, hasLocalisationStation } from '../../../utils/StationUtils'
import { arrayOf, getLabel, getObjectLabel } from '../../../utils/StoreUtils'
import { getI18nTitleData, searchAllCharacters } from '../../../utils/StringUtil'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import { setActions } from '../../../components/ActionUtil'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import HydrometryAction from '../../actions/HydrometryAction'
import DtoHydrometricStation from '../../dto/DtoHydrometricStation'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import { WhiteCard } from 'components/styled/Card'
import TabList from 'components/list/TabList'
import CampaignPortletPanel from 'campaign/components/CampaignPortletPanel'
import { getCampaignIcon, getCampaignProgress, getProgressBar } from 'campaign/utils/CampaignUtils'
import { getColorCampaign } from 'utils/CampaignUtil'
import StationFilterFields from 'station/components/search/StationFilterFields'
import { Grid } from '@mui/material'
import DtoContributorLink from 'station/dto/DtoContributorLink'
import ContributorItem from 'referencial/components/contributor/dto/ContributorItem'
import StationAction from 'station/actions/StationAction'
import NetworkLinkDto from 'referencial/components/network/dto/NetworkLinkDto'
import NetworkDto from 'referencial/components/network/dto/NetworkDto'
import SieauParameterDto from 'administration/dto/SieauParameterDto'
import { DEFAULT_CONTRIBUTOR_TYPE } from 'administration/components/user/constants/UserConstants'
import AddHydroStepper from '../addHydro/AddHydroStepper'
import DtoCampaignProgress from 'campaign/dto/DtoCampaignProgress'
import DtoStationCampaign from 'campaign/dto/DtoStationCampaign'
import DtoUserBookmark from 'administration/components/user/dto/DtoUserBookmark'


class HydrometersDashboardApp extends Component {
    constructor(props) {
        super(props)
        const baseFilters = AppStore.getState().AdministrationReducer.hydrometry || {}
        this.state = {
            progress: 0,
            dataLoaded: false,
            panel: STATION_LIST,
            filter: {
                searchValue: baseFilters.searchValue || '',
                filter: baseFilters.filter || -1,
            },
            open: false,
            data: {},
            referentType: parseInt(getSetting(this.props.settings, 'contributorTypeAdministrator')) || DEFAULT_CONTRIBUTOR_TYPE.ADMINISTRATOR,
            operatorType: parseInt(getSetting(this.props.settings, 'contributorTypeOperator')) || DEFAULT_CONTRIBUTOR_TYPE.OPERATOR,
            isAddStationPopupOpen: false,
        }
    }

    setTitle = () => {
        this.props.setTitle([{
            title: i18n.hydrometry,
            href: 'hydrometry',
        }, {
            title: i18n.dashboard,
            href: 'hydrometry',
        }])
    }

    componentDidMount() {
        if (!componentHasHabilitations(H_HYDRO_DASHBOARD)) { // A modifier quand react-router sera à jour
            this.props.push('/unauthorized')
            return
        }
        this.setComponentActions()
        this.setTitle()
        this.props.loadHydrometersTable().then(() => this.setData())
        if (this.props.globalResearch) {
            this.setState({ searchValue: this.props.globalResearch })
            this.props.updateGlobalResearch('')
        }
        if (!this.props.cities.length) {
            AppStore.dispatch(CityAction.fetchCities())
        }
        this.props.fetchAllContributors(STATION_TYPE_CONSTANT.hydrometry)
        // this.props.fetchNetworks()
        // this.props.fetchHydrometryNetworkLinks()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.globalResearch) {
            this.setState({ searchValue: nextProps.globalResearch })
            this.props.updateGlobalResearch('')
        }
    }

    setComponentActions() {
        if (this.state.dataLoaded) {
            const { data } = this.state
            const actions = {
                export: () => {
                    return {
                        data: data.stations,
                        exportType: 'xlsx',
                        titleFile: data.title,
                    }
                },
            }
            const currentUser = getUser()
            if (currentUser.admin === '1' || currentUser.metadata === '1') {
                actions.new = () => this.setState({ isAddStationPopupOpen: true })
            }
            setActions(actions)
            this.setSelectedSearchValueStations(data, this.state.columnName, this.state.sort)
        }
    }

    onChangeValue = (ref, value, ref2, value2) => {
        const obj = {
            [ref]: value || (this.refs[ref] ? this.refs[ref].value : value),
        }
        if (ref2) {
            obj[ref2] = value2
        }
        this.setState({ ...obj, dataLoaded: false }, this.setData)
    }

    onTableSort = (columnName, sort) => {
        this.setState({ columnName, sort })
        const { data } = this.state
        this.setSelectedSearchValueStations(data, columnName, sort)
    }

    getSelectedSearchValues = () => AppStore.getState().AdministrationReducer.selectedSearchValues || {}

    setSelectedSearchValueStations = (data, columnName, sort) => {
        const sortedStations = getSortedTableData(data.stations, { column: columnName, sort }, true)
        const {
            hydrometry: searchValues,
        } = this.getSelectedSearchValues()
        if (!searchValues || !searchValues.stations || this.getStationsHash(searchValues.stations) !== this.getStationsHash(sortedStations)) {
            AppStore.dispatch(SieauAction.update('selectedSearchValues', 'hydrometry', { stations: sortedStations, columnName, sort }))
        }
    }

    getIcon = (panelName, iconName, i18N, id) => <i className='material-icons right blue-text clickable map-right-icon tooltipped' data-tooltip={i18N} id={id} onClick={() => this.onChangeValue('panel', panelName)}>{iconName}</i>

    getTruncateLabel = (string = '', nb) => {
        return string && string.length > nb ? <span className='tooltipped' data-tooltip={string}>{`${string.slice(0, nb - 3)}...`}</span> : string || ''
    }

    getPanelButton = () => {
        const panelList = [STATION_LIST, MAP]
        return panelList.filter(p => p !== this.state.panel).map(p => {
            switch (p) {
                case STATION_LIST:
                    return this.getIcon(STATION_LIST, 'list', i18n.table)
                case MAP:
                    return this.getIcon(MAP, 'map', i18n.map, 'icon_map')
                default:
                    return null
            }
        })
    }

    containsSearchValue = (station) => {
        return some(['code', 'creationDate', 'name', 'closeDate', 'city', 'stationType'], prop => station[prop] && station[prop].value ? searchAllCharacters(station[prop].value.toString()).includes(searchAllCharacters(this.state.filter.searchValue)) : false)
    }

    getHydrometricStations = () => {
        if (this.props.hydrometricStations.length) {
            return orderBy(this.props.hydrometricStations, 'name')
                .map(station => {
                    return {
                        ...station,
                        code: { value: station.code },
                        nullValue: getBookmarks(station.code, getUserBookmarksByStationType(this.props.userBookmarks, 'hydrometry', station.code)),
                        nullValue2: { value: hasValue(station.jobExecutionId) && <i className='material-icons little'>wifi</i> },
                        city: { value: getObjectLabel(this.props.citiesIndex[station.townCode], 'labelWithCode') },
                        name: { value: station.name || '' },
                        creationDate: { value: getDate(station.creationDate) },
                        closeDate: { value: getDate(station.closeDate) },
                        stationType: { value: station.stationType ? getStationType(parseInt(station.stationType)).libelle : '' },
                        headers: ['code', 'city', 'name', 'creationDate', 'closeDate', 'stationType'],
                    }
                })
        }
        return []
    }

    getCurrentCampaigns = () => this.props.hydrometryCampaigns.reduce((acc, c) => {
        const {
            nbStation = 0,
            nbStationValidated = 0,
        } = this.props.hydrometryCampaignsProgress.find(cp => cp.id === c.id) || {}
        if (nbStation !== 0 && nbStationValidated < nbStation && c.statut === 2) {
            return [
                ...acc,
                c,
            ]
        } return acc
    }, [])

    getCampaigns = () => this.getCurrentCampaigns().map(campaign => {
        const {
            nbStation,
            progress = 0,
            progressTotal = 0,
        } = this.props.hydrometryCampaignsProgress.find(p => p.id === campaign.id) || {}
        const progressValue = getCampaignProgress(progress, progressTotal)
        return {
            id: campaign.id,
            qualificationTableColor: {
                color: getColorCampaign(false, progressValue, 2),
            },
            nullValue: getCampaignIcon(campaign.campaignType, 'small'),
            name: campaign.name,
            nbStations: nbStation,
            startDate: campaign.beginningApplication,
            progression: getProgressBar(progress, progressTotal, progressValue, i18n.analyseOn, i18n.analysisOn),
            referent: getLabel(this.props.contacts, campaign.contactCode, 'mnemonique'),
            contactCode: campaign.contactCode,
        }
    })

    setData = () => {
        const { filter, referentType, operatorType } = this.state
        const { filterResults } = this.props
        const filterHeaders = keys(filterResults?.[0] || {}).filter(d => d !== 'id')
        const data = {
            title: i18n.hydrometricStations,
            type: { headers: ['nullValue', 'nullValue2', 'code', 'city', 'name', 'creationDate', 'closeDate', 'stationType', ...filterHeaders] },
            stations: this.getHydrometricStations().map(s => {
                const filterValuesFound = filterResults.find(fd =>
                    fd.id === s.id,
                ) || {}
                const filterValues = keys(filterValuesFound).reduce((acc, key) => {
                    const value = filterValuesFound[key]
                    if (key !== 'id') {
                        return { ...acc, [key]: { value: value !== 'null' ? value : '' } }
                    } return acc
                }, {})
                return {
                    ...s,
                    ...filterValues,
                }
            }),
            legendPanel: (
                <div className='row no-margin valign-wrapper padding-bottom-4 padding-top-4 padding-left-1 padding-right-1'>
                    <i className='material-icons '>wifi</i>
                    { i18n.automaticUpdate }
                </div>
            ),
            campaignPanel: this.getCampaigns(),
            campaignHeader: { headers: ['qualificationTableColor', 'nullValue', 'name', 'nbStations', 'startDate', 'progression', 'referent'] },
        }
        const stations = (() => {
            const searchFiltered = data.stations.filter(s => this.containsSearchValue(s))
            const operatorFiltered = (() => {
                if (!filter.operatorIds?.length) return searchFiltered

                const filteredLinks = this.props.contributorLinks
                    .filter(l => l.contributorType === operatorType && filter.operatorIds.includes(l.idContributor))

                return searchFiltered.filter(s => filteredLinks.some(l => l.idStation === s.id))
            })()

            const referentFiltered = (() => {
                if (!filter.referentIds?.length) return operatorFiltered

                const filteredLinks = this.props.contributorLinks
                    .filter(l => l.contributorType === referentType && filter.referentIds.includes(l.idContributor))

                return operatorFiltered.filter(s => filteredLinks.some(l => l.idStation === s.id))
            })()

            if (hasValue(filter.filter) && filter.filter !== -1 && filter.filterResults) {
                return flatten(filter.filterResults.map(stationResult => referentFiltered.find(station => station.id === stationResult.id) || []))
            }

            // const networkFiltered = !isUndefined(filter.network) ? this.props.networkLinks.filter(l => filter.network === l.idNetwork).map(l => referentFiltered.find(s => s.id === l.idStation)).filter(s => !!s) : referentFiltered
            // if (hasValue(filter.filter) && filter.filter !== -1 && filter.filterResults) {
            //     return flatten(filter.filterResults.map(stationResult => networkFiltered.find(station => station.id === stationResult.id) || []))
            // }
            return referentFiltered // return networkFiltered
        })()
        this.setState({ data: { ...data, stations }, dataLoaded: true })
    }

    render() {
        const { data, filter, isAddStationPopupOpen } = this.state
        // const hydroNetworks = uniq(this.props.networkLinks.map(l => l.idNetwork)).map(id => this.props.networks.find(n => id === n.id)).filter(c => !isUndefined(c))
        if (data) {
            const {
                panel,
            } = this.state

            const contentPanel = (() => {
                if (!this.state.dataLoaded) {
                    return <ProgressCard progress={this.state.progress} withMessage />
                }

                switch (panel) {
                    case MAP:
                        if (data.stations.length > 20000) {
                            return <MessageCard >{ i18n.tooMuchDataToShow }</MessageCard>
                        }
                        const stations = data.stations.map(s => ({ ...s, code: s.code.value, name: s.name.value }))
                        const stationsWithCoordinates = stations.filter(s => s && (hasLocalisationStation(s) || s.townCode))
                        return (
                            <WhiteCard
                                title={(
                                    <><span>{data?.title}</span><span style={{ fontWeight: 'normal' }}>{` (${data?.stations?.length} ${getI18nTitleData(i18n.element, i18n.elements, data?.stations)})`}</span></>
                                )}
                                round
                            >
                                <CartographyPanel
                                    layers={['STATIONS_POINTS']}
                                    componentType='hydrometry'
                                    stationsPoints={stationsWithCoordinates}
                                    stationsPanelTitle={i18n.stations}
                                    heightToSubstract={450}
                                />
                            </WhiteCard>
                        )
                    default:
                        const searchValues = this.getSelectedSearchValues().hydrometry
                        const initialSort = searchValues && searchValues.columnName ? { column: searchValues.columnName, sort: searchValues.sort } : null
                        return (
                            <Table
                                title={data.title}
                                data={data.stations}
                                paging
                                color
                                initialSort={initialSort}
                                legendPanel={data.legendPanel}
                                nbPerPageLabel={nbPerPageLabelMedium}
                                onSort={(value, sort) => this.onTableSort(value, sort)}
                                type={data.type}
                                onClick={s => AppStore.dispatch(push(`/station/hydrometry/${s.id}`))}
                                condensed
                                sortable
                                invertedHeaderStyle
                                id='hydro_table'
                                round
                            />
                        )
                }
            })()

            return (
                <div style={{ marginRight: 5, paddingBottom: 100 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <CampaignPortletPanel
                                campaigns={data.campaignPanel}
                                stationType='hydrometry'
                                tableHeader={data.campaignHeader}
                                noHeader
                                customHeaders={{
                                    name: i18n.campaign,
                                }}
                                round={true}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <StationStatisticPanel stationType='hydrometry' round />
                        </Grid>
                        <Grid item xs={10}>
                            <StationFilterFields
                                defaultFilter={filter}
                                onValidate={values => this.setState({ filter: { ...values } }, () => this.setData())}
                                stationType={STATION_TYPE_NAME.hydrometry}
                                stations={this.props.hydrometricStations}
                                contributorLinks={this.props.contributorLinks}
                                // networklist={hydroNetworks}
                            />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: -60 }}>
                            <TabList
                                onChangeTab={(v) => this.onChangeValue('panel', v)}
                                tabs={[
                                    {
                                        value: STATION_LIST,
                                        label: i18n.table,
                                        icon: 'list',
                                    },
                                    {
                                        value: MAP,
                                        label: i18n.map,
                                        icon: 'map',
                                    },
                                ]}
                            >
                                {contentPanel}
                            </TabList>
                        </Grid>
                        <AddHydroStepper
                            isOpen={isAddStationPopupOpen}
                            setIsOpen={v => {
                                this.setState({ isAddStationPopupOpen: v })
                            }}
                        />
                    </Grid>
                </div>
            )
        }
        return null
    }

    getStationsHash = stations => stations.map(s => s.code.value).join('')

    componentDidUpdate() {
        this.setComponentActions()
    }
}

HydrometersDashboardApp.propTypes = {
    hydrometricStations: arrayOf(DtoHydrometricStation),
    citiesIndex: PropTypes.objectOf(PropTypes.instanceOf(CityDto)),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    globalResearch: PropTypes.string,
    filters: arrayOf(DtoFilter),
    push: PropTypes.func,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(ContactDto)),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    loadHydrometersTable: PropTypes.func,
    updateGlobalResearch: PropTypes.func,
    setTitle: PropTypes.func,
    createHydrometer: PropTypes.func,
    contributorLinks: PropTypes.arrayOf(PropTypes.instanceOf(DtoContributorLink)),
    contributorsIndex: PropTypes.objectOf(PropTypes.instanceOf(ContributorItem)),
    networkLinks: PropTypes.arrayOf(PropTypes.instanceOf(NetworkLinkDto)),
    network: PropTypes.objectOf(PropTypes.instanceOf(NetworkDto)),
    settings: PropTypes.objectOf(PropTypes.instanceOf(SieauParameterDto)),
    filterResults: PropTypes.arrayOf(Object),
    hydrometryCampaignsProgress: PropTypes.arrayOf(PropTypes.instanceOf(DtoCampaignProgress)),
    hydrometryCampaigns: PropTypes.arrayOf(PropTypes.instanceOf(DtoStationCampaign)),
    fetchAllContributors: PropTypes.func,
    userBookmarks: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserBookmark)),
}

const mapStateToProps = store => ({
    hydrometricStations: store.HydrometryReducer.hydrometricStations,
    hydrometryCampaigns: store.HydrometryReducer.hydrometryCampaigns,
    hydrometryCampaignsProgress: store.HydrometryReducer.hydrometryCampaignsProgress,
    citiesIndex: store.CityReducer.citiesIndex,
    cities: store.CityReducer.cities,
    globalResearch: store.HomeReducer.globalResearch,
    userBookmarks: store.UserReducer.userBookmarks,
    filters: store.StationReducer.filters,
    contacts: store.ContactReducer.contacts,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    filterResults: store.StationReducer.filterResults,
    contributorLinks: store.StationReducer.contributorLinks,
    contributorsIndex: store.ContributorReducer.contributorsIndex,
    networkLinks: store.QualityReducer.networkLinks,
    networks: store.NetworkReducer.networks,
    settings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    loadHydrometersTable: HydrometryAction.loadHydrometersTable,
    updateGlobalResearch: HomeAction.updateGlobalResearch,
    fetchAllContributors: StationAction.fetchAllContributors,
    // fetchHydrometryNetworkLinks: HydrometryAction.fetchHydrometryNetworkLinks,
    // fetchNetworks: NetworkAction.fetchNetworks,
    setTitle: HomeAction.setTitle,
    push,
    createHydrometer: HydrometryAction.createHydrometer,
}

export default connect(mapStateToProps, mapDispatchToProps)(HydrometersDashboardApp)
