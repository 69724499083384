/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import NumberField from '../../../../components/forms/NumberField'
import DtoTankStation from '../dto/DtoTankStation'
import Select from '../../../../components/forms/Select'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import { Grid2 } from '@mui/material'
import { isDisplayed } from 'utils/InstallationUtils'
import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
import useSandreList from 'utils/customHook/useSandreList'

const TankCivilEngPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    tank = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
        applicationSettings,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    const onChangeCivilEng = (value) => {
        onChange({
            link_civilEngs: [
                {
                    idStation: tank.idStation,
                    ...tank.link_civilEngs[0],
                    ...value,
                },
            ],
        })
    }

    const tankTypeList = useSandreList('INSTALLATIONS_TYPERESERVOIR')
    const revetementList = useSandreList('INSTALLATIONS_REVETEMENTCUVE')
    const fillingList = useSandreList('INSTALLATIONS_DISPOSITIFREMPLISSAGE')
    const usageList = useSandreList('RESERVOIRS_USAGES')
    const natureList = useSandreList('INSTALLATIONS_NATUREEQUIPEMENT')
    const bacheList = useSandreList('BACHE_TYPE')

    const civilEng = tank.link_civilEngs[0] || {}

    return (
        <>
            <Grid2 size={6}>
                <Select
                    value={civilEng.tankType}
                    label={i18n.tankType}
                    keyValue='code'
                    options={tankTypeList}
                    onChange={(v) => {
                        onChangeCivilEng({ tankType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_TYPERESERVOIR, i18n.tankType, civilEng.tankType, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.numberTanks}
                    title={i18n.numberTanks}
                    onChange={(v) => {
                        onChangeCivilEng({ numberTanks: v })
                        onChangeVisit({ previousValue: civilEng.numberTanks, newValue: v, field: i18n.numberTanks })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={civilEng.coatingTanks}
                    label={i18n.coatingTanks}
                    keyValue='code'
                    options={revetementList}
                    onChange={(v) => {
                        onChangeCivilEng({ coatingTanks: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_REVETEMENTCUVE, i18n.coatingTanks, civilEng.coatingTanks, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    title={i18n.overflowRating}
                    value={civilEng.overflowRating}
                    onChange={v => {
                        onChangeCivilEng({ overflowRating: v })
                        onChangeVisit({ previousValue: civilEng.overflowRating, newValue: v, field: i18n.overflowRating })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.usefulVolume}
                    title={i18n.usefulVolume}
                    onChange={(v) => {
                        onChangeCivilEng({ usefulVolume: v })
                        onChangeVisit({ previousValue: civilEng.usefulVolume, newValue: v, field: i18n.usefulVolume })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.dedicatedVolume}
                    title={i18n.dedicatedVolume}
                    onChange={(v) => {
                        onChangeCivilEng({ dedicatedVolume: v })
                        onChangeVisit({ previousValue: civilEng.dedicatedVolume, newValue: v, field: i18n.dedicatedVolume })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.altiLevelTN}
                    title={i18n.altiLevelTN}
                    onChange={(v) => {
                        onChangeCivilEng({ altiLevelTN: v })
                        onChangeVisit({ previousValue: civilEng.altiLevelTN, newValue: v, field: i18n.altiLevelTN })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.radierTankLevel}
                    title={i18n.radierTankLevel}
                    onChange={(v) => {
                        onChangeCivilEng({ radierTankLevel: v })
                        onChangeVisit({ previousValue: civilEng.radierTankLevel, newValue: v, field: i18n.radierTankLevel })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.totalHeightTour}
                    title={i18n.totalHeightTour}
                    onChange={(v) => {
                        onChangeCivilEng({ totalHeightTour: v })
                        onChangeVisit({ previousValue: civilEng.totalHeightTour, newValue: v, field: i18n.totalHeightTour })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={civilEng.fillingDevice}
                    label={i18n.fillingDevice}
                    keyValue='code'
                    options={fillingList}
                    onChange={(v) => {
                        onChangeCivilEng({ fillingDevice: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_DISPOSITIFREMPLISSAGE, i18n.fillingDevice, civilEng.fillingDevice, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.area}
                    title={i18n.aream2}
                    onChange={v => {
                        onChangeCivilEng({ area: v })
                        onChangeVisit({ previousValue: civilEng.area, newValue: v, field: i18n.surface })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={civilEng.idUsage}
                    keyValue='code'
                    label={i18n.usage}
                    options={usageList}
                    onChange={(v) => {
                        onChangeCivilEng({ idUsage: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.RESERVOIRS_USAGES, i18n.usage, civilEng.idUsage, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
                {/* <Row displayIf={isDisplayed('INFOS_COMPL')}> */}
            </Grid2>
            {isDisplayed(applicationSettings, 'INFOS_COMPL', INSTALLATION_TYPE.TANK) && (
                <Grid2 size={6}>
                    <Select
                        value={civilEng.natureTanks}
                        keyValue='code'
                        label={i18n.natureTanks}
                        options={natureList}
                        onChange={(v) => {
                            onChangeCivilEng({ natureTanks: v })
                            onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_NATUREEQUIPEMENT, i18n.natureTanks, civilEng.natureTanks, v))
                        }}
                        integerValue
                        readMode={readMode}
                    />
                </Grid2>
            )}
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.strainerRate}
                    title={i18n.strainerRate}
                    onChange={v => {
                        onChangeCivilEng({ strainerRate: v })
                        onChangeVisit({ previousValue: civilEng.strainerRate, newValue: v, field: i18n.strainerRate })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={civilEng.tarpType}
                    keyValue='code'
                    label={i18n.tarpType}
                    options={bacheList}
                    onChange={(v) => {
                        onChangeCivilEng({ tarpType: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.BACHE_TYPE, i18n.tarpType, civilEng.tarpType, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.interiorRenovationYear}
                    title={i18n.interiorRenovationYear}
                    onChange={v => {
                        onChangeCivilEng({ interiorRenovationYear: v })
                        onChangeVisit({ previousValue: civilEng.interiorRenovationYear, newValue: v, field: i18n.interiorRenovationYear })
                    }}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.exteriorRenovationYear}
                    title={i18n.exteriorRenovationYear}
                    onChange={v => {
                        onChangeCivilEng({ exteriorRenovationYear: v })
                        onChangeVisit({ previousValue: civilEng.exteriorRenovationYear, newValue: v, field: i18n.exteriorRenovationYear })
                    }}
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

TankCivilEngPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default TankCivilEngPanel
