import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, Grid2 } from '@mui/material'
import { push } from '@lagunovsky/redux-react-router'
import { omit, template } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import UserAction from '../../../administration/components/user/actions/UserAction'
import ActionComponent from '../../../components/ActionComponent'
import Input from '../../../components/forms/Input'
import NumberField from '../../../components/forms/NumberField'
import Select from '../../../components/forms/Select'
import AquiferAction from '../../../referencial/components/aquifers/actions/AquiferAction'
import DtoAquifer from '../../../referencial/components/aquifers/dto/DtoAquifer'
import CityDto from '../../../referencial/components/city/dto/CityDto'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import NetworkAction from '../../../referencial/components/network/actions/NetworkAction'
import NetworkDto from '../../../referencial/components/network/dto/NetworkDto'
import WatermassAction from '../../../referencial/components/watermass/actions/WatermassAction'
import WatermassDto from '../../../referencial/components/watermass/dto/WatermassDto'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import WatershedDto from '../../../referencial/components/watershed/dto/WatershedDto'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getReferencialComponent } from '../../../referencial/util/ReferencialUtils'
import StationAction from '../../../station/actions/StationAction'
import LinkedStationsPanel from '../../../station/components/associatedStation/LinkedStationsPanel'
import StationMapDashboardPanel from '../../../station/components/dashboard/component/map/StationMapDashboardPanel'
import StationContactsPanel from '../../../station/components/link/StationContactsPanel'
import StationUpdatePanel from '../../../station/components/update/StationUpdatePanel'
import AppStore from '../../../store/AppStore'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getDate } from '../../../utils/DateUtil'
import { setConfirmationModal } from '../../../utils/FormUtils'
import { getLogin, getUser } from '../../../utils/SettingUtils'
import { getBookmarks, getLinks } from '../../../utils/StationUtils'
import { arrayOf, getSandreList } from '../../../utils/StoreUtils'
import { getUserBookmarksByStationType } from '../../../utils/UserUtil'
import PiezometryAction from '../../actions/PiezometryAction'
import DtoPiezometer from '../../dto/DtoPiezometer'
import DatasAlertPanel from '../alerts/datas/DatasAlertPanel'
import PiezometerDescriptionTablesPanel from './PiezometerDescriptionTablesPanel'
import { hasValue } from '../../../utils/NumberUtil'
import { CardTitle } from 'components/card/NewCard'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

class PiezometerDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            piezometer: props.piezometer,
            readMode: true,
        }
    }

    componentDidMount() {
        const actions = {
            exportmodel: () => ({
                stationId: this.props.piezometer.id.toString(),
                stationCode: this.props.piezometer.code,
                stationType: this.props.piezometer.typeName,
                environmentModels: this.props.typeEnvironmentModels,
            }),
            links: getLinks(this.props.piezometer, this.props),
            arrowNav: getStationArrowNav('piezometry', this.props.piezometers, this.props.piezometer.id, s => this.props.push(`/station/piezometry/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => AppStore.dispatch(PiezometryAction.deletePiezometer(this.props.piezometer.id, () => this.props.push('/piezometry'))),
            })
        }
        if (!this.props.aquifers.length) {
            this.props.fetchAquifers()
        }
        if (!this.props.watermasses.length) {
            this.props.fetchWatermasses()
        }
        if (!this.props.networks.length) {
            this.props.fetchNetworks()
        }
        if (!this.props.watersheds.length) {
            this.props.fetchWatersheds()
        }
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            links: getLinks(this.props.piezometer, this.props),
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ piezometer: this.props.piezometer })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            arrowNav: getStationArrowNav('piezometry', this.props.piezometers, this.props.piezometer.id, s => this.props.push(`/station/piezometry/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        this.setState({ readMode: true })
        const actions = {
            exportmodel: () => ({
                stationId: this.props.piezometer.id.toString(),
                stationCode: this.props.piezometer.code,
                stationType: this.props.piezometer.typeName,
                environmentModels: this.props.typeEnvironmentModels,
            }),
            links: getLinks(this.props.piezometer, this.props),
            arrowNav: getStationArrowNav('piezometry', this.props.piezometers, this.props.piezometer.id, s => this.props.push(`/station/piezometry/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    onSave = () => {
        const applySave = () => this.props.updatePiezometer({ ...this.state.piezometer, updateLogin: getLogin() }, () => {
            this.setReadOnlyMode()
        })
        if (this.state.piezometer.updatedMinLandmark) {
            setConfirmationModal(applySave, template(i18n.landmarkUpdateConfirmationMessage)({ startDate: getDate(this.state.piezometer.updatedMinLandmark), endDate: getDate(this.state.piezometer.updatedMaxLandmark) }))
        } else {
            applySave()
        }
    }

    onDelete = () => {
        AppStore.dispatch(StationAction.deletePiezometer(this.state.piezometer, () => AppStore.dispatch(push('/piezometry'))))
    }

    setBookmark = (deleteBookmark = false) => {
        const { piezometer } = this.state
        if (deleteBookmark) {
            this.props.deleteBookmark({ identifiant: piezometer.code, login: getLogin(), stationType: 1 })
        } else {
            this.props.createBookmark({ identifiant: piezometer.code, login: getLogin(), stationType: 1 })
        }
    }

    onChangePiezometer = (newObject) => this.setState({ piezometer: { ...this.state.piezometer, ...newObject } })

    changePiezometerWork = (obj) => {
        this.setState({
            piezometer: {
                ...this.state.piezometer, link_work: [{
                    ...(this.state.piezometer.link_work[0] || {}),
                    idStation: this.state.piezometer.id,
                    ...obj,
                }],
            },
        })
    }

    onChangeGeoData = (value) => {
        const linkGeo = this.state.piezometer.link_geo && this.state.piezometer.link_geo[0] || {}
        this.onChangePiezometer({
            link_geo: [
                {
                    ...linkGeo,
                    idStation: this.state.piezometer.id, ...value,
                }],
            LAST_FORM: value.LAST_FORM,
        })
    }

    openContactModal = contactCode => {
        if (this.state.readMode) {
            const contactFound = this.props.contacts.find(({ id }) => contactCode === id)
            this.setState({ isOpenContact: !!contactFound, contact: contactFound })
        }
    }

    render() {
        const {
            readMode,
            piezometer,
            isOpenContact,
            contact = {},
        } = this.state
        const mode = { readMode, editMode: !this.state.readMode, freezeOpti: true }
        const geoData = piezometer.link_geo && piezometer.link_geo[0] || {}
        return (
            <Grid2 container spacing={1} sx={{ marginBottom: '100px', padding: '10 10 0 20' }} alignItems='flex-start'>
                <Grid2 container size={9} columnSpacing={1}>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.description} />
                            <CardContent>
                                <Grid2 container columnSpacing={1}>
                                    <Grid2 size={readMode ? 6 : 3}>
                                        <Input
                                            value={`${piezometer.code}${(readMode && `/${piezometer.designation || ''}`) || ''}`}
                                            title={i18n.code}
                                            disabled
                                            {...mode}
                                        />
                                    </Grid2>
                                    {!readMode && (
                                        <>
                                            <Grid2 size={6}>
                                                /
                                            </Grid2>
                                            <Grid2 size={6}>
                                                <Input
                                                    value={piezometer.designation}
                                                    title={i18n.designation}
                                                    keyObj='designation'
                                                    changeObj={this.onChangePiezometer}
                                                    {...mode}
                                                />
                                            </Grid2>
                                        </>
                                    )}
                                    <Grid2 size={5}>
                                        <Input
                                            value={piezometer.name}
                                            title={i18n.name}
                                            keyObj='name'
                                            changeObj={this.onChangePiezometer}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={1}>
                                        {getBookmarks(piezometer.code, getUserBookmarksByStationType(this.props.userBookmarks, 1), true, this.setBookmark)}
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            style={{ padding: 0 }}
                                            value={piezometer.creation}
                                            id='creationDate'
                                            label={i18n.creationDate}
                                            onChange={(_, v) => this.onChangePiezometer({ creation: v })}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <SimpleDatePicker
                                            style={{ padding: 0 }}
                                            value={piezometer.close}
                                            id='closeDate'
                                            label={i18n.closeDate}
                                            onChange={(_, v) => this.onChangePiezometer({ close: v })}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            options={getSandreList(this.props.sandreCodes, SANDRE.PIEZOMETER_NATURE)}
                                            value={piezometer.nature}
                                            label={i18n.nature}
                                            onChange={v => this.onChangePiezometer({ nature: v })}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            options={getSandreList(this.props.sandreCodes, SANDRE.FIELD_MODES)}
                                            value={piezometer.fieldMode}
                                            label={i18n.fieldMode}
                                            onChange={v => this.onChangePiezometer({ fieldMode: v })}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            options={getSandreList(this.props.sandreCodes, SANDRE.STATE_CODES)}
                                            value={piezometer.stateCode}
                                            label={i18n.state}
                                            onChange={v => this.onChangePiezometer({ stateCode: v })}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Select
                                            options={getSandreList(this.props.sandreCodes, SANDRE.PIEZOMETRES_TYPE)}
                                            value={piezometer.waterSourceType}
                                            label={i18n.waterSourceType}
                                            onChange={v => this.onChangePiezometer({ waterSourceType: hasValue(v) ? v.toString() : undefined })}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleTextArea
                                            value={piezometer.comment}
                                            title={i18n.comment}
                                            keyObj='comment'
                                            changeObj={this.onChangePiezometer}
                                            {...mode}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <PiezometerDescriptionTablesPanel
                        piezometer={piezometer}
                        onChangePiezometer={this.onChangePiezometer}
                        onShowContact={this.openContactModal}
                        changePiezometerWork={this.changePiezometerWork}
                        {...mode}
                    />
                    <Grid2 size={12}>
                        <StationContactsPanel onChange={this.onChangePiezometer} station={piezometer} readMode={this.state.readMode} />
                    </Grid2>
                </Grid2>
                <Grid2 container size={3} columnSpacing={1}>
                    <Grid2 size={12}>
                        <StationUpdatePanel station={piezometer} />
                    </Grid2>
                    <Grid2 size={12}>
                        <DatasAlertPanel station={piezometer} measures={[]} controlErrorsOnly />
                    </Grid2>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.campaign} />
                            <CardContent>
                                <NumberField
                                    title={i18n.previsionalVisitNumber}
                                    value={piezometer.previsionalVisitNumber}
                                    onChange={v => this.onChangePiezometer({ previsionalVisitNumber: v })}
                                    readMode={readMode}
                                />
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <StationMapDashboardPanel noMarkerTooltip station={piezometer} type='piezometry' />
                    </Grid2>
                    <Grid2 size={12}>
                        <Card>
                            <CardTitle title={i18n.location} />
                            <CardContent>
                                <Grid2 container columnSpacing={1}>
                                    <Grid2 size={12}>
                                        <Input
                                            title={i18n.address}
                                            value={piezometer.address}
                                            keyObj='address'
                                            changeObj={this.onChangePiezometer}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Select
                                            value={piezometer.townCode}
                                            label={i18n.city}
                                            options={this.props.cities}
                                            keyValue='id' displayWithCode
                                            onChange={v => this.onChangePiezometer({ townCode: v })}
                                            {...mode}
                                        />
                                    </Grid2>
                                    {(piezometer.declarationTownCode !== piezometer.townCode || !readMode) && (
                                        <Grid2 size={12}>
                                            <Select
                                                value={piezometer.declarationTownCode}
                                                label={i18n.declarationTownCode}
                                                options={this.props.cities}
                                                keyValue='id'
                                                displayWithCode
                                                onChange={v => this.onChangePiezometer({ declarationTownCode: v })} {...mode}
                                            />
                                        </Grid2>
                                    )}
                                    <Grid2 size={12}>
                                        <Input
                                            value={piezometer.countryCode}
                                            title={i18n.countryCode}
                                            keyObj='countryCode'
                                            changeObj={this.onChangePiezometer}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <SimpleTextArea
                                            title={i18n.localisation}
                                            noMargin={false}
                                            classname={!readMode && 'margin-bottom-1' || ''}
                                            value={piezometer.localization}
                                            keyObj='localization'
                                            changeObj={this.onChangePiezometer}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            title='X'
                                            value={piezometer.x}
                                            floatValue
                                            onChange={v => this.onChangePiezometer({ x: v })}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <NumberField
                                            title='Y'
                                            value={piezometer.y}
                                            floatValue
                                            onChange={v => this.onChangePiezometer({ y: v })}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <Select
                                            label={i18n.projection}
                                            value={piezometer.projection}
                                            options={getSandreList(this.props.sandreCodes, SANDRE.PROJECTION)}
                                            onChange={v => this.onChangePiezometer({ projection: v })}
                                            {...mode}
                                            integerValue
                                        />
                                    </Grid2>
                                    <Grid2 size={12}>
                                        <NumberField
                                            value={piezometer.altitude}
                                            title={i18n.altitudeM}
                                            floatValue
                                            onChange={(v) => this.onChangePiezometer({ altitude: v })}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.ignMap}
                                            value={geoData.ignMap}
                                            keyObj='ignMap'
                                            changeObj={this.onChangeGeoData}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.geologicalMap}
                                            value={geoData.geologicalMap}
                                            keyObj='geologicalMap'
                                            changeObj={this.onChangeGeoData}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.section}
                                            value={geoData.parcel}
                                            keyObj='parcel'
                                            changeObj={this.onChangeGeoData}
                                            {...mode}
                                        />
                                    </Grid2>
                                    <Grid2 size={6}>
                                        <Input
                                            title={i18n.parcel}
                                            value={geoData.section}
                                            keyObj='section'
                                            changeObj={this.onChangeGeoData}
                                            {...mode}
                                        />
                                    </Grid2>
                                </Grid2>
                            </CardContent>
                        </Card>
                    </Grid2>
                    <Grid2 size={12}>
                        <LinkedStationsPanel noMargin={false} className='margin-top-1' onReMount={this.props.onRemount} station={piezometer} {...mode} />
                    </Grid2>
                </Grid2>
                <Dialog
                    onClose={() => this.setState({ isOpenContact: false })}
                    fullWidth
                    maxWidth='lg'
                    open={isOpenContact}
                >
                    <DialogContent>
                        <Grid2 container>
                            <Grid2 size={12}>
                                <Card>
                                    <CardTitle title={i18n.description} />
                                    <CardContent>
                                        <Grid2 container columnSpacing={1}>
                                            <Grid2 size={3}>
                                                <Input
                                                    title={i18n.title}
                                                    value={contact.title}
                                                    readMode={true}
                                                />
                                            </Grid2>
                                            <Grid2 size={9}>
                                                <Input
                                                    title={i18n.name}
                                                    value={contact.name}
                                                    readMode={true}
                                                />
                                            </Grid2>
                                            <Grid2 size={6}>
                                                <Input
                                                    title={i18n.socialReason}
                                                    value={contact.socialReason}
                                                    readMode={true}
                                                />
                                            </Grid2>
                                            <Grid2 size={6}>
                                                <SimpleDatePicker
                                                    style={{ padding: 0 }}
                                                    label={i18n.birthdate}
                                                    value={contact.birthdate}
                                                    readMode={true}
                                                />
                                            </Grid2>
                                            <Grid2 size={6}>
                                                <Select
                                                    label={i18n.functions}
                                                    value={contact.contactFunction}
                                                    options={getSandreList(this.props.sandreCodes, SANDRE.CONTACTS_FONCTIONS)}
                                                    readMode={true}
                                                />
                                            </Grid2>
                                            <Grid2 size={12}>
                                                <SimpleTextArea
                                                    title={i18n.comment}
                                                    value={contact.comment}
                                                    readMode={true}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                            <Grid2 size={12}>
                                <Card>
                                    <CardTitle title={i18n.localisation} />
                                    <CardContent>
                                        <Grid2 container columnSpacing={1}>
                                            <Grid2 size={12}>
                                                <SimpleTextArea
                                                    title={i18n.address}
                                                    value={contact.address}
                                                    readMode={true}
                                                />
                                            </Grid2>
                                            <Grid2 size={12}>
                                                <SimpleTextArea
                                                    title={i18n.additionnalAddress}
                                                    value={contact.additionalAddress}
                                                    readMode={true}
                                                />
                                            </Grid2>
                                            <Grid2 size={3}>
                                                <Input
                                                    title={i18n.postalCode}
                                                    value={contact.postalBox}
                                                    readMode={true}
                                                />
                                            </Grid2>
                                            <Grid2 size={9}>
                                                <Select
                                                    label={i18n.city}
                                                    value={contact.cityCode}
                                                    options={getReferencialComponent(this.props.cities)}
                                                    readMode={true}
                                                    clearFunction
                                                />
                                            </Grid2>
                                            <Grid2 size={3}>
                                                <Input
                                                    title={i18n.cedexCode}
                                                    value={contact.cedex}
                                                    readMode={true}
                                                />
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        </Grid2>
                        <Grid2 size={12}>
                            <Card>
                                <CardTitle title={i18n.coordinates} />
                                <CardContent>
                                    <Grid2 container columnSpacing={1}>
                                        <Grid2 size={3}>
                                            <Input
                                                title={i18n.phoneTel}
                                                value={contact.phoneTel}
                                                readMode={true}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <Input
                                                title={i18n.phoneTelSecond}
                                                value={contact.phoneTelSecond}
                                                readMode={true}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Input
                                                title={i18n.fax}
                                                value={contact.fax}
                                                readMode={true}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <Input
                                                title={i18n.desktopTel}
                                                value={contact.desktopTel}
                                                readMode={true}
                                            />
                                        </Grid2>
                                        <Grid2 size={3}>
                                            <Input
                                                title={i18n.mobile}
                                                value={contact.mobile}
                                                readMode={true}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Input
                                                title={i18n.email}
                                                value={contact.email}
                                                readMode={true}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ isOpenContact: false })} variant='outlined' color='secondary'>
                            {i18n.close}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid2 >
        )
    }

    shouldComponentUpdate(_, nextState) {
        if (nextState.piezometer.LAST_FORM !== this.state.piezometer.LAST_FORM && nextState.piezometer.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

PiezometerDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    piezometer: PropTypes.instanceOf(DtoPiezometer),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    cities: arrayOf(CityDto),
    contacts: arrayOf(ContactDto),
    aquifers: arrayOf(DtoAquifer),
    watermasses: arrayOf(WatermassDto),
    networks: arrayOf(NetworkDto),
    watersheds: arrayOf(WatershedDto),
    typeEnvironmentModels: PropTypes.arrayOf(PropTypes.string),
}


const mapStateToProps = store => ({
    piezometer: store.StationReducer.piezometer,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    userBookmarks: store.UserReducer.userBookmarks,
    cities: store.CityReducer.cities,
    contacts: store.ContactReducer.contacts,
    piezometers: store.PiezometryReducer.piezometersLight,
    watermasses: store.WatermassReducer.watermasses,
    aquifers: store.AquiferReducer.aquifers,
    networks: store.NetworkReducer.networks,
    watersheds: store.WatershedReducer.watersheds,
    typeEnvironmentModels: store.ExportReducer.typeEnvironmentModels,
})

const mapDispatchToProps = {
    updatePiezometer: StationAction.updatePiezometer,
    push,
    deleteBookmark: UserAction.deleteBookmark,
    createBookmark: UserAction.createBookmark,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchWatermasses: WatermassAction.fetchWatermasses,
    fetchNetworks: NetworkAction.fetchNetworks,
    fetchWatersheds: WatershedAction.fetchWatersheds,
}

export default connect(mapStateToProps, mapDispatchToProps)(PiezometerDescriptionPanel)
