/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getSandreLabel } from '../../../../utils/StringUtil'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../../utils/DateUtil'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import useSandreList from 'utils/customHook/useSandreList'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import DtoTankStation from '../dto/DtoTankStation'

const headers = ['equipment', 'lastControleDate', 'state', 'comment']

const SecurityDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const radioList = useSandreList(SANDRE.RADIO_SECURITE)
    const zoneList = useSandreList(SANDRE.RESERVOIRS_ZONES_ETATS)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Select
                            options={radioList}
                            label={i18n.equipment}
                            value={stateElement.equipment}
                            onChange={v => onChangeElement({ equipment: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.lastControleDate}
                            id='startDate'
                            label={i18n.lastControleDate}
                            onChange={v => onChangeElement({ lastControleDate: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={zoneList}
                            label={i18n.state}
                            value={stateElement.equipmentStateCode}
                            onChange={v => onChangeElement({ equipmentStateCode: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={stateElement.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

SecurityDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}


const TankSecurityPanel = ({
    tank = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={tank}
            readMode={readMode}
            onChange={onChange}
            keyList='link_securities'
            formatFunction={c => ({
                ...c,
                equipment: getSandreLabel(sandreCodes, SANDRE.RADIO_SECURITE, c.equipment),
                state: getSandreLabel(sandreCodes, SANDRE.RESERVOIRS_ZONES_ETATS, c.equipmentStateCode),
                lastControleDate: getDate(c.lastControleDate),
            })}
            titles={{
                title: i18n.securities,
                edit: i18n.editSecurity,
                new: i18n.newSecurity,
            }}
            headers={headers}
            dialogContent={SecurityDialogContent}
        />

    )
}

TankSecurityPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default TankSecurityPanel