/* eslint-disable camelcase */
import { Button, Grid2 } from '@mui/material'
import ProgressCard from 'components/card/ProgressCard'
import { DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import { groupBy, orderBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useActions from 'utils/customHook/useActions'
import useTitle from 'utils/customHook/useTitle'
import { getYear } from 'utils/DateUtil'
import AgriAction from '../../agriAdministration/actions/AgriAction'
import ServicesAccordion from './services/ServicesAccordion'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Select from 'components/forms/Select'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import ServicesFilters from './services/ServicesFilters'
import { searchAllCharacters } from 'utils/StringUtil'
import { servicesHeaders } from 'exploitations/utils/AgriUtils'

const ServicesManagerApp = ({}) => {
    const dispatch = useDispatch()

    const [expanded, setExpanded] = useState(`panel_${getYear(moment.now())}`)
    const [open, setOpen] = useState(false)
    const [progress, setProgress] = useState(0)
    const [calculationDate, setCalculationDate] = useState(moment.now())
    const [survey, setSurvey] = useState()
    const [dataLoaded, setDataLoaded] = useState(false)

    // filters
    const [search, setSearch] = useState()
    const [city, setCity] = useState()

    const codificationOCTAGRI = useApplicationSetting('codificationOCTAGRI')

    const {
        servicesSituations,
        services,
        summarySurveys,
        exploitationsLight,
    } = useSelector(store => ({
        servicesSituations: store.AgriReducer.servicesSituations,
        services: store.AgriReducer.services,
        summarySurveys: store.AgriReducer.summarySurveys,
        exploitationsLight: store.AgriReducer.exploitationsLight,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.folders,
        href: 'dossiers',
    }, {
        title: i18n.services,
        href: 'dossiers/services',
    }], [])

    useEffect(() => {
        dispatch(AgriAction.fetchServicesSituations({ codificationOCTAGRI })).then(() => setDataLoaded(true))
        if (!services.length) {
            dispatch(AgriAction.fetchServices())
        }
        if (!summarySurveys.length) {
            dispatch(AgriAction.fetchSummarySurveys())
        }
        if (!exploitationsLight.length) {
            dispatch(AgriAction.fetchExploitationsLight())
        }
    }, [])

    useActions(() => [], [])

    const closeModal = () => {
        setProgress(0)
        setOpen(false)
    }

    const getHash = (service) => {
        return searchAllCharacters(servicesHeaders.map(key => service[key] || ''))
    }

    const filteredData = useMemo(() => {
        const searchValue = searchAllCharacters(search)
        const cityFilters = city ? servicesSituations.filter(ss => ss.cityCode === city) : servicesSituations
        return search ? cityFilters.filter(i => getHash(i).includes(searchValue)) : cityFilters
    }, [search, city, servicesSituations])

    const yearGrouped = (groupBy(filteredData, d => getYear(d.calculationDate)))

    return (
        <Grid2 container className='padding-left-2 padding-top-1 padding-right-1' sx={{ paddingBottom: '100px' }} spacing={1}>
            <Grid2 size={6}>
                <ServicesFilters setCity={setCity} setSearch={setSearch} />
            </Grid2>
            <Grid2 size={6}>
                <StyledFieldSet style={{ marginTop: 0 }}>
                    <StyledLegend>{i18n.actions}</StyledLegend>
                    <Grid2 container alignItems='center' spacing={1}>
                        <SimpleDatePicker
                            label={i18n.calculationDate}
                            value={calculationDate}
                            onChange={setCalculationDate}
                            obligatory
                        />
                        <Select
                            label={i18n.survey}
                            value={survey}
                            options={summarySurveys}
                            onChange={setSurvey}
                            keyLabel='nameWithYear'
                        />
                        <Grid2 offset='auto'>
                            <Button
                                variant='contained'
                                onClick={() => {
                                    setProgress(0)
                                    setOpen(true)
                                    const data = survey ? summarySurveys.find(ss => ss.id === survey).link_exploitations : exploitationsLight.map(d => d.idExploitation)
                                    dispatch(AgriAction.calculateServicesSituation(calculationDate, data, setProgress, () => {
                                        setDataLoaded(false)
                                        dispatch(AgriAction.fetchServicesSituations({ codificationOCTAGRI })).then(() => setDataLoaded(true))
                                        closeModal()
                                    }))
                                }}
                            >
                                {i18n.calculate}
                            </Button>
                        </Grid2>
                    </Grid2>
                </StyledFieldSet>
            </Grid2>
            {dataLoaded &&
                orderBy(Object.keys(yearGrouped), d => parseInt(d), ['desc']).map(yearKey => (
                    <ServicesAccordion
                        key={yearKey}
                        data={yearGrouped[yearKey]}
                        yearKey={yearKey}
                        expanded={expanded}
                        setExpanded={setExpanded}
                    />
                )) || <Grid2 size={12}><ProgressCard indeterminate round /></Grid2>
            }
            <DialogMUI open={open}>
                <DialogTitleMUI>{i18n.calcul}</DialogTitleMUI>
                <DialogContentMUI>
                    <ProgressCard
                        className='padding-top-1'
                        progress={progress}
                        withMessage
                        withCancel
                        whenCancel={closeModal}
                    />
                </DialogContentMUI>
            </DialogMUI>
        </Grid2>
    )
}

ServicesManagerApp.propTypes = {
}

export default ServicesManagerApp
