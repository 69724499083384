/* eslint-disable camelcase */
import { Avatar, Button, Card, Grid2, LinearProgress } from '@mui/material'
import { withStyles } from '@mui/styles'
import AgriAction from 'agriAdministration/actions/AgriAction'
import { push } from '@lagunovsky/redux-react-router'
import DtoExploitation from 'exploitations/dto/DtoExploitation'
import HomeAction from 'home/actions/HomeAction'
import InstallationAction from 'installation/actions/InstallationAction'
import DtoInstallationWithGeoItem from 'installation/components/installations/dto/DtoInstallationWithGeoItem'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import DtoIntervenantDeclaration from 'survey/dto/DtoIntervenantDeclaration'
import DtoSurvey from 'survey/dto/DtoSurvey'
import ToastrAction from 'toastr/actions/ToastrAction'
import { mainBlue } from 'utils/constants/ColorTheme'
import { getDate } from 'utils/DateUtil'
import { getSetting, getUser } from 'utils/SettingUtils'
import { getSandreLabel } from 'utils/StringUtil'
import DeclarationContactsStep from './steps/DeclarationContactsStep'
import DeclarationPointsStep from './steps/DeclarationPointsStep'
import DeclarationRecapStep from './steps/DeclarationRecapStep'
import DeclarationSamplerStep from './steps/DeclarationSamplerStep'
import ProgressCard from 'components/card/ProgressCard'

const ADVANCEMENT_STEP = 0
const SAMPLER_STEP = 1
const CONTACTS_STEP = 2
const POINTS_STEP = 3
const RECAP_STEP = 4

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: '25px !important',
        border: '1px solid grey',
    },
    colorPrimary: {
        backgroundColor: `${theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]} !important`,
    },
    bar: {
        backgroundColor: '#a5c7e6 !important',
    },
}))(LinearProgress)

const styleButton = { width: '100%' }

class DeclarationStepperApp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: Number(props.params.step),
            declaration: props.declaration || {},
            progress: [0, 0, 0, 0],
            dataLoaded: false,
            pointsLoaded: false,
            allContributors: [],
            exportLoading: false,
            readMode: props.survey?.statusCode === 3 || getUser()?.consultant === '1',
        }
    }

    componentDidMount() {
        this.props.fetchExploitationUser().then(() => {
            const { exploitation, params } = this.props
            this.props.fetchInstallationsByExploitationId(exploitation.idExploitation).then(() => {
                this.props.fetchDeclaration(params.idDeclaration).then(() => {
                    const { declaration, survey } = this.props
                    if (declaration.idDeclaration) {
                        this.setState({ declaration, pointsLoaded: true, dataLoaded: true, readMode: declaration.statusCode >= 4 || this.state.readMode })
                        this.props.setTitle([{
                            title: i18n.planning,
                            href: 'planning',
                        }, {
                            title: i18n.surveys,
                            href: 'planning/surveys',
                        }, {
                            title: `${survey.name || ''} [${survey.year || ''}]`,
                            href: `survey/${survey.idSurvey}`,
                        }, {
                            title: `${i18n.declaration} ${exploitation.codification}`,
                            href: `survey/${survey.idSurvey}/declaration/${params.idDeclaration}/stepper/0`,
                        }])
                        this.updateProgress()
                    }
                })
            })
        })
    }

    updateProgress = () => {
        const { declaration, progress } = this.state
        const updateProgress = []
        if (declaration.lastStep) {
            const { lastStep } = declaration
            if (lastStep >= SAMPLER_STEP) {
                updateProgress.push(100)
            } else {
                updateProgress.push(0)
            }
            if (lastStep >= CONTACTS_STEP) {
                updateProgress.push(100)
            } else {
                updateProgress.push(0)
            }
            if ((declaration.link_declarationInstallation || []).length) {
                const length = declaration.link_declarationInstallation.length
                let pointsHavingUsages = 0
                declaration.link_declarationInstallation.map((p) => {
                    if ((p.link_usages || []).length) {
                        pointsHavingUsages = pointsHavingUsages + 1
                    }
                })
                const pourcentage = (pointsHavingUsages / length) * 90 + 10
                updateProgress.push(pourcentage)
            } else {
                updateProgress.push(0)
            }
            if (lastStep >= RECAP_STEP) {
                updateProgress.push(100)
            } else {
                updateProgress.push(0)
            }
            this.setState({ progress: updateProgress })
            window.scrollTo(0, 0)
        } else {
            this.setState({ progress })
            window.scrollTo(0, 0)
        }
    }

    onChangeDeclaration = (key, value) => {
        const { declaration } = this.state
        const updateDeclaration = {
            ...declaration,
            [key]: value,
        }
        this.setState({ declaration: updateDeclaration })
        if (key !== 'comment') {
            this.props.updateDeclaration(updateDeclaration)
        }
    }

    startDeclaration = () => {
        const { survey, params } = this.props
        this.props.push(`/survey/${survey.idSurvey}/declaration/${params.idDeclaration}/stepper/${this.getNextStep(0)}`)
        window.scrollTo(0, 0)
    }

    previousStep = () => {
        const { declaration, readMode } = this.state
        const { survey, params } = this.props
        const step = Number(params.step)
        this.props.push(`/survey/${survey.idSurvey}/declaration/${params.idDeclaration}/stepper/${this.getPrevStep(step)}`)
        if (!readMode) {
            this.setState({ dataLoaded: false })
            this.props.updateDeclaration(declaration).then(() => {
                if (step === SAMPLER_STEP || step === CONTACTS_STEP) {
                    this.props.fetchDeclarationContributors(declaration.idSurvey).then(() => {
                        this.setState({ dataLoaded: true })
                        this.updateProgress()
                    })
                } else {
                    this.setState({ dataLoaded: true })
                    this.updateProgress()
                }
            })
        }
    }

    getPrevStep = (actualStep) => {
        const { survey: { link_params = [] } } = this.props
        if ((getSetting(link_params, `step${actualStep - 1}`) || '1') === '0') {
            return this.getPrevStep(actualStep - 1)
        }
        return actualStep - 1
    }

    getNextStep = (actualStep) => {
        const { survey: { link_params = [] } } = this.props
        if ((getSetting(link_params, `step${actualStep + 1}`) || '1') === '0') {
            return this.getNextStep(actualStep + 1)
        }
        return actualStep + 1
    }

    nextStep = () => {
        const { declaration, allContributors, readMode } = this.state
        const { contributors, exploitation, params, survey } = this.props
        const step = Number(params.step)
        const preleveur = contributors.find((c) => c.id === exploitation.operatorCode) || {}
        if (contributors?.length) {
            if (!readMode && step === CONTACTS_STEP &&
                preleveur.structureType !== 1 && !allContributors.find((cT) => cT.contributorType === -1 && cT.idExploitation === exploitation.idExploitation && cT.mode !== 'd')) {
                this.props.error(i18n.fillLegalRepresentative)
            } else {
                this.props.push(`/survey/${survey.idSurvey}/declaration/${params.idDeclaration}/stepper/${this.getNextStep(step)}`)
                if (!readMode) {
                    this.setState({ dataLoaded: false })
                    const updateDeclaration = {
                        ...declaration,
                        lastStep: declaration.lastStep > step ? declaration.lastStep : step,
                        statusCode: declaration.statusCode === 3 ? 3 : 2,
                    }
                    this.props.updateDeclaration(updateDeclaration).then(() => {
                        if (step === 0 || step === 1) {
                            this.props.fetchDeclarationContributors(declaration.idSurvey).then(() => {
                                this.setState({ dataLoaded: true })
                                this.updateProgress()
                            })
                        } else {
                            this.setState({ dataLoaded: true })
                            this.updateProgress()
                        }
                    })
                }
            }
        }
    }

    setContributors = (allContributors) => {
        this.setState({ allContributors })
    }

    getStep = (declaration) => {
        const { progress, pointsLoaded, dataLoaded, readMode } = this.state
        const { survey, sandreCodes, params } = this.props
        const step = Number(params.step)
        const props = {
            declaration,
            onChangeDeclaration: this.onChangeDeclaration,
            dataLoaded,
            previousStep: this.previousStep,
            nextStep: this.nextStep,
            readMode,
            params,
        }
        switch (step) {
            case ADVANCEMENT_STEP:
                if (!dataLoaded) {
                    return <ProgressCard withMessage indeterminate round />
                }
                return (
                    <Grid2 container size={12} justifyContent='center' sx={{ padding: '10px' }}>
                        <Card sx={{ height: 'fit-content' }}>
                            <Grid2
                                container
                                direction='column'
                                justifyContent='center'
                                alignItems='center'
                                sx={{ padding: '0 15px', height: 'fit-content' }}
                            >
                                <Grid2>
                                    <p><span className='bold'>{i18n.survey} : </span>{survey.name || ''}</p>
                                    <p><span className='bold'>{i18n.year} : </span>{survey.year}</p>
                                    <p><span className='bold'>{i18n.status} : </span>{getSandreLabel(sandreCodes, SANDRE.CMS_STATUT, survey.statusCode)}</p>
                                    <p><span className='bold'>{i18n.startDate} : </span>{survey.startDate ? getDate(survey.startDate) : ''}</p>
                                    <p><span className='bold'>{i18n.endDate} : </span>{survey.endDate ? getDate(survey.endDate) : ''}</p>
                                    <p><span className='bold'>{i18n.adminEndDate} : </span>{survey.adminEndDate ? getDate(survey.adminEndDate) : ''}</p>
                                </Grid2>
                                <Grid2>
                                    <h5>{i18n.advancementDeclaration}</h5>
                                </Grid2>
                                <Grid2
                                    container
                                    direction='row'
                                    justifyContent='space-evenly'
                                    alignItems='center'
                                    width={500}
                                    spacing={3}
                                    className='padding-top-2'
                                >
                                    {(getSetting(survey.link_params, 'step1') || '1') !== '0' && (
                                        <>
                                            <Grid2 size={5}>
                                                <BorderLinearProgress
                                                    variant='determinate'
                                                    value={progress[0] || 0}
                                                />
                                            </Grid2>
                                            <Grid2 size={5}>{i18n.verifyCoordinates}</Grid2>
                                        </>
                                    )}
                                    {(getSetting(survey.link_params, 'step2') || '1') !== '0' && (
                                        <>
                                            <Grid2 size={5}>
                                                <BorderLinearProgress
                                                    variant='determinate'
                                                    value={progress[1] || 0}
                                                />
                                            </Grid2>
                                            <Grid2 size={5}>{i18n.verifyExploitation}</Grid2>
                                        </>
                                    )}
                                    {(getSetting(survey.link_params, 'step3') || '1') !== '0' && (
                                        <>
                                            <Grid2 size={5}>
                                                <BorderLinearProgress
                                                    variant='determinate'
                                                    value={progress[2] || 0}
                                                />
                                            </Grid2>
                                            <Grid2 size={5}>{i18n.declareUses}</Grid2>
                                        </>
                                    )}
                                    <>
                                        <Grid2 size={5}>
                                            <BorderLinearProgress
                                                variant='determinate'
                                                value={progress[3] || 0}
                                            />
                                        </Grid2>
                                        <Grid2 size={5}>{i18n.commentAndValidation}</Grid2>
                                    </>
                                </Grid2>
                                <Grid2>
                                    <p>{i18n.declarationStepperDescription}</p>
                                </Grid2>
                            </Grid2>
                        </Card>
                    </Grid2>
                )
            case SAMPLER_STEP: default:
                return <DeclarationSamplerStep {...props} />
            case CONTACTS_STEP:
                return (
                    <DeclarationContactsStep
                        setContributors={this.setContributors}
                        {...props}
                    />
                )
            case POINTS_STEP:
                if (pointsLoaded) {
                    return <DeclarationPointsStep {...props} />
                }
                return (
                    <Grid2 container alignItems='center' size={12}>
                        <ProgressCard withMessage indeterminate round />
                    </Grid2>
                )
            case RECAP_STEP:
                return <DeclarationRecapStep {...props} />
        }
    }

    getStepLibelle = () => {
        const { params } = this.props
        const step = Number(params.step)

        switch (step) {
            case SAMPLER_STEP: case CONTACTS_STEP: default:
                return i18n.iCheckMyInformations
            case POINTS_STEP:
                return i18n.iSelectMyPoints
            case RECAP_STEP:
                return i18n.iCheckMyDeclaration
        }
    }

    render() {
        const { declaration, dataLoaded } = this.state
        const { survey, params } = this.props
        const step = Number(params.step)
        return (
            <>
                {step !== ADVANCEMENT_STEP ? (
                    <Grid2
                        container
                        alignItems='center'
                        justifyContent='center'
                        sx={{
                            padding: '0 10px',
                            backgroundColor: 'white',
                            height: '60px',
                            borderBottom: '1px solid grey',
                        }}
                    >
                        <Grid2 size={2} md={1}>
                            <Avatar sx={{ color: 'white', backgroundColor: `${mainBlue} !important` }}>{step}/4</Avatar>
                        </Grid2>
                        <Grid2 size={10} sx={{ color: 'black' }}>
                            <h5 className='italic-label'>{this.getStepLibelle()}</h5>
                        </Grid2>
                    </Grid2>
                ) : ''}
                <Grid2
                    container
                    justifyContent='space-between'
                    alignItems='stretch'
                    sx={{
                        height: `calc(${step !== ADVANCEMENT_STEP ? '100% - 124px' : '100% - 124px'})`,
                        overflow: 'none',
                    }}
                >
                    {dataLoaded ? this.getStep(declaration) : (
                        <Grid2 size={12} className='padding-1'>
                            <ProgressCard indeterminate withMessage round />
                        </Grid2>
                    )}
                </Grid2>
                {step === ADVANCEMENT_STEP && dataLoaded && (
                    <Grid2
                        container
                        direction='row'
                        justifyContent='space-around'
                        alignItems='center'
                        sx={{
                            height: '60px',
                            backgroundColor: 'white',
                            zIndex: '1000',
                            borderTop: '1px solid grey',
                        }}
                    >
                        <Grid2 size={2}>
                            <Button variant='outlined' color='primary' onClick={() => this.props.push(`/survey/${survey.idSurvey}`)} sx={styleButton}>
                                {i18n.returnToSurvey}
                            </Button>
                        </Grid2>
                        <Grid2 size={2}>
                            <Button variant='outlined' color='primary' onClick={() => this.props.push(`/dossiers/${declaration.idExploitation}/dashboard`)} sx={styleButton}>
                                {i18n.returnToFolder}
                            </Button>
                        </Grid2>
                        <Grid2 size={2}>
                            <Button variant='contained' color='primary' onClick={this.startDeclaration} sx={styleButton}>
                                {i18n.next}
                            </Button>
                        </Grid2>
                    </Grid2>
                )}
            </>
        )
    }
}

const mapDispatchToProps = {
    setTitle: HomeAction.setTitle,
    fetchExploitationUser: AgriAction.fetchExploitationUser,
    fetchDeclaration: AgriAction.fetchDeclaration,
    updateDeclaration: AgriAction.updateDeclaration,
    fetchDeclarationContributors: AgriAction.fetchDeclarationContributors,
    fetchInstallationsByExploitationId: InstallationAction.fetchInstallationsByExploitationId,
    error: ToastrAction.error,
    push,
}

const mapStateToProps = (store) => {
    return {
        declaration: store.AgriReducer.declaration,
        survey: store.AgriReducer.survey,
        declarationContributors: store.AgriReducer.declarationContributors,
        exploitation: store.AgriReducer.exploitation,
        contributors: store.ContributorReducer.contributors,
        installations: store.InstallationReducer.installations,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }
}

DeclarationStepperApp.propTypes = {
    params: PropTypes.shape({
        idDeclaration: PropTypes.string,
        step: PropTypes.string,
    }),
    setTitle: PropTypes.func,
    fetchExploitationUser: PropTypes.func,
    fetchDeclaration: PropTypes.func,
    updateDeclaration: PropTypes.func,
    fetchDeclarationContributors: PropTypes.func,
    error: PropTypes.func,
    push: PropTypes.func,
    declaration: PropTypes.instanceOf(DtoDeclaration),
    location: PropTypes.instanceOf(PropTypes.shape({})),
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    declarationContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenantDeclaration)),
    contributors: PropTypes.arrayOf(PropTypes.instanceOf(ContributorDto)),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    fetchInstallationsByExploitationId: PropTypes.func,
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallationWithGeoItem)),
    survey: PropTypes.instanceOf(DtoSurvey),
    sandreCodes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
}

export default connect(mapStateToProps, mapDispatchToProps)(DeclarationStepperApp)
