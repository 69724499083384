import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { getFullDate, getHour } from '../../../utils/DateUtil'
import { getLabel } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import { Card, CardContent, Grid2 } from '@mui/material'
import Input from 'components/forms/Input'
import { authorizeContributors, authorizeFilter } from 'utils/HabilitationUtil'
import MultiContactsAutocomplete from 'referencial/components/contact/components/MultiContactsAutocomplete'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import useSandreList from 'utils/customHook/useSandreList'
import Select from 'components/forms/Select'
import { onChangeHour } from 'utils/FormUtils'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import StationDescriptionTable from './StationDescriptionTable'

const headers = ['startDate', 'endDate', 'type', 'contributor', 'contact', 'internalReference']

const ContributorDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const {
        contributors,
        contacts,
        accountUser,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        contacts: store.ContactReducer.contacts,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const contributorsList = useSandreList(SANDRE.CONTRIBUTOR_TYPES)


    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            label={i18n.startDate}
                            onChange={v => onChangeElement({ startDate: v })}
                            max={stateElement.endDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(stateElement.startDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateElement.endDate }, stateElement.startDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            label={i18n.endDate}
                            onChange={v => onChangeElement({ endDate: v })}
                            min={stateElement.startDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(stateElement.endDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateElement.startDate }, stateElement.endDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={contributorsList}
                            label={i18n.type}
                            value={stateElement.contributorType}
                            onChange={v => onChangeElement({ contributorType: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <MultiContributorsAutocomplete
                            values={stateElement.idContributor}
                            label={i18n.contributor}
                            onChange={v => onChangeElement({ idContributor: v })}
                            options={authorizeContributors(contributors, accountUser, stateElement.idContributor)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <MultiContactsAutocomplete
                            values={stateElement.contactCode}
                            label={i18n.contact}
                            onChange={v => onChangeElement({ contactCode: v })}
                            options={authorizeFilter(contacts, accountUser, stateElement.contactCode)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.internalReference}
                            value={stateElement.internalReference}
                            onChange={v => onChangeElement({ internalReference: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

ContributorDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const StationContributorPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
    keyList = 'link_contributors',
}) => {
    const {
        contacts,
        sandreCodes,
        contributors,
    } = useSelector(store => ({
        contacts: store.ContactReducer.contacts,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    return (
        <>
            <StationDescriptionTable
                station={station}
                readMode={readMode}
                onChange={onChange}
                keyList={keyList}
                formatFunction={c => ({
                    ...c,
                    startDate: getFullDate(c.startDate),
                    endDate: getFullDate(c.endDate),
                    type: getSandreLabel(sandreCodes, SANDRE.CONTRIBUTOR_TYPES, c.contributorType),
                    contributor: getLabel(contributors, c.idContributor, 'mnemonique'),
                    contact: getLabel(contacts, c.contactCode),
                })}
                titles={{
                    title: i18n.contributors,
                    edit: i18n.editContributorLink,
                    new: i18n.newContributorLink,
                }}
                headers={headers}
                dialogContent={ContributorDialogContent}
            />
        </>
    )
}

StationContributorPanel.propTypes = {
    station: PropTypes.object,
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    keyList: PropTypes.string,
}


export default StationContributorPanel