import { Card, CardContent, Grid2 } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'
import { connect, shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { getDate, getFullDate, getHour } from '../../../utils/DateUtil'
import { onChangeHour } from '../../../utils/FormUtils'
import { hasValue, round } from '../../../utils/NumberUtil'
import { getUpdatedLandmarkDate } from '../../../utils/PiezometryUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import Select from 'components/forms/Select'
import useSandreList from 'utils/customHook/useSandreList'
import NumberField from 'components/forms/NumberField'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import Input from 'components/forms/Input'
import StationDescriptionTable from './StationDescriptionTable'

const headers = ['startDate', 'endDate', 'altimetricSystem', 'height', 'rating', 'origin', 'comment']
const mandatoryFields = [{ field: 'height' }, { field: 'startDate' }, { field: 'altimetrySystemNature', i18n: 'altimetricSystem' }, { field: 'altimetrySystemDate', i18n: 'altimetricSystem' }]

const LandmarkDialogContent = ({
    stateElement,
    onChangeElement = () => { },
    station = {},
}) => {
    const landmarkList = useSandreList(SANDRE.ORIGINE_REPERE)

    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const altimetrySystems = station.link_altimetrySystems

    const altimetrySystemsFiltered = altimetrySystems?.filter(as => hasValue(as.natureCode) && hasValue(as.startDate))
        .map(as => ({
            value: `${as.natureCode}:${as.startDate}`,
            label: `${getSandreLabel(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_NATURE, as.natureCode)} : ${getDate(as.startDate)}`,
        }))
    const selectedAS = hasValue(stateElement.altimetrySystemNature) && hasValue(stateElement.altimetrySystemDate) ? `${stateElement.altimetrySystemNature}:${stateElement.altimetrySystemDate}` : null
    const altimetrySystem = altimetrySystems.find(d => d.startDate === stateElement.altimetrySystemDate)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            label={i18n.startDate}
                            onChange={v => onChangeElement({ startDate: v })}
                            max={stateElement.endDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(stateElement.startDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateElement.endDate }, stateElement.startDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            label={i18n.endDate}
                            onChange={v => onChangeElement({ endDate: v })}
                            min={stateElement.startDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(stateElement.endDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateElement.startDate }, stateElement.endDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={landmarkList}
                            label={i18n.origin}
                            value={stateElement.origin}
                            onChange={v => onChangeElement({ origin: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            label={i18n.altimetricSystem}
                            options={altimetrySystemsFiltered}
                            value={selectedAS}
                            onChange={v => {
                                if (hasValue(v) && v != -1 && v.split(':').length === 2) {
                                    const result = v.split(':')
                                    onChangeElement({ altimetrySystemNature: parseInt(result[0]), altimetrySystemDate: parseInt(result[1]) })
                                } else {
                                    onChangeElement({
                                        altimetrySystemNature: undefined, altimetrySystemDate: undefined,
                                    })
                                }
                            }}
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={stateElement.height}
                            title={i18n.height}
                            onChange={v => onChangeElement({ height: v })}
                            floatValue
                            obligatory
                        />
                    </Grid2>
                    <Grid2 size={3}>
                        <NumberField
                            value={stateElement.height + (altimetrySystem && altimetrySystem.altitude || 0)}
                            title={i18n.pressmark}
                            floatValue
                            disabled
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={stateElement.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

LandmarkDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
}

const StationLandmarkPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
    createOnly = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={(changes, selectedLandmark) => {
                onChange({
                    ...changes,
                    ...getUpdatedLandmarkDate(station, selectedLandmark),
                })
            }}
            keyList='link_landmarks'
            formatFunction={l => {
                const as = station.link_altimetrySystems && station.link_altimetrySystems.find(a => a.natureCode === l.altimetrySystemNature && a.startDate === l.altimetrySystemDate)
                return {
                    ...l,
                    comment: l.comment,
                    startDate: getFullDate(l.startDate),
                    endDate: getFullDate(l.endDate),
                    height: hasValue(l.height) ? l.height.toString() : '',
                    rating: hasValue(as) && hasValue(l.height) ? round(as.altitude + l.height) : '',
                    altimetricSystem: hasValue(as) ?
                        `${getSandreLabel(sandreCodes, SANDRE.ALTIMETRIC_SYSTEM_NATURE, as.natureCode)} - ${getFullDate(as.startDate)}` :
                        { leftIcon: 'warning', leftIconColor: 'red', textColor: 'red', value: i18n.undefinedSysAlti },
                    origin: getSandreLabel(sandreCodes, SANDRE.ORIGINE_REPERE, l.origin),
                }
            }}
            titles={{
                title: i18n.landmarks,
                edit: i18n.editLandmark,
                new: i18n.newLandmark,
            }}
            headers={headers}
            dialogContent={LandmarkDialogContent}
            mandatoryFields={mandatoryFields}
            editable={!createOnly}
            deletable={!createOnly}
        />
    )
}

StationLandmarkPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    createOnly: PropTypes.bool,
}

const mapStateToProps = store => ({
    sandreCodes: store.ReferencialReducer.sandreCodes,
})

export default connect(mapStateToProps)(StationLandmarkPanel)