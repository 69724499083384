/* eslint-disable camelcase */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { Dialog, Button, Grid2, DialogActions, DialogContent } from '@mui/material'
import { getDate } from 'utils/DateUtil'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import NumberField from 'components/forms/NumberField'
import DtoSTEPCollectionBasin from '../dto/DtoSTEPCollectionBasin'
import Input from 'components/forms/Input'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { StyledFieldSet } from 'components/StyledElements'
import STEPCollectionsBasinsImportModal from './modal/STEPCollectionsBasinsImportModal'
import ExportFileModal from 'components/modal/ExportFileModal'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ExportAction from 'export/actions/ExportAction'
import { formatData } from 'utils/ExportDataUtil'
import { CardTable } from 'components/datatable/NewTable'
import { greyBlue } from 'utils/constants/ColorTheme'

const exportHeaders = ['basinIdentifier', 'basinName', 'connectionDate', 'countLinkSub', 'countLinkEstablishment']
const syntheticExportHeaders = ['siret', 'internalReferences', 'name', 'cityCode', 'city', 'categoryActivity', 'activityCode', 'activityLibelle', 'staff', 'effectiveTranche', 'icpe', 'dischargeAgreement', 'basinIdentifier', 'basinName']
const fullExportHeaders = ['siret', 'internalReferences', 'name', 'cityCode', 'city', 'x', 'y', 'projection', 'status', 'categoryActivity', 'activityCode', 'activityLibelle', 'activityCode2', 'activityLibelle2', 'activityCode3', 'activityLibelle3', 'activityCode4', 'activityLibelle4', 'staff', 'effectiveTranche', 'icpe', 'dischargeAgreement', 'basinIdentifier', 'basinName']

const STEPCollectionBasinsPanel = ({
    STEP = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        installation,
    } = useSelector(store => ({
        installation: store.InstallationReducer.installation,
    }), shallowEqual)
    const dispatch = useDispatch()

    const headers = [
        { key: 'basinIdentifier', value: i18n.identifier },
        { key: 'basinName', value: i18n.name },
        'connectionDate',
        'countLinkSub',
        'countLinkEstablishment',
    ]

    const [isOpen, setIsOpen] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(null)
    const [isOpenExport, setIsOpenExport] = useState(false)

    // basin state
    const [basinIdentifier, setBasinIdentifier] = useState(null)
    const [basinName, setBasinName] = useState(null)
    const [countLinkSub, setCountLinkSub] = useState(null)
    const [countLinkEstablishment, setCountLinkEstablishment] = useState(null)
    const [connectionDate, setConnectionDate] = useState(null)

    // import state
    const [isOpenImport, setIsOpenImport] = useState(false)

    const STEPBasins = STEP.link_basins.map((u, index) => ({
        ...u,
        index,
    }))
    const tableData = STEPBasins.map(es => ({
        ...es,
        connectionDate: getDate(es.connectionDate),
    }))

    const exportAction = {
        icon: 'file_download',
        color: 'white',
        tooltip: i18n.export,
        onClick: () => setIsOpenExport(true),
    }

    const actions = !readMode ? [{
        icon: 'note_add',
        color: 'white',
        tooltip: i18n.add,
        onClick: () => {
            setIsNew(true)
            setIsOpen(true)
        },
    }, {
        icon: 'file_upload',
        color: 'white',
        tooltip: i18n.importFile,
        onClick: () => setIsOpenImport(true),
    }, exportAction] : [exportAction]

    const resetPopup = () => {
        setIsOpen(false)
        setIsNew(false)
        setSelectedIndex(null)
        setBasinIdentifier(null)
        setBasinName(null)
        setCountLinkSub(null)
        setCountLinkEstablishment(null)
        setConnectionDate(null)
    }

    const changeBasin = () => {
        const selectedBasin = STEPBasins.find(ls => ls.index === selectedIndex)
        const newBasin = new DtoSTEPCollectionBasin({
            ...selectedBasin,
            idInstallation: STEP.idStation,
            basinIdentifier,
            basinName,
            connectionDate,
            countLinkSub,
            countLinkEstablishment,
        })
        if (isNew) {
            onChange({
                link_basins: [...STEPBasins, newBasin],
            })
        } else {
            onChange({
                link_basins: [
                    ...STEPBasins.slice(0, selectedIndex),
                    { ...newBasin, index: selectedIndex },
                    ...STEPBasins.slice(selectedIndex + 1),
                ],
            })
        }
        resetPopup()
    }

    const setModal = (selectedBasin) => {
        const element = STEPBasins.find(es => selectedBasin.index === es.index)
        setIsOpen(true)
        setSelectedIndex(element.index)
        setBasinIdentifier(element.basinIdentifier)
        setBasinName(element.basinName)
        setCountLinkSub(element.countLinkSub)
        setCountLinkEstablishment(element.countLinkEstablishment)
        setConnectionDate(element.connectionDate)
    }

    const exportData = (data, type, curExportHeaders, title) => {
        const dataWithHeader = data.length > 0 ? [{ ...data[0], headers: curExportHeaders }, ...data.slice(1)] : []
        const dataFormatted = formatData(dataWithHeader)
        dispatch(ExportAction.export(dataFormatted, type, title))
    }

    const indusSiteExport = (type, curExportHeaders) => {
        ExportAction.fetchStepBasinsExport(STEP.idStation).then(jsonData => {
            exportData(jsonData, type, curExportHeaders, `${i18n.indusSiteList}_${installation?.name.replaceAll(' ', '_')}`)
        })
    }

    const lineActions = [{
        icon: 'close',
        onClick: (element) => onChange({ link_basins: STEPBasins.filter(u => u.index !== element.index) }),
        displayed: !readMode,
    }, {
        icon: 'edit',
        onClick: (element) => setModal(element),
        displayed: !readMode,
    }]

    return (
        <Grid2 id='usages'>
            <CardTable
                title={i18n.collectionBasins}
                rows={tableData}
                headers={headers}
                actions={actions}
                lineActions={lineActions}
                color={greyBlue}
            />
            {isOpen && (
                <Dialog maxWidth='lg' open={isOpen}>
                    <DefaultDialogTitle title={isNew ? i18n.editBasin : i18n.editBasin} onClose={resetPopup} />
                    <DialogContent style={{ padding: '10 20 15' }}>
                        <StyledFieldSet>
                            <Grid2 container columnSpacing={1}>
                                <Grid2 size={6}>
                                    <Input
                                        title={i18n.identifier}
                                        value={basinIdentifier}
                                        onChange={setBasinIdentifier}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <Input
                                        title={i18n.name}
                                        value={basinName}
                                        onChange={setBasinName}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <SimpleDatePicker
                                        value={connectionDate}
                                        style={{ padding: 0 }}
                                        id='connectionDate'
                                        label={i18n.connectionDate}
                                        onChange={setConnectionDate}
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <NumberField
                                        title={i18n.countLinkEstablishment}
                                        value={countLinkEstablishment}
                                        disabled
                                    />
                                </Grid2>
                                <Grid2 size={6}>
                                    <NumberField
                                        title={i18n.countLinkSub}
                                        value={countLinkSub}
                                        disabled
                                    />
                                </Grid2>
                            </Grid2>
                        </StyledFieldSet>
                    </DialogContent>
                    <DialogActions>
                        <Grid2 container justifyContent='center'>
                            <Button onClick={changeBasin} variant='contained'>
                                {isNew ? i18n.add : i18n.change}
                            </Button>
                        </Grid2>
                    </DialogActions>
                </Dialog>
            )
            }
            <ExportFileModal
                open={isOpenExport}
                onClose={() => setIsOpenExport(false)}
                closeOnExport
                data={
                    [{
                        name: i18n.export,
                        group: 0,
                        formats: [{
                            type: i18n.excelFile,
                            callback: () => exportData(tableData, 'xlsx', exportHeaders, i18n.basinsList),
                        }, {
                            type: i18n.csvFile,
                            callback: () => exportData(tableData, 'csv', exportHeaders, i18n.basinsList),
                        }],
                    }, {
                        name: i18n.syntheticExport,
                        group: 1,
                        formats: [{
                            type: i18n.excelFile,
                            callback: () => indusSiteExport('xlsx', syntheticExportHeaders, `${i18n.indusSiteList}`),
                        }, {
                            type: i18n.csvFile,
                            callback: () => indusSiteExport('csv', syntheticExportHeaders, `${i18n.indusSiteList}`),
                        }],
                    }, {
                        name: i18n.exportFull,
                        group: 1,
                        formats: [{
                            type: i18n.excelFile,
                            callback: () => indusSiteExport('xlsx', fullExportHeaders, `${i18n.indusSiteList}`),
                        }, {
                            type: i18n.csvFile,
                            callback: () => indusSiteExport('csv', fullExportHeaders, `${i18n.indusSiteList}`),
                        }],
                    }]
                }
                groups={[{
                    value: 0,
                    name: i18n.basinsList,
                }, {
                    value: 1,
                    name: i18n.indusSiteList,
                }]}
            />

            <STEPCollectionsBasinsImportModal open={isOpenImport} setOpen={setIsOpenImport} STEP={STEP} />
        </Grid2 >
    )
}

STEPCollectionBasinsPanel.propTypes = {
    STEP: PropTypes.object, // DtoStep
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default STEPCollectionBasinsPanel