import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import { push } from '@lagunovsky/redux-react-router'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { isEqual, omit } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { greyBlue } from 'utils/constants/ColorTheme'
import { arrayOf } from 'utils/StoreUtils'
import ActionComponent from '../../../components/ActionComponent'
import Checkbox from '../../../components/forms/Checkbox'
import NumberField from '../../../components/forms/NumberField'
import SieauAction from '../../../components/sieau/SieauAction'
import QualityAction from '../../../quality/actions/QualityAction'
import AquiferAction from '../../../referencial/components/aquifers/actions/AquiferAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import StationAction from '../../../station/actions/StationAction'
import AppStore from '../../../store/AppStore'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { downloadURI } from '../../../utils/ExportDataUtil'
import { getSetting, getUser } from '../../../utils/SettingUtils'
import { getLinks } from '../../../utils/StationUtils'
import { getVisitCheckBoxChange } from '../../../utils/VisitUtils'
import InstallationAction from '../../actions/InstallationAction'
import { INSTALLATION_TYPE } from '../../constants/InstallationConstants'
import DtoInstallationBorehole from '../../dto/borehole/DtoInstallationBorehole'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import BoreholeAquiferTable from './aquifer/components/BoreholeAquiferTable'
import DtoBoreholeAquifer from './aquifer/dto/DtoBoreholeAquifer'
import BoreholeCommentTable from './boreholeComment/components/BoreholeCommentTable'
import BoreholeCasingTable from './casing/components/BoreholeCasingTable'
import BoreholeCivilGenPanel from './civilGen/BoreholeCivilGenPanel'
import BoreholeDrillingTable from './drilling/components/BoreholeDrillingTable'
import BoreholeEquipmentsPanel from './equipments/BoreholeEquipmentsPanel'
import BoreholeLithologyTable from './lithology/components/BoreholeLithologyTable'
import BoreholeManagementPanel from './management/BoreholeManagementPanel'
import GenericInstallationDescription from '../installationDefault/GenericInstallationDescription'
import { Card, CardContent, Grid2 } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'
import BoreholeCharacteristicsPanel from './BoreholeCharacteristicsPanel'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { isDisplayed } from 'utils/InstallationUtils'

class BoreholeDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            borehole: new DtoInstallationBorehole({}),
            readMode: true,
        }
    }

    componentDidMount() {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            if (this.props.installation.installationType === INSTALLATION_TYPE.BOREHOLE) {
                AppStore.dispatch(InstallationAction.fetchInstallationBorehole(this.props.installation.id))
            }
        }

        if (this.props.installationBorehole.id) {
            this.setState({ borehole: this.props.installationBorehole })
        }

        if (!this.props.installationBorehole.id && this.props.installation.installationType === INSTALLATION_TYPE.BOREHOLE) {
            this.setState({ borehole: { ...this.state.borehole, id: this.props.installation.id } })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        if (!this.props.watersheds.length) {
            this.props.fetchWatersheds()
        }

        if (!this.props.natures.length) {
            this.props.fetchNatures()
        }

        if (!this.props.aquifers.length) {
            this.props.fetchAquifers()
        }

        if (!this.props.fieldModes.length) {
            this.props.fetchFieldModes()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate(prevProps) {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
            if (this.props.installation.installationType === INSTALLATION_TYPE.BOREHOLE) {
                AppStore.dispatch(InstallationAction.fetchInstallationBorehole(this.props.installation.id))
            }
        }
        if (prevProps.installationBorehole.id !== this.props.installationBorehole.id) {
            this.setState({ borehole: this.props.installationBorehole })
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () => this.props.push('/installation'))
    }

    getObjectIfHasChanged = (propsObject, stateObject) => {
        if (!isEqual(propsObject, stateObject)) {
            return stateObject
        }
        return null
    }

    onSave = (visit) => {
        const { installation, borehole } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    borehole,
                },
            ).then(() => this.setReadOnlyMode())
        }

        return this.props.updateInstallationBorehole(
            installation,
            borehole,
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, borehole: this.props.installationBorehole })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) => this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    onChangeBorehole = (newObject) => this.setState({ borehole: { ...this.state.borehole, ...newObject } })

    onChangeAquifer = (value) => {
        const aquifers = (this.state.borehole.link_aquifers || []).filter(a => a.order !== 0)
        const obj = {
            ...this.state.borehole,
            link_aquifers: value ? [...aquifers, new DtoBoreholeAquifer({ aquiferCode: value, order: 0, idStation: this.props.installation.id })] : aquifers,
        }

        this.setState({ borehole: obj })
    }

    isDisplayed = (panel) => {
        return getSetting(this.props.applicationSettings, `PANEL_${this.state.installation.installationType}_${panel}`) !== 'hidden'
    }

    render() {
        const { installation, borehole, readMode } = this.state
        const { applicationSettings } = this.props
        const mode = { readMode, editMode: !readMode }

        const aquifer = borehole.link_aquifers && borehole.link_aquifers.length ? borehole.link_aquifers.find(a => a.order === 0) || {} : {}
        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        const boreholeParams = {
            station: borehole,
            borehole,
            onChange: this.onChangeBorehole,
            onChangeVisit: this.onChangeVisit,
            readMode,
        }

        return (
            <GenericInstallationDescription
                readMode={readMode}
                installation={installation}
                onChangeInstallation={this.onChangeInstallation}
                onChangeVisit={this.onChangeVisit}
                descElements={
                    <>
                        <Grid2 size={6}>
                            <NumberField
                                value={borehole.sharedVolumeCapture}
                                title={i18n.sharedVolumeCapture}
                                onChange={v => {
                                    this.onChangeBorehole({ sharedVolumeCapture: v })
                                    this.onChangeVisit({ previousValue: borehole.sharedVolumeCapture, newValue: v, field: i18n.sharedVolumeCapture })
                                }}
                                {...mode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                value={borehole.operatingDayTime}
                                title={i18n.operatingDayTime}
                                onChange={v => {
                                    this.onChangeBorehole({ operatingDayTime: v })
                                    this.onChangeVisit({ previousValue: borehole.operatingDayTime, newValue: v, field: i18n.operatingDayTime })
                                }}
                                {...mode}
                            />
                        </Grid2>
                        <Grid2 size={6}>
                            <NumberField
                                title={i18n.withdrawnFlowTest}
                                value={borehole.withdrawnFlowTest}
                                onChange={v => {
                                    this.onChangeBorehole({ withdrawnFlowTest: v })
                                    this.onChangeVisit({ previousValue: borehole.withdrawnFlowTest, newValue: v, field: i18n.withdrawnFlowTest })
                                }}
                                floatValue
                                {...mode}
                            />
                        </Grid2>
                        <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                            <Card>
                                <CardTitle title={i18n.management} color={greyBlue} />
                                <CardContent>
                                    <Grid2 container columnSpacing={1}>
                                        <BoreholeManagementPanel {...boreholeParams} />
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                        <Grid2 size={12} sx={{ marginBottom: '5px' }}>
                            <Card>
                                <CardTitle title={i18n.civilEngineering} color={greyBlue} />
                                <CardContent>
                                    <Grid2 container columnSpacing={1}>
                                        <BoreholeCivilGenPanel {...boreholeParams} />
                                        <Grid2 size={12}>
                                            <BuildingsPanel {...params} />
                                        </Grid2>
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                        <Grid2 size={12}>
                            <Card>
                                <CardTitle title={i18n.equipments} color={greyBlue} />
                                <CardContent>
                                    <Grid2 container columnSpacing={1}>
                                        <BoreholeEquipmentsPanel {...boreholeParams} />
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                    </>
                }
                otherElements={
                    <>
                        <Grid2 size={12}>
                            <Card>
                                <CardTitle title={i18n.characteristics} />
                                <CardContent>
                                    <Grid2 container columnSpacing={1}>
                                        <BoreholeCharacteristicsPanel
                                            onChangeInstallation={this.onChangeInstallation}
                                            onChangeBorehole={this.onChangeBorehole}
                                            onChangeVisit={this.onChangeVisit}
                                            borehole={borehole}
                                            installation={installation}
                                            readMode={readMode}
                                            aquifer={aquifer}
                                            onChangeAquifer={this.onChangeAquifer}
                                        />
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                        {isDisplayed(applicationSettings, 'DRILLINGS', INSTALLATION_TYPE.BOREHOLE) && (
                            <Grid2 size={12}>
                                <BoreholeDrillingTable {...boreholeParams} />
                            </Grid2>
                        )}
                        {isDisplayed(applicationSettings, 'COMMENTS', INSTALLATION_TYPE.BOREHOLE) && (
                            <Grid2 size={12}>
                                <BoreholeCommentTable {...boreholeParams} />
                            </Grid2>
                        )}
                        {isDisplayed(applicationSettings, 'LITHOLOGY', INSTALLATION_TYPE.BOREHOLE) && (
                            <Grid2 size={12}>
                                <BoreholeLithologyTable {...boreholeParams} />
                            </Grid2>
                        )}
                        {isDisplayed(applicationSettings, 'STRATIGRAPHY', INSTALLATION_TYPE.BOREHOLE) && (
                            <Grid2 size={12}>
                                <BoreholeAquiferTable {...boreholeParams} onChangeAquifer={this.onChangeAquifer} />
                            </Grid2>
                        )}
                        {isDisplayed(applicationSettings, 'CASINGS', INSTALLATION_TYPE.BOREHOLE) && (
                            <Grid2 size={12}>
                                <BoreholeCasingTable {...boreholeParams} />
                            </Grid2>
                        )}
                        <Grid2 size={12}>
                            <Card>
                                <CardContent>
                                    <Grid2 container columnSpacing={1}>
                                        <Grid2 size={6}>
                                            <Checkbox c
                                                label={i18n.superficialWaterIsolation}
                                                checked={borehole.superficialWaterIsolation}
                                                onChange={(v) => {
                                                    this.onChangeBorehole({ superficialWaterIsolation: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.superficialWaterIsolation, borehole.superficialWaterIsolation, v))
                                                }} disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox c
                                                label={i18n.isolationToVerify}
                                                checked={borehole.isolationToVerify}
                                                onChange={(v) => {
                                                    this.onChangeBorehole({ isolationToVerify: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.isolationToVerify, borehole.isolationToVerify, v))
                                                }} disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox c
                                                label={i18n.supIsolationToVerify}
                                                checked={borehole.supIsolationToVerify}
                                                onChange={(v) => {
                                                    this.onChangeBorehole({ supIsolationToVerify: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.supIsolationToVerify, borehole.supIsolationToVerify, v))
                                                }} disabled={readMode}
                                            />
                                        </Grid2>

                                        <Grid2 size={6}>
                                            <Checkbox c
                                                label={i18n.nonIsolatedWaterTables}
                                                checked={borehole.nonIsolatedWaterTables}
                                                onChange={(v) => {
                                                    this.onChangeBorehole({ nonIsolatedWaterTables: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.nonIsolatedWaterTables, borehole.nonIsolatedWaterTables, v))
                                                }} disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <Checkbox c
                                                label={i18n.isolatedWaterTables}
                                                checked={borehole.isolatedWaterTables}
                                                onChange={(v) => {
                                                    this.onChangeBorehole({ isolatedWaterTables: v })
                                                    this.onChangeVisit(getVisitCheckBoxChange(i18n.isolatedWaterTables, borehole.isolatedWaterTables, v))
                                                }} disabled={readMode}
                                            />
                                        </Grid2>
                                        <Grid2 size={6}>
                                            <SimpleTextArea
                                                value={borehole.isolationComment}
                                                title={i18n.isolationComment}
                                                onChange={v => {
                                                    this.onChangeBorehole({ isolationComment: v })
                                                    this.onChangeVisit({ previousValue: installation.isolationComment, newValue: v, field: i18n.isolationComment })
                                                }} {...mode}
                                            />
                                        </Grid2>
                                    </Grid2>
                                </CardContent>
                            </Card>
                        </Grid2>
                    </>
                }
            />
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

BoreholeDescriptionPanel.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    installationBorehole: store.InstallationReducer.installationBorehole,
    watersheds: store.WatershedReducer.watersheds,
    natures: store.StationReducer.natures,
    aquifers: store.AquiferReducer.aquifers,
    fieldModes: store.StationReducer.fieldModes,
    installationVisits: store.InstallationReducer.installationVisits,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    push,
    delete: SieauAction.delete,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchNatures: StationAction.fetchNatures,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchFieldModes: StationAction.fetchFieldModes,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationBorehole: InstallationAction.updateInstallationBorehole,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
}

export default connect(mapStateToProps, mapDispatchToProps)(BoreholeDescriptionPanel)