/* eslint-disable camelcase */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Step, StepLabel, Stepper } from '@mui/material'
import Card from 'components/card/Card'
import { push } from '@lagunovsky/redux-react-router'
import CmsAction from 'events/actions/CmsAction'
import InstallationAction from 'installation/actions/InstallationAction'
import InstVisitDanger from 'installation/components/campaign/validation/InstVisitDanger'
import InstVisitDiagnostic from 'installation/components/campaign/validation/InstVisitDiagnostic'
import { DANGER_ELEMENT_TYPE } from 'installation/constants/InstallationConstants'
import { flatten } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import i18n from 'simple-react-i18n'
import { STEP } from 'station/constants/CampaignConstants'
import { greyBlue } from 'utils/constants/ColorTheme'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { exportFile } from 'utils/ExportDataUtil'
import { getSandreLabel } from 'utils/StringUtil'

const InstallationDangerModal = ({
    open = false,
    setOpen = () => { },
}) => {
    const dispatch = useDispatch()

    const [step, setStep] = useState(STEP.DIAGNOSTIC)
    const [title, setTitle] = useState('')

    const {
        danger,
        visit,
        cmsSurvey,
        installation,
        sandreCodes,
        installationsTypes,
    } = useSelector(store => ({
        danger: store.InstallationReducer.danger,
        visit: store.InstallationReducer.visit,
        cmsSurvey: store.EventsReducer.cmsSurvey,
        installation: store.InstallationReducer.installation,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        installationsTypes: store.InstallationReducer.installationsTypes,
    }), shallowEqual)

    useEffect(() => {
        dispatch(InstallationAction.fetchInstallationTypes())
    }, [])

    useEffect(() => {
        if (visit.idSurvey && installation.id) {
            dispatch(CmsAction.fetchCMSSurveyRestricted(visit.idSurvey, installation.id))
        }
    }, [visit.idSurvey, installation.id])

    useUpdateEffect(() => {
        if (danger.idDanger && danger.siteType == DANGER_ELEMENT_TYPE.INSTALLATION) {
            dispatch(InstallationAction.fetchVisit(danger.siteCode, danger.idCampaign))
        }
    }, [danger])

    useUpdateEffect(() => {
        if (visit.idVisit) {
            setOpen(true)
            dispatch(InstallationAction.fetchInstallation(visit.idInstallation))
        }
    }, [visit])

    useUpdateEffect(() => {
        const code = installation.code ? `[${installation.code}]` : ''
        const name = installation.name ? `${installation.name}` : ''
        const type = installationsTypes.find(i => i.id === installation.installationType)?.name || ''

        setTitle(
            <span>
                <span className='clickable' onClick={() => this.props.push(`/station/installation/${installation.id}`)}>
                    {`${code} ${name} - ${type} `}
                </span>
            </span>,
        )
    }, [installation])

    const getDiagnosticData = (paragraph, question) => {
        const answer = visit.link_answers.find(la => la.idQuestion === question.idQuestion) || {}
        return {
            ...installation,
            paragraph: paragraph.description,
            question: question.description,
            answer: answer?.answerMultiChoice && i18n[answer.answerMultiChoice],
            state: question.sandreField ? getSandreLabel(sandreCodes, question.sandreField, answer.answerLong) : '',
            isStateProblem: answer.answerBool,
            comment: answer.answerText,
        }
    }

    const getDiagnosticExportData = () => {
        const data = flatten(cmsSurvey.link_paragraphs.map(paragraph =>
            paragraph.link_questions.map(question =>
                getDiagnosticData(paragraph, question),
            ),
        ))
        return data.length ? [{ ...data[0], headers: ['code', 'name', 'paragraph', 'question', 'answer', 'state', 'isStateProblem', 'comment'] }, ...data.slice(1, data.length)] : []
    }

    const getDangerExportData = () => {
        const data = visit.link_dangers.map(exportDanger => {
            const paragraph = exportDanger.idLinkedParagraph >= 0 ? cmsSurvey.link_paragraphs.find(la => la.idParagraph === exportDanger.idLinkedParagraph) : {}
            const question = exportDanger.idLinkedQuestion >= 0 && paragraph.link_questions ? paragraph.link_questions.find(lq => lq.idQuestion === exportDanger.idLinkedQuestion) : {}
            const answer = visit.link_answers.find(la => la.idQuestion === exportDanger.idLinkedQuestion) || {}
            return {
                ...installation,
                ...exportDanger,
                paragraph: paragraph.description,
                question: question.description,
                answer: answer.answerMultiChoice,
                state: question.sandreField ? getSandreLabel(sandreCodes, question.sandreField, answer.answerLong) : '',
                isStateProblem: answer.answerBool,
                comment: answer.answerText,
                dangerousSituation: getSandreLabel(sandreCodes, SANDRE.INSTALLATIONS_DANGERS, exportDanger.dangerousSituation),
                dangerComment: exportDanger.comment,
            }
        })

        return data.length ? [{ ...data[0], headers: ['code', 'name', 'paragraph', 'question', 'answer', 'state', 'isStateProblem', 'comment', 'dangerousSituation', 'dangerComment'] }, ...data.slice(1, data.length)] : 0
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='lg'
        >
            <DialogTitle>
                <Stepper
                    activeStep={step - 2}
                    style={{ zoom: '1.5', height: '30px' }}
                >
                    <Step
                        className='clickable'
                        onClick={() => setStep(STEP.DIAGNOSTIC)}
                    >
                        <StepLabel>{i18n.diagnostic}</StepLabel>
                    </Step>
                    <Step
                        className='clickable'
                        onClick={() => setStep(STEP.DANGERS)}
                    >
                        <StepLabel><div style={{ whiteSpace: 'pre-line' }}>{i18n.dangerousSituations}</div></StepLabel>
                    </Step>
                </Stepper>
            </DialogTitle>
            <DialogContent>
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <Card
                            title={title}
                            headerStyle={{ backgroundColor: greyBlue }}
                            actions={[
                                step === STEP.DIAGNOSTIC && {
                                    iconName: 'file_download',
                                    tooltip: i18n.export,
                                    onClick: () => {
                                        setOpen(false)
                                        exportFile({
                                            data: getDiagnosticExportData(),
                                            titleFile: `${i18n.diagnostic}_${installation.name || installation.code}`,
                                        })
                                    },
                                },
                                step === STEP.DANGERS && {
                                    iconName: 'file_download',
                                    tooltip: i18n.export,
                                    onClick: () => {
                                        setOpen(false)
                                        exportFile({
                                            data: getDangerExportData(),
                                            titleFile: `${i18n.dangers}_${installation.name || installation.code}`,
                                        })
                                    },
                                },
                            ].filter(d => !!d)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {step === STEP.DIAGNOSTIC && <InstVisitDiagnostic visit={visit} readMode />}
                        {step === STEP.DANGERS && <InstVisitDanger visit={visit} readMode />}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(push(`/installation/campaign/${danger.idCampaign}/visit/${danger.siteCode}/${step}`))} variant='outlined'>
                    {i18n.modifVisit}
                </Button>
                <Button onClick={() => setOpen(false)} variant='contained' color='primary'>
                    {i18n.close}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

InstallationDangerModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default InstallationDangerModal
