import { Button as ButtonMUI, ButtonGroup } from '@mui/material'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import i18n from 'simple-react-i18n'
import useLocalStorage from '../../utils/customHook/useLocalStorage'
import { getDate } from '../../utils/DateUtil'
import { getLocalStorageJson } from '../../utils/FormUtils'
import Button from '../forms/Button'
import Input from '../forms/Input'
import SimpleDatePicker from '../forms/SimpleDatePicker'

const CHART_TABS_SUIVI_PLUVIO = 'CHART_TABS_SUIVI_PLUVIO'
const CIVIL_YEAR = 'CIVIL_YEAR'
const HYDRO_YEAR = 'HYDRO_YEAR'
const HISTO = 'HISTO'
const OTHER = 'OTHER'


const ChartTabsSuiviGlobal = ({
    onChangeDate = () => {},
    // withPluvioCote= true,
    // changeParent= () => {},
    defaultValue= {},
}) => {
    const previousSelected = getLocalStorageJson(CHART_TABS_SUIVI_PLUVIO) || defaultValue || {}
    const [chartData, setChartData] = useLocalStorage(CHART_TABS_SUIVI_PLUVIO, defaultValue)
    const [otherTabSelected, setOtherTabSelected] = useState(false)
    const [ otherMinDate, setOtherMinDate] = useState(previousSelected.minDate || moment().startOf('year').valueOf())
    const [ otherMaxDate, setOtherMaxDate] = useState(previousSelected.maxDate || moment().valueOf())

    const onSelectOtherTab = () => {
        const changes = { otherTabSelected: !otherTabSelected, settingsTabSelected: false }
        setOtherTabSelected(!otherTabSelected)

        if (chartData.minDate) {
            if (chartData.maxDate) {
                const newChanges = { ...changes, minDate: chartData.minDate, maxDate: chartData.maxDate, active: OTHER }
                setChartData(newChanges)
                onChangeDate({ minDate: newChanges.minDate, maxDate: newChanges.maxDate })
            } else {
                const newChanges = { ...changes, minDate: chartData.minDate, active: OTHER }
                setChartData(newChanges)
                onChangeDate({ minDate: newChanges.minDate, maxDate: moment().valueOf() })
            }
        }
    }

    const onSubmitOtherDates = () => {
        const newMinDate = otherMinDate ? moment(otherMinDate).startOf('day').valueOf() : null
        const newMaxDate = otherMaxDate ? moment(otherMaxDate).endOf('day').valueOf() : null
        if (newMinDate && newMaxDate) {
            setChartData({ minDate: newMinDate, maxDate: newMaxDate, active: OTHER })
            onChangeDate({ minDate: newMinDate, maxDate: newMaxDate })
        }
    }


    const setHisto = () => {
        const obj = { active: HISTO, minDate: null, maxDate: moment().valueOf() }
        setChartData(obj)
        onChangeDate({ minDate: null, maxDate: moment().valueOf(), tab: HISTO })
    }

    const setCivilYear = () => {
        const obj = { active: CIVIL_YEAR }
        setChartData(obj)
        onChangeDate({ minDate: moment().startOf('year').valueOf(), maxDate: moment().endOf('year').valueOf(), tab: CIVIL_YEAR })
    }

    const setHydroYear = () => {
        const obj = { active: HYDRO_YEAR }
        setChartData(obj)
        const minDate = moment().month(9).year(moment().month() >= 9 ? moment().year() : moment().year() - 1)
        onChangeDate({ minDate: minDate.startOf('month').valueOf(), maxDate: minDate.add(1, 'year').month(9).startOf('month').valueOf(), tab: HYDRO_YEAR })
    }

    const getActive = (value) => {
        return chartData.active === value ? 'contained' : 'outlined'
    }

    useEffect(()=> {
        if (previousSelected.active === HISTO) {
            setHisto()
        } else if (previousSelected.active === CIVIL_YEAR) {
            setCivilYear()
        } else if (previousSelected.active === HYDRO_YEAR) {
            setHydroYear()
        } else {
            onChangeDate({ minDate: previousSelected.minDate, maxDate: previousSelected.maxDate }, true)
        }
    }, [])


    const buttons = [
        <ButtonMUI color='primary' variant={ getActive(CIVIL_YEAR) } style={{ marginLeft: '0.75rem', borderWidth: 2, borderColor: 'inherit', padding: '6px 15px', fontWeight: 600 }} onClick={ setCivilYear }>{i18n.civilYear}</ButtonMUI>,
        <ButtonMUI color='primary' variant={ getActive(HYDRO_YEAR) } style={{ borderWidth: 2, borderColor: 'inherit', padding: '6px 15px', fontWeight: 600 }} onClick={ setHydroYear }>{i18n.hydroYear}</ButtonMUI>,
        <ButtonMUI color='primary' variant={ getActive(HISTO) } style={{ borderWidth: 2, borderColor: 'inherit', padding: '6px 15px', fontWeight: 600 }} onClick={ setHisto }>{i18n.histo}</ButtonMUI>,
        <span onClick={onSelectOtherTab}><ButtonMUI color='primary' variant={ getActive(OTHER) } style={{ marginLeft: '0.75rem', borderWidth: 2, borderColor: 'inherit', padding: '6px 15px', fontWeight: 600 }} onClick={ onSelectOtherTab }>{i18n.other}</ButtonMUI></span>,
    ]

    return (
        <div className='absolute row no-margin no-padding z-index-10'>
            <div className='row no-margin'>
                <ButtonGroup>
                    { buttons }
                </ButtonGroup>
                <div className={ otherTabSelected ? 'chartDates-dropdown' : 'hidden' }>
                    <div style={ { height: '10px' } }/>
                    <div className='sieau-dropdown-panel padding-top-1 padding-bottom-1' style={ { width: '200px' } }>
                        <div className='collection-item row no-margin valign-wrapper' >
                            <SimpleDatePicker col={ 12 } label={ i18n.startDate } value={ otherMinDate } onChange={ setOtherMinDate } />
                        </div>
                        <div className='collection-item row no-margin valign-wrapper padding-top-1'>
                            <SimpleDatePicker col={ 12 } label={ i18n.endDate } value={ otherMaxDate } onChange={ setOtherMaxDate } />
                        </div>
                        <div className='row no-margin padding-top-1 padding-left-1 padding-right-1'>
                            <Button col={ 12 } onClick={ onSubmitOtherDates } title={ i18n.toLoad }/>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

ChartTabsSuiviGlobal.propTypes = {
    onChangeDate: PropTypes.func,
    // withPluvioCote: PropTypes.bool,
    // changeParent: PropTypes.func,
    default: PropTypes.object,
}

export default ChartTabsSuiviGlobal