import { groupBy, max, orderBy, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import CmsAction from '../../../../../../events/actions/CmsAction'
import DtoDocumentStatistic from '../../../../../../events/dto/DtoDocumentStatistic'
import { getFullDateMini } from '../../../../../../utils/DateUtil'
import { nbPerPageLabelMedium } from '../../../../../../referencial/constants/ReferencialConstants'
import { CardTable } from '../../../../../../components/datatable/NewTable'
import ExportAction from '../../../../../../export/actions/ExportAction'
import { formatData } from '../../../../../../utils/ExportDataUtil'
import { greyBlue } from '../../../../../../utils/constants/ColorTheme'

const headers = ['document', 'nbAccess', 'lastAccess']

class DocumentsStatisticsPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stats: [],
        }
    }

    componentDidMount() {
        const { module, login } = this.props
        if (module) {
            this.props.fetchModuleDocumentsStatistics(module).then(() => {
                const { moduleDocumentsStatistics } = this.props
                this.setStatsData(moduleDocumentsStatistics)
            })
        } else if (login) {
            this.props.fetchUserDocumentsStatistics(login).then(() => {
                const { userDocumentsStatistics } = this.props
                this.setStatsData(userDocumentsStatistics)
            })
        } else {
            this.props.fetchDocumentsStatistics().then(() => {
                const { documentsStatistics } = this.props
                this.setStatsData(documentsStatistics)
            })
        }
    }

    setStatsData = (stats) => {
        this.setState({ stats: this.getDatas(stats) })
    }

    getDatas = (stats) => {
        const groupedValues = groupBy(stats, 'identifiant')
        return orderBy(Object.keys(groupedValues).map((key) => {
            const statisticObject = [ ...groupedValues[key] ]
            const lastVisit = max(statisticObject.map((s) => s.statisticDate))
            return {
                document: key && key.length > 1 ? key.charAt(0).toUpperCase() + key.slice(1) : key || '',
                lastAccess: getFullDateMini(lastVisit),
                nbAccess: statisticObject.length,
                headers,
            }
        }), 'nbAccess', 'desc')
    }

    render() {
        const { stats } = this.state
        const data = take(stats, 10)
        return !!stats.length && (
            <CardTable
                actions={[{
                    icon: 'download',
                    onClick: () => this.props.export(formatData(data), 'xlsx', i18n.userDocumentsStatistics),
                    color: 'white',
                }]}
                title={`10 ${i18n.userDocumentsStatistics}`}
                headers={headers}
                rowsPerPageOptions={nbPerPageLabelMedium}
                rows={ data }
                data-cy={'stats_docs_table'}
                color={greyBlue}
                hideNbElements
            />
        )
    }
}

DocumentsStatisticsPanel.propTypes = {
    fetchDocumentsStatistics: PropTypes.func,
    fetchModuleDocumentsStatistics: PropTypes.func,
    fetchUserDocumentsStatistics: PropTypes.func,
    export: PropTypes.func,
    documentsStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoDocumentStatistic)),
    moduleDocumentsStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoDocumentStatistic)),
    userDocumentsStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoDocumentStatistic)),
    module: PropTypes.string,
    login: PropTypes.string,
}

const mapStateToProps = (store) => {
    return {
        documentsStatistics: store.EventsReducer.documentsStatistics,
        moduleDocumentsStatistics: store.EventsReducer.moduleDocumentsStatistics,
        userDocumentsStatistics: store.EventsReducer.userDocumentsStatistics,
    }
}

const mapDispatchToProps = {
    fetchDocumentsStatistics: CmsAction.fetchDocumentsStatistics,
    fetchModuleDocumentsStatistics: CmsAction.fetchModuleDocumentsStatistics,
    fetchUserDocumentsStatistics: CmsAction.fetchUserDocumentsStatistics,
    export: ExportAction.export,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsStatisticsPanel)
