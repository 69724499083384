import { camelCase, isArray, isNil, isPlainObject, keys, omitBy, toLower, words } from 'lodash'
import React from 'react'
import i18n from 'simple-react-i18n'
import { HYDRO, PIEZO, PLUVIO } from '../alerting/constants/MeasureTypeContants'
import { hasValue } from './NumberUtil'

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const chunkLineWithWords = (line, nbCharacters) => {
    const [processed, currentLine] = line.split(' ').reduce(([proc, cur], word) => {
        if (cur.length + word.length + 1 > nbCharacters) {
            return [proc + (proc.length ? '\n' : '') + cur, word]
        }
        return [proc, `${cur} ${word}`]
    }, ['', ''])
    return currentLine.length && processed.length ? `${processed}\n${currentLine}` : processed + currentLine
}

const chunkWithWords = (string, nbCharacters) => words(string, /[^\n\r]+/g).map(line => chunkLineWithWords(line, nbCharacters)).join('\n')

const getStringWithoutSpace = string => string.replace(/\s/g, '')

const substringText = (text, shortNumber = 50) => {
    if (text) {
        if (text.length > shortNumber) {
            return `${text.substring(text, shortNumber)}...`
        }
        return text
    }
    return ''
}

const i18nize = labelObjects => labelObjects.map(obj => ({ ...obj, label: i18n[obj.label] }))

const geti18n = i18nString => i18nString ? i18n[i18nString] : ''

const i18nExist = (i18nValue) => {
    try {
        return i18n[i18nValue]
    } catch (e) {
        return false
    }
}

const getI18nOrLabel = i18nValue => {
    try {
        return i18n[i18nValue]
    } catch (e) {
        return i18nValue
    }
}

const getI18nTitleDataLength = (i18nSingular, i18nPlurial = '', datasLength = 0) => {
    if (i18nPlurial) {
        if (datasLength > 1) {
            return i18nPlurial
        }
        return i18nSingular
    }
    return i18nPlurial
}

const getI18nTitleData = (i18nSingular, i18nPlurial = '', datas = []) => {
    if (i18nPlurial) {
        if (datas) {
            return getI18nTitleDataLength(i18nSingular, i18nPlurial, datas.length)
        }
        return getI18nTitleDataLength(i18nSingular, i18nPlurial)
    }
    return i18nSingular
}

const searchAllCharacters = (str = '')=> toLower(camelCase(str))

const filterAllCharacters = (data, headers, searchValue) => {
    const formatedSearchValue = searchAllCharacters(searchValue)
    return data.filter(obj => headers.some(key => {
        const value = (obj[key]?.sortValue ?? obj[key]?.value) || obj[key]
        return searchAllCharacters(value)?.includes(formatedSearchValue)
    }))
}

const getTypeNumberi18n = (typeNumber) => {
    switch (typeNumber) {
        case HYDRO:
            return i18n.hydrometry
        case PLUVIO:
            return i18n.pluviometry
        case PIEZO:
            return i18n.piezometry
        default:
            return ''
    }
}

const getSandreLabel = (sandreCodes, field, code, noCheckRef = false) => {
    const found = noCheckRef
        ? sandreCodes.find(c => c.field == field && (c.code == code))
        : sandreCodes.find(c => c.field == field && (c.code == code || (c.reference && c.reference == code)))
    return found ? found.name || `[${found.code}]` : null
}

const getSandreIndexLabel = (sandreIndex, field, code, noCheckRef = false) => {
    const foundField = sandreIndex[field] || {}
    const found = noCheckRef
        ? foundField[code]
        : (foundField[code] ? foundField[code] : Object.keys(foundField).map(key => foundField[key]).find(c => c.reference == code))
    return found ? found.name || `[${found.code}]` : null
}

const getSandreComment = (sandreCodes, field, code, noCheckRef = false) => {
    const found = noCheckRef
        ? sandreCodes.find(c => c.field == field && (c.code == code))
        : sandreCodes.find(c => c.field == field && (c.code == code || (c.reference && c.reference == code)))
    return found ? found.comment || `[${found.code}]` : null
}

const getSandreMnemonique = (sandreCodes, field, code, noCheckRef = false) => {
    const found = noCheckRef
        ? sandreCodes.find(c => c.field == field && (c.code == code))
        : sandreCodes.find(c => c.field == field && (c.code == code || (c.reference && c.reference == code)))
    return found ? found.mnemonique || `[${found.code}]` : null
}

const formatSiret = (siret) => {
    if (siret && siret.length === 14) {
        return `${siret.slice(0, 3)} ${siret.slice(3, 6)} ${siret.slice(6, 9)} ${siret.slice(9, siret.length)}`
    }
    return siret
}

const formatPhone = (phone) => {
    if (phone && phone.length === 10) {
        return `${phone.slice(0, 2)} ${phone.slice(2, 4)} ${phone.slice(4, 6)} ${phone.slice(6, 8)} ${phone.slice(8, phone.length)}`
    }
    return phone
}

const formatMilliers = (nb) => {
    const string = `${hasValue(nb) ? nb : ''}`
    const x = string.split('.')
    let x1 = x[0]
    const x2 = x.length > 1 ? `.${x[1]}` : ''
    const rgx = /(\d+)(\d{3})/
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2')
    }
    return x1 + x2
}

const multiLinesi18n = (text) => {
    return (<span style={{ whiteSpace: 'pre' }}>{ text }</span>)
}

const getValidCodeInput = (value) => {
    return value.replaceAll(' ', '').replace(/[^0-9a-zA-Z]/g, '')
}

const getValidNameInsee = (name) => name?.toLowerCase().replace(' ', '-').replace('\'', '-')

const toQueryParam = obj => {
    const listQuery = keys(omitBy(obj, p => isNil(p) || (isArray(p) && p.length === 0) || isPlainObject(p))).map(key => {
        if (isArray(obj[key])) {
            return `${key}=${obj[key].join(',')}`
        }
        return `${key}=${obj[key]}`
    })
    return listQuery.join('&')
}

const getExportFormat = (roundValue = 3) => {
    return roundValue <= 0 ? '0.0' : `0.${'0'.repeat(roundValue)}`
}

export {
    getStringWithoutSpace,
    substringText,
    i18nize,
    geti18n,
    i18nExist,
    searchAllCharacters,
    filterAllCharacters,
    getSandreLabel,
    getSandreIndexLabel,
    getI18nTitleData,
    getI18nTitleDataLength,
    capitalizeFirstLetter,
    getTypeNumberi18n,
    getSandreComment,
    formatPhone,
    formatMilliers,
    formatSiret,
    multiLinesi18n,
    chunkWithWords,
    getI18nOrLabel,
    getValidCodeInput,
    getValidNameInsee,
    getSandreMnemonique,
    toQueryParam,
    getExportFormat,
}
