/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import Checkbox from 'components/forms/Checkbox'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import Input from 'components/forms/Input'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import NumberField from 'components/forms/NumberField'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { getSandreLabel, getSandreMnemonique } from 'utils/StringUtil'
import { shallowEqual, useSelector } from 'react-redux'

const headers = ['sandreCode', 'internalCodeSing', 'name', 'envType', 'sensitiveAreaName', 'nitrogenSensitivity', 'rejectOver1500m', 'comment']

const RejectPointDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const projections = useSandreList(SANDRE.PROJECTION)
    const referencials = useSandreList(SANDRE.STEP_POINTS_REFERENTIELS)
    const recEnv = useSandreList(SANDRE.MILIEU_RECEPTEUR)
    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1} alignItems='center'>
                    <Grid2 size={6}>
                        <Select
                            value={stateElement.idPoint}
                            label={i18n.referentielsPoint}
                            options={referencials}
                            onChange={(v) => onChangeElement({ idPoint: v })}
                            displayWithCode
                            keyLabel='mnemonique'
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            value={stateElement.pointCode}
                            title={i18n.internalCodeSing}
                            onChange={v => onChangeElement({ pointCode: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            value={stateElement.envName}
                            title={i18n.name}
                            onChange={v => onChangeElement({ envName: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={stateElement.envType}
                            label={i18n.envType}
                            options={recEnv}
                            displayWithCode
                            onChange={(v) => onChangeElement({ envType: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            value={stateElement.comment}
                            title={i18n.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.x}
                            title={i18n.x}
                            onChange={v => onChangeElement({ x: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.y}
                            title={i18n.y}
                            onChange={v => onChangeElement({ y: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={stateElement.projection}
                            label={i18n.projection}
                            options={projections}
                            displayWithCode
                            onChange={(v) => onChangeElement({ projection: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            label={i18n.rejectOver1500m}
                            checked={stateElement.rejectOver1500m}
                            onChange={v => onChangeElement({ rejectOver1500m: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            value={stateElement.sensitiveAreaName}
                            title={i18n.sensitiveAreaName}
                            onChange={v => onChangeElement({ sensitiveAreaName: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            label={i18n.nitrogenSensitivity}
                            checked={stateElement.nitrogenSensitivity}
                            onChange={v => onChangeElement({ nitrogenSensitivity: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            style={{ padding: 0 }}
                            label={i18n.nitrogenSensitivityOrderDate}
                            value={stateElement.nitrogenSensitivityOrderDate}
                            onChange={v => onChangeElement({ nitrogenSensitivityOrderDate: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            label={i18n.sensitivityPhosphorus}
                            checked={stateElement.sensitivityPhosphorus}
                            onChange={v => onChangeElement({ sensitivityPhosphorus: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            label={i18n.sensitivityPhosphorusOrderDate}
                            value={stateElement.sensitivityPhosphorusOrderDate}
                            onChange={v => onChangeElement({ sensitivityPhosphorusOrderDate: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

RejectPointDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const STEPRejectPointsPanel = ({
    STEP = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={STEP}
            readMode={readMode}
            onChange={onChange}
            keyList='link_rejectPoints'
            formatFunction={srp => ({
                ...srp,
                sandreCode: getSandreMnemonique(sandreCodes, SANDRE.STEP_POINTS_REFERENTIELS, srp.idPoint),
                internalCodeSing: srp.pointCode,
                name: srp.envName,
                projection: getSandreLabel(sandreCodes, SANDRE.PROJECTION, srp.projection),
                envType: getSandreLabel(sandreCodes, SANDRE.MILIEU_RECEPTEUR, srp.envType),
                rejectOver1500m: srp.rejectOver1500m ? (<Checkbox checked disabled />) : false,
                nitrogenSensitivity: srp.nitrogenSensitivity ? (<Checkbox checked disabled />) : false,
            })}
            titles={{
                title: i18n.rejectPoints,
                edit: i18n.editRejectPoint,
                new: i18n.newRejectPoint,
            }}
            headers={headers}
            dialogContent={RejectPointDialogContent}
        />
    )
}

STEPRejectPointsPanel.propTypes = {
    STEP: PropTypes.object, // DtoStep
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default STEPRejectPointsPanel
