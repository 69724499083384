import PicturesApp from 'station/components/picture/PicturesApp'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import useTitle from 'utils/customHook/useTitle'
import i18n from 'simple-react-i18n'
import { getStationTitle } from 'utils/StationUtils'

const QualityPicturesApp = ({

}) => {
    const {
        qualitometer,
        qualitometers,
    } = useSelector(store => ({
        qualitometer: store.QualityReducer.qualitometer,
        qualitometers: store.QualityReducer.qualitometersLight,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.quality,
        href: 'quality',
    }, {
        title: getStationTitle(qualitometer),
        href: `station/quality/${qualitometer?.id}`,
    }, {
        title: i18n.picturesAndDocuments,
        href: `station/quality/${qualitometer?.id}/picture`,
    }], [qualitometer])

    return (
        <PicturesApp
            stations={qualitometers}
            station={qualitometer}
            stationType='quality'
        />
    )
}

export default QualityPicturesApp