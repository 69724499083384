import MessageCard from 'components/card/MessageCard'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { isNil, orderBy, uniqBy } from 'lodash'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import HydroChart from 'catchment/components/catchment/dashboard/hydrometry/HydroChart'
import { HydroCumulOptionIcon } from 'catchment/components/catchment/dashboard/hydrometry/HydroOption'
import { AUTO } from 'hydrometry/constants/HydroOptionConstant'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, Grid2 } from '@mui/material'
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'
import { promiseAllProgress } from 'utils/ActionUtils'
import useBoolean from 'utils/customHook/useBoolean'
import { AccordionTitle } from 'components/styled/Accordions'
import { ControlChartTabs, J90, useSimpleChartTabs } from 'components/echart/SimpleChartTabs'
import moment from 'moment'
import { exportFile } from 'utils/ExportDataUtil'
import { getDate } from 'date-fns'

const FollowUpHydro = ({
    hydrometricStationsLinked,
    stationConstant = 'DEFAULT',
    expanded = false,
    setExpanded = () => {},
}) => {
    const dispatch = useDispatch()

    const {
        hydrometricStations,
        hydrometryDataTypes,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    const {
        time,
        setTime,
        startDate,
        endDate,
    } = useSimpleChartTabs(J90, 'HYDRO')

    const [dataTypes, setDataTypes] = useLocalStorage(`${stationConstant}_HYDRO_DATATYPES`, [4, 5]) // hauteur, débit
    const [dataMeasures, setDataMeasures] = useState([])
    const [group, setGroup] = useLocalStorage(`${stationConstant}_HYDRO_GROUP`, AUTO)

    const filteredDataTypes = useMemo(() => hydrometryDataTypes.filter(dt => dt.showData || dataTypes.includes(dt.id)), [dataTypes, hydrometryDataTypes])

    const [stations, setStations] = useState(isNil(hydrometricStationsLinked[0]?.id) ? [] : [hydrometricStationsLinked[0]?.id])

    useEffect(() => {
        dispatch(HydrometryAction.fetchHydrometricThresholds())
        dispatch(HydrometryAction.fetchHydrometryDataTypes())
    }, [])

    const { value: isLoaded, setFalse: onLoad, setTrue: onLoaded } = useBoolean(false)
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        onLoad()
        setProgress(0)
        setDataMeasures(prev => prev.filter(dm => dataTypes.includes(dm.dataType)))
        const defaultGroup = moment.duration(moment(endDate).diff(moment(startDate))).days() < 30 ? 'all' : 'MAX'
        const listInputs = hydrometricStationsLinked.flatMap(h => {
            return dataTypes
                .map(dataType => ({
                    groupFunc: group === 'AUTO' ? defaultGroup : group,
                    chartMode: true,
                    startDate,
                    endDate,
                    dataType,
                    stationId: h.id,
                }))
        })

        const promises = listInputs.map(input => dispatch(HydrometryAction.getHydroChronicMeasures(input))
            .then(data => setDataMeasures(prev => uniqBy([data, ...prev], d => `${d.stationId}#${d.dataType}`))))

        promiseAllProgress(promises, setProgress)
            .finally(onLoaded)
    }, [hydrometricStationsLinked, group, dataTypes, startDate, endDate])

    const dataMeasuresFiltered = useMemo(() => {
        const orderedDataMeasures = orderBy(dataMeasures, 'dataType')
        if (stations.length === 0) return orderedDataMeasures
        return orderedDataMeasures.filter(({ stationId }) => stations.includes(stationId))
    }, [dataMeasures, stations])

    const actions = [
        <HydroCumulOptionIcon
            key={0}
            defaultGroupType={group}
            onChangeGroupType={setGroup}
            disabled={!isLoaded}
        />,
        {
            icon: 'file_download',
            tooltip: i18n.export,
            onClick: () => {
                const exportData = dataMeasures.flatMap(dm => {
                    const hydro = hydrometricStations.find(({ id }) => id === dm.stationId)
                    const dt = hydrometryDataTypes.find(h => h.id === dm.dataType)
                    return dm.measures.map(m => ({
                        code: hydro?.code,
                        date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                        value: { value: m.value, format: '0.00000', cellType: 'number' },
                        name: hydro?.name || dm.stationId,
                        dataType: dt?.name ?? i18n.unknown,
                    }))
                })
                exportFile({
                    data: exportData.length ? [
                        {
                            ...exportData[0],
                            headers: ['code', 'name', 'dataType', 'date', 'value'],
                        },
                        ...exportData.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.dataHydro,
                }, true)
            },
        },
    ]

    return (
        <Accordion expanded={expanded} onChange={setExpanded} data-cy='follow_up_hydro'>
            <AccordionTitle
                title={i18n.hydrometry}
                actions={actions}
                color='white'
                fontColor='black'
            />
            <AccordionDetails>
                <Grid2 container columnSpacing={2} alignItems='center'>
                    <Grid2 size={6}>
                        <SuperMultiAutocomplete
                            label={i18n.hydrometricStation}
                            options={hydrometricStationsLinked}
                            values={stations}
                            onChange={setStations}
                            keyValue={'id'}
                            multiple
                            limit={2}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <SuperMultiAutocomplete
                            label={i18n.dataTypes}
                            options={filteredDataTypes}
                            values={dataTypes}
                            onChange={setDataTypes}
                            keyValue='id'
                            multiple
                            limit={3}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <ControlChartTabs
                            time={time}
                            setTime={setTime}
                            disabled={!isLoaded}
                        />
                    </Grid2>
                </Grid2>
                <div style={{ position: 'relative' }}>
                    {!isLoaded && (<CircularProgressWithLabel value={progress} />) }
                    {dataMeasuresFiltered.length > 0 && (
                        <HydroChart
                            dataMeasures={dataMeasuresFiltered}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    )}
                    {dataMeasuresFiltered.length === 0 && (<MessageCard>{i18n.noDataToDisplay}</MessageCard>)}
                </div>
            </AccordionDetails>
        </Accordion>
    )
}

FollowUpHydro.propTypes = {
    hydrometricStationsLinked: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)).isRequired,
    stationConstant: PropTypes.string,
    expanded: PropTypes.bool,
    setExpanded: PropTypes.func,
}

export default FollowUpHydro