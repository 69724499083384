import { ButtonGroup, Popover } from '@mui/material'
import Input from 'components/forms/Input'
import { ButtonMUI } from 'components/styled/Buttons'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { onChangeDate } from 'utils/FormUtils'
import useBoolean from 'utils/customHook/useBoolean'
import PropTypes from 'prop-types'
import useLocalStorage from 'utils/customHook/useLocalStorage'

const CHART_TABS = 'CHART_TABS'
const H_24 = 'H_24'
const H_48 = 'H_48'
const J7 = 'J7'
const J30 = 'J30'
const J90 = 'J90'
const J365 = 'J365'
const HISTO = 'HISTO'
const OTHER = 'OTHER'

const overrideStoredValue = (value) => value.active === HISTO ? { active: J365 } : value

const getSelectedDate = ({ active, maxDate, minDate }) => {
    switch (active) {
        case H_24: return { startDate: moment().subtract(1, 'days').valueOf(), endDate: moment().valueOf() }
        case H_48: return { startDate: moment().subtract(2, 'days').valueOf(), endDate: moment().valueOf() }
        case J7: return { startDate: moment().subtract(7, 'days').valueOf(), endDate: moment().valueOf() }
        case J30: return { startDate: moment().subtract(30, 'days').valueOf(), endDate: moment().valueOf() }
        case J90: return { startDate: moment().subtract(90, 'days').valueOf(), endDate: moment().valueOf() }
        case J365: return { startDate: moment().subtract(365, 'days').valueOf(), endDate: moment().valueOf() }
        case OTHER: return { startDate: minDate, endDate: maxDate }
        case HISTO:
        default: return {}
    }
}

const useSimpleChartTabs = (defaultTime, constant ='') => {
    const [time, setTime] = useLocalStorage(`${constant && `${constant}_`}${CHART_TABS}`, { active: defaultTime }, overrideStoredValue)
    const {
        startDate,
        endDate,
    } = useMemo(() => getSelectedDate(time), [time])

    return {
        time,
        setTime,
        startDate,
        endDate,
    }
}

const ControlChartTabs = ({
    time,
    setTime,
    disabled,
}) => {
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState(moment().valueOf())

    const {
        value: isOpen,
        toggle: toggleOpen,
    } = useBoolean(false)
    const anchorEl = useRef()

    return (
        <>
            <ButtonGroup>
                <ButtonMUI
                    variant={time.active === H_24 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setTime({ active: H_24 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px 0px 2px 2px', fontWeight: 600 }}
                >
                    {i18n.h24}
                </ButtonMUI>
                <ButtonMUI
                    variant={time.active === H_48 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setTime({ active: H_48 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px', fontWeight: 600 }}
                >
                    {i18n.h48}
                </ButtonMUI>
                <ButtonMUI
                    variant={time.active === J7 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setTime({ active: J7 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px 0px', fontWeight: 600 }}
                >
                    {i18n.j7}
                </ButtonMUI>
                <ButtonMUI
                    variant={time.active === J30 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setTime({ active: J30 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px', fontWeight: 600 }}
                >
                    {i18n.j30}
                </ButtonMUI>
                <ButtonMUI
                    variant={time.active === J90 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setTime({ active: J90 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px 0px', fontWeight: 600 }}
                >
                    {i18n.j90}
                </ButtonMUI>
                <ButtonMUI
                    variant={time.active === J365 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setTime({ active: J365 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px', fontWeight: 600 }}
                >
                    {i18n.j365}
                </ButtonMUI>
                <ButtonMUI
                    variant={time.active === HISTO ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setTime({ active: HISTO })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px 2px 2px 0px', fontWeight: 600 }}
                >
                    {i18n.histo}
                </ButtonMUI>
                <ButtonMUI
                    variant={time.active === OTHER ? 'contained' : 'outlined'}
                    onClick={() => {
                        if (disabled) return
                        toggleOpen()
                    }}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px', marginLeft: '10px', fontWeight: 600 }}
                    ref={anchorEl}
                >
                    {i18n.other}
                </ButtonMUI>
            </ButtonGroup>


            <Popover
                open={isOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={toggleOpen}
                slotProps={{
                    paper: {
                        sx: {
                            padding: '10px',
                        },
                    },
                }}
            >
                <Input
                    title={i18n.startDate}
                    value={getDate(startDate)}
                    onChange={v => onChangeDate(v, setStartDate)}
                />
                <Input
                    title={i18n.endDate}
                    value={getDate(endDate)}
                    onChange={v => onChangeDate(v, setEndDate)}
                />
                <ButtonMUI
                    onClick={() => {
                        setTime({ active: OTHER, maxDate: endDate, minDate: startDate })
                        toggleOpen()
                    }}
                    variant='contained'
                    fullWidth
                >
                    {i18n.toLoad}
                </ButtonMUI>
            </Popover>
        </>
    )
}

ControlChartTabs.propTypes = {
    time: PropTypes.shape({
        active: PropTypes.oneOf([H_24, H_48, J7, J30, J90, J365, HISTO, OTHER]).isRequired,
        maxDate: PropTypes.number,
        minDate: PropTypes.number,
    }).isRequired,
    setTime: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}

const SimpleChartTabs = ({
    onChange = () => {},
    defaultSelected = J90,
    disabled = false,
}) => {
    const [selected, setSelected] = useLocalStorage(CHART_TABS, { active: defaultSelected }, overrideStoredValue)
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState(moment().valueOf())

    const {
        value: isOpen,
        toggle: toggleOpen,
    } = useBoolean(false)
    const anchorEl = useRef()

    useEffect(() => {
        switch (selected.active) {
            case H_24: onChange(moment().subtract(1, 'days').valueOf(), moment().valueOf()); break
            case H_48: onChange(moment().subtract(2, 'days').valueOf(), moment().valueOf()); break
            case J7: onChange(moment().subtract(7, 'days').valueOf(), moment().valueOf()); break
            case J30: onChange(moment().subtract(30, 'days').valueOf(), moment().valueOf()); break
            case J90: onChange(moment().subtract(90, 'days').valueOf(), moment().valueOf()); break
            case J365: onChange(moment().subtract(365, 'days').valueOf(), moment().valueOf()); break
            case HISTO: onChange(); break
            case OTHER:
            default:
        }
    }, [selected.active])

    return (
        <>
            <ButtonGroup>
                <ButtonMUI
                    variant={selected.active === H_24 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setSelected({ active: H_24 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px 0px 2px 2px', fontWeight: 600 }}
                >
                    {i18n.h24}
                </ButtonMUI>
                <ButtonMUI
                    variant={selected.active === H_48 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setSelected({ active: H_48 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px', fontWeight: 600 }}
                >
                    {i18n.h48}
                </ButtonMUI>
                <ButtonMUI
                    variant={selected.active === J7 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setSelected({ active: J7 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px 0px', fontWeight: 600 }}
                >
                    {i18n.j7}
                </ButtonMUI>
                <ButtonMUI
                    variant={selected.active === J30 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setSelected({ active: J30 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px', fontWeight: 600 }}
                >
                    {i18n.j30}
                </ButtonMUI>
                <ButtonMUI
                    variant={selected.active === J90 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setSelected({ active: J90 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px 0px', fontWeight: 600 }}
                >
                    {i18n.j90}
                </ButtonMUI>
                <ButtonMUI
                    variant={selected.active === J365 ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setSelected({ active: J365 })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px', fontWeight: 600 }}
                >
                    {i18n.j365}
                </ButtonMUI>
                <ButtonMUI
                    variant={selected.active === HISTO ? 'contained' : 'outlined'}
                    onClick={() => !disabled && setSelected({ active: HISTO })}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px 2px 2px 0px', fontWeight: 600 }}
                >
                    {i18n.histo}
                </ButtonMUI>
                <ButtonMUI
                    variant={selected.active === OTHER ? 'contained' : 'outlined'}
                    onClick={() => {
                        if (disabled) return
                        setSelected({ active: OTHER })
                        toggleOpen()
                    }}
                    style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: '2px', marginLeft: '10px', fontWeight: 600 }}
                    ref={anchorEl}
                >
                    {i18n.other}
                </ButtonMUI>
            </ButtonGroup>


            <Popover
                open={isOpen}
                anchorEl={anchorEl.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={toggleOpen}
                slotProps={{
                    paper: {
                        sx: {
                            padding: '10px',
                        },
                    },
                }}
            >
                <Input
                    title={i18n.startDate}
                    value={getDate(startDate)}
                    onChange={v => onChangeDate(v, setStartDate)}
                />
                <Input
                    title={i18n.endDate}
                    value={getDate(endDate)}
                    onChange={v => onChangeDate(v, setEndDate)}
                />
                <ButtonMUI
                    onClick={() => {
                        onChange(startDate, endDate)
                        setSelected(p => ({ ...p, maxDate: endDate, minDate: startDate }))
                        toggleOpen()
                    }}
                    variant='contained'
                    fullWidth
                >
                    {i18n.toLoad}
                </ButtonMUI>
            </Popover>
        </>
    )
}

SimpleChartTabs.propTypes = {
    onChange: PropTypes.func,
    defaultSelected: PropTypes.oneOf([H_24, H_48, J7, J30, J90, J365, HISTO]),
    disabled: PropTypes.bool,
}

export {
    useSimpleChartTabs,
    ControlChartTabs,
    SimpleChartTabs,
    H_24, H_48, J7, J30, J90, J365, HISTO, OTHER,
}