import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import i18n from 'simple-react-i18n'
import ActionComponent from '../../../components/ActionComponent'
import SieauAction from '../../../components/sieau/SieauAction'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import { getUser } from '../../../utils/SettingUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import LiftingCivilEngPanel from './components/LiftingCivilEngPanel'
import LiftingEquipmentsPanel from './components/LiftingEquipmentsPanel'
import DtoLiftingStation from './dto/DtoLiftingStation'
import QualityAction from '../../../quality/actions/QualityAction'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'
import InstallationEquipmentsPanel from '../installations/components/InstallationEquipmentsPanel'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { downloadURI } from '../../../utils/ExportDataUtil'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import { arrayOf } from 'utils/StoreUtils'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import { greyBlue } from 'utils/constants/ColorTheme'
import GenericInstallationDescription from '../installationDefault/GenericInstallationDescription'
import { isDisplayed } from 'utils/InstallationUtils'
import { INSTALLATION_TYPE } from 'installation/constants/InstallationConstants'
import { CardTitle } from 'components/card/NewCard'
import { CardContent, Grid2, Card } from '@mui/material'

class LiftingStationDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: new DtoInstallation({}),
            usedWater: {},
            lifting: new DtoLiftingStation({}),
            readMode: true,
        }
    }

    componentDidMount = () => {
        if (this.props.installation.id) {
            this.setState({ installation: this.props.installation })
            this.props.fetchInstallationLifting(this.props.installation.id).then(() => {
                this.setState({ lifting: this.props.installationLifting })
            })
        }

        if (!this.props.status.length) {
            this.props.fetchStatus()
        }

        if (!this.props.installationEvents.length) {
            this.props.fetchInstallationEvents(this.props.installation.id)
        }

        if (!this.props.contacts.length) {
            this.props.fetchContacts()
        }

        this.props.fetchInstallationVisits(this.props.installation.id).then(() => {
            if (this.props.onChangeVisit) {
                this.setState({ readMode: false })
            } else if (this.state.readMode) {
                this.setReadOnlyMode()
            }
        })

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => {
        this.props.deleteInstallation(this.props.installation.id, () => this.props.push('/installation'))
    }

    onSave = (visit) => {
        const { installation, lifting } = this.state
        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                    lifting,
                },
            ).then(() => this.setReadOnlyMode())
        }
        return this.props.updateInstallationLifting(
            installation,
            lifting,
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation, lifting: this.props.installationLifting })
                this.setReadOnlyMode()
            },
            delete: () => this.onDelete(),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        this.setState({ readMode: true })
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.onDelete(),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }

    onChangeInstallation = (newObject) => this.setState({ installation: { ...this.state.installation, ...newObject } })

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    onChangeLifting = (newObject) => this.setState({ lifting: { ...this.state.lifting, ...newObject } })

    render() {
        const { installation, readMode, lifting } = this.state
        const { applicationSettings } = this.props

        const params = {
            station: installation,
            onChange: this.onChangeInstallation,
            onChangeVisit: this.onChangeVisit,
            readMode,
            sandreCode: SANDRE.EQUIPEMENTS_POSTES_RELEVAGES,
        }

        const paramsLifting = {
            onChange: this.onChangeLifting,
            onChangeVisit: this.onChangeVisit,
            lifting,
            readMode,
        }

        return (
            <GenericInstallationDescription
                readMode={readMode}
                installation={installation}
                onChangeInstallation={this.onChangeInstallation}
                onChangeVisit={this.onChangeVisit}
                descElements={
                    <>
                        {isDisplayed(applicationSettings, 'LIFTING_CIVIL_ENG', INSTALLATION_TYPE.LIFTING_STATION) && (
                            <Grid2 size={12} sx={{ paddingBottom: '5px' }}>
                                <Card>
                                    <CardTitle title={i18n.civilEngineering} color={greyBlue} />
                                    <CardContent>
                                        <Grid2 container columnSpacing={1}>
                                            <LiftingCivilEngPanel {...paramsLifting} />
                                            <Grid2 size={12}>
                                                <BuildingsPanel {...params} />
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        )}
                        {isDisplayed(applicationSettings, 'LIFTING_EQUIPMENTS', INSTALLATION_TYPE.LIFTING_STATION) && (
                            <Grid2 size={12}>
                                <Card>
                                    <CardTitle title={i18n.equipments} color={greyBlue} />
                                    <CardContent>
                                        <Grid2 container columnSpacing={1}>
                                            <LiftingEquipmentsPanel {...paramsLifting} />
                                            <Grid2 size={12}>
                                                <InstallationEquipmentsPanel {...params} />
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>
                                </Card>
                            </Grid2>
                        )}
                    </>
                }
            />
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

LiftingStationDescriptionPanel.propTypes = {
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
}

const mapStateToProps = (store) => ({
    applicationSettings: store.AdministrationReducer.applicationSettings,
    installation: store.InstallationReducer.installation,
    contacts: store.ContactReducer.contacts,
    installationEvents: store.InstallationReducer.installationEvents,
    users: store.UserReducer.users,
    status: store.QualityReducer.status,
    installationLifting: store.InstallationReducer.installationLifting,
    installationVisits: store.InstallationReducer.installationVisits,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    push,
    setPopup: SieauAction.setPopup,
    changeBssCode: InstallationAction.changeBssCode,
    fetchStatus: QualityAction.fetchStatus,
    fetchInstallationEvents: InstallationAction.fetchInstallationEvents,
    fetchContacts: ContactAction.fetchContacts,
    deleteInstallation: InstallationAction.deleteInstallation,
    updateInstallationLifting: InstallationAction.updateInstallationLifting,
    fetchInstallationLifting: InstallationAction.fetchInstallationLifting,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
}
export default connect(mapStateToProps, mapDispatchToProps)(LiftingStationDescriptionPanel)
