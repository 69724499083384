import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Checkbox from '../../../../components/forms/Checkbox'
import Select from '../../../../components/forms/Select'
import Job from '../../../dto/DtoJob'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import { getIntegrationModes } from '../../../../utils/JobUtils'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { nbPerPageLabelMedium } from '../../../../referencial/constants/ReferencialConstants'
import { CardTable } from '../../../../components/datatable/NewTable'

class SynopPanel extends Component {
    state = {
    }

    onDeleteCode = (s) => {
        if (this.props.isEditMode) {
            const codes = this.props.job.parameters.parameters.filter((code) => code !== s.credentials)
            this.props.addCode(codes)
        }
    }

    getDataTypes = type => {
        const { parameters } = this.props.job
        if (parameters.dataTypes && parameters.dataTypes.length) {
            return parameters.dataTypes.find(d => d.includes(type)) || ''
        }
        return ''
    }

    addSynopCode = () => {
        if (this.props.isEditMode) {
            const code = this.refs.txtAddSynopCode.value
            const exist = this.props.job.parameters.parameters.find(p => p.toLowerCase() == code.toLowerCase())
            if (!code) {
                this.props.toastrError(i18n.youMustEnterAtLeastOneCode)
            } else if (exist) {
                this.props.toastrError(i18n.youHaveAlreadyEnteredThisCode)
            } else {
                this.props.addCode([
                    code,
                    ...this.props.job.parameters.parameters,
                ])
                this.refs.txtAddSynopCode.value = ''
            }
        }
    }

    getStations = () => {
        return this.props.job.parameters.parameters.map((v) => ({ credentials: v }))
    }

    handleChangeDataIntegration = integration => {
        const { parameters } = this.props.job
        const newDataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('DATA'))
            if (integration) {
                return [...data, integration]
            }
            return data
        })().filter(s => !s.includes('data'))
        this.props.onChangeDataTypes(newDataTypes)
    }

    handleChangeStationIntegration = integration => {
        const { parameters } = this.props.job
        const dataTypes = (() => {
            const data = parameters.dataTypes.filter(d => !d.includes('station'))
            if (integration) {
                return [...data, 'stationIntegration']
            }
            return data
        })()
        this.props.onChangeDataTypes(dataTypes)
    }

    render() {
        const disabled = { disabled: !this.props.isEditMode }
        const myData =this.getStations()
        return (
            <div>
                <div className='row no-margin valign-wrapper padding-bottom-2'>
                    <div className='col s3 no-margin'>
                        <Checkbox
                            checked={!!this.getDataTypes('station')}
                            label={i18n.integrateStationInfo}
                            onChange={this.handleChangeStationIntegration}
                            {...disabled}
                        />
                    </div>
                    <div className='col s4 no-margin'>
                        <Select
                            value={this.getDataTypes('DATA')}
                            options={getIntegrationModes()}
                            label={i18n.dataIntegration}
                            labelSpan={integrationModeHelpIcon()}
                            onChange={this.handleChangeDataIntegration}
                            {...disabled}
                        />
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s3'>
                        <label>{i18n.addCredential}</label>
                    </div>
                    <div className='input-field col s9 no-padding'>
                        <div className='col s8'>
                            <input id='txtAddSynopCode' type='text' placeholder={i18n.SYNOPExample} ref='txtAddSynopCode' {...disabled}/>
                            <label className='tinyLabel'>{i18n.stationSYNOPCode}</label>
                        </div>
                        <a className='waves-effect waves-light btn col offset-s1 s3'
                            onClick={this.addSynopCode} {...disabled}
                        >
                            {i18n.add}
                        </a>
                    </div>
                </div>
                <div className='row valign-wrapper'>
                    <div className='col s12'>
                        <CardTable
                            rows={ myData }
                            headers={ ['credentials']}
                            title={ i18n.credentials }
                            lineActions={[{
                                icon: 'delete',
                                onClick: (s) => this.onDeleteCode(s),
                                displayed: this.props.isEditMode,
                            }]}
                            rowsPerPageOptions={ nbPerPageLabelMedium }
                            data-cy='Synop_table_body'
                            actions={[{
                                icon: 'download',
                                onClick: () => exportFile({
                                    data: [{ ...(myData[0]), headers: ['credentials'] }, ...(myData.slice(1, myData.length))],
                                    titleFile: i18n.credentials,
                                }),
                                tooltip: i18n.download,
                            }]}
                            displayHeaders={false}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

SynopPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    addCode: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeDataTypes: PropTypes.func,
    toastrError: PropTypes.func,
}

const mapDispatchToProps = {
    toastrError: ToastrAction.error,
}

export default connect(
    null,
    mapDispatchToProps,
)(SynopPanel)
