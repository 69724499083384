import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { nbPerPageLabelShort, SANDRE } from 'referencial/constants/ReferencialConstants'
import { sortBy, uniq } from 'lodash'
import { getDate } from 'utils/DateUtil'
import { push } from '@lagunovsky/redux-react-router'
import { useDispatch } from 'react-redux'
import { formatData } from 'utils/ExportDataUtil'
import ExportAction from 'export/actions/ExportAction'
import ExportFileModal from 'components/modal/ExportFileModal'
import Select from 'components/forms/Select'
import { Grid2 } from '@mui/material'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import { useParams } from 'react-router-dom'
import useSandreList from 'utils/customHook/useSandreList'
import DtoServiceSituation from 'exploitations/dto/DtoServiceSituation'
import Input from 'components/forms/Input'
import moment from 'moment'
import useApplicationSetting from 'utils/customHook/useApplicationSetting'
import AgriAction from 'agriAdministration/actions/AgriAction'
import { servicesHeaders } from 'exploitations/utils/AgriUtils'
import { CardTable } from 'components/datatable/NewTable'
import { darkBlue } from 'utils/constants/ColorTheme'

const ServicesTable = ({
    data = [],
}) => {
    const dispatch = useDispatch()
    const date = useMemo(() => data[0]?.calculationDate, [])

    const codifOCTAGRI = useApplicationSetting('codificationOCTAGRI', setting => parseInt(setting))

    const [open, setOpen] = useState(false)
    const [quoteDate, setQuoteDate] = useState(moment.now())
    const [codification, setCodification] = useState(codifOCTAGRI)
    const [orderTitle, setOrderTitle] = useState('OUGC XX - Redevance')

    const { id } = useParams()
    const codif = useSandreList(SANDRE.INTERVENANTS_TYPE_CODIFICATION)

    const formattedData = sortBy(data.map((d) => {
        return {
            ...d,
            productCode: `${d.designation} (${d.technicalId} - ${d.productCode})`,
            colorLine: !d.codifOCTAGRI && 'orange',
        }
    }), 'codification')

    const getServiceExportContent = () => {
        return (
            <Grid2 container spacing={1}>
                <Grid2 size={6}>
                    <Input
                        title={i18n.orderTitle}
                        value={orderTitle}
                        onChange={setOrderTitle}
                        obligatory
                    />
                </Grid2>
                <Grid2 size={6}>
                    <SimpleDatePicker
                        col={12}
                        label={i18n.quoteDate}
                        value={quoteDate}
                        onChange={setQuoteDate}
                        obligatory
                    />
                </Grid2>
                <Grid2 size={6}>
                    <Select
                        label={i18n.codification}
                        options={codif}
                        value={codification}
                        onChange={setCodification}
                        obligatory
                    />
                </Grid2>
            </Grid2>
        )
    }

    const getExportContent = () => {
        return (
            <Grid2 container>
                <Grid2 size={6}>
                    <Select
                        label={i18n.codification}
                        options={codif}
                        value={codification}
                        onChange={setCodification}
                        obligatory
                    />
                </Grid2>
            </Grid2>
        )
    }

    const launchExport = (newData, type) => {
        const exportData = [
            { ...newData[0], headers: servicesHeaders },
            ...newData.slice(1, newData.length),
        ]
        dispatch(ExportAction.export(formatData(exportData), type, i18n.calculationDate))
    }

    const onExport = (type) => {
        return codification === codifOCTAGRI ?
            launchExport(data, type) :
            AgriAction.promiseServicesSituations({
                dateCalculus: date,
                codificationOCTAGRI: String(codification) || codifOCTAGRI,
            }).then((json) => launchExport(json, type))
    }

    const exportObj = {
        calculationDate: date,
        quoteDate,
        codification,
        ids: uniq(data.map(d => d.idExploitation)),
        orderTitle,
    }

    const exportsData = [{
        name: i18n.export,
        settingsContent: getExportContent(),
        formats: [{
            type: i18n.exportXLSX,
            callback: () => onExport('xlsx'),
        }, {
            type: i18n.csv,
            callback: () => onExport('csv'),
        }],
    }, {
        name: `${i18n.exportName} ${i18n.services}`,
        settingsContent: getServiceExportContent(),
        formats: [{
            type: i18n.exportXLSX,
            callback: () => {
                dispatch(ExportAction.exportExploitationsServices({ ...exportObj, exportType: 'xlsx' }))
            },
        }, {
            type: i18n.csv,
            callback: () => {
                dispatch(ExportAction.exportExploitationsServices({ ...exportObj, exportType: 'csv' }))
            },
        }],
    }]

    return (
        <Grid2 className='margin-top-1'>
            <CardTable
                title={`${i18n.calculationDate} : ${getDate(date)}`}
                rows={formattedData}
                rowsPerPageOptions={nbPerPageLabelShort}
                headers={servicesHeaders}
                actions={[{
                    icon: 'file_download',
                    color: 'white',
                    tooltip: i18n.export,
                    onClick: () => setOpen(true),
                }]}
                onClickRow={service => id ? () => {} : dispatch(push(`/dossiers/${service.idExploitation}/dashboard`))}
                color={darkBlue}
            />
            <ExportFileModal
                open={open}
                onClose={() => setOpen(false)}
                data={exportsData}
                settingsStyle={{ paddingBottom: 100 }}
            />
        </Grid2>
    )
}

ServicesTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.instanceOf(DtoServiceSituation)),
}

export default ServicesTable
