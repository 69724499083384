import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { searchAllCharacters } from '../../utils/StringUtil'
import { flatten, groupBy, take, orderBy } from 'lodash'
import { hasValue } from '../../utils/NumberUtil'
import { useDispatch } from 'react-redux'

const SelectDropdown = ({}) => {
    const dispatch = useDispatch()
    const [selectState, setSelectState] = useState({})
    const [selectProps, setSelectProps] = useState({})
    const [position, setPosition] = useState({ width: 0, top: 0, left: 0, height: 0 })
    const [onChangeValue, setOnChangeValueFunc] = useState(() => () => {})

    const { group, nullLabel, value, limit, noNullValue, options, displayWithCode, keyValue, keyLabel, withThresholdGroups, noLimit, sortFunc, noSort, dropdownHeight } = selectProps
    const { editing, searchValue, focus } = selectState
    const { width, top, left, height } = position

    const changeSelectDropdown = (newSelectState, newSelectProps, newSelectId, onChangeValueFunc, onBlurFunc) => {
        const select = $(`#input-${newSelectId}`) // Select.js (à côté de ce fichier)
        const newPosition = !!select && !!select[0] ? { ...select.offset(), width: select.width(), height: select.height() } : {}
        setSelectState(newSelectState)
        setSelectProps(newSelectProps)
        setPosition(newPosition)
        setOnChangeValueFunc(() => onChangeValueFunc)
        $('#sieauApp').on('click', onBlurFunc)
        $('#bubbleHelp').on('click', onBlurFunc)
        const popupMui = $('.MuiDialog-root')
        if (popupMui && popupMui.length) {
            popupMui.on('click', onBlurFunc)
        }
    }

    useEffect(() => {
        dispatch({ type: 'SET_SELECT_FUNCTIONS', changeSelectDropdown })
    }, [dispatch])

    const getId = useCallback((element) => {
        if (element) {
            if (hasValue(element[keyValue])) {
                return element[keyValue]
            } else if (hasValue(element.code)) {
                return element.code
            } else if (hasValue(element.id)) {
                return element.id
            } else if (hasValue(element.value)) {
                return element.value
            }
        }
        return null
    }, [keyValue])

    const getName = useCallback((element) => {
        const code = displayWithCode ? getId(element) : null
        const codeShown = hasValue(code) && code !== '' ? ` [${code}]` : ''
        return (element?.[keyLabel] || element?.mnemonique || element?.name || element?.label || nullLabel || '') + codeShown
    }, [displayWithCode, getId, keyLabel, nullLabel])

    const isSearchValueInclude = useCallback((text) => {
        return searchAllCharacters(text).includes(searchAllCharacters(searchValue))
    }, [searchValue])

    const searchElements = useMemo(() => {
        const values = (() => {
            if (options && options.length) {
                if (editing) {
                    return options.filter(element => isSearchValueInclude(getName(element)))
                }
                return options
            }
            return []
        })().filter(e => hasValue(getId(e)))
        return noSort ? values : orderBy(values,
            [
                v => searchAllCharacters(getName(v)).indexOf(searchAllCharacters(searchValue)) === 0,
                sortFunc ? sortFunc : v => (getName(v) || '').trim() === '' ? 'zzzzzz' : searchAllCharacters(getName(v)),
                v => getName(v).length,
            ]
            , ['desc', 'asc', 'desc'])
    }, [sortFunc, options, editing, isSearchValueInclude, getName])

    if (!focus) {
        return null
    }

    const getDropdownElement = (element) => {
        const id = getId(element)
        const icon = element.icon ? <>{element.icon}&nbsp;</> : null
        const withIconStyle = element.icon ? { height: 30, padding: 5 } : {}
        if (group) {
            return (
                <li
                    className={`mAutocomplete__dropdown_element ${id == value ? 'selected_value' : ''}`}
                    style={{ paddingLeft: '30px', ...withIconStyle }}
                    key={id}
                    onClick={() => onChangeValue(element)}
                    data-cy={`select-${getName(element)}`}
                >
                    <div className='row no-margin valign-wrapper'>{icon}{getName(element)}</div>
                </li>
            )
        }
        return (
            <li
                className={`mAutocomplete__dropdown_element ${id == value ? 'selected_value' : ''}`}
                key={id}
                onClick={() => onChangeValue(element)}
                style={{ ...withIconStyle }}
                data-cy={`select-${getName(element)}`}
            >
                <div className='row no-margin valign-wrapper' id={getName(element).toLowerCase().replaceAll(' ', '_')}>
                    {(withThresholdGroups && element.referenceCode ? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : '')}
                    {icon}{getName(element)}
                </div>
            </li>
        )
    }

    const shownElements = noLimit ? searchElements : take(searchElements, limit)
    const dropdownElements = (() => {
        if (group) {
            const searchGroupedElements = groupBy(shownElements, 'type')
            return flatten(Object.keys(searchGroupedElements).map(key => {
                const groupName = <li style={{ fontWeight: 'bolder', fontStyle: 'italic', padding: '5px 10px' }} key={key}>{key !== 'undefined' ? key : <>&nbsp;</>}</li>
                const newOptions = searchGroupedElements[key].map(element => getDropdownElement(element))
                return [groupName, ...newOptions]
            }))
        }
        return shownElements.map(element => getDropdownElement(element))
    })()
    return (
        <div className='mAutocomplete__dropdown_class'>
            <ul id='selectDropdownAquasys' className='mAutocomplete__dropdown' style={ {
                position: 'absolute',
                left,
                top: top + height - 6,
                minWidth: width,
                maxHeight: dropdownHeight,
                width: navigator.userAgent.includes('Mozilla') ? '-moz-fit-content' : 'fit-content',
            } }
            >
                {((!editing && !noNullValue) || (editing && nullLabel && !noNullValue && searchAllCharacters(nullLabel).includes(searchAllCharacters(searchValue)))) &&
                <li
                    id='empty'
                    className={`mAutocomplete__dropdown_element ${value === undefined ? 'selected_value' : ''}`}
                    onClick={() => onChangeValue(undefined)}
                    data-cy='select-blank'
                >
                    { nullLabel || <span>&nbsp;</span> }
                </li>
                }
                {dropdownElements}
                { shownElements.length < searchElements.length && (<li className='mAutocomplete__dropdown_element '>...</li>) }
            </ul>
        </div>
    )
}
export default SelectDropdown