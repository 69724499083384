/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import Select from '../../../../components/forms/Select'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import { CardTitle } from 'components/card/NewCard'
import { Card, CardContent, Grid2 } from '@mui/material'

const InstallationContextPanel = ({
    defaultStation = {},
    onChange = () => { },
    readMode = false,
}) => {
    const defaultStationWithWatermasses = useMemo(() => {
        const defaultLinkWatermasses = defaultStation.watermassCode ? [{ watermassCode: defaultStation.watermassCode }] : []
        return defaultLinkWatermasses.length ? { ...defaultStation, link_watermasses: [{ watermassCode: defaultStation.watermassCode }] } : defaultStation
    }, [defaultStation])

    const [station, setStation] = useState(defaultStationWithWatermasses)

    const {
        watersheds,
        hydrometricStations,
        watermasses,
    } = useSelector(store => ({
        watersheds: store.WatershedReducer.watersheds,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        watermasses: store.WatermassReducer.watermasses,
    }), shallowEqual)

    useEffect(() => {
        setStation(defaultStationWithWatermasses)
    }, [defaultStationWithWatermasses])

    const onChangeStation = (newObj) => {
        setStation({ ...station, ...newObj })
        onChange(newObj)
    }

    const hydrometricStationsFormatted = useMemo(() => hydrometricStations.map(hydro => ({
        ...hydro,
        name: `[${hydro.code}] ${hydro.name}`,
    })), [hydrometricStations])

    return (
        <Card id='context_card'>
            <CardTitle title={i18n.context} />
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={12}>
                        <Checkbox
                            label={i18n.referenceWork}
                            checked={station.referenceWork}
                            onChange={v => onChangeStation({ referenceWork: v })}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={station.watermassCode}
                            label={i18n.watermass}
                            options={watermasses}
                            keyValue='code'
                            displayWithCode
                            onChange={v => onChangeStation({ watermassCode: v })}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={station.watershed1}
                            label={i18n.watershed}
                            options={watersheds}
                            keyValue='id'
                            onChange={v => onChangeStation({ watershed1: v })}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={station.watershed2}
                            label={i18n.watershed2}
                            options={watersheds}
                            keyValue='id'
                            onChange={v => onChangeStation({ watershed2: v })}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={station.watershed3}
                            label={i18n.watershed3}
                            options={watersheds}
                            keyValue='id'
                            onChange={v => onChangeStation({ watershed3: v })}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={station.watershed4}
                            label={i18n.watershed4}
                            options={watersheds}
                            keyValue='id'
                            onChange={v => onChangeStation({ watershed4: v })}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={station.hydroCode}
                            label={i18n.stationHYDROCode}
                            options={hydrometricStationsFormatted}
                            keyValue='code'
                            onChange={v => onChangeStation({ hydroCode: v })}
                            readMode={readMode}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            value={station.river}
                            title={i18n.river}
                            onChange={v => onChangeStation({ river: v })}
                            readMode={readMode}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

InstallationContextPanel.propTypes = {
    defaultStation: PropTypes.instanceOf(DtoInstallation),
    onChange: PropTypes.func,
    readMode: PropTypes.bool,
}

export default InstallationContextPanel
