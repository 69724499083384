import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { instanceOf } from '../../../utils/StoreUtils'
import { getFullDate } from '../../../utils/DateUtil'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import i18n from 'simple-react-i18n'
import { compact, orderBy, uniqBy } from 'lodash'
import PluviometryAction from '../../../pluviometry/actions/PluviometryAction'
import { getAllLastMeasuresFormat } from '../../../alerting/utils/MeasureUtil'
import PiezometryAction from '../../../piezometry/actions/PiezometryAction'
import PropTypes from 'prop-types'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import Row from '../../react/Row'
import iconQualito from 'assets/pictures/markers/map_popup_qualito.svg'
import iconPiezo from 'assets/pictures/markers/map_popup_piezo.svg'
import iconPluvio from 'assets/pictures/markers/map_popup_pluvio.svg'
import iconHydro from 'assets/pictures/markers/map_popup_hydro.svg'
import iconInstallation from 'assets/pictures/markers/map_popup_installations.svg'
import iconUnite from 'assets/pictures/markers/map_unites.png'
import iconContact from 'assets/pictures/markers/map_contact.png'
import { Grid } from '@mui/material'
import AppStore from '../../../store/AppStore'
import { push } from '@lagunovsky/redux-react-router'
import CityAction from '../../../referencial/components/city/actions/CityAction'
import CardMapStation from './CardMapStation'
import { getHardPiezoDataTypes } from 'utils/PiezometryUtils'
import { getHardHydroDataTypes } from 'utils/HydroUtils'
import { ALL_THEME } from 'alerting/constants/ThemeConstants'
import { LARGE_RADIUS } from 'utils/constants/Theme'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'

// React cannot trigger clicks from this component which is on the Map, you must use the EventListener in PopupContent.js
const MapPopupStation = ({
    station = {},
    click = false,
    setStation = () => {},
}) => {
    const {
        citiesIndex,
        piezometryDataTypes,
        hydrometryDataTypes,
        pluviometryDataTypes,
    } = useSelector(store => ({
        citiesIndex: store.CityReducer.citiesIndex,
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
    }), shallowEqual)

    const [dataLoaded, setDataLoaded] = useState(false)
    const [lastMeasures, setLastMeasures] = useState([])

    const dispatch = useDispatch()

    const { isLoaded } = useProgressDispatch(() => compact([
        !citiesIndex.length && dispatch(CityAction.fetchCities()),
        !piezometryDataTypes.length && dispatch(PiezometryAction.fetchPiezometryDataTypes()),
        !hydrometryDataTypes.length && dispatch(HydrometryAction.fetchHydrometryDataTypes()),
        !pluviometryDataTypes.length && dispatch(PluviometryAction.fetchPluviometryDataTypes()),
    ]), [])

    const dataTypePiezo = [...getHardPiezoDataTypes().filter(dt => !piezometryDataTypes.some(odt => odt.id === dt.id)), ...piezometryDataTypes]
    const dataTypeHydro = [...getHardHydroDataTypes().filter(dt => !hydrometryDataTypes.some(odt => odt.id === dt.id)), ...hydrometryDataTypes]

    useEffect(() => {
        if (station?.id) {
            setDataLoaded(false)
            switch (station.typeName) {
                case STATION_TYPE_NAME.piezometry:
                    PiezometryAction.promisePiezoSituationLastMeasures([station.id])
                        .then(piezoSituationLastMeasures => {
                            setLastMeasures(getAllLastMeasuresFormat(
                                station.id,
                                piezoSituationLastMeasures,
                                STATION_TYPE_NAME.piezometry,
                                dataTypePiezo,
                            ).map(m => ({ ...m, id: station.id })))
                            setDataLoaded(true)
                        })
                    break
                case STATION_TYPE_NAME.hydrometry:
                    HydrometryAction.promiseHydroSituationLastMeasures([station.id])
                        .then(hydroSituationLastMeasures => {
                            setLastMeasures(getAllLastMeasuresFormat(
                                station.id,
                                hydroSituationLastMeasures,
                                STATION_TYPE_NAME.hydrometry,
                                dataTypeHydro,
                            ).map(m => ({ ...m, id: station.id })))
                            setDataLoaded(true)
                        })
                    break
                case STATION_TYPE_NAME.pluviometry:
                    PluviometryAction.promisePluvioSituationLastMeasures([station.id])
                        .then(pluvioSituationLastMeasures => {
                            setLastMeasures(getAllLastMeasuresFormat(
                                station.id,
                                pluvioSituationLastMeasures,
                                STATION_TYPE_NAME.pluviometry,
                                pluviometryDataTypes,
                            ).map(m => ({ ...m, id: station.id })))
                            setDataLoaded(true)
                        })
                    break
                default:
                    setLastMeasures([])
                    setDataLoaded(true)
                    break
            }
        } else {
            setLastMeasures([])
            setDataLoaded(true)
        }
    }, [station, click])

    useEffect(() => {
        if (setStation) {
            setStation(station)
        }
    }, [station])

    const color = station.color || ALL_THEME[station.typeName.toUpperCase()]?.color
    const icon = (() => {
        if (station.markerIcon) {
            return station.markerIcon
        }
        switch (station.typeName) {
            case 'quality':
                return iconQualito
            case 'piezometry':
                return iconPiezo
            case 'pluviometry':
                return iconPluvio
            case 'hydrometry':
                return iconHydro
            case 'installation':
                return iconInstallation
            case 'productionUnit':
                return iconUnite
            case 'contact':
            case 'contributor': return iconContact
            default:
                return null
        }
    })()

    const allDataTypes = {
        piezometry: dataTypePiezo,
        hydrometry: dataTypeHydro,
        pluviometry: pluviometryDataTypes,
    }
    const dataTypes = allDataTypes[station.typeName]
    const lastMeasureFormatted = orderBy(uniqBy(lastMeasures, 'dataType'), 'dataType').map(lm => {
        const measureDate = lm.lastMeasureDate || lm.measureDate || lm.date
        return {
            ...lm,
            unit: lm.unit || lm?.dataType ? dataTypes?.find(dt => dt.id === lm.dataType)?.unit : '',
            measureDate,
            dataTypeLabel: lm.title || lm?.dataType ? dataTypes?.find(dt => dt.id === lm.dataType)?.label : '',
        }
    })

    const site = {
        ...station,
        lastMeasures: lastMeasureFormatted,
        markerIcon: icon,
        color,
    }

    return station.popupContent ? (
        <div className='popupStation'>
            {station.popupContent}
        </div>
    ) : station.popupContentWithIcon ? (
        <div className='row no-margin valign-wrapper popupStation'>
            <div className='col s1 no-padding no-margin-left'>
                {
                    icon?.startsWith('<svg') ? (
                        <img className='responsive-img' src={`data:image/svg+xml;utf8,${icon}`} />
                    ) : (
                        <img className='max-width-20 margin-right-1 responsive-img' src={icon} />
                    )
                }
            </div>
            {station.popupContentWithIcon}
        </div>
    ) : (
        <Grid
            container item
            onClick={() => AppStore.dispatch(push(`/station/${station.typeName}/${site.id}/dashboard`))}
            sx={{ backgroundColor: 'white', borderRadius: LARGE_RADIUS, overflow: 'hidden' }}
        >
            {(!isLoaded || !dataLoaded) ? (
                <Grid
                    container item xs={10}
                    sx={{
                        backgroundColor: 'white',
                        padding: '20px 0',
                        borderRadius: '0 10px 10px 0',
                    }}
                    justifyContent='center'
                    alignItems='center'
                    direction='column'
                >
                    {i18n.progressLoading}
                </Grid>
            ) : (
                <>
                    {station.typeName === 'cms' ? (
                        <div className='col s12 no-padding'>
                            <a href={`#/contents/${station.idCategory}/${station.id}`}>
                                <Row>
                                    <p className='bold'>{station.title}</p>
                                </Row>
                                <Row>
                                    <p>{getFullDate(station.dateDebut)} {station.dateFin ? ` au ${getFullDate(station.dateFin)}` : ''}</p>
                                </Row>
                            </a>
                        </div>
                    ) : (
                        <Grid container item xs={12}>
                            <CardMapStation
                                site={site}
                                isMap
                                onClick={station.onClick}
                                detailed={station.typeName === STATION_TYPE_NAME.quality || click}
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    )
}

MapPopupStation.propTypes = {
    station: instanceOf(DtoQualitometer),
    click: PropTypes.bool,
    setStation: PropTypes.func,
}

export default MapPopupStation