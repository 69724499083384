import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { getSandreList } from '../../../../utils/StoreUtils'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import Checkbox from '../../../../components/forms/Checkbox'
import NumberField from '../../../../components/forms/NumberField'
import DtoLiftingStation from '../dto/DtoLiftingStation'
import Select from '../../../../components/forms/Select'
import { getVisitCheckBoxChange, getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import { Grid2 } from '@mui/material'

const LiftingCivilEngPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    lifting = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const onChangeCivilEng = (value) => {
        onChange({
            link_civilEngs: [
                {
                    idStation: lifting.idStation,
                    ...lifting.link_civilEngs[0],
                    ...value,
                },
            ],
        })
    }

    const civilEng = lifting.link_civilEngs[0] || {}

    return (
        <>
            <Grid2 size={6}>
                <Select
                    value={civilEng.tankNature}
                    label={i18n.tankNature}
                    keyValue='code'
                    options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_NATUREEQUIPEMENT)}
                    onChange={(v) => {
                        onChangeCivilEng({ tankNature: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_NATUREEQUIPEMENT, i18n.tankNature, civilEng.tankNature, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={civilEng.antiAcidCoating}
                    label={i18n.antiAcidCoating}
                    onChange={(v) => {
                        onChangeCivilEng({ antiAcidCoating: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.antiAcidCoating, civilEng.antiAcidCoating, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.altiLevel}
                    title={i18n.altiLevel}
                    onChange={(v) => {
                        onChangeCivilEng({ altiLevel: v })
                        onChangeVisit({ previousValue: civilEng.altiLevel, newValue: v, field: i18n.altiLevel })
                    }}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.waterEnterLevel}
                    title={i18n.waterEnterLevel}
                    onChange={(v) => {
                        onChangeCivilEng({ waterEnterLevel: v })
                        onChangeVisit({ previousValue: civilEng.waterEnterLevel, newValue: v, field: i18n.waterEnterLevel })
                    }}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.bottomTankLevel}
                    title={i18n.bottomTankLevel}
                    onChange={(v) => {
                        onChangeCivilEng({ bottomTankLevel: v })
                        onChangeVisit({ previousValue: civilEng.bottomTankLevel, newValue: v, field: i18n.bottomTankLevel })
                    }}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.depthTank}
                    title={i18n.depth}
                    onChange={(v) => {
                        onChangeCivilEng({ depthTank: v })
                        onChangeVisit({ previousValue: civilEng.depthTank, newValue: v, field: i18n.depth })
                    }}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={civilEng.overfull}
                    label={i18n.overfull}
                    onChange={(v) => {
                        onChangeCivilEng({ overfull: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.overfull, civilEng.overfull, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.overfullLevel}
                    title={i18n.overfullLevel}
                    onChange={(v) => {
                        onChangeCivilEng({ overfullLevel: v })
                        onChangeVisit({ previousValue: civilEng.overfullLevel, newValue: v, field: i18n.overfullLevel })
                    }}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={civilEng.bufferTank}
                    label={i18n.bufferTank}
                    onChange={(v) => {
                        onChangeCivilEng({ bufferTank: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.bufferTank, civilEng.bufferTank, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.bufferTankVolume}
                    title={i18n.bufferTankVolume}
                    onChange={(v) => {
                        onChangeCivilEng({ bufferTankVolume: v })
                        onChangeVisit({ previousValue: civilEng.bufferTankVolume, newValue: v, field: i18n.bufferTankVolume })
                    }}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
        </>
    )
}

LiftingCivilEngPanel.propTypes = {
    lifting: PropTypes.instanceOf(DtoLiftingStation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}


export default LiftingCivilEngPanel
