import SimpleFilterSelect from 'components/forms/specific/SimpleFilterSelect'
import SelectedElementsModal from 'components/modal/SelectedElementsModal'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import i18n from 'simple-react-i18n'
import { STATION_TYPE_NAME } from 'station/constants/StationConstants'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { v4 as uuidv4 } from 'uuid'
import ProgressCard from '../../../components/card/ProgressCard'
import Table from '../../../components/datatable/Table'
import Input from '../../../components/forms/Input'
import RadioButtons from '../../../components/forms/RadioButtons'
import Select from '../../../components/forms/Select'
import SieauAction from '../../../components/sieau/SieauAction'
import ContributorAction from '../../../referencial/components/contributor/actions/ContributorAction'
import SimpleDatePicker from '../../../components/forms/SimpleDatePicker'
import Checkbox from '../../../components/forms/Checkbox'
import HydrometryAction from '../../../hydrometry/actions/HydrometryAction'
import Job from '../../../import/dto/Job'
import { nbPerPageLabelShort } from '../../../referencial/constants/ReferencialConstants'
import ContactAction from '../../../referencial/components/contact/actions/ContactAction'

const ALL = 'ALL'
const CUSTOM = 'CUSTOM'
const DIFF = 'DIFF'
const UPDATE_DATE = 'UPDATE_DATE'
const FILTERS = 'FILTERS'
const STATIONS = 'STATIONS'

const ExportHydroportailPanel = ({
    job = {},
    onChangeJob = () => {},
    isEditMode = false,
}) => {
    const {
        hydrometricStations,
        contributors,
        // cities,
        contacts,
    } = useSelector(store => ({
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        contributors: store.ContributorReducer.contributors,
        // cities: store.CityReducer.cities,
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    const exportModes = [
        { code: ALL, name: i18n.exportHistory },
        { code: CUSTOM, name: i18n.choiseDate },
        { code: DIFF, name: i18n.exportAccordingLastAutoExport },
        // { code: UPDATE_DATE, name: i18n.exportWithUpdateDate },
    ]

    const selectStationsMode = [
        { code: FILTERS, name: i18n.selectByCriteria },
        { code: STATIONS, name: i18n.selectStations },
    ]

    const dispatch = useDispatch()

    const { progress, isLoaded } = useProgressDispatch(() => [
        dispatch(HydrometryAction.fetchHydrometricStations()),
        dispatch(ContributorAction.fetchContributors()),
        // dispatch(CityAction.fetchCities()),
        dispatch(ContactAction.fetchContacts()),
    ], [])

    const jobFilters = useMemo(() => job.parameters.filters.length ? JSON.parse(job.parameters.filters[0]) : { height: true, flow: true }, [job.parameters.filters])

    const onChangeJobParameter = (changes) => onChangeJob({ parameters: { ...job.parameters, ...changes } })

    const onChangeFilters = (changes) => onChangeJob({ parameters: { ...job.parameters, filters: [JSON.stringify({ ...jobFilters, ...changes })] } })

    const getModal = (selectedStations) => {
        const id = uuidv4()
        const actions = (
            <div>
                <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
            </div>
        )
        const popup = {
            id,
            header: i18n.selectStationsToBeExported,
            actions,
            minHeight: '65vh',
            className: 'hugeModal',
            content: <SelectedElementsModal
                selectedList={selectedStations}
                onChange={(list) => onChangeFilters({ stations: list.map(p => p.id) })}
                list={hydrometricStations}
            />,
        }
        dispatch(SieauAction.setPopup(popup))
    }

    const contributorsFormatted = useMemo(() => contributors.filter(c => c.siret?.length > 0).map((c) => ({
        ...c,
        nameFormatted: `${c?.siret} - ${c?.name || ''}`,
    })), [contributors])

    const selectedStations = useMemo(() => hydrometricStations.filter(p => (jobFilters.stations || []).includes(p.id)), [jobFilters.stations, hydrometricStations])

    if (!isLoaded) {
        return <ProgressCard progress={ progress } />
    }

    return (
        <div>
            <div className='row no-margin padding-top-1 valign-wrapper'>
                <Input
                    col={4}
                    title={i18n.directory}
                    value={job.parameters.path}
                    onChange={v => onChangeJobParameter({ path: v })}
                    disabled={!isEditMode}
                />
                <Select
                    col={4}
                    options={exportModes}
                    label={i18n.exportMode}
                    onChange={v => onChangeFilters({ mode: v })}
                    value={jobFilters.mode}
                    disabled={!isEditMode}
                    tooltip={i18n.exportQesoutTip}
                />
                {(() => {
                    if (jobFilters.mode === CUSTOM) {
                        return [
                            <SimpleDatePicker
                                col={2}
                                label={i18n.startDate}
                                value={jobFilters.startDate}
                                onChange={v => onChangeFilters({ startDate: v })}
                                disabled={!isEditMode}
                            />,
                            <SimpleDatePicker
                                col={2}
                                label={i18n.endDate}
                                value={jobFilters.endDate}
                                onChange={v => onChangeFilters({ endDate: v })}
                                disabled={!isEditMode}
                            />,
                        ]
                    }
                    // if (jobFilters.mode === UPDATE_DATE) {
                    //     return (
                    //         <SimpleDatePicker
                    //             col={4}
                    //             label={i18n.updateDate}
                    //             value={jobFilters.updateDateModeDate}
                    //             onChange={v => onChangeFilters({ updateDateModeDate: v })}
                    //             disabled={!isEditMode}
                    //         />
                    //     )
                    // }
                    return <div className='col s4' />
                })()}
            </div>
            <div className='row no-margin padding-top-1 valign-wrapper'>
                <MultiContributorsAutocomplete
                    col={4}
                    label={`${i18n.contributor} ${i18n.sender}`}
                    values={jobFilters.sender}
                    onChange={c => onChangeFilters({ sender: c })}
                    options={contributorsFormatted}
                    keyLabel='nameFormatted'
                    disabled={!isEditMode}
                    obligatory={true}
                />
                <Select
                    col={4}
                    options={contacts}
                    label={`${i18n.contact} ${i18n.sender}`}
                    value={jobFilters.senderContact}
                    nullLabel='&nbsp;'
                    displayWithCode
                    onChange={c => onChangeFilters({ senderContact: c })}
                    disabled={!isEditMode}
                    obligatory={true}
                />
                <div className='col s4'/>
            </div>
            <div className='row no-margin padding-top-1'>
                <RadioButtons
                    col={4}
                    title={i18n.selectStations}
                    elements={selectStationsMode}
                    selected={jobFilters.selectStationsMode || FILTERS }
                    onChange={v => onChangeFilters({ selectStationsMode: v })}
                    disabled={!isEditMode}
                />
                <Checkbox
                    col={2}
                    label='Données brutes'
                    checked={jobFilters.bruteMeasures}
                    onChange={c => onChangeFilters({ bruteMeasures: c })}
                    disabled={!isEditMode}
                />
                <Checkbox
                    col={2}
                    label='Données validées'
                    checked={jobFilters.validMeasures}
                    onChange={c => onChangeFilters({ validMeasures: c })}
                    disabled={!isEditMode}
                />
                <Checkbox
                    col={2}
                    label='Hauteurs'
                    checked={jobFilters.height}
                    onChange={c => onChangeFilters({ height: c })}
                    disabled={!isEditMode}
                />
                <Checkbox
                    col={2}
                    label='Débits'
                    checked={jobFilters.flow}
                    onChange={c => onChangeFilters({ flow: c })}
                    disabled={!isEditMode}
                />
            </div>
            {!jobFilters.selectStationsMode || jobFilters.selectStationsMode === FILTERS ? (
                <div className='row no-margin padding-top-1'>
                    {/* <Select*/}
                    {/*    col={4}*/}
                    {/*    options={networks}*/}
                    {/*    label={i18n.network}*/}
                    {/*    value={jobFilters.network}*/}
                    {/*    onChange={c => onChangeFilters({ network: c })}*/}
                    {/*    disabled={!isEditMode}*/}
                    {/* />*/}
                    {/* <Select*/}
                    {/*    col={4}*/}
                    {/*    options={cities}*/}
                    {/*    label={i18n.city}*/}
                    {/*    value={jobFilters.city}*/}
                    {/*    onChange={c => onChangeFilters({ city: c })}*/}
                    {/*    disabled={!isEditMode}*/}
                    {/*    keyLabel='labelWithCode'*/}
                    {/* />*/}
                    <SimpleFilterSelect
                        col={4}
                        onChange={(_, f) => onChangeFilters({ stationsFilter: f && f !== -1 ? f : null })}
                        stations={hydrometricStations}
                        stationType={STATION_TYPE_NAME.hydrometry}
                        disabled={!isEditMode}
                        value= {jobFilters.stationsFilter}
                    />
                </div>
            ) : (
                <div className='row no-margin padding-top-1'>
                    <Table
                        title={i18n.stations}
                        data={selectedStations}
                        nbPerPageLabel={nbPerPageLabelShort}
                        type={{ headers: ['code', 'name'] }}
                        actions={[{ iconName: 'edit', onClick: () => getModal(selectedStations) }]}
                        sortable condensed activeHeader paging
                    />
                </div>
            )}
        </div>
    )
}

ExportHydroportailPanel.propTypes = {
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
}

export default ExportHydroportailPanel