import React, { useEffect, useState } from 'react'
import { genericPromise } from '../../../../../utils/ActionUtils'
import { path } from '../../../../../conf/basepath'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import i18n from 'simple-react-i18n'
import SelectStationComponent from '../../../../../components/modal/SelectStationComponent'
import Button from '../../../../../components/forms/Button'
import Row from '../../../../../components/react/Row'
import { shallowEqual, useSelector } from 'react-redux'
import AppStore from '../../../../../store/AppStore'
import PiezometryAction from '../../../../../piezometry/actions/PiezometryAction'
import { nbPerPageLabel } from '../../../../../referencial/constants/ReferencialConstants'
import Table from '../../../../../components/datatable/Table'
import EChart from '../../../../../components/echart/EChart'
import Line from '../../../../../components/echart/series/Line'
import Axis from '../../../../../components/echart/Axis'
import { hasValue, round } from '../../../../../utils/NumberUtil'
import { setConfirmationModal } from '../../../../../utils/FormUtils'
import StationLandmarkPanel from '../../../../../station/components/link/StationLandmarkPanel'
import StationAltimetrySystemsPanel from '../../../../../station/components/link/StationAltimetrySystemsPanel'
import { yAutomaticScaleTab } from '../../../../../components/echart/EChartUtils'
import ToastrAction from '../../../../../toastr/actions/ToastrAction'
import NetworkAction from '../../../../../referencial/components/network/actions/NetworkAction'
import { orderBy } from 'lodash'


const getProbaColor = (proba) => {
    switch (proba) {
        case 'Improbable':
            return '#335d00'
        case 'Très peu probable':
            return '#529a00'
        case 'Peu probable':
            return '#9a7b00'
        case 'Probable':
            return '#ea7f00'
        case 'Très probable':
            return '#ff3312'
        default:
            return '#b91b00'
    }
}

const getChart = (p) => {
    if (!p) {
        return null
    }
    const yScale = yAutomaticScaleTab(p.actualMeasures, p.correctedMeasures)
    const options = {
        series: [Line({
            data: p.actualMeasures,
            name: i18n.chronic,
            connectNulls: false,
            showSymbol: false,
            color: '#222',
            serieId: 'bruteMeasures',
            markLine: {
                symbol: 'diamond',
                label: { show: false },
                lineStyle: {
                    type: 'solid',
                    width: 2,
                    color: '#999',
                },
                data: p.datesReperes.map(d => ({ xAxis: d })),
            },
        }), Line({
            data: p.correctedMeasures,
            name: 'mesures corrigées',
            connectNulls: false,
            showSymbol: false,
            color: '#335d00',
            serieId: 'corrected',
        })],
        title: '[NGF] Recalcul des NGF à partir du dernier repère',
        grid: [{
            top: 25,
            right: '2%',
            height: 300,
            left: 100,
        }],
        xAxis: [Axis({
            type: 'time',
            position: 'bottom',
            showSplitLine: true,
        })],
        yAxis: [Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${i18n.chronic} (m)`,
            nameGap: 40,
            showSplitLine: true,
            ...yScale,
        })],
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        setDataZoom: true,
        height: 400,
    }
    return (
        <EChart options={options} id='v6Chart' initialZoomValueStart={0} initialZoomValueEnd={100}/>
    )
}

const getDepthChart = (p, v6Prof) => {
    if (!p) {
        return null
    }

    const corrActual = p.actualMeasures.map(m => [m[0], round(p.lastCote - m[1])])
    const corrCorr = p.correctedMeasures.map(m => [m[0], round(p.lastCote - m[1])])
    const yScale = yAutomaticScaleTab(corrActual, corrCorr, v6Prof)
    const options = {
        series: [Line({
            data: corrActual,
            name: i18n.chronic,
            connectNulls: false,
            showSymbol: false,
            color: '#222',
            serieId: 'bruteMeasures',
            markLine: {
                symbol: 'diamond',
                label: { show: false },
                lineStyle: {
                    type: 'solid',
                    width: 2,
                    color: '#999',
                },
                data: p.datesReperes.map(d => ({ xAxis: d })),
            },
        }), Line({
            data: corrCorr,
            name: 'mesures corrigées',
            connectNulls: false,
            showSymbol: false,
            color: '#335d00',
            serieId: 'corrected',
        }), Line({
            data: orderBy(v6Prof, m => m[0]).map(m => [m[0], m[1] * -1]),
            name: 'mesures V6 (pz_codebss)',
            connectNulls: false,
            showSymbol: false,
            color: '#985200',
            serieId: 'v6',
        })],
        title: '[Profondeur] Corrigées + actuelles + V6',
        grid: [{
            top: 25,
            right: '2%',
            height: 300,
            left: 100,
        }],
        xAxis: [Axis({
            type: 'time',
            position: 'bottom',
            showSplitLine: true,
        })],
        yAxis: [Axis({
            type: 'value',
            nameLocation: 'middle',
            name: `${i18n.chronic} (m)`,
            nameGap: 40,
            inverse: true,
            showSplitLine: true,
            ...yScale,
        })],
        axisPointer: {
            link: { xAxisIndex: 'all' },
        },
        setDataZoom: true,
        height: 400,
    }
    return (
        <EChart options={options} id='v6ChartDepth' initialZoomValueStart={0} initialZoomValueEnd={100}/>
    )
}

const CorrectChRepereApp = ({}) => {
    const {
        piezometers,
        piezosIndex,
        networks,
        networkPiezoLink,
        piezometersReferents,
    } = useSelector(store => ({
        piezometers: store.PiezometryReducer.piezometersLight,
        piezosIndex: store.PiezometryReducer.piezosIndex,
        networks: store.NetworkReducer.networks,
        networkPiezoLink: store.PiezometryReducer.networkPiezoLink,
        piezometersReferents: store.PiezometryReducer.piezometersReferents,
    }), shallowEqual)
    const [dataLoading, setDataLoading] = useState(false)
    const [filterStations, setFilterStations] = useState([])
    const [corrections, setCorrections] = useState({})
    const [popupOpen, setPopupOpen] = useState(false)
    const [selectedPb, setSelectedPb] = useState(undefined)
    const [pbChReperes, setPbChReperes] = useState([]) // ChReperePbOutput
    const [v6Prof, setV6Prof] = useState([])

    const loadIdentChRepere = () => {
        setDataLoading(true)
        genericPromise(`${path}piezometer/dataTypes/correctV6`, 'POST', { mode: 'IDENT_CH_REPERE', stationIds: filterStations }).then(json => {
            setDataLoading(false)
            setPbChReperes(json)
        })
    }

    const loadV6Prof = (id) => {
        setDataLoading(true)
        genericPromise(`${path}piezometer/dataTypes/correctV6`, 'POST', { mode: 'GET_V6_PROF', stationIds: [id] }).then(json => {
            setDataLoading(false)
            setV6Prof(json)
        })
    }

    const loadCorrectRepere = (ids) => {
        setDataLoading(true)
        genericPromise(`${path}piezometer/dataTypes/correctV6`, 'POST', { mode: 'CORRECT_CH_REPERE', stationIds: ids }).then(json => {
            setDataLoading(false)
            AppStore.dispatch(ToastrAction.success('Corrections effectuées avec succès !'))
            setCorrections(json.reduce((acc, obj) => {
                acc[obj[0]] = obj[1]
                return acc
            }, {}))
        })
    }

    useEffect(() => {
        if (!piezometers.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (!networks.length) {
            AppStore.dispatch(NetworkAction.fetchNetworks())
        }
        if (!networkPiezoLink.length) {
            AppStore.dispatch(PiezometryAction.fetchNetworkPiezoLink())
        }
        if (!piezometersReferents.length) {
            AppStore.dispatch(PiezometryAction.fetchPiezometersReferents())
        }
    }, [])

    const areEqual = p => Math.abs(round(p.v6FirstProf, 3)) === Math.abs(round(p.correctedFirstProf, 3))
    const foundPiezos = pbChReperes.map(p => ({
        ...piezosIndex[p.stationId],
        ...p,
        measuresCount: p.actualMeasures.length,
        nbReperes: p.reperes.length,
        gapReperes: (
            <span className={ 'arrests-level-panel white-text' } style={ { backgroundColor: getProbaColor(p.probaRepere) } }>
                {round(p.decalageRepere, 3)} - {p.probaRepere}
            </span>
        ),
        diffCoteDernierRepere: round(p.diffCoteDernierRepere, 3),
        diffCorrection: round(p.diffCorrection, 3),
        v6FirstProf: { value: round(p.v6FirstProf, 3), color: areEqual(p) ? 'green' : 'red' },
        correctedFirstProf: { value: round(p.correctedFirstProf, 3), color: areEqual(p) ? 'green' : 'red' },
        notCorrectedFirst: round(p.notCorrectedFirst, 3),
        corrections: hasValue(corrections[p.stationId]) ? corrections[p.stationId] : '',
    }))

    return (
        <div>
            <Row>
                <Button col={4} title='Filtrer les stations' onClick={() => setPopupOpen(true)}/>
                <div className='col s4'>{filterStations.length} stations filtrées</div>
                <Button col={4} title='Lancer la recherche' onClick={loadIdentChRepere}/>
            </Row>
            {
                dataLoading && <h5>En cours de calcul ...</h5>
            }
            <Row>
                <Table
                    title='Stations concernées'
                    condensed
                    data={foundPiezos}
                    type={{ headers: ['id', 'code', 'name', 'measuresCount', 'nbReperes', 'gapReperes', 'diffCoteDernierRepere', 'diffCorrection', 'v6FirstProf', 'correctedFirstProf', 'notCorrectedFirst', 'corrections'] }}
                    customHeaders={{
                        nbReperes: 'Nb repères',
                        gapReperes: 'Décalage dernier repère',
                        corrections: 'Corrections',
                        diffCoteDernierRepere: 'Diff cote dernier repère',
                        diffCorrection: 'Diff entre 2 mesures',
                        hasV6Table: 'V6 présente',
                        v6FirstProf: '1ère prof v6',
                        correctedFirstProf: '1ère prof corrigée',
                        notCorrectedFirst: '1ère prof actuelle',
                    }}
                    sortable
                    onClick={p => {
                        setSelectedPb(p)
                        loadV6Prof(p.id)
                    }}
                    paging
                    nbPerPageLabel={nbPerPageLabel}
                />
            </Row>
            <Row>
                { getChart(selectedPb) }
            </Row>
            <Row>
                { getDepthChart(selectedPb, v6Prof) }
            </Row>
            {
                selectedPb ? (
                    <Row className='padding-1 no-padding-bottom'>
                        <StationLandmarkPanel station={ { link_landmarks: selectedPb.reperes, link_altimetrySystems: selectedPb.sysAltis } } className='blue' readMode={true}/>
                    </Row>
                ) : null
            }
            {
                selectedPb ? (
                    <Row className='padding-1 no-padding-bottom'>
                        <StationAltimetrySystemsPanel station={ { link_altimetrySystems: selectedPb.sysAltis } } className='blue' readMode={true}/>
                    </Row>
                ) : null
            }
            <Row>
                <Button col={4} title='!!! Appliquer la correction !!!' onClick={() => setConfirmationModal(() => loadCorrectRepere([selectedPb.stationId]), 'Confirmer la correction ?')}/>
                <Button col={4} title='!!!!!!! Corriger tous les points !!!!!!!' onClick={() => setConfirmationModal(() => loadCorrectRepere(pbChReperes.map(p => p.stationId)), 'Confirmer la correction ?')}/>
            </Row>
            <Row>
                <Button col={4} title='Corriger les points en vert uniquement' onClick={() => setConfirmationModal(() => loadCorrectRepere((foundPiezos.filter(p => p.v6FirstProf.color === 'green').map(p => p.stationId) || [])), 'Confirmer la correction ?')}/>
            </Row>
            <Dialog
                onClose={() => setPopupOpen(false)}
                fullWidth
                maxWidth='lg'
                open={popupOpen}
                classes='no-padding'
                scroll='body'
            >
                <DialogTitle>{i18n.calculationParams}</DialogTitle>
                <DialogContent>
                    <SelectStationComponent
                        selectedStations={filterStations}
                        campaignEvents={[]}
                        stationType='piezometry'
                        onChangeSelectedStation={tmpStations => setFilterStations(tmpStations)}
                        networkLinks={networkPiezoLink}
                        // minHeight='30vh'
                    />
                </DialogContent>
            </Dialog>
        </div>
    )
}

CorrectChRepereApp.propTypes = {
}

export default CorrectChRepereApp
