import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import Select from '../../../../components/forms/Select'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { getDate } from '../../../../utils/DateUtil'
import { onChangeDate } from '../../../../utils/FormUtils'
import { getSandreList } from '../../../../utils/StoreUtils'
import {
    getVisitCheckBoxChange,
    getVisitDatePickerChange,
    getVisitSandreSelectChange,
} from '../../../../utils/VisitUtils'
import DtoInstallationBorehole from '../../../dto/borehole/DtoInstallationBorehole'
import { Grid2 } from '@mui/material'

const BoreholeManagementPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    borehole = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const onChangeBorehole = (value) => {
        onChange({
            link_civilEngs: [
                {
                    idStation: borehole.id,
                    ...borehole.link_civilEngs[0],
                    ...value,
                },
            ],
        })
    }

    const [civilEng = {}] = borehole.link_civilEngs

    return (
        <>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.depthForation}
                    title={i18n.depthForation}
                    onChange={(v) => {
                        onChangeBorehole({ depthForation: v })
                        onChangeVisit({ previousValue: civilEng.depthForation, newValue: v, field: i18n.depthForation })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.interneCasingDiameter}
                    title={i18n.interneCasingDiameter}
                    onChange={(v) => {
                        onChangeBorehole({ interneCasingDiameter: v })
                        onChangeVisit({ previousValue: civilEng.interneCasingDiameter, newValue: v, field: i18n.interneCasingDiameter })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.thicknessCasing}
                    title={i18n.thicknessCasing}
                    onChange={(v) => {
                        onChangeBorehole({ thicknessCasing: v })
                        onChangeVisit({ previousValue: civilEng.thicknessCasing, newValue: v, field: i18n.thicknessCasing })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={civilEng.natureCasing}
                    label={i18n.natureCasing}
                    keyValue='code'
                    options={getSandreList(sandreCodes, SANDRE.TUBAGES_MATERIAU)}
                    onChange={(v) => {
                        onChangeBorehole({ natureCasing: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.TUBAGES_MATERIAU, i18n.natureCasing, civilEng.natureCasing, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.depthCasing}
                    title={i18n.depthCasing}
                    onChange={(v) => {
                        onChangeBorehole({ depthCasing: v })
                        onChangeVisit({ previousValue: civilEng.depthCasing, newValue: v, field: i18n.depthCasing })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={civilEng.crepineWork}
                    label={i18n.crepineWork}
                    onChange={(v) => {
                        onChangeBorehole({ crepineWork: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.crepineWork, civilEng.crepineWork, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.depthPump}
                    title={i18n.depthPump}
                    onChange={(v) => {
                        onChangeBorehole({ depthPump: v })
                        onChangeVisit({ previousValue: civilEng.depthPump, newValue: v, field: i18n.depthPump })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.depthProbe}
                    title={i18n.depthProbe}
                    onChange={(v) => {
                        onChangeBorehole({ depthProbe: v })
                        onChangeVisit({ previousValue: civilEng.depthProbe, newValue: v, field: `${i18n.civilEngineering} - ${i18n.depthProbe}` })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.TNLevel}
                    title={i18n.TNLevel}
                    onChange={(v) => {
                        onChangeBorehole({ TNLevel: v })
                        onChangeVisit({ previousValue: civilEng.TNLevel, newValue: v, field: i18n.TNLevel })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.flangeLevel}
                    title={i18n.flangeLevel}
                    onChange={(v) => {
                        onChangeBorehole({ flangeLevel: v })
                        onChangeVisit({ previousValue: civilEng.flangeLevel, newValue: v, field: i18n.flangeLevel })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <NumberField
                    value={civilEng.landmarkLevel}
                    title={i18n.landmarkLevel}
                    onChange={(v) => {
                        onChangeBorehole({ landmarkLevel: v })
                        onChangeVisit({ previousValue: civilEng.landmarkLevel, newValue: v, field: i18n.landmarkLevel })
                    }}
                    floatValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={civilEng.coping}
                    label={i18n.coping}
                    onChange={(v) => {
                        onChangeBorehole({ coping: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.coping, civilEng.coping, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={civilEng.rollover}
                    label={i18n.rollover}
                    keyValue='code'
                    options={getSandreList(sandreCodes, SANDRE.INSTALLATIONS_CAPOTAGE)}
                    onChange={(v) => {
                        onChangeBorehole({ rollover: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_CAPOTAGE, i18n.rollover, civilEng.rollover, v))
                    }}
                    integerValue
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Checkbox
                    checked={civilEng.waterproofHead}
                    label={i18n.waterproofHead}
                    onChange={(v) => {
                        onChangeBorehole({ waterproofHead: v })
                        onChangeVisit(getVisitCheckBoxChange(i18n.waterproofHead, civilEng.waterproofHead, v))
                    }}
                    disabled={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Input
                    value={getDate(civilEng.lastInspection)}
                    title={i18n.lastInspection}
                    onChange={v => {
                        onChangeDate(v, v2 => onChangeBorehole({ lastInspection: v2 }))
                        onChangeVisit(getVisitDatePickerChange(i18n.lastInspection, civilEng.lastInspection, v))
                    }}
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

BoreholeManagementPanel.propTypes = {
    borehole: PropTypes.instanceOf(DtoInstallationBorehole),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default BoreholeManagementPanel
