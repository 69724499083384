import SliderPanel from 'components/slider/SliderPanel'
import { isNil } from 'lodash'
import PropTypes from 'prop-types'
import DtoQualityThreshold from 'quality/dto/QualityThreshold/DtoQualityThreshold'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getDate } from 'utils/DateUtil'
import { getLabel } from 'utils/StoreUtils'
import useSandreParameters from 'utils/customHook/useSandreParameters'

const Label = ({
    title = '',
    label = '',
}) => !!label && (
    <span>
        {`${title}: ${label}`}
        <br />
    </span>
)

Label.propTypes = {
    title: PropTypes.string,
    label: PropTypes.string,
}

const TooltipAnalyse = ({
    title = '',
    analyse = {},
    threshold,
    overrideColor,
}) => {
    const {
        contributors,
        supports,
        fractions,
        units,
        qualifications,
        remarks,
        status,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        supports: store.SupportReducer.supports,
        fractions: store.FractionReducer.fractions,
        units: store.UnitReducer.units,
        qualifications: store.QualityReducer.qualifications,
        remarks: store.OperationReducer.remarks,
        status: store.QualityReducer.status,
    }), shallowEqual)

    const parametersSandre = useSandreParameters()

    const producer = contributors.find(c => c.id === analyse.producer)
    const laboratory = contributors.find(c => c.id === analyse.labo)

    const parameterSandre = parametersSandre[analyse.parameter]?.find(ps => ps.code === analyse.result)
    const result = !isNil(parameterSandre) ? `${parameterSandre.name} [${analyse.result}]` : analyse.value
    return (
        <div>
            <Label title={i18n.date} label={getDate(analyse.analysisDate ?? analyse.sampleDate)} />
            <Label title={i18n.result} label={result} />
            <Label title={i18n.unit} label={getLabel(units, analyse.unit, 'symbolWithCode')} />
            <Label title={i18n.remark} label={getLabel(remarks, analyse.remark, 'nameWithCode')} />
            <Label title={i18n.status} label={getLabel(status, analyse.status)} />
            <Label title={i18n.qualification} label={getLabel(qualifications, analyse.qualification)} />
            <Label title={i18n.support} label={getLabel(supports, analyse.support, 'labelWithCode')} />
            <Label title={i18n.fraction} label={getLabel(fractions, analyse.fraction, 'labelWithCode')} />
            <Label title={i18n.producer} label={producer && (producer.mnemonique || producer.name) || ''} />
            <Label title={i18n.laboratory} label={laboratory && (laboratory.mnemonique || laboratory.name) || ''} />
            {
                analyse.remark !== '0' && analyse.color !== 'white' && threshold && (
                    <SliderPanel
                        title={title}
                        analysis = {analyse}
                        threshold={threshold}
                        withThresholdLevels
                        overrideColors={overrideColor}
                    />
                )
            }
        </div>
    )
}

TooltipAnalyse.propTypes = {
    title: PropTypes.string.isRequired,
    analyse: PropTypes.shape({ // analysis + threshold result
        producer: PropTypes.number,
        labo: PropTypes.number,
        status: PropTypes.string,
        qualification: PropTypes.string,
        remark: PropTypes.string,
        unit: PropTypes.string,
        support: PropTypes.number,
        fraction: PropTypes.string,
        value: PropTypes.string,
        color: PropTypes.string,
    }).isRequired,
    threshold: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoQualityThreshold),
        PropTypes.shape({
            parameterCode: PropTypes.string,
            threshold1: PropTypes.number,
            threshold2: PropTypes.number,
        }),
    ]),
    overrideColor: PropTypes.arrayOf(PropTypes.string),
}

export default TooltipAnalyse