/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import useSandreList from 'utils/customHook/useSandreList'
import { SANDRE } from 'referencial/constants/ReferencialConstants'
import { getSandreLabel, getSandreMnemonique } from 'utils/StringUtil'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import { shallowEqual, useSelector } from 'react-redux'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import Input from 'components/forms/Input'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import NumberField from 'components/forms/NumberField'

const headers = ['sandreCode', 'internalCodeSing', 'comment', 'x', 'y', 'projection']

const FollowPointDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const projections = useSandreList(SANDRE.PROJECTION)
    const referencials = useSandreList(SANDRE.STEP_POINTS_REFERENTIELS)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Select
                            value={stateElement.sandreCode}
                            label={i18n.referentielsPoint}
                            options={referencials}
                            displayWithCode
                            onChange={(v) => onChangeElement({ sandreCode: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            value={stateElement.internalCode}
                            title={i18n.internalCodeSing}
                            onChange={v => onChangeElement({ internalCode: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            value={stateElement.comment}
                            title={i18n.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.x}
                            title={i18n.x}
                            onChange={v => onChangeElement({ x: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.y}
                            title={i18n.y}
                            onChange={v => onChangeElement({ y: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={stateElement.projection}
                            label={i18n.projection}
                            options={projections}
                            keyValue='id'
                            keyLabel='mnemonique'
                            displayWithCode
                            onChange={(v) => onChangeElement({ projection: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

FollowPointDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const STEPFollowPointsPanel = ({
    STEP = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={STEP}
            readMode={readMode}
            onChange={onChange}
            keyList='link_followPoints'
            formatFunction={sfp => ({
                ...sfp,
                projection: getSandreLabel(sandreCodes, SANDRE.PROJECTION, sfp.projection),
                sandreCode: getSandreMnemonique(sandreCodes, SANDRE.STEP_POINTS_REFERENTIELS, sfp.sandreCode),
                internalCodeSing: sfp.internalCode,
            })}
            titles={{
                title: i18n.followPoints,
                edit: i18n.editFollowPoint,
                new: i18n.newFollowPoint,
            }}
            headers={headers}
            dialogContent={FollowPointDialogContent}
        />
    )
}

STEPFollowPointsPanel.propTypes = {
    STEP: PropTypes.object, // DtoStep
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
}

export default STEPFollowPointsPanel
