import Checkbox from 'components/forms/Checkbox'
import { push } from '@lagunovsky/redux-react-router'
import { isUndefined, template } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import Input from '../../components/forms/Input'
import NumberField from '../../components/forms/NumberField'
import Select from '../../components/forms/Select'
import UpdatePanel from '../../components/updatePanel/UpdatePanel'
import QualityAction from '../../quality/actions/QualityAction'
import { SANDRE } from '../../referencial/constants/ReferencialConstants'
import LinkedStationsPanel from '../../station/components/associatedStation/LinkedStationsPanel'
import StationAssociatedTownsPanel from '../../station/components/link/StationAssociatedTownsPanel'
import { getStationArrowNav } from '../../utils/ActionUtils'
import { getDate } from '../../utils/DateUtil'
import { onChangeDate } from '../../utils/FormUtils'
import { getLogin } from '../../utils/SettingUtils'
import { getLinks } from '../../utils/StationUtils'
import DistributionUnitAction from '../actions/DistributionUnitAction'
import DistributionUnitNetworksPanel from './DistributionUnitNetworksPanel'
import useActions from 'utils/customHook/useActions'
import useSandreList from 'utils/customHook/useSandreList'
import { Card, CardContent, Grid2 } from '@mui/material'
import { useParams } from 'react-router'
import useUpdateEffect from 'utils/customHook/useUpdateEffect'
import { hasValue } from 'utils/NumberUtil'
import StationContributorPanel from 'station/components/link/StationContributorPanel'

const DistributionUnitDescriptionPanel = ({
    stationTypes = [],
}) => {
    const dispatch = useDispatch()

    const { id } = useParams()

    const {
        distributionUnit,
        distributionUnits,
        users,
        status,
        sectors,
    } = useSelector(store => ({
        distributionUnit: store.DistributionUnitReducer.distributionUnit,
        distributionUnits: store.DistributionUnitReducer.distributionUnits,
        users: store.UserReducer.users,
        status: store.QualityReducer.status,
        sectors: store.SectorReducer.sectors,
    }), shallowEqual)

    const [udiState, setUdiState] = useState(distributionUnit)
    const [readMode, setReadMode] = useState(true)

    useEffect(() => {
        if (!hasValue(distributionUnit.id)) {
            dispatch(DistributionUnitAction.fetchDistributionUnit(id))
        }
        if (!status.length) {
            dispatch(QualityAction.fetchStatus())
        }
    }, [])

    useUpdateEffect(() => {
        setUdiState(distributionUnit)
    }, [distributionUnit])

    const onDelete = () => dispatch(DistributionUnitAction.deleteDistributionUnit(distributionUnit.id, () => dispatch(push('/distributionUnit'))))

    const onSave = () => {
        if (!udiState.code) {
            ToastrAction.error(template(i18n.fieldNotSet)({ field: i18n.code }))
        } else {
            dispatch(DistributionUnitAction.updateDistributionUnit(udiState)).then(() => {
                dispatch(DistributionUnitAction.fetchDistributionUnitSectors())
            })
            setReadMode(true)
        }
    }

    useActions(() => {
        if (readMode) {
            return {
                edit: () => setReadMode(false),
                delete: onDelete,
                links: getLinks(distributionUnit, { stationTypes }),
                arrowNav: getStationArrowNav('distributionUnit', distributionUnits, distributionUnit.id, s => dispatch(push(`/station/distributionUnit/${s.id}/description`))),
            }
        }
        return {
            cancel: () => {
                setUdiState(distributionUnit)
                setReadMode(true)
            },
            save: onSave,
            delete: onDelete,
            links: getLinks(distributionUnit, { stationTypes }),
            arrowNav: getStationArrowNav('distributionUnit', distributionUnits, distributionUnit.id, s => dispatch(push(`/station/distributionUnit/${s.id}/description`))),
        }
    }, [readMode, udiState, distributionUnit])


    const onChangeDistributionUnit = value => setUdiState(prevState => ({ ...prevState, ...value }))

    const waterNatureList = useSandreList(SANDRE.NATURE_EAU)
    const expModeList = useSandreList(SANDRE.MODE_EXPLOITATION)
    const statesList = useSandreList(SANDRE.CODE_ETAT)

    if (isUndefined(udiState.id)) {
        return null
    }

    return (
        <Grid2 container spacing={1} sx={{ marginBottom: '100px', padding: '10 10 0 20' }} alignItems='flex-start'>
            <Grid2 size={9} container spacing={1.5} >
                <Card>
                    <CardContent>
                        <Grid2 container columnSpacing={1.5} rowSpacing={0.5}>
                            <Grid2 size={6}>
                                <Input
                                    value={udiState.code}
                                    title={i18n.code}
                                    onChange={v => onChangeDistributionUnit({ code: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    value={udiState.name}
                                    title={i18n.name}
                                    onChange={v => onChangeDistributionUnit({ name: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    value={udiState.mnemonic}
                                    title={i18n.mnemonic}
                                    onChange={v => onChangeDistributionUnit({ mnemonic: v })}
                                    readMode={readMode}
                                    data-cy='mnemonicUdi'
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    options={statesList}
                                    label={i18n.status}
                                    value={udiState.state}
                                    onChange={v => onChangeDistributionUnit({ state: v })}
                                    readMode={readMode}
                                    data-cy='stateUdi'
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    options={waterNatureList}
                                    label={i18n.waterNature}
                                    value={udiState.waterNature}
                                    onChange={v => onChangeDistributionUnit({ waterNature: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Input
                                    value={getDate(udiState.creationDate)}
                                    title={i18n.creationDate}
                                    onChange={v => onChangeDate(v, v2 => onChangeDistributionUnit({ creationDate: v2 }))}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    value={udiState.sector}
                                    label={i18n.sector}
                                    options={sectors}
                                    onChange={v => onChangeDistributionUnit({ sector: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Select
                                    options={expModeList}
                                    label={i18n.exploitationModeCode}
                                    value={udiState.exploitationModeCode}
                                    onChange={v => onChangeDistributionUnit({ exploitationModeCode: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.decreePopulation}
                                    value={udiState.decreePopulation}
                                    onChange={v => onChangeDistributionUnit({ decreePopulation: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.permanentPopulation}
                                    value={udiState.permanentPopulation}
                                    onChange={v => onChangeDistributionUnit({ permanentPopulation: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.summerPopulation}
                                    value={udiState.summerPopulation}
                                    onChange={v => onChangeDistributionUnit({ summerPopulation: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.winterPopulation}
                                    value={udiState.winterPopulation}
                                    onChange={v => onChangeDistributionUnit({ winterPopulation: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.subscriberNumber}
                                    value={udiState.subscriberNumber}
                                    onChange={v => onChangeDistributionUnit({ subscriberNumber: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.distributedQuantity}
                                    value={udiState.distributedQuantity}
                                    onChange={v => onChangeDistributionUnit({ distributedQuantity: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.lengthWaterPipe}
                                    value={udiState.lengthWaterPipe}
                                    onChange={v => onChangeDistributionUnit({ lengthWaterPipe: v })}
                                    floatValue
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.linearOutConnection}
                                    value={udiState.linearOutConnection}
                                    onChange={v => onChangeDistributionUnit({ linearOutConnection: v })}
                                    floatValue
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.aepNetworkRenewalRate}
                                    value={udiState.aepNetworkRenewalRate}
                                    onChange={v => onChangeDistributionUnit({ aepNetworkRenewalRate: v })}
                                    floatValue
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.nbConnections}
                                    value={udiState.nbConnections}
                                    onChange={v => onChangeDistributionUnit({ nbConnections: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.lengthConnections}
                                    value={udiState.lengthConnections}
                                    onChange={v => onChangeDistributionUnit({ lengthConnections: v })}
                                    floatValue
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.averageAgeConnection}
                                    value={udiState.averageAgeConnection}
                                    onChange={v => onChangeDistributionUnit({ averageAgeConnection: v })}
                                    floatValue
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Checkbox
                                    checked={udiState.mixteSyndicate}
                                    label={i18n.mixteSyndicate}
                                    onChange={v => onChangeDistributionUnit({ mixteSyndicate: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <Checkbox
                                    checked={udiState.member}
                                    label={i18n.member}
                                    onChange={v => onChangeDistributionUnit({ member: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.enterYear}
                                    value={udiState.enterYear}
                                    onChange={v => onChangeDistributionUnit({ enterYear: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.exitYear}
                                    value={udiState.exitYear}
                                    onChange={v => onChangeDistributionUnit({ exitYear: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.treatedlength}
                                    value={udiState.treatedlength}
                                    onChange={v => onChangeDistributionUnit({ treatedlength: v })}
                                    floatValue
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={6}>
                                <NumberField
                                    title={i18n.connectionWithoutCounter}
                                    value={udiState.connectionWithoutCounter}
                                    onChange={v => onChangeDistributionUnit({ connectionWithoutCounter: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                            <Grid2 size={12}>
                                <Input
                                    value={udiState.descriptive}
                                    title={i18n.descriptive}
                                    onChange={v => onChangeDistributionUnit({ descriptive: v })}
                                    readMode={readMode}
                                />
                            </Grid2>
                        </Grid2>
                    </CardContent>
                </Card>
                <Grid2 size={12}>
                    <StationContributorPanel
                        station={udiState}
                        onChange={onChangeDistributionUnit}
                        readMode={readMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <LinkedStationsPanel
                        title={i18n.productionUnit}
                        filteredStations={['productionUnit']}
                        station={udiState}
                        editMode={!readMode}
                        collapsible={false}
                        hideListTitle
                        coloredCard
                    />
                </Grid2>
                <Grid2 size={12}>
                    <StationAssociatedTownsPanel
                        station={udiState}
                        onChange={onChangeDistributionUnit}
                        readMode={readMode}
                    />
                </Grid2>
                <Grid2 size={12}>
                    <DistributionUnitNetworksPanel
                        station={udiState}
                        onChange={onChangeDistributionUnit}
                        readMode={readMode}
                    />
                </Grid2>
            </Grid2>
            <Grid2 size={3} container spacing={1.5} direction='column'>
                {
                    (udiState.updateLogin || udiState.updateDate) &&
                    <Grid2 size={12}>
                        <UpdatePanel updateLogin={udiState.updateLogin}
                            updateDate={udiState.updateDate}
                        />
                    </Grid2>
                }
                <Grid2 size={12}>
                    <Card>
                        <CardContent>
                            <Grid2 container columnSpacing={1}>
                                <Grid2 size={12}>
                                    <Input
                                        value={udiState.dataOrigin}
                                        title={i18n.dataOrigin}
                                        onChange={v => onChangeDistributionUnit({ dataOrigin: v })}
                                        readMode={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Select
                                        label={i18n.status}
                                        value={udiState.status}
                                        onChange={v => onChangeDistributionUnit({
                                            status: v,
                                            statusLogin: getLogin(),
                                            statusDate: moment().valueOf(),
                                        })}
                                        options={status}
                                        integerValue
                                        readMode={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Select
                                        label={i18n.controlDoneBy}
                                        value={udiState.loginStatus}
                                        onChange={v => onChangeDistributionUnit({ loginStatus: v })}
                                        options={users.map(u => ({ code: u.login, name: u.name || u.login }))}
                                        readMode={readMode}
                                    />
                                </Grid2>
                                <Grid2 size={12}>
                                    <Input
                                        value={getDate(udiState.dateStatus)}
                                        title={i18n.controlDoneAt}
                                        onChange={v => onChangeDate(v, v2 => onChangeDistributionUnit({ dateStatus: v2 }))}
                                        readMode={readMode}
                                    />
                                </Grid2>
                            </Grid2>
                        </CardContent>
                    </Card>
                </Grid2>
                <Grid2 size={12}>
                    <LinkedStationsPanel
                        filteredStations={['quality', 'piezometry', 'hydrometry', 'pluviometry', 'installation', 'distributionUnit']}
                        station={distributionUnit}
                        editMode={!readMode}
                    />
                </Grid2>
            </Grid2>
        </Grid2>
    )
}

DistributionUnitDescriptionPanel.propTypes = {
    stationTypes: PropTypes.arrayOf(PropTypes.string),
}

export default DistributionUnitDescriptionPanel
