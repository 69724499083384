/* eslint-disable camelcase */
import { Button, Dialog, Icon, Grid2, DialogContent, DialogActions } from '@mui/material'
import { AGRI } from 'administration/components/user/constants/HabilitationConstants'
import DtoUser from 'administration/components/user/dto/DtoUser'
import AgriAction from 'agriAdministration/actions/AgriAction'
import Checkbox from 'components/forms/Checkbox'
import Input from 'components/forms/Input'
import Select from 'components/forms/Select'
import ExportFileModal from 'components/modal/ExportFileModal'
import { push } from '@lagunovsky/redux-react-router'
import DtoExploitationExportFull from 'exploitations/dto/DtoExploitationExportFull'
import ExportAction from 'export/actions/ExportAction'
import { EXPORT_JOB_STATUS } from 'export/constants/ExportConstants'
import { compact, lowerCase, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import CityDto from 'referencial/components/city/dto/CityDto'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import DtoManagementUnit from 'referencial/components/managementUnit/dto/DtoManagementUnit'
import WatershedDto from 'referencial/components/watershed/dto/WatershedDto'
import { nbPerPageLabelMedium, nbPerPageLabelHuge } from 'referencial/constants/ReferencialConstants'
import DtoSandreCode from 'referencial/dto/DtoSandreCode'
import i18n from 'simple-react-i18n'
import DtoDeclaration from 'survey/dto/DtoDeclaration'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getDate, getDateExport } from 'utils/DateUtil'
import { downloadURI, formatData } from 'utils/ExportDataUtil'
import { getLocalStorageJson, setLocalStorageJson, sieauTooltip } from 'utils/FormUtils'
import { getLogin, getSetting, getUser } from 'utils/SettingUtils'
import { formatMilliers, formatPhone, searchAllCharacters } from 'utils/StringUtil'
import DtoDeclarationExportFull from '../../dto/DtoDeclarationExportFull'
import DtoSurvey from '../../dto/DtoSurvey'
import DeclarationsFilterPanel from './DeclarationsFilterPanel'
import { LOCAL_DECLARATIONS_FILTERS, SURVEY_TYPES } from 'agriAdministration/constants/AgriConstants'
import { hasValue } from 'utils/NumberUtil'
import { DefaultDialogTitle } from 'components/styled/Dialog'
import { ALIGN, CardTable } from 'components/datatable/NewTable'
import { darkBlue } from 'utils/constants/ColorTheme'

const headersTable = ['statusIcon', 'codification', 'city', 'sampler', 'structureType', 'advancement',
    'updateDate', 'modifIcon', 'nbPtReal', 'realVolume', 'nbPtPrevi', 'prevVolume', 'adjustedEstimWrap', 'administrator']

const exportHeaders = ['validatedDec', 'codification', 'cityCode', 'cityLabel', 'samplerName', 'email', 'phoneTel', 'mobile', 'structureType',
    'advancement', 'status', 'updateDate', 'modif', 'nbPtReal', 'realVolume', 'nbPtPrevi', 'prevVolume', 'adjustedEstim', 'administrators']
const exportHeadersFull = ['updateDate', 'activityEndDate', 'codification', 'structureType', 'civility', 'name', 'address', 'additionnalAddress',
    'inseeCode', 'postalCode', 'city', 'phoneTel', 'mobile', 'fax', 'email', 'comment', 'siret', 'pacage', 'octagri_aura', 'octagri_local', 'muse',
    'agence', 'advancement', 'modif', 'nbPtReal', 'realVolume', 'nbPtPrevi', 'prevVolume', 'adjustedEstim', 'administrators']
const headersExportAgency = ['codification', 'structureType', 'civility', 'name', 'address', 'additionnalAddress', 'inseeCode',
    'postalCode', 'city', 'phoneTel', 'mobile', 'fax', 'email', 'siret', 'pacage', 'agence', 'advancement']

class SurveyDeclarationsPanel extends Component {
    constructor(props) {
        super(props)
        const { survey: { link_params = [] } } = props
        this.state = {
            filters: getLocalStorageJson(LOCAL_DECLARATIONS_FILTERS) || { shut: true, adjourned: true },
            modalFilters: {},
            tmpDeclarations: [],
            open: false,
            openTableExportModal: false,
            selectedDeclaration: {},
            dataLoaded: false,
            surveyData: [],
            settings: {},
            modelDeclaration: getSetting(link_params, 'defaultModelDeclaration'),
            modelSynthese: getSetting(link_params, 'defaultModelSynthese'),
            modelNotification: getSetting(link_params, 'defaultModelNotification'),
        }
    }

    getSurveyData = () => {
        const { survey, users, exploitationsExportFullData } = this.props
        const isConsult = getUser()?.consultant === '1'
        return survey.link_declarations.map(declaration => {
            const administrators = declaration.idExploitation && survey.link_codifications.filter(c => c.idExploitation === declaration.idExploitation && c.codification !== declaration.codification) || []
            const displaySelfAssignButton = !administrators.find(adm => adm.codification === getLogin())
            const cityValue = declaration.cityCode ? `${declaration.city} [${declaration.cityCode}]` : ''
            const consult = survey.statusCode === 3 || declaration.statusCode >= 4 || isConsult
            const exploitation = exploitationsExportFullData.find((e) => e.idExploitation === declaration.idExploitation) || {}
            const advancement = (declaration.lastStep * 100) / 4
            return {
                ...declaration,
                exploitation,
                adjourned: !(exploitation.link_points || []).find((lp) => !lp.endDate || lp.endDate > moment().valueOf()),
                // table values
                statusIcon: { value: <div style={{ padding: '3px' }}>{this.getAdvancementIcon(declaration.lastStep, declaration.statusCode)}</div>, sortValue: declaration.statusCode },
                codification: { value: declaration.codification },
                city: {
                    value: (
                        <span
                            className='clickable'
                            {...sieauTooltip(cityValue, null, 'bottom')}
                        >
                            { cityValue }
                        </span>
                    ),
                    style: {
                        width: '8vw',
                    },
                },
                sampler: {
                    value: (
                        <span
                            className='clickable'
                            {...sieauTooltip(`${declaration.name}\n${i18n.email} : ${declaration.email || ''} \n${i18n.phoneTel} : ${formatPhone(declaration.phoneTel) || ''} \n${i18n.mobile} : ${formatPhone(declaration.mobile) || ''}`, null, 'bottom')}
                        >
                            { declaration.name }
                        </span>
                    ),
                    style: {
                        width: '10vw',
                    },
                    sortValue: declaration.name,
                    onClick: () => this.props.push(`/referencial/contributor/${declaration.idContributor}`),
                },
                structureType: { value: declaration.structureType },
                advancement: { value: `${declaration.lastStep ? (advancement > 100 ? 100 : advancement) : 0}%`, align: ALIGN.RIGHT },
                updateDate: { value: getDate(declaration.updateDate) },
                updateDateValue: declaration.updateDate,
                modifIcon: {
                    value: users.find(u => u.login === declaration.updateLogin && (u.admin === '1' || u.metadata === '1')) ? (
                        <Icon className='clickable' {...sieauTooltip(declaration.updateLogin, null, 'bottom')}>person</Icon>
                    ) : null,
                },
                modif: {
                    value: users.find(u => u.login === declaration.updateLogin && (u.admin === '1' || u.metadata === '1')) ? declaration.updateLogin : '',
                },
                nbPtReal: {
                    value: formatMilliers(declaration.nbPtReal),
                    sortValue: declaration.nbPtReal,
                },
                adjustedEstimWrap: {
                    value: declaration.volumesAdjusted ? (
                        <Icon>check_box</Icon>
                    ) : null,
                    align: ALIGN.CENTER,
                },
                adjustedEstim: { value: declaration.volumesAdjusted ? i18n.yes : i18n.no },
                realVolume: {
                    value: formatMilliers(declaration.realVolume),
                    align: ALIGN.RIGHT,
                    sortValue: declaration.realVolume,
                },
                nbPtPrevi: {
                    value: formatMilliers(declaration.nbPtPrevi),
                    sortValue: declaration.nbPtPrevi,
                },
                prevVolume: {
                    value: formatMilliers(declaration.prevVolume),
                    align: ALIGN.RIGHT,
                    sortValue: declaration.prevVolume,
                },
                administrator: {
                    data: administrators.map(c => c.codification).join(', '),
                    value: (
                        <span
                            className='clickable'
                            {...sieauTooltip(administrators.map(c => c.codification).join(', '), null, 'bottom')}
                        >
                            {administrators.map(c => c.codification).join(', ')}
                        </span>
                    ),
                    style: {
                        width: '5vw',
                    },
                },
                nullValue2: {
                    value: !isConsult ? (
                        <Icon className='clickable' {...sieauTooltip(displaySelfAssignButton ? i18n.selfAssign: i18n.disaffect, null, 'bottom')}>
                            {displaySelfAssignButton ? 'person_add' : 'person_add_disabled'}
                        </Icon>
                    ) : null,
                    onClick: isConsult ? () => {} : (displaySelfAssignButton ? () => this.selfAssign(declaration.idExploitation) : () => this.desaffect(declaration.idExploitation)),
                },
                nullValue3: {
                    value: <Icon className='clickable' {...sieauTooltip(consult ? i18n.consult : i18n.edit, null, 'bottom')}>{consult ? 'remove_red_eye' : 'edit'}</Icon>,
                    onClick: () => this.goToDeclaration(declaration.idExploitation, declaration.idDeclaration),
                },
                nullValue: {
                    value: <Icon className='clickable'{...sieauTooltip(i18n.export, null, 'bottom')}>file_download</Icon>,
                    onClick: () => this.setState({ openDeclarationExportModal: true, selectedDeclaration: declaration }),
                },

                // export values
                validatedDec: declaration.lastStep === 5 && declaration.statusCode === 3 ? i18n.yes : i18n.no,
                cityLabel: declaration.cityCode ? `${declaration.city}` : '',
                samplerName: declaration.name,
                phoneTel: formatPhone(declaration.phoneTel),
                mobile: formatPhone(declaration.mobile),
                status: declaration.statusCode,
            }
        })
    }

    onValidate = () => {
        const { survey } = this.props
        const { tmpDeclarations } = this.state
        this.props.onChange({
            survey: {
                ...survey,
                link_declarations: tmpDeclarations.map((d) => ({
                    statusCode: 0,
                    idDeclaration: -1,
                    idContributor: d.operatorCode,
                    idSurvey: survey.idSurvey,
                    comment: null,
                    ...d,
                    updateDate: d.updateDate,
                    nullValue: undefined,
                })),
            },
        })

        this.setState({ open: false })
    }

    onCheck = (exploitation, value, declaration) => {
        const { survey } = this.props
        const { tmpDeclarations } = this.state

        if (declaration && declaration.statusCode) {
            this.props.warning(i18n.deleteExploitationButDeclarationHasBeenStarted)
        }
        const newTmpDeclarations = value ?
            [ ...tmpDeclarations, new DtoDeclaration({
                ...exploitation,
                statusCode: 0,
                idDeclaration: -1,
                idContributor: exploitation.operatorCode,
                idSurvey: survey.idSurvey,
                comment: null,
                updateDate: null,
            }) ]
            : tmpDeclarations.filter(ld => ld.idExploitation !== exploitation.idExploitation)
        this.setState({ tmpDeclarations: newTmpDeclarations })
    }

    createAndDownloadEdition = (id, model) => {
        this.props.info(i18n.loadingDocument)
        this.props.getEditionDeclaration(id, model).then((json) => downloadURI(json.targetPath))
    }

    createSynthesisEdition = (id, model) => {
        this.props.info(i18n.loadingDocument)
        this.props.getSynthesisDeclaration(id, model).then((json) => downloadURI(json.targetPath))
    }

    getNotification = (idExploitation, year, model) => {
        this.props.info(i18n.loadingDocument)
        this.props.getNotifEdition(idExploitation, year, model).then((json) => downloadURI(json.targetPath))
    }

    selfAssign = (id) => {
        const { survey } = this.props
        this.props.changeExploitationAdministrator(id, true).then(() => {
            this.props.actualiseSelfAssign(survey.idSurvey, survey.link_declarations)
        })
    }

    desaffect = (id) => {
        const { survey } = this.props
        this.props.deleteExploitationAdministrator(id).then(() => {
            this.props.actualiseSelfAssign(survey.idSurvey, survey.link_declarations)
        })
    }

    goToDeclaration = (idExploitation, idDeclaration) => {
        this.props.changeExploitationAdministrator(idExploitation).then(() => {
            this.props.push(`/survey/${this.props.params.id}/declaration/${idDeclaration}/stepper/0`)
        })
    }

    getFilteredData = (data, headers, filterValue) => {
        const includesValue = searchAllCharacters(filterValue || '')
        return data.filter(i => this.getHashMateriel(i).includes(lowerCase(filterValue)) || this.getHash(i, headers).includes(includesValue))
    }

    getHashMateriel = (declaration) => {
        return lowerCase((declaration.link_points || []).reduce((acc, p) => `${acc ? `${acc}, ` : ''}${p.code ? `${p.code}, ` : ''}${p.materiels || ''}`, ''))
    }

    getHash = (declaration, headers) => {
        return searchAllCharacters([...headers, 'email', 'mobile', 'phoneTel', 'legalRepSampler', 'address', 'name'].map(key => declaration[key] && declaration[key].value ? declaration[key].value : declaration[key]))
    }

    getFilteredDeclarations = data => {
        const { filters: { step0, step1, step2, step3, step4, validated, validatedByAdmin,
            myFolders, city, managementUnit, subManagementUnit, watershed, adjourned, shut, procedureType } } = this.state
        const closedFilteredData = shut ? data : data.filter((d) => !d.exploitation.folderClosed)
        const adjournedFilteredData = adjourned ? closedFilteredData : closedFilteredData.filter((d) => !d.adjourned)
        const cityFilteredData = city ? adjournedFilteredData.filter((d) => city === d.cityCode || (d.link_points || []).find((p) => p.cityCode === city)) : adjournedFilteredData
        const myFoldersData = myFolders ? cityFilteredData.filter((d) => d?.administrator?.data?.split(', ')?.includes(getLogin())) : cityFilteredData
        const ugeFilteredData = managementUnit ? myFoldersData.filter(({ link_points }) => link_points && link_points.find((l) => l.managementCode === parseInt(managementUnit))) : myFoldersData
        const subUgeFilteredData = subManagementUnit ? ugeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.subManagementCode === parseInt(subManagementUnit))) : ugeFilteredData
        const watershedFilteredData = watershed ? subUgeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.bvCode === parseInt(watershed))) : subUgeFilteredData
        const procedureFilteredData = hasValue(procedureType) ? watershedFilteredData.filter((d) => procedureType === d.exploitation.procedureType) : watershedFilteredData
        if ((step0 && step1 && step2 && step3 && step4 && validated && validatedByAdmin) || (!step0 && !step1 && !step2 && !step3 && !step4 && !validated && !validatedByAdmin)) {
            return procedureFilteredData
        }
        return procedureFilteredData.filter(d => (step0 && !d.lastStep) || (step1 && d.lastStep === 1) ||
            (step2 && d.lastStep === 2) ||(step3 && d.lastStep === 3) ||
            (step4 && d.lastStep >= 4) || (validated && d.statusCode === 3) ||
            (validatedByAdmin && d.statusCode === 4),
        )
    }

    getFilteredExploitations = data => {
        const { modalFilters: { city, managementUnit, subManagementUnit, watershed } } = this.state
        const cityFilteredData = city ? data.filter((e) => (city === e.inseeCode) || e.link_points.find((p) => p.cityCode === city)) : data
        const ugeFilteredData = managementUnit ? cityFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.managementCode === parseInt(managementUnit))) : cityFilteredData
        const subUgeFilteredData = subManagementUnit ? ugeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.subManagementCode === parseInt(subManagementUnit))) : ugeFilteredData
        return watershed ? subUgeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.bvCode === parseInt(watershed))) : subUgeFilteredData
    }

    onOpen = () => {
        const { survey } = this.props
        this.setState({
            open: true,
            tmpDeclarations: survey.link_declarations,
        })
    }

    isAllChecked = (tableData) => {
        const { tmpDeclarations } = this.state
        const selectedIdsArray = tmpDeclarations.map(si => si.idExploitation)
        return !tableData.filter(td => !selectedIdsArray.includes(td.idExploitation)).length
    }

    onSelectAll = (value, tableData) => {
        const { tmpDeclarations } = this.state
        const selectedIdsArray = tmpDeclarations.map(si => si.idExploitation)
        const allFinalizedDec = tableData.filter(td => !selectedIdsArray.includes(td.idExploitation)).map((d) => ({ ...d, updateDate: null }))
        if (value) {
            this.setState({
                tmpDeclarations: [
                    ...tmpDeclarations,
                    ...allFinalizedDec,
                ],
            })
        } else {
            this.setState({
                tmpDeclarations: tmpDeclarations.filter(si => !tableData.map(td => td.idExploitation).includes(si.idExploitation)),
            })
        }
    }

    getDialogContent = (tmpDeclarations) => {
        const { exploitationsExportFullData, cities, managementUnits, watersheds, survey } = this.props
        const { modalFilters } = this.state
        const filterExploitation = exploitationsExportFullData.filter(e => !((e.endDate && e.endDate < survey.startDate) || e.status === '3') || tmpDeclarations.find(se => se.idExploitation === e.idExploitation))
        const displayExploitations = filterExploitation.map(e => {
            const declaration = tmpDeclarations.find(se => se.idExploitation === e.idExploitation)
            return {
                ...e,
                sampler: e.name,
                city: e.inseeCode ? `${e.city || ''} [${e.inseeCode}]` : '',
                structureType: e.structureType,
                checkBox: (
                    <Checkbox
                        checked={!!declaration}
                        onChange={value => this.onCheck(e, value, declaration)}
                        disabled={declaration && declaration.statusCode >= 4}
                    />
                ),
            }
        })

        const headers = ['codification', 'city', 'sampler', 'structureType']
        const tableData = this.getFilteredData(this.getFilteredExploitations(displayExploitations), headers, modalFilters.exploitationFilter)

        return (
            <Grid2 size={12} container spacing={1}>
                <Grid2 size={4}>
                    <Input
                        title={i18n.search}
                        value={modalFilters.exploitationFilter}
                        onChange={v => this.setState({ modalFilters: { ...modalFilters, exploitationFilter: v } })}
                    />
                </Grid2>
                <Grid2 size={2}>
                    <Select
                        label={i18n.city}
                        options={cities}
                        value={modalFilters.city}
                        keyLabel='labelWithCode'
                        onChange={(v) => this.setState({ modalFilters: { ...modalFilters, city: v } })}
                        clearFunction
                    />
                </Grid2>
                <Grid2 size={2}>
                    <Select
                        label={i18n.managementUnit}
                        options={sortBy(managementUnits.filter((u) => !u.parent), 'id')}
                        value={modalFilters.managementUnit}
                        keyValue='managementCode'
                        keyLabel='labelWithCode'
                        onChange={(v) => this.setState({ modalFilters: { ...modalFilters, managementUnit: v, subManagementUnit: null } })}
                        clearFunction
                    />
                </Grid2>
                <Grid2 size={2}>
                    <Select
                        label={i18n.subManagementUnit}
                        options={ modalFilters.managementUnit ? sortBy(managementUnits.filter((u) => u.parent === parseInt(modalFilters.managementUnit)), 'id') : []}
                        value={modalFilters.subManagementUnit}
                        keyValue='managementCode'
                        keyLabel='labelWithCode'
                        onChange={(v) => this.setState({ modalFilters: { ...modalFilters, subManagementUnit: v } })}
                        clearFunction
                    />
                </Grid2>
                <Grid2 size={2}>
                    <Select
                        label={i18n.watershed}
                        options={sortBy(watersheds, [e => e?.name?.toLowerCase()])}
                        value={modalFilters.watershed}
                        keyValue='id'
                        keyLabel='labelWithCode'
                        onChange={(v) => this.setState({ modalFilters: { ...modalFilters, watershed: v } })}
                        integerValue
                        clearFunction
                    />
                </Grid2>
                <Grid2 size={12}>
                    <CardTable
                        title={i18n.folders}
                        rows={tableData}
                        headers={[
                            ...headers,
                            { key: 'checkBox', value: <Checkbox checked={this.isAllChecked(tableData)} onChange={value => this.onSelectAll(value, tableData)}/> },
                        ]}
                        rowsPerPageOptions={nbPerPageLabelMedium}
                        color={darkBlue}
                    />
                </Grid2>
            </Grid2>
        )
    }

    getAdvancementIcon = (lastStep, statusCode) => {
        if (lastStep === 5 && statusCode === 3) {
            return (<Icon sx={{ fontSize: '1.7rem' }} {...sieauTooltip(i18n.validated, null, 'bottom')}>check_circle_outline</Icon>)
        } else if (lastStep === 5 && statusCode === 4) {
            return (<Icon sx={{ fontSize: '1.7rem' }} {...sieauTooltip(i18n.validatedByAdmin, null, 'bottom')}>check_circle</Icon>)
        } else if (lastStep > 0 && lastStep < 5) {
            return (<Icon sx={{ fontSize: '1.7rem' }} {...sieauTooltip(i18n.inProgress, null, 'bottom')}>play_circle_outline</Icon>)
        }
        return (<Icon sx={{ fontSize: '1.7rem' }} {...sieauTooltip(i18n.notStarted, null, 'bottom')}>hourglass_full</Icon>)
    }

    exportData = (data, type, headers, title) => {
        const dataWithHeader = data.length > 0 ? [{ ...data[0], headers }, ...data.slice(1)] : []
        const dataFormatted = formatData(dataWithHeader)
        this.props.export(dataFormatted, type, title)
    }

    getFilteredTableExport = (tableData, data) => {
        return compact(tableData.map((s) => {
            const formattedData = data.find((d) => d.idDeclaration === s.idDeclaration)
            if (formattedData) {
                return {
                    ...formattedData,
                    modif: s?.modif,
                    nbPtReal: s?.nbPtReal,
                    realVolume: s?.realVolume,
                    nbPtPrevi: s?.nbPtPrevi,
                    prevVolume: s?.prevVolume,
                    adjustedEstim: s?.adjustedEstim,
                    administrators: s?.administrator?.data,
                }
            }
            return null
        }))
    }

    getSettingsContentsOasis = () => {
        const { contributors } = this.props
        const { settings } = this.state
        const { senderCode, receiverCode } = settings
        return (
            <Grid2 container direction='column' alignItems='stretch'>
                <Select
                    label={i18n.sender}
                    options={contributors.filter(c => c.siret)}
                    onChange={c => this.setState({
                        settings: {
                            ...settings,
                            senderCode: c,
                        },
                    })}
                    value={senderCode}
                    nullLabel='&nbsp;'
                    clearFunction
                    keyLabel='mnemonique'
                />
                <Select
                    label={i18n.receiver}
                    options={contributors.filter(c => c.siret)}
                    onChange={c => this.setState({
                        settings: {
                            ...settings,
                            receiverCode: c,
                        },
                    })}
                    value={receiverCode}
                    nullLabel='&nbsp;'
                    clearFunction
                    keyLabel='mnemonique'
                />
            </Grid2>
        )
    }

    getDataExportAgency = () => {
        const { survey, exploitationsExportFullData } = this.props
        return exploitationsExportFullData.filter((e) => survey.link_declarations.find((d) => d.idExploitation === e.idExploitation)).map((e) => {
            const advancement = (e.lastDeclarationStep * 100) / 4
            return {
                ...e,
                city: `${e.city || ''} ${e.inseeCode ? `[${e.inseeCode}]` : ''}`,
                advancement: `${(e.lastDeclarationStep && (advancement > 100 ? 100 : advancement) || 0)} %`,
            }
        })
    }

    onExportMultiple = (exportType, idsDeclarations, modelName) => {
        this.props.runExportTmp({
            id: 0,
            exportType,
            login: getLogin(),
            creationDate: 0,
            status: EXPORT_JOB_STATUS.WAITING,
            stationType: AGRI,
            params: JSON.stringify({
                idsDeclarations,
                modelName,
            }),
        }, i18n.loadingDocumentConsultExportMenu)
    }

    exportAgencySurvey = (type) => {
        const { survey } = this.props
        this.props.exportAgencySurvey(`${i18n.followUp} - ${i18n.waterAgencyExport}_${getDateExport()}`, survey.idSurvey, type).then((isOk) => {
            if (isOk) {
                this.props.createSurveysEvents([{
                    idSurvey: survey.idSurvey,
                    eventType: 'e',
                    associateType: 'agency',
                    eventDate: Date.now(),
                    updateLogin: getLogin(),
                }])
            }
        })
    }

    getExportSurveyModal = (declarationsExportData) => {
        const { exportFullSurvey, survey, allModelsByType } = this.props
        const { openTableExportModal, modelDeclaration, modelSynthese, modelNotification } = this.state
        const modelsFormatted = allModelsByType.map((m) => ({
            ...m,
            nameFormatted: m.name.split('.')[0],
        }))
        const data = [{
            name: i18n.syntheticExport,
            group: 1,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => this.exportData(declarationsExportData, 'xlsx', exportHeaders, `${i18n.followUp} - ${i18n.syntheticExport}`),
            }, {
                type: i18n.csv,
                callback: () => this.exportData(declarationsExportData, 'csv', exportHeaders, `${i18n.followUp} - ${i18n.syntheticExport}`),
            }],
        }, {
            name: i18n.exportFull,
            group: 1,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => this.exportData(this.getFilteredTableExport(declarationsExportData, exportFullSurvey), 'xlsx', exportHeadersFull, `${i18n.followUp} - ${i18n.exportFull}`),
            }, {
                type: i18n.csv,
                callback: () => this.exportData(this.getFilteredTableExport(declarationsExportData, exportFullSurvey), 'csv', exportHeadersFull, `${i18n.followUp} - ${i18n.exportFull}`),
            }],
        }, {
            name: i18n.exportMassEmptyPDF,
            group: 2,
            formats: [{
                type: 'ZIP',
                callback: () => this.onExportMultiple('exportEmpty', declarationsExportData.map((d) => d.idDeclaration), modelDeclaration),
            }],
            settingsContent: (
                <Select
                    col={6}
                    label={i18n.model}
                    options={modelsFormatted.filter((m) => m.stationType === 'agriDeclaration')}
                    value={modelDeclaration}
                    onChange={(v) => this.setState({ modelDeclaration: v })}
                    keyLabel='nameFormatted'
                    keyValue='name'
                    noNullValue
                    obligatory
                />
            ),
        }, {
            name: i18n.exportMassSynthesisPDF,
            group: 2,
            formats: [{
                type: 'ZIP',
                callback: () => this.onExportMultiple('synthesis', declarationsExportData.map((d) => d.idDeclaration), modelSynthese),
            }],
            settingsContent: (
                <Select
                    col={6}
                    label={i18n.model}
                    options={modelsFormatted.filter((m) => m.stationType === 'agriSynthese')}
                    value={modelSynthese}
                    onChange={(v) => this.setState({ modelSynthese: v })}
                    keyLabel='nameFormatted'
                    keyValue='name'
                    noNullValue
                    obligatory
                />
            ),
        }, {
            name: i18n.exportMassNotificationPDF,
            group: 2,
            formats: [{
                type: 'ZIP',
                callback: () => this.onExportMultiple('notification', declarationsExportData.map((d) => d.idDeclaration), modelNotification),
            }],
            settingsContent: (
                <Select
                    col={6}
                    label={i18n.model}
                    options={modelsFormatted.filter((m) => m.stationType === 'agriNotification')}
                    value={modelNotification}
                    onChange={(v) => this.setState({ modelNotification: v })}
                    keyLabel='nameFormatted'
                    keyValue='name'
                    noNullValue
                    obligatory
                />
            ),
        }, {
            name: i18n.waterAgencyExport,
            group: 3,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => this.exportAgencySurvey('xlsx'),
            }, {
                type: i18n.csv,
                callback: () => this.exportAgencySurvey('csv'),
            }],
        }, {
            settingsContent: this.getSettingsContentsOasis(),
            name: i18n.oasisDDTExport,
            group: 3,
            formats: [{
                type: i18n.xml,
                callback: () => this.exportOasisDeclarations(survey, declarationsExportData),
            }],
        }, {
            name: `${i18n.exportName} ${i18n.waterAgency} (${i18n.contributors})`,
            group: 3,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => this.exportData(this.getDataExportAgency(), 'xlsx', headersExportAgency, `${i18n.contributors}_${i18n.agencyExport}`),
            }, {
                type: i18n.csv,
                callback: () => this.exportData(this.getDataExportAgency(), 'csv', headersExportAgency, `${i18n.contributors}_${i18n.agencyExport}`),
            }],
        }, {
            name: `${i18n.exportName} ${i18n.survey} (${i18n.contacts})`,
            group: 4,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => this.exportContactsSurvey(survey, 'xlsx', declarationsExportData),
            }, {
                type: i18n.csv,
                callback: () => this.exportContactsSurvey(survey, 'csv', declarationsExportData),
            }],
        }, {
            name: i18n.exportFolderWithoutUses,
            group: 4,
            formats: [{
                type: i18n.exportXLSX,
                callback: () => this.props.exportSurveyNoUses(`${survey.year}_${survey.name} - ${i18n.exportFolderWithoutUses}`, survey.idSurvey, 'xlsx'),
            }, {
                type: i18n.csv,
                callback: () => this.props.exportSurveyNoUses(`${survey.year}_${survey.name} - ${i18n.exportFolderWithoutUses}`, survey.idSurvey, 'csv'),
            }],
        }]

        return openTableExportModal && (
            <ExportFileModal
                open={openTableExportModal}
                onClose={() => this.setState({ openTableExportModal: false })}
                data={data}
                groups={[{
                    value: 1,
                    name: i18n.table,
                }, {
                    value: 2,
                    name: i18n.mass,
                }, {
                    value: 3,
                    name: i18n.agency,
                }, {
                    value: 4,
                    name: i18n.other,
                }]}
            />
        )
    }

    exportContactsSurvey = (survey, fileType, data) => {
        const idsExploitations = this.getFilteredTableExport(data, survey.link_declarations).map(({ idExploitation }) => idExploitation)
        if (idsExploitations.length) {
            this.props.exportContactsSurvey(`${i18n.exportName}_${i18n.contacts}_${i18n.survey}_${survey.name}[${survey.year}]_${getDateExport()}`, survey.idSurvey, fileType, idsExploitations)
        } else {
            this.props.warning(i18n.noDataToExport)
        }
    }

    exportOasisDeclarations = (survey, declarationsExportData) => {
        const { contributors } = this.props
        const { settings } = this.state
        const { senderCode, receiverCode } = settings

        const senderSiret = (contributors.find(c => c.code === senderCode) || {}).siret
        const receiverSiret = (contributors.find(c => c.code === receiverCode) || {}).siret

        if (!senderSiret || !receiverSiret) {
            this.props.warning(i18n.pleaseCompleteAllField)
        } else {
            this.props.exportOasisDeclarations(survey.idSurvey, this.getFilteredTableExport(declarationsExportData, survey.link_declarations).map(({ idExploitation }) => idExploitation), senderSiret, receiverSiret).then((isOk) => {
                if (isOk) {
                    this.props.createSurveysEvents([{
                        idSurvey: survey.idSurvey,
                        eventType: 'e',
                        associateType: 'ddt',
                        eventDate: Date.now(),
                        updateLogin: getLogin(),
                    }])
                }
            })
        }
    }

    exportOasis = (selectedDeclaration) => {
        const { contributors } = this.props
        const { settings } = this.state
        const { senderCode, receiverCode } = settings

        const senderSiret = (contributors.find(c => c.code === senderCode) || {}).siret
        const receiverSiret = (contributors.find(c => c.code === receiverCode) || {}).siret

        if (!senderSiret || !receiverSiret) {
            this.props.warning(i18n.pleaseCompleteAllField)
        } else {
            this.props.exportOasis(selectedDeclaration, senderSiret, receiverSiret)
        }
    }

    getExportDeclarationModal = () => {
        const { openDeclarationExportModal, selectedDeclaration, modelDeclaration, modelSynthese, modelNotification } = this.state
        const { survey, allModelsByType } = this.props
        const modelsFormatted = allModelsByType.map((m) => ({
            ...m,
            nameFormatted: m.name.split('.')[0],
        }))
        const url = (window.location.href || document.URL).split('#')[0]
        // JSON exports are used to debug
        const displayDev = url.includes('dev.sieau') || url.includes('localhost') || url.includes('recette.manager.medeau')
        const jsons = displayDev ? [
            {
                name: 'Export Vide JSON',
                formats: [{
                    type: 'json',
                    callback: () => this.props.exportEdition(selectedDeclaration.idDeclaration),
                }],
            }, {
                name: 'Synthèse JSON',
                formats: [{
                    type: 'json',
                    callback: () => this.props.exportEditionFull(selectedDeclaration.idDeclaration),
                }],
            },
        ] : []
        const exportNotif = survey.surveyType === SURVEY_TYPES.ANNUAL ? {
            name: i18n.notification,
            formats: [{
                type: 'pdf',
                callback: () => this.getNotification(selectedDeclaration.idExploitation, survey.year, modelNotification),
            }],
            settingsContent: (
                <Select
                    col={6}
                    label={i18n.model}
                    options={modelsFormatted.filter((m) => m.stationType === 'agriNotification')}
                    value={modelNotification}
                    onChange={(v) => this.setState({ modelNotification: v })}
                    keyLabel='nameFormatted'
                    keyValue='name'
                    noNullValue
                    obligatory
                />
            ),
        } : null
        const data = compact([{
            name: i18n.exportEmpty,
            formats: [{
                type: 'pdf',
                callback: () => this.createAndDownloadEdition(selectedDeclaration.idDeclaration, modelDeclaration),
            }],
            settingsContent: (
                <Select
                    col={6}
                    label={i18n.model}
                    options={modelsFormatted.filter((m) => m.stationType === 'agriDeclaration')}
                    value={modelDeclaration}
                    onChange={(v) => this.setState({ modelDeclaration: v })}
                    keyLabel='nameFormatted'
                    keyValue='name'
                    noNullValue
                    obligatory
                />
            ),
        }, {
            name: i18n.synthesis,
            formats: [{
                type: 'pdf',
                callback: () => this.createSynthesisEdition(selectedDeclaration.idDeclaration, modelSynthese),
            }],
            settingsContent: (
                <Select
                    col={6}
                    label={i18n.model}
                    options={modelsFormatted.filter((m) => m.stationType === 'agriSynthese')}
                    value={modelSynthese}
                    onChange={(v) => this.setState({ modelSynthese: v })}
                    keyLabel='nameFormatted'
                    keyValue='name'
                    noNullValue
                    obligatory
                />
            ),
        }, {
            settingsContent: this.getSettingsContentsOasis(),
            name: i18n.oasisDDTExport,
            formats: [{
                type: i18n.xml,
                callback: () => this.exportOasis(selectedDeclaration),
            }],
        }, exportNotif, ...jsons])
        return openDeclarationExportModal && (
            <ExportFileModal
                open={openDeclarationExportModal}
                onClose={() => this.setState({ openDeclarationExportModal: false })}
                data={data}
            />
        )
    }

    onValidateFilter = (filters) => {
        setLocalStorageJson(LOCAL_DECLARATIONS_FILTERS, filters)
        this.setState({ filters })
    }

    getSelectExploitModal = () => {
        const { tmpDeclarations, open } = this.state
        return open && (
            <Dialog
                fullWidth
                maxWidth='lg'
                open={open}
                onClose={() => this.setState({ isOpen: false })}
            >
                <DefaultDialogTitle
                    title={i18n.selectExploitations}
                    onClose={() => this.setState({ open: false })}
                />
                <DialogContent>
                    {this.getDialogContent(tmpDeclarations)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onValidate} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    render = () => {
        const { readMode } = this.props
        const { filters } = this.state

        const tableData = this.getFilteredData(this.getFilteredDeclarations(this.getSurveyData()), [...headersTable, 'samplerName'], filters.declarationFilter)

        const actions = [
            !readMode && getUser().isAdmin === '1' ? {
                icon: 'compare_arrows',
                color: 'white',
                tooltip: i18n.select,
                onClick: () => this.onOpen(),
            } : {},
            {
                icon: 'file_download',
                color: 'white',
                tooltip: i18n.export,
                onClick: () => this.setState({ openTableExportModal: true }),
            },
        ]

        return (
            <Grid2 container className='padding-top-1' direction='column'>
                <DeclarationsFilterPanel onValidate={(tmpFilter) => this.onValidateFilter(tmpFilter)} />
                <CardTable
                    title={i18n.declarations}
                    actions={actions}
                    rows={tableData}
                    color={darkBlue}
                    headers={[
                        'statusIcon',
                        'codification',
                        'sampler',
                        { key: 'structureType', value: i18n.structure },
                        { key: 'city', value: i18n.city, width: '50px' },
                        { key: 'advancement', value: '%', align: ALIGN.RIGHT },
                        { key: 'updateDate', value: i18n.update },
                        { key: 'modifIcon', value: i18n.modif },
                        { key: 'nbPtReal', value: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbPtRealWrap}</span>) },
                        { key: 'realVolume', value: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.realVolumeWrap}</span>) },
                        { key: 'nbPtPrevi', value: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.nbPtPreviWrap}</span>) },
                        { key: 'prevVolume', value: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.prevVolumeWrap}</span>) },
                        { key: 'adjustedEstimWrap', value: (<span style={{ whiteSpace: 'pre-wrap' }}>{i18n.adjustedEstimWrap}</span>) },
                        { key: 'administrator', value: i18n.administrator, width: '50px' },
                        { key: 'nullValue2', value: '', displayed: readMode },
                        { key: 'nullValue3', value: '', displayed: readMode },
                        { key: 'nullValue', value: '' },
                    ]}
                    rowsPerPageOptions={nbPerPageLabelHuge}
                />
                {this.getSelectExploitModal()}
                {this.getExportSurveyModal(tableData)}
                {this.getExportDeclarationModal()}
            </Grid2>
        )
    }
}

SurveyDeclarationsPanel.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string,
    }),
    survey: PropTypes.instanceOf(DtoSurvey),
    readMode: PropTypes.bool,
    getEditionDeclaration: PropTypes.func,
    getSynthesisDeclaration: PropTypes.func,
    actualiseSelfAssign: PropTypes.func,
    changeExploitationAdministrator: PropTypes.func,
    deleteExploitationAdministrator: PropTypes.func,
    onChange: PropTypes.func,
    push: PropTypes.func,
    export: PropTypes.func,
    users: PropTypes.arrayOf(DtoUser),
    sandreCodes: PropTypes.arrayOf(DtoSandreCode),
    contributors: PropTypes.arrayOf(ContributorDto),
    citiesIndex: PropTypes.shape({}),
    cities: PropTypes.arrayOf(PropTypes.instanceOf(CityDto)),
    exportOasis: PropTypes.func,
    exportOasisDeclarations: PropTypes.func,
    exportFullSurvey: PropTypes.arrayOf(DtoDeclarationExportFull),
    exploitationsExportFullData: PropTypes.arrayOf(DtoExploitationExportFull),
    managementUnits: PropTypes.arrayOf(PropTypes.instanceOf(DtoManagementUnit)),
    watersheds: PropTypes.arrayOf(PropTypes.instanceOf(WatershedDto)),
    allModelsByType: PropTypes.arrayOf(PropTypes.shape({})),
    exportEdition: PropTypes.func,
    exportEditionFull: PropTypes.func,
    runExportTmp: PropTypes.func,
    warning: PropTypes.func,
    info: PropTypes.func,
    fetchSurvey: PropTypes.func,
    exportContactsSurvey: PropTypes.func,
    exportSurveyNoUses: PropTypes.func,
    exportAgencySurvey: PropTypes.func,
    createSurveysEvents: PropTypes.func,
    getNotifEdition: PropTypes.func,
}

const mapStateToProps = store => ({
    users: store.UserReducer.users,
    sandreCodes: store.ReferencialReducer.sandreCodes,
    contributors: store.ContributorReducer.contributors,
    citiesIndex: store.CityReducer.citiesIndex,
    cities: store.CityReducer.cities,
    exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
    managementUnits: store.ManagementUnitReducer.managementUnits,
    watersheds: store.WatershedReducer.watersheds,
    survey: store.AgriReducer.survey,
    allModelsByType: store.AdministrationReducer.allModelsByType,
})

const mapDispatchToProps = {
    push,
    fetchSurvey: AgriAction.fetchSurvey,
    getEditionDeclaration: AgriAction.getEditionDeclaration,
    getSynthesisDeclaration: AgriAction.getSynthesisDeclaration,
    exportOasis: AgriAction.exportOasis,
    exportOasisDeclarations: AgriAction.exportOasisDeclarations,
    exportEdition: AgriAction.exportEditionJson,
    exportEditionFull: AgriAction.exportEditionFullJson,
    changeExploitationAdministrator: AgriAction.changeExploitationAdministrator,
    deleteExploitationAdministrator: AgriAction.deleteExploitationAdministrator,
    createSurveysEvents: AgriAction.createSurveysEvents,
    exportContactsSurvey: AgriAction.exportContactsSurvey,
    exportSurveyNoUses: AgriAction.exportSurveyNoUses,
    exportAgencySurvey: AgriAction.exportAgencySurvey,
    getNotifEdition: AgriAction.getNotifEdition,
    export: ExportAction.export,
    runExportTmp: ExportAction.runExportTmp,
    warning: ToastrAction.warning,
    info: ToastrAction.info,
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDeclarationsPanel)
