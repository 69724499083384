import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import i18n from 'simple-react-i18n'
import DtoParcelAndSection from '../../../../../carto/DtoParcelAndSection'
import DtoSandreCode from '../../../../../referencial/dto/DtoSandreCode'
import { arrayOf } from '../../../../../utils/StoreUtils'
import LocalisationMapPanel from '../LocalisationMapPanel'

class LocalisationMapModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            initialInstallation: { ...props.installation },
            tmpMapMarker: { ...props.installation },
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        const { installation } = this.props
        if (!isEqual(prevProps.installation, installation)) {
            this.setState({
                initialInstallation: { ...installation },
                tmpMapMarker: { ...installation },
            })
        }
    }

    onChangeLocation = (newGeoData) => {
        const { tmpMapMarker } = this.state
        const geoData = tmpMapMarker.link_geo && tmpMapMarker.link_geo[0] || {}
        const localisation = tmpMapMarker.localisation || {}
        if (newGeoData) {
            this.setState({
                tmpMapMarker: {
                    ...tmpMapMarker,
                    link_geo: [{
                        ...geoData,
                        idStation: tmpMapMarker.id,
                        parcel: newGeoData.parcel || null,
                        section: newGeoData.section || null,
                    }],
                    localisation: {
                        ...localisation,
                        projection: newGeoData.projection,
                        x: newGeoData.x,
                        y: newGeoData.y,
                    },
                    projection: newGeoData.projection,
                    x: newGeoData.x,
                    y: newGeoData.y,
                },
            })
        }
    }

    onChangeCity = (townCode) => {
        if (townCode) {
            this.setState(({ tmpMapMarker }) => ({
                tmpMapMarker: {
                    ...tmpMapMarker,
                    townCode,
                },
            }))
        }
    }

    render() {
        const { tmpMapMarker } = this.state
        const {
            open,
            readMode,
        } = this.props

        return (
            <Dialog
                onClose={this.props.onClose}
                fullWidth
                maxWidth='md'
                open={open}
            >
                <DialogContent>
                    <LocalisationMapPanel
                        installation={tmpMapMarker}
                        onClose={this.props.onClose}
                        onChangeInstallation={this.props.onChangeInstallation}
                        readMode={readMode}
                        onChangeLocation={this.onChangeLocation}
                        onChangeCity={this.onChangeCity}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        this.setState(({ initialInstallation }) => ({ tmpMapMarker: { ...initialInstallation } }))
                        this.props.onClose()
                    }} variant='outlined'
                    >
                        {i18n.close}
                    </Button>
                    <Button onClick={() => {
                        this.props.onChangeInstallation(tmpMapMarker)
                        this.props.onClose()
                    }} variant='contained' color='primary'
                    >
                        {i18n.apply}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

LocalisationMapModal.propTypes = {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    parcelAndSection: PropTypes.instanceOf(DtoParcelAndSection),
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
    onChangeInstallation: PropTypes.func,
    onClose: PropTypes.func,
    installation: PropTypes.object, // DtoInstallation
    fetchParcelAndSectionByCoordinate: PropTypes.func,
    readMode: PropTypes.bool,
    open: PropTypes.bool,
    sandreCodes: arrayOf(DtoSandreCode),
}

export default LocalisationMapModal
