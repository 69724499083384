import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { getDate } from '../../../utils/DateUtil'
import { Card, CardContent } from '@mui/material'

const StationUpdatePanel = ({
    station,
}) => {
    return (
        <Card>
            <CardContent>
                {(station && station.updateDate && station.updateLogin)
                    && `${i18n.updatedAt} ${getDate(station.updateDate)} ${i18n.by} ${station.updateLogin}`
                    || i18n.noUserSet
                }
            </CardContent>
        </Card>
    )
}

StationUpdatePanel.propTypes = {
    station: PropTypes.object, // DtoStation
}

export default StationUpdatePanel
