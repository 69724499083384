import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Card from '../../../../components/card/Card'
import Select from '../../../../components/forms/Select'
import CityAction from '../../../../referencial/components/city/actions/CityAction'
import CityDto from '../../../../referencial/components/city/dto/CityDto'
import ContactDto from '../../../../referencial/components/contact/dto/ContactDto'
import { arrayOf, getLabel } from '../../../../utils/StoreUtils'
import { formatPhone } from '../../../../utils/StringUtil'
import ContactAction from '../actions/ContactAction'
import { CardTitle } from 'components/card/NewCard'
import { CardContent, Grid2 } from '@mui/material'

const iconStyle = {
    width: '24px',
    height: '24px',
}

class ContactCard extends Component {
    componentDidMount = () => {
        this.props.fetchContacts()
        this.props.fetchCities()
    }

    getLine = (value, icon) => {
        if (value) {
            if (icon) {
                return (
                    <div className='valign-wrapper'>
                        <i className='material-icons margin-right-1' style={iconStyle}>{icon}</i>
                        <h6>{value}</h6>
                    </div>
                )
            }
            return <h6>{value}</h6>
        }
        return null
    }

    getAutoComplete = () => {
        return (
            <Grid2 size={12}>
                <Select
                    value={this.props.contactCode}
                    options={this.props.contacts}
                    keyValue='id'
                    displayWithCode
                    onChange={v => this.props.onChange(v)}
                />
            </Grid2>
        )
    }

    getFullDisplay = (contact) => {
        const postalCode = contact.postalCode ? `${contact.postalCode} ` : ''
        return (
            <Grid2 size={12}>
                {this.getLine(contact.name)}
                {this.getLine(contact.address)}
                {this.getLine(contact.additionalAddress)}
                <h6>{postalCode + getLabel(this.props.cities, contact.cityCode)}</h6>
                {this.getLine(formatPhone(contact.phoneTel), 'phone')}
                {this.getLine(formatPhone(contact.mobile), 'smartphone')}
                {this.getLine(formatPhone(contact.desktopTel), 'print')}
                {this.getLine(contact.email, 'mail_outlined')}
            </Grid2>
        )
    }

    render() {
        const { contacts, readMode, contactCode, hideNull } = this.props
        const contact = contacts.find(c => c.id == contactCode)

        if (!contact) {
            return !hideNull && (
                <Card id={this.props.id}>
                    <CardTitle title={this.props.title} />
                    {!readMode && (
                        <CardContent>
                            {this.getAutoComplete() || null}
                        </CardContent>
                    )}
                </Card>
            )
        }

        return (
            <Card id={this.props.id}>
                <CardTitle title={this.props.title} />
                <CardContent>
                    {readMode && this.getFullDisplay(contact) || this.getAutoComplete()}
                </CardContent>
            </Card>
        )
    }
}

ContactCard.propTypes = {
    id: PropTypes.number,
    contactCode: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    title: PropTypes.string.isRequired,
    smallCard: PropTypes.bool,
    readMode: PropTypes.bool,
    hideNull: PropTypes.bool,
    contacts: arrayOf(ContactDto),
    cities: arrayOf(CityDto),
    fetchContacts: PropTypes.func,
    fetchCities: PropTypes.func,
    onChange: PropTypes.func,
}

const mapStateToProps = store => ({
    contacts: store.ContactReducer.contacts,
    cities: store.CityReducer.cities,
})

const mapDispatchToProps = {
    fetchContacts: ContactAction.fetchContacts,
    fetchCities: CityAction.fetchCities,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactCard)