import MessageCard from 'components/card/MessageCard'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import { isNil, orderBy, uniqBy } from 'lodash'
import { getBeginingOfTheYear, getDate, getEndOfTheYear } from 'utils/DateUtil'
import { exportFile } from 'utils/ExportDataUtil'
import HydrometryAction from 'hydrometry/actions/HydrometryAction'
import HydroChart from 'catchment/components/catchment/dashboard/hydrometry/HydroChart'
import { intParser } from 'utils/customHook/useApplicationSetting'
import { HydroCumulOptionIcon } from 'catchment/components/catchment/dashboard/hydrometry/HydroOption'
import { AUTO } from 'hydrometry/constants/HydroOptionConstant'
import DtoHydrometricStation from 'hydrometry/dto/DtoHydrometricStation'
import PropTypes from 'prop-types'
import useStartPeriod from 'catchment/utils/useStartPeriod'
import useEndPeriod from 'catchment/utils/useEndPeriod'
import { Card, CardContent, Grid2 } from '@mui/material'
import { CardTitle } from 'components/card/NewCard'
import CircularProgressWithLabel from 'components/progress/CircularProgressWithLabel'
import useLocalStorage from 'utils/customHook/useLocalStorage'
import SuperMultiAutocomplete from 'components/forms/SuperMultiAutocomplete'

const CatchmentFollowUpHydro = ({
    hydrometricStationsLinked = [],
}) => {
    const dispatch = useDispatch()

    const {
        catchment,
        hydrometricStations,
        hydrometryDataTypes,
    } = useSelector(store => ({
        catchment: store.CatchmentReducer.catchment,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
    }), shallowEqual)

    const startPeriod = useStartPeriod(intParser)
    const endPeriod = useEndPeriod(intParser)

    const startDate = getBeginingOfTheYear(startPeriod)
    const endDate = getEndOfTheYear(endPeriod)

    const [dataTypes, setDataTypes] = useLocalStorage('CATCHMENT_HYDRO_DATATYPES', [4, 5]) // hauteur, débit
    const [dataMeasures, setDataMeasures] = useState([])
    const [group, setGroup] = useLocalStorage('CATCHMENT_HYDRO_GROUP', AUTO)

    const filteredDataTypes = useMemo(() => hydrometryDataTypes.filter(dt => dt.showData || dataTypes.includes(dt.id)), [dataTypes, hydrometryDataTypes])

    const getDefaultSelectPointId = () => {
        const mainPointFound = catchment.catchmentPoints?.find(({ mainPoint }) => mainPoint)
        const code = mainPointFound?.codeWithoutDesignation
        const idMainPoint = mainPointFound && hydrometricStations.find(q => q.code === code)?.id
        const defaultPoint = idMainPoint ?? hydrometricStationsLinked[0]?.id
        return isNil(defaultPoint) ? [] : [defaultPoint]
    }

    const [stations, setStations] = useState(getDefaultSelectPointId)

    useEffect(() => {
        setStations(getDefaultSelectPointId)
    }, [catchment, hydrometricStations])

    useEffect(() => {
        dispatch(HydrometryAction.fetchHydrometricThresholds())
        dispatch(HydrometryAction.fetchHydrometryDataTypes())
    }, [])

    const {
        isLoaded,
        progress,
    } = useProgressDispatch((cancelRef) => {
        setDataMeasures(prev => prev.filter(dm => dataTypes.includes(dm.dataType)))
        const listInputs = hydrometricStationsLinked.flatMap(h => {
            return dataTypes
                .filter(dt => !dataMeasures.some(dm => dm.stationId === h.id && dm.dataType === dt && dm.groupFunc === (group === 'AUTO' ? 'MAX' : group)))
                .map(dataType => ({
                    groupFunc: group === 'AUTO' ? 'MAX' : group,
                    chartMode: true,
                    startDate,
                    endDate,
                    dataType,
                    stationId: h.id,
                }))
        })

        return listInputs.map(input => dispatch(HydrometryAction.getHydroChronicMeasures(input))
            .then(data => !cancelRef.current && setDataMeasures(prev => uniqBy([data, ...prev], d => `${d.stationId}#${d.dataType}`))))
    }, [group, dataTypes])

    const dataMeasuresFiltered = useMemo(() => {
        const orderedDataMeasures = orderBy(dataMeasures, 'dataType')
        if (stations.length === 0) return orderedDataMeasures
        return orderedDataMeasures.filter(({ stationId }) => stations.includes(stationId))
    }, [dataMeasures, stations])

    const actions = [
        <HydroCumulOptionIcon
            key={0}
            defaultGroupType={group}
            onChangeGroupType={setGroup}
            disabled={!isLoaded}
        />,
        {
            icon: 'file_download',
            tooltip: i18n.export,
            onClick: () => {
                const exportData = dataMeasures.flatMap(dm => {
                    const hydro = hydrometricStations.find(({ id }) => id === dm.stationId)
                    const dt = hydrometryDataTypes.find(h => h.id === dm.dataType)
                    return dm.measures.map(m => ({
                        code: hydro?.code,
                        date: { value: getDate(m.date), format: 'dd/MM/yyyy', cellType: 'date' },
                        value: { value: m.value, format: '0.00000', cellType: 'number' },
                        name: hydro?.name || dm.stationId,
                        dataType: dt?.name ?? i18n.unknown,
                    }))
                })
                exportFile({
                    data: exportData.length ? [
                        {
                            ...exportData[0],
                            headers: ['code', 'name', 'dataType', 'date', 'value'],
                        },
                        ...exportData.slice(1),
                    ] : [],
                    exportType: 'xlsx',
                    titleFile: i18n.dataHydro,
                }, true)
            },
        },
    ]

    return (
        <Card data-cy='follow_up_hydro'>
            <CardTitle title={i18n.hydrometry} actions={actions} />
            <CardContent>
                <Grid2 container alignItems='center' spacing={2}>
                    <Grid2 size={4}>
                        <SuperMultiAutocomplete
                            label={i18n.hydrometricStation}
                            options={hydrometricStationsLinked}
                            values={stations}
                            onChange={setStations}
                            keyValue={'id'}
                            multiple
                            limit={2}
                        />
                    </Grid2>
                    <Grid2 size={4}>
                        <SuperMultiAutocomplete
                            label={i18n.dataTypes}
                            options={filteredDataTypes}
                            onChange={setDataTypes}
                            values={dataTypes}
                            keyValue='id'
                            multiple
                            limit={3}
                        />
                    </Grid2>
                </Grid2>
                <div style={{ position: 'relative' }}>
                    {!isLoaded && (<CircularProgressWithLabel value={progress} />) }
                    {dataMeasuresFiltered.length > 0 && (
                        <HydroChart
                            dataMeasures={dataMeasuresFiltered}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    )}
                    {dataMeasuresFiltered.length === 0 && (<MessageCard>{i18n.noDataToDisplay}</MessageCard>)}
                </div>
            </CardContent>
        </Card>
    )
}

CatchmentFollowUpHydro.propTypes = {
    hydrometricStationsLinked: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrometricStation)).isRequired,
}

export default CatchmentFollowUpHydro