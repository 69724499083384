import PropTypes from 'prop-types'
import React, { useState } from 'react'
import i18n from 'simple-react-i18n'
import Select from 'components/forms/Select'
import { Button, Card, Grid2 } from '@mui/material'
import Input from 'components/forms/Input'
import NumberField from 'components/forms/NumberField'
import useSandreList from 'utils/customHook/useSandreList'

const emptyFilter = {
    searchValue: undefined,
    year: undefined,
    status: undefined,
}

const FormFilterPARs = ({ onChange = () => { } }) => {
    const [tmpFilter, setTmpFilter] = useState(emptyFilter)

    const onReset = () => {
        onChange(emptyFilter)
        setTmpFilter(emptyFilter)
    }

    const statusList = useSandreList('AGRI_PAR.STATUT')

    return (
        <Card className='padding-1'>
            <Grid2 container spacing={1}>
                <Grid2 size={2}>
                    <Input
                        title={ i18n.search }
                        value={ tmpFilter.searchValue }
                        onChange={(v) => setTmpFilter({ ...tmpFilter, searchValue: v })}
                        onEnterKeyPress={() => onChange(tmpFilter)}
                    />
                </Grid2>
                <Grid2 size={2}>
                    <NumberField
                        title={i18n.year}
                        value={tmpFilter.year}
                        onChange={v => setTmpFilter({ ...tmpFilter, year: v })}
                    />
                </Grid2>
                <Grid2 size={2}>
                    <Select
                        label={i18n.status}
                        options={statusList}
                        value={tmpFilter.status}
                        onChange={v => setTmpFilter({ ...tmpFilter, status: v })}
                        integerValue
                        keyValue='code'
                        nullLabel='&nbsp;'
                    />
                </Grid2>
                <Grid2 size={6} container justifyContent='flex-end' alignItems='center'>
                    <Button variant='outlined' onClick={onReset}>
                        {i18n.reinit}
                    </Button>
                    <Button variant='contained' onClick={() => onChange(tmpFilter)}>
                        {i18n.search}
                    </Button>
                </Grid2>
            </Grid2>
        </Card>
    )
}

FormFilterPARs.propTypes = {
    onChange: PropTypes.func,
}

export default FormFilterPARs