/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { shallowEqual, useSelector } from 'react-redux'
import { SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import DtoTankStation from '../dto/DtoTankStation'
import Select from '../../../../components/forms/Select'
import { getVisitSandreSelectChange } from '../../../../utils/VisitUtils'
import { Grid2 } from '@mui/material'
import useSandreList from 'utils/customHook/useSandreList'

const TankEquipmentsPanel = ({
    onChange = () => { },
    onChangeVisit = () => { },
    tank = {},
    readMode = false,
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    const flowList = useSandreList('INSTALLATIONS_MESUREDEBIT')
    const levelList = useSandreList('RESERVOIRS_TYPEDETECTIONNIVEAU')

    const onChangeEquipments = (value) => {
        onChange({
            link_equipments: [
                {
                    idStation: tank.idStation,
                    ...tank.link_equipments[0],
                    ...value,
                },
            ],
        })
    }

    const equipments = tank.link_equipments[0] || {}

    return (
        <>
            <Grid2 size={6}>
                <Select
                    value={equipments.alimentationFlowMeasurement}
                    label={i18n.alimentationFlowMeasurement}
                    options={flowList}
                    onChange={(v) => {
                        onChangeEquipments({ alimentationFlowMeasurement: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT, i18n.alimentationFlowMeasurement, equipments.alimentationFlowMeasurement, v))
                    }}
                    integerValue
                    keyValue='code'
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={equipments.distributionFlowMeasurement}
                    label={i18n.distributionFlowMeasurement}
                    options={flowList}
                    onChange={(v) => {
                        onChangeEquipments({ distributionFlowMeasurement: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.INSTALLATIONS_MESUREDEBIT, i18n.distributionFlowMeasurement, equipments.distributionFlowMeasurement, v))
                    }}
                    integerValue
                    keyValue='code'
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={6}>
                <Select
                    value={equipments.typeDetectionLevel}
                    label={i18n.typeDetectionLevel}
                    options={levelList}
                    onChange={(v) => {
                        onChangeEquipments({ typeDetectionLevel: v })
                        onChangeVisit(getVisitSandreSelectChange(sandreCodes, SANDRE.RESERVOIRS_TYPEDETECTIONNIVEAU, i18n.typeDetectionLevel, equipments.typeDetectionLevel, v))
                    }}
                    integerValue
                    keyValue='code'
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

TankEquipmentsPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    onChangeVisit: PropTypes.func,
}

export default TankEquipmentsPanel
