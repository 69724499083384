/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import DtoTankStation from '../dto/DtoTankStation'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import { Card, CardContent, Grid2 } from '@mui/material'
import Input from 'components/forms/Input'
import Checkbox from 'components/forms/Checkbox'

const headers = ['equipment', 'state', 'comment']

const TankOperatorEquipDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 container size={12}>
                        <Grid2 size={6}>
                            <Input
                                title={i18n.equipment}
                                value={stateElement.equipment}
                                onChange={v => onChangeElement({ equipment: v })}
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={stateElement.fixingState}
                            label={i18n.fixingState}
                            onChange={v => onChangeElement({ fixingState: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={stateElement.sealingState}
                            label={i18n.sealingState}
                            onChange={v => onChangeElement({ sealingState: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={stateElement.ductPassageState}
                            label={i18n.ductPassageState}
                            onChange={v => onChangeElement({ ductPassageState: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={stateElement.implantationState}
                            label={i18n.implantationState}
                            onChange={v => onChangeElement({ implantationState: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={stateElement.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

TankOperatorEquipDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const isDefined = value => value && <span>{value}<br /></span>

const formatText = (equip) => {
    const isFixing = equip.fixingState ? i18n.fixingState : null
    const isSealingState = equip.sealingState ? i18n.sealingState : null
    const isDuctPassageState = equip.ductPassageState ? i18n.ductPassageState : null
    const isImplantation = equip.implantationState ? i18n.implantationState : null


    return (<div>
        {isDefined(isFixing)}
        {isDefined(isSealingState)}
        {isDefined(isDuctPassageState)}
        {isDefined(isImplantation)}
    </div>)
}

const TankOperatorEquipmentPanel = ({
    tank = {},
    readMode = false,
    onChange = () => { },
}) => {
    return (
        <StationDescriptionTable
            station={tank}
            readMode={readMode}
            onChange={onChange}
            keyList='link_operatorEquipments'
            formatFunction={c => ({
                ...c,
                state: formatText(c),
            })}
            titles={{
                title: i18n.equipments,
                edit: i18n.editLithology,
                new: i18n.editEquipment,
            }}
            headers={headers}
            dialogContent={TankOperatorEquipDialogContent}
        />

    )
}

TankOperatorEquipmentPanel.propTypes = {
    tank: PropTypes.instanceOf(DtoTankStation),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default TankOperatorEquipmentPanel