import { reduce } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoUserHabilitation from '../../user/dto/DtoUserHabilitation'
import { PANEL_JOURNAL, PANEL_REQ } from '../constants/SuperAdminConstants'
import SuperAdminReqPanel from './SuperAdminReqPanel'
import SuperAdminJournalPanel from './SuperAdminJournalPanel'
import HomeAction from '../../../../home/actions/HomeAction'
import AppStore from '../../../../store/AppStore'

class SuperAdminPanel extends Component {
    constructor(props) {
        super(props)
        this.state = {
            view: PANEL_REQ,
        }
    }

    componentDidMount() {
        AppStore.dispatch(HomeAction.setTitle([{
            title: ' (̿▀̿‿ ̿▀̿ ̿)    Super Admin    (づ｡◕‿‿◕｡)づ',
            href: 'pgsse/dashboard',
        }]))
    }

    changeState = (view) => {
        this.setState({
            view,
        })
    }

    getListOfHabilitations = () => {
        const result = reduce(
            this.props.userHabilitations,
            (a, b) => {
                (a[b.habilitation.split('_')[0]] || (a[b.habilitation.split('_')[0]] = [])).push(b)
                return a
            },
            {},
        )
        return Object.keys(result).map((o) => {
            return {
                module: o,
                values: result[o],
            }
        })
    }

    getPanel = () => {
        const listOfHabilitations = this.getListOfHabilitations()
        switch (this.state.view) {
            case PANEL_REQ:
            default:
                return <SuperAdminReqPanel listOfHabilitations={listOfHabilitations} />
            case PANEL_JOURNAL:
                return <SuperAdminJournalPanel listOfHabilitations={listOfHabilitations} />
        }
    }

    render() {
        return (
            <div className='row no-margin'>
                <div className='col s12'>
                    <ul className='tabs tabs-fixed-width'>
                        <li className='tab'>
                            <a onClick={() => this.changeState(PANEL_REQ)}>{i18n.request}</a>
                        </li>
                        <li className='tab'>
                            <a onClick={() => this.changeState(PANEL_JOURNAL)}>{i18n.journal}</a>
                        </li>
                    </ul>
                </div>
                <div className='col s12'>{this.getPanel()}</div>
            </div>
        )
    }

    componentDidUpdate() {
        $('ul.tabs').tabs()
    }
}

SuperAdminPanel.propTypes = {
    userHabilitations: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserHabilitation)),
}

const mapStateToProps = (store) => {
    return {
        userHabilitations: store.UserReducer.userHabilitations,
    }
}
export default connect(mapStateToProps)(SuperAdminPanel)

