import { createSlice } from '@reduxjs/toolkit'
import PerimetersThunk from '../action/PerimetersThunk'
import PerimeterDto from 'perimeters/dto/PerimeterDto'
import { createIndex } from '../../utils/StoreUtils'
import CompanyOwnerDto from '../dto/CompanyOwnerDto'
import PrivateOwnerDto from '../dto/PrivateOwnerDto'
import PerimetersFolderDto from 'perimeters/dto/PerimetersFolderDto'
import OwnerGroupDto from 'perimeters/dto/OwnerGroupDto'

export const initialState = {
    perimetersFolder: [],
    perimeters: [],
    mortgages: [],
    perimeterFolderLastEvent: {},
    perimetersFolderPrivateOwners: [],
    perimetersFolderPrivateOwnersIndex: [],
    perimetersFolderCompanyOwners: [],
    perimetersFolderCompanyOwnersIndex: [],
    ownersGroups: [],
}

const store = createSlice({
    name: 'perimeters',
    initialState,
    reducers: {
        receiveOwnersGroups: (state, action) => {
            state.ownersGroups = action.payload.map(p => new OwnerGroupDto(p))
        },
        addOwnersGroups: (state, action) => {
            state.ownersGroups = [...state.ownersGroups.filter(og => og.communalAccount !== action.payload[0]?.communalAccount), ...action.payload]
        },
    },
    extraReducers: {
        [PerimetersThunk.getPerimetersFolders.pending]: (state) => {
            state.perimetersFolder = []
        },
        [PerimetersThunk.getPerimetersFolders.fulfilled]: (state, action) => {
            state.perimetersFolder = action.payload.map(pf => new PerimetersFolderDto(pf))
        },
        [PerimetersThunk.getPerimeters.pending]: (state) => {
            state.perimeters = []
        },
        [PerimetersThunk.getPerimeters.fulfilled]: (state, action) => {
            state.perimeters = action.payload.map(p => new PerimeterDto(p))
        },
        [PerimetersThunk.getMortgages.pending]: (state) => {
            state.mortgages = []
        },
        [PerimetersThunk.getMortgages.fulfilled]: (state, action) => {
            state.mortgages = action.payload
        },
        [PerimetersThunk.getPerimeterFolderLastEvent.fulfilled]: (state, action) => {
            state.perimeterFolderLastEvent = action.payload
        },
        [PerimetersThunk.getPerimetersFolderPrivateOwners.pending]: (state) => {
            state.perimetersFolderPrivateOwners = []
        },
        [PerimetersThunk.getPerimetersFolderPrivateOwners.fulfilled]: (state, action) => {
            const privateOwners = action.payload.map(po => new PrivateOwnerDto(po))
            state.perimetersFolderPrivateOwners = action.payload
            state.perimetersFolderPrivateOwnersIndex = createIndex(privateOwners)
        },
        [PerimetersThunk.getPerimetersFolderCompanyOwners.pending]: (state) => {
            state.companyOwners = []
        },
        [PerimetersThunk.getPerimetersFolderCompanyOwners.fulfilled]: (state, action) => {
            const companyOwners = action.payload.map(co => new CompanyOwnerDto(co))
            state.perimetersFolderCompanyOwners = action.payload
            state.perimetersFolderCompanyOwnersIndex = createIndex(companyOwners)
        },
    },
})

export const PerimeterActionConstant = store.actions
export default store.reducer