import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid2,
} from '@mui/material'
import HomeAction from 'home/actions/HomeAction'
import { chunk, isEqual, max } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import AppStore from 'store/AppStore'
import ToastrAction from 'toastr/actions/ToastrAction'
import ActionComponent from '../../../components/ActionComponent'
import Checkbox from '../../../components/forms/Checkbox'
import Input from '../../../components/forms/Input'
import Select from '../../../components/forms/Select'
import SieauAction from '../../../components/sieau/SieauAction'
import CmsAction from '../../../events/actions/CmsAction'
import CMSCategoryDto from '../../../events/dto/CMSCategoryDto'
import ReferencialAction from '../../../referencial/action/ReferencialAction'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { getDate } from '../../../utils/DateUtil'
import { setConfirmationModal, sieauTooltip } from '../../../utils/FormUtils'
import { getLogin, getUser } from '../../../utils/SettingUtils'
import { arrayOf, getLabel, getMapStateToProps } from '../../../utils/StoreUtils'
import { ICONS_PRESELECTION } from '../../constants/AdministrationConstants'
import UserAction from '../user/actions/UserAction'
import UserDto from '../user/dto/UserDto'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { hasValue } from 'utils/NumberUtil'
import ContributorDto from 'referencial/components/contributor/dto/ContributorDto'
import { DialogActionsMUI, DialogContentMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import { NewTable } from '../../../components/datatable/NewTable'
import { searchAllCharacters } from '../../../utils/StringUtil'

const Filter = ({
    filterParent,
    changeFilterParent,
}) => {
    const [filter, setFilter] = useState(filterParent)
    return (
        <Card>
            <CardContent>
                <Grid2 container justifyContent={'space-between'} alignItems='center'>
                    <Grid2 size={4}>
                        <Input
                            title={i18n.search}
                            value={filter.searchValue}
                            onChange={ v => setFilter(prev => ({ ...prev, searchValue: v })) }
                            onEnterKeyPress={ v => changeFilterParent({ ...filter, searchValue: v }) }
                            data-cy={'search_input'}
                        />
                    </Grid2>
                    <Grid2 size={4} spacing={1} container justifyContent='flex-end'>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                setFilter({})
                                changeFilterParent({})
                            }}
                            data-cy={'reset_button'}
                        >
                            {i18n.reinit}
                        </Button>
                        <Button
                            variant='contained'
                            onClick={() => changeFilterParent(filter)}
                            data-cy={'search_button'}
                        >
                            {i18n.search}
                        </Button>
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}
Filter.propTypes = {
    filterParent: PropTypes.shape({
        searchValue: PropTypes.string,
    }),
    changeFilterParent: PropTypes.func,
}

class AdministrationContentsApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            categories: [],
            isOpen: false,
            category: {},
            isIconModalOpen: false,
            filter: {},
        }
    }

    componentDidMount() {
        if (!this.props.sandreCodes.length) {
            this.props.fetchSandreCodes()
        }
        this.props.fetchCMSCategories()
        this.props.fetchUsers()
        AppStore.dispatch(HomeAction.setHelpLink('administration', '94'))
        if (this.props.cmsCategories.length) {
            this.setCategoriesState(this.props.cmsCategories)
        }
        this.setReadOnlyMode()
        this.props.forceFetch('title', [{
            title: i18n.administration,
            href: 'administration',
        }, {
            title: i18n.contentManagement,
            href: 'administration/contents',
        }])
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cmsCategories.length !== this.props.cmsCategories.length) {
            this.setCategoriesState(this.props.cmsCategories)
        }
    }

    setCategoriesState = (categories) => this.setState({ categories, initialCategories: categories })

    setEditMode = () => {
        this.setState({ disabled: false })
        const actions = {
            cancel: () => {
                this.setState({ categories: this.props.cmsCategories })
                this.setReadOnlyMode()
            },
        }
        if (!(getUser().consultant === '1')) {
            actions.save = () => this.onSaveCategories()
            actions.new = () => this.addNewCategory()
        }
        this.setActions(actions)
    }

    setReadOnlyMode = () => {
        this.setState({ disabled: true })
        if (!(getUser().consultant === '1')) {
            this.setActions({
                edit: () => this.setEditMode(),
            })
        } else {
            this.setActions({})
        }
    }

    addNewCategory = () => {
        const newId = this.state.categories.length ? max(this.state.categories.map(c => c.id)) + 1 : 1
        const newCategory = new CMSCategoryDto({
            id: newId,
            title: i18n.newCategory,
            typeCategory: 0,
            author: getUser().name || getLogin(),
            login: getLogin(),
            startDate: moment().valueOf(),
            displayOnNotificationBar: true,
            icon: 'event_note',
            displayOnMenu: true,
            menu: i18n.communication,
        })
        this.setState({ categories: [ ...this.state.categories, newCategory ] })
    }

    onSaveCategories = () => {
        const { categories } = this.state
        const newCategories = categories.filter(stateCategory => !this.props.cmsCategories.find(c => c.id === stateCategory.id)).map(c => {
            return ({ ...c, isNew: true })
        })
        const changedCategories = categories.filter(stateCategory => {
            const found = this.props.cmsCategories.find(c => c.id === stateCategory.id)
            if (found && !isEqual(stateCategory, found)) {
                return true
            }
            return false
        })
        const updatedCategories = [ ...newCategories, ...changedCategories ]
        if (updatedCategories.length) {
            this.props.updateCMSCategory(updatedCategories, this.props.cmsCategories, this.setReadOnlyMode)
        } else {
            this.props.info(i18n.nothingToChange)
        }
    }

    onDeleteCategory = (deleteCategory) => {
        const category = this.state.categories.find(c => c.id === deleteCategory.id)
        if (!this.state.disabled) {
            if (this.props.cmsCategories.find(c => c.id === category.id)) {
                setConfirmationModal(() => this.props.delete(category))
            } else {
                $('.tooltipped').tooltip('remove')
                this.setState({ categories: this.state.categories.filter(c => c.id !== category.id) })
            }
        }
    }

    onValidateCategory = (toClose) => {
        const { category } = this.state
        const categories = [ ...this.state.categories.filter(c => c.id !== category.id), new CMSCategoryDto(category) ]
        this.setState({ categories, [toClose]: false })
    }

    onChangeCategory = (key, value) => {
        this.setState({ category: { ...this.state.category, [key]: value } })
    }

    getIconModal = () => {
        const { category } = this.state
        const icons = ICONS_PRESELECTION.map(iconName => (
            <div className='col s2'>
                <i
                    className='material-icons medium clickable'
                    onClick={() => {
                        this.onChangeCategory('icon', iconName)
                        $('.modal').modal('close')
                    }}
                >{ iconName }</i>
            </div>
        ))
        const rows = chunk(icons, 6).map(iconGroup => (
            <div className='row no-margin padding-bottom-2'>
                { iconGroup }
            </div>
        ))
        return (
            <Dialog
                onClose={() => this.setState({ isIconModalOpen: false })}
                fullWidth
                maxWidth='md'
                open={this.state.isIconModalOpen}
            >
                <DialogTitle>{`${category.title} - ${i18n.icon}`}</DialogTitle>
                <DialogContent>
                    <div>
                        { rows }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({ isIconModalOpen: false })} variant='outlined' >
                        {i18n.cancel}
                    </Button>
                    <Button onClick={() => this.onValidateCategory('isIconModalOpen')} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    setIconModal = () => {
        if (!this.state.disabled) {
            this.setState({ isIconModalOpen: true })
        }
    }

    getNotif = () => {
        const { category, disabled } = this.state
        const checkbox = <Checkbox checked={ category.displayOnNotificationBar } disabled={ disabled } onChange={ v => this.onChangeCategory('displayOnNotificationBar', v) }/>
        if (category.displayOnNotificationBar) {
            const tooltip = !disabled ? sieauTooltip(i18n.changeIcon, null, 'bottom') : null
            return (
                <div className='row no-margin valign-wrapper'>
                    <div className='col s1'>
                        { checkbox }
                    </div>
                    <div className='col s11'>
                        <i className={ `material-icons ${disabled ? 'grey-text' : ''}` } {...tooltip} onClick={ () => this.setIconModal() }>{ category.icon }</i>
                    </div>
                </div>
            )
        }
        return checkbox
    }

    getMenu = () => {
        const { category, disabled }= this.state
        const readMode = { readMode: disabled, disabled }
        const checkbox = <Checkbox checked={ category.displayOnMenu } disabled={ disabled } onChange={ v => this.onChangeCategory('displayOnMenu', v) }/>
        if (category.displayOnMenu) {
            return (
                <div className='row no-margin valign-wrapper'>
                    <div className='col s1'>
                        { checkbox }
                    </div>
                    <div className='col s11'>
                        <Input title={ i18n.name } value={ category.menu } {...readMode} onChange={ v => this.onChangeCategory('menu', v) }/>
                    </div>
                </div>
            )
        }
        return checkbox
    }

    onEditCategory = (editCategory) => {
        const category = this.state.categories.find(c => c.id === editCategory.id)
        this.setState({ isOpen: true, category })
    }

    getDialogEditModal = () => {
        const { category, isOpen, disabled } = this.state
        const { users, sandreCodes, contributors } = this.props
        const readMode = { readMode: disabled }
        const usersValues = users.map(u => ({ code: u.login, label: u.name || u.login }))
        const typesCategories = sandreCodes.filter(type => type.field === 'CMS.TYPE').map(type => {
            return { value: type.code, label: type.name }
        })
        return (
            <Dialog
                onClose={() => this.setState({ isOpen: false })}
                fullWidth
                maxWidth='md'
                open={isOpen}
            >
                <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {category.title}
                    <Icon style={{ color: 'white' }} size='small' icon='close' onClick={() => this.setState({ isOpen: false })} />
                </DialogTitleMUI>
                <DialogContentMUI>
                    <div className='row no-margin padding-top-1'>
                        <Input
                            col={ 6 }
                            title={i18n.name}
                            value={ category.title }
                            onChange={ v => this.onChangeCategory('title', v) }
                            {...readMode}
                        />
                        <Select
                            col={ 6 }
                            label={i18n.typeCategory}
                            options={ typesCategories }
                            value={ category.typeCategory }
                            onChange={ v => this.onChangeCategory('typeCategory', v) }
                            {...readMode}
                        />
                        <SimpleDatePicker
                            col={ 12 }
                            label={i18n.startDate}
                            value={ category.startDate }
                            id={ `startDate${category.id}` }
                            onChange={ (v) => this.onChangeCategory('startDate', v) }
                            {...readMode}
                        />
                        <SimpleDatePicker
                            col={ 12 }
                            label={i18n.endDate}
                            value={ category.endDate }
                            id={ `endDate${category.id}` }
                            onChange={ (v) => this.onChangeCategory('endDate', v) }
                            {...readMode}
                        />
                        <Select
                            col={ 12 }
                            label={i18n.author}
                            options={ usersValues }
                            value={ category.login }
                            onChange={ v => this.onChangeCategory('login', v) }
                            {...readMode}
                        />
                        <MultiContributorsAutocomplete
                            label={i18n.administrator}
                            disablePortal={false}
                            options={contributors}
                            values={hasValue(category.managers) ? category.managers.split(';').map(s => parseInt(s)) : []}
                            onChange={codes => this.onChangeCategory('managers', codes?.join(';') ? codes.join(';') : undefined)}
                            multiple={true}
                            col={12}
                        />
                    </div>
                    <div className='row no-margin padding-top-1' >{this.getNotif()}</div>
                    <div className='row no-margin padding-top-1' >{this.getMenu()}</div>
                </DialogContentMUI>
                <DialogActionsMUI>
                    <Button onClick={() => this.onValidateCategory('isOpen')} variant='contained' color='primary'>
                        {i18n.validate}
                    </Button>
                </DialogActionsMUI>
            </Dialog>

        )
    }

    render() {
        const {
            categories,
            disabled,
            isOpen,
            isIconModalOpen,
            filter,
        } = this.state
        const {
            sandreCodes,
            contributors,
        } = this.props

        const headers = ['id', 'name', 'typeCategory', 'notifBar', 'menu', 'startDate', 'endDate', 'author', 'administrator']

        if (categories.length) {
            const typesCategories = sandreCodes.filter(type => type.field === 'CMS.TYPE').map(type => {
                return { value: type.code, label: type.name }
            })
            const data = categories.map(category => {
                const admin = category.managers?.split(';').map(m => {
                    const contrib = contributors.find(c => c.id === parseInt(m)) || {}
                    return contrib?.name || ''
                }).join('\n')
                return {
                    id: category.id,
                    name: category.title,
                    typeCategory: getLabel(typesCategories, category.typeCategory, 'label', 'value'),
                    notifBar: category.displayOnNotificationBar ? <i className={ 'material-icons' }>{ category.icon }</i> : '',
                    menu: category.displayOnMenu ? category.menu : '',
                    startDate: getDate(category.startDate),
                    endDate: getDate(category.endDate),
                    author: category.login,
                    administrator: <span style={{ whiteSpace: 'pre-wrap' }}>{admin}</span>,
                }
            })

            const filterSearch = filter.searchValue ? data.filter(model => headers.some((header) => {
                const key = header === 'model' ? 'name' : header
                return searchAllCharacters(model[key])?.includes(searchAllCharacters(filter.searchValue))
            })) : data

            return (
                <Grid2 container spacing={1} sx={{ padding: '10 10 100 10' }}>
                    <Grid2 size={12}>
                        <Filter
                            filterParent={ filter }
                            changeFilterParent={v => this.setState({ filter: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <NewTable
                            rowsPerPageOptions={[{ value: 1000 }]}
                            rows={ filterSearch }
                            headers={ headers }
                            data-cy={'contents_table'}
                            lineActions={[
                                { icon: 'delete', onClick: this.onDeleteCategory, displayed: !disabled },
                                { icon: 'edit', onClick: this.onEditCategory, displayed: !disabled },
                            ]}
                        />
                        { isOpen && this.getDialogEditModal() }
                        { isIconModalOpen && this.getIconModal() }
                    </Grid2>
                </Grid2>
            )
        }
        return (
            <Grid2 padding={1} size={12}>
                <Card>
                    <CardHeader
                        sx={{ py: 5 }}
                        title={i18n.noCategories}
                    />
                </Card>
            </Grid2>
        )
    }
}

AdministrationContentsApp.propTypes = {
    sandreCodes: arrayOf(DtoSandreCode),
    cmsCategories: arrayOf(CMSCategoryDto),
    users: arrayOf(UserDto),
    delete: PropTypes.func,
    contributors: arrayOf(ContributorDto),
}

const mapStateToProps = store => getMapStateToProps({
    sandreCodes: store.ReferencialReducer.sandreCodes,
    cmsCategories: store.EventsReducer.cmsCategories,
    users: store.UserReducer.users,
    contributors: store.ContributorReducer.contributors,
})

const mapDispatchToProps = {
    fetchSandreCodes: ReferencialAction.fetchSandreCodes,
    fetchCMSCategories: CmsAction.fetchCMSCategories,
    fetchUsers: UserAction.fetchUsers,
    forceFetch: SieauAction.forceFetch,
    updateCMSCategory: CmsAction.updateCMSCategory,
    info: ToastrAction.info,
    delete: CmsAction.deleteCMSCategory,
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationContentsApp)
