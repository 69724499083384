/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Input from 'components/forms/Input'
import Textarea from 'components/forms/Textarea'
import StepperDialog from 'components/modal/StepperDialog'
import ExploitationsFilterPanel from 'exploitations/components/panels/ExploitationsFilterPanel'
import { CardTable } from 'components/datatable/NewTable'
import { nbPerPageLabelShort } from 'referencial/constants/ReferencialConstants'
import Checkbox from 'components/forms/Checkbox'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getLogin } from 'utils/SettingUtils'
import { searchAllCharacters } from 'utils/StringUtil'
import { lowerCase, uniq } from 'lodash'
import { emptyFilterExploitations } from 'agriAdministration/constants/AgriConstants'
import ToastrAction from 'toastr/actions/ToastrAction'
import UserAction from 'administration/components/user/actions/UserAction'
import DisplayedValue from 'components/forms/DisplayedValue'
import { darkBlue } from 'utils/constants/ColorTheme'


const headersExport = ['codification', 'structureType', 'organism', 'civility', 'name', 'address', 'additionnalAddress', 'inseeCode', 'postalCode', 'city', 'phoneTel',
    'mobile', 'fax', 'email', 'comment', 'contributors', 'siret', 'pacage', 'octagri_aura', 'octagri_local', 'muse', 'agence', 'domestic', 'agri', 'nonAgri', 'adjourned', 'shut']

const STEP = {
    USERS: 0,
    SUMMARY: 1,
}

const RestrictionsNotifDialog = ({
    filter = {},
    onClose = () => { },
    open = false,
}) => {
    const [filtersExploit, setFilters] = useState({ ...emptyFilterExploitations, ...filter })
    const [exploitationsSelected, setExploitationsSelected] = useState([])
    const [notifTitle, setNotifTitle] = useState()
    const [notifBody, setNotifBody] = useState('')

    const {
        exploitationsExportFullData,
        userBookmarks,
    } = useSelector(store => ({
        exploitationsExportFullData: store.AgriReducer.exploitationsExportFullData,
        userBookmarks: store.UserReducer.userBookmarks,
    }), shallowEqual)
    const dispatch = useDispatch()

    const users = useMemo(() => {
        return uniq(exploitationsExportFullData.filter((e) => exploitationsSelected.includes(e.idExploitation)).flatMap((e) => e.linkUsers.map((u) => u.codification)))
    }, [exploitationsExportFullData, exploitationsSelected])

    const onSendNotif = () => {
        if (notifTitle) {
            dispatch(UserAction.postNotif(
                { title: notifTitle, body: notifBody },
                users,
                'iryqua',
            ))
            onClose()
        } else {
            dispatch(ToastrAction.error(i18n.pleaseCompleteAllRequiredField))
        }
    }

    const getHashMateriel = (exploitation) => {
        return lowerCase(exploitation.link_points.reduce((acc, p) => `${acc ? `${acc}, ` : ''}${p.code ? `${p.code}, ` : ''}${p.materiels || ''}`, ''))
    }

    const getHash = (exploitation) => {
        return searchAllCharacters([...headersExport, 'legalRepSampler', 'legalRepExploit', 'contactsExploit'].map(key => exploitation[key] || ''))
    }

    const getFilteredData = (data) => {
        const { searchValue, structureTypes, city, managementUnit, subManagementUnit, watershed, filters, domestic,
            agri, nonAgri, adjourned, shut, sampleType, sampleNature, accompanyingAquiferCode, droughtSector, organism } = filtersExploit
        const login = getLogin()
        const favoritesFiltered = filters === 1 ? data.filter((e) => userBookmarks.find((b) => b.login === login && b.stationType === 10 && parseInt(b.identifiant) === e.idExploitation)) : data
        const cityFilteredData = city ? favoritesFiltered.filter((e) => (city === e.inseeCode) || e.link_points.find((p) => p.cityCode === city)) : favoritesFiltered
        const ugeFilteredData = managementUnit ? cityFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.managementCode === parseInt(managementUnit))) : cityFilteredData
        const subUgeFilteredData = subManagementUnit ? ugeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.subManagementCode === parseInt(subManagementUnit))) : ugeFilteredData
        const watershedFilteredData = watershed ? subUgeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.bvCode === parseInt(watershed))) : subUgeFilteredData
        const sampleTypeFilteredData = sampleType ? watershedFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.sampleType === parseInt(sampleType))) : watershedFilteredData
        const sampleNatureFilteredData = sampleNature ? sampleTypeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.sampleNature === parseInt(sampleNature))) : sampleTypeFilteredData
        const accompanyingAquiferCodeFilteredData = accompanyingAquiferCode ? sampleNatureFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.accompanyingAquiferCode === parseInt(accompanyingAquiferCode))) : sampleNatureFilteredData
        const droughtSectorFilteredData = droughtSector ? accompanyingAquiferCodeFilteredData.filter(({ link_points }) => link_points && link_points.find((l) => l.droughtSector === parseInt(droughtSector))) : accompanyingAquiferCodeFilteredData
        const structureFilteredData = structureTypes.length ? droughtSectorFilteredData.filter(({ structureCode }) => structureTypes.includes(structureCode)) : droughtSectorFilteredData
        const organismFilteredData = organism ? structureFilteredData.filter((e) => e.organism === organism) : structureFilteredData
        const checkBoxFilteredData = (() => {
            if ((domestic && agri && nonAgri && adjourned && shut) || (!domestic && !agri && !nonAgri && !adjourned && !shut)) {
                return organismFilteredData
            }
            return organismFilteredData.filter(d => (domestic && d.domestic.sortValue) || (agri && d.agri.sortValue) ||
            (nonAgri && d.nonAgri.sortValue) ||(adjourned && d.adjourned.sortValue) ||
            (shut && d.shut.sortValue),
            )
        })()
        const includesValue = searchAllCharacters(searchValue || '')
        return checkBoxFilteredData.filter(e => getHashMateriel(e).includes(lowerCase(searchValue)) || getHash(e).includes(includesValue))
    }

    const tableData = useMemo(() => {
        return getFilteredData(exploitationsExportFullData).map(e => {
            const isSelected = exploitationsSelected.some(id => id === e.idExploitation)
            return {
                ...e,
                isSelected,
                sampler: e.name,
                city: e.inseeCode ? `${e.city || ''} [${e.inseeCode}]` : '',
                structureType: e.structureType,
                checkBox: (
                    <Checkbox
                        checked={isSelected}
                        onChange={() => {
                            if (isSelected) {
                                setExploitationsSelected(exploitationsSelected.filter(id => id !== e.idExploitation))
                            } else {
                                setExploitationsSelected([ ...exploitationsSelected, e.idExploitation])
                            }
                        }}
                    />
                ),
            }
        })
    }, [exploitationsExportFullData, exploitationsSelected, filtersExploit])

    const isAllSelected = tableData.every(p => p.isSelected)

    const onSelectAll = () => {
        setExploitationsSelected(isAllSelected ? [] : uniq([
            ...(exploitationsSelected || []),
            ...tableData.map((e) => e.idExploitation),
        ]))
    }

    return (
        <StepperDialog
            steps={[{
                label: i18n.users,
                constant: STEP.USERS,
            },
            {
                label: i18n.content,
                constant: STEP.SUMMARY,
            }]}
            open={open}
            title={i18n.notification}
            closeDialog={() => onClose(false)}
            renderSaveButton={(s) => <Button onClick={onSendNotif} disabled={s === STEP.USERS || !notifTitle} variant='contained'>{i18n.send}</Button>}
            contentStyle={{ padding: '12px' }}
        >
            {step => (
                <>
                    {step === STEP.USERS && (
                        <>
                            <ExploitationsFilterPanel
                                className='margin-top-0 margin-bottom-1'
                                initialFilters={filtersExploit}
                                noMargin={false}
                                onValidate={setFilters}
                                useLocalStorage={false}
                            />
                            <CardTable
                                title={i18n.folders}
                                headers={[
                                    'codification',
                                    'city',
                                    'sampler',
                                    'structureType',
                                    { key: 'checkBox', value: <Checkbox checked={isAllSelected} />, onClick: onSelectAll },
                                ]}
                                rows={tableData}
                                rowsPerPageOptions={nbPerPageLabelShort}
                                color={darkBlue}
                            />
                        </>
                    )}
                    {step === STEP.SUMMARY && (
                        <>
                            <Input
                                col={12}
                                title={ i18n.title }
                                value={ notifTitle }
                                onChange={(v) => setNotifTitle(v)}
                                obligatory
                            />
                            <Textarea
                                col={12}
                                value={notifBody}
                                title={i18n.comment}
                                onChange={v => setNotifBody(v)}
                                height='200px'
                            />
                            <DisplayedValue label={i18n.potentialNumberContactedUsers} value={users.length} />
                        </>
                    )}
                </>
            )}
        </StepperDialog>
    )
}

RestrictionsNotifDialog.propTypes = {
    filter: PropTypes.shape({}),
    open: PropTypes.bool,
    onClose: PropTypes.func,
}


export default RestrictionsNotifDialog
