import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { getDate } from 'utils/DateUtil'
import { DATE } from './KeyFigureConstants'
import { getLongNumber, getNumberFormat } from '../../utils/NumberUtil'
import { filter, isUndefined } from 'lodash'
import { Card, Grid2, Tooltip } from '@mui/material'
import { getI18nOrLabel } from 'utils/StringUtil'

const SimpleKeyFigurePanel = ({
    data,
    showAllValues,
}) => {
    const filterValue = useMemo(() => showAllValues ? data : filter(data, o => o.value && o.value !== '0'), [data, showAllValues])

    const getLabelDate = (d) => {
        if (d.typeValue) {
            if (d.typeValue === DATE) {
                return [getDate(d.value), getDate(d.value)]
            }
            return [getLongNumber(d.value), getNumberFormat(d.value)]
        }
        return [d.value, d.value]
    }

    return (
        <Card
            sx={{
                borderRadius: '4px',
                boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.1)',
                padding: '12px',
                backgroundColor: '#53a1ff',
                marginBottom: '12px',
            }}
        >
            {filterValue.map((d, idx) => {
                const value = getLabelDate(d)
                const title = getI18nOrLabel(d.title)
                const tooltip = !isUndefined(d.tooltip) ? getI18nOrLabel(d.tooltip) : ''
                const tooltipContent = `${tooltip || title || ''} : ${value[1]}`
                return (
                    <Grid2 key={idx} container size={12} sx={{ color: 'white' }}>
                        <Grid2 size={12} fontSize={15}>
                            { title }
                        </Grid2>
                        <Grid2 size={12} fontSize={25}>
                            <Tooltip title={tooltipContent}>
                                { value[0] }
                            </Tooltip>
                        </Grid2>
                    </Grid2>
                )
            })}
        </Card>
    )
}
SimpleKeyFigurePanel.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        tooltip: PropTypes.string,
        value: PropTypes.number,
        typeValue: PropTypes.string,
    })),
    showAllValues: PropTypes.bool,
}

export default SimpleKeyFigurePanel
