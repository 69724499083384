import HomeAction from 'home/actions/HomeAction'
import { compact, groupBy, isEmpty, orderBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { v4 as uuidv4 } from 'uuid'
import WaitAction from 'wait/WaitAction'
import ActionComponent from '../../../components/ActionComponent'
import Card from '../../../components/card/Card'
import ProgressCard from '../../../components/card/ProgressCard'
import CartographyPanel from '../../../components/map/CartographyPanel'
import SieauAction from '../../../components/sieau/SieauAction'
import InstallationAction from '../../../installation/actions/InstallationAction'
import NearbyInstallationsPanel from '../../../installation/components/installationDefault/nearbyInstallations/NearbyInstallationsPanel'
import { INSTALLATION_TYPE } from '../../../installation/constants/InstallationConstants'
import DtoInstallationLight from '../../../installation/dto/installation/DtoInstallationLight'
import DtoPiezometerLight from '../../../piezometry/dto/DtoPiezometerLight'
import PluviometerDto from '../../../pluviometry/dto/PluviometerDto'
import WatermassDto from '../../../referencial/components/watermass/dto/WatermassDto'
import WatershedDto from '../../../referencial/components/watershed/dto/WatershedDto'
import { getDistance, getWGS84Coordinate } from '../../../utils/mapUtils/CoordinateUtils'
import { getSettingInt, getUser } from '../../../utils/SettingUtils'
import {
    findStationType,
    getAssociatedStations,
    getStation,
    getStationTitle,
    hasLocalisation,
    hasLocalisationStation,
} from '../../../utils/StationUtils'
import { arrayOf, getMapStateToProps, getPropTypes, instanceOf } from '../../../utils/StoreUtils'
import StationAction from '../../actions/StationAction'
import { STATION_TYPE } from '../../constants/StationConstants'
import DtoAssociatedStation from '../../dto/DtoAssociatedStation'
import DtoSite from '../../dto/sites/DtoSite'
import DtoSiteType from '../../dto/sites/DtoSiteType'
import AssociatedStationsEditModal from './AssociatedStationsEditModal'

const propsToFetch = {
    qualitometers: false,
    qualitometer: false,
    piezometer: false,
    hydrometricStation: false,
    hydrometricStations: false,
    productionUnit: false,
    productionUnits: false,
    distributionUnit: false,
    distributionUnits: false,
    installation: false,
    installationAssociations: false,
    productionUnitAssociations: false,
    distributionUnitAssociations: false,
    stationTypes: false,
}

class StationAssociatedStationApp extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = { maxDistance: 500,
            dataLoaded: false,
            dataLoading: false,
            progress: 0,
        }
    }

    setTitle = (station) => {
        if (this.props.layers.length > 0) {
            this.props.setTitle([{
                title: i18n[this.props.urlStationTypes[0]],
                href: this.props.urlStationTypes[0],
            }, {
                title: getStationTitle(station),
                href: `station/${this.props.urlStationTypes[0]}/${station.id}`,
            }])
        }
    }

    fetchData = (station) => {
        const loadRest = () => {
            if (!hasLocalisationStation(station) && !hasLocalisation(station) && !station.townCode) {
                this.setState({ dataLoaded: true })
                return
            }
            this.setState({ dataLoading: true, dataLoaded: false, progress: 0 })
            if (hasLocalisationStation(station) || hasLocalisation(station)) {
                const coordinates = hasLocalisation(station) ? getWGS84Coordinate(station.localisation) : getWGS84Coordinate({ ...station, x: 0, y: 0 })
                this.props.LoadStationNearbySituationByLocalization(station, coordinates,
                    p => this.setState({ progress: p }),
                ).then(() => {
                    this.setState({ dataLoaded: true })
                })
            } else if (station.townCode) {
                this.props.LoadStationNearbySituationByTownCode(station.townCode, p => this.setState({ progress: p }),
                ).then(() => {
                    this.setState({ dataLoaded: true })
                })
            } else {
                this.setState({ dataLoaded: true })
            }
        }
        if (this.props.installations.length) {
            loadRest()
        } else {
            this.props.fetchInstallationsLight().then(() => loadRest())
        }
    }

    componentDidMount() {
        const station = getStation(this.props, this.props.urlStationTypes[0])
        if (station.id) {
            this.setTitle(station)
            if (!this.props.associatedSites.length) {
                this.props.waitStart()
                this.props.fetchAssociatedSites(station.code, findStationType(station.typeName).code).then(() => {
                    this.props.waitStop()
                })
            }
        }
        if (station.id &&
            this.props.externalSites.length === 0 &&
            this.props.sitesTypes.length === 0) {
            this.fetchData(station)
        } else if (this.props.sitesTypes.length > 0) {
            this.setState({ dataLoaded: true })
        }
        this.props.setBackPath(`/${i18n[this.props.urlStationTypes[0]]}`)
        if (this.props.layers.length === 3 && !(getUser().consultant === '1')) {
            this.setActions({
                edit: () => {
                    $('.tooltipped').tooltip('remove')
                    const id = uuidv4()

                    const buttons = (
                        <div>
                            <a className='waves-effect waves-teal btn-flat modal-close'>{ i18n.close }</a>
                        </div>
                    )
                    const popup = {
                        id,
                        header: i18n.editAssociatedStations,
                        actions: buttons,
                        fixedFooter: true,
                        content: <AssociatedStationsEditModal station={ station }/>,
                    }
                    this.props.setPopup(popup)
                },
            })
        }
    }

    componentDidUpdate(prevProps) {
        const prevStation = getStation(prevProps, this.props.urlStationTypes[0])
        const station = getStation(this.props, this.props.urlStationTypes[0])
        if (station.id && !prevStation.id) {
            this.setTitle(station)
            this.fetchData(station)
        }
    }

    getInstallationsFromSameCity = (station) => {
        if (this.props.stationDashboard && this.props.urlStationTypes[0] === 'installation') {
            return this.props.installations.filter(i => i.id !== station.id && i.townCode === station.townCode)
        }
        return []
    }

    getNearbyInstallations = (station) => {
        if (this.props.nearbyInstallations && hasLocalisationStation(station)) {
            const installationsWithDistance = this.props.installations.filter(i => i.installationType === INSTALLATION_TYPE.BOREHOLE && hasLocalisationStation(i) && i.id !== station.id)
                .map(i => {
                    return {
                        ...i,
                        distance: getDistance(this.props.installation, i),
                    }
                })
            const nearbyInstallations = orderBy(installationsWithDistance.filter(i => i.distance < this.state.maxDistance), 'distance')
            const panel ={
                icon: 'gps_fixed',
                title: i18n.nearbyInstallations,
                content: (<NearbyInstallationsPanel nearbyInstallations={ nearbyInstallations }
                    maxDistance={ this.state.maxDistance }
                    onChangeMaxDistance={ v => this.setState({ maxDistance: v }) }
                />),
            }
            return {
                panel,
                installations: nearbyInstallations,
            }
        }
        return { panel: null, installations: [] }
    }

    getValidLayers = () => {
        const groupedSites = groupBy(this.props.externalSites, 'type_id')
        const types = Object.keys(groupedSites).map(typeId => this.props.sitesTypes.find(elem => elem.id === parseInt(typeId)).parameter)
        const layers = this.props.layers.filter(v => {
            return !(
                v === 'STATION_WATERSHED' && !this.props.watershed.id ||
                v === 'STATION_WATERSHED_1' && !this.props.watershed1.id ||
                v === 'STATION_WATERSHED_2' && !this.props.watershed2.id ||
                v === 'STATION_WATERSHED_3' && !this.props.watershed3.id ||
                v === 'STATION_WATERSHED_4' && !this.props.watershed4.id ||
                v === 'UDI' && isEmpty(this.props.distributionUnit) ||
                v === 'POLLUTED_SOIL' && !types.includes('POLLUTED_SOIL') ||
                v === 'INDUSTRIAL_SITE' && !types.includes('INDUSTRIAL_SITE') ||
                v === 'WATERMASS' && !this.props.watermass.id
            )
        })
        return layers
    }

    getSamplePointMarkerIcon = (stationType) => {
        switch (stationType) {
            case STATION_TYPE.POINT_EAU_SOUTERRAINE.toString():
                return 'SAMPLE_POINT_POINT_EAU_SOUTERRAINE'
            case STATION_TYPE.COURS_D_EAU.toString():
                return 'SAMPLE_POINT_COURS_D_EAU'
            case STATION_TYPE.PLAN_D_EAU.toString():
                return 'SAMPLE_POINT_PLAN_D_EAU'
            case STATION_TYPE.GRAND_COURS_D_EAU.toString():
                return 'SAMPLE_POINT_GRAND_COURS_D_EAU'
            case STATION_TYPE.MILIEU_MARIN.toString():
                return 'SAMPLE_POINT_MILIEU_MARIN'
            case STATION_TYPE.AUTRE_COURS_D_EAU.toString():
                return 'SAMPLE_POINT_AUTRE_COURS_D_EAU'
            case STATION_TYPE.SOURCE.toString():
                return 'SAMPLE_POINT_SOURCE'
            case STATION_TYPE.EAU_DISTRIBUEE.toString():
                return 'SAMPLE_POINT_EAU_DISTRIBUEE'
            case STATION_TYPE.EAU_TRAITEE.toString():
                return 'SAMPLE_POINT_EAU_TRAITEE'
            default:
                return 'SAMPLE_POINT'
        }
    }

    getDefaultLayers = (stationType) => {
        const { themeLayers, applicationSettings } = this.props
        switch (stationType) {
            case 'hydrometry':
                const idThemeESU = getSettingInt(applicationSettings, 'themeCartoESU')
                return idThemeESU ? compact([themeLayers.find((t) => t.id === idThemeESU)]) : []
            case 'piezometry':
                const idThemeESO = getSettingInt(applicationSettings, 'themeCartoESO')
                return idThemeESO ? compact([themeLayers.find((t) => t.id === idThemeESO)]) : []
            default:
                return []
        }
    }

    render() {
        if (this.state.dataLoaded) {
            const stationType = this.props.urlStationTypes[0]
            const station = {
                ...getStation(this.props, stationType),
                stationType: this.props.qualitometer ? this.props.qualitometer.stationType : '',
            }
            const sameCodeStations = [
                ...this.props.qualitometers,
                ...this.props.piezometers,
                ...this.props.hydrometricStations,
                ...this.props.distributionUnits,
                ...this.props.productionUnits,
                ...this.props.installations,
                ...this.props.pluviometers,
            ].filter(s => s.typeName !== station.typeName && s.code === station.code)
            const nearbyInstallations = this.getNearbyInstallations(station)
            const samplePointType = this.getSamplePointMarkerIcon(station.stationType)
            const samplePoints = station.link_samplePoints ? station.link_samplePoints.map(sp => ({
                ...sp,
                stationType: station.stationType,
                id: sp.idQualitometer,
                x: sp.x || station.x,
                y: sp.y || station.y,
                projection: sp.projection || station.projection,
                layer: samplePointType,
                typeName: samplePointType,
                scale: 0.5,
            })) : []
            const stationsPoints = [
                ...getAssociatedStations(this.props).filter(s => hasLocalisation(s) || s.townCode),
                ...sameCodeStations.filter(s => hasLocalisation(s) || s.townCode),
                ...this.getInstallationsFromSameCity(station),
                ...nearbyInstallations.installations,
            ]
            const uniqPoints = uniqBy(stationsPoints, s => s.typeName + s.id)
            return (
                <CartographyPanel layers={['STATIONS_POINTS', ...this.getValidLayers()]}
                    panels={nearbyInstallations.panel ? [nearbyInstallations.panel] : []}
                    componentType={stationType}
                    station={station}
                    town={station.townCode || stationsPoints?.[0]?.townCode}
                    stationsPoints={[...uniqPoints, ...samplePoints]}
                    showAllLayers={true}
                    stationType={stationType}
                    stationsPanelTitle={i18n.associatedStations}
                    defaultLayers={this.getDefaultLayers(stationType)}
                />
            )
        }
        if (this.state.dataLoading) {
            return <ProgressCard progress={this.state.progress}/>
        }
        return (
            <div className='padding-top-1 padding-bottom-1 padding-left-2' style={{ display: 'grid' }}>
                <Card className='padding-top-1 padding-bottom-1 padding-left-2'>
                    <h5>{ i18n.noDataToDisplay }</h5>
                </Card>
            </div>
        )
    }
}

StationAssociatedStationApp.propTypes = getPropTypes(propsToFetch, {
    pluviometer: PropTypes.arrayOf(PropTypes.instanceOf(PluviometerDto)),
    pluviometers: PropTypes.instanceOf(PluviometerDto),
    piezometers: arrayOf(DtoPiezometerLight),
    installations: arrayOf(DtoInstallationLight),
    layers: PropTypes.string,
    nearbyInstallations: PropTypes.bool,
    id: PropTypes.number.isRequired,
    urlStationTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    associatedSites: PropTypes.instanceOf(DtoAssociatedStation),
    externalSites: PropTypes.arrayOf(PropTypes.instanceOf(DtoSite)),
    sitesTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoSiteType)),
    getLink: PropTypes.func,
    watermass: PropTypes.instanceOf(WatermassDto),
    stationKMLExists: PropTypes.shape({
        exists: PropTypes.boolean,
    }),
    stationDashboard: PropTypes.bool,
    watershed: instanceOf(WatershedDto),
    watershed1: instanceOf(WatershedDto),
    watershed2: instanceOf(WatershedDto),
    watershed3: instanceOf(WatershedDto),
    watershed4: instanceOf(WatershedDto),
    LoadStationNearbySituationByTownCode: PropTypes.func,
    LoadStationNearbySituationByLocalization: PropTypes.func,
    waitStart: PropTypes.func,
    waitStop: PropTypes.func,
})

StationAssociatedStationApp.defaultProps = {
    getLink: ('', ''),
    layers: ['STATION', 'STATIONS_POINTS', 'TOWN'],
}

const mapStateToProps = (store) => getMapStateToProps(propsToFetch, {
    piezometers: store.PiezometryReducer.piezometersLight,
    pluviometers: store.PluviometryReducer.pluviometers,
    pluviometer: store.PluviometryReducer.pluviometer,
    installations: store.InstallationReducer.installationsLight,
    externalSites: store.StationReducer.externalSites,
    sitesTypes: store.StationReducer.sitesTypes,
    associatedSites: store.StationReducer.associatedSites,
    stationKMLExists: store.StationReducer.stationKMLExists,
    watershed: store.WatershedReducer.watershed,
    watershed1: store.WatershedReducer.watershed1,
    watershed2: store.WatershedReducer.watershed2,
    watershed3: store.WatershedReducer.watershed3,
    watershed4: store.WatershedReducer.watershed4,
    watermass: store.WatermassReducer.watermass,
    watermasses: store.WatermassReducer.watermasses,
    themeLayers: store.HomeReducer.themeLayers,
    applicationSettings: store.AdministrationReducer.applicationSettings,
})

const mapDispatchToProps = {
    fetchAssociatedSites: StationAction.fetchAssociatedSites,
    LoadStationNearbySituationByTownCode: StationAction.LoadStationNearbySituationByTownCode,
    LoadStationNearbySituationByLocalization: StationAction.LoadStationNearbySituationByLocalization,
    waitStart: WaitAction.waitStart,
    waitStop: WaitAction.waitStop,
    fetchInstallationsLight: InstallationAction.fetchInstallationsLight,
    setTitle: HomeAction.setTitle,
    setBackPath: HomeAction.setBackPath,
    setPopup: SieauAction.setPopup,
}

export default connect(mapStateToProps, mapDispatchToProps)(StationAssociatedStationApp)
