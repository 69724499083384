import { groupBy, isUndefined, keys, round } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Table from '../../../components/datatable/Table'
import { nbPerPageLabelShort } from '../../../referencial/constants/ReferencialConstants'
import { exportFile } from '../../../utils/ExportDataUtil'
import useListIndexed from 'utils/customHook/useListIndexed'
import DtoSearchTaxon from 'quality/dto/taxons/DtoSearchHydrobio'
import DtoOperation from 'quality/dto/operation/DtoOperation'
import ProgressCard from 'components/card/ProgressCard'
import OperationAction from '../operation/actions/OperationAction'

const stationTableHeaders = ['stationCode', 'cityCode', 'cityLabel', 'stationName', 'identifiedTaxons', 'nbSample', 'withEnumerations', 'occurrenceFrequency']
// const maxNbElements = 25000

// use displayAll if needed
const SampleHydrobioPanel = ({
    hydrobioList = [],
    filter = {},
}) => {
    const {
        qualitometers,
        citiesIndex,
    } = useSelector(store => ({
        qualitometers: store.QualityReducer.qualitometersLight,
        citiesIndex: store.CityReducer.citiesIndex,
    }), shallowEqual)

    const dispatch = useDispatch()

    const [operations, setOperations] = useState([])
    const [progress, setProgress] = useState(0)
    const [dataLoaded, setDataLoaded] = useState(false)

    const groupedByQualito = useMemo(() => groupBy(hydrobioList, 'qualitometer'), [hydrobioList])

    useEffect(() => {
        const stationIds = keys(groupedByQualito)
        if (stationIds.length) {
            dispatch(OperationAction.getAllHydrobioOperation(stationIds, setProgress)).then(op => {
                setOperations(op.map(o => new DtoOperation(o)))
                setDataLoaded(true)
            })
        } else {
            setDataLoaded(true)
        }
        return () => {
            setOperations([])
            setDataLoaded(false)
            setProgress(0)
        }
    }, [groupedByQualito])

    const filteredOperations = useMemo(() => {
        return !isUndefined(filter.support) ? operations.filter(o => `${o.support}` === filter.support) : operations
    }, [filter, operations])

    const groupQualitometers = useListIndexed(qualitometers, 'id')

    const stationsData = useMemo(() => {
        const groupedByStations = groupBy(hydrobioList, 'qualitometer')
        return keys(groupedByStations).map(key => {
            const station = groupQualitometers[key]
            const city = station?.townCode ? citiesIndex[station.townCode] : null

            const listHydrobios = groupedByStations[key]
            const identifiedTaxons = keys(groupBy(listHydrobios, 'taxon'))

            const groupedHydrobios = groupBy(listHydrobios, 'operation')
            const samples = filteredOperations.filter(o => o.qualitometer === parseInt(key))

            const operationsWithEnumerations = keys(groupedHydrobios).filter(gtKey => groupedHydrobios[gtKey].filter(t =>
                t.enumerationA ||
                t.enumerationB ||
                t.enumerationC ||
                t.enumerationC2 ||
                t.enumerationP1 ||
                t.enumerationP2 ||
                t.enumerationP3 ||
                t.enumerationP4 ||
                t.enumerationP5 ||
                t.enumerationP6 ||
                t.enumerationP7 ||
                t.enumerationP8 ||
                t.enumerationP9 ||
                t.enumerationP10 ||
                t.enumerationP11 ||
                t.enumerationP12,
            ).length)

            const occurrenceFrequency = samples.length && (operationsWithEnumerations.length / samples.length) * 100
            const roundedOccurrence = occurrenceFrequency ? `${round(occurrenceFrequency, 2)}%` : i18n.incalculable

            return {
                stationCode: { value: station?.code },
                cityCode: { value: station?.townCode },
                cityLabel: { value: city?.name },
                stationName: { value: station?.name },
                identifiedTaxons: { value: identifiedTaxons.length, format: '0', cellType: 'number' },
                nbSample: { value: samples.length, format: '0', cellType: 'number' },
                withEnumerations: { value: operationsWithEnumerations.length, format: '0', cellType: 'number' },
                occurrenceFrequency: { value: roundedOccurrence },
            }
        })
    }, [citiesIndex, groupQualitometers, filteredOperations, hydrobioList])

    return dataLoaded ? (
        <Table
            title={i18n.stations}
            data={stationsData}
            actions={[{
                iconName: 'file_download',
                tooltip: i18n.export,
                onClick: () => {
                    exportFile({
                        data: stationsData.length ? [{ ...stationsData[0], headers: stationTableHeaders }, ...stationsData.slice(1)] : [],
                        exportType: 'xlsx',
                        titleFile: `${i18n.research}-${i18n.quality}`,
                    })
                },
            }]}
            sortable
            color
            type={{ headers: stationTableHeaders }}
            condensed
            paging
            nbPerPageLabel={nbPerPageLabelShort}
            noHightlight
            id='station_table'
        />
    ) : <ProgressCard progress={progress} />
}

SampleHydrobioPanel.propTypes = {
    hydrobioList: PropTypes.arrayOf(PropTypes.instanceOf(DtoSearchTaxon)),
    filter: PropTypes.shape({}),
}

export default SampleHydrobioPanel