/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { getFullDate, getHour } from '../../../utils/DateUtil'
import { getLabel } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import StationDescriptionTable from './StationDescriptionTable'
import SimpleTextArea from 'components/forms/SimpleTextArea'
import { Card, CardContent, Grid2 } from '@mui/material'
import Input from 'components/forms/Input'
import { onChangeHour } from 'utils/FormUtils'
import Select from 'components/forms/Select'
import { authorizeContributors } from 'utils/HabilitationUtil'
import useSandreList from 'utils/customHook/useSandreList'
import SimpleDatePicker from 'components/forms/SimpleDatePicker'

const headers = ['startDate', 'endDate', 'hydrologicalEntity', 'contributor', 'qualityAssociation', 'comment']

const HydroEntityDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const {
        contributors,
        hydrogeologicalEntities,
        accountUser,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        hydrogeologicalEntities: store.StationReducer.hydrogeologicalEntities,
        accountUser: store.AccountReducer.accountUser,
    }), shallowEqual)

    const resourcePointList = useSandreList(SANDRE.QUALITE_POINT_RESSOURCE)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.startDate}
                            label={i18n.startDate}
                            onChange={v => onChangeElement({ startDate: v })}
                            max={stateElement.endDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.startHour}
                            value={getHour(stateElement.startDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ startDate: v2 }), { max: stateElement.endDate }, stateElement.startDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <SimpleDatePicker
                            value={stateElement.endDate}
                            label={i18n.endDate}
                            onChange={v => onChangeElement({ endDate: v })}
                            min={stateElement.startDate}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Input
                            title={i18n.endHour}
                            value={getHour(stateElement.endDate)}
                            onChange={v => onChangeHour(v, v2 => onChangeElement({ endDate: v2 }), { min: stateElement.startDate }, stateElement.endDate)}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={stateElement.contributor}
                            label={i18n.contributor}
                            onChange={v => onChangeElement({ contributor: v })}
                            keyValue='id'
                            options={authorizeContributors(contributors, accountUser, stateElement.contributor)}
                            displayWithCode
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            value={stateElement.hydrologicalEntity}
                            label={i18n.hydrogeologicalEntity}
                            onChange={v => onChangeElement({ hydrologicalEntity: v })}
                            keyValue='code'
                            options={hydrogeologicalEntities}
                            displayWithCode
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select
                            options={resourcePointList}
                            label={i18n.qualityAssociation}
                            value={stateElement.qualityAssociation}
                            onChange={v => onChangeElement({ qualityAssociation: v })}
                        />
                    </Grid2>
                    <Grid2 size={12}>
                        <SimpleTextArea
                            title={i18n.comment}
                            value={stateElement.comment}
                            onChange={v => onChangeElement({ comment: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

HydroEntityDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const StationHydrogeologicalEntityPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        contributors,
        hydrogeologicalEntities,
        sandreCodes,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
        hydrogeologicalEntities: store.StationReducer.hydrogeologicalEntities,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_hydroEntity'
            formatFunction={c => ({
                ...c,
                startDate: getFullDate(c.startDate),
                endDate: getFullDate(c.endDate),
                contributor: getLabel(contributors, c.contributor),
                hydrologicalEntity: getLabel(hydrogeologicalEntities, c.hydrologicalEntity),
                qualityAssociation: getSandreLabel(sandreCodes, SANDRE.QUALITE_POINT_RESSOURCE, c.qualityAssociation),
            })}
            titles={{
                title: i18n.hydrogeologicalEntities,
                edit: i18n.hydrogeologicalEntities,
                new: i18n.hydrogeologicalEntities,
            }}
            headers={headers}
            dialogContent={HydroEntityDialogContent}
        />
    )
}

StationHydrogeologicalEntityPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default StationHydrogeologicalEntityPanel