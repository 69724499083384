import React from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@mui/material'
import { isNil } from 'lodash'

const SimpleCircularProgressWithLabel = ({ value, ...otherProps }) => (
    <Box sx={{ position: 'relative', display: 'inline-flex', backgroundColor: 'transparent' }}>
        <CircularProgress
            variant={!isNil(value) ? 'determinate' : 'indeterminate'}
            sx={{
                backgroundColor: 'transparent',
                '& svg': {
                    backgroundColor: 'transparent',
                },
            }}
            value={value}
            {...otherProps}
        />
        {!isNil(value) && (
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                }}
            >
                <span>{`${Math.round(value)}%`}</span>
            </Box>
        )}
    </Box>
)

SimpleCircularProgressWithLabel.propTypes = {
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    otherProps: PropTypes.shape({}),
}

export default SimpleCircularProgressWithLabel
