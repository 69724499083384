import { difference, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import Checkbox from '../../../../components/forms/Checkbox'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import RadioButtons from '../../../../components/forms/RadioButtons'
import Select from '../../../../components/forms/Select'
import Icon from '../../../../components/icon/Icon'
import PiezometryAction from '../../../../piezometry/actions/PiezometryAction'
import ReferencialAction from '../../../../referencial/action/ReferencialAction'
import ContributorAction from '../../../../referencial/components/contributor/actions/ContributorAction'
import { nbPerPageLabelMedium, SANDRE } from '../../../../referencial/constants/ReferencialConstants'
import { setModal } from '../../../../utils/FormUtils'
import { getIntegrationModes, getMeasureCoteList } from '../../../../utils/JobUtils'
import { hasValue } from '../../../../utils/NumberUtil'
import { getHardPiezoDataTypes } from '../../../../utils/PiezometryUtils'
import { getSettingInt } from '../../../../utils/SettingUtils'
import { execByType, getStations } from '../../../../utils/StationUtils'
import { getQualificationSelectOptions, getStatusSelectOptions } from '../../../../utils/StatusUtil'
import Job from '../../../dto/DtoJob'
import ImportFileModal from './ImportFileModal'
import { integrationModeHelpIcon } from '../../../utils/ImportUtils'
import MultiContributorsAutocomplete from 'referencial/components/contributor/components/MultiContributorsAutocomplete'
import { getHardHydroDataTypes } from '../../../../utils/HydroUtils'
import HydrometryAction from '../../../../hydrometry/actions/HydrometryAction'
import PluviometryAction from '../../../../pluviometry/actions/PluviometryAction'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, Icon as IconMui } from '@mui/material'
import CSVGenericAdvancedModal from './CSVGenericAdvancedModal'
import { exportFile } from '../../../../utils/ExportDataUtil'
import { CardTable } from '../../../../components/datatable/NewTable'
import useSandreList from '../../../../utils/customHook/useSandreList'
import { ButtonMUI } from '../../../../components/styled/Buttons'

const SebaPanel = ({
    job,
    filters,
    isEditMode,
    onChangeFilters,
    onChangeJobGlobalParameters,
    onChangeTableParameters,
    parameters,
    onDeleteParameter,
    onDuplicateParameter,
    onAddParameter,
}) => {
    const [advancedPopupIsOpen, setAdvancedPopupIsOpen] = useState(false)
    const piezoNatures = useSandreList(SANDRE.PIEZOMETER_MEASURE_NATURE, true)
    const obtentionModes = useSandreList(SANDRE.OBTAINING_MODE)
    const dispatch = useDispatch()
    const {
        piezometryDataTypes,
        piezometers,
        pluviometers,
        pluviometryDataTypes,
        hydrometricStations,
        hydrometryDataTypes,
        sandreCodes,
        contributors,
        applicationSettings,
    } = useSelector((store) => ({
        piezometryDataTypes: store.PiezometryReducer.piezometryDataTypes,
        piezometers: store.PiezometryReducer.piezometersLight,
        pluviometers: store.PluviometryReducer.pluviometers,
        pluviometryDataTypes: store.PluviometryReducer.pluviometryDataTypes,
        hydrometricStations: store.HydrometryReducer.hydrometricStations,
        hydrometryDataTypes: store.HydrometryReducer.hydrometryDataTypes,
        status: store.QualityReducer.status,
        qualifications: store.QualityReducer.qualifications,
        sandreCodes: store.ReferencialReducer.sandreCodes,
        contributors: store.ContributorReducer.contributors,
        applicationSettings: store.AdministrationReducer.applicationSettings,
    }), shallowEqual)

    useEffect(() => {
        if (!piezometers.length) {
            dispatch(PiezometryAction.fetchPiezometersLight())
        }
        if (!hydrometricStations.length) {
            dispatch(HydrometryAction.fetchHydrometricStations())
        }
        if (!pluviometers.length) {
            dispatch(PluviometryAction.fetchPluviometers())
        }
        if (!piezometryDataTypes.length) {
            dispatch(PiezometryAction.fetchPiezometryDataTypes())
        }
        if (!hydrometryDataTypes.length) {
            dispatch(HydrometryAction.fetchHydrometryDataTypes())
        }
        if (!pluviometryDataTypes.length) {
            dispatch(PluviometryAction.fetchPluviometryDataTypes())
        }
        dispatch(ReferencialAction.fetchSandreCodes())
        dispatch(ContributorAction.fetchContributors())
        if (!Object.keys(filters).length) {
            onChangeFilters({
                measureCote: 2,
                producer: getSettingInt(applicationSettings, 'validationDefaultProducer'),
                manager: getSettingInt(applicationSettings, 'validationDefaultManager'),
                validator: getSettingInt(applicationSettings, 'validationDefaultValidator'),
            })
        }
    }, [])

    const setAdvancedFtpModal = () => {
        setModal({
            title: i18n.importParam,
            content: <ImportFileModal url={job.parameters.path} />,
        })
    }

    const onChangeColumns = (parameter, index, id, channels) => {
        const actualData = parameter.data || []
        const newData = (() => {
            if (!hasValue(channels)) {
                return actualData.filter(d => d.id !== id)
            }
            return uniqBy([ { id, channels }, ...actualData], 'id')
        })()
        onChangeTableParameters(parameter, index, { data: newData })
    }

    const findData = (parameter, id) => {
        const found = (parameter.data || []).find(d => d.id === id)
        return found ? found.channels : null
    }

    const getDataTypes = () => execByType(filters?.stationType, {
        pluviometry: () => pluviometryDataTypes,
        piezometry: () => uniqBy([ ...getHardPiezoDataTypes(), ...piezometryDataTypes ], 'id'),
        hydrometry: () => uniqBy([ ...hydrometryDataTypes, ...getHardHydroDataTypes() ], 'id'),
        default: () => [],
    })

    const getParametersData = () => {
        const disabled = { disabled: !isEditMode }
        return parameters.map((p, index) => {
            const columnFields = getDataTypes().reduce((acc, val) => ({
                ...acc,
                [`data${val.id}`]: <Input value={ findData(p, val.id) } onChange={ v => onChangeColumns(p, index, val.id, v) } {...disabled }/>,
            }), {})
            return {
                nullValue: <Icon icon='delete' tooltip={ i18n.delete } onClick={ () => onDeleteParameter(index) } />,
                nullValue2: <Icon icon='add_to_photos' tooltip={ i18n.duplicate } onClick={ () => onDuplicateParameter(index) } />,
                directory: <Input value={ p.directory } onChange={ v => onChangeTableParameters(p, index, { directory: v }) } {...disabled }/>,
                filter: <Input value={ p.filter } onChange={ v => onChangeTableParameters(p, index, { filter: v }) } {...disabled }/>,
                coeff: <NumberField value={ p.factor } onChange={ v => onChangeTableParameters(p, index, { factor: v }) } floatValue {...disabled }/>,
                offset: <NumberField value={ p.offset } onChange={ v => onChangeTableParameters(p, index, { offset: v }) } floatValue {...disabled }/>,
                station: (<Select options={ getStations({ piezometers, pluviometers, hydrometricStations }, filters.stationType).map(s => ({ ...s, name: s.code ? `[${s.code}] ${s.name}` : s.name })) }
                    onChange={ (_, v) => v && v.id ? onChangeTableParameters(p, index, { stationCode: v.code, stationId: v.id }) : null }
                    value={ p.stationId } keyValue='id' {...disabled }
                />),
                pointPrelevement: <Input value={ p.pointPrelevement } onChange={ v => onChangeTableParameters(p, index, { pointPrelevement: v }) } {...disabled }/>,
                ...columnFields,
            }
        })
    }

    const getPiezometryDataLabel = () => {
        const dataType = getDataTypes()
        const header = ['directory', 'filter', 'station', 'coeff', 'offset', ...dataType.map(h => h.label)]
        return header
    }

    const filtreDatatype = (data, value) => data.filter(d => d.id === value ? d.channels : '')
        .map(v => v.channels)

    const exportData = () => {
        const data = parameters.map(param => {
            const columnFields = getDataTypes(filters).reduce((acc, val) => ({
                ...acc,
                [val.label]: param.data ? filtreDatatype(param.data, val.id) : '',
            }), {})
            const line = {
                directory: param.directory,
                filter: param.filter,
                station: param.stationCode,
                excelTab: param.sheet,
                conditionColumn: param.conditionColumn,
                requiredValue: param.requiredValue,
                coeff: param.factor,
                offset: param.offset,
                ...columnFields,
                headers: getPiezometryDataLabel(filters),
            }
            return line
        })
        return data
    }

    const dataTypes = [
        { value: 'pluviometry', label: i18n.pluviometry },
        { value: 'piezometry', label: i18n.piezometry },
        { value: 'hydrometry', label: i18n.hydrometry },
    ]
    const disabled = !isEditMode
    const tableLines = getParametersData()
    const baseHeaders = ['nullValue', 'nullValue2', 'directory', 'filter', 'station', 'coeff', 'offset', 'pointPrelevement']
    const columnHeaders = getDataTypes(filters).map(type => `data${type.id}`)
    const filtersHeaders = difference([ ...baseHeaders, ...columnHeaders ], filters.unwantedColumns)
    const customHeaders = getDataTypes(filters).reduce((acc, val) => ({ ...acc, [`data${val.id}`]: val.label }), {})
    return (
        <Grid2 container rowSpacing={1}>
            <Grid2 size={12}>
                <fieldset>
                    <legend>&nbsp;{i18n.defaultValue}&nbsp;</legend>
                    <Grid2 container className='padding-top-1' spacing={1} justifyContent='center' alignItems='center'>
                        <Grid2 size={3}><Select label={ i18n.status } value={ filters.status } onChange={ v => onChangeFilters({ status: v }) } options={ getStatusSelectOptions() } nullLabel='&nbsp;' disabled={ disabled }/></Grid2>
                        <Grid2 size={3}><Select label={ i18n.qualification } value={ filters.qualification } onChange={ v => onChangeFilters({ qualification: v }) } options={ getQualificationSelectOptions() } nullLabel='&nbsp;' disabled={ disabled }/></Grid2>
                        <Grid2 size={6}><MultiContributorsAutocomplete label={ i18n.producer } values={ filters.producer } onChange={ v => onChangeFilters({ producer: v }) } options={ contributors } disabled={ disabled }/></Grid2>
                        <Grid2 size={2}><Select label={ i18n.measureNature } value={ filters.nature } onChange={ v => onChangeFilters({ nature: v }) } options={ piezoNatures } nullLabel='&nbsp;' disabled={ disabled }/></Grid2>
                        <Grid2 size={2}><Select label={ i18n.obtainningMode } value={ filters.obtainingMode } onChange={ v => onChangeFilters({ obtainingMode: v }) } options={ obtentionModes } nullLabel='&nbsp;' disabled={ disabled }/></Grid2>
                        <Grid2 size={4}><MultiContributorsAutocomplete label={ i18n.administrator } values={ filters.manager } onChange={ v => onChangeFilters({ manager: v }) } options={ contributors } disabled={ disabled }/></Grid2>
                        <Grid2 size={4}><MultiContributorsAutocomplete label={ i18n.validator } values={ filters.validator } onChange={ v => onChangeFilters({ validator: v }) } options={ contributors } disabled={ disabled }/></Grid2>
                    </Grid2>
                </fieldset>
            </Grid2>
            <Grid2 size={12} container spacing={1} justifyContent='center' alignItems='center'>
                <Grid2 size={3}><Checkbox label={ i18n.renameFic } onChange={ v => onChangeFilters({ renameProcessedFiles: v }) } disabled={ disabled } checked={ filters.renameProcessedFiles } /></Grid2>
                <Grid2 size={3}><Checkbox label={ i18n.dontProcessFilesInLogs } onChange={ v => onChangeFilters({ dontProcessFilesInLogs: v }) } disabled={ disabled } checked={ filters.dontProcessFilesInLogs } /></Grid2>
                <Grid2 size={3}><RadioButtons elements={ getMeasureCoteList() } selected={ filters.measureCote } onChange={ v => onChangeFilters({ measureCote: v }) } title={ i18n.ratingExpression } disabled={ disabled }/></Grid2>
            </Grid2>
            <Grid2 size={12} container spacing={1} justifyContent='center' alignItems='baseline'>
                <Grid2 size={3}><Select label={ i18n.dataType } options={ dataTypes } obligatory value={ filters.stationType } onChange={ v => onChangeFilters({ stationType: v }) } disabled={ disabled }/></Grid2>
                <Grid2 size={3}>
                    <Select options={ getIntegrationModes() } label={ i18n.dataIntegration } value={ filters.importMode } labelSpan={ integrationModeHelpIcon() }
                        onChange={ v => onChangeFilters({ importMode: v }) } disabled={ disabled }
                    /></Grid2>
                <Grid2 size={4} container justifyContent='flex-start' alignItems='center' spacing={2}>
                    <Grid2 size={8}><Input title={ i18n.directory } value={ job.parameters.path } onChange={ v => onChangeJobGlobalParameters({ path: v }) } disabled={ disabled } /></Grid2>
                    <Grid2>
                        <ButtonMUI
                            onClick={() => setAdvancedFtpModal()}
                            style={{ border: 'solid rgba(53, 96, 159, 1)', borderWidth: 2, fontWeight: 600 }}
                        ><IconMui>input</IconMui></ButtonMUI>
                    </Grid2>
                </Grid2>
                <Grid2 size={2}><NumberField title={i18n.excludedValue} value={filters.excludedValue} onChange={v => onChangeFilters({ excludedValue: v }) } disabled={ disabled } floatValue /></Grid2>
            </Grid2>
            <Grid2 size={12} className='job-parameter-table'>
                <CardTable
                    rows={ tableLines }
                    headers={ filtersHeaders }
                    headersLabel = { customHeaders }
                    title={ i18n.credentials }
                    rowsPerPageOptions={ nbPerPageLabelMedium }
                    data-cy='SebaImport_table'
                    actions={[{
                        icon: 'add_box',
                        onClick: onAddParameter,
                        tooltip: i18n.add,
                        displayed: isEditMode,
                    },
                    {
                        icon: 'build',
                        onClick: () => setAdvancedPopupIsOpen(true),
                        tooltip: i18n.advanced,
                        displayed: isEditMode,
                    },
                    {
                        icon: 'download',
                        onClick: () => {
                            exportFile({
                                data: exportData(),
                                titleFile: i18n.credentials,
                            })
                        },
                        tooltip: i18n.download,
                    }]}
                />
            </Grid2>
            <Dialog
                fullWidth
                maxWidth='lg'
                open={advancedPopupIsOpen}
            >
                <DialogTitle style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {i18n.selectColumns}
                </DialogTitle>
                <DialogContent className='padding-top-1 padding-bottom-0'>
                    <CSVGenericAdvancedModal
                        unwantedColumns={ filters.unwantedColumns ?? [] }
                        onChange={ v => onChangeFilters({ unwantedColumns: v }) }
                        dataTypes={ getDataTypes(filters) }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAdvancedPopupIsOpen(false)} variant='outlined' color='primary'>
                        {i18n.close}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid2>
    )
}
SebaPanel.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    filters: PropTypes.object,
    job: PropTypes.instanceOf(Job).isRequired,
    onChangeJob: PropTypes.func.isRequired,
    isEditMode: PropTypes.bool,
    onChangeJobGlobalParameters: PropTypes.func,
    onChangeFilters: PropTypes.func,
    onChangeTableParameters: PropTypes.func,
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(PropTypes.object)),
    onDeleteParameter: PropTypes.func,
    onDuplicateParameter: PropTypes.func,
    onAddParameter: PropTypes.func,
}

export default SebaPanel