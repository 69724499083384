/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import Input from '../../../../components/forms/Input'
import NumberField from '../../../../components/forms/NumberField'
import DtoInstallation from '../../../dto/installation/DtoInstallation'
import Checkbox from '../../../../components/forms/Checkbox'
import DtoInstallationCasing from '../../../dto/installation/DtoInstallationCasing'
import SimpleDatePicker from '../../../../components/forms/SimpleDatePicker'
import { Grid2 } from '@mui/material'
import SimpleTextArea from 'components/forms/SimpleTextArea'

const InstallationOuvragePanel = ({
    onChange = () => { },
    station = {},
    disabled = false,
    readMode = false,
    work,
    surveyMode,
}) => {
    const ouvrage = work || (station.link_sampleCasing && station.link_sampleCasing.length ? station.link_sampleCasing[0] : new DtoInstallationCasing({ idInstallation: station.id }))

    const onChangeCasing = (value) => {
        if (work) {
            onChange({
                ...ouvrage,
                ...value,
            })
        } else {
            onChange({
                link_sampleCasing: [{
                    ...ouvrage,
                    ...value,
                }],
            })
        }
    }

    return (
        <>
            <Grid2 size={{ md: 6, xs: 12 }}>
                <SimpleDatePicker
                    id='completionDate'
                    label={i18n.completionDate}
                    style={{ padding: 0 }}
                    value={ouvrage.completionDate}
                    onChange={(v) => onChangeCasing({ completionDate: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={{ md: 6, xs: 12 }}>
                <SimpleDatePicker
                    id='declarationDate'
                    label={i18n.declarationDate}
                    style={{ padding: 0 }}
                    value={ouvrage.declarationDate}
                    onChange={(v) => onChangeCasing({ declarationDate: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={{ md: 6, xs: 12 }}>
                <SimpleDatePicker
                    id='receiptDate'
                    style={{ padding: 0 }}
                    label={i18n.receiptDate}
                    value={ouvrage.receiptDate}
                    onChange={(v) => onChangeCasing({ receiptDate: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={{ md: 6, xs: 12 }}>
                <Input
                    value={ouvrage.receiptNumber}
                    title={i18n.receiptNumber}
                    onChange={v => onChangeCasing({ receiptNumber: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={{ md: 6, xs: 12 }}>
                <NumberField
                    title={i18n.depth}
                    value={ouvrage.depth}
                    min={0}
                    onChange={v => onChangeCasing({ depth: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                />
            </Grid2>
            <Grid2 size={{ md: 6, xs: 12 }}>
                <NumberField
                    title={i18n.copingHeight}
                    value={ouvrage.copingHeight}
                    min={0}
                    onChange={v => onChangeCasing({ copingHeight: v })}
                    disabled={disabled}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={{ xs: 3, md: 6 }}>
                <Checkbox
                    checked={ouvrage.cementing}
                    label={i18n.cementing}
                    onChange={v => onChangeCasing({ cementing: v })}
                    disabled={readMode || disabled}
                />
            </Grid2>
            <Grid2 size={{ xs: 3, md: 6 }}>
                <Checkbox
                    checked={ouvrage.concreteCoping}
                    label={i18n.concreteCoping}
                    onChange={v => onChangeCasing({ concreteCoping: v })}
                    disabled={readMode || disabled}
                />
            </Grid2>
            <Grid2 size={{ xs: 3, md: 6 }}>
                <Checkbox
                    checked={ouvrage.protectionHead}
                    label={i18n.protectionHead}
                    onChange={v => onChangeCasing({ protectionHead: v })}
                    disabled={readMode || disabled}
                />
            </Grid2>
            <Grid2 size={{ xs: 3, md: 6 }}>
                <Checkbox
                    checked={ouvrage.modernizedPerimeterASP}
                    label={i18n.modernizedPerimeterASP}
                    onChange={v => onChangeCasing({ modernizedPerimeterASP: v })}
                    disabled={readMode || disabled}
                />
            </Grid2>
            <Grid2 size={{ xs: 3, md: 6 }}>
                <Checkbox
                    checked={ouvrage.specificPrescriptions}
                    label={i18n.specificPrescriptions}
                    onChange={v => onChangeCasing({ specificPrescriptions: v })}
                    disabled={readMode || disabled}
                />
            </Grid2>
            <Grid2 size={{ md: 6, xs: 12 }}>
                <NumberField
                    title={i18n.reserveFlow}
                    value={ouvrage.reserveFlow}
                    min={0}
                    onChange={v => onChangeCasing({ reserveFlow: v })}
                    disabled={surveyMode || disabled}
                    readMode={readMode}
                    floatValue
                />
            </Grid2>
            <Grid2 size={12}>
                <SimpleTextArea
                    title={i18n.comment}
                    value={ouvrage.comment}
                    onChange={value => onChangeCasing({ comment: value })}
                    disabled={disabled}
                    readMode={readMode}
                />
            </Grid2>
        </>
    )
}

InstallationOuvragePanel.propTypes = {
    station: PropTypes.instanceOf(DtoInstallation),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    readMode: PropTypes.bool,
    work: PropTypes.instanceOf({}),
    surveyMode: PropTypes.bool,
}

export default InstallationOuvragePanel
