import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import DtoUserCmsStatistic from '../../../user/dto/DtoUserCmsStatistic'
import DtoUserStationStatistic from '../../../user/dto/DtoUserStationStatistic'
import DtoUserStatistic from '../../../user/dto/DtoUserStatistic'
import DtoUserUsageStatistic from '../../../user/dto/DtoUserUsageStatistic'
import AccessChartPanel from './charts/AccessChartPanel'
import CmsStatisticsPanel from './statistic/CmsStatisticsPanel'
import StationStatisticsPanel from './statistic/StationStatisticsPanel'
import StatisticsPanel from './statistic/StatisticsPanel'
import UsageStatisticsPanel from './statistic/UsageStatisticsPanel'
import { Grid2 } from '@mui/material'
import i18n from 'simple-react-i18n'
import useTitle from '../../../../../utils/customHook/useTitle'
import User from '../../../../../account/dto/User'

const UserStatisticPanel = () => {
    const {
        user,
        userStatistics,
        userCmsStatistics,
        userUsageStatistics,
        userStationStatistics,
    } = useSelector(store => ({
        user: store.UserReducer.user,
        userStatistics: store.UserReducer.userStatistics,
        userCmsStatistics: store.UserReducer.userCmsStatistics,
        userUsageStatistics: store.UserReducer.userUsageStatistics,
        userStationStatistics: store.UserReducer.userStationStatistics,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.administration,
        href: 'administration',
    },
    {
        title: i18n.users,
        href: 'administration/user',
    },
    {
        title: user.login,
        href: `administration/user/${user.login}`,
    },
    {
        title: i18n.statistics,
        href: `administration/user/${user.login}/statistics`,
    }], [user.login])

    return (
        <Grid2 container spacing={2} padding={'0 10 10 10'}>
            <Grid2 container size={12} spacing={1}>
                <Grid2 size={8}>
                    <AccessChartPanel
                        userStatistics={userStatistics}
                    />
                </Grid2>
                <Grid2 size={4}>
                    <StatisticsPanel
                        userStatistics={userStatistics}
                    />
                </Grid2>
            </Grid2>
            <Grid2 size={12}>
                <UsageStatisticsPanel
                    userUsageStatistics={userUsageStatistics}
                />
            </Grid2>
            <Grid2 size={12}>
                <StationStatisticsPanel
                    userStationStatistics={userStationStatistics}
                />
            </Grid2>
            <Grid2 size={12}>
                <CmsStatisticsPanel
                    userCmsStatistics={userCmsStatistics}
                />
            </Grid2>
        </Grid2>
    )
}

UserStatisticPanel.propTypes = {
    user: PropTypes.instanceOf(User),
    userStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStatistic)),
    userCmsStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserCmsStatistic)),
    userUsageStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserUsageStatistic)),
    userStationStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserStationStatistic)),
}

export default UserStatisticPanel
