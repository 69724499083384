/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import { arrayOf, getLabel } from '../../../../utils/StoreUtils'
import DtoPiezometerContributorLink from '../../../../piezometry/dto/DtoPiezometerContributorLink'
import Select from 'components/forms/Select'
import { Card, CardContent } from '@mui/material'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'

const headers = ['contributor']

const KeyPossessorDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const {
        contributors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    return (
        <Card>
            <CardContent>
                <Select
                    value={stateElement.contributor}
                    label={i18n.contributor}
                    onChange={v => onChangeElement({ contributor: v })}
                    keyValue='id'
                    options={contributors}
                />
            </CardContent>
        </Card>
    )
}

KeyPossessorDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const InstallationKeysPossessorPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        contributors,
    } = useSelector(store => ({
        contributors: store.ContributorReducer.contributors,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_keyPossessors'
            formatFunction={c => ({
                ...c,
                contributor: getLabel(contributors, c.contributor, 'mnemonique'),
            })}
            titles={{
                title: i18n.keysPossessor,
                edit: i18n.editContributorLink,
                new: i18n.newContributorLink,
            }}
            headers={headers}
            dialogContent={KeyPossessorDialogContent}
        />
    )
}

InstallationKeysPossessorPanel.propTypes = {
    station: PropTypes.object, // DtoInstallation
    contributors: arrayOf(DtoPiezometerContributorLink),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}


export default InstallationKeysPossessorPanel