import { isEqual, orderBy, reduce, take } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import i18n from 'simple-react-i18n'
import { getI18nOrLabel } from 'utils/StringUtil'
import { getFullDateMini } from '../../../../../../utils/DateUtil'
import DtoUser from '../../../dto/DtoUser'
import DtoUserUsageStatistic from '../../../dto/DtoUserUsageStatistic'
import DtoUserUsageStatisticItem from '../../../dto/DtoUserUsageStatisticItem'
import ExportAction from '../../../../../../export/actions/ExportAction'
import { formatData } from '../../../../../../utils/ExportDataUtil'
import { nbPerPageLabelMedium } from '../../../../../../referencial/constants/ReferencialConstants'
import { CardTable } from '../../../../../../components/datatable/NewTable'
import { greyBlue } from '../../../../../../utils/constants/ColorTheme'

class UsageStatisticsPanel extends Component {
    constructor(props) {
        super(props)
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps.userUsageStatistics, this.props.userUsageStatistics)
            || !isEqual(nextProps.user, this.props.user)
    }

    getReduceStatistics = () => {
        return reduce(this.props.userUsageStatistics, (a, b) => {
            if (!a[`${b.module}_${b.value}`]) {
                a[`${b.module}_${b.value}`] = {
                    module: b.module,
                    value: b.value,
                    datas: [],
                }
            }
            a[`${b.module}_${b.value}`].datas.push(b)
            return a
        }, {})
    }

    getDatas = () => {
        const reduceValues = this.getReduceStatistics()
        return orderBy(Object.keys(reduceValues).map((o) => {
            const statisticObject = reduceValues[o]
            const listOfStatistics = statisticObject.datas
            const lastVisit = orderBy(listOfStatistics, 'statisticDate', 'desc')[0]
            return new DtoUserUsageStatisticItem({
                stationType: getI18nOrLabel(statisticObject.module.toLowerCase()),
                functionnality: getI18nOrLabel(statisticObject.value),
                lastAccess: getFullDateMini(lastVisit.statisticDate),
                nbAccess: listOfStatistics.length,
            })
        }), 'nbAccess', 'desc')
    }

    render() {
        const login = this.props.user.login || i18n.users
        if (this.props.userUsageStatistics.length) {
            const data = take(this.getDatas(), 10)
            return (
                <CardTable
                    actions={[{
                        icon: 'download',
                        onClick: () => this.props.export(formatData(data), 'xlsx', `${i18n.userUsageStatisticsExport + login}`),
                        color: 'white',
                    }]}
                    title={ `10 ${i18n.userUsageStatistics}` }
                    headers={['stationType', 'functionnality', 'nbAccess', 'lastAccess']}
                    rowsPerPageOptions={nbPerPageLabelMedium}
                    rows={ data }
                    data-cy={'user_usage_statistics_table'}
                    color={greyBlue}
                    hideNbElements
                    displayHeaders={false}
                />
            )
        }
        return null
    }
}

UsageStatisticsPanel.propTypes = {
    user: PropTypes.instanceOf(DtoUser),
    userUsageStatistics: PropTypes.arrayOf(PropTypes.instanceOf(DtoUserUsageStatistic)),
    export: PropTypes.func,
}
const mapStateToProps = (store) => {
    return {
        user: store.UserReducer.user,
    }
}
const mapDispatchToProps = {
    export: ExportAction.export,
}
export default connect(mapStateToProps, mapDispatchToProps)(UsageStatisticsPanel)
