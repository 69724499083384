import { push } from '@lagunovsky/redux-react-router'
import React, { useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import moment from 'moment'
import { sieauTooltip } from 'utils/FormUtils'
import AgriThunk from 'agriAdministration/actions/AgriThunk'
import { getDate, getFullDateMini } from 'utils/DateUtil'
import { NewTable } from 'components/datatable/NewTable'
import { Icon } from '@mui/material'


const CurrentSurveysPanel = () => {
    const {
        surveysWithStats,
    } = useSelector(store => ({
        surveysWithStats: store.AgriReducer.surveysWithStats,
    }), shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(AgriThunk.fetchSurveysWithStats())
    }, [])

    const redirect = (href = '') => dispatch(push(href))

    const getAgriColorWarning = (date) => {
        if (date) {
            const now = moment().valueOf()
            const diffDays = moment(date).diff(now, 'days')
            const diffMonths = moment(date).diff(now, 'months')
            if (diffDays <= 15) {
                return 'red'
            }
            if (diffMonths <= 1) {
                return 'orange'
            }
            return ''
        }
        return ''
    }

    const surveys = useMemo(() => {
        return surveysWithStats.filter((s) => s.statusCode === 1).map((s) => {
            const progress = ((s.nbDeclarationsValidated / s.nbDeclarations) * 100) || 0
            const tooltipDDT = s.ddtLastExportDate ? sieauTooltip(`${i18n.lastExport} : ${getFullDateMini(s.ddtLastExportDate)}`, null, 'bottom') : {}
            const tooltipAgency = s.agencyLastExportDate ? sieauTooltip(`${i18n.lastExport} : ${getFullDateMini(s.agencyLastExportDate)}`, null, 'bottom') : {}
            return {
                ...s,
                year: { value: s.year },
                name: { value: s.name },
                advancement: { value: (
                    <div style={{ padding: '3px' }}>
                        <div>{`${i18n.periode}: ${getDate(s.startDate)} - ${getDate(s.endDate)}`}</div>
                        <div>{`${i18n.finalizedDeclarations}: ${s.nbDeclarationsValidated} / ${s.nbDeclarations}`}</div>
                    </div>
                ) },
                endDate: { value: getDate(s.endDate), classNameColor: progress !== 100 ? getAgriColorWarning(s.endDate) : '' },
                ddtLimitDate: {
                    value: <span {...tooltipDDT}>{getDate(s.ddtLimitDate)}</span>,
                    classNameColor: !s.ddtLastExportDate ? getAgriColorWarning(s.ddtLimitDate) : '',
                },
                agencyLimitDate: {
                    value: <span {...tooltipAgency}>{getDate(s.agencyLimitDate)}</span>,
                    classNameColor: !s.agencyLastExportDate ? getAgriColorWarning(s.agencyLimitDate) : '',
                },
            }
        })
    }, [surveysWithStats])

    return (
        <NewTable
            rows={surveys}
            headers={[
                { key: 'icon', value: <Icon>edit_calendar</Icon>, tooltip: i18n.surveys, onClick: () => redirect('/planning/surveys') },
                'year',
                'name',
                'advancement',
                'endDate',
                'ddtLimitDate',
                'agencyLimitDate',
            ]}
            onClickRow={(s) => redirect(`/survey/${s.idSurvey}`)}
        />
    )
}

export default CurrentSurveysPanel