/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import DtoSandreCode from '../../../referencial/dto/DtoSandreCode'
import { arrayOf } from '../../../utils/StoreUtils'
import { getSandreLabel } from '../../../utils/StringUtil'
import StationDescriptionTable from './StationDescriptionTable'
import NumberField from 'components/forms/NumberField'
import { Card, CardContent, Grid2 } from '@mui/material'
import Select from 'components/forms/Select'
import useSandreList from 'utils/customHook/useSandreList'

const headers = ['projection', 'x', 'y', 'z', 'precision']

const LocationDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const projectionList = useSandreList(SANDRE.PROJECTION)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Select
                            options={projectionList}
                            label={i18n.projection}
                            value={stateElement.projection}
                            onChange={v => onChangeElement({ projection: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.x}
                            title={i18n.x}
                            onChange={v => onChangeElement({ x: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.y}
                            title={i18n.y}
                            onChange={v => onChangeElement({ y: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.z}
                            title={i18n.z}
                            onChange={v => onChangeElement({ z: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.precision}
                            title={i18n.precision}
                            onChange={v => onChangeElement({ precision: v })}
                            floatValue
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

LocationDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const StationLocationPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_locations'
            formatFunction={c => ({
                ...c,
                projection: getSandreLabel(sandreCodes, SANDRE.PROJECTION, c.projection),
            })}
            titles={{
                title: i18n.otherLocations,
                edit: i18n.editLocation,
                new: i18n.newLocation,
            }}
            headers={headers}
            dialogContent={LocationDialogContent}
        />
    )
}

StationLocationPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    sandreCodes: arrayOf(DtoSandreCode),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
    setPopup: PropTypes.func,
}

export default StationLocationPanel