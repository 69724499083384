import React, { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Table from '../../../../components/datatable/Table'
import i18n from 'simple-react-i18n'
import Card from '../../../../components/card/Card'
import Input from '../../../../components/forms/Input'
import ParameterAction from '../actions/ParameterAction'
import ParameterGroupDto from '../dto/ParameterGroupDto'
import ParameterGroupLinkDto from '../dto/ParameterGroupLinkDto'
import ProgressCard from '../../../../components/card/ProgressCard'
import ParameterDto from '../dto/ParameterDto'
import { PATH_REFERENCIAL, PATH_REFERENCIAL_PARAMETER, PATH_REFERENCIAL_PARAMETERS_GROUP_NEW } from 'home/constants/RouteConstants'
import useTitle from 'utils/customHook/useTitle'
import useActions from 'utils/customHook/useActions'
import { checkMandatoryFields } from 'utils/FormUtils'
import { push } from '@lagunovsky/redux-react-router'
import { Button, Grid } from '@mui/material'
import SelectParameter from 'campaign/components/campaignDashboard/quality/SelectParameter'
import { DialogActionsMUI, DialogContentMUI, DialogMUI, DialogTitleMUI } from 'components/styled/Dialog'
import Icon from 'components/icon/Icon'
import Textarea from 'components/forms/Textarea'
import ReferencialAction from 'referencial/action/ReferencialAction'
import Select from 'components/forms/Select'
import ToastrAction from 'toastr/actions/ToastrAction'
import { hasValue } from 'utils/NumberUtil'
import useProgressDispatch from 'utils/customHook/useProgressDispatch'
import Checkbox from 'components/forms/Checkbox'
import WaitAction from 'wait/WaitAction'
import { useParams } from 'react-router'

const PARAMETER_GROUP_HEADERS = ['id', 'name', 'definition']

const ParameterGroupApp = () => {
    const { code } = useParams()
    const {
        parameterGroupLinks,
        parameterGroups,
        parameters,
        referencialStatus,
    } = useSelector(store => ({
        parameterGroupLinks: store.ParameterReducer.parameterGroupLinks,
        parameterGroups: store.ParameterReducer.parameterGroups,
        parameters: store.ParameterReducer.parameters,
        referencialStatus: store.ReferencialReducer.referencialStatus,
    }), shallowEqual)

    const isNew = code === 'new'

    const [group, setGroup] = useState({})

    const [classParameter, setClassParameter] = useState([])
    const [usageParameter, setUsageParameter] = useState([])
    const [tempClassParameter, setTempClassParameter] = useState([])
    const [tempUsageParameter, setTempUsageParameter] = useState([])
    const [selectClassParameter, setSelectClassParameter] = useState(false)
    const [selectUsageParameter, setSelectUsageParameter] = useState(false)

    const [editMode, setEditMode] = useState(isNew)
    const [isClassGroup, setIsClassGroup] = useState(isNew)

    const dispatch = useDispatch()

    useEffect(() => {
        if (!parameterGroups.length) {
            dispatch(ParameterAction.fetchParameterGroups())
        }
        if (!parameterGroupLinks.length) {
            dispatch(ParameterAction.fetchParameterGroupLinks())
        }
        if (!parameters.length) {
            dispatch(ParameterAction.fetchParameters())
        }
        if (!referencialStatus.length) {
            dispatch(ReferencialAction.fetchReferencialStatus())
        }
    }, [])

    const { isLoaded, progress } = useProgressDispatch(() => {
        const promises = !parameterGroups.length ? [ParameterAction.fetchParameterGroups] : []
        const promisesGroupLinks = !parameterGroupLinks.length ? [...promises, ParameterAction.fetchParameterGroupLinks] : promises
        const promisesParameters = !parameters.length ? [...promisesGroupLinks, ParameterAction.fetchParameters] : promisesGroupLinks
        const promisesStatus = !referencialStatus.length ? [...promisesParameters, ReferencialAction.fetchReferencialStatus] : promisesParameters
        return promisesStatus.map(p => dispatch(p()))
    }, [])

    useTitle(() => {
        if (isNew) {
            return [
                {
                    title: i18n.referencials,
                    href: PATH_REFERENCIAL,
                },
                {
                    title: i18n.parameters,
                    href: PATH_REFERENCIAL_PARAMETER,
                },
                {
                    title: i18n.new,
                    href: PATH_REFERENCIAL_PARAMETERS_GROUP_NEW,
                },
            ]
        }
        return [
            {
                title: i18n.referencials,
                href: PATH_REFERENCIAL,
            },
            {
                title: i18n.parameters,
                href: PATH_REFERENCIAL_PARAMETER,
            },
            {
                title: parameterGroups.find(p => p.code === parseInt(code))?.name || '',
                href: `referencial/parameterGroup/${code}`,
            },
        ]
    }, [code, parameterGroups])

    const defaultParameterGroup = useMemo(() => parameterGroups.find(param => param.code === parseInt(code)) || {}, [code, parameterGroups])

    useEffect(() => {
        if (code === 'new') {
            setGroup({ name: i18n.newGroup })
        } else {
            setGroup(defaultParameterGroup)
        }
    }, [code, defaultParameterGroup])

    const setGroupParameters = () => {
        const parametersClassGroups = parameterGroupLinks.filter(groupLinks => (groupLinks.classGroupCode && parseInt(code) === groupLinks.classGroupCode))
        const parametersUsageGroups = parameterGroupLinks.filter(groupLinks =>
            (parseInt(code) === groupLinks?.usageGroupCode) ||
            (parseInt(code) === groupLinks?.usageGroupCode2) ||
            (parseInt(code) === groupLinks?.usageGroupCode3) ||
            (parseInt(code) === groupLinks?.usageGroupCode4) ||
            (parseInt(code) === groupLinks?.usageGroupCode5),
        )
        const classParameters = parameters.filter(param => parametersClassGroups.find(pg => pg.parameterCode === param.code))
        const usageParameters = parameters.filter(param => parametersUsageGroups.find(pg => pg.parameterCode === param.code))
        setClassParameter(classParameters.map(p => ({ ...p, id: p.code })))
        setTempClassParameter(classParameters.map(cp => ({ parameterCode: cp.code, fractionCode: cp.fractionCode, placeCode: cp.placeCode, supportCode: cp.supportCode, unitCode: cp.unitCode })))
        setUsageParameter(usageParameters.map(p => ({ ...p, id: p.code })))
        setTempUsageParameter(usageParameters.map(cp => ({ parameterCode: cp.code, fractionCode: cp.fractionCode, placeCode: cp.placeCode, supportCode: cp.supportCode, unitCode: cp.unitCode })))
    }

    const mandatoryFileds = useMemo(() => [
        { field: 'name', i18n: 'name' },
    ], [])

    const checkedClassGroup = useMemo(() => {
        const classGroupsCodes = parameterGroupLinks.filter(p => p.classGroupCode).map(p => p.classGroupCode)
        return !isNew ? parameterGroups.some(p => classGroupsCodes.includes(p.code)) : isClassGroup
    }, [isClassGroup, isNew, parameterGroupLinks, parameterGroups])

    useActions(() => {
        if (isNew) {
            return {
                save: () => {
                    checkMandatoryFields(mandatoryFileds, group, () => {
                        if ((checkedClassGroup && !classParameter.length) || (!checkedClassGroup && !usageParameter.length)) {
                            dispatch(ToastrAction.warning(i18n.pleaseEnterAtLeastOneParameter))
                        } else {
                            dispatch(WaitAction.waitStart())
                            dispatch(ParameterAction.createParameterGroup({
                                parameterGroup: { ...group, status: hasValue(group.status) && `${group.status}` },
                                classParameterIds: classParameter.map(({ id }) => id),
                                usageParameterIds: usageParameter.map(({ id }) => id),
                            })).then(newCode => {
                                dispatch(WaitAction.waitStop())
                                dispatch(push(`/referencial/parameterGroup/${newCode}`))
                            })
                        }
                    })
                },
                cancel: () => {
                    dispatch(push(PATH_REFERENCIAL_PARAMETER))
                },
            }
        }
        if (editMode) {
            return {
                delete: () => {
                    dispatch(WaitAction.waitStart())
                    dispatch(ParameterAction.deleteParameterGroupWithLink(code)).then(() => {
                        dispatch(WaitAction.waitStop())
                        dispatch(push(PATH_REFERENCIAL_PARAMETER))
                    })
                },
                save: () => {
                    checkMandatoryFields(mandatoryFileds, group, () => {
                        if ((checkedClassGroup && !classParameter.length) || (!checkedClassGroup && !usageParameter.length)) {
                            dispatch(ToastrAction.warning(i18n.pleaseEnterAtLeastOneParameter))
                        } else {
                            dispatch(WaitAction.waitStart())
                            dispatch(ParameterAction.updateParameterGroup({
                                parameterGroup: { ...group, status: hasValue(group.status) && `${group.status}` },
                                classParameterIds: classParameter.map(({ id }) => id),
                                usageParameterIds: usageParameter.map(({ id }) => id),
                            })).then(() => dispatch(WaitAction.waitStop()))
                            setEditMode(false)
                        }
                    })
                },
                cancel: () => {
                    setGroup(defaultParameterGroup)
                    setGroupParameters()
                    setEditMode(false)
                },
            }
        }
        return {
            edit: () => {
                setEditMode(true)
            },
        }
    }, [code, classParameter, usageParameter, group, isNew, editMode, defaultParameterGroup, checkedClassGroup])

    useEffect(() => {
        setGroupParameters()
    }, [parameters, parameterGroupLinks, code])

    const onCloseClassGroupDialog = () => {
        setSelectClassParameter(false)
        setTempClassParameter([])
    }

    const onCloseUsageGroupDialog = () => {
        setSelectUsageParameter(false)
        setTempUsageParameter([])
    }

    return isLoaded ? (
        <Grid container sx={{ padding: '1rem' }}>
            <Grid item xs={12}>
                <Card title={group.name} round>
                    <Grid container alignItems='center' sx={{ padding: '1rem' }}>
                        <Grid item xs={4} sx={{ paddingRight: '0.5rem' }}>
                            <Input
                                title={i18n.nameGroup}
                                value={group.name}
                                disabled={!editMode}
                                onChange={value => setGroup({ ...group, name: value })}
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ paddingLeft: '0.5rem' }}>
                            <Select
                                options={referencialStatus}
                                label={i18n.status}
                                onChange={value => setGroup({ ...group, status: value })}
                                value={group.status}
                                disabled={!editMode}
                                nullLabel='&nbsp;'
                            />
                        </Grid>
                        <Grid item xs={4} sx={{ padding: '6px 0 0 15px' }}>
                            <Checkbox
                                label={i18n.classGroup}
                                checked={checkedClassGroup}
                                onChange={() => {
                                    setIsClassGroup(true)
                                    setClassParameter([])
                                    setUsageParameter([])
                                }}
                                disabled={!isNew}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Textarea
                                value={group.description}
                                title={i18n.description}
                                onChange={value => setGroup({ ...group, description: value })}
                                disabled={!editMode}
                                height='15vh'
                            />
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid container item xs={12} sx={{ marginTop: '1rem' }} justifyContent='space-between'>
                {checkedClassGroup ? (
                    <Grid item xs={12}>
                        <Table
                            title={i18n.classGroupParameters}
                            data={classParameter}
                            type={{ headers: PARAMETER_GROUP_HEADERS }}
                            padding
                            sortable
                            initialSort={{ column: 'id', sort: 'asc' }}
                            link='referencial/parameter'
                            round
                            actions={!!editMode && [{
                                iconName: 'edit',
                                color: 'white',
                                onClick: () => setSelectClassParameter(true),
                            }]}
                        />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Table
                            title={i18n.usageGroupParameters}
                            data={usageParameter}
                            type={{ headers: PARAMETER_GROUP_HEADERS }}
                            padding
                            sortable
                            initialSort={{ column: 'id', sort: 'asc' }}
                            link='referencial/parameter'
                            round
                            actions={!!editMode && [{
                                iconName: 'edit',
                                color: 'white',
                                onClick: () => setSelectUsageParameter(true),
                            }]}
                        />
                    </Grid>
                )}
                <DialogMUI
                    maxWidth='xl'
                    fullWidth
                    open={selectClassParameter}
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.classGroup}
                        <Icon
                            style={{ color: 'white' }}
                            size='small'
                            icon='close'
                            onClick={onCloseClassGroupDialog}
                        />
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        <div style={{ height: '70vh' }}>
                            <SelectParameter
                                selectedParameters={tempClassParameter}
                                onChange={({ selectedParameters }) => setTempClassParameter(selectedParameters)}
                            />
                        </div>
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Button
                            onClick={() => {
                                const classParameters = parameters.filter(p => tempClassParameter.some(cp => cp.parameterCode === p.code)).map(p => ({ ...p, id: p.code }))
                                setClassParameter(classParameters)
                                setSelectClassParameter(false)
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.validate}
                        </Button>
                    </DialogActionsMUI>
                </DialogMUI>
                <DialogMUI
                    maxWidth='xl'
                    fullWidth
                    open={selectUsageParameter}
                >
                    <DialogTitleMUI style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {i18n.usageGroup}
                        <Icon
                            style={{ color: 'white' }}
                            size='small'
                            icon='close'
                            onClick={onCloseUsageGroupDialog}
                        />
                    </DialogTitleMUI>
                    <DialogContentMUI>
                        <div style={{ height: '70vh' }}>
                            <SelectParameter
                                selectedParameters={tempUsageParameter}
                                onChange={({ selectedParameters }) => setTempUsageParameter(selectedParameters)}
                            />
                        </div>
                    </DialogContentMUI>
                    <DialogActionsMUI>
                        <Button
                            onClick={() => {
                                const usageParameters = parameters.filter(p => tempUsageParameter.some(cp => cp.parameterCode === p.code)).map(p => ({ ...p, id: p.code }))
                                setUsageParameter(usageParameters)
                                setSelectUsageParameter(false)
                            }}
                            variant='contained'
                            color='primary'
                        >
                            {i18n.validate}
                        </Button>
                    </DialogActionsMUI>
                </DialogMUI>
            </Grid>
        </Grid>
    ) : <ProgressCard progress={progress} />
}

ParameterGroupApp.propTypes = {
    parameterGroupLinks: PropTypes.arrayOf(PropTypes.instanceOf(ParameterGroupLinkDto)),
    parameterGroups: PropTypes.arrayOf(PropTypes.instanceOf(ParameterGroupDto)),
    parameters: PropTypes.arrayOf(PropTypes.instanceOf(ParameterDto)),
    fetchParameterGroupLinks: PropTypes.func,
    fetchParameterGroups: PropTypes.func,
    forceFetch: PropTypes.func,
    fetchParameters: PropTypes.func,
}

export default ParameterGroupApp