import fetch from 'isomorphic-fetch'
import i18n from 'simple-react-i18n'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getValidStatsByModules } from 'utils/StatisticUtil'
import WaitAction from 'wait/WaitAction'
import ApplicationConf from '../../../../conf/ApplicationConf'
import LogAction from '../../../../log/actions/LogAction'
import ContactAction from '../../../../referencial/components/contact/actions/ContactAction'
import { checkAuth, checkError, genericPromise2, getAuthorization, getJson } from '../../../../utils/ActionUtils'
import { userActions } from '../reducers/UserReducer'

const UserAction = {
    promiseUsers() {
        return fetch(ApplicationConf.user.userPath(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchUsers() {
        return (dispatch) => {
            return UserAction.promiseUsers()
                .then(json => {
                    dispatch(userActions.receiveUsers(json))
                })
        }
    },

    promiseUsersLight() {
        return fetch(ApplicationConf.user.usersLight(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchUsersLight() {
        return (dispatch) => {
            return UserAction.promiseUsersLight()
                .then(json => {
                    dispatch(userActions.receiveUsersLight(json))
                })
        }
    },

    fetchUser(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.get(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveUser(json))
                    dispatch(UserAction.fetchUserStatistics(json.login))
                    dispatch(UserAction.fetchUserKeyfigure(json.login))
                    dispatch(UserAction.fetchUserStationStatistic(json.login))
                    dispatch(UserAction.fetchUserCmsStatistic(json.login))
                    dispatch(UserAction.fetchUserUsageStatistic(json.login))
                    dispatch(UserAction.fetchUserStations(json.login))
                    dispatch(UserAction.fetchUserHabilitations(json.login))
                })
        }
    },

    promiseApplicativeUsers() {
        return fetch(ApplicationConf.user.applicativeUsers(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
    },
    fetchApplicativeUsers() {
        return (dispatch) => {
            return UserAction.promiseApplicativeUsers()
                .then(json => {
                    dispatch(userActions.receiveApplicativeUsers(json))
                })
        }
    },

    fetchAllUsers() {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return Promise.all([ UserAction.promiseUsers(), UserAction.promiseApplicativeUsers() ])
                .then(jsonTab => {
                    dispatch(userActions.receiveUsers(jsonTab[0]))
                    dispatch(userActions.receiveApplicativeUsers(jsonTab[1]))
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    fetchAllMobileNotifications(forceLoad = false) {
        return (dispatch, getState) => {
            const { mobileNotifications } = getState().UserReducer
            if (mobileNotifications.length && !forceLoad) {
                return Promise.resolve()
            }
            return fetch(ApplicationConf.user.getAllMobileNotifications(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveAllMobileNotifications(json))
                })
        }
    },
    postNotif: (notificationContent, logins, app) => dispatch => fetch(ApplicationConf.user.sendNotif(), {
        method: 'POST',
        headers: getAuthorization(),
        body: JSON.stringify({ notificationContent, logins, app }),
    })
        .then(checkAuth)
        .then(getJson)
        .then(json => {
            if (json.notifSend) {
                dispatch(ToastrAction.success(i18n.contentSuccessfulyShared))
            } else {
                dispatch(ToastrAction.error(i18n.contentCannotBeShared))
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(i18n.contentCannotBeShared + i18n.event + err))
            dispatch(ToastrAction.error(i18n.contentCannotBeShared))
        }),

    fetchUserKeyfigure(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getKeyFigures(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveUserKeyFigures(json))
                })
        }
    },

    fetchUserStatistics(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getStatistics(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveUserStatistic(json))
                })
        }
    },

    fetchAllUserStatistics() {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getAllStatistics(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveAllUserStatistic(getValidStatsByModules(json)))
                })
        }
    },

    fetchUserStationStatistic(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getStationStatistics(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveUserStationStatistics(json))
                })
        }
    },

    fetchAllUserStationStatistic() {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getAllStationStatistics(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveAllUserStationStatistics(json))
                })
        }
    },

    fetchUserCmsStatistic(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getCmsStatistics(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveUserCmsStatistics(json))
                })
        }
    },

    fetchAllUserCmsStatistic() {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getAllCmsStatistics(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveAllUserCmsStatistics(json))
                })
        }
    },

    fetchUserUsageStatistic(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getUsageStatistics(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveUserUsageStatistics(json))
                })
        }
    },

    fetchAllUserUsageStatistic() {
        return (dispatch) => {
            return fetch(ApplicationConf.user.getAllUsageStatistics(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveAllUserUsageStatistics(json))
                })
        }
    },

    fetchUserStations(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.userStations(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveUserStations(json))
                })
        }
    },

    fetchUsersOfFilter(moduleFilter, listCode) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.usersOfFilter(moduleFilter, listCode), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveFilterUsers(json))
                })
        }
    },
    updateUserStations(login, userStations) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.userStations(login), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(userStations),
            })
                .then(checkAuth)
                .then(getJson)
                .then(() => {
                    dispatch(UserAction.fetchUserStations(login))
                })
        }
    },
    updateUser(user) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.put(), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(user),
            }).then(() => {
                dispatch(UserAction.fetchUser(user.login))
                dispatch(ContactAction.fetchContacts(true))
                dispatch(WaitAction.waitStop())
                dispatch(ToastrAction.success(i18n.saveSuccess))
            })
        }
    },

    fetchUserHabilitations(login) {
        return (dispatch) => {
            return fetch(ApplicationConf.user.habilitations(login), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveUserHabilitations(json))
                }).then(() => {
                    dispatch(WaitAction.waitStop())
                })
        }
    },

    updateUserHabilitations(login, userHabilitations) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.habilitations(login), {
                method: 'PUT',
                headers: getAuthorization(),
                body: JSON.stringify(userHabilitations),
            })
                .then(checkAuth)
                .then(getJson)
                .then(() => {
                    dispatch(UserAction.fetchUserHabilitations(login))
                })
        }
    },

    fetchApplicationHabilitations: () => dispatch => {
        return fetch(ApplicationConf.user.applicationHabilitations(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(userActions.receiveAllApplicationHabilitation(json))
            })
    },

    promiseBookmarks() {
        return fetch(ApplicationConf.user.getBookmarks(), {
            method: 'GET',
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
    },

    fetchBookmarks: () => dispatch => {
        return UserAction.promiseBookmarks()
            .then(json => {
                dispatch(userActions.receiveAllBookmarks(json))
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.bookmarks} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.bookmarks))
            })
    },

    createBookmark(bookmark) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.setBookmark(), {
                method: 'POST',
                headers: getAuthorization(),
                body: JSON.stringify(bookmark),
            }).then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(WaitAction.waitStop())
                    return dispatch(UserAction.fetchBookmarks())
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.bookmarks} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.bookmarks))
                })
        }
    },

    deleteBookmark(bookmark) {
        return dispatch => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.setBookmark(), {
                method: 'DELETE',
                headers: getAuthorization(),
                body: JSON.stringify(bookmark),
            }).then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then(() => {
                    dispatch(WaitAction.waitStop())
                    return dispatch(UserAction.fetchBookmarks())
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.updateError + i18n.bookmarks} : ${err}`))
                    dispatch(ToastrAction.error(i18n.updateError + i18n.bookmarks))
                })
        }
    },

    fetchUserKeyFigures() {
        return (dispatch) => {
            return fetch(ApplicationConf.keyfigures.user(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveUserKeyFigures(json))
                })
        }
    },

    addUser(user, dontSendMail = false) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.userPath(), {
                method: 'POST',
                body: JSON.stringify({
                    data: user,
                    dontSendMail,
                }),
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(response => {
                    if (response.status && response.status === 200) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.success(i18n.addUserSuccessMessage))
                        return true
                    } throw new Error(response.error)
                })
                .catch((err) => {
                    const error = parseInt(err.toString().split(': ')[1])
                    if (error && error === 409) {
                        dispatch(WaitAction.waitStop())
                        dispatch(ToastrAction.warning(i18n.identifierAlreadyTaken))
                    } else if (error && error !== 409) {
                        dispatch(WaitAction.waitStop())
                        dispatch(LogAction.logError(`${i18n.addUserErrorMessage} : ${error}`))
                        dispatch(ToastrAction.error(i18n.addUserErrorMessage))
                    }
                })
        }
    },

    duplicateUser: (login, duplicateUser, dontSendMail = false) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.user.duplicateUser(login), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify({
                duplicateUser,
                dontSendMail,
            }),
        })
            .then((json) => checkAuth(json, {
                409: () => dispatch(ToastrAction.warning(i18n.identifierAlreadyTaken)),
            }))
            .then(getJson)
            .then(checkError)
            .then(() => {
                dispatch(WaitAction.waitStop())
                dispatch(ContactAction.fetchContacts(true))
                dispatch(ToastrAction.success(i18n.elementCreateSuccess))
            })
            .catch(err => {
                dispatch(WaitAction.waitStop())
                dispatch(LogAction.logError(`${i18n.duplicate} ${i18n.user} ${err}`))
                return err
            })
    },

    deleteUser(login) {
        return (dispatch) => {
            dispatch(WaitAction.waitStart())
            return fetch(ApplicationConf.user.delete(login), {
                method: 'DELETE',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(() => {
                    dispatch(WaitAction.waitStop())
                    dispatch(ToastrAction.success(i18n.deleteUserSuccessMessage))
                })
                .catch((err) => {
                    dispatch(WaitAction.waitStop())
                    dispatch(LogAction.logError(`${i18n.addUserErrorMessage} : ${err}`))
                    dispatch(ToastrAction.error(i18n.addUserErrorMessage))
                })
        }
    },

    fetchUsersThemesAssignment: () => dispatch => {
        return fetch(ApplicationConf.user.assignedThemesLayers(), {
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(userActions.receiveUsersThemesAssignement(json))
            })
    },

    fetchUserThemesAssignment: () => dispatch => {
        return fetch(ApplicationConf.user.themeAssign(), {
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(userActions.receiveUserThemesAssignement(json))
            })
    },

    assignThemeToUsers: (themeId, logins = []) => dispatch => {
        dispatch(WaitAction.waitStart())
        return fetch(ApplicationConf.user.themeAssign(), {
            method: 'POST',
            body: JSON.stringify({ logins, themeId }),
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                dispatch(WaitAction.waitStop())
                dispatch(UserAction.fetchUsersThemesAssignment())
                if (json === 0) {
                    dispatch(ToastrAction.warning(i18n.noLayersLinkToTheme))
                } else {
                    dispatch(ToastrAction.success(i18n.AccessThemeWellModified))
                }
            })
            .catch(() => dispatch(WaitAction.waitStop()))
    },

    fetchUserView() {
        return (dispatch) => {
            return fetch(ApplicationConf.user.userView(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveUserView(json))
                })
        }
    },
    updateUserView: (data) => dispatch => {
        return fetch(ApplicationConf.user.userView(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(data),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.error) {
                    dispatch(ToastrAction.error(i18n.updateError + i18n.homepage))
                } else {
                    dispatch(UserAction.fetchUserView())
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.homepage} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.homepage))
            })
    },

    fetchGenericUserView() {
        return (dispatch) => {
            return fetch(ApplicationConf.user.genericUserView(), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(json => {
                    dispatch(userActions.receiveGenericUserView(json))
                })
        }
    },

    updateGenericUserView: (data, callback = () => { }) => dispatch => {
        return fetch(ApplicationConf.user.genericUserView(), {
            method: 'POST',
            headers: getAuthorization(),
            body: JSON.stringify(data),
        })
            .then(checkAuth)
            .then(getJson)
            .then(json => {
                if (json.error) {
                    dispatch(ToastrAction.error(i18n.updateError + i18n.homepage))
                } else {
                    dispatch(UserAction.fetchGenericUserView())
                    callback()
                    dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
                }
            })
            .catch(err => {
                dispatch(LogAction.logError(`${i18n.updateError + i18n.homepage} : ${err}`))
                dispatch(ToastrAction.error(i18n.updateError + i18n.homepage))
            })
    },

    getFilterParamList: (request) => dispatch => genericPromise2(ApplicationConf.user.filterParamRequest(), { body: { request }, method: 'POST' }, {
        500: () => {
            dispatch(ToastrAction.error(i18n.nonConformRequest))
            dispatch(LogAction.logError(i18n.nonConformRequest))
        },
        405: () => {
            dispatch(ToastrAction.error(i18n.nonConformRequest))
            dispatch(LogAction.logError(i18n.nonConformRequest))
        },
        401: () => {
            dispatch(ToastrAction.error(i18n.unauthorized))
            dispatch(LogAction.logError(i18n.unauthorized))
        },
    }),

    promiseUserStationHasRights: (stationType, code) => genericPromise2(ApplicationConf.user.userStationHasRights(stationType, code)),

    fetchUserStationHasRights(stationType, code) {
        return (dispatch) => {
            return UserAction.promiseUserStationHasRights(stationType, code)
                .then((json = {}) => {
                    dispatch(userActions.receiveStationRights(json))
                    return json
                })
                .catch(err => {
                    dispatch(LogAction.logError(`${i18n.fetchError + i18n.station} : ${err}`))
                    dispatch(ToastrAction.error(i18n.fetchError + i18n.station))
                })
        }
    },
}

export default UserAction
