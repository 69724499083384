/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../../../hydrometry/dto/DtoHydrometricStation'
import DtoPiezometer from '../../../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../../../referencial/constants/ReferencialConstants'
import { getLabel } from '../../../../../utils/StoreUtils'
import { getSandreLabel } from '../../../../../utils/StringUtil'
import DtoInstallation from '../../../../dto/installation/DtoInstallation'
import DtoBoreholeCasing from '../dto/DtoBoreholeCasing'
import StationDescriptionTable from 'station/components/link/StationDescriptionTable'
import { Card, CardContent, Grid2 } from '@mui/material'
import NumberField from 'components/forms/NumberField'
import Select from 'components/forms/Select'
import useSandreList from 'utils/customHook/useSandreList'
import Checkbox from 'components/forms/Checkbox'

const headers = new DtoBoreholeCasing({}).headers

const CasingDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const {
        aquifers,
    } = useSelector(store => ({
        aquifers: store.AquiferReducer.aquifers,
    }), shallowEqual)

    const materialList = useSandreList(SANDRE.CASING_MATERIAL)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1} alignItems='center'>
                    <Grid2 container size={12}>
                        <Grid2 size={6}>
                            <NumberField
                                value={stateElement.order}
                                title={i18n.order}
                                onChange={v => onChangeElement({ order: v })}
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.startDepth}
                            title={i18n.startDepth}
                            onChange={v => onChangeElement({ startDepth: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.endDepth}
                            title={i18n.endDepth}
                            onChange={v => onChangeElement({ endDepth: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 container size={12}>
                        <Grid2 size={6}>
                            <Select
                                value={stateElement.aquiferCode}
                                label={i18n.aquifer}
                                options={aquifers}
                                onChange={v => onChangeElement({ aquiferCode: v })}
                            />
                        </Grid2>
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.aquiferRoof}
                            title={i18n.aquiferRoof}
                            onChange={v => onChangeElement({ aquiferRoof: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.aquiferFloor}
                            title={i18n.aquiferFloor}
                            onChange={v => onChangeElement({ aquiferFloor: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.internalCasingDiameter}
                            title={i18n.internalCasingDiameter}
                            onChange={v => onChangeElement({ internalCasingDiameter: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.externalCasingDiameter}
                            title={i18n.externalCasingDiameter}
                            onChange={v => onChangeElement({ externalCasingDiameter: v })}
                            floatValue
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Select value={stateElement.materialCode}
                            label={i18n.casingMaterial}
                            options={materialList}
                            integerValue
                            onChange={v => onChangeElement({ materialCode: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={stateElement.strainers}
                            label={i18n.strainers}
                            onChange={v => onChangeElement({ strainers: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.column}
                            title={i18n.column}
                            onChange={v => onChangeElement({ column: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <Checkbox
                            checked={stateElement.cementing}
                            label={i18n.cementing}
                            onChange={v => onChangeElement({ cementing: v })}
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

CasingDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const BoreholeCasingTable = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        aquifers,
        sandreCodes,
    } = useSelector(store => ({
        aquifers: store.AquiferReducer.aquifers,
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_casings'
            formatFunction={c => ({
                ...c,
                cementing: c.cementing ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
                strainers: c.strainers ? <i className='material-icons tiny table-checkbox'>check_box</i> : null,
                diameterSymbol: c.internalCasingDiameter,
                depth: c.startDepth,
                aquifer: getLabel(aquifers, c.aquiferCode),
                casingMaterial: getSandreLabel(sandreCodes, SANDRE.CASING_MATERIAL, c.materialCode),
            })}
            titles={{
                title: i18n.casings,
                edit: i18n.casings,
                new: i18n.casings,
            }}
            headers={headers}
            dialogContent={CasingDialogContent}
        />
    )
}

BoreholeCasingTable.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default BoreholeCasingTable