import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { getStationTypeFromUserConstants } from '../../../../../utils/StationUtils'
import UserAction from '../../actions/UserAction'
import AdministrationAction from '../../../../actions/AdministrationAction'
import WaitAction from 'wait/WaitAction'
import {
    ALL,
    CATCHMENT,
    DISTRIBUTION,
    HYDRO,
    INSTALLATION,
    MAT, PERIMETERS, PGSSE, PIEZO,
    PLUVIO,
    PRODUCTION,
    QUALITO, RESOURCE, SITUATION,
} from '../../constants/ApplicationHabilitationConstants'
import {
    AGRI,
    AQUADB,
    OBSERVATORY,
    QAPTYS,
    SITUATION_DIAGNOSTIC_FORAGE,
} from '../../constants/HabilitationConstants'
import DtoUserHabilitation from '../../dto/DtoUserHabilitation'
import { getI18nOrLabel } from 'utils/StringUtil'
import { AccordionDetailsMUI, AccordionMUI, AccordionSummaryMUI } from 'components/styled/Accordions'
import i18n from 'simple-react-i18n'
import Checkbox from 'components/forms/Checkbox'
import { Grid } from '@mui/material'
import { StyledFieldSet, StyledLegend } from 'components/StyledElements'
import { NAME_LOGIN_PICTURE } from 'administration/constants/AdministrationConstants'
import useTitle from '../../../../../utils/customHook/useTitle'

const disabledHabilitations = [SITUATION_DIAGNOSTIC_FORAGE]

const ModuleCard = ({
    moduleType = '',
}) => {
    const dispatch = useDispatch()
    const {
        applicationHabilitations,
        userHabilitations,
        user,
        applicativeUsers,
        contacts,
    } = useSelector(store => ({
        applicationHabilitations: store.UserReducer.applicationHabilitations,
        userHabilitations: store.UserReducer.userHabilitations,
        user: store.UserReducer.user,
        applicativeUsers: store.UserReducer.applicativeUsers,
        contacts: store.ContactReducer.contacts,
    }), shallowEqual)

    useTitle(() => [{
        title: i18n.administration,
        href: 'administration',
    },
    {
        title: i18n.users,
        href: 'administration/user',
    },
    {
        title: user.login,
        href: `administration/user/${user.login}`,
    },
    {
        title: i18n.modules,
        href: `administration/user/${user.login}/modules`,
    }], [user.login])

    const habilitations = applicationHabilitations.filter(hab => hab.habilitationType === moduleType)
    const filteredHabilitations = habilitations.filter(hab => !!hab.title && !hab.parameter.includes(`_${ALL}`))

    const hasHabilitation = hab => userHabilitations.some(h => h.habilitation === hab)

    const hasModuleHabilitation = hasHabilitation(moduleType)

    const isAllCheck = filteredHabilitations.every(hab => hasHabilitation(hab.parameter))


    const onChangeApplicativeUser = () => {
        const contact = contacts.find(c => c.id === user.contactCode)
        const newUser = {
            ...user,
            contactName: contact?.name || '',
        }

        dispatch(UserAction.updateUser(newUser)).then(() => {
            dispatch(AdministrationAction.fetchPublicPicture(newUser.login, NAME_LOGIN_PICTURE))
            dispatch(WaitAction.waitStart())
            dispatch(UserAction.fetchUsers()).then(() => {
                dispatch(WaitAction.waitStop())
            })
        })
    }

    const onChange = (habilitation, isCheck) => {
        const newHabilitations = isCheck ?
            userHabilitations.filter(h => h.habilitation !== habilitation) :
            [
                ...userHabilitations,
                new DtoUserHabilitation({
                    login: user.login,
                    habilitation,
                }),
            ]

        dispatch(UserAction.updateUserHabilitations(user.login, newHabilitations))
        if (applicativeUsers.find(a => a.login === habilitation)) {
            onChangeApplicativeUser()
        }
    }

    const onChangeAll = () => {
        const filteredUserHabilitations = userHabilitations.filter(hab => !filteredHabilitations.some(h => h.parameter === hab.habilitation))
        const updatedHabilitations = isAllCheck ?
            filteredUserHabilitations :
            [
                ...filteredUserHabilitations,
                ...filteredHabilitations.map(hab => new DtoUserHabilitation({ login: user.login, habilitation: hab.parameter })),
            ]

        dispatch(UserAction.updateUserHabilitations(user.login, updatedHabilitations))
    }

    return (
        <AccordionMUI defaultExpanded={false} className='margin-bottom-1'>
            <AccordionSummaryMUI>
                <span className='bold'>
                    {getI18nOrLabel(getStationTypeFromUserConstants(moduleType))}
                </span>
            </AccordionSummaryMUI>
            <AccordionDetailsMUI>
                <Grid container>
                    <Grid item>
                        <Checkbox
                            label={i18n.moduleAccess}
                            checked={hasModuleHabilitation}
                            onChange={() => onChange(moduleType, hasModuleHabilitation)}
                        />
                    </Grid>
                    {
                        !!filteredHabilitations.length && (
                            <Grid item xs={12}>
                                <StyledFieldSet>
                                    <StyledLegend>{i18n.options}</StyledLegend>
                                    <Grid container>
                                        <Grid container item xs={12} sx={{ paddingBottom: 2 }}>
                                            <Grid item xs={6}>
                                                <Checkbox
                                                    label={i18n.selectAll}
                                                    disabled={!hasModuleHabilitation}
                                                    checked={isAllCheck}
                                                    onChange={onChangeAll}
                                                />
                                            </Grid>
                                        </Grid>
                                        {
                                            filteredHabilitations.map(hab => {
                                                const isCheck = hasHabilitation(hab.parameter)
                                                const isDisabled = disabledHabilitations.includes(hab.parameter)
                                                return (
                                                    <Grid item xs={6}>
                                                        <Checkbox
                                                            label={isDisabled ? `${getI18nOrLabel(hab.title)} (${i18n.unavailable})` : getI18nOrLabel(hab.title)}
                                                            disabled={isDisabled || !hasModuleHabilitation}
                                                            checked={isCheck}
                                                            onChange={() => onChange(hab.parameter, isCheck)}
                                                        />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </StyledFieldSet>
                            </Grid>
                        )
                    }
                </Grid>
            </AccordionDetailsMUI>
        </AccordionMUI>
    )
}

ModuleCard.propTypes = {
    moduleType: PropTypes.oneOf([
        QUALITO,
        PIEZO,
        PLUVIO,
        HYDRO,
        DISTRIBUTION,
        INSTALLATION,
        CATCHMENT,
        PRODUCTION,
        MAT,
        SITUATION,
        AGRI,
        QAPTYS,
        OBSERVATORY,
        AQUADB,
        PGSSE,
        RESOURCE,
    ]),
}

const UserModulesPanel = ({

}) => {
    const dispatch = useDispatch()
    const {
        applicativeUsers,
    } = useSelector(store => ({
        applicativeUsers: store.UserReducer.applicativeUsers,
    }), shallowEqual)

    useEffect(() => {
        if (!applicativeUsers.length) {
            dispatch(UserAction.fetchApplicativeUsers())
        }
    }, [])

    return (
        <>
            <ModuleCard moduleType={QUALITO} />
            <ModuleCard moduleType={PIEZO} />
            <ModuleCard moduleType={PLUVIO} />
            <ModuleCard moduleType={HYDRO} />
            <ModuleCard moduleType={DISTRIBUTION} />
            <ModuleCard moduleType={INSTALLATION} />
            <ModuleCard moduleType={PERIMETERS} />
            <ModuleCard moduleType={CATCHMENT} />
            <ModuleCard moduleType={PRODUCTION} />
            <ModuleCard moduleType={MAT} />
            <ModuleCard moduleType={SITUATION} />
            <ModuleCard moduleType={AGRI} />
            <ModuleCard moduleType={QAPTYS} />
            <ModuleCard moduleType={OBSERVATORY} />
            <ModuleCard moduleType={AQUADB} />
            <ModuleCard moduleType={PGSSE} />
            <ModuleCard moduleType={RESOURCE} />
        </>
    )
}

export default UserModulesPanel
