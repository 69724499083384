import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from '@lagunovsky/redux-react-router'
import ActionComponent from '../../../components/ActionComponent'
import { arrayOf, getMapStateToProps, getPropTypes } from '../../../utils/StoreUtils'
import SieauAction from '../../../components/sieau/SieauAction'
import { isEqual, omit } from 'lodash'
import InstallationAction from '../../actions/InstallationAction'
import ToastrAction from 'toastr/actions/ToastrAction'
import { getSetting, getUser } from '../../../utils/SettingUtils'
import { getStationArrowNav } from '../../../utils/ActionUtils'
import { getLinks } from '../../../utils/StationUtils'
import { downloadURI } from '../../../utils/ExportDataUtil'
import WatershedAction from '../../../referencial/components/watershed/actions/WatershedAction'
import AquiferAction from '../../../referencial/components/aquifers/actions/AquiferAction'
import HydrogeologicalEntityAction from '../../../referencial/components/hydrogeologicalEntity/actions/HydrogeologicalEntityAction'
import ContactDto from '../../../referencial/components/contact/dto/ContactDto'
import DtoInstallation from '../../dto/installation/DtoInstallation'
import DtoHydrogeologicalEntity from '../../../station/dto/DtoHydrogeologicalEntity'
import DtoInstallationTableLine from 'installation/dto/installation/DtoInstallationTableLine'
import DtoAccountHabilitation from 'account/dto/DtoAccountHabilitation'
import GenericInstallationDescription from './GenericInstallationDescription'
import BuildingsPanel from '../installations/components/BuildingsPanel'
import { Grid2 } from '@mui/material'

const storeProps = {
    installation: false,
    cities: false,
    contacts: false,
    watersheds: true,
    sandreCodes: false,
    natures: true,
    aquifers: true,
    fieldModes: true,
    installationEvents: false,
    users: false,
    status: true,
}

class InstallationDescriptionPanel extends ActionComponent {
    constructor(props) {
        super(props)
        this.state = {
            installation: { ...props.installation },
            readMode: true,
        }
    }

    componentDidMount() {
        const { installation, watersheds, aquifers, hydrogeologicalEntities } = this.props
        this.props.fetch(storeProps)
        if (installation.id) {
            this.setState({ installation })
        }
        if (!watersheds.length) {
            this.props.fetchWatersheds()
        }
        if (!aquifers.length) {
            this.props.fetchAquifers()
        }
        if (!hydrogeologicalEntities.length) {
            this.props.fetchHydrogeologicalEntities()
        }

        if (installation.id) {
            this.props.fetchInstallationVisits(installation.id).then(() => {
                if (this.props.onChangeVisit) {
                    this.setState({ readMode: false })
                } else if (this.state.readMode) {
                    this.setReadOnlyMode()
                }
            })
        }

        if (this.props.onChangeVisit) {
            this.setState({ readMode: false })
        } else {
            this.setReadOnlyMode()
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!isEqual(prevProps.installation, this.props.installation)) {
            this.setState({ installation: this.props.installation })
            if (!this.props.onChangeVisit) {
                this.setReadOnlyMode()
            }
        }
    }

    onDelete = () => this.props.push('/installation')

    onSave = (visit) => {
        const { installation } = this.state

        if (this.props.onChangeVisit) {
            return this.props.updateInstallationAndVisit(visit.idVisit,
                {
                    visit,
                    installation,
                },
            ).then(() => this.setReadOnlyMode())
        }

        return this.props.updateInstallation(
            installation,
        ).then(() => this.setReadOnlyMode())
    }

    setEditMode = () => {
        this.setState({ readMode: false })
        const actions = {
            save: () => this.onSave(),
            cancel: () => {
                this.setState({ installation: this.props.installation })
                this.setReadOnlyMode()
            },
            delete: () => this.props.delete('installation', this.props.installation.id, this.onDelete),
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
        }
        if (getUser().consultant === '1') {
            this.setActions(omit(actions, ['save', 'delete']))
        } else {
            this.setActions(actions)
        }
    }

    setReadOnlyMode = () => {
        if (this.props.onChangeVisit) {
            return
        }
        this.setState({ readMode: true })
        const { installationVisits } = this.props
        const visitExport = installationVisits.length ? { export: () => this.createAndDownloadEdition(installationVisits[0]) } : null
        const actions = {
            ...visitExport,
            links: getLinks(this.props.installation, this.props),
            arrowNav: getStationArrowNav('installation', this.props.installationTable, this.props.installation.id, s => this.props.push(`/station/installation/${s.id}/description`)),
            visitLinks: this.props.installationVisits.map(iv => ({ ...iv, idInstallation: this.props.installation.id })),
        }
        if (getUser().consultant === '1') {
            this.setActions(actions)
        } else {
            this.setActions({
                ...actions,
                edit: () => this.setEditMode(),
                delete: () => this.props.delete('installation', this.props.installation.id, this.onDelete),
            })
        }
    }

    createAndDownloadEdition = (installationVisit) => {
        const { installation } = this.state
        this.props.getEditionInstallation(installationVisit.idCampaign, installation.id).then(json => {
            downloadURI(json.targetPath)
        })
    }
    onChangeInstallation = (newObject) => {
        this.setState({ installation: { ...this.state.installation, ...newObject } })
    }

    onChangeVisit = (visitObject) => {
        if (visitObject && this.props.onChangeVisit) {
            this.props.onChangeVisit(visitObject)
        }
    }

    isDisplayed = (panel) => {
        return getSetting(this.props.applicationSettings, `PANEL_${this.state.installation.installationType}_${panel}`) !== 'hidden'
    }

    render() {
        const { installation, readMode } = this.state


        return (
            <GenericInstallationDescription
                readMode={readMode}
                installation={installation}
                onChangeInstallation={this.onChangeInstallation}
                onChangeVisit={this.onChangeVisit}
                descElements={
                    <Grid2 size={12}>
                        <BuildingsPanel
                            station={installation}
                            onChange={this.onChangeInstallation}
                            onChangeVisit={this.onChangeVisit}
                            readMode={readMode}
                        />
                    </Grid2>
                }
            />
        )
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextState.installation.LAST_FORM !== this.state.installation.LAST_FORM && nextState.installation.LAST_FORM.match(/INPUT|TEXT/g)) {
            return false
        }
        return true
    }
}

InstallationDescriptionPanel.propTypes = getPropTypes(storeProps, {
    onRemount: PropTypes.func,
    onChangeVisit: PropTypes.func, // used for changes on visit mode only
    contacts: arrayOf(ContactDto),
    installation: PropTypes.instanceOf(DtoInstallation),
    hydrogeologicalEntities: PropTypes.arrayOf(PropTypes.instanceOf(DtoHydrogeologicalEntity)),
    installationTable: arrayOf(DtoInstallationTableLine),
    accountHabilitations: arrayOf(DtoAccountHabilitation),
})

const mapStateToProps = (store) => getMapStateToProps(storeProps, {
    applicationSettings: store.AdministrationReducer.applicationSettings,
    watersheds: store.WatershedReducer.watersheds,
    aquifers: store.AquiferReducer.aquifers,
    contacts: store.ContactReducer.contacts,
    installation: store.InstallationReducer.installation,
    installationVisits: store.InstallationReducer.installationVisits,
    hydrogeologicalEntities: store.HydrogeologicalEntityReducer.hydrogeologicalEntities,
    installationTable: store.InstallationReducer.installationTable,
    accountHabilitations: store.AccountReducer.accountHabilitations,
})

const mapDispatchToProps = {
    push,
    updateInstallation: InstallationAction.updateInstallation,
    info: ToastrAction.info,
    fetch: SieauAction.fetch,
    delete: SieauAction.delete,
    getEditionInstallation: InstallationAction.getEditionInstallation,
    fetchWatersheds: WatershedAction.fetchWatersheds,
    fetchAquifers: AquiferAction.fetchAquifers,
    fetchHydrogeologicalEntities: HydrogeologicalEntityAction.fetchHydrogeologicalEntities,
    fetchInstallationVisits: InstallationAction.fetchInstallationVisits,
    updateInstallationAndVisit: InstallationAction.updateInstallationAndVisit,
}

export default connect(mapStateToProps, mapDispatchToProps)(InstallationDescriptionPanel)
