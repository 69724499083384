/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import i18n from 'simple-react-i18n'
import DtoDistributionUnit from '../../../distributionUnit/dto/DtoDistributionUnit'
import DtoHydrometricStation from '../../../hydrometry/dto/DtoHydrometricStation'
import DtoInstallation from '../../../installation/dto/installation/DtoInstallation'
import DtoPiezometer from '../../../piezometry/dto/DtoPiezometer'
import DtoProductionUnit from '../../../productionUnit/dto/DtoProductionUnit'
import DtoQualitometer from '../../../quality/dto/DtoQualitometer'
import { SANDRE } from '../../../referencial/constants/ReferencialConstants'
import { getSandreLabel } from '../../../utils/StringUtil'
import { Card, CardContent, Grid2 } from '@mui/material'
import useSandreList from 'utils/customHook/useSandreList'
import Select from 'components/forms/Select'
import NumberField from 'components/forms/NumberField'
import StationDescriptionTable from './StationDescriptionTable'

const headers = ['lithology', 'thickness']

const LithologyDialogContent = ({
    stateElement,
    onChangeElement = () => { },
}) => {
    const lithologyList = useSandreList(SANDRE.LITHOLOGY)

    return (
        <Card>
            <CardContent>
                <Grid2 container columnSpacing={1}>
                    <Grid2 size={6}>
                        <Select
                            options={lithologyList}
                            label={i18n.name}
                            value={stateElement.lithologyCode}
                            onChange={v => onChangeElement({ lithologyCode: v })}
                        />
                    </Grid2>
                    <Grid2 size={6}>
                        <NumberField
                            value={stateElement.thickness}
                            title={i18n.thickness}
                            onChange={v => onChangeElement({ thickness: v })}
                            floatValue
                        />
                    </Grid2>
                </Grid2>
            </CardContent>
        </Card>
    )
}

LithologyDialogContent.propTypes = {
    stateElement: PropTypes.object,
    onChangeElement: PropTypes.func,
}

const StationLithologyPanel = ({
    station = {},
    readMode = false,
    onChange = () => { },
}) => {
    const {
        sandreCodes,
    } = useSelector(store => ({
        sandreCodes: store.ReferencialReducer.sandreCodes,
    }), shallowEqual)

    return (
        <StationDescriptionTable
            station={station}
            readMode={readMode}
            onChange={onChange}
            keyList='link_lithology'
            formatFunction={c => ({
                ...c,
                type: getSandreLabel(sandreCodes, SANDRE.LITHOLOGY_TYPE, c.type),
                lithology: getSandreLabel(sandreCodes, SANDRE.LITHOLOGY, c.lithologyCode),
            })}
            titles={{
                title: i18n.lithology,
                edit: i18n.editLithology,
                new: i18n.newLithology,
            }}
            headers={headers}
            dialogContent={LithologyDialogContent}
        />
    )
}

StationLithologyPanel.propTypes = {
    station: PropTypes.oneOfType([
        PropTypes.instanceOf(DtoProductionUnit),
        PropTypes.instanceOf(DtoDistributionUnit),
        PropTypes.instanceOf(DtoQualitometer),
        PropTypes.instanceOf(DtoPiezometer),
        PropTypes.instanceOf(DtoHydrometricStation),
        PropTypes.instanceOf(DtoInstallation),
    ]),
    readMode: PropTypes.bool,
    onChange: PropTypes.func,
}

export default StationLithologyPanel