import ApplicationConf from 'conf/ApplicationConf'
import ToastrAction from 'toastr/actions/ToastrAction'
import i18n from 'simple-react-i18n'
import {
    CENTRAL,
    POWER_SUPPLY,
    RESET_ALL_MATERIELS,
    SENSOR,
    SIM,
    TELECOM,
    VARIOUS,
} from '../constants/MaterielConstants'
import CentralAction from '../components/central/actions/CentralAction'
import SimAction from '../components/sim/actions/SimAction'
import TelecomAction from '../components/telecom/actions/TelecomAction'
import SensorAction from '../components/sensor/actions/SensorAction'
import PowerSupplyAction from '../components/powerSupply/actions/PowerSupplyAction'
import VariousMaterielAction from '../components/variousMateriel/actions/VariousMaterielAction'
import EquipmentAction from '../components/equipment/actions/EquipmentAction'
import {
    checkAuth,
    checkAuthWithoutKicking,
    checkError, genericPromise,
    getAuthorization,
    getJson,
    promiseAllProgress,
} from '../../utils/ActionUtils'
import SubscriptionAction from '../components/subscription/actions/SubscriptionAction'
import ContributorAction from '../../referencial/components/contributor/actions/ContributorAction'
import LogAction from '../../log/actions/LogAction'
import { materielActions } from '../reducers/MaterielReducer'
import { ContributorActionConstant } from 'referencial/components/contributor/reducers/ContributorReducer'

const MaterielAction = {

    fetchMonitoredMateriels: () => dispatch => {
        return fetch(ApplicationConf.materiel.monitored(), {
            headers: getAuthorization(),
        })
            .then(checkAuth)
            .then(getJson)
            .then(checkError)
            .then((json = []) => {
                const promises = json.map(o => {
                    switch (o.typeMateriel) {
                        case CENTRAL:
                            return CentralAction.promiseCentral(o.idMateriel)
                        case SIM:
                            return SimAction.promiseSim(o.idMateriel)
                        case TELECOM:
                            return TelecomAction.promiseTelecom(o.idMateriel)
                        case SENSOR:
                            return SensorAction.promiseSensor(o.idMateriel)
                        case POWER_SUPPLY:
                            return PowerSupplyAction.promisePowerSupply(o.idMateriel)
                        case VARIOUS:
                            return VariousMaterielAction.promiseVariousMateriel(o.idMateriel)
                        default:
                            return EquipmentAction.promiseEquipment(o.idMateriel)
                    }
                })
                Promise.all(promises)
                    .then((jsonMat = []) => {
                        const materiels = jsonMat.map((o, i) => {
                            switch (json[i].typeMateriel) {
                                case CENTRAL:
                                    return { ...json[i], serialNumber: o.serialNumber, type: o.centralType }
                                case SIM:
                                    return { ...json[i], serialNumber: o.serialNumber, type: o.simType }
                                case TELECOM:
                                    return { ...json[i], serialNumber: o.serialNumber, type: o.telecomType }
                                case SENSOR:
                                    return { ...json[i], serialNumber: o.serialNumber, type: o.sensorType }
                                case POWER_SUPPLY:
                                    return { ...json[i], serialNumber: o.serialNumber, type: o.powerSupplyType }
                                case VARIOUS:
                                    return { ...json[i], serialNumber: o.serialNumber, type: o.variousType }
                                default:
                                    return { ...json[i], serialNumber: o.equipment.serialNumber, type: o.equipment.equipmentType }
                            }
                        })
                        dispatch(materielActions.monitored(materiels))
                    })
            })
            .catch((err) => {
                dispatch(LogAction.logError(`${i18n.fetchError + i18n.monitoredMateriels} : ${err}`))
                dispatch(ToastrAction.error(i18n.fetchError + i18n.monitoredMateriels))
            })
    },

    fetchMaterielAssignments(materielId, materielType) {
        return (dispatch) => {
            return fetch(ApplicationConf.materiel.materielAssignments(materielId, materielType), {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(materielActions.assignments(json))
                })
        }
    },

    promiseMaterielStates: () => fetch(ApplicationConf.materiel.states(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError),

    fetchMaterielStates: () => dispatch => MaterielAction.promiseMaterielStates()
        .then((json = []) => {
            dispatch(materielActions.states(json))
        }),

    promiseMaterielCampaign(id) {
        return fetch(ApplicationConf.materiel.campaignMateriels(id), {
            headers: getAuthorization(),
        })
            .then(checkAuthWithoutKicking)
            .then(getJson)
            .then(checkError)
    },

    fetchMaterielCampaign(id, callback = () => { }) {
        return (dispatch) => {
            return MaterielAction.promiseMaterielCampaign(id)
                .then((json = []) => {
                    dispatch(materielActions.campaign(json))
                    callback()
                })
        }
    },
    fetchMaterielStationEvents(fetchUrl, siteType) {
        return (dispatch) => {
            return fetch(fetchUrl, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(materielActions.addStationsEvents({ stationsEvents: json, siteType }))
                })
        }
    },
    fetchMaterielStationCampaigns(fetchUrl, siteType, siteId) {
        return (dispatch) => {
            return fetch(fetchUrl, {
                method: 'GET',
                headers: getAuthorization(),
            })
                .then(checkAuth)
                .then(getJson)
                .then(checkError)
                .then((json = []) => {
                    dispatch(materielActions.addStationsCampaigns({ stationsCampaigns: json, siteType, siteId }))
                })
        }
    },

    fetchAllSituationByEvent: (id, stationType, stationId) => () => Promise.all([
        MaterielAction.promiseSituationsByEvent(ApplicationConf.materiel.equipmentsSituationByEvent(id, stationType, stationId)),
        MaterielAction.promiseSituationsByEvent(ApplicationConf.materiel.centralsSituationByEvent(id, stationType, stationId)),
        MaterielAction.promiseSituationsByEvent(ApplicationConf.materiel.sensorsSituationByEvent(id, stationType, stationId)),
        MaterielAction.promiseSituationsByEvent(ApplicationConf.materiel.powerSuppliesSituationByEvent(id, stationType, stationId)),
        MaterielAction.promiseSituationsByEvent(ApplicationConf.materiel.simsSituationByEvent(id, stationType, stationId)),
        MaterielAction.promiseSituationsByEvent(ApplicationConf.materiel.telecomsSituationByEvent(id, stationType, stationId)),
        MaterielAction.promiseSituationsByEvent(ApplicationConf.materiel.variousMaterielsSituationByEvent(id, stationType, stationId)),
        MaterielAction.promiseSituationsByEvent(ApplicationConf.materiel.subscriptionsSituationByEvent(id, stationType, stationId)),
    ]).then(json => json).catch(() => [[], [], [], [], [], [], [], []]),

    promiseSituationsByEvent: path => fetch(path, {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .catch(() => []),

    fetchMaterielsByEvent: (stationId, eventDate) => () => fetch(
        ApplicationConf.materiel.materielByEvent(stationId, eventDate),
        {
            method: 'GET',
            headers: getAuthorization(),
        },
    )
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then((json = {}) => json)
        .catch(() => ({})),

    fetchMaterielTypes: () => dispatch => fetch(ApplicationConf.materiel.type(), {
        method: 'GET',
        headers: getAuthorization(),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => dispatch(materielActions.types(json)))
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.fetchError + i18n.materiel} : ${err}`))
            dispatch(ToastrAction.error(i18n.fetchError + i18n.materiel))
        }),

    updateMaterielTypes: materielTypes => dispatch => fetch(ApplicationConf.materiel.materielType(), {
        method: 'PUT',
        headers: getAuthorization(),
        body: JSON.stringify(materielTypes),
    })
        .then(checkAuth)
        .then(getJson)
        .then(checkError)
        .then(json => {
            if (json.result > 0) {
                dispatch(MaterielAction.fetchMaterielTypes())
                dispatch(ToastrAction.success(i18n.elementUpdateSuccess))
            }
        })
        .catch(err => {
            dispatch(LogAction.logError(`${i18n.updateError + i18n.materiel} : ${err}`))
            dispatch(ToastrAction.error(i18n.updateError + i18n.materiel))
        }),

    promiseVariousMaterielFromType: (id) => genericPromise(ApplicationConf.materiel.variousMaterielFromType(id)),

    loadInventory: progressCallback => dispatch => {
        const promises = [
            MaterielAction.promiseMaterielStates(),
            CentralAction.promiseCentrals(true),
            CentralAction.promiseCentralLastSituation(),
            CentralAction.promiseCentralTypes(),
            PowerSupplyAction.promisePowerSupplies(true),
            PowerSupplyAction.promisePowerSuppliesLastSituation(),
            PowerSupplyAction.promisePowerSupplyTypes(),
            SensorAction.promiseSensors(true),
            SensorAction.promiseSensorLastSituation(),
            SensorAction.promiseSensorTypes(),
            VariousMaterielAction.promiseVariousMateriels(),
            VariousMaterielAction.promiseVariousMaterielsLastSituations(),
            VariousMaterielAction.promiseVariousMaterielTypes(),
            EquipmentAction.promiseEquipments(),
            EquipmentAction.promiseEquipmentsLastSituations(),
            EquipmentAction.promiseEquipmentTypes(),
            SimAction.promiseSims(true),
            SimAction.promiseSimsLastSituations(),
            SimAction.promiseSimTypes(),
            TelecomAction.promiseTelecoms(true),
            TelecomAction.promiseTelecomLastSituation(),
            TelecomAction.promiseTelecomTypes(),
            SubscriptionAction.promiseSubscriptions(),
            SubscriptionAction.promiseSubscriptionsLastSituations(),
            SubscriptionAction.promiseSubscriptionTypes(),
            ContributorAction.promiseContributors(),
        ]
        return promiseAllProgress(promises, progressCallback).then(jsonTab => {
            dispatch(materielActions.states(jsonTab[0]))
            dispatch(CentralAction.receiveCentrals(jsonTab[1]))
            dispatch(CentralAction.receiveCentralsLastSituations(jsonTab[2]))
            dispatch(CentralAction.receiveCentralTypes(jsonTab[3]))
            dispatch(PowerSupplyAction.receivePowerSupplies(jsonTab[4]))
            dispatch(PowerSupplyAction.receivePowerSupplyLastSituations(jsonTab[5]))
            dispatch(PowerSupplyAction.receivePowerSupplyTypes(jsonTab[6]))
            dispatch(SensorAction.receiveSensors(jsonTab[7]))
            dispatch(SensorAction.receiveSensorLastSituations(jsonTab[8]))
            dispatch(SensorAction.receiveSensorTypes(jsonTab[9]))
            dispatch(VariousMaterielAction.receiveVariousMateriels(jsonTab[10]))
            dispatch(VariousMaterielAction.receiveVariousMaterielLastSituations(jsonTab[11]))
            dispatch(VariousMaterielAction.receiveVariousMaterielTypes(jsonTab[12]))
            dispatch(EquipmentAction.receiveEquipments(jsonTab[13]))
            dispatch(EquipmentAction.receiveEquipmentLastSituations(jsonTab[14]))
            dispatch(EquipmentAction.receiveEquipmentTypes(jsonTab[15]))
            dispatch(SimAction.receiveSims(jsonTab[16]))
            dispatch(SimAction.receiveSimLastSituations(jsonTab[17]))
            dispatch(SimAction.receiveSimTypes(jsonTab[18]))
            dispatch(TelecomAction.receiveTelecoms(jsonTab[19]))
            dispatch(TelecomAction.receiveTelecomLastSituations(jsonTab[20]))
            dispatch(TelecomAction.receiveTelecomTypes(jsonTab[21]))
            dispatch(SubscriptionAction.receiveSubscriptions(jsonTab[22]))
            dispatch(SubscriptionAction.receiveSubscriptionsLastSituations(jsonTab[23]))
            dispatch(SubscriptionAction.receiveSubscriptionTypes(jsonTab[24]))
            dispatch(ContributorActionConstant.receiveAllContributors(jsonTab[25]))
        }).catch((err) => {
            dispatch(LogAction.logError(`${i18n.loadError} : ${err}`))
            dispatch(ToastrAction.error(i18n.loadError))
        })
    },

    resetAllMateriels: () => dispatch => dispatch({ type: RESET_ALL_MATERIELS }),
}

export default MaterielAction
